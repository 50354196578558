import { combineReducers } from 'redux';
import websiteReducer from './websiteReducer';
import formReducer from './formReducer';


// console.log = function() {
    
// }

export default combineReducers({
    webRed : websiteReducer,
    formRed : formReducer
});