import React, { Component } from "react";
// import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
// import {browserHistory} from "react-router";
import "./../../../public/scss/pagcorDashboard_statistics.scss";
import PropTypes from 'prop-types';
import { compareBranchAvgDMERecordAllBranches, triggerYearChange, candidateDistributionRecord, compareCategorisedDMERecordAllBranches, compareSectionDMERecordAllBranches, compareDivisionDMERecordAllBranches, compareCompetencyBySectionRecordAllBranches, compareCompetencyByDivisionRecordAllBranches, getAllDivisions, getCompetencyAvgCompareAllBranch, getAllBranches, getTotalPeopleAssesstCompareAllBranches, updateBranchAction, getMainCompetencyList, getSubCompetencyRecord } from './../../../actions/webRedActions';
import { connect } from 'react-redux';
import BranchesListSidebar from './BranchesListSidebar';
import DMEProfileComponent from './DMEProfileComponent';

import {
    Container,
    Row,
    Col,
    Button,
    Spinner,
    FormGroup,
    Input,
    Media,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Table,
    Tooltip
} from "reactstrap";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMale, faFemale, faTimes, faBars, faSchool, faInfo, faComment, faCalendarCheck, faCalendar, faChevronLeft } from '@fortawesome/free-solid-svg-icons';

import Switch from "react-switch";
import { ResponsiveBar } from '@nivo/bar';
import { ResponsiveBubble } from '@nivo/circle-packing';
import { ResponsivePie } from '@nivo/pie';
import { ResponsiveRadar } from '@nivo/radar';
import { ResponsiveTreeMap } from '@nivo/treemap';
import { ResponsiveHeatMap } from '@nivo/heatmap'

import branchicon from './../../../public/images/branches/branchicon.png';
import devisionicon from './../../../public/images/branches/divisionIcon.png';
import sectionicon from './../../../public/images/branches/sectionIcon.png';

import branchData1 from './branchData1';
import branchData2 from './branchData2';
import archetypesData from './architypesData';
import DMEProfileCompare from "./DMEProfileCompareAllBranch";
import { Link } from "react-router-dom";
import AllBranchesStats from "./AllBranchesStats";


// import $ from 'jquery'; 

class AllBranchesStatsCompare extends Component {

    constructor(props) {
        super(props);

        this.state = {

            branchIndex: 0,
            competencyIndex: 0,
            divisionIndex: 0,
            sectionIndex: 0,
            branches: [
                branchData1,
                branchData2
            ],
            allBranches: {
                mainCompetencies: [{
                    name: "CORE",
                    totalAverage: 84.3
                }, {
                    name: "ORGANIZATIONAL",
                    totalAverage: 90
                }, {
                    name: "LEADERSHIP",
                    totalAverage: 95.3
                }, {
                    name: "TECHNICAL",
                    totalAverage: 93
                }]
            },
            archetypesList: archetypesData,
            archetypesDataChart: [],
            compComparisonData: {
                keys: [],
                data: []
            },
            compComparisonDataDivisionWise: {
                keys: [],
                data: []
            },
            compComparisonDataDivisionWiseTempDivisionArray: [], // temporary random scores array for persistency while switching in different divisions to show in sections comparison chart
            compComparisonDataSectionWise: {
                keys: [],
                data: []
            },
            divisionSectionData: {
                "name": "loading",
                "color": "hsl(241, 70%, 50%)",
                "sample": 1
            },
            respondentsCatogorizeDMEData: '',
            competencyFrameworkData: '',
            branchesListToggle: false,
            archetypesTreeMapData: '',
            tooltipOpen: [],



            branchesListData: [],
            selectedBranchIdValue: '',



            selectedSkill: this.props.match.params.skillId,
            selectedSkillId: 0,

            situations: [''],
            situationsAvg: [],
            situationsAvgSpecificCity: [],
            userTypeDataAllRegions: '',
            userTypeDataSpecificRegion: '',
            genderDataAllRegions: '',
            genderDataSpecificRegion: '',
            ageDataAllRegions: '',
            ageDataSpecificRegion: '',
            positionsDataAllRegions: '',
            positionsDataSpecificRegion: '',
            mediumsDataAllRegions: '',
            mediumsDataSpecificRegion: '',
            transportDataAllRegions: '',
            transportDataSpecificRegion: '',
            userTypeScoresData: {
                keys: [],
                data: []
            },
            skillUpdateLoader: false,
            GenderUpdateLoader: false,
            userTypeScoresDataPie1: [[], []],
            userTypeScoresDataPie1Loc: 0,
            genderScoresData: [],
            genderScoresDataLoc: 0,
            ageScoreDataLoc: 0,
            userTypeAgeScoresData: [{
                keys: [],
                data: []
            }, {
                keys: [],
                data: []
            }],
            positionScoreDataLoc: 0,
            userTypePositionsScoresData: [{
                keys: [],
                data: []
            }, {
                keys: [],
                data: []
            }],
            mediumScoreDataLoc: 0,
            userTypeMediumsScoresData: [{
                keys: [],
                data: []
            }, {
                keys: [],
                data: []
            }],
            transportScoreDataLoc: 0,
            userTypeTransportsScoresData: [{
                keys: [],
                data: []
            }, {
                keys: [],
                data: []
            }],
            swarmDataAllRegions: [],
            isBranchesLoading: false,
            allCities: [{
                cityid: 0,
                cityname: "Manila"
            }],
            cityUpdateLoader: false,
            situationsAvgDataLoc: 0,
            citySearchText: '',
            selectedMayer: "Isko Moreno",
            // showModalInfo : false,
            // showModalChat : false,
            integratedFormId: "",
            integratedFormToggle: false,
            formSource: "",
            totalAssesst: "",
            totalDivision: "",
            totalSection: "",
            branchName: "",
            mainCompetency: [],
            isMainCompetencyLoading: true,
            mainCompetencyAverage: "-",
            subCompetency: [],
            arrray: [],
            subCompPerc: "0",
            mapCompetencyonTree: [],
            compComparisonDataApi: {
                keys: [],
                data: []
            },
            allDivisions: [],
            allSections: [],
            compComparisonDataDivisionWiseApi: {
                keys: [],
                data: []
            },
            divisionName: "",
            sectionName: "",
            compComparisonDataSectionWiseApi: {
                keys: [],
                data: []
            },
            competencyName: "",
            divisionSectionDataApi: '',
            subcompetencyLoader: true,
            selectedYear: '2019,2020',
            totalPeopleinSection: 0,
            sectionHideGraph: false,
            divisionHideGraph: false,

            peopleAssest19: 'loading',
            peopleAssest20: 'loading',
            allDivision19: 'loading',
            allDivision20: 'loading',
            allSections19: 'loading',
            allSections20: 'loading',
            bacolodImg: 'https://banner2.cleanpng.com/20180614/qrv/kisspng-placewell-international-services-corp-bacolod-cit-city-logo-5b2271ccec1ca1.5961857215289840129671.jpg',
            noImg: 'https://ctt.trains.com/sitefiles/images/no-preview-available.png'
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0); // scroll to top after rendering the component
        this.props.getTotalPeopleAssesstCompareAllBranches(this.state.selectedYear);
        this.props.getCompetencyAvgCompareAllBranch('2019,2020');
        this.props.compareBranchAvgDMERecordAllBranches('2019,2020');
        this.props.compareCategorisedDMERecordAllBranches('2019,2020');
    }



    componentWillReceiveProps(nextProps, thisProps) {
        if (this.props.switchedBranch != nextProps.switchedBranch) {
            debugger;
            this.state.subCompetency = [];
            this.setState({
                selectedBranchIdValue: nextProps.switchedBranch.selectedBranch.id,
                branchName: nextProps.switchedBranch.selectedBranch.name,
                //First Section States Zero for loader
                totalAssesst: '',
                totalDivision: '',
                totalSection: '',
                //Second Main and Sub Competency loader
                isMainCompetencyLoading: true,
                subcompetencyLoader: true,
                competencyIndex: 0,
                subCompPerc: 0,
                //Third Tree Map
                mapCompetencyonTree: '',
                //Fourth COmpetency Avg
                compComparisonDataApi: {
                    keys: [],
                    data: []
                },
                //Fifth Competency with Division
                compComparisonDataDivisionWiseApi: {
                    keys: [],
                    data: []
                },
                //Sixth Competency with Section
                compComparisonDataSectionWiseApi: {
                    keys: [],
                    data: []
                },
                //Seventh Bubble Graph for Designatino
                divisionSectionDataApi: '',



                //new new new
                peopleAssest19: 'loading',
                peopleAssest20: 'loading',
                allDivision19: 'loading',
                allDivision20: 'loading',
                allSections19: 'loading',
                allSections20: 'loading'
            }, () => {
                console.log('branchIndex', this.state.selectedBranchIdValue);
            })
        }

        else if (this.props.totalPeopleAssest != nextProps.totalPeopleAssest) {
            debugger;
            this.setState({
                peopleAssest19: nextProps.totalPeopleAssest.result[2019].totallPeopleAssesst,
                peopleAssest20: nextProps.totalPeopleAssest.result[2020].totallPeopleAssesst,
                allDivision19: nextProps.totalPeopleAssest.result[2019].divisions,
                allDivision20: nextProps.totalPeopleAssest.result[2020].divisions,
                allSections19: nextProps.totalPeopleAssest.result[2019].sections,
                allSections20: nextProps.totalPeopleAssest.result[2020].sections,
            }, () => {
                console.log('branchIndex', this.state.selectedBranchIdValue);
            })
        }

        else if (this.props.getAllCompetencyAvg != nextProps.getAllCompetencyAvg) {
            if (nextProps.getAllCompetencyAvg !== 'error') {
                debugger;

                var dataArrayNew = [];

                nextProps.getAllCompetencyAvg.result.result.map((item, index) => {
                    dataArrayNew.push({
                        "type": item.competency_name,
                        "2019": nextProps.getAllCompetencyAvg.result.everyMainComptPerc[2019][item.competency_id].totallPerc,
                        "2020": nextProps.getAllCompetencyAvg.result.everyMainComptPerc[2020][item.competency_id].totallPerc
                    })
                })

                this.setState({
                    compComparisonDataApi: {
                        keys: ["2019", "2020"],
                        data: dataArrayNew
                    },
                    mainCompetency: nextProps.getAllCompetencyAvg.result.result
                }, () => {

                    // if (this.state.allDivision19.length > 0) {
                    //     if (this.state.allSections19.length > 0) {
                    //         this.props.compareCompetencyByDivisionRecordAllBranches('2019,2020', this.state.allDivision19[0].DIVISION)
                    //         this.props.compareCompetencyBySectionRecordAllBranches('2019,2020', this.state.allSections19[0].SECTION)
                    //         this.props.compareDivisionDMERecordAllBranches('2019,2020', this.state.allDivision19[0].DIVISION)
                    //         this.props.compareSectionDMERecordAllBranches('2019,2020', this.state.allSections19[0].SECTION)
                    //     }
                    //     else {
                    //         debugger;
                    //         this.props.compareCompetencyByDivisionRecordAllBranches('2019,2020', this.state.allDivision19[0].DIVISION)
                    //         this.props.compareCompetencyBySectionRecordAllBranches('2019,2020', "No Section")
                    //         this.props.compareDivisionDMERecordAllBranches('2019,2020', this.state.allDivision19[0].DIVISION)
                    //         this.props.compareSectionDMERecordAllBranches('2019,2020', "No Section")
                    //     }
                    // }
                    // else {
                    //     this.props.compareCompetencyByDivisionRecordAllBranches('2019,2020', "No Division")
                    //     this.props.compareCompetencyBySectionRecordAllBranches('2019,2020', "No Section")
                    //     this.props.compareDivisionDMERecordAllBranches('2019,2020', "No Division")
                    //     this.props.compareSectionDMERecordAllBranches('2019,2020', "No Section")
                    // }
                })
            }
            else {
                debugger;
                window.alert("ERROR in Competency Average Comparision Stats");
            }
        }


        else if (this.props.competencyByDivision != nextProps.competencyByDivision) {
            if (nextProps.competencyByDivision !== 'error') {
                debugger;

                var dataArrayNew = [];

                this.state.mainCompetency.map((item, index) => {
                    dataArrayNew.push({
                        "type": item.competency_name,
                        "2019": Math.round(nextProps.competencyByDivision[2019][item.competency_name].totallPerc),
                        "2020": Math.round(nextProps.competencyByDivision[2020][item.competency_name].totallPerc)
                    })
                })

                this.setState({
                    compComparisonDataDivisionWiseApi: {
                        keys: ["2019", "2020"],
                        data: dataArrayNew
                    },
                }, () => {

                })
            }
            else {
                debugger;
                window.alert("ERROR in Competency Average Comparision Stats");
            }
        }

        else if (this.props.competencyBySection != nextProps.competencyBySection) {
            if (nextProps.competencyBySection !== 'error') {
                debugger;

                var dataArrayNew = [];

                this.state.mainCompetency.map((item, index) => {
                    dataArrayNew.push({
                        "type": item.competency_name,
                        "2019": Math.round(nextProps.competencyBySection[2019][item.competency_name].totallPerc),
                        "2020": Math.round(nextProps.competencyBySection[2020][item.competency_name].totallPerc)
                    })
                })

                this.setState({
                    compComparisonDataSectionWiseApi: {
                        keys: ["2019", "2020"],
                        data: dataArrayNew
                    },
                }, () => {

                })
            }
            else {
                debugger;
                window.alert("ERROR in Competency Average Comparision Stats");
            }
        }

    }

    toggleBranchesBar = () => {
        this.setState({
            branchesListToggle: !this.state.branchesListToggle
        }, () => {
            // if(this.state.branchesListToggle) {
            //   $('body').css({overflow: 'hidden'})
            // }else{
            //   $('body').css({overflow: ''})
            // }
        })
    }

    toggleModalInfo = () => {
        this.setState({
            integratedFormId: "ODY5Mg==",
            integratedFormToggle: true
        }, () => {

            // var randomEmail = parseInt(Math.random()*5000 + 10) + "@yopmail.com";
            // var randomPhone = parseInt(Math.random()*100000 + 200);

            this.setState({
                // formSource : "https://form.assessmentfund.com/"+ this.state.integratedFormId +"?nested=true&email="+ randomEmail +"&phone="+ randomPhone +"&name=DashboardTalentMapUser"
                formSource: "https://form.assessmentfund.com/" + this.state.integratedFormId
            }, () => {
                console.log(this.state.formSource);
            })

        })
    }

    toggleModalChat = () => {
        this.setState({
            integratedFormId: "ODY5Mw==",
            integratedFormToggle: true
        }, () => {

            // var randomEmail = parseInt(Math.random()*5000 + 10) + "@yopmail.com";
            // var randomPhone = parseInt(Math.random()*100000 + 200);

            this.setState({
                // formSource : "https://form.assessmentfund.com/"+ this.state.integratedFormId +"?nested=true&email="+ randomEmail +"&phone="+ randomPhone +"&name=DashboardTalentMapUser"
                formSource: "https://form.assessmentfund.com/" + this.state.integratedFormId
            }, () => {
                console.log(this.state.formSource);
            })

        })
    }

    closeModalForm = () => {
        this.setState({
            integratedFormToggle: false,
            formSource: ""
        })
    }

    toggleTooltip = (key) => {
        var tempArrayTooltip = this.state.tooltipOpen;
        tempArrayTooltip[key] = !tempArrayTooltip[key];
        this.setState({
            tooltipOpen: tempArrayTooltip
        }, () => {
            // console.log(this.state.tooltipOpen);
        })
    };

    render() {
        const { allDivision19, allDivision20, allSections19, allSections20, peopleAssest19, peopleAssest20, branches, allBranches, branchIndex, competencyIndex, divisionIndex, sectionIndex, archetypesDataChart, selectedBranchIdValue, branchName, totalDivision, totalAssesst, totalSection
            , mainCompetency, isMainCompetencyLoading, mainCompetencyAverage, allDivisions, allSections, selectedYear } = this.state;

        var subCompetenciesDataArrayAPI = []; // for sub competencies with average bar chart with API

        this.state.subCompetency.map((comp, key) => {

            var averageValue = comp.totallPerc;

            subCompetenciesDataArrayAPI.push({
                score: Math.round(averageValue),
                value: comp.name
            })


        });
        var subCompetenciesDataArray = []; // for sub competencies with average bar chart

        branches[branchIndex].mainCompetencies[competencyIndex].subCompetencies.map((comp, key) => {

            var averageValue = comp.average;

            subCompetenciesDataArray.push({
                score: averageValue,
                value: comp.name
            })


        });

        var subCompetenciesDataArrayCountAPI = []; // for sub competencies with average bar chart API

        this.state.subCompetency.map((comp, key) => {

            var total = comp.totall;

            subCompetenciesDataArrayCountAPI.push({
                score: total,
                value: comp.name
            })


        });
        var subCompetenciesDataArrayCount = []; // for sub competencies with average bar chart 

        branches[branchIndex].mainCompetencies[competencyIndex].subCompetencies.map((comp, key) => {

            var total = parseInt(comp.d_total + comp.m_total + comp.e_total);

            subCompetenciesDataArrayCount.push({
                score: total,
                value: comp.name
            })


        });
        console.log("MAP STATE", this.state.divisionSectionDataApi);
        console.log("HASEEB STATE", this.state.divisionSectionData);
        console.log("TREE", this.state.mapCompetencyonTree);
        console.log("MAIN COMPETENCY", this.state.mainCompetency);
        return (
            <div className="PagcorStatsComponent">
                {/* <Header /> */}

                <div>

                    <Container fluid>
                        <Row style={{ padding: '3vh 0' }}>
                            <Col style={{ padding: '5vh 0' }} md={{ size: 6 }} className="text-right">
                                <Link to="/pagcor/AllBranchesStats">
                                    <Button className={"yearButton"}>GO BACK</Button>
                                </Link>
                            </Col>

                            {/* section for mayer information and city additional info - starts here */}
                            <Col md={{ size: 12 }}>
                                <div className="chartBox">
                                    <div>
                                        <Row style={{ padding: '25px' }}>

                                            <Col md={{ size: 4 }}>
                                                <div className="schoolBox topSchoolBox">
                                                    <Row>
                                                        <Col md={{ size: 12 }}>
                                                            <div style={{ verticalAlign: 'middle', padding: '0vh 0' }}>

                                                                <img src={branchicon} />
                                                                <p style={{ margin: 0, color: '#7a8596', position: 'relative', top: '8px' }}>Branch</p>
                                                                <h3 className="cityName">All Branches</h3>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    {/* <p className="titleTag">City</p> */}
                                                </div>
                                            </Col>

                                            <Col md={{ size: 4 }}>
                                                <div className="schoolBox topSchoolBox">

                                                    <h3 className="cityName"></h3>
                                                    <div className="oneInfo">
                                                        <div className="iconBox">
                                                            <div className="mt-4">
                                                                <FontAwesomeIcon className="iconHere" icon={faMale} size="4x" />
                                                                <FontAwesomeIcon className="iconHere" icon={faFemale} size="4x" />
                                                            </div>
                                                        </div>
                                                        <p style={{ color: '#7a8596' }}>People Assessed</p>
                                                        <p style={{ color: '#7a8596' }}>2019</p>
                                                        {peopleAssest19 == 'loading' ? <Spinner className="citySpinner mt-2" size="sm" color="light" /> :
                                                            <h3>{peopleAssest19}</h3>}
                                                    </div>
                                                    <div className="oneInfo">
                                                        <div className="iconBox">
                                                            <div className="mt-4">
                                                                <FontAwesomeIcon className="iconHere" icon={faMale} size="4x" />
                                                                <FontAwesomeIcon className="iconHere" icon={faFemale} size="4x" />
                                                            </div>
                                                        </div>
                                                        <p style={{ color: '#7a8596' }}>People Assessed</p>
                                                        <p style={{ color: '#7a8596' }}>2020</p>
                                                        {peopleAssest20 == 'loading' ? <Spinner className="citySpinner mt-2" size="sm" color="light" /> :
                                                            <h3>{peopleAssest20}</h3>}
                                                    </div>

                                                </div>
                                            </Col>

                                            <Col md={{ size: 4 }}>
                                                <div className="schoolBox topSchoolBox">
                                                    <Row>
                                                        <Col>
                                                            <h3 className="cityName"></h3>
                                                            <div className="oneInfo">
                                                                <div className="iconBox">
                                                                    {/* <FontAwesomeIcon className="iconHere" icon={faMale} size="4x" />
                                        <FontAwesomeIcon className="iconHere" icon={faFemale} size="4x" /> */}
                                                                    <img src={devisionicon} />
                                                                </div>
                                                                <p style={{ color: '#7a8596' }}>Divisions</p>
                                                                <p style={{ color: '#7a8596' }}>2019 -  {allDivision19 == 'loading' ? <Spinner className="citySpinner mt-2" size="sm" color="light" /> : <span className="font-weight-bold text-light" style={{ fontSize: 20 }}>{allDivision19.length}</span>}</p>
                                                                <p style={{ color: '#7a8596' }}>2020 - {allDivision20 == 'loading' ? <Spinner className="citySpinner mt-2" size="sm" color="light" /> : <span className="font-weight-bold text-light" style={{ fontSize: 20 }}>{allDivision20.length}</span>}</p>
                                                            </div>
                                                            <div className="oneInfo">
                                                                <div className="iconBox">
                                                                    {/* <FontAwesomeIcon className="iconHere" icon={faSchool} size="4x" /> */}
                                                                    <img src={sectionicon} />
                                                                </div>
                                                                <p style={{ color: '#7a8596', maxWidth: "140px" }}>Sections</p>
                                                                <p style={{ color: '#7a8596' }}>2019 - {allSections20 == 'loading' ? <Spinner className="citySpinner mt-2" size="sm" color="light" /> : <span className="font-weight-bold text-light" style={{ fontSize: 20 }}>{allSections19.length}</span>}</p>
                                                                <p style={{ color: '#7a8596' }}>2020 - {allSections20 == 'loading' ? <Spinner className="citySpinner mt-2" size="sm" color="light" /> : <span className="font-weight-bold text-light" style={{ fontSize: 20 }}>{allSections20.length}</span>}</p>
                                                                {/* {totalSection == '' ? <Spinner className="citySpinner mt-2" size="sm" color="light" /> :
                                                                    <h3>{totalSection}</h3>} */}
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Col>

                                        </Row>



                                    </div>
                                </div>
                            </Col>
                            {/* section for mayer information and city additional info - ends here */}


                            <Col md={{ size: 12 }}>
                                <div className="chartBox">
                                    <div className="titleHead" >
                                        <Row>
                                            <Col md={{ size: 8 }}>
                                                <h5>
                                                    Competencies' average score comparison with all branches
                                </h5>
                                            </Col>
                                            <Col md={{ size: 4 }}>
                                                <div className="quickIcons" style={{ right: '15px' }}>
                                                    <FontAwesomeIcon className="customIcon" icon={faCalendarCheck} size="1x" onClick={e => this.toggleModalInfo()} />
                                                    <FontAwesomeIcon className="customIcon" icon={faComment} size="1x" onClick={e => this.toggleModalChat()} />
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                    <div style={{ height: 'auto' }}>

                                        <Row style={{ padding: '25px' }}>
                                            <Col md={{ size: 6 }}>

                                                <p style={{ color: '#7ba2dc', textAlign: 'left' }}>This bar chart depicts a performance analysis of percentage scores for {branchName} as compared to the overall result for this assessment for each of the four main competencies.</p>

                                            </Col>
                                            <Col md={{ size: 12 }}>

                                                <div className="chartBox" style={{ background: 'transparent' }}>
                                                    <div style={{ height: '400px', position: 'relative' }}>

                                                        {
                                                            this.state.compComparisonDataApi.data.length == 0 ?
                                                                <div className="customSpinnerChart">
                                                                    <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                                                                </div>
                                                                :

                                                                <ResponsiveBar
                                                                    data={this.state.compComparisonDataApi.data}
                                                                    keys={this.state.compComparisonDataApi.keys}
                                                                    indexBy="type"
                                                                    margin={{ top: 60, right: 30, bottom: 60, left: 100 }}
                                                                    padding={0.6}
                                                                    innerPadding={10}
                                                                    label={d => `${d.value}%`}
                                                                    // onClick={e => this.barChartClicked(e)}
                                                                    groupMode="grouped"
                                                                    colors={{ scheme: 'paired' }}
                                                                    // colors={["#f706d5", "#770367" ]}
                                                                    // colors={["#68366f", "#3d1f42" ]}
                                                                    layout="vertical"
                                                                    enableGridY={false}
                                                                    defs={[
                                                                        {
                                                                            id: 'dots',
                                                                            type: 'patternDots',
                                                                            background: 'inherit',
                                                                            color: '#38bcb2',
                                                                            size: 4,
                                                                            padding: 1,
                                                                            stagger: true
                                                                        },
                                                                        {
                                                                            id: 'lines',
                                                                            type: 'patternLines',
                                                                            background: 'inherit',
                                                                            color: '#eed312',
                                                                            rotation: -45,
                                                                            lineWidth: 6,
                                                                            spacing: 10
                                                                        }
                                                                    ]}
                                                                    fill={[
                                                                        {
                                                                            match: {
                                                                                id: 'fries'
                                                                            },
                                                                            id: 'dots'
                                                                        },
                                                                        {
                                                                            match: {
                                                                                id: 'sandwich'
                                                                            },
                                                                            id: 'lines'
                                                                        }
                                                                    ]}
                                                                    borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                                                                    axisTop={null}
                                                                    axisRight={null}
                                                                    axisBottom={{
                                                                        tickSize: 5,
                                                                        tickPadding: 5,
                                                                        tickRotation: 0,
                                                                        legend: '',
                                                                        legendPosition: 'middle',
                                                                        legendOffset: 50
                                                                    }}
                                                                    axisLeft={{
                                                                        tickSize: 5,
                                                                        tickPadding: 5,
                                                                        tickRotation: 0,
                                                                        legend: '',
                                                                        legendPosition: 'middle',
                                                                        legendOffset: -60
                                                                    }}
                                                                    labelSkipWidth={12}
                                                                    labelSkipHeight={12}
                                                                    labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                                                                    legends={[
                                                                        {
                                                                            dataFrom: 'keys',
                                                                            anchor: 'top-right',
                                                                            direction: 'column',
                                                                            justify: false,
                                                                            translateX: -15,
                                                                            translateY: -60,
                                                                            itemsSpacing: 2,
                                                                            itemWidth: 100,
                                                                            itemHeight: 25,
                                                                            itemDirection: 'left-to-right',
                                                                            itemOpacity: 0.85,
                                                                            symbolSize: 20,
                                                                            effects: [
                                                                                {
                                                                                    on: 'hover',
                                                                                    style: {
                                                                                        itemOpacity: 1
                                                                                    }
                                                                                }
                                                                            ]
                                                                        }
                                                                    ]}
                                                                    animate={true}
                                                                    motionStiffness={90}
                                                                    motionDamping={15}
                                                                />
                                                        }
                                                    </div>
                                                </div>
                                            </Col>
                                            {/* {this.state.divisionHideGraph ? null :
                                                <React.Fragment>
                                                    <div className="seperatorHeading">
                                                        Competencies' average score of {this.state.divisionName} in All Branches

                                                        <select className="customDropDown" onChange={e => {
                                                            this.setState({
                                                                sectionIndex: 0, // reset the sectionIndex
                                                                divisionIndex: 0,
                                                                divisionName: e.target.value,
                                                                compComparisonDataDivisionWiseApi: {
                                                                    keys: [],
                                                                    data: []
                                                                },
                                                            }, () => {
                                                                debugger;
                                                                this.props.compareCompetencyByDivisionRecordAllBranches(this.state.selectedYear, this.state.divisionName)
                                                                debugger;
                                                            })
                                                        }}>
                                                            {
                                                                typeof allDivision19 == 'object' ?
                                                                    allDivision19.map((division, key) =>
                                                                        <option key={key} value={division.DIVISION}>{division.DIVISION}</option>
                                                                    ) : null
                                                            }
                                                        </select>

                                                    </div>

                                                    <Col md={{ size: 8, offset: 1 }}>

                                                        <div style={{ color: '#7ba2dc', textAlign: 'left', marginTop: '30px' }}>
                                                            This bar chart highlights the performance for the Divisions in {branchName} for each of the four main competencies and has three points of relevance and comparison:
                            <ol style={{ marginTop: '20px' }}>
                                                                <li>Average percentage score of each of the {branches[branchIndex].totalDivisions} divisions for {branchName}</li>
                                                                <li>Average percentage score of each Division in {branchName} as compared to the {branchName}'s overall scores</li>
                                                                <li>Average percentage score of each Division in {branchName} as compared to overall result for this assessment</li>
                                                            </ol>
                                                        </div>

                                                    </Col>

                                                    <Col md={{ size: 9 }} style={{ paddingTop: '25px' }}>
                                                        <div className="chartBox" style={{ background: 'transparent' }}>
                                                            <div style={{ height: '400px', position: 'relative' }}>
                                                                {
                                                                    this.state.compComparisonDataDivisionWiseApi.data.length == 0 ?
                                                                        <div className="customSpinnerChart">
                                                                            <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                                                                        </div>
                                                                        :
                                                                        <ResponsiveBar
                                                                            data={this.state.compComparisonDataDivisionWiseApi.data}
                                                                            keys={this.state.compComparisonDataDivisionWiseApi.keys}
                                                                            indexBy="type"
                                                                            margin={{ top: 100, right: 0, bottom: 60, left: 100 }}
                                                                            padding={0.3}
                                                                            innerPadding={10}
                                                                            label={d => `${d.value}%`}
                                                                            // onClick={e => this.barChartClicked(e)}
                                                                            groupMode="grouped"
                                                                            colors={{ scheme: 'paired' }}
                                                                            // colors={["#f706d5", "#770367" ]}
                                                                            // colors={["#68366f", "#3d1f42" ]}
                                                                            layout="vertical"
                                                                            enableGridY={false}
                                                                            defs={[
                                                                                {
                                                                                    id: 'dots',
                                                                                    type: 'patternDots',
                                                                                    background: 'inherit',
                                                                                    color: '#38bcb2',
                                                                                    size: 4,
                                                                                    padding: 1,
                                                                                    stagger: true
                                                                                },
                                                                                {
                                                                                    id: 'lines',
                                                                                    type: 'patternLines',
                                                                                    background: 'inherit',
                                                                                    color: '#eed312',
                                                                                    rotation: -45,
                                                                                    lineWidth: 6,
                                                                                    spacing: 10
                                                                                }
                                                                            ]}
                                                                            fill={[
                                                                                {
                                                                                    match: {
                                                                                        id: 'fries'
                                                                                    },
                                                                                    id: 'dots'
                                                                                },
                                                                                {
                                                                                    match: {
                                                                                        id: 'sandwich'
                                                                                    },
                                                                                    id: 'lines'
                                                                                }
                                                                            ]}
                                                                            borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                                                                            axisTop={null}
                                                                            axisRight={null}
                                                                            axisBottom={{
                                                                                tickSize: 5,
                                                                                tickPadding: 5,
                                                                                tickRotation: 0,
                                                                                legend: '',
                                                                                legendPosition: 'middle',
                                                                                legendOffset: 50
                                                                            }}
                                                                            axisLeft={{
                                                                                tickSize: 5,
                                                                                tickPadding: 5,
                                                                                tickRotation: 0,
                                                                                legend: '',
                                                                                legendPosition: 'middle',
                                                                                legendOffset: -60
                                                                            }}
                                                                            labelSkipWidth={12}
                                                                            labelSkipHeight={12}
                                                                            labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                                                                            legends={[
                                                                                {
                                                                                    dataFrom: 'keys',
                                                                                    anchor: 'top-right',
                                                                                    direction: 'column',
                                                                                    justify: false,
                                                                                    translateX: -100,
                                                                                    translateY: -100,
                                                                                    itemsSpacing: 2,
                                                                                    itemWidth: 150,
                                                                                    itemHeight: 25,
                                                                                    itemDirection: 'left-to-right',
                                                                                    itemOpacity: 0.85,
                                                                                    symbolSize: 20,
                                                                                    effects: [
                                                                                        {
                                                                                            on: 'hover',
                                                                                            style: {
                                                                                                itemOpacity: 1
                                                                                            }
                                                                                        }
                                                                                    ]
                                                                                }
                                                                            ]}
                                                                            animate={true}
                                                                            motionStiffness={90}
                                                                            motionDamping={15}
                                                                        />
                                                                }
                                                            </div>
                                                        </div>
                                                    </Col>

                                            
                                                </React.Fragment>} */}

                                            {/* {this.state.sectionHideGraph ? null :
                                                <React.Fragment>
                                                    <div className="seperatorHeading">
                                                        Competencies' average score of {this.state.sectionName} in all Branches

                                                        <select className="customDropDown" onChange={e => {
                                                            this.setState({
                                                                sectionIndex: 0,
                                                                sectionName: e.target.value,
                                                                compComparisonDataSectionWiseApi: {
                                                                    keys: [],
                                                                    data: []
                                                                },
                                                                totalPeopleinSection: 0
                                                            }, () => {
                                                                this.props.compareCompetencyBySectionRecordAllBranches(this.state.selectedYear, this.state.sectionName)
                                                            })
                                                        }}>
                                                            {
                                                                typeof allSections19 == 'object' ?
                                                                    allSections19.map((section, key) =>
                                                                        <option key={key} value={section.SECTION}>{section.SECTION}</option>
                                                                    ) : null
                                                            }
                                                        </select>

                                                    </div>

                                                    <Col md={{ size: 8, offset: 1 }}>

                                                        <div style={{ color: '#7ba2dc', textAlign: 'left', marginTop: '30px' }}>
                                                            This bar chart is a further breakdown of each Division and highlights the performance for the Sections in each Division in {branchName} for each of the four main competencies and has four points of relevance and comparison:
                            <ol style={{ marginTop: '20px' }}>
                                                                <li>Average percentage score of each of the Section in the selected Division for {branchName}</li>
                                                                <li>Average percentage score of each Section as compared to the overall scores for the selected Division in {branchName} as compared to the {branchName}'s overall scores</li>
                                                                <li>Average percentage score of each Section for the selected Division as compared to the {branchName}'s overall scores</li>
                                                                <li>Average percentage score of each Section for the selected Division in {branchName} as compared to overall result for this assessment </li>
                                                            </ol>
                                                        </div>

                                                    </Col>

                                                    <Col md={{ size: 9 }} style={{ paddingTop: '25px' }}>
                                                        <div className="chartBox" style={{ background: 'transparent' }}>
                                                            <div style={{ height: '500px', position: 'relative' }}>
                                                                {
                                                                    this.state.compComparisonDataSectionWiseApi.data.length == 0 ?
                                                                        <div className="customSpinnerChart">
                                                                            <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                                                                        </div>
                                                                        :
                                                                        <ResponsiveBar
                                                                            data={this.state.compComparisonDataSectionWiseApi.data}
                                                                            keys={this.state.compComparisonDataSectionWiseApi.keys}
                                                                            indexBy="type"
                                                                            margin={{ top: 140, right: 0, bottom: 60, left: 100 }}
                                                                            padding={0.25}
                                                                            innerPadding={10}
                                                                            label={d => `${d.value}%`}
                                                                            // onClick={e => this.barChartClicked(e)}
                                                                            groupMode="grouped"
                                                                            colors={{ scheme: 'paired' }}
                                                                            // colors={["#f706d5", "#770367" ]}
                                                                            // colors={["#68366f", "#3d1f42" ]}
                                                                            layout="vertical"
                                                                            enableGridY={false}
                                                                            defs={[
                                                                                {
                                                                                    id: 'dots',
                                                                                    type: 'patternDots',
                                                                                    background: 'inherit',
                                                                                    color: '#38bcb2',
                                                                                    size: 4,
                                                                                    padding: 1,
                                                                                    stagger: true
                                                                                },
                                                                                {
                                                                                    id: 'lines',
                                                                                    type: 'patternLines',
                                                                                    background: 'inherit',
                                                                                    color: '#eed312',
                                                                                    rotation: -45,
                                                                                    lineWidth: 6,
                                                                                    spacing: 10
                                                                                }
                                                                            ]}
                                                                            fill={[
                                                                                {
                                                                                    match: {
                                                                                        id: 'fries'
                                                                                    },
                                                                                    id: 'dots'
                                                                                },
                                                                                {
                                                                                    match: {
                                                                                        id: 'sandwich'
                                                                                    },
                                                                                    id: 'lines'
                                                                                }
                                                                            ]}
                                                                            borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                                                                            axisTop={null}
                                                                            axisRight={null}
                                                                            axisBottom={{
                                                                                tickSize: 5,
                                                                                tickPadding: 5,
                                                                                tickRotation: 0,
                                                                                legend: '',
                                                                                legendPosition: 'middle',
                                                                                legendOffset: 50
                                                                            }}
                                                                            axisLeft={{
                                                                                tickSize: 5,
                                                                                tickPadding: 5,
                                                                                tickRotation: 0,
                                                                                legend: '',
                                                                                legendPosition: 'middle',
                                                                                legendOffset: -60
                                                                            }}
                                                                            labelSkipWidth={12}
                                                                            labelSkipHeight={12}
                                                                            labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                                                                            legends={[
                                                                                {
                                                                                    dataFrom: 'keys',
                                                                                    anchor: 'top-right',
                                                                                    direction: 'column',
                                                                                    justify: false,
                                                                                    translateX: -100,
                                                                                    translateY: -135,
                                                                                    itemsSpacing: 2,
                                                                                    itemWidth: 150,
                                                                                    itemHeight: 25,
                                                                                    itemDirection: 'left-to-right',
                                                                                    itemOpacity: 0.85,
                                                                                    symbolSize: 20,
                                                                                    effects: [
                                                                                        {
                                                                                            on: 'hover',
                                                                                            style: {
                                                                                                itemOpacity: 1
                                                                                            }
                                                                                        }
                                                                                    ]
                                                                                }
                                                                            ]}
                                                                            animate={true}
                                                                            motionStiffness={90}
                                                                            motionDamping={15}
                                                                        />
                                                                }
                                                            </div>
                                                        </div>
                                                    </Col>

                                                </React.Fragment>
                                            } */}

                                        </Row>


                                    </div>
                                </div>
                            </Col>


                            {/* <Col md={{ size: 6 }}>
                                <div className="chartBox">
                                    <div className="titleHead">
                                        <Row>
                                            <Col md={{ size: 8 }}>

                                                <h5>Avg. score for {subCompetenciesDataArrayAPI.length} competencies assessed within <strong>{this.state.competencyName}</strong></h5>

                                            </Col>
                                            <Col style={{ textAlign: 'right' }} md={{ size: 4 }}>

                                                <select className="customDropDown" style={{ top: 0 }} onChange={e => {
                                                    // this.updateCompetency(branches[branchIndex].mainCompetencies[e.target.value].name, e.target.value);
                                                    debugger;
                                                    this.updateCompetencyFromDropDown(e.target.value)
                                                }}>
                                                    {
                                                        mainCompetency.map((comp, key) =>
                                                            <option key={key} value={key} selected={key == this.state.competencyIndex}>{comp.competency_name}</option>
                                                        )
                                                    }
                                                </select>
                                            </Col>
                                        </Row>
                                    </div>
                                    <div style={{ height: subCompetenciesDataArrayAPI.length > 15 ? '840px' : '440px', position: 'relative' }}>
                                        {
                                            subCompetenciesDataArrayAPI.length == 0 ?
                                                <div className="customSpinnerChart">
                                                    <Spinner style={{ width: '3rem', height: '3rem' }} size="lg" color="light" />
                                                </div>
                                                :
                                                <ResponsiveBar
                                                    data={subCompetenciesDataArrayAPI}
                                                    keys={['score']}
                                                    indexBy="value"
                                                    margin={{ top: 30, right: 40, bottom: 60, left: 400 }}
                                                    padding={0.2}
                                                    innerPadding={5}
                                                    label={d => `${d.value}%`}
                                                    // onClick={e => this.barChartClicked(e)}
                                                    groupMode="stacked"
                                                    // colors={{ scheme: 'paired' }}
                                                    colors={["#d63333", "#f706d5"]}
                                                    layout="horizontal"
                                                    enableGridY={false}
                                                    defs={[
                                                        {
                                                            id: 'dots',
                                                            type: 'patternDots',
                                                            background: 'inherit',
                                                            color: '#38bcb2',
                                                            size: 4,
                                                            padding: 1,
                                                            stagger: true
                                                        },
                                                        {
                                                            id: 'lines',
                                                            type: 'patternLines',
                                                            background: 'inherit',
                                                            color: '#eed312',
                                                            rotation: -45,
                                                            lineWidth: 6,
                                                            spacing: 10
                                                        }
                                                    ]}
                                                    fill={[
                                                        {
                                                            match: {
                                                                id: 'fries'
                                                            },
                                                            id: 'dots'
                                                        },
                                                        {
                                                            match: {
                                                                id: 'sandwich'
                                                            },
                                                            id: 'lines'
                                                        }
                                                    ]}
                                                    borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                                                    axisTop={null}
                                                    axisRight={null}
                                                    axisBottom={{
                                                        tickSize: 5,
                                                        tickPadding: 5,
                                                        tickRotation: 0,
                                                        legend: '',
                                                        legendPosition: 'middle',
                                                        legendOffset: 50
                                                    }}
                                                    axisLeft={{
                                                        tickSize: 5,
                                                        tickPadding: 5,
                                                        tickRotation: 0,
                                                        legend: '',
                                                        legendPosition: 'middle',
                                                        legendOffset: -60
                                                    }}
                                                    labelSkipWidth={12}
                                                    labelSkipHeight={12}
                                                    labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                                            
                                                    animate={true}
                                                    motionStiffness={90}
                                                    motionDamping={15}
                                                />
                                        }

                                    </div>
                                </div>
                            </Col> */}

                            {/* <Col md={{ size: 6 }}>
                                <div className="chartBox">

                                    <div className="titleHead">
                                        <Row>
                                            <Col md={{ size: 8 }}>

                                                <h5>People assessed for {subCompetenciesDataArrayCountAPI.length} sub-competencies within <strong>{this.state.competencyName}</strong></h5>

                                            </Col>
                                            <Col style={{ textAlign: 'right' }} md={{ size: 4 }}>

                                                <select className="customDropDown" style={{ top: 0 }} onChange={e => {
                                                    // this.updateCompetency(branches[branchIndex].mainCompetencies[e.target.value].name, e.target.value);
                                                    this.updateCompetencyFromDropDown(e.target.value)
                                                }}>
                                                    {
                                                        mainCompetency.map((comp, key) =>
                                                            <option key={key} value={key} selected={key == this.state.competencyIndex}>{comp.competency_name}</option>
                                                        )
                                                    }
                                                </select>
                                            </Col>
                                        </Row>
                                    </div>

                                    <div style={{ height: subCompetenciesDataArrayCountAPI.length > 15 ? '840px' : '440px', position: 'relative' }}>
                                        {
                                            subCompetenciesDataArrayCountAPI.length == 0 ?
                                                <div className="customSpinnerChart">
                                                    <Spinner style={{ width: '3rem', height: '3rem' }} size="lg" color="light" />
                                                </div>
                                                :
                                                <ResponsiveBar
                                                    data={subCompetenciesDataArrayCountAPI}
                                                    keys={['score']}
                                                    indexBy="value"
                                                    margin={{ top: 30, right: 40, bottom: 60, left: 400 }}
                                                    padding={0.2}
                                                    innerPadding={5}
                                                    label={d => `${d.value}`}
                                                    // onClick={e => this.barChartClicked(e)}
                                                    groupMode="stacked"
                                                    // colors={{ scheme: 'paired' }}
                                                    colors={["#1f78b4", "#f706d5"]}
                                                    layout="horizontal"
                                                    enableGridY={false}
                                                    defs={[
                                                        {
                                                            id: 'dots',
                                                            type: 'patternDots',
                                                            background: 'inherit',
                                                            color: '#38bcb2',
                                                            size: 4,
                                                            padding: 1,
                                                            stagger: true
                                                        },
                                                        {
                                                            id: 'lines',
                                                            type: 'patternLines',
                                                            background: 'inherit',
                                                            color: '#eed312',
                                                            rotation: -45,
                                                            lineWidth: 6,
                                                            spacing: 10
                                                        }
                                                    ]}
                                                    fill={[
                                                        {
                                                            match: {
                                                                id: 'fries'
                                                            },
                                                            id: 'dots'
                                                        },
                                                        {
                                                            match: {
                                                                id: 'sandwich'
                                                            },
                                                            id: 'lines'
                                                        }
                                                    ]}
                                                    borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                                                    axisTop={null}
                                                    axisRight={null}
                                                    axisBottom={{
                                                        tickSize: 5,
                                                        tickPadding: 5,
                                                        tickRotation: 0,
                                                        legend: '',
                                                        legendPosition: 'middle',
                                                        legendOffset: 50
                                                    }}
                                                    axisLeft={{
                                                        tickSize: 5,
                                                        tickPadding: 5,
                                                        tickRotation: 0,
                                                        legend: '',
                                                        legendPosition: 'middle',
                                                        legendOffset: -60
                                                    }}
                                                    labelSkipWidth={12}
                                                    labelSkipHeight={12}
                                                    labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                                                    animate={true}
                                                    motionStiffness={90}
                                                    motionDamping={15}
                                                />
                                        }
                                    </div>
                                </div>
                            </Col> */}


                            <Col md={{ size: 12 }}>
                                <div className="chartBox">
                                    <div className="titleHead">
                                        <Row>
                                            <Col md={{ size: 8 }}>
                                                {/* <h5>Candidates' distribution in <strong>{ branches[branchIndex].mainCompetencies[competencyIndex].name }</strong> competency based on Deficient, Matching and Exceeding (DME)</h5> */}
                                                <h5><strong>{branchName}</strong> average score comparison based on Deficient, Matching and Exceeding (DME)</h5>
                                            </Col>
                                            <Col md={{ size: 4 }}>
                                                <div className="quickIcons" style={{ right: '15px' }}>
                                                    <FontAwesomeIcon className="customIcon" icon={faCalendarCheck} size="1x" onClick={e => this.toggleModalInfo()} />
                                                    <FontAwesomeIcon className="customIcon" icon={faComment} size="1x" onClick={e => this.toggleModalChat()} />
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>

                                    {/* Here we'll call component for DME profile */}
                                    {
                                        // this.state.respondentsCatogorizeDMEData && this.state.respondentsCatogorizeDMEData != '' ?
                                        <DMEProfileCompare
                                            // data={this.state.respondentsCatogorizeDMEData}
                                            // c_competency={ branches[branchIndex].mainCompetencies[competencyIndex].name }
                                            // c_branch={branchName}
                                            allData={{
                                                branches: branches,
                                                allBranches: allBranches,
                                                branchIndex: branchIndex,
                                                divisionIndex: divisionIndex,
                                                sectionIndex: sectionIndex,
                                                competencyIndex: competencyIndex,
                                                selectedBranchIdValue: this.state.selectedBranchIdValue,
                                                branchName: this.state.branchName,
                                                allDivisions: this.state.allDivision19,
                                                allSections: this.state.allSections19,
                                                dmeByDivision: this.props.dmeByDivision,
                                                dmeBySection: this.props.dmeBySection,
                                                mainCompetency: this.state.mainCompetency,
                                                dmeCategorised: this.props.dmeCategorised,
                                                selectedYear: this.state.selectedYear,
                                                divisionHideGraph: this.state.divisionHideGraph,
                                                sectionHideGraph: this.state.sectionHideGraph
                                                // divisionName : this.state.allDivisions[0].DIVISION
                                            }}
                                        />
                                        // : null
                                    }

                                </div>
                            </Col>


                            <Col md={{ size: 12 }} style={{ display: 'none' }}>
                                <div className="chartBox">
                                    <div className="titleHead">
                                        <Row>
                                            <Col md={{ size: 7 }}>
                                                <h5>Candidates of {branchName} were evaluated on each of the following Archetypes</h5>
                                            </Col>
                                            <Col style={{ textAlign: 'right' }} md={{ size: 5 }}>

                                            </Col>
                                        </Row>
                                    </div>
                                    <div>
                                        <Row style={{ padding: '30px 50px' }}>

                                            <Col md={{ size: 3 }}>
                                                <Row>
                                                    {
                                                        this.state.archetypesList.map((archetype, key) =>
                                                            <Col key={key} md={{ size: 6 }} style={{ padding: 0 }}>
                                                                <div className="archetypeNameBox" id={"tooltip_" + key}>
                                                                    {
                                                                        archetype.name
                                                                    }
                                                                </div>
                                                                <Tooltip placement="right" isOpen={this.state.tooltipOpen[key]} target={"tooltip_" + key} toggle={e => this.toggleTooltip(key)} innerClassName="customTooltip">
                                                                    <p style={{ textAlign: 'left', padding: '10px', background: '#d63333', fontSize: '1rem' }}>
                                                                        {
                                                                            archetype.desc
                                                                        }
                                                                    </p>
                                                                </Tooltip>
                                                            </Col>
                                                        )
                                                    }
                                                </Row>
                                            </Col>

                                            <Col md={{ size: 9 }}>
                                                <Row style={{ padding: '0' }} className="competencyFramworkChartBox smallFont">
                                                    <Col md={{ size: 12 }} style={{ paddingRight: 0 }}>
                                                        <p style={{ color: '#7ba2dc', textAlign: 'left', padding: '12px 20px 0px 20px' }}>
                                                            The pattern of Archetypes across {branchName} can be seen in the following representation
                                  </p>
                                                        {
                                                            this.state.archetypesTreeMapData && this.state.archetypesTreeMapData != '' ?
                                                                <div style={{ height: 'calc(82vh)' }}>
                                                                    <ResponsiveTreeMap
                                                                        root={this.state.archetypesTreeMapData}
                                                                        identity="name"
                                                                        value="totalSample"
                                                                        tile="resquarify"
                                                                        innerPadding={2}
                                                                        outerPadding={2}
                                                                        margin={{ top: 10, right: 0, bottom: 10, left: 10 }}
                                                                        label={(e) => { return e.name }}
                                                                        // labelFormat=".0s"
                                                                        labelSkipSize={10}
                                                                        labelTextColor={{ theme: 'background' }}
                                                                        colors={["#0544a2", "#020a15", "#a6cee3"]}
                                                                        // colors={{ scheme: 'purpleRed_green' }}
                                                                        borderColor={{ from: 'color', modifiers: [['darker', 0.3]] }}
                                                                        animate={true}
                                                                        motionStiffness={90}
                                                                        motionDamping={11}
                                                                    />
                                                                </div>
                                                                : null
                                                        }
                                                    </Col>
                                                </Row>
                                            </Col>

                                        </Row>



                                        <Row>

                                            <Col md={{ size: 10, offset: 1 }}>
                                                <p style={{ color: '#7ba2dc', textAlign: 'center', padding: '15px 20px 0px 20px' }}>
                                                    The following framework represents the distribution of each Archetype across the Divisions in {branchName}.
                              </p>
                                            </Col>

                                            <Col md={{ size: 12 }}>
                                                <div style={{ height: '500px', position: 'relative' }}>
                                                    {
                                                        archetypesDataChart.data && archetypesDataChart.data.length ?
                                                            <ResponsiveHeatMap
                                                                data={archetypesDataChart.data}
                                                                keys={archetypesDataChart.keys}
                                                                indexBy={archetypesDataChart.indexBy}
                                                                margin={{ top: 120, right: 60, bottom: 20, left: 280 }}
                                                                forceSquare={true}
                                                                axisTop={{ orient: 'top', tickSize: 15, tickPadding: 10, tickRotation: -40, legend: 'Archetypes', legendOffset: -110, legendPosition: 'middle' }}
                                                                axisRight={null}
                                                                axisBottom={null}
                                                                axisLeft={{
                                                                    orient: 'left',
                                                                    tickSize: 10,
                                                                    tickPadding: 5,
                                                                    tickRotation: 0,
                                                                    legend: 'Divisions',
                                                                    legendPosition: 'middle',
                                                                    legendOffset: -230
                                                                }}
                                                                cellOpacity={1}
                                                                cellBorderColor={{ from: 'color', modifiers: [['darker', 0.4]] }}
                                                                labelTextColor={{ from: 'color', modifiers: [['darker', 1.8]] }}
                                                                defs={[
                                                                    {
                                                                        id: 'lines',
                                                                        type: 'patternLines',
                                                                        background: 'inherit',
                                                                        color: 'rgba(0, 0, 0, 0.1)',
                                                                        rotation: -45,
                                                                        lineWidth: 4,
                                                                        spacing: 7
                                                                    }
                                                                ]}
                                                                fill={[{ id: 'lines' }]}
                                                                animate={true}
                                                                motionStiffness={80}
                                                                motionDamping={9}
                                                                hoverTarget="cell"
                                                                cellHoverOthersOpacity={0.25}
                                                            />
                                                            : null
                                                    }
                                                </div>
                                            </Col>

                                        </Row>
                                    </div>

                                </div>
                            </Col>







                        </Row>
                    </Container>
                </div>

                {/* <div className={this.state.branchesListToggle ? "citiesListBarContainer active" : 'citiesListBarContainer'}>
                    <Container fluid>
                        <Row style={{ textAlign: 'left' }}>
                            {
                                this.state.isBranchesLoading ?
                                    <div className="customSpinnerChart">
                                    

                                        <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                                    </div>
                                    :
                                    <BranchesListSidebar selectedBranchId={selectedBranchIdValue} ></BranchesListSidebar>
                            }

                        </Row>
                    </Container>

                    <Button onClick={e => this.toggleBranchesBar()} className={this.state.branchesListToggle ? "citiesListBarSwitch opened" : "citiesListBarSwitch"} color="primary">
                        {
                            this.state.branchesListToggle ?
                                <FontAwesomeIcon className="iconHere" icon={this.state.branchesListToggle ? faTimes : faBars} size="1x" />
                                :
                                <span>change<br />branch</span>
                        }
                    </Button>
                </div> */}

                <div className={this.state.integratedFormToggle ? "integratedFormSideBar active" : 'integratedFormSideBar'}>
                    {
                        this.state.formSource !== "" ?
                            <iframe src={this.state.formSource}></iframe>
                            : null
                    }

                    <Button onClick={e => this.closeModalForm()} className={this.state.integratedFormToggle ? this.state.integratedFormId == "ODY5Mg==" ? "citiesListBarSwitch green opened" : "citiesListBarSwitch yellow opened" : "citiesListBarSwitch"} color="primary">
                        <FontAwesomeIcon className="iconHere" icon={faTimes} size="1x" />
                    </Button>

                </div>

                {/* <Footer /> */}
            </div>
        );

    }
}

AllBranchesStatsCompare.propTypes = {
    getAllBranches: PropTypes.func.isRequired,
}

const mapStatesToProps = state => ({
    switchedBranch: state.webRed.switchedBranch,
    totalPeopleAssest: state.webRed.totalPeopleAssestCompareAllBranches,
    mainCompetencies: state.webRed.mainCompetencies,
    getSubCompetency: state.webRed.getSubCompetency,
    getAllCompetencyAvg: state.webRed.competencyAvgCompareAllBranches,
    divisionsAndSections: state.webRed.divisionsAndSections,
    competencyByDivision: state.webRed.competencyByDivisionCompareAllBranches,
    competencyBySection: state.webRed.competencyBySectionCompareAllBranches,
    dmeByDivision: state.webRed.dmeByDivisionCompare,
    dmeBySection: state.webRed.dmeBySectionCompare,
    dmeCategorised: state.webRed.dmeCategorisedCompareAllBranches,
    candidateDistribution: state.webRed.candidateDistribution,
    competencyYear: state.webRed.competencyYear
})

export default connect(mapStatesToProps, { getAllBranches, getTotalPeopleAssesstCompareAllBranches, updateBranchAction, getMainCompetencyList, getSubCompetencyRecord, getCompetencyAvgCompareAllBranch, getAllDivisions, compareCompetencyByDivisionRecordAllBranches, compareCompetencyBySectionRecordAllBranches, compareDivisionDMERecordAllBranches, compareSectionDMERecordAllBranches, compareCategorisedDMERecordAllBranches, candidateDistributionRecord, triggerYearChange, compareBranchAvgDMERecordAllBranches })(AllBranchesStatsCompare);