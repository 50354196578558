import React, { Component } from "react";
import Header from './../Header';
// import Footer from "./../Footer";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle, faCheckCircle } from '@fortawesome/free-solid-svg-icons';

import './../../../public/scss/login.scss';

import { sendValidateEmpId } from './../../../actions/webRedActions';
import { autoFocusNextField } from './../../../actions/utility';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// import userData from './../userData';

import {
    Button,
    Container,
    Row,
    Col,
    Media,
    Form, FormGroup, Label, Input, FormText,
    Spinner
  } from "reactstrap";

// import readXlsxFile from 'read-excel-file';

// import { BrowserRouter as Router, Route, Link } from "react-router-dom";

class EmployeedIdForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            
            // userInfo : {
            //     employee_id : '01-1234'
            // }, // dummy user data
            employeeId : ["", "", "-", "", "", "", ""],
            employeeIdString : "",
            employeeIdError : "",
            isLoading: false,
            // enableNextBtn : false

        };
    }
  
    componentDidMount() {

        autoFocusNextField("boxFieldForm"); // auto focus utility method called
        const emppid = localStorage.getItem('emppid');
        if (emppid) {
          // Initialize state with the localStorage value if it exists
          this.setState({
            employeeId: emppid.split('') // Split the string into an array for individual inputs
          });
          this.setState({
            employeeIdString: emppid// Split the string into an array for individual inputs
          });
          
        }

    }

    componentWillReceiveProps(nextProps) {
        // Check if triggerNextPinSuccess has changed
        if (this.props.triggerNextPinSuccess !== nextProps.triggerNextPinSuccess) {
            // Ensure nextProps.triggerNextPinSuccess and nextProps.triggerNextPinSuccess.data exist
            if (nextProps.triggerNextPinSuccess && nextProps.triggerNextPinSuccess.data) {
                if (nextProps.triggerNextPinSuccess.data.status === "error") {
                    this.setState({
                        isLoading: false,
                        employeeIdError: nextProps.triggerNextPinSuccess.data.message || 'An error occurred'
                    });
                }
            } else {
                console.log("nextProps.triggerNextPinSuccess or data is undefined");
            }
        }
    }

        handleChange = (event, index) => {
            event.preventDefault();
            const { name, value } = event.target;
        
            // Clone the employeeId array to avoid mutating state directly
            let employeeIdTemp = [...this.state.employeeId];
            employeeIdTemp[index] = value;
        
            // Update the employeeId in the state
            this.setState({
                employeeId: employeeIdTemp
            }, () => {
                // Convert the employeeId array into a string
                const emp_id = this.convertEmpIdToString(this.state.employeeId);
                //console.log('emp_id:---- ', emp_id);
        
                // Update the employeeIdString state with the converted value
                this.setState({
                    employeeIdString: emp_id
                }, () => {
                    // If all inputs are filled (7 characters), perform validation or further actions
                    if (emp_id.length === 7) {
                        // Call your verification or action here
                    }
                });
            });
        
            // Clear any error message if present
            if (this.state.employeeIdError !== "") {
                this.setState({
                    employeeIdError: ""
                });
            }
        };
    

    handleSubmit = (event) => {
        event.preventDefault();
        
    }

    convertEmpIdToString = (empId) => {
        var stringEmpId = "";
        empId.map(val => {
            if(val && val != "" && val != undefined){
                stringEmpId += val;
            }
        })

        return stringEmpId;
    }

    render() {

    const {employeeId, employeeIdError, employeeIdString, isLoading} = this.state;

    return (
      <div className="EmployeedIdForm" style={{padding: '10vh 0', textAlign: 'center'}}>

        <h3>Welcome to PAGCOR's Assessments</h3>
        <hr />
        <Row>
            <Col>
                <Form>
                    <p>What is your employee ID NUMBER?</p>
                    <FormGroup className="boxFieldForm">
                        
                        <Input type="text" bsSize="lg" maxLength="1" name="employeeId" id="employeeId1"
                            value={employeeId[0]}
                            onChange={evt => this.handleChange(evt, 0)} />

                        <Input type="text" bsSize="lg" maxLength="1" name="employeeId" id="employeeId2" 
                            value={employeeId[1]}
                            onChange={evt => this.handleChange(evt, 1)} />

                        <Input type="text" bsSize="lg" value="-" maxLength="1" name="dash" id="dash" disabled />

                        <Input type="text" bsSize="lg" maxLength="1" name="employeeId" id="employeeId3"
                            value={employeeId[3]}
                            onChange={evt => this.handleChange(evt, 3)} />

                        <Input type="text" bsSize="lg" maxLength="1" name="employeeId" id="employeeId4"
                            value={employeeId[4]}
                            onChange={evt => this.handleChange(evt, 4)} />

                        <Input type="text" bsSize="lg" maxLength="1" name="employeeId" id="employeeId5"
                            value={employeeId[5]}
                            onChange={evt => this.handleChange(evt, 5)} />

                        <Input type="text" bsSize="lg" maxLength="1" name="employeeId" id="employeeId6"
                            value={employeeId[6]}
                            onChange={evt => this.handleChange(evt, 6)} />

                    </FormGroup>
                    {
                        employeeIdError && employeeIdError != "" ?
                            <p style={{color: 'red'}}>
                                <FontAwesomeIcon icon={faTimesCircle} size="1x" style={{marginRight: '10px'}} />
                                {employeeIdError}
                            </p>
                        : null
                    }
                </Form>
            </Col>
        </Row>
        <Row>
            <Col style={{textAlign: 'center'}}>
                {
                    employeeIdString.length == 7 ?
                        <Button style={{margin: '30px 0 0 0'}} color={"primary"} size="lg"
                            onClick={(e) => {
                                this.setState({
                                    isLoading : true
                                }, () => {
                                    // send and validate employee id action to send verification code to user for first time
                                    this.props.sendValidateEmpId(this.state.employeeIdString, false);
                                })
                            }}
                            disabled={isLoading}
                        >
                            Next
                            {
                                isLoading ? <Spinner style={{margin: '4px 0px 4px 10px'}} color="light" size="sm" /> : null
                            }
                        </Button>
                    : null
                }
            </Col>
        </Row>
      </div>
    );
  }
}

EmployeedIdForm.propTypes = {
    // triggerNextToSecurityPin: PropTypes.func.isRequired,
    sendValidateEmpId: PropTypes.func.isRequired
}

const mapStatesToProps = state => ({
    triggerNextPinSuccess : state.webRed.triggerNextPinSuccess
})

export default connect( mapStatesToProps, { sendValidateEmpId })( EmployeedIdForm );