import React, { Component } from "react";
// import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
// import {browserHistory} from "react-router";
import "./../../../public/scss/talentMap_statistics.scss";
// import Header from '../../website/Header';
// import Footer from "../../website/Footer";

// import store from './../../store';
import PropTypes from 'prop-types';
import { updateBranchAction } from '../../../actions/webRedActions';
// import { validateEmailAddress } from './../../actions/utility';
import { connect } from 'react-redux';

import {
    Container,
    Row,
    Col,
    Button,
    Collapse,
    Spinner,
    FormGroup,
    Input,
    Media,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter
  } from "reactstrap";

  
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import { Link } from "react-router-dom";


class BranchesListSidebar extends Component {

  constructor(props) {
      super(props);

      this.state = {
          isCitiesLoading : true,
          branchUpdateLoader : false,
        //   verifiedCities : [],
          allBranches : [{
            branchid : 0,
            branchname: "ANGELES"
          },{
            branchid : 28,
            branchname: "BACOLOD"
          },{
            branchid : 1,
            branchname: "CEBU"
          },{
            branchid : 2,
            branchname: "DAVAO"
          },{
            branchid : 3,
            branchname: "ILOCOS"
          },{
            branchid : 4,
            branchname: "MALATE"
          },{
            branchid : 5,
            branchname: "MANILA BAY"
          },{
            branchid : 6,
            branchname: "OLONGAPO"
          },{
            branchid : 7,
            branchname: "TAGAYTAY"
          },{
            branchid : 8,
            branchname: "WINFORD"
          },{
            branchid : 9,
            branchname: "SOG2"
          },{
            branchid : 10,
            branchname: "SOG4"
          },{
            branchid : 11,
            branchname: "SOG5"
          },{
            branchid : 12,
            branchname: "Head Office",
            subBranches : [{
              branchid : 13,
              branchname: "AD"
            },{
              branchid : 14,
              branchname: "ASHD"
            },{
              branchid : 15,
              branchname: "AMLCD"
            },{
              branchid : 16,
              branchname: "ASD"
            },{
              branchid : 17,
              branchname: "BD"
            },{
              branchid : 18,
              branchname: "CRSD"
            },{
              branchid : 20,
              branchname: "CMED"
            },{
              branchid : 21,
              branchname: "CCD"
            },{
              branchid : 22,
              branchname: "CID"
            },{
              branchid : 23,
              branchname: "CPD"
            },{
              branchid : 24,
              branchname: "CSSCD"
            },{
              branchid : 25,
              branchname: "CSD"
            },{
              branchid : 26,
              branchname: "DPO"
            },{
              branchid : 27,
              branchname: "ED"
            },{
              branchid : 28,
              branchname: "FMED"
            },{
              branchid : 29,
              branchname: "FMD"
            },{
              branchid : 30,
              branchname: "GLDD"
            },{
              branchid : 31,
              branchname: "HRCOE"
            },{
              branchid : 32,
              branchname: "HROD"
            },{
              branchid : 33,
              branchname: "ITD"
            },{
              branchid : 34,
              branchname: "IAD"
            },{
              branchid : 35,
              branchname: "IGOD"
            },{
              branchid : 36,
              branchname: "IVD"
            },{
              branchid : 37,
              branchname: "LG"
            },{
              branchid : 38,
              branchname: "LSD"
            },{
              branchid : 39,
              branchname: "LMD"
            },{
              branchid : 40,
              branchname: "MD"
            },{
              branchid : 41,
              branchname: "OCCEO"
            },{
              branchid : 42,
              branchname: "OGLD"
            },{
              branchid : 43,
              branchname: "PASED"
            },{
              branchid : 44,
              branchname: "PD"
            },{
              branchid : 45,
              branchname: "SSD"
            },{
              branchid : 46,
              branchname: "SG"
            },{
              branchid : 47,
              branchname: "SMD"
            },{
              branchid : 48,
              branchname: "SURV"
            },{
              branchid : 49,
              branchname: "TSD"
            },{
              branchid : 50,
              branchname: "TD"
            }]
          }],
          filteredBranches : [],
          selectedBranchId : this.props.selectedBranchId,
          selectedBranch : this.props.selectedBranch,
          branchSearchText : "",
          isOpenBranch : false
      };

  }

  componentDidMount() {

  }

  setIsOpenBranch = () => {
    this.setState({
      isOpenBranch : !this.state.isOpenBranch
    })
  }

  updateBranch = (branch, branchId) => {
    // console.log('checking the component changes Faheem');
    // if(!this.state.branchUpdateLoader) { // if city data is already populated so they

      this.setState({
        selectedBranch : branch,
        selectedBranchId : branchId,
        // selectedBranch : branch // mapping region with city also as both are same things now
      },
      () => {

        // this.setState({
        //   branchUpdateLoader : true
        // }, () => {
          //call updateBranch action so that it'll reflect the branch change in main component
          this.props.updateBranchAction(this.state.selectedBranchId, this.state.selectedBranch);
          
        //   //call selected city/region gender data
        //   this.props.getGenderClassificationAvg(this.state.selectedSkillId, this.state.selectedRegion);
          
        //   //call selected city/region age data
        //   this.props.getAgeClassificationAvg(this.state.selectedRegion);

        //   //call selected city/region position data
        //   this.props.getPositionClassificationAvg(this.state.selectedRegion);

        //   //call selected city/region medium data
        //   this.props.getMediumsClassificationAvg(this.state.selectedRegion);

        //   //call selected city/region transportation data
        //   this.props.getTransportClassificationAvg(this.state.selectedRegion);

        //   // calls an action for getting skills/situations against each specific city.
        //   this.props.getSpecificCitySkills(this.state.selectedRegion, this.state.selectedCityId);
        
        // })
        
      })

    // }

  }


  componentWillReceiveProps(nextProps, thisProps) {
    if(this.props.selectedBranchId != nextProps.selectedBranchId) {
      this.setState({
        selectedBranchId : nextProps.selectedBranchId
      }, () => {
        console.log("selectedBranchId: " + this.state.selectedBranchId)
      })
    }

    // if(this.props.selectedBranch != nextProps.selectedBranch) {
    //   this.setState({
    //     selectedBranch : nextProps.selectedBranch
    //   }, () => {
    //     console.log("selectedBranch: " + this.state.selectedBranch)
    //   })
    // }

    
    if(this.props.allBranchesListData != nextProps.allBranchesListData) {
      if(nextProps.allBranchesListData && nextProps.allBranchesListData.result && nextProps.allBranchesListData.result.length) {
        this.setState({
          allBranches : nextProps.allBranchesListData.result.map(branch => { return {
                          branchid : branch.branch_id,
                          branchname : branch.branch_name
                        }}),
          isCitiesLoading : false
        }, () => {
          debugger;
          this.props.updateBranchAction(nextProps.allBranchesListData.result[0].branch_id, nextProps.allBranchesListData.result[0].branch_name);
          console.log(this.state.allBranches)
        })
      }

    }


  }

  filterBranches = (e) => {
    this.setState({
      branchSearchText : e.target.value
    }, () => {
        
      var branchFilterArray = [];

      this.state.allBranches.map(branch => {
        
        var bName = branch.branchname.toLowerCase();

        if(bName.match(this.state.branchSearchText)) {
            branchFilterArray.push(branch);
        }

      })

      this.setState({
        filteredBranches : branchFilterArray
      }, () => {
        console.log(this.state.filteredBranches);
      })

    })
  }
  
    
  render() {

    let branches = this.state.filteredBranches.length ? this.state.filteredBranches : this.state.allBranches;
      
    return (
      <Col md={{ size: 12 }}>
        <h6 style={{margin: '10px 0', color: '#fff', borderBottom: '1px #23344c solid', padding: '10px 3px', fontSize: '18px'}}>View all branches data:</h6>
        <Link to="/pagcor/AllBranchesStats" style={{ textDecoration: 'none' }}>
            <Button className="allBranchesButton" block>All branches data</Button>
        </Link>

        <h6 style={{margin: '10px 0', color: '#fff', borderBottom: '1px #23344c solid', padding: '10px 3px', fontSize: '18px'}}>Select a branch:</h6>
        
        <FormGroup>
            <Input type="text" name="citySearchField" id="citySearchField" value={this.state.branchSearchText} placeholder="Quick search" onChange={(e) => this.filterBranches(e)}/>
        </FormGroup>
        {this.state.isCitiesLoading ? <div className="text-center"><Spinner className="" size="lg" color="light" /> </div> :
        <div className="mainCitiesBox" style={{color: '#fff', position: 'relative'}}>
          {
            this.state.branchSearchText.length && !this.state.filteredBranches.length ?
              <span style={{textAlign: 'center', width: '100%', fontSize: '12px'}}>we couldn't find any matches for "{this.state.branchSearchText}"</span>
            :
            branches.map((branch, key) => {

              if(branch.branchname == "Head Office") {

                return (
                  <div style={{position: 'relative'}}>
                    <span style={{ opacity : '1', color : "#fff" }} key={key} className={'cityHere'}
                      onClick={() => this.setIsOpenBranch()}
                    >
                      {branch.branchname}
                    </span>

                    <FontAwesomeIcon
                      style={{
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        color: "#fff",
                        cursor : "pointer"
                      }}
                      className="toggleIconHere"
                      icon={this.state.isOpenBranch ? faMinus : faPlus} size="1x"
                        onClick={() => this.setIsOpenBranch()}
                      />

                    <Collapse isOpen={this.state.isOpenBranch}>
                      {

                        branch.subBranches && branch.subBranches.length && branch.subBranches.map((dept, key1) => {
                        
                          var isBranch = false;
                          if(branch.branchid == this.state.selectedBranchId){ // compare with skill id from url
                            isBranch = true;
                          }

                          return (
                            <span style={{ opacity : '1', color : "#fff", paddingLeft: '50px' }} key={key1} className={isBranch ? 'cityHere highlighted' : 'cityHere'}>
                              {dept.branchname}
                            </span> 
                          )
                        })
                        
                      }
                    </Collapse>
                  </div>
                )

              } else {

                var isBranch = false;
                if(branch.branchid == this.state.selectedBranchId){ // compare with skill id from url
                  isBranch = true;
                }

                return (
                  <span style={{ opacity : '1', color : "#fff" }} key={key} className={isBranch ? 'cityHere highlighted' : 'cityHere'} 
                  onClick={(e) => this.updateBranch(branch.branchname, branch.branchid)}
                  
                  >
                    {/* {
                      isBranch && this.state.branchUpdateLoader ? 
                        <Spinner className="citySpinner" size="sm" color="light" />
                      : null
                    } */}
                    {branch.branchname}
                  </span>
                )

              }

            })
          }  
        </div>
  }
      </Col>
    );
    
  }
}

BranchesListSidebar.propTypes = {
  updateBranchAction: PropTypes.func.isRequired,
}

const mapStatesToProps = state => ({
  allBranchesListData: state.webRed.allBranchesListData
})

export default connect(mapStatesToProps, { updateBranchAction })(BranchesListSidebar);