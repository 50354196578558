import React, { Component } from "react";
// import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
// import {browserHistory} from "react-router";
import "./../../../public/scss/pagcorDashboard_statistics.scss";
// import Header from '../../website/Header';
// import Footer from "../../website/Footer";

// import store from './../../store';
import PropTypes from 'prop-types';
import { branchAvgDMERecord, triggerYearChange, candidateDistributionRecord, categorisedDMERecord, sectionDMERecord, divisionDMERecord, competencyBySectionRecord, competencyByDivisionRecord, getAllDivisions, getAllCompetencyAvgRecord, getAllBranches, getTotalPeopleAssesst, updateBranchAction, getMainCompetencyList, getSubCompetencyRecord, getAllSkills, getAllCitiesData, getDemographicAvg, getGenderClassificationAvg, getAgeClassificationAvg, getPositionClassificationAvg, getMediumsClassificationAvg, getTransportClassificationAvg } from './../../../actions/webRedActions';
// import { validateEmailAddress } from './../../actions/utility';
import { connect } from 'react-redux';

import BranchesListSidebar from './BranchesListSidebar';
// import DistributionAndComparisonTopCitiesComponent from './DistributionAndComparisonTopCitiesComponent';
import DMEProfileComponent from './DMEProfileComponent';

import {
  Container,
  Row,
  Col,
  Button,
  Spinner,
  FormGroup,
  Input,
  Media,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  Tooltip
} from "reactstrap";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMale, faFemale, faTimes, faBars, faSchool, faInfo, faComment, faCalendarCheck, faCalendar } from '@fortawesome/free-solid-svg-icons';

import Switch from "react-switch";
import { ResponsiveBar } from '@nivo/bar';
import { ResponsiveBubble } from '@nivo/circle-packing';
import { ResponsivePie } from '@nivo/pie';
import { ResponsiveRadar } from '@nivo/radar';
import { ResponsiveTreeMap } from '@nivo/treemap';
import { ResponsiveHeatMap } from '@nivo/heatmap'

import branchicon from './../../../public/images/branches/branchicon.png';
import devisionicon from './../../../public/images/branches/divisionIcon.png';
import sectionicon from './../../../public/images/branches/sectionIcon.png';

import branchData1 from './branchData1';
import branchData2 from './branchData2';
import archetypesData from './architypesData';
import { Link } from "react-router-dom";


// import $ from 'jquery'; 

class PagcorStatsComponent extends Component {

  constructor(props) {
    super(props);

    this.state = {

      branchIndex: 0,
      competencyIndex: 0,
      divisionIndex: 0,
      sectionIndex: 0,
      branches: [
        branchData1,
        branchData2
      ],
      allBranches: {
        mainCompetencies: [{
          name: "CORE",
          totalAverage: 84.3
        }, {
          name: "ORGANIZATIONAL",
          totalAverage: 90
        }, {
          name: "LEADERSHIP",
          totalAverage: 95.3
        }, {
          name: "TECHNICAL",
          totalAverage: 93
        }]
      },
      archetypesList: archetypesData,
      archetypesDataChart: [],
      compComparisonData: {
        keys: [],
        data: []
      },
      compComparisonDataDivisionWise: {
        keys: [],
        data: []
      },
      compComparisonDataDivisionWiseTempDivisionArray: [], // temporary random scores array for persistency while switching in different divisions to show in sections comparison chart
      compComparisonDataSectionWise: {
        keys: [],
        data: []
      },
      divisionSectionData: {
        "name": "loading",
        "color": "hsl(241, 70%, 50%)",
        "sample": 1
      },
      respondentsCatogorizeDMEData: '',
      competencyFrameworkData: '',
      branchesListToggle: true,
      archetypesTreeMapData: '',
      tooltipOpen: [],



      branchesListData: [],
      selectedBranchIdValue: '',



      selectedSkill: this.props.match.params.skillId,
      selectedSkillId: 0,

      situations: [''],
      situationsAvg: [],
      situationsAvgSpecificCity: [],
      userTypeDataAllRegions: '',
      userTypeDataSpecificRegion: '',
      genderDataAllRegions: '',
      genderDataSpecificRegion: '',
      ageDataAllRegions: '',
      ageDataSpecificRegion: '',
      positionsDataAllRegions: '',
      positionsDataSpecificRegion: '',
      mediumsDataAllRegions: '',
      mediumsDataSpecificRegion: '',
      transportDataAllRegions: '',
      transportDataSpecificRegion: '',
      userTypeScoresData: {
        keys: [],
        data: []
      },
      skillUpdateLoader: false,
      GenderUpdateLoader: false,
      userTypeScoresDataPie1: [[], []],
      userTypeScoresDataPie1Loc: 0,
      genderScoresData: [],
      genderScoresDataLoc: 0,
      ageScoreDataLoc: 0,
      userTypeAgeScoresData: [{
        keys: [],
        data: []
      }, {
        keys: [],
        data: []
      }],
      positionScoreDataLoc: 0,
      userTypePositionsScoresData: [{
        keys: [],
        data: []
      }, {
        keys: [],
        data: []
      }],
      mediumScoreDataLoc: 0,
      userTypeMediumsScoresData: [{
        keys: [],
        data: []
      }, {
        keys: [],
        data: []
      }],
      transportScoreDataLoc: 0,
      userTypeTransportsScoresData: [{
        keys: [],
        data: []
      }, {
        keys: [],
        data: []
      }],
      swarmDataAllRegions: [],
      isBranchesLoading: false,
      allCities: [{
        cityid: 0,
        cityname: "Manila"
      }],
      cityUpdateLoader: false,
      situationsAvgDataLoc: 0,
      citySearchText: '',
      selectedMayer: "Isko Moreno",
      // showModalInfo : false,
      // showModalChat : false,
      integratedFormId: "",
      integratedFormToggle: false,
      formSource: "",
      totalAssesst: 'loading',
      totalDivision: 'loading',
      totalSection: 'loading',
      branchName: "",
      mainCompetency: [],
      isMainCompetencyLoading: true,
      mainCompetencyAverage: "0",
      subCompetency: [],
      arrray: [],
      subCompPerc: "0",
      mapCompetencyonTree: [],
      compComparisonDataApi: {
        keys: [],
        data: []
      },
      allDivisions: [],
      allSections: [],
      compComparisonDataDivisionWiseApi: {
        keys: [],
        data: []
      },
      divisionName: "",
      sectionName: "",
      compComparisonDataSectionWiseApi: {
        keys: [],
        data: []
      },
      competencyName: "",
      divisionSectionDataApi: '',
      dummydivisionSectionDataApi: {
        "name": "Test Branch",
        "color": "hsl(241, 70%, 50%)",
        "children": [{
          "name": "Division 1",
          "children": [{
            "name": "Section 1",
            "children": [{
              "name": "Designation 1",
              "sample": "5"
            },
            {
              "name": "Designation 2",
              "sample": "0"
            }]
          }]
        },
        ]
      },
      subcompetencyLoader: true,
      selectedYear: '2019',
      totalPeopleinSection: 0,
      sectionHideGraph: false,
      divisionHideGraph: false,
      bacolodImg: 'https://banner2.cleanpng.com/20180614/qrv/kisspng-placewell-international-services-corp-bacolod-cit-city-logo-5b2271ccec1ca1.5961857215289840129671.jpg',
      noImg: 'https://ctt.trains.com/sitefiles/images/no-preview-available.png'
    };


  }

  componentDidMount() {
    window.scrollTo(0, 0); // scroll to top after rendering the component

    this.props.getAllBranches(); // get all branches list

    this.averageWithAllBranchesMapping();

    this.averageWithBranchesMappingForDivisions();

    this.averageWithBranchesMappingForSections();

    // this.DMEDataMapping(this.state.branchIndex, this.state.competencyIndex);

    this.devisionSectionDesignationMapping();

    this.competencyFrameworkDataMapping(this.state.branchIndex);

    this.archetypesTreeMapDataMapping();

    this.archetypesDataMapping(this.state.branchIndex);

    //Graph Actions
    // this.props.updateBranchAction(this.state.selectedBranchIdValue, "CF-ANGELES");
    // this.props.getMainCompetencyList(this.state.selectedBranchIdValue , '2019');
    // this.props.getAllDivisions(this.state.selectedBranchIdValue , "2019");
    // this.props.getTotalPeopleAssesst(this.state.selectedBranchIdValue , '2019');
  }

  devisionSectionDesignationMapping = () => {

    const { branches, branchIndex, competencyIndex, allBranches } = this.state;

    // mapping of divisions, sections & designations bubble chart data starts here
    var divisionsArray = [];
    branches[branchIndex].divisions.map((division, key) => { //loop for extracting data for divisions

      var sectionsArray = [];
      division.sections && division.sections.length && division.sections.map((section, key) => {

        var designationsArray = [];
        section.designations && section.designations.length && section.designations.map((designation, key) => {
          designationsArray.push({
            "name": designation.name,
            "sample": designation.count
          })
        })

        sectionsArray.push({
          "name": section.name,
          "children": designationsArray
        })
      })

      divisionsArray.push({
        "name": division.name,
        "children": sectionsArray
      });

    })

    setTimeout(() => {

      this.setState({
        divisionSectionData: {
          "name": branches[branchIndex].name,
          "color": "hsl(241, 70%, 50%)",
          "children": divisionsArray
        }
      },
        () => {
          console.log(this.state.divisionSectionData);
        })

    }, 500);

    // mapping of divisions, sections & designations bubble chart data ends here
  }

  averageWithAllBranchesMapping = () => {

    // mapping data for comparison all branches chart

    const { branches, branchIndex, competencyIndex, allBranches, divisionIndex, sectionIndex } = this.state;

    var dataArray = [];
    branches[branchIndex].mainCompetencies.map((comp, key) => {
      dataArray.push({
        "type": comp.name,
        [branches[branchIndex].name]: Math.round(comp.totalAverage),
        "All Branches": Math.round(allBranches.mainCompetencies[key].totalAverage)
      });
    })

    this.setState({
      compComparisonData: {
        keys: ["All Branches", branches[branchIndex].name],
        data: dataArray
      }
    }, () => {
      console.log(this.state.compComparisonData);
    })

  }

  averageWithBranchesMappingForDivisions = () => {

    // mapping data for comparison all branches chart

    const { branches, branchIndex, allBranches, divisionIndex } = this.state;

    var dataArrayDivision = [];
    var tempArrayRandomVal = [];
    branches[branchIndex].mainCompetencies.map((comp, key) => {

      //temperory work done starts here
      var randomValue = this.getRandomAvgScores(75, 95);
      tempArrayRandomVal.push(randomValue);
      //temperory work done stops here

      dataArrayDivision.push({
        "type": comp.name,
        [branches[branchIndex].divisions[divisionIndex].name]: randomValue, // temporary random average score
        [branches[branchIndex].name]: Math.round(comp.totalAverage),
        "All Branches": Math.round(allBranches.mainCompetencies[key].totalAverage)
      });
    })

    this.setState({
      compComparisonDataDivisionWise: {
        keys: ["All Branches", branches[branchIndex].name, branches[branchIndex].divisions[divisionIndex].name],
        data: dataArrayDivision
      }
    }, () => {
      console.log(this.state.compComparisonDataDivisionWise);

      this.setState({
        compComparisonDataDivisionWiseTempDivisionArray: tempArrayRandomVal
      }, () => {
        this.averageWithBranchesMappingForSections(); // reflects the sections chart
      })
    })


  }
  averageWithBranchesMappingForDivisions = () => {

    // mapping data for comparison all branches chart

    const { branches, branchIndex, allBranches, divisionIndex } = this.state;

    var dataArrayDivision = [];
    var tempArrayRandomVal = [];
    branches[branchIndex].mainCompetencies.map((comp, key) => {

      //temperory work done starts here
      var randomValue = this.getRandomAvgScores(75, 95);
      tempArrayRandomVal.push(randomValue);
      //temperory work done stops here

      dataArrayDivision.push({
        "type": comp.name,
        [branches[branchIndex].divisions[divisionIndex].name]: randomValue, // temporary random average score
        [branches[branchIndex].name]: Math.round(comp.totalAverage),
        "All Branches": Math.round(allBranches.mainCompetencies[key].totalAverage)
      });
    })

    this.setState({
      compComparisonDataDivisionWise: {
        keys: ["All Branches", branches[branchIndex].name, branches[branchIndex].divisions[divisionIndex].name],
        data: dataArrayDivision
      }
    }, () => {
      console.log(this.state.compComparisonDataDivisionWise);

      this.setState({
        compComparisonDataDivisionWiseTempDivisionArray: tempArrayRandomVal
      }, () => {
        this.averageWithBranchesMappingForSections(); // reflects the sections chart
      })
    })


  }

  averageWithBranchesMappingForSections = () => {

    const { branches, branchIndex, allBranches, divisionIndex, sectionIndex } = this.state;

    var dataArraySection = [];
    branches[branchIndex].mainCompetencies.map((comp, key) => {
      dataArraySection.push({
        "type": comp.name,
        [branches[branchIndex].divisions[divisionIndex].sections[sectionIndex].name]: this.getRandomAvgScores(75, 95), // temporary random average score,
        [branches[branchIndex].divisions[divisionIndex].name]: this.state.compComparisonDataDivisionWiseTempDivisionArray[key], // temporary random average score
        [branches[branchIndex].name]: Math.round(comp.totalAverage),
        "All Branches": Math.round(allBranches.mainCompetencies[key].totalAverage)
      });
    })

    this.setState({
      compComparisonDataSectionWise: {
        keys: ["All Branches", branches[branchIndex].name, branches[branchIndex].divisions[divisionIndex].name, branches[branchIndex].divisions[divisionIndex].sections[sectionIndex].name],
        data: dataArraySection
      }
    }, () => {
      console.log(this.state.compComparisonDataSectionWise);
    })

  }


  getRandomAvgScores = (min, max) => {
    return parseInt(Math.random() * (max - min) + min);
  }

  getRandomArchetypeValue = (min, max) => {
    return parseInt(Math.random() * (max - min) + min);
  }

  archetypesDataMapping = (this_branchIndex) => {

    const { branches, branchIndex, archetypesList } = this.state;

    console.log(archetypesList)

    var dataArray = [];
    var keysArray = [];
    branches[branchIndex].divisions.map((division, key) => {
      dataArray.push({
        "division": division.name,
        [archetypesList[0].name]: this.getRandomArchetypeValue(10, 25),
        [archetypesList[1].name]: this.getRandomArchetypeValue(1, 10),
        [archetypesList[2].name]: this.getRandomArchetypeValue(10, 25),
        [archetypesList[3].name]: this.getRandomArchetypeValue(1, 10),
        [archetypesList[4].name]: this.getRandomArchetypeValue(1, 10),
        [archetypesList[5].name]: this.getRandomArchetypeValue(1, 10),
        [archetypesList[6].name]: this.getRandomArchetypeValue(10, 25),
        [archetypesList[7].name]: this.getRandomArchetypeValue(1, 10),
        [archetypesList[8].name]: this.getRandomArchetypeValue(1, 10),
        [archetypesList[9].name]: this.getRandomArchetypeValue(1, 10),
        [archetypesList[10].name]: this.getRandomArchetypeValue(1, 10),
        [archetypesList[11].name]: this.getRandomArchetypeValue(1, 10),
        [archetypesList[12].name]: this.getRandomArchetypeValue(1, 10),
        [archetypesList[13].name]: this.getRandomArchetypeValue(1, 10),
        [archetypesList[14].name]: this.getRandomArchetypeValue(10, 25),
        [archetypesList[15].name]: this.getRandomArchetypeValue(10, 25),
        [archetypesList[16].name]: this.getRandomArchetypeValue(10, 25),
        [archetypesList[17].name]: this.getRandomArchetypeValue(1, 10),
        [archetypesList[18].name]: this.getRandomArchetypeValue(1, 10),
        [archetypesList[19].name]: this.getRandomArchetypeValue(1, 10),
        [archetypesList[20].name]: this.getRandomArchetypeValue(10, 25),
        [archetypesList[21].name]: this.getRandomArchetypeValue(1, 10),
        [archetypesList[22].name]: this.getRandomArchetypeValue(10, 25),
        [archetypesList[23].name]: this.getRandomArchetypeValue(10, 25),
        [archetypesList[24].name]: this.getRandomArchetypeValue(10, 25),
        [archetypesList[25].name]: this.getRandomArchetypeValue(1, 10),
        [archetypesList[26].name]: this.getRandomArchetypeValue(1, 10)
      });
    })

    archetypesList.map(archetype => {
      keysArray.push(archetype.name);
    })


    this.setState({
      archetypesDataChart: {
        keys: keysArray,
        data: dataArray,
        indexBy: "division"
      }
    }, () => {
      console.log(this.state.archetypesDataChart);
    })

  }

  competencyFrameworkDataMapping = (branchIndex) => {

    console.log('branchIndex', branchIndex);

    const { branches } = this.state;

    // mapping of competency framework treemap chart data starts here
    var rootName = branches[branchIndex].name;

    var mainCompTemp = [];

    branches[branchIndex].mainCompetencies.map((mainComp, key) => {

      // var subCompTemp = [];
      // var namePrefix = '';
      // if(key == 0)
      //   namePrefix = "C";
      // else if(key == 1)
      //   namePrefix = "O";
      // else if(key == 2)
      //   namePrefix = "L";
      // else
      //   namePrefix = "T";

      // mainComp.subCompetencies.map((subComp, key) => {
      //   subCompTemp.push({
      //     "name" : namePrefix + (key+1),
      //     "fullname" : subComp.name,
      //     "totalSample" : parseInt(subComp.d_total + subComp.m_total + subComp.e_total),
      //   })
      // })
      var totalSample = 0;
      mainComp.subCompetencies.map(subComp => {
        totalSample += parseInt(subComp.d_total + subComp.m_total + subComp.e_total);
      })

      mainCompTemp.push({
        "name": mainComp.name,
        "fullname": mainComp.name + " - Total responses",
        "averageScore": Math.round(mainComp.totalAverage),
        "totalSample": totalSample,
      })

    })

    setTimeout(() => {

      this.setState({
        competencyFrameworkData: {
          // "root" : {
          "name": rootName,
          "fullname": rootName,
          "children": mainCompTemp
          // }
        }
      },
        () => {
          console.log(this.state.competencyFrameworkData);
        })

    }, 500);

    // mapping of competency framework treemap chart data ends here
  }


  archetypesTreeMapDataMapping = () => {

    const { archetypesList } = this.state;

    var archetypeArray = [];

    archetypesList.map((archetype, key) => {

      archetypeArray.push({
        "name": archetype.name,
        "fullname": archetype.desc,
        // "averageScore" : Math.round(mainComp.totalAverage),
        "totalSample": archetype.total,
      })

    })

    setTimeout(() => {

      this.setState({
        archetypesTreeMapData: {
          // "root" : {
          "name": "Archetypes",
          "fullname": "Archetypes",
          "children": archetypeArray
          // }
        }
      },
        () => {
          console.log(this.state.archetypesTreeMapData);
        })

    }, 500);

  }

  updateCompetency = (comp, index) => {
    this.state.subCompetency = [];
    this.setState({
      competencyIndex: index,
      competencyName: comp.competency_name,
      subcompetencyLoader: true,
      subCompPerc: 0
    }, () => {
      debugger;
      this.props.getSubCompetencyRecord(comp.competency_id, this.state.selectedBranchIdValue, this.state.selectedYear)
      // this.DMEDataMapping(this.state.branchIndex, this.state.competencyIndex);
    })
  }
  updateCompetencyFromDropDown = (compId) => {
    // this.state.subCompetency = [];
    debugger;
    this.setState({
      competencyIndex: compId,
    }, () => {
      debugger;
      this.props.getSubCompetencyRecord(this.state.mainCompetency[compId].competency_id, this.state.selectedBranchIdValue, this.state.selectedYear)
      // this.DMEDataMapping(this.state.branchIndex, this.state.competencyIndex);
    })
  }

  componentWillReceiveProps(nextProps, thisProps) {
    if (this.props.switchedBranch != nextProps.switchedBranch) {
      debugger;
      this.state.subCompetency = [];
      this.setState({
        selectedBranchIdValue: nextProps.switchedBranch.selectedBranch.id,
        branchName: nextProps.switchedBranch.selectedBranch.name,
        branchesListToggle: false,
        //First Section States Zero for loader
        totalAssesst: 'loading',
        totalDivision: 'loading',
        totalSection: 'loading',
        //Second Main and Sub Competency loader
        isMainCompetencyLoading: true,
        subcompetencyLoader: true,
        competencyIndex: 0,
        subCompPerc: 0,
        mainCompetencyAverage: 0,
        mainCompetency: [],
        //Third Tree Map
        mapCompetencyonTree: '',
        //Fourth COmpetency Avg
        compComparisonDataApi: {
          keys: [],
          data: []
        },
        //Fifth Competency with Division
        compComparisonDataDivisionWiseApi: {
          keys: [],
          data: []
        },
        //Sixth Competency with Section
        compComparisonDataSectionWiseApi: {
          keys: [],
          data: []
        },
        //Seventh Bubble Graph for Designatino
        divisionSectionDataApi: ''
      }, () => {
        console.log('branchIndex', this.state.selectedBranchIdValue);
        this.averageWithAllBranchesMapping();
        this.averageWithBranchesMappingForDivisions();
        this.averageWithBranchesMappingForSections();
        // this.DMEDataMapping(this.state.branchIndex, this.state.competencyIndex);
        this.competencyFrameworkDataMapping(this.state.branchIndex);
        this.props.getTotalPeopleAssesst(this.state.selectedBranchIdValue, this.state.selectedYear);
        this.props.getMainCompetencyList(this.state.selectedBranchIdValue, this.state.selectedYear);
        // this.props.getAllDivisions(this.state.selectedBranchIdValue , this.state.selectedYear);
        this.props.branchAvgDMERecord(this.state.selectedBranchIdValue, this.state.selectedYear);
        this.props.candidateDistributionRecord(this.state.selectedBranchIdValue, this.state.selectedYear);
      })
    }

    else if (this.props.competencyYear != nextProps.competencyYear) {
      debugger;
      this.state.subCompetency = [];
      this.setState({
        selectedBranchIdValue: nextProps.switchedBranch.selectedBranch.id,
        selectedYear: nextProps.competencyYear,
        branchName: nextProps.switchedBranch.selectedBranch.name,
        //First Section States Zero for loader
        totalAssesst: 'loading',
        totalDivision: 'loading',
        totalSection: 'loading',
        //Second Main and Sub Competency loader
        isMainCompetencyLoading: true,
        subcompetencyLoader: true,
        competencyIndex: 0,
        subCompPerc: 0,
        mainCompetencyAverage: 0,
        mainCompetency: [],
        //Third Tree Map
        mapCompetencyonTree: '',
        //Fourth COmpetency Avg
        compComparisonDataApi: {
          keys: [],
          data: []
        },
        //Fifth Competency with Division
        compComparisonDataDivisionWiseApi: {
          keys: [],
          data: []
        },
        //Sixth Competency with Section
        compComparisonDataSectionWiseApi: {
          keys: [],
          data: []
        },
        //Seventh Bubble Graph for Designatino
        divisionSectionDataApi: ''
      }, () => {
        console.log('branchIndex', this.state.selectedBranchIdValue);
        this.averageWithAllBranchesMapping();
        this.averageWithBranchesMappingForDivisions();
        this.averageWithBranchesMappingForSections();
        // this.DMEDataMapping(this.state.branchIndex, this.state.competencyIndex);
        this.competencyFrameworkDataMapping(this.state.branchIndex);
        this.props.getTotalPeopleAssesst(this.state.selectedBranchIdValue, this.state.selectedYear);
        this.props.getMainCompetencyList(this.state.selectedBranchIdValue, this.state.selectedYear);
        // this.props.getAllDivisions(this.state.selectedBranchIdValue , this.state.selectedYear);
        this.props.branchAvgDMERecord(this.state.selectedBranchIdValue, this.state.selectedYear);
        this.props.candidateDistributionRecord(this.state.selectedBranchIdValue, this.state.selectedYear);
      })
    }

    else if (this.props.totalPeopleAssest != nextProps.totalPeopleAssest) {
      this.setState({
        totalAssesst: nextProps.totalPeopleAssest.result.totallPeopleAssesst,
        totalDivision: nextProps.totalPeopleAssest.result.divisions.length,
        totalSection: nextProps.totalPeopleAssest.result.sections.length
      }, () => {
        console.log('branchIndex', this.state.selectedBranchIdValue);
      })
    }


    else if (this.props.mainCompetencies != nextProps.mainCompetencies) {
      try {
        // var dummmyArray = [];
        // nextProps.mainCompetencies.result.map((item, index) => {
        //   dummmyArray.push({
        //     averageScore: nextProps.mainCompetencies.everyMainComptPerc[item.competency_id].totallPerc,
        //     name: item.competency_name,
        //     fullname: item.competency_name + " - Total responses",
        //     totalSample: nextProps.mainCompetencies.everyMainComptPerc[item.competency_id].totallCnt
        //   })
        // })
        // setTimeout(() => {
        //   this.setState({
        //     mapCompetencyonTree: {
        //       "name": this.state.branchName,
        //       "fullname": this.state.branchName,
        //       "children": dummmyArray
        //     }
        //   },
        //     () => {
        //       console.log(this.state.mapCompetencyonTree);
        //     })
        // }, 500);


        this.setState({
          mainCompetency: nextProps.mainCompetencies.result,
          mainCompetencyAverage: nextProps.mainCompetencies.mainComptPerc,
          isMainCompetencyLoading: false,
          competencyName: nextProps.mainCompetencies.result[0].competency_name
        }, () => {
          this.props.getSubCompetencyRecord(this.state.mainCompetency[0].competency_id, this.state.selectedBranchIdValue, this.state.selectedYear)
          this.props.categorisedDMERecord(this.state.selectedBranchIdValue, this.state.selectedYear)
          this.props.getAllCompetencyAvgRecord('', this.state.selectedYear);
          console.log('branchIndex', this.state.selectedBranchIdValue);
        })

      }
      catch (err) {
        window.alert(err);
      }
    }

    else if (this.props.getSubCompetency != nextProps.getSubCompetency) {

      if (Object.keys(nextProps.getSubCompetency.result).length > 0) {
        var dummmyArray = [];
        nextProps.mainCompetencies.result.map((item, index) => {
          dummmyArray.push({
            averageScore: nextProps.mainCompetencies.everyMainComptPerc[item.competency_id].totallPerc,
            name: item.competency_name,
            fullname: item.competency_name + " - Total responses",
            totalSample: nextProps.mainCompetencies.everyMainComptPerc[item.competency_id].totallCnt
          })
        })
        setTimeout(() => {
          this.setState({
            mapCompetencyonTree: {
              "name": this.state.branchName,
              "fullname": this.state.branchName,
              "children": dummmyArray
            }
          },
            () => {
              console.log(this.state.mapCompetencyonTree);
            })
        }, 500);
      }
      else {
        this.setState({
          mapCompetencyonTree: 'No Record'
        })
        debugger;
      }



      this.state.subCompetency = [];
      var subCompAvg = 0;
      var count = 0;
      Object.keys(nextProps.getSubCompetency.result).map(item =>
        this.state.subCompetency.push({
          name: item,
          totall: nextProps.getSubCompetency.result[item].totall,
          totallPerc: nextProps.getSubCompetency.result[item].totallPerc
        }))
      Object.keys(nextProps.getSubCompetency.result).map(itemss => {
        subCompAvg += Number(nextProps.getSubCompetency.result[itemss].totallPerc)
        count++;
      })
      console.log("LENGTH:", count);
      var avg;
      if (subCompAvg == 0) {
        avg = 0;
      }
      else {
        avg = Math.round(subCompAvg / count);
      }
      this.setState({
        subCompPerc: avg,
        subcompetencyLoader: false
      })
    }

    else if (this.props.getAllCompetencyAvg != nextProps.getAllCompetencyAvg) {
      debugger;
      var dataArrayNew = [];
      nextProps.mainCompetencies.result.map((item) => {
        dataArrayNew.push({
          "type": item.competency_name,
          [this.state.branchName]: nextProps.mainCompetencies.everyMainComptPerc[item.competency_id].totallPerc,
          "All Branches": nextProps.getAllCompetencyAvg.result[item.competency_name].totallPerc
        })
      })
      this.setState({
        compComparisonDataApi: {
          keys: ["All Branches", this.state.branchName],
          data: dataArrayNew
        }
      }, () => {
        this.props.getAllDivisions(this.state.selectedBranchIdValue, this.state.selectedYear);
      })
    }


    else if (this.props.divisionsAndSections != nextProps.divisionsAndSections) {
      debugger;

      if (nextProps.divisionsAndSections.division.length > 0) {
        if (nextProps.divisionsAndSections.section[nextProps.divisionsAndSections.division[0].DIVISION].length > 0) {
          debugger;
          this.setState({
            allDivisions: nextProps.divisionsAndSections.division,
            allSections: nextProps.divisionsAndSections.section[nextProps.divisionsAndSections.division[0].DIVISION],
            divisionName: nextProps.divisionsAndSections.division[0].DIVISION,
            sectionName: nextProps.divisionsAndSections.section[nextProps.divisionsAndSections.division[0].DIVISION][0].SECTION,
          }, () => {
            debugger;
            this.props.competencyByDivisionRecord(this.state.selectedBranchIdValue, this.state.selectedYear, nextProps.divisionsAndSections.division[0].DIVISION)
            // this.props.competencyBySectionRecord(this.state.selectedBranchIdValue , this.state.selectedYear , nextProps.divisionsAndSections.section[0].SECTION)
            // this.props.sectionDMERecord(this.state.selectedBranchIdValue , this.state.selectedYear , this.state.allSections[0].SECTION)
            this.props.divisionDMERecord(this.state.selectedBranchIdValue, this.state.selectedYear, this.state.allDivisions[0].DIVISION)
          })
        }
        else {
          debugger;
          this.setState({
            allDivisions: nextProps.divisionsAndSections.division,
            allSections: nextProps.divisionsAndSections.section[nextProps.divisionsAndSections.division[0].DIVISION],
            divisionName: nextProps.divisionsAndSections.division[0].DIVISION,
            sectionName: "",
          }, () => {
            this.props.competencyByDivisionRecord(this.state.selectedBranchIdValue, this.state.selectedYear, nextProps.divisionsAndSections.division[0].DIVISION)
            // this.props.competencyBySectionRecord(this.state.selectedBranchIdValue , this.state.selectedYear , nextProps.divisionsAndSections.section[0].SECTION)
            // this.props.sectionDMERecord(this.state.selectedBranchIdValue , this.state.selectedYear , this.state.allSections[0].SECTION)
            this.props.divisionDMERecord(this.state.selectedBranchIdValue, this.state.selectedYear, this.state.allDivisions[0].DIVISION)
          })
        }
      }
      else {
        this.setState({
          divisionHideGraph: true,
          sectionHideGraph: true
        })
      }








      // if (nextProps.divisionsAndSections.division.length > 0) {
      //   if (nextProps.divisionsAndSections.section.length > 0) {
      //     this.setState({
      //       allDivisions: nextProps.divisionsAndSections.division,
      //       allSections: nextProps.divisionsAndSections.section,
      //       divisionName: nextProps.divisionsAndSections.division[0].DIVISION,
      //       sectionName: nextProps.divisionsAndSections.section[0].SECTION,
      //     }, () => {
      //       this.props.competencyByDivisionRecord(this.state.selectedBranchIdValue, this.state.selectedYear, nextProps.divisionsAndSections.division[0].DIVISION)
      //       // this.props.competencyBySectionRecord(this.state.selectedBranchIdValue , this.state.selectedYear , nextProps.divisionsAndSections.section[0].SECTION)
      //       // this.props.sectionDMERecord(this.state.selectedBranchIdValue , this.state.selectedYear , this.state.allSections[0].SECTION)
      //       this.props.divisionDMERecord(this.state.selectedBranchIdValue, this.state.selectedYear, this.state.allDivisions[0].DIVISION)
      //     })
      //   }
      //   else {
      //     this.setState({
      //       allDivisions: nextProps.divisionsAndSections.division,
      //       allSections: nextProps.divisionsAndSections.section,
      //       divisionName: nextProps.divisionsAndSections.division[0].DIVISION,
      //       sectionName: "",
      //     }, () => {
      //       this.props.competencyByDivisionRecord(this.state.selectedBranchIdValue, this.state.selectedYear, nextProps.divisionsAndSections.division[0].DIVISION)
      //       // this.props.competencyBySectionRecord(this.state.selectedBranchIdValue , this.state.selectedYear , nextProps.divisionsAndSections.section[0].SECTION)
      //       // this.props.sectionDMERecord(this.state.selectedBranchIdValue , this.state.selectedYear , this.state.allSections[0].SECTION)
      //       this.props.divisionDMERecord(this.state.selectedBranchIdValue, this.state.selectedYear, this.state.allDivisions[0].DIVISION)
      //     })
      //   }
      // }
      // else {
      //   this.setState({
      //     divisionHideGraph: true,
      //     sectionHideGraph: true
      //   })
      // }
    }

    else if (this.props.competencyByDivision != nextProps.competencyByDivision) {
      debugger;
      var dataArrayforGraph = [];
      var dataArraygraphSection = [];
      try {
        nextProps.mainCompetencies.result.map((item) => {
          dataArrayforGraph.push({
            "type": item.competency_name,
            [this.state.branchName]: nextProps.mainCompetencies.everyMainComptPerc[item.competency_id].totallPerc,
            [this.state.divisionName]: Math.round(nextProps.competencyByDivision[item.competency_name].totallPerc),
            "All Branches": nextProps.getAllCompetencyAvg.result[item.competency_name].totallPerc
          })
          //COmpetency Section Change Reflect...
          if (nextProps.competencyBySection != "") {
            dataArraygraphSection.push({
              "type": item.competency_name,
              [this.state.branchName]: nextProps.mainCompetencies.everyMainComptPerc[item.competency_id].totallPerc,
              [this.state.divisionName]: Math.round(nextProps.competencyByDivision[item.competency_name].totallPerc),
              [this.state.sectionName]: Math.round(nextProps.competencyBySection[item.competency_name].totallPerc),
              "All Branches": nextProps.getAllCompetencyAvg.result[item.competency_name].totallPerc
            })
          }
        })
        debugger;
        this.setState({
          compComparisonDataDivisionWiseApi: {
            keys: ["All Branches", this.state.branchName, this.state.divisionName],
            data: dataArrayforGraph
          },
          divisionHideGraph: false
        }, () => {
          if (this.state.allSections.length > 0) {
            debugger;
            this.setState({
              sectionHideGraph: false
            }, () => {
              this.props.competencyBySectionRecord(this.state.selectedBranchIdValue, this.state.selectedYear, this.state.sectionName)
            })
          }
          else {
            debugger;
            this.setState({
              sectionHideGraph: true
            })
          }
          // this.props.competencyBySectionRecord(this.state.selectedBranchIdValue , this.state.selectedYear , this.state.sectionName)
        })

        this.setState({
          compComparisonDataSectionWiseApi: {
            keys: ["All Branches", this.state.branchName, this.state.divisionName, this.state.sectionName],
            data: dataArraygraphSection
          }
        })

      }

      catch (err) {
        window.alert("Error in COMPETENCY BY DIVISION:");
        this.setState({
          compComparisonDataDivisionWiseApi: {
            keys: [],
            data: []
          }
        })
      }
    }

    else if (this.props.competencyBySection != nextProps.competencyBySection) {
      debugger;
      var dataArraygraphSection = [];
      var peopleinSec = 0;
      nextProps.mainCompetencies.result.map((item) => {
        debugger;
        dataArraygraphSection.push({
          "type": item.competency_name,
          [this.state.branchName]: nextProps.mainCompetencies.everyMainComptPerc[item.competency_id].totallPerc,
          [this.state.divisionName]: Math.round(nextProps.competencyByDivision[item.competency_name].totallPerc),
          [this.state.sectionName]: Math.round(nextProps.competencyBySection[item.competency_name].totallPerc),
          "All Branches": nextProps.getAllCompetencyAvg.result[item.competency_name].totallPerc
        })
        peopleinSec += nextProps.competencyBySection[item.competency_name].totall;

      })
      this.setState({
        compComparisonDataSectionWiseApi: {
          keys: ["All Branches", this.state.branchName, this.state.divisionName, this.state.sectionName],
          data: dataArraygraphSection
        },
        totalPeopleinSection: peopleinSec
      })
    }

    else if (this.props.candidateDistribution != nextProps.candidateDistribution) {
      debugger;
      var divisionsArray = [];

      nextProps.candidateDistribution.branchDivisions.map((division, index) => {

        var sectionsArray = [];

        nextProps.candidateDistribution[division.DIVISION].sections.map((section, indexx) => {

          var designationsArray = [];

          nextProps.candidateDistribution[division.DIVISION][section.SECTION].DESIGNATION.map((designation, indexxx) => {
            designationsArray.push({
              "name": designation.DESIGNATION,
              "sample": nextProps.candidateDistribution[division.DIVISION][section.SECTION][designation.DESIGNATION].totall
            })

          })

          sectionsArray.push({
            "name": section.SECTION,
            "children": designationsArray
          })

        })

        divisionsArray.push({
          "name": division.DIVISION,
          "children": sectionsArray
        });

      })
      debugger;
      if (divisionsArray.length > 0) {
        if (divisionsArray[0].children.length > 0) {
          setTimeout(() => {
            this.setState({
              divisionSectionDataApi: {
                "name": this.state.branchName,
                "color": "hsl(241, 70%, 50%)",
                "children": divisionsArray
              }
            },
              () => {
                console.log(this.state.divisionSectionDataApi);
              })

          }, 500);
        }
        else {
          debugger;
          this.setState({
            divisionSectionDataApi: 'No Record'
          })
        }
      }
      else {
        debugger;
        this.setState({
          divisionSectionDataApi: 'No Record'
        })
      }

      // setTimeout(() => {
      //   this.setState({
      //     divisionSectionDataApi: {
      //       "name": this.state.branchName,
      //       "color": "hsl(241, 70%, 50%)",
      //       "children": divisionsArray
      //     }
      //   },
      //     () => {
      //       console.log(this.state.divisionSectionDataApi);
      //     })

      // }, 500);

      // this.setState({
      //   dummydivisionSectionDataApi : {
      //     "name": this.state.branchName,
      //     "color": "hsl(241, 70%, 50%)",
      //     "children": [{
      //       "name" : "Division 1",
      //       "children" : [{
      //         "name" : "Section 1",
      //         "children" : [{
      //           "name": "Designation 1",
      //           "sample": "1"
      //         }]
      //       }]
      //     }]
      //   }
      // })
    }

  }

  divisionDropDownChange = () => {
    debugger;
    if (this.props.divisionsAndSections.section[this.state.divisionName].length > 0) {
      this.setState({
        sectionName: this.props.divisionsAndSections.section[this.state.divisionName][0].SECTION,
      })
    }
    this.setState({
      allSections: this.props.divisionsAndSections.section[this.state.divisionName],
    })
    this.props.competencyByDivisionRecord(this.state.selectedBranchIdValue, this.state.selectedYear, this.state.divisionName);
  }

  toggleBranchesBar = () => {
    this.setState({
      branchesListToggle: !this.state.branchesListToggle
    }, () => {
      // if(this.state.branchesListToggle) {
      //   $('body').css({overflow: 'hidden'})
      // }else{
      //   $('body').css({overflow: ''})
      // }
    })
  }

  toggleModalInfo = () => {
    this.setState({
      integratedFormId: "ODY5Mg==",
      integratedFormToggle: true
    }, () => {

      // var randomEmail = parseInt(Math.random()*5000 + 10) + "@yopmail.com";
      // var randomPhone = parseInt(Math.random()*100000 + 200);

      this.setState({
        // formSource : "https://form.assessmentfund.com/"+ this.state.integratedFormId +"?nested=true&email="+ randomEmail +"&phone="+ randomPhone +"&name=DashboardTalentMapUser"
        formSource: "https://form.assessmentfund.com/" + this.state.integratedFormId
      }, () => {
        console.log(this.state.formSource);
      })

    })
  }

  toggleModalChat = () => {
    this.setState({
      integratedFormId: "ODY5Mw==",
      integratedFormToggle: true
    }, () => {

      // var randomEmail = parseInt(Math.random()*5000 + 10) + "@yopmail.com";
      // var randomPhone = parseInt(Math.random()*100000 + 200);

      this.setState({
        // formSource : "https://form.assessmentfund.com/"+ this.state.integratedFormId +"?nested=true&email="+ randomEmail +"&phone="+ randomPhone +"&name=DashboardTalentMapUser"
        formSource: "https://form.assessmentfund.com/" + this.state.integratedFormId
      }, () => {
        console.log(this.state.formSource);
      })

    })
  }

  closeModalForm = () => {
    this.setState({
      integratedFormToggle: false,
      formSource: ""
    })
  }

  toggleTooltip = (key) => {
    var tempArrayTooltip = this.state.tooltipOpen;
    tempArrayTooltip[key] = !tempArrayTooltip[key];
    this.setState({
      tooltipOpen: tempArrayTooltip
    }, () => {
      // console.log(this.state.tooltipOpen);
    })
  };

  yearChange = (e) => {
    console.log(e.target.value);
    this.setState({
      selectedYear: e.target.value
    }, () => {
      this.props.triggerYearChange(this.state.selectedYear);
    })
  }


  render() {
    const { branches, allBranches, branchIndex, competencyIndex, divisionIndex, sectionIndex, archetypesDataChart, selectedBranchIdValue, branchName, totalDivision, totalAssesst, totalSection
      , mainCompetency, isMainCompetencyLoading, mainCompetencyAverage, allDivisions, allSections, selectedYear } = this.state;

    var subCompetenciesDataArrayAPI = []; // for sub competencies with average bar chart with API

    this.state.subCompetency.map((comp, key) => {

      var averageValue = comp.totallPerc;

      subCompetenciesDataArrayAPI.push({
        score: Math.round(averageValue),
        value: comp.name
      })


    });
    var subCompetenciesDataArray = []; // for sub competencies with average bar chart

    branches[branchIndex].mainCompetencies[competencyIndex].subCompetencies.map((comp, key) => {

      var averageValue = comp.average;

      subCompetenciesDataArray.push({
        score: averageValue,
        value: comp.name
      })


    });

    var subCompetenciesDataArrayCountAPI = []; // for sub competencies with average bar chart API

    this.state.subCompetency.map((comp, key) => {

      var total = comp.totall;

      subCompetenciesDataArrayCountAPI.push({
        score: total,
        value: comp.name
      })


    });
    var subCompetenciesDataArrayCount = []; // for sub competencies with average bar chart 

    branches[branchIndex].mainCompetencies[competencyIndex].subCompetencies.map((comp, key) => {

      var total = parseInt(comp.d_total + comp.m_total + comp.e_total);

      subCompetenciesDataArrayCount.push({
        score: total,
        value: comp.name
      })


    });
    console.log("MAP STATE", this.state.divisionSectionDataApi);
    console.log("HASEEB STATE", this.state.divisionSectionData);
    console.log("TREE", this.state.mapCompetencyonTree);
    return (
      <div className="PagcorStatsComponent">
        {/* <Header /> */}

        <div>

          <Container fluid>
            <Row style={{ padding: '3vh 0' }}>
              <Col style={{ padding: '6vh 0' }} md={{ size: 6 }} className="text-right">
                <h5>Select the year to see the competency assessment talent map - </h5>
              </Col>
              <Col style={{ padding: '5vh 0' }} md={{ size: 6 }} className="text-left">
                <Button className={selectedYear == '2019' ? "yearSelected" : "yearButton"} value="2019" onClick={this.yearChange}>2019</Button>
                <Button className={selectedYear == '2020' ? "yearSelected" : "yearButton"} value="2020" onClick={this.yearChange}>2020</Button>
                <Link to="/pagcor/comparision">
                  <Button className={"yearButton"} >Comparision Year</Button>
                </Link>
              </Col>

              {/* section for mayer information and city additional info - starts here */}
              <Col md={{ size: 12 }}>
                <div className="chartBox">
                  <div>
                    <Row style={{ padding: '25px' }}>

                      <Col md={{ size: 4 }}>
                        <div className="schoolBox topSchoolBox">
                          <Row>
                            <Col md={{ size: 12 }}>
                              <div style={{ verticalAlign: 'middle', padding: '0vh 0' }}>
                                {branchName == "CF-BACOLOD" ?
                                  <div className="circleBox imageBack" style={{ backgroundImage: "url(" + this.state.bacolodImg + ")" }}></div>
                                  :
                                  <img src={branchicon} />}
                                <p style={{ margin: 0, color: '#7a8596', position: 'relative', top: '8px' }}>Branch</p>
                                <h3 className="cityName">{branchName}</h3>
                              </div>
                            </Col>
                          </Row>
                          {/* <p className="titleTag">City</p> */}
                        </div>
                      </Col>

                      <Col md={{ size: 4 }}>
                        <div className="schoolBox topSchoolBox">
                          <Row>
                            <Col md={{ size: 12 }}>
                              <div style={{ verticalAlign: 'middle', padding: '0vh 0' }}>
                                <div className="circleBox imageBack">
                                  <FontAwesomeIcon className="iconHere" icon={faMale} size="4x" />
                                  <FontAwesomeIcon className="iconHere" icon={faFemale} size="4x" />
                                </div>
                                <p style={{ margin: 0, color: '#7a8596', position: 'relative', top: '8px' }}>People assessed</p>
                                {totalAssesst == 'loading' ? <Spinner className="citySpinner mt-2" size="sm" color="light" /> :
                                  <h3 className="cityName">{totalAssesst}</h3>}
                              </div>
                            </Col>
                          </Row>
                          {/* <p className="titleTag">Mayer</p> */}
                        </div>
                      </Col>

                      <Col md={{ size: 4 }}>
                        <div className="schoolBox topSchoolBox">
                          <Row>
                            <Col>
                              <h3 className="cityName"></h3>
                              <div className="oneInfo">
                                <div className="iconBox">
                                  {/* <FontAwesomeIcon className="iconHere" icon={faMale} size="4x" />
                                        <FontAwesomeIcon className="iconHere" icon={faFemale} size="4x" /> */}
                                  <img src={devisionicon} />
                                </div>
                                <p style={{ color: '#7a8596' }}>Divisions</p>
                                {totalDivision == 'loading' ? <Spinner className="citySpinner mt-2" size="sm" color="light" /> :
                                  <h3>{totalDivision}</h3>}
                              </div>
                              <div className="oneInfo">
                                <div className="iconBox">
                                  {/* <FontAwesomeIcon className="iconHere" icon={faSchool} size="4x" /> */}
                                  <img src={sectionicon} />
                                </div>
                                <p style={{ color: '#7a8596', maxWidth: "140px" }}>Sections</p>
                                {totalSection == 'loading' ? <Spinner className="citySpinner mt-2" size="sm" color="light" /> :
                                  <h3>{totalSection}</h3>}
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </Col>



                    </Row>



                  </div>
                </div>
              </Col>
              {/* section for mayer information and city additional info - ends here */}







              <Col md={{ size: 12 }}>
                <div className="chartBox">
                  <div className="titleHead">
                    <Row>
                      <Col md={{ size: 8 }}>
                        <h5>Overview of competencies in {branchName} </h5>
                      </Col>

                      <Col style={{ textAlign: 'right' }} md={{ size: 4 }}>

                        <div className="quickIcons">
                          <FontAwesomeIcon className="customIcon" icon={faCalendarCheck} size="1x" onClick={e => this.toggleModalInfo()} />
                          <FontAwesomeIcon className="customIcon" icon={faComment} size="1x" onClick={e => this.toggleModalChat()} />
                        </div>

                        {/* <Switch
                                checked={this.state.situationsAvgDataLoc == 1 ? true : false}
                                onChange={e => this.setState({ situationsAvgDataLoc : this.state.situationsAvgDataLoc == 0 ? 1 : 0 }) }
                                uncheckedIcon={
                                  <div className="switchLabel">
                                    {this.state.selectedRegion}
                                  </div>
                                }
                                checkedIcon={
                                  <div className="switchLabel">
                                    All Cities
                                  </div>
                                }
                                className={this.state.situationsAvgDataLoc == 0 ? 'react-switch medium lowest' : 'react-switch medium highest'}
                                id="icon-switch"
                              /> */}
                      </Col>
                    </Row>
                  </div>
                  <div>
                    <Row style={{ padding: '25px' }}>

                      <Col md={{ size: 6 }}>
                        <div className="schoolBox">
                          <Row>
                            <Col md={{ size: 7 }}>
                              <div style={{ verticalAlign: 'middle', padding: '5vh 0' }}>
                                <div className="circleBox">
                                  <h2>{mainCompetency.length}</h2>
                                  <p>*Main competencies tested for<br />the <strong>{branchName}</strong></p>
                                  <div className="smallCircle">
                                    {mainCompetencyAverage}<span>%</span>
                                    <p>*Average<br />score</p>
                                  </div>
                                </div>
                              </div>
                            </Col>
                            <Col md={{ size: 5 }} style={{ textAlign: 'left' }}>

                              <div style={{ color: '#fff', position: 'relative', top: '50%', transform: 'translateY(-50%)' }}>
                                {isMainCompetencyLoading ? <div className="text-center"><Spinner className="" size="lg" color="light" /> </div> :

                                  mainCompetency.map((m_competency, key) => {

                                    var isThisOne = false;
                                    if (key == competencyIndex) { // compare with skill id from url
                                      isThisOne = true;
                                    }

                                    return (
                                      <span style={{
                                        opacity: !isThisOne && this.state.competencyUpdateLoader ? '0.4' : '1'
                                      }} key={key} className={isThisOne ? 'skillHere highlighted' : 'skillHere'} onClick={e => this.updateCompetency(m_competency, key)}>
                                        {
                                          isThisOne && this.state.competencyUpdateLoader ?
                                            <Spinner className="skillSpinner" size="sm" color="light" />
                                            : null
                                        }
                                        {m_competency.competency_name}
                                      </span>
                                    )
                                  })
                                }

                              </div>
                            </Col>
                            {/* <Col className="bottomLabel" md={{size: 12}}>
                                      <p>* Skills recommended by the World Bank<br />for 21st century workforce</p>
                                  </Col> */}
                          </Row>
                        </div>
                      </Col>

                      <Col md={{ size: 6 }}>
                        <div className="schoolBox">
                          <Row>
                            <Col md={{ size: 6 }}>
                              <div style={{ verticalAlign: 'middle', padding: '7vh 0' }}>
                                <div className="circleBox2" style={{ width: '280px', height: '280px' }}>
                                  <h2 style={{ lineHeight: '190px' }}>{this.state.subCompetency.length}</h2>
                                  <p>*Competencies tested in
                                          <br />
                                    <strong style={{ fontSize: '18px' }}>{this.state.competencyName}</strong>
                                  </p>
                                  {/* animated infinite bounce delay-2s */}
                                  <div className="smallCircle">
                                    {this.state.subCompPerc}<span>%</span>
                                    <p>*Average<br />score</p>
                                  </div>
                                </div>
                              </div>
                            </Col>
                            <Col md={{ size: 6 }} style={{ textAlign: 'left', paddingRight: '0', position: 'relative', zIndex: '999' }}>
                              {this.state.subcompetencyLoader ? <div style={{ color: '#fff', position: 'relative', top: '50%', transform: 'translateY(-50%)' }}>
                                <div className="text-center"><Spinner className="" size="lg" color="light" /> </div>
                              </div> :
                                <div className="situationsParent">

                                  {
                                    this.state.subCompetency.map((competency, key) => {

                                      // var isSkill = false;
                                      var dotColor = "red";
                                      var averageValue = competency.totallPerc;

                                      if (averageValue >= 80 && averageValue <= 89) {
                                        dotColor = "yellow";
                                      }
                                      if (averageValue >= 90 && averageValue <= 100) {
                                        dotColor = "#2cf32c";
                                      }

                                      return (
                                        <div key={key} className='small skillHere'>
                                          {competency.name}
                                          {
                                            averageValue != 0 ?
                                              <span className="dot" style={{ background: dotColor, color: dotColor }}>
                                                <span className="dotText">
                                                  {competency.totallPerc}
                                                  <span style={{ fontSize: '10px' }}>%</span>
                                                </span>
                                              </span>
                                              : null
                                          }
                                        </div>
                                      )
                                    })
                                  }
                                </div>
                              }

                            </Col>
                            <Col className="bottomLabel" md={{ size: 12 }}>

                              <p>* Average percentage of required<br />competencies met in {this.state.competencyName} competency</p>

                              {/* {
                                        this.state.situationsAvgDataLoc == 0 ?
                                          <p className="contactForCity" onClick={e => this.toggleModalInfo()}>* This graph represents partial data for this city. <span>Contact us</span> for the complete dataset for this city.</p>
                                        : null
                                      } */}

                            </Col>
                          </Row>
                        </div>
                      </Col>

                    </Row>

                    <Row style={{ padding: '0 15px 15px 15px' }} className="competencyFramworkChartBox">
                      <Col md={{ size: 12 }}>
                        <h5 style={{ marginTop: '20px' }}>This map depicts the average percentage score for each of the four main competency for {branchName}</h5>
                        <div style={{ height: 'calc(60vh - 60px)' }}>
                          {
                            this.state.mapCompetencyonTree && this.state.mapCompetencyonTree != '' && this.state.mapCompetencyonTree != 'No Record' ?
                              <ResponsiveTreeMap
                                root={this.state.mapCompetencyonTree}
                                identity="fullname"
                                value="totalSample"
                                tile="binary"
                                innerPadding={10}
                                outerPadding={10}
                                margin={{ top: 10, right: 10, bottom: 10, left: 10 }}
                                label={(e) => { return e.name + " - " + e.averageScore + "%" }}
                                // labelFormat=".0s"
                                labelSkipSize={10}
                                labelTextColor={{ theme: 'background' }}
                                colors={["#172740", "#0d1623", "#a6cee3"]}
                                // colors={{ scheme: 'purpleRed_green' }}
                                borderColor={{ from: 'color', modifiers: [['darker', 0.3]] }}
                                animate={true}
                                motionStiffness={90}
                                motionDamping={11}
                              />
                              : this.state.mapCompetencyonTree == 'No Record' ? <h5 className="mt-5 text-danger">No Record Found</h5> : <div className="customSpinnerChart">
                                <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                              </div>}
                        </div>

                      </Col>
                    </Row>

                  </div>
                </div>
              </Col>




              <Col md={{ size: 12 }}>
                <div className="chartBox">
                  <div className="titleHead" >
                    <Row>
                      <Col md={{ size: 8 }}>
                        <h5>
                          <strong>{branchName}</strong> competencies' average score comparison with all branches
                                </h5>
                      </Col>
                      <Col md={{ size: 4 }}>
                        <div className="quickIcons" style={{ right: '15px' }}>
                          <FontAwesomeIcon className="customIcon" icon={faCalendarCheck} size="1x" onClick={e => this.toggleModalInfo()} />
                          <FontAwesomeIcon className="customIcon" icon={faComment} size="1x" onClick={e => this.toggleModalChat()} />
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <div style={{ height: 'auto' }}>

                    <Row style={{ padding: '25px' }}>
                      <Col md={{ size: 6 }}>

                        <p style={{ color: '#7ba2dc', textAlign: 'left' }}>This bar chart depicts a performance analysis of percentage scores for {branchName} as compared to the overall result for this assessment for each of the four main competencies.</p>

                      </Col>
                      <Col md={{ size: 12 }}>

                        <div className="chartBox" style={{ background: 'transparent' }}>
                          <div style={{ height: '400px', position: 'relative' }}>

                            {
                              this.state.compComparisonDataApi.data.length == 0 ?
                                <div className="customSpinnerChart">
                                  <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                                </div>
                                :

                                <ResponsiveBar
                                  data={this.state.compComparisonDataApi.data}
                                  keys={this.state.compComparisonDataApi.keys}
                                  indexBy="type"
                                  margin={{ top: 60, right: 30, bottom: 60, left: 100 }}
                                  padding={0.6}
                                  innerPadding={10}
                                  label={d => `${d.value}%`}
                                  // onClick={e => this.barChartClicked(e)}
                                  groupMode="grouped"
                                  colors={{ scheme: 'paired' }}
                                  // colors={["#f706d5", "#770367" ]}
                                  // colors={["#68366f", "#3d1f42" ]}
                                  layout="vertical"
                                  enableGridY={false}
                                  defs={[
                                    {
                                      id: 'dots',
                                      type: 'patternDots',
                                      background: 'inherit',
                                      color: '#38bcb2',
                                      size: 4,
                                      padding: 1,
                                      stagger: true
                                    },
                                    {
                                      id: 'lines',
                                      type: 'patternLines',
                                      background: 'inherit',
                                      color: '#eed312',
                                      rotation: -45,
                                      lineWidth: 6,
                                      spacing: 10
                                    }
                                  ]}
                                  fill={[
                                    {
                                      match: {
                                        id: 'fries'
                                      },
                                      id: 'dots'
                                    },
                                    {
                                      match: {
                                        id: 'sandwich'
                                      },
                                      id: 'lines'
                                    }
                                  ]}
                                  borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                                  axisTop={null}
                                  axisRight={null}
                                  axisBottom={{
                                    tickSize: 5,
                                    tickPadding: 5,
                                    tickRotation: 0,
                                    legend: '',
                                    legendPosition: 'middle',
                                    legendOffset: 50
                                  }}
                                  axisLeft={{
                                    tickSize: 5,
                                    tickPadding: 5,
                                    tickRotation: 0,
                                    legend: '',
                                    legendPosition: 'middle',
                                    legendOffset: -60
                                  }}
                                  labelSkipWidth={12}
                                  labelSkipHeight={12}
                                  labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                                  legends={[
                                    {
                                      dataFrom: 'keys',
                                      anchor: 'top-right',
                                      direction: 'column',
                                      justify: false,
                                      translateX: -15,
                                      translateY: -60,
                                      itemsSpacing: 2,
                                      itemWidth: 100,
                                      itemHeight: 25,
                                      itemDirection: 'left-to-right',
                                      itemOpacity: 0.85,
                                      symbolSize: 20,
                                      effects: [
                                        {
                                          on: 'hover',
                                          style: {
                                            itemOpacity: 1
                                          }
                                        }
                                      ]
                                    }
                                  ]}
                                  animate={true}
                                  motionStiffness={90}
                                  motionDamping={15}
                                />
                            }
                          </div>
                        </div>
                      </Col>
                      {this.state.divisionHideGraph ? null :
                        <React.Fragment>
                          <div className="seperatorHeading">
                            Competencies' average score of {this.state.divisionName} with in {branchName}

                            <select className="customDropDown" onChange={e => {
                              this.setState({
                                sectionIndex: 0, // reset the sectionIndex
                                divisionIndex: 0,
                                divisionName: e.target.value,
                                compComparisonDataDivisionWiseApi: {
                                  keys: [],
                                  data: []
                                },
                              }, () => {
                                debugger;
                                this.divisionDropDownChange();
                                debugger;
                              })
                            }}>
                              {
                                allDivisions.map((division, key) =>
                                  <option key={key} value={division.DIVISION}>{division.DIVISION}</option>
                                )
                              }
                            </select>

                          </div>

                          <Col md={{ size: 8, offset: 1 }}>

                            <div style={{ color: '#7ba2dc', textAlign: 'left', marginTop: '30px' }}>
                              This bar chart highlights the performance for the Divisions in {branchName} for each of the four main competencies and has three points of relevance and comparison:
                            <ol style={{ marginTop: '20px' }}>
                                <li>Average percentage score of each of the {totalDivision} divisions for {branchName}</li>
                                <li>Average percentage score of each Division in {branchName} as compared to the {branchName}'s overall scores</li>
                                <li>Average percentage score of each Division in {branchName} as compared to overall result for this assessment</li>
                              </ol>
                            </div>

                          </Col>

                          <Col md={{ size: 9 }} style={{ paddingTop: '25px' }}>
                            <div className="chartBox" style={{ background: 'transparent' }}>
                              <div style={{ height: '400px', position: 'relative' }}>
                                {
                                  this.state.compComparisonDataDivisionWiseApi.data.length == 0 ?
                                    <div className="customSpinnerChart">
                                      <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                                    </div>
                                    :
                                    <ResponsiveBar
                                      data={this.state.compComparisonDataDivisionWiseApi.data}
                                      keys={this.state.compComparisonDataDivisionWiseApi.keys}
                                      indexBy="type"
                                      margin={{ top: 100, right: 0, bottom: 60, left: 100 }}
                                      padding={0.3}
                                      innerPadding={10}
                                      label={d => `${d.value}%`}
                                      // onClick={e => this.barChartClicked(e)}
                                      groupMode="grouped"
                                      colors={{ scheme: 'paired' }}
                                      // colors={["#f706d5", "#770367" ]}
                                      // colors={["#68366f", "#3d1f42" ]}
                                      layout="vertical"
                                      enableGridY={false}
                                      defs={[
                                        {
                                          id: 'dots',
                                          type: 'patternDots',
                                          background: 'inherit',
                                          color: '#38bcb2',
                                          size: 4,
                                          padding: 1,
                                          stagger: true
                                        },
                                        {
                                          id: 'lines',
                                          type: 'patternLines',
                                          background: 'inherit',
                                          color: '#eed312',
                                          rotation: -45,
                                          lineWidth: 6,
                                          spacing: 10
                                        }
                                      ]}
                                      fill={[
                                        {
                                          match: {
                                            id: 'fries'
                                          },
                                          id: 'dots'
                                        },
                                        {
                                          match: {
                                            id: 'sandwich'
                                          },
                                          id: 'lines'
                                        }
                                      ]}
                                      borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                                      axisTop={null}
                                      axisRight={null}
                                      axisBottom={{
                                        tickSize: 5,
                                        tickPadding: 5,
                                        tickRotation: 0,
                                        legend: '',
                                        legendPosition: 'middle',
                                        legendOffset: 50
                                      }}
                                      axisLeft={{
                                        tickSize: 5,
                                        tickPadding: 5,
                                        tickRotation: 0,
                                        legend: '',
                                        legendPosition: 'middle',
                                        legendOffset: -60
                                      }}
                                      labelSkipWidth={12}
                                      labelSkipHeight={12}
                                      labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                                      legends={[
                                        {
                                          dataFrom: 'keys',
                                          anchor: 'top-right',
                                          direction: 'column',
                                          justify: false,
                                          translateX: -100,
                                          translateY: -100,
                                          itemsSpacing: 2,
                                          itemWidth: 150,
                                          itemHeight: 25,
                                          itemDirection: 'left-to-right',
                                          itemOpacity: 0.85,
                                          symbolSize: 20,
                                          effects: [
                                            {
                                              on: 'hover',
                                              style: {
                                                itemOpacity: 1
                                              }
                                            }
                                          ]
                                        }
                                      ]}
                                      animate={true}
                                      motionStiffness={90}
                                      motionDamping={15}
                                    />
                                }
                              </div>
                            </div>
                          </Col>

                          <Col md={{ size: 3 }}>
                            <div className="schoolBox topSchoolBox bottomBox">
                              <Row>
                                <Col>

                                  <div className="oneInfo">
                                    <div className="iconBox">

                                      <Row>
                                        <Col>
                                          <h3>{allDivisions.length}</h3>
                                          <p style={{ color: '#7a8596' }}>Divisions in<br />{branchName}</p>
                                        </Col>
                                        <Col>
                                          <img src={devisionicon} />
                                        </Col>
                                      </Row>

                                    </div>
                                  </div>

                                  <div className="oneInfo">
                                    <div className="iconBox">

                                      <Row>
                                        <Col>
                                          <h3>{allSections.length}</h3>
                                          <p style={{ color: '#7a8596' }}>Sections in<br />{this.state.divisionName}</p>
                                        </Col>
                                        <Col>
                                          <img src={sectionicon} />
                                        </Col>
                                      </Row>

                                    </div>
                                  </div>

                                </Col>
                              </Row>
                            </div>
                          </Col>
                        </React.Fragment>}

                      {this.state.sectionHideGraph ? null :
                        <React.Fragment>
                          <div className="seperatorHeading">
                            Competencies' average score of {this.state.sectionName} with in {this.state.divisionName}

                            <select className="customDropDown" onChange={e => {
                              this.setState({
                                sectionIndex: 0,
                                sectionName: e.target.value,
                                compComparisonDataSectionWiseApi: {
                                  keys: [],
                                  data: []
                                },
                                totalPeopleinSection: 0
                              }, () => {
                                this.props.competencyBySectionRecord(this.state.selectedBranchIdValue, this.state.selectedYear, this.state.sectionName)
                              })
                            }}>
                              {
                                allSections.map((section, key) =>
                                  <option key={key} value={section.SECTION}>{section.SECTION}</option>
                                )
                              }
                            </select>

                          </div>

                          <Col md={{ size: 8, offset: 1 }}>

                            <div style={{ color: '#7ba2dc', textAlign: 'left', marginTop: '30px' }}>
                              This bar chart is a further breakdown of each Division and highlights the performance for the Sections in each Division in {branchName} for each of the four main competencies and has four points of relevance and comparison:
                            <ol style={{ marginTop: '20px' }}>
                                <li>Average percentage score of each of the Section in the selected Division for {branchName}</li>
                                <li>Average percentage score of each Section as compared to the overall scores for the selected Division in {branchName} as compared to the {branchName}'s overall scores</li>
                                <li>Average percentage score of each Section for the selected Division as compared to the {branchName}'s overall scores</li>
                                <li>Average percentage score of each Section for the selected Division in {branchName} as compared to overall result for this assessment </li>
                              </ol>
                            </div>

                          </Col>

                          <Col md={{ size: 9 }} style={{ paddingTop: '25px' }}>
                            <div className="chartBox" style={{ background: 'transparent' }}>
                              <div style={{ height: '500px', position: 'relative' }}>
                                {
                                  this.state.compComparisonDataSectionWiseApi.data.length == 0 ?
                                    <div className="customSpinnerChart">
                                      <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                                    </div>
                                    :
                                    <ResponsiveBar
                                      data={this.state.compComparisonDataSectionWiseApi.data}
                                      keys={this.state.compComparisonDataSectionWiseApi.keys}
                                      indexBy="type"
                                      margin={{ top: 140, right: 0, bottom: 60, left: 100 }}
                                      padding={0.25}
                                      innerPadding={10}
                                      label={d => `${d.value}%`}
                                      // onClick={e => this.barChartClicked(e)}
                                      groupMode="grouped"
                                      colors={{ scheme: 'paired' }}
                                      // colors={["#f706d5", "#770367" ]}
                                      // colors={["#68366f", "#3d1f42" ]}
                                      layout="vertical"
                                      enableGridY={false}
                                      defs={[
                                        {
                                          id: 'dots',
                                          type: 'patternDots',
                                          background: 'inherit',
                                          color: '#38bcb2',
                                          size: 4,
                                          padding: 1,
                                          stagger: true
                                        },
                                        {
                                          id: 'lines',
                                          type: 'patternLines',
                                          background: 'inherit',
                                          color: '#eed312',
                                          rotation: -45,
                                          lineWidth: 6,
                                          spacing: 10
                                        }
                                      ]}
                                      fill={[
                                        {
                                          match: {
                                            id: 'fries'
                                          },
                                          id: 'dots'
                                        },
                                        {
                                          match: {
                                            id: 'sandwich'
                                          },
                                          id: 'lines'
                                        }
                                      ]}
                                      borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                                      axisTop={null}
                                      axisRight={null}
                                      axisBottom={{
                                        tickSize: 5,
                                        tickPadding: 5,
                                        tickRotation: 0,
                                        legend: '',
                                        legendPosition: 'middle',
                                        legendOffset: 50
                                      }}
                                      axisLeft={{
                                        tickSize: 5,
                                        tickPadding: 5,
                                        tickRotation: 0,
                                        legend: '',
                                        legendPosition: 'middle',
                                        legendOffset: -60
                                      }}
                                      labelSkipWidth={12}
                                      labelSkipHeight={12}
                                      labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                                      legends={[
                                        {
                                          dataFrom: 'keys',
                                          anchor: 'top-right',
                                          direction: 'column',
                                          justify: false,
                                          translateX: -100,
                                          translateY: -135,
                                          itemsSpacing: 2,
                                          itemWidth: 150,
                                          itemHeight: 25,
                                          itemDirection: 'left-to-right',
                                          itemOpacity: 0.85,
                                          symbolSize: 20,
                                          effects: [
                                            {
                                              on: 'hover',
                                              style: {
                                                itemOpacity: 1
                                              }
                                            }
                                          ]
                                        }
                                      ]}
                                      animate={true}
                                      motionStiffness={90}
                                      motionDamping={15}
                                    />
                                }
                              </div>
                            </div>
                          </Col>


                          <Col md={{ size: 3 }}>
                            <div className="schoolBox topSchoolBox bottomBox">
                              <Row>
                                <Col>
                                  <div className="oneInfo">
                                    <div className="iconBox">

                                      <Row>
                                        <Col>
                                          <h3>{this.state.totalPeopleinSection}</h3>
                                          <p style={{ color: '#7a8596' }}>People<br />in {this.state.sectionName}</p>
                                        </Col>
                                        <Col>
                                          <div style={{ margin: '30px 0px 10px 0' }}>
                                            <FontAwesomeIcon className="iconHere" icon={faMale} size="2x" />
                                            <FontAwesomeIcon className="iconHere" icon={faFemale} size="2x" />
                                          </div>
                                        </Col>
                                      </Row>

                                    </div>
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          </Col>
                        </React.Fragment>
                      }
                      <Col md={{ size: 5 }}>
                        <div className="chartBox" style={{ border: '1px #0544a2 solid', borderRadius: '0' }}>
                          <div className="titleHead" style={{ background: '#0b2548', borderBottom: '1px #0544a2 solid' }}>
                            <Row>
                              <Col md={{ size: 12 }}>
                                <h5>
                                  <strong>{branchName}</strong> competencies' average score comparison with all branches
                              </h5>
                              </Col>
                              {/* <Col md={{ size : 4 }}>
                              <div className="quickIcons" style={{right: '15px'}}>
                                <FontAwesomeIcon className="customIcon" icon={faCalendarCheck} size="1x" onClick={e => this.toggleModalInfo()} />
                                <FontAwesomeIcon className="customIcon" icon={faComment} size="1x" onClick={e => this.toggleModalChat()} />
                              </div>
                            </Col> */}

                            </Row>
                          </div>

                          <div style={{ height: '660px', background: '#05162d', position: 'relative' }}>

                            <p style={{ color: '#7ba2dc', textAlign: 'left', padding: '15px 20px 0px 20px' }}>
                              The following graph depicts the comparison of average percentage scores for {branchName} with other branches for each of the four main competencies.
                          </p>

                            {
                              this.state.compComparisonDataApi.data.length == 0 ?
                                <div className="customSpinnerChart">
                                  <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                                </div> :
                                <ResponsiveRadar
                                  data={this.state.compComparisonDataApi.data}
                                  keys={this.state.compComparisonDataApi.keys}
                                  indexBy="type"
                                  maxValue="auto"
                                  margin={{ top: 50, right: 160, bottom: 70, left: 110 }}
                                  curve="linearClosed"
                                  borderWidth={2}
                                  borderColor={{ from: 'color', modifiers: [] }}
                                  gridLevels={1}
                                  gridShape="circular"
                                  gridLabelOffset={25}
                                  enableDots={true}
                                  dotSize={12}
                                  dotColor={{ from: 'color', modifiers: [] }}
                                  dotBorderWidth={0}
                                  dotBorderColor={{ from: 'color' }}
                                  enableDotLabel={false}
                                  dotLabel="value"
                                  dotLabelYOffset={-12}
                                  colors={["#93519c", "#e84f9a"]}
                                  fillOpacity={0}
                                  blendMode="normal"
                                  animate={true}
                                  motionStiffness={200}
                                  motionDamping={7}
                                  isInteractive={true}
                                  legends={[
                                    {
                                      anchor: 'top-left',
                                      direction: 'column',
                                      translateX: -90,
                                      translateY: 0,
                                      itemWidth: 80,
                                      itemHeight: 25,
                                      itemTextColor: '#999',
                                      symbolSize: 12,
                                      symbolShape: 'circle',
                                      effects: [
                                        {
                                          on: 'hover',
                                          style: {
                                            itemTextColor: '#000'
                                          }
                                        }
                                      ]
                                    }
                                  ]}
                                />
                            }
                          </div>
                        </div>
                      </Col>

                      <Col md={{ size: 7 }}>
                        <div className="chartBox" style={{ border: '1px #0544a2 solid', borderRadius: '0' }}>
                          <div className="titleHead" style={{ background: '#0b2548', borderBottom: '1px #0544a2 solid' }}>
                            <Row>
                              <Col md={{ size: 12 }}>
                                <h5>Candidates' distribution in divisions, sections & designations in <strong>{branchName}</strong></h5>
                              </Col>
                              {/* <Col style={{textAlign: 'right'}} md={{ size : 5 }}>
                                
                                <div className="quickIcons">
                                  <FontAwesomeIcon className="customIcon" icon={faCalendarCheck} size="1x" onClick={e => this.toggleModalInfo()} />
                                  <FontAwesomeIcon className="customIcon" icon={faComment} size="1x" onClick={e => this.toggleModalChat()} />
                                </div>
                                
                                <Switch
                                  checked={this.state.userTypeScoresDataPie1Loc == 1 ? true : false}
                                  onChange={e => this.setState({ userTypeScoresDataPie1Loc : this.state.userTypeScoresDataPie1Loc == 0 ? 1 : 0 }) }
                                  uncheckedIcon={
                                    <div className="switchLabel">
                                      {this.state.selectedRegion}
                                    </div>
                                  }
                                  checkedIcon={
                                    <div className="switchLabel">
                                      All Cities
                                    </div>
                                  }
                                  className={this.state.userTypeScoresDataPie1Loc == 0 ? 'react-switch medium lowest' : 'react-switch medium highest'}
                                  id="icon-switch"
                                />
                            </Col> */}
                            </Row>
                          </div>
                          <div>
                            {/* {
                            this.state.skillUpdateLoader ? 
                              <div className="customSpinnerChart">
                                
                                <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                              </div>
                            : null
                          } */}

                            <Row>
                              {/* <Col md={{ size : 12 }}>
                              <h2>asdadasd</h2>
                            </Col> */}
                              <Col md={{ size: 12 }} style={{ height: '660px', background: '#05162d', position: 'relative', overflow: 'hidden' }}>

                                <p style={{ color: '#7ba2dc', textAlign: 'left', padding: '15px 20px 0px 20px' }}>
                                  The following representation provides in-depth view of the volume of people assessed in {branchName}. This is further broken down to give a complete overview of volume of people assessed in each Division, Section and Position for {branchName}.
                              </p>
                                {
                                  this.state.divisionSectionDataApi && this.state.divisionSectionDataApi != '' && this.state.divisionSectionDataApi != 'No Record' ?
                                    <ResponsiveBubble
                                      root={this.state.divisionSectionDataApi}
                                      margin={{ top: 30, right: 20, bottom: 130, left: 20 }}
                                      identity="name"
                                      value="sample"
                                      colors={{ scheme: 'paired' }}
                                      // colors={["#f1e15b", "#1f78b4"]}
                                      padding={6}
                                      label="value"
                                      labelTextColor={{ from: 'color', modifiers: [['darker', 0.8]] }}
                                      borderWidth={2}
                                      borderColor={{ from: 'color' }}
                                      defs={[
                                        {
                                          id: 'lines',
                                          type: 'patternLines',
                                          background: 'none',
                                          color: 'inherit',
                                          rotation: -45,
                                          lineWidth: 5,
                                          spacing: 8
                                        }
                                      ]}
                                      // fill={[ { match: { depth: 1 }, id: 'lines' } ]}
                                      animate={true}
                                      motionStiffness={90}
                                      motionDamping={12}
                                    /> : this.state.divisionSectionDataApi == 'No Record' ? <h6 className="mt-5 text-danger">No Record Found - No Employee is assigned in any Section</h6> :
                                      <div className="customSpinnerChart">
                                        <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                                      </div>}
                              </Col>
                            </Row>

                          </div>
                        </div>
                      </Col>
                    </Row>


                  </div>
                </div>
              </Col>


              <Col md={{ size: 6 }}>
                <div className="chartBox">
                  <div className="titleHead">
                    <Row>
                      <Col md={{ size: 8 }}>

                        <h5>Avg. score for {subCompetenciesDataArrayAPI.length} competencies assessed within <strong>{this.state.competencyName}</strong></h5>

                      </Col>
                      <Col style={{ textAlign: 'right' }} md={{ size: 4 }}>

                        {/* <div className="quickIcons">
                                <FontAwesomeIcon className="customIcon" icon={faCalendarCheck} size="1x" onClick={e => this.toggleModalInfo()} />
                                <FontAwesomeIcon className="customIcon" icon={faComment} size="1x" onClick={e => this.toggleModalChat()} />
                              </div> */}

                        <select className="customDropDown" style={{ top: 0 }} onChange={e => {
                          // this.updateCompetency(branches[branchIndex].mainCompetencies[e.target.value].name, e.target.value);
                          debugger;
                          this.updateCompetencyFromDropDown(e.target.value)
                        }}>
                          {
                            mainCompetency.map((comp, key) =>
                              <option key={key} value={key} selected={key == this.state.competencyIndex}>{comp.competency_name}</option>
                            )
                          }
                        </select>
                      </Col>
                    </Row>
                  </div>
                  <div style={{ height: subCompetenciesDataArrayAPI.length > 15 ? '940px' : '440px', position: 'relative' }}>
                    {
                      this.state.subcompetencyLoader ?
                        <div className="customSpinnerChart">
                          <Spinner style={{ width: '3rem', height: '3rem' }} size="lg" color="light" />
                        </div> :
                        subCompetenciesDataArrayAPI.length == 0 ? <h5 className="mt-5 text-danger">No Record Found</h5> :
                          <ResponsiveBar
                            data={subCompetenciesDataArrayAPI}
                            keys={['score']}
                            indexBy="value"
                            margin={{ top: 30, right: 40, bottom: 60, left: 400 }}
                            padding={0.2}
                            innerPadding={5}
                            label={d => `${d.value}%`}
                            // onClick={e => this.barChartClicked(e)}
                            groupMode="stacked"
                            // colors={{ scheme: 'paired' }}
                            colors={["#d63333", "#f706d5"]}
                            layout="horizontal"
                            enableGridY={false}
                            defs={[
                              {
                                id: 'dots',
                                type: 'patternDots',
                                background: 'inherit',
                                color: '#38bcb2',
                                size: 4,
                                padding: 1,
                                stagger: true
                              },
                              {
                                id: 'lines',
                                type: 'patternLines',
                                background: 'inherit',
                                color: '#eed312',
                                rotation: -45,
                                lineWidth: 6,
                                spacing: 10
                              }
                            ]}
                            fill={[
                              {
                                match: {
                                  id: 'fries'
                                },
                                id: 'dots'
                              },
                              {
                                match: {
                                  id: 'sandwich'
                                },
                                id: 'lines'
                              }
                            ]}
                            borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                            axisTop={null}
                            axisRight={null}
                            axisBottom={{
                              tickSize: 5,
                              tickPadding: 5,
                              tickRotation: 0,
                              legend: '',
                              legendPosition: 'middle',
                              legendOffset: 50
                            }}
                            axisLeft={{
                              tickSize: 5,
                              tickPadding: 5,
                              tickRotation: 0,
                              legend: '',
                              legendPosition: 'middle',
                              legendOffset: -60
                            }}
                            labelSkipWidth={12}
                            labelSkipHeight={12}
                            labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                            // legends={[
                            //     {
                            //         dataFrom: 'keys',
                            //         anchor: 'top-right',
                            //         direction: 'column',
                            //         justify: false,
                            //         translateX: -15,
                            //         translateY: -40,
                            //         itemsSpacing: 2,
                            //         itemWidth: 100,
                            //         itemHeight: 25,
                            //         itemDirection: 'left-to-right',
                            //         itemOpacity: 0.85,
                            //         symbolSize: 20,
                            //         effects: [
                            //             {
                            //                 on: 'hover',
                            //                 style: {
                            //                     itemOpacity: 1
                            //                 }
                            //             }
                            //         ]
                            //     }
                            // ]}
                            animate={true}
                            motionStiffness={90}
                            motionDamping={15}
                          />
                    }

                    {/* {
                            this.state.situationsAvgDataLoc == 0 ?
                              <p style={{fontSize: '12px', color: '#435f84', left: '5px'}} className="contactForCity" onClick={e => this.toggleModalInfo()}>* This graph represents partial data for this city. <span>Contact us</span> for the complete dataset for this city.</p>
                            : null
                          } */}

                  </div>
                </div>
              </Col>

              <Col md={{ size: 6 }}>
                <div className="chartBox">

                  <div className="titleHead">
                    <Row>
                      <Col md={{ size: 8 }}>

                        <h5>People assessed for {subCompetenciesDataArrayCountAPI.length} sub-competencies within <strong>{this.state.competencyName}</strong></h5>

                      </Col>
                      <Col style={{ textAlign: 'right' }} md={{ size: 4 }}>

                        <select className="customDropDown" style={{ top: 0 }} onChange={e => {
                          // this.updateCompetency(branches[branchIndex].mainCompetencies[e.target.value].name, e.target.value);
                          this.updateCompetencyFromDropDown(e.target.value)
                        }}>
                          {
                            mainCompetency.map((comp, key) =>
                              <option key={key} value={key} selected={key == this.state.competencyIndex}>{comp.competency_name}</option>
                            )
                          }
                        </select>
                      </Col>
                    </Row>
                  </div>

                  <div style={{ height: subCompetenciesDataArrayCountAPI.length > 15 ? '940px' : '440px', position: 'relative' }}>
                    {
                      this.state.subcompetencyLoader ?
                        <div className="customSpinnerChart">
                          <Spinner style={{ width: '3rem', height: '3rem' }} size="lg" color="light" />
                        </div>
                        : subCompetenciesDataArrayCountAPI.length == 0 ? <h5 className="mt-5 text-danger">No Record Found</h5> :
                          <ResponsiveBar
                            data={subCompetenciesDataArrayCountAPI}
                            keys={['score']}
                            indexBy="value"
                            margin={{ top: 30, right: 40, bottom: 60, left: 400 }}
                            padding={0.2}
                            innerPadding={5}
                            label={d => `${d.value}`}
                            // onClick={e => this.barChartClicked(e)}
                            groupMode="stacked"
                            // colors={{ scheme: 'paired' }}
                            colors={["#1f78b4", "#f706d5"]}
                            layout="horizontal"
                            enableGridY={false}
                            defs={[
                              {
                                id: 'dots',
                                type: 'patternDots',
                                background: 'inherit',
                                color: '#38bcb2',
                                size: 4,
                                padding: 1,
                                stagger: true
                              },
                              {
                                id: 'lines',
                                type: 'patternLines',
                                background: 'inherit',
                                color: '#eed312',
                                rotation: -45,
                                lineWidth: 6,
                                spacing: 10
                              }
                            ]}
                            fill={[
                              {
                                match: {
                                  id: 'fries'
                                },
                                id: 'dots'
                              },
                              {
                                match: {
                                  id: 'sandwich'
                                },
                                id: 'lines'
                              }
                            ]}
                            borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                            axisTop={null}
                            axisRight={null}
                            axisBottom={{
                              tickSize: 5,
                              tickPadding: 5,
                              tickRotation: 0,
                              legend: '',
                              legendPosition: 'middle',
                              legendOffset: 50
                            }}
                            axisLeft={{
                              tickSize: 5,
                              tickPadding: 5,
                              tickRotation: 0,
                              legend: '',
                              legendPosition: 'middle',
                              legendOffset: -60
                            }}
                            labelSkipWidth={12}
                            labelSkipHeight={12}
                            labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                            animate={true}
                            motionStiffness={90}
                            motionDamping={15}
                          />
                    }
                  </div>
                </div>
              </Col>


              <Col md={{ size: 12 }}>
                <div className="chartBox">
                  <div className="titleHead">
                    <Row>
                      <Col md={{ size: 8 }}>
                        {/* <h5>Candidates' distribution in <strong>{ branches[branchIndex].mainCompetencies[competencyIndex].name }</strong> competency based on Deficient, Matching and Exceeding (DME)</h5> */}
                        <h5><strong>{branchName}</strong> average score comparison based on Deficient, Matching and Exceeding (DME)</h5>
                      </Col>
                      <Col md={{ size: 4 }}>
                        <div className="quickIcons" style={{ right: '15px' }}>
                          <FontAwesomeIcon className="customIcon" icon={faCalendarCheck} size="1x" onClick={e => this.toggleModalInfo()} />
                          <FontAwesomeIcon className="customIcon" icon={faComment} size="1x" onClick={e => this.toggleModalChat()} />
                        </div>
                      </Col>
                    </Row>
                  </div>

                  {/* Here we'll call component for DME profile */}
                  {
                    // this.state.respondentsCatogorizeDMEData && this.state.respondentsCatogorizeDMEData != '' ?
                    <DMEProfileComponent
                      // data={this.state.respondentsCatogorizeDMEData}
                      // c_competency={ branches[branchIndex].mainCompetencies[competencyIndex].name }
                      // c_branch={branchName}
                      allData={{
                        branches: branches,
                        allBranches: allBranches,
                        branchIndex: branchIndex,
                        divisionIndex: divisionIndex,
                        sectionIndex: sectionIndex,
                        competencyIndex: competencyIndex,
                        selectedBranchIdValue: this.state.selectedBranchIdValue,
                        branchName: this.state.branchName,
                        allDivisions: this.state.allDivisions,
                        allSections: this.state.allSections,
                        dmeByDivision: this.props.dmeByDivision,
                        dmeBySection: this.props.dmeBySection,
                        mainCompetency: this.state.mainCompetency,
                        dmeCategorised: this.props.dmeCategorised,
                        selectedYear: this.state.selectedYear,
                        divisionHideGraph: this.state.divisionHideGraph,
                        // sectionHideGraph: this.state.sectionHideGraph
                        // divisionName : this.state.allDivisions[0].DIVISION
                      }}
                    />
                    // : null
                  }

                </div>
              </Col>


              {/* <Col md={{ size: 12 }}>

                      {
                        this.state.situations && this.state.situations.length ?
                          <DistributionAndComparisonTopCitiesComponent selectedRegion={this.state.selectedRegion} selectedSkill={this.state.selectedSkill} selectedSkillId={this.state.selectedSkillId} situations={this.state.situations} situationsAvg={this.state.situationsAvg} situationsAvgSpecificCity={this.state.situationsAvgSpecificCity} />
                        : null
                      }

                    </Col> */}



              <Col md={{ size: 12 }} style={{ display: 'none' }}>
                <div className="chartBox">
                  <div className="titleHead">
                    <Row>
                      <Col md={{ size: 7 }}>
                        <h5>Candidates of {branchName} were evaluated on each of the following Archetypes</h5>
                      </Col>
                      <Col style={{ textAlign: 'right' }} md={{ size: 5 }}>

                      </Col>
                    </Row>
                  </div>
                  <div>
                    <Row style={{ padding: '30px 50px' }}>

                      <Col md={{ size: 3 }}>
                        <Row>
                          {
                            this.state.archetypesList.map((archetype, key) =>
                              <Col key={key} md={{ size: 6 }} style={{ padding: 0 }}>
                                <div className="archetypeNameBox" id={"tooltip_" + key}>
                                  {
                                    archetype.name
                                  }
                                </div>
                                <Tooltip placement="right" isOpen={this.state.tooltipOpen[key]} target={"tooltip_" + key} toggle={e => this.toggleTooltip(key)} innerClassName="customTooltip">
                                  <p style={{ textAlign: 'left', padding: '10px', background: '#d63333', fontSize: '1rem' }}>
                                    {
                                      archetype.desc
                                    }
                                  </p>
                                </Tooltip>
                              </Col>
                            )
                          }
                        </Row>
                      </Col>

                      <Col md={{ size: 9 }}>
                        <Row style={{ padding: '0' }} className="competencyFramworkChartBox smallFont">
                          <Col md={{ size: 12 }} style={{ paddingRight: 0 }}>
                            <p style={{ color: '#7ba2dc', textAlign: 'left', padding: '12px 20px 0px 20px' }}>
                              The pattern of Archetypes across {branchName} can be seen in the following representation
                                  </p>
                            {
                              this.state.archetypesTreeMapData && this.state.archetypesTreeMapData != '' ?
                                <div style={{ height: 'calc(82vh)' }}>
                                  <ResponsiveTreeMap
                                    root={this.state.archetypesTreeMapData}
                                    identity="name"
                                    value="totalSample"
                                    tile="resquarify"
                                    innerPadding={2}
                                    outerPadding={2}
                                    margin={{ top: 10, right: 0, bottom: 10, left: 10 }}
                                    label={(e) => { return e.name }}
                                    // labelFormat=".0s"
                                    labelSkipSize={10}
                                    labelTextColor={{ theme: 'background' }}
                                    colors={["#0544a2", "#020a15", "#a6cee3"]}
                                    // colors={{ scheme: 'purpleRed_green' }}
                                    borderColor={{ from: 'color', modifiers: [['darker', 0.3]] }}
                                    animate={true}
                                    motionStiffness={90}
                                    motionDamping={11}
                                  />
                                </div>
                                : null
                            }
                          </Col>
                        </Row>
                      </Col>

                    </Row>



                    <Row>

                      <Col md={{ size: 10, offset: 1 }}>
                        <p style={{ color: '#7ba2dc', textAlign: 'center', padding: '15px 20px 0px 20px' }}>
                          The following framework represents the distribution of each Archetype across the Divisions in {branchName}.
                              </p>
                      </Col>

                      <Col md={{ size: 12 }}>
                        <div style={{ height: '500px', position: 'relative' }}>
                          {
                            archetypesDataChart.data && archetypesDataChart.data.length ?
                              <ResponsiveHeatMap
                                data={archetypesDataChart.data}
                                keys={archetypesDataChart.keys}
                                indexBy={archetypesDataChart.indexBy}
                                margin={{ top: 120, right: 60, bottom: 20, left: 280 }}
                                forceSquare={true}
                                axisTop={{ orient: 'top', tickSize: 15, tickPadding: 10, tickRotation: -40, legend: 'Archetypes', legendOffset: -110, legendPosition: 'middle' }}
                                axisRight={null}
                                axisBottom={null}
                                axisLeft={{
                                  orient: 'left',
                                  tickSize: 10,
                                  tickPadding: 5,
                                  tickRotation: 0,
                                  legend: 'Divisions',
                                  legendPosition: 'middle',
                                  legendOffset: -230
                                }}
                                cellOpacity={1}
                                cellBorderColor={{ from: 'color', modifiers: [['darker', 0.4]] }}
                                labelTextColor={{ from: 'color', modifiers: [['darker', 1.8]] }}
                                defs={[
                                  {
                                    id: 'lines',
                                    type: 'patternLines',
                                    background: 'inherit',
                                    color: 'rgba(0, 0, 0, 0.1)',
                                    rotation: -45,
                                    lineWidth: 4,
                                    spacing: 7
                                  }
                                ]}
                                fill={[{ id: 'lines' }]}
                                animate={true}
                                motionStiffness={80}
                                motionDamping={9}
                                hoverTarget="cell"
                                cellHoverOthersOpacity={0.25}
                              />
                              : null
                          }
                        </div>
                      </Col>

                    </Row>
                  </div>

                </div>
              </Col>







            </Row>
          </Container>
        </div>

        <div className={this.state.branchesListToggle ? "citiesListBarContainer active" : 'citiesListBarContainer'}>
          <Container fluid>
            <Row style={{ textAlign: 'left' }}>
              {
                this.state.isBranchesLoading ?
                  <div className="customSpinnerChart">
                    {/* <Spinner style={{width: '2rem', height: '2rem'}} size="lg" color="light" /> */}

                    <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                  </div>
                  :
                  <BranchesListSidebar selectedBranchId={selectedBranchIdValue} ></BranchesListSidebar>
              }

            </Row>
          </Container>

          <Button onClick={e => this.toggleBranchesBar()} className={this.state.branchesListToggle ? "citiesListBarSwitch opened" : "citiesListBarSwitch"} color="primary">
            {
              this.state.branchesListToggle ?
                <FontAwesomeIcon className="iconHere" icon={this.state.branchesListToggle ? faTimes : faBars} size="1x" />
                :
                <span>change<br />branch</span>
            }
          </Button>
        </div>

        <div className={this.state.integratedFormToggle ? "integratedFormSideBar active" : 'integratedFormSideBar'}>
          {
            this.state.formSource !== "" ?
              <iframe src={this.state.formSource}></iframe>
              : null
          }

          <Button onClick={e => this.closeModalForm()} className={this.state.integratedFormToggle ? this.state.integratedFormId == "ODY5Mg==" ? "citiesListBarSwitch green opened" : "citiesListBarSwitch yellow opened" : "citiesListBarSwitch"} color="primary">
            <FontAwesomeIcon className="iconHere" icon={faTimes} size="1x" />
          </Button>

        </div>

        {/* <Footer /> */}
      </div>
    );

  }
}

PagcorStatsComponent.propTypes = {
  getAllBranches: PropTypes.func.isRequired,
}

const mapStatesToProps = state => ({
  switchedBranch: state.webRed.switchedBranch,
  totalPeopleAssest: state.webRed.totalPeopleAssest,
  mainCompetencies: state.webRed.mainCompetencies,
  getSubCompetency: state.webRed.getSubCompetency,
  getAllCompetencyAvg: state.webRed.getAllCompetencyAvg,
  divisionsAndSections: state.webRed.divisionsAndSections,
  competencyByDivision: state.webRed.competencyByDivision,
  competencyBySection: state.webRed.competencyBySection,
  dmeByDivision: state.webRed.dmeByDivision,
  dmeBySection: state.webRed.dmeBySection,
  dmeCategorised: state.webRed.dmeCategorised,
  candidateDistribution: state.webRed.candidateDistribution,
  competencyYear: state.webRed.competencyYear
})

export default connect(mapStatesToProps, { getAllBranches, getTotalPeopleAssesst, updateBranchAction, getMainCompetencyList, getSubCompetencyRecord, getAllCompetencyAvgRecord, getAllDivisions, competencyByDivisionRecord, competencyBySectionRecord, divisionDMERecord, sectionDMERecord, categorisedDMERecord, candidateDistributionRecord, triggerYearChange, branchAvgDMERecord })(PagcorStatsComponent);