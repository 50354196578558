import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import Header from './../Header';
// import Footer from "./../Footer";

import AssessmentList from './AssessmentList';
import Instruction from './Instruction';

import { getAllAssessments } from './../../../actions/webRedActions';
import { getEmployeeProfile } from './../../../actions/utility';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// import dataDummy from './../dataDummy';

import {
    Spinner,
    Col
  } from "reactstrap";

// import readXlsxFile from 'read-excel-file';

// import { BrowserRouter as Router, Route, Link } from "react-router-dom";

class MyAssessmentsComponent extends Component {

  constructor(props) {
      super(props);
      this.state = {
        mainCompetencies : [],
        subCompetencies : [],
        subComptStatus : [],
        competenciesRpl : [],
        competencies : {},
        listingLoading : false,
        isInstructionRead : localStorage.getItem('isInstructionRead') !=null ? true : false,
        mainCompetenciesNames: 'Core, Organizational, Leadership and Technical',
        empData : getEmployeeProfile(),
        responseError : ""
      };
  }

  componentDidMount() {
    this.setState({
      listingLoading : true
    }, () => {
      const empData = getEmployeeProfile();
      this.props.getAllAssessments(empData.ID_NUMBER);
    })
  }

  componentWillReceiveProps(nextProps, thisProps) {
    // console.log(nextProps.fetchedAllAssessments);
    if(this.props.fetchedAllAssessments != nextProps.fetchedAllAssessments) {
      const data = nextProps.fetchedAllAssessments.result;
        if (typeof data !=="string") {
          
          this.setState({
            mainCompetencies : data.mainCompt,
            subCompetencies : data.subCompt,
            subComptStatus : data.subComptStatus,
            mainCompetenciesNames : data.mainCompetenciesNames,
            competenciesRpl : data.subComptRPL,
            responseError : "no"
          }, () => {

            this.setState({
              competencies : this.state.mainCompetencies.map((comp) => {
                return {
                  'id' : comp.competency_id,
                  'name' : comp.competency_name,
                  'subCompetencies' : typeof this.state.subCompetencies !=="undefined" ? this.state.subCompetencies[comp.competency_id] : []
                };
              }),
              listingLoading : false
            }, () => {
              console.log(this.state.competencies);
            })

          })
        }
        else {
          this.setState({responseError : "yes",listingLoading:false})
        }
    }
  }

  render() {

    return (
      <div className="MyAssessmentsComponent">
        <Header />
        {
          this.state.listingLoading ?
            <Col md={{size: 12}} style={{textAlign: 'center', padding: '30vh 0'}}>
              <Spinner color="primary" />
            </Col>
          : null
        }
        {
          this.state.competencies && this.state.competencies.length && this.state.responseError == "no" ?
          this.state.isInstructionRead == false ? 
            <Instruction mainCompetenciesNames={this.state.mainCompetenciesNames} empData={this.state.empData} onAgree={(isAgree)=>{
              if(isAgree) {
                localStorage.setItem('isInstructionRead',true)
                this.setState({isInstructionRead:true})
              }
              
            }}/>
            :
            <AssessmentList type="self" competencies={this.state.competencies} subComptStatus={this.state.subComptStatus} competenciesRpl={this.state.competenciesRpl}/>
          :
          
          this.state.responseError == "yes" &&  this.state.listingLoading == false ? 
            <span class ="text-cneter">Please Refresh - there is error in fetching data from server</span>
          :null
          
        }
        
        {/* <Footer /> */}
      </div>
    );
  }

}

MyAssessmentsComponent.propTypes = {
  getAllAssessments: PropTypes.func.isRequired
}

const mapStatesToProps = state => ({
  fetchedAllAssessments : state.webRed.fetchedAllAssessments
})

export default connect( mapStatesToProps, { getAllAssessments })( MyAssessmentsComponent );