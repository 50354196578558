import React, { Component } from "react";
import PropTypes from 'prop-types';
import { branchAvgDMERecord, compareDivisionDMERecord, compareSectionDMERecord } from '../../../actions/webRedActions';
import { connect } from 'react-redux';

import {
    Container,
    Row,
    Col,
    Tooltip,
    Button,
    Spinner,
    FormGroup,
    Input,
    Media,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter
} from "reactstrap";

import { ResponsiveBar } from '@nivo/bar';

import './../../../public/scss/DMEProfile.scss';

import devisionicon from './../../../public/images/branches/divisionIcon2.png';
import sectionicon from './../../../public/images/branches/sectionIcon2.png';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMale, faFemale } from '@fortawesome/free-solid-svg-icons';

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faMale } from '@fortawesome/free-solid-svg-icons';


class DMEProfileCompare extends Component {

    constructor(props) {
        super(props);

        this.state = {
            // data : this.props.data,
            respondentsCatogorizeDMEData: '',

            dataMappingArray: [{
                name: 'Deficient'
            }, {
                name: 'Matching'
            }, {
                name: 'Exceeding'
            }],
            allBranches: this.props.allData.allBranches,
            branches: this.props.allData.branches,
            branchIndex: this.props.allData.branchIndex,
            competencyIndex: this.props.allData.competencyIndex,
            divisionIndex: this.props.allData.divisionIndex,
            sectionIndex: this.props.allData.sectionIndex,

            compComparisonDataDME: {
                keys: [],
                data: []
            },
            compComparisonDataDivisionWiseDME: {
                keys: [],
                data: []
            },
            compComparisonDataBranchWiseTempDivisionArrayDME: [], // temporary random scores array for persistency while switching in different divisions to show in sections comparison chart
            compComparisonDataDivisionWiseTempDivisionArrayDME: [],
            compComparisonDataSectionWiseDME: {
                keys: [],
                data: []
            },
            selectedBranchIdValue: this.props.allData.selectedBranchIdValue,
            dataMappingArrayDME: [{
                name: 'D'
            }, {
                name: 'M'
            }, {
                name: 'E'
            }],
            compComparisonDataDMEApi: {
                keys: [],
                data: []
            },
            branchName: "",
            allDivisions: [],
            allSections: [],
            divisionName: '',
            sectionName: '',
            compComparisonDataDivisionWiseDMEApi: {
                keys: [],
                data: []
            },
            compComparisonDataSectionWiseDMEApi: {
                keys: [],
                data: []
            },
            mainCompetency: [],
            coreCategorisedDMEData: {
                data: []
            },
            competencyName: '',
            competencyIndexs: '',
            peopleInSection: '',
            selectedYear: '',
            sectionHideGraph: false,
            divisionHideGraph: false,
            dmeCategorisedHideGraph: false,

            testingArray: {
                data: [
                    {
                        "competency": 2019,
                        "Deficient": 12,
                        "Matching": 50,
                        "Exceeding": 16
                    },
                    {
                        "competency": 2020,
                        "Deficient": 122,
                        "Matching": 503,
                        "Exceeding": 216
                    }
                ],
                keys: ['Deficient', 'Matching', 'Exceeding'],
                indexBy: 'competency'
            },

            subcompetencyForCore: [],
            subcompetencyForOrganizational: [],
            subcompetencyForLeadership: [],
            subcompetencyForTechnical: [],

            organizingCategorisedDMEData: {
                data: []
            },
            technicalCategorisedDMEData: {
                data: []
            },
            leadershipCategorisedDMEData: {
                data: []
            },
        };

    }

    componentDidMount() {

        this.DMEDataMapping(this.state.branchIndex, this.state.competencyIndex);
        // this.props.branchAvgDMERecord(this.state.selectedBranchIdValue , "2019");
    }

    componentWillReceiveProps(nextProps, thisProps) {
        if (nextProps.allData.allSections.length > 0 && this.props.allData.allDivisions != nextProps.allData.allDivisions) {
            debugger;
            this.setState({
                divisionName: nextProps.allData.allDivisions[0].DIVISION
            })
        }
        if (nextProps.allData.allSections.length > 0 && this.props.allData.allSections != nextProps.allData.allSections) {
            this.setState({
                sectionName: nextProps.allData.allSections[0].SECTION
            })
        }
        this.setState({
            // data : nextProps.data,

            allBranches: nextProps.allData.allBranches,
            branches: nextProps.allData.branches,
            branchIndex: nextProps.allData.branchIndex,
            competencyIndex: nextProps.allData.competencyIndex,
            divisionIndex: nextProps.allData.divisionIndex,
            sectionIndex: nextProps.allData.sectionIndex,
            selectedBranchIdValue: nextProps.allData.selectedBranchIdValue,
            branchName: nextProps.allData.branchName,
            allDivisions: nextProps.allData.allDivisions,
            allSections: nextProps.allData.allSections,
            mainCompetency: nextProps.allData.mainCompetency,
            selectedYear: nextProps.allData.selectedYear,
            divisionHideGraph: nextProps.allData.divisionHideGraph,
            sectionHideGraph: nextProps.allData.sectionHideGraph

        }, () => {
            console.log(nextProps);

            this.averageWithAllBranchesMappingDME();

            this.DMEDataMapping(this.state.branchIndex, this.state.competencyIndex);
        })

        if (this.props.allData.selectedBranchIdValue != nextProps.allData.selectedBranchIdValue) {
            debugger;
            this.setState({
                compComparisonDataDMEApi: {
                    keys: [],
                    data: []
                },
                coreCategorisedDMEData: {
                    data: []
                },
                organizingCategorisedDMEData: {
                    data: []
                },
                technicalCategorisedDMEData: {
                    data: []
                },
                leadershipCategorisedDMEData: {
                    data: []
                },
                compComparisonDataDivisionWiseDMEApi: {
                    keys: [],
                    data: []
                },
                compComparisonDataSectionWiseDMEApi: {
                    keys: [],
                    data: []
                },
                subcompetencyForCore: [],
                subcompetencyForLeadership: [],
                subcompetencyForOrganizational: [],
                subcompetencyForTechnical: [],
                peopleInSection: 0,
                competencyIndexs: 0,
                selectedBranchIdValue: nextProps.allData.selectedBranchIdValue,
            }, () => {
                // this.props.branchAvgDMERecord(this.state.selectedBranchIdValue , this.state.selectedYear);
            })
            // this.props.divisionDMERecord(this.state.selectedBranchIdValue , '2019' , this.state.allDivisions[0].DIVISION)
        }


        else if (this.props.allData.selectedYear != nextProps.allData.selectedYear) {
            debugger;
            this.setState({
                compComparisonDataDMEApi: {
                    keys: [],
                    data: []
                },
                coreCategorisedDMEData: {
                    data: []
                },
                compComparisonDataDivisionWiseDMEApi: {
                    keys: [],
                    data: []
                },
                compComparisonDataSectionWiseDMEApi: {
                    keys: [],
                    data: []
                },
                peopleInSection: 0,
                competencyIndexs: 0,
                selectedBranchIdValue: nextProps.allData.selectedBranchIdValue,
            }, () => {
                // this.props.branchAvgDMERecord(this.state.selectedBranchIdValue , this.state.selectedYear);
            })
        }

        else if (this.props.branchAvgDme != nextProps.branchAvgDme) {
            debugger;
            try {
                var dataArray = [];
                this.state.dataMappingArrayDME.map((type, key) => {
                    dataArray.push({
                        "type": type.name,
                        '2019': nextProps.branchAvgDme[2019].branchData[type.name].perc,
                        "2020": nextProps.branchAvgDme[2020].branchData[type.name].perc
                    });
                })


                this.setState({
                    compComparisonDataDMEApi: {
                        keys: ["2019", "2020"],
                        data: dataArray
                    },
                }, () => {
                    console.log(this.state.compComparisonDataDMEApi);
                })
            }
            catch (err) {
                debugger;
                window.alert(err)
            }
        }


        else if (this.props.dmeByDivision != nextProps.dmeByDivision) {
            debugger;



            try {
                var dataArray = [];
                this.state.dataMappingArrayDME.map((type, key) => {
                    dataArray.push({
                        "type": type.name,
                        '2019': nextProps.dmeByDivision[2019].divisionData[type.name].perc,
                        "2020": nextProps.dmeByDivision[2020].divisionData[type.name].perc
                    });
                })


                this.setState({
                    compComparisonDataDivisionWiseDMEApi: {
                        keys: ["2019", "2020"],
                        data: dataArray
                    },
                }, () => {
                    console.log(this.state.compComparisonDataDMEApi);
                })
            }
            catch (err) {
                debugger;
                window.alert(err)
            }

        }


        else if (this.props.dmeBySection != nextProps.dmeBySection) {
            debugger;

            try {
                var dataArray = [];
                this.state.dataMappingArrayDME.map((type, key) => {
                    dataArray.push({
                        "type": type.name,
                        '2019': nextProps.dmeBySection[2019].sectionData[type.name].perc,
                        "2020": nextProps.dmeBySection[2020].sectionData[type.name].perc
                    });
                })


                this.setState({
                    compComparisonDataSectionWiseDMEApi: {
                        keys: ["2019", "2020"],
                        data: dataArray
                    },
                }, () => {
                    console.log(this.state.compComparisonDataDMEApi);
                })
            }
            catch (err) {
                debugger;
                window.alert(err)
            }

        }

        else if (this.props.allData.dmeCategorised != nextProps.allData.dmeCategorised) {


            if (Object.keys(nextProps.allData.dmeCategorised[2019].comptwiseData).length > 0) {
                debugger;

                this.setState({
                    subcompetencyForCore: Object.keys(nextProps.allData.dmeCategorised[2019].comptwiseData['CORE']),
                    subcompetencyForOrganizational: Object.keys(nextProps.allData.dmeCategorised[2019].comptwiseData['ORGANIZATIONAL']),
                    subcompetencyForLeadership: Object.keys(nextProps.allData.dmeCategorised[2019].comptwiseData['LEADERSHIP']),
                    subcompetencyForTechnical: Object.keys(nextProps.allData.dmeCategorised[2019].comptwiseData['TECHNICAL']),
                }, () => {
                    this.categorizedDMEChangeCore(this.state.subcompetencyForCore[0]);
                    this.categorizedDMEChangeOrganizing(this.state.subcompetencyForOrganizational[0])
                    this.categorizedDMEChangeTechnical(this.state.subcompetencyForTechnical[0])
                    this.categorizedDMEChangeLeadership(this.state.subcompetencyForLeadership[0])
                })

            }
        }
    }

    categorizedDMEChangeCore = (subComp) => {
        this.setState({
            coreCategorisedDMEData: {
                data: []
            },
            //   competencyName: this.state.mainCompetency[competencyIndex].competency_name
        })

        debugger;
        if (Object.keys(this.props.allData.dmeCategorised[2019].comptwiseData).length > 0 && Object.keys(this.props.allData.dmeCategorised[2020].comptwiseData).length > 0) {
            debugger;
            this.setState({
                coreCategorisedDMEData: {
                    data: [{
                        "competency": "2019",
                        "Deficient": this.props.allData.dmeCategorised[2019].comptwiseData['CORE'][subComp]['D'].totall,
                        "Matching": this.props.allData.dmeCategorised[2019].comptwiseData['CORE'][subComp]['M'].totall,
                        "Exceeding": this.props.allData.dmeCategorised[2019].comptwiseData['CORE'][subComp]['E'].totall
                    },
                    {
                        "competency": "2020",
                        "Deficient": this.props.allData.dmeCategorised[2020].comptwiseData['CORE'][subComp]['D'].totall,
                        "Matching": this.props.allData.dmeCategorised[2020].comptwiseData['CORE'][subComp]['M'].totall,
                        "Exceeding": this.props.allData.dmeCategorised[2020].comptwiseData['CORE'][subComp]['E'].totall
                    }],
                    keys: ['Deficient', 'Matching', 'Exceeding'],
                    indexBy: 'competency'
                }
            })
        }
        else {
            debugger;
            this.setState({
                coreCategorisedDMEData: {
                    data: [{
                        "competency": "2019",
                        "Deficient": this.props.allData.dmeCategorised[2019].comptwiseData['CORE'][subComp]['D'].totall,
                        "Matching": this.props.allData.dmeCategorised[2019].comptwiseData['CORE'][subComp]['M'].totall,
                        "Exceeding": this.props.allData.dmeCategorised[2019].comptwiseData['CORE'][subComp]['E'].totall
                    },
                    {
                        "competency": "2020",
                        "Deficient": 0,
                        "Matching": 0,
                        "Exceeding": 0
                    }],
                    keys: ['Deficient', 'Matching', 'Exceeding'],
                    indexBy: 'competency'
                }
            })
        }
    }
    categorizedDMEChangeOrganizing = (subComp) => {
        this.setState({
            organizingCategorisedDMEData: {
                data: []
            },
            //   competencyName: this.state.mainCompetency[competencyIndex].competency_name
        })
        debugger;
        if (Object.keys(this.props.allData.dmeCategorised[2019].comptwiseData).length > 0 && Object.keys(this.props.allData.dmeCategorised[2020].comptwiseData).length > 0) {
            this.setState({
                organizingCategorisedDMEData: {
                    data: [{
                        "competency": "2019",
                        "Deficient": this.props.allData.dmeCategorised[2019].comptwiseData['ORGANIZATIONAL'][subComp]['D'].totall,
                        "Matching": this.props.allData.dmeCategorised[2019].comptwiseData['ORGANIZATIONAL'][subComp]['M'].totall,
                        "Exceeding": this.props.allData.dmeCategorised[2019].comptwiseData['ORGANIZATIONAL'][subComp]['E'].totall
                    },
                    {
                        "competency": "2020",
                        "Deficient": this.props.allData.dmeCategorised[2020].comptwiseData['ORGANIZATIONAL'][subComp]['D'].totall,
                        "Matching": this.props.allData.dmeCategorised[2020].comptwiseData['ORGANIZATIONAL'][subComp]['M'].totall,
                        "Exceeding": this.props.allData.dmeCategorised[2020].comptwiseData['ORGANIZATIONAL'][subComp]['E'].totall
                    }],
                    keys: ['Deficient', 'Matching', 'Exceeding'],
                    indexBy: 'competency'
                }
            })
        }
        else {
            this.setState({
                organizingCategorisedDMEData: {
                    data: [{
                        "competency": "2019",
                        "Deficient": this.props.allData.dmeCategorised[2019].comptwiseData['ORGANIZATIONAL'][subComp]['D'].totall,
                        "Matching": this.props.allData.dmeCategorised[2019].comptwiseData['ORGANIZATIONAL'][subComp]['M'].totall,
                        "Exceeding": this.props.allData.dmeCategorised[2019].comptwiseData['ORGANIZATIONAL'][subComp]['E'].totall
                    },
                    {
                        "competency": "2020",
                        "Deficient": 0,
                        "Matching": 0,
                        "Exceeding": 0
                    }],
                    keys: ['Deficient', 'Matching', 'Exceeding'],
                    indexBy: 'competency'
                }
            })
        }

    }

    categorizedDMEChangeLeadership = (subComp) => {
        this.setState({
            leadershipCategorisedDMEData: {
                data: []
            },
            //   competencyName: this.state.mainCompetency[competencyIndex].competency_name
        })

        if (Object.keys(this.props.allData.dmeCategorised[2019].comptwiseData).length > 0 && Object.keys(this.props.allData.dmeCategorised[2020].comptwiseData).length > 0) {
            debugger;
            this.setState({
                leadershipCategorisedDMEData: {
                    data: [{
                        "competency": "2019",
                        "Deficient": this.props.allData.dmeCategorised[2019].comptwiseData['LEADERSHIP'][subComp]['D'].totall,
                        "Matching": this.props.allData.dmeCategorised[2019].comptwiseData['LEADERSHIP'][subComp]['M'].totall,
                        "Exceeding": this.props.allData.dmeCategorised[2019].comptwiseData['LEADERSHIP'][subComp]['E'].totall
                    },
                    {
                        "competency": "2020",
                        "Deficient": this.props.allData.dmeCategorised[2020].comptwiseData['LEADERSHIP'][subComp]['D'].totall,
                        "Matching": this.props.allData.dmeCategorised[2020].comptwiseData['LEADERSHIP'][subComp]['M'].totall,
                        "Exceeding": this.props.allData.dmeCategorised[2020].comptwiseData['LEADERSHIP'][subComp]['E'].totall
                    }],
                    keys: ['Deficient', 'Matching', 'Exceeding'],
                    indexBy: 'competency'
                }
            })
        }
        else {
            debugger;
            this.setState({
                leadershipCategorisedDMEData: {
                    data: [{
                        "competency": "2019",
                        "Deficient": this.props.allData.dmeCategorised[2019].comptwiseData['LEADERSHIP'][subComp]['D'].totall,
                        "Matching": this.props.allData.dmeCategorised[2019].comptwiseData['LEADERSHIP'][subComp]['M'].totall,
                        "Exceeding": this.props.allData.dmeCategorised[2019].comptwiseData['LEADERSHIP'][subComp]['E'].totall
                    },
                    {
                        "competency": "2020",
                        "Deficient": 0,
                        "Matching": 0,
                        "Exceeding": 0
                    }],
                    keys: ['Deficient', 'Matching', 'Exceeding'],
                    indexBy: 'competency'
                }
            })
        }

    }

    categorizedDMEChangeTechnical = (subComp) => {
        this.setState({
            technicalCategorisedDMEData: {
                data: []
            },
            //   competencyName: this.state.mainCompetency[competencyIndex].competency_name
        })

        if (Object.keys(this.props.allData.dmeCategorised[2019].comptwiseData).length > 0 && Object.keys(this.props.allData.dmeCategorised[2020].comptwiseData).length > 0) {
            debugger;
            this.setState({
                technicalCategorisedDMEData: {
                    data: [{
                        "competency": "2019",
                        "Deficient": this.props.allData.dmeCategorised[2019].comptwiseData['TECHNICAL'][subComp]['D'].totall,
                        "Matching": this.props.allData.dmeCategorised[2019].comptwiseData['TECHNICAL'][subComp]['M'].totall,
                        "Exceeding": this.props.allData.dmeCategorised[2019].comptwiseData['TECHNICAL'][subComp]['E'].totall
                    },
                    {
                        "competency": "2020",
                        "Deficient": this.props.allData.dmeCategorised[2020].comptwiseData['TECHNICAL'][subComp]['D'].totall,
                        "Matching": this.props.allData.dmeCategorised[2020].comptwiseData['TECHNICAL'][subComp]['M'].totall,
                        "Exceeding": this.props.allData.dmeCategorised[2020].comptwiseData['TECHNICAL'][subComp]['E'].totall
                    }],
                    keys: ['Deficient', 'Matching', 'Exceeding'],
                    indexBy: 'competency'
                }
            })
        }
        else {
            debugger;
            this.setState({
                technicalCategorisedDMEData: {
                    data: [{
                        "competency": "2019",
                        "Deficient": this.props.allData.dmeCategorised[2019].comptwiseData['TECHNICAL'][subComp]['D'].totall,
                        "Matching": this.props.allData.dmeCategorised[2019].comptwiseData['TECHNICAL'][subComp]['M'].totall,
                        "Exceeding": this.props.allData.dmeCategorised[2019].comptwiseData['TECHNICAL'][subComp]['E'].totall
                    },
                    {
                        "competency": "2020",
                        "Deficient": 0,
                        "Matching": 0,
                        "Exceeding": 0
                    }],
                    keys: ['Deficient', 'Matching', 'Exceeding'],
                    indexBy: 'competency'
                }
            })
        }

    }

    categorizedDMEChange = (branchIndex, competencyIndex) => {
        this.setState({
            coreCategorisedDMEData: {
                data: []
            },
            competencyName: this.state.mainCompetency[competencyIndex].competency_name
        })
        var tempArrayData = [];
        var objectKeys = [];
        debugger;
        objectKeys = Object.keys(this.props.allData.dmeCategorised.comptwiseData[this.props.allData.mainCompetency[competencyIndex].competency_name]);
        objectKeys.map((item, index) => {
            tempArrayData.push({
                "competency": item,
                "Deficient": this.props.allData.dmeCategorised.comptwiseData[this.props.allData.mainCompetency[competencyIndex].competency_name][item]['D'].totall,
                "Matching": this.props.allData.dmeCategorised.comptwiseData[this.props.allData.mainCompetency[competencyIndex].competency_name][item]['M'].totall,
                "Exceeding": this.props.allData.dmeCategorised.comptwiseData[this.props.allData.mainCompetency[competencyIndex].competency_name][item]['E'].totall
            })
        })
        debugger;
        console.log("HAMZA is on:", tempArrayData)
        console.log("HAMZA Object Keys:", Object.keys(this.props.allData.dmeCategorised.comptwiseData[this.props.allData.mainCompetency[competencyIndex].competency_name]))
        setTimeout(() => {
            this.setState({
                coreCategorisedDMEData: {
                    data: tempArrayData,
                    keys: ['Deficient', 'Matching', 'Exceeding'],
                    indexBy: 'competency'
                }
            },
                () => {
                    console.log(this.state.coreCategorisedDMEData);
                })

        }, 200)
    }

    DMEDataMapping = (branchIndex, competencyIndex) => {

        console.log(branchIndex, competencyIndex);

        const { branches } = this.state;
        // mapping of DME pie charts data starts here
        var tempArrayData = [];
        // var tempArrayKeys = [];
        // var d_array = [];
        // var d_totalNumber = 0;

        // var m_array = [];
        // var m_totalNumber = 0;

        // var e_array = [];
        // var e_totalNumber = 0;

        branches[branchIndex].mainCompetencies[competencyIndex].subCompetencies.map((comp, key) => {
            tempArrayData.push({
                "competency": comp.name,
                "Deficient": comp.d_total,
                "Matching": comp.m_total,
                "Exceeding": comp.e_total
            })
            // d_array.push({
            //     "id" : comp.name,
            //     "label" : comp.name,
            //     "value" : comp.d_total ? comp.d_total : 1
            // });
            // d_totalNumber += comp.d_total ? comp.d_total : 1;

            // m_array.push({
            //     "id" : comp.name,
            //     "label" : comp.name,
            //     "value" : comp.m_total ? comp.m_total : 1
            // });
            // m_totalNumber += comp.m_total ? comp.m_total : 1;

            // e_array.push({
            //     "id" : comp.name,
            //     "label" : comp.name,
            //     "value" : comp.e_total ? comp.e_total : 1
            // });
            // e_totalNumber += comp.e_total ? comp.e_total : 1;
        })

        setTimeout(() => {

            this.setState({
                // respondentsCatogorizeDMEData : [{
                //         name : "deificient",
                //         heading : "<span>D</span>eficient",
                //         para : "Participants whose average score lies under required competencies score",
                //         data : d_array,
                //         total : d_totalNumber,
                //     },{
                //         name : "matching",
                //         heading : "<span>M</span>atching",
                //         para : "Participants whose average score is matching the required competencies score",
                //         data : m_array,
                //         total : m_totalNumber
                //     },{
                //         name : "exceeding",
                //         heading : "<span>E</span>xceeding",
                //         para : "Participants whose average score is exceeding the required competencies score",
                //         data : e_array,
                //         total : e_totalNumber
                //     }]
                respondentsCatogorizeDMEData: {
                    data: tempArrayData,
                    keys: ['Deficient', 'Matching', 'Exceeding'],
                    indexBy: 'competency'
                }
            },
                () => {
                    console.log(this.state.respondentsCatogorizeDMEData);
                })

        }, 200);

        // mapping of DME pie charts data ends here

    }

    getRandomDMEValue = (min, max) => {
        return parseInt(Math.random() * (max - min) + min);
    }

    averageWithAllBranchesMappingDME = () => {

        // mapping data for comparison all branches chart for DME

        const { branches, branchIndex, competencyIndex, allBranches, divisionIndex, sectionIndex } = this.state;

        var dataArray = [];
        var tempArrayRandomVal = [];
        this.state.dataMappingArray.map((type, key) => {
            var randomValue = this.getRandomDMEValue(10, 43);
            tempArrayRandomVal.push(randomValue);
            // if(key == 1) {
            //     randomValue = this.getRandomDMEValue(10, 43);
            // }
            // if(key == 2) {
            //     randomValue = this.getRandomDMEValue(10, 43);
            // }
            dataArray.push({
                "type": type.name,
                [branches[branchIndex].name]: randomValue,
                "All Branches": key == 0 ? 36 : key == 1 ? 21 : 42
            });
        })

        this.setState({
            compComparisonDataDME: {
                keys: ["All Branches", branches[branchIndex].name],
                data: dataArray
            }
        }, () => {
            console.log(this.state.compComparisonDataDME);

            this.setState({
                compComparisonDataBranchWiseTempDivisionArrayDME: tempArrayRandomVal
            }, () => {
                this.averageWithBranchesMappingForDivisionsDME(); // reflects the divisions chart
            })
        })

    }

    averageWithBranchesMappingForDivisionsDME = () => {

        // mapping data for comparison all branches chart

        const { branches, branchIndex, allBranches, divisionIndex } = this.state;

        var dataArrayDivision = [];
        var tempArrayRandomVal = [];
        this.state.dataMappingArray.map((type, key) => {

            //temperory work done starts here
            var randomValue = this.getRandomDMEValue(10, 43);
            tempArrayRandomVal.push(randomValue);
            //temperory work done stops here

            dataArrayDivision.push({
                "type": type.name,
                [branches[branchIndex].divisions[divisionIndex].name]: randomValue, // temporary random average score
                [branches[branchIndex].name]: this.state.compComparisonDataBranchWiseTempDivisionArrayDME[key], // temporary random average score,
                "All Branches": key == 0 ? 36 : key == 1 ? 21 : 42
            });
        })

        this.setState({
            compComparisonDataDivisionWiseDME: {
                keys: ["All Branches", branches[branchIndex].name, branches[branchIndex].divisions[divisionIndex].name],
                data: dataArrayDivision
            }
        }, () => {
            console.log(this.state.compComparisonDataDivisionWiseDME);

            this.setState({
                compComparisonDataDivisionWiseTempDivisionArrayDME: tempArrayRandomVal
            }, () => {
                this.averageWithBranchesMappingForSectionsDME(); // reflects the sections chart
            })
        })


    }

    averageWithBranchesMappingForSectionsDME = () => {

        const { branches, branchIndex, allBranches, divisionIndex, sectionIndex } = this.state;

        var dataArraySection = [];
        this.state.dataMappingArray.map((type, key) => {
            dataArraySection.push({
                "type": type.name,
                [branches[branchIndex].divisions[divisionIndex].sections[sectionIndex].name]: this.getRandomDMEValue(10, 43), // temporary random average score,
                [branches[branchIndex].divisions[divisionIndex].name]: this.state.compComparisonDataDivisionWiseTempDivisionArrayDME[key], // temporary random average score
                [branches[branchIndex].name]: this.state.compComparisonDataBranchWiseTempDivisionArrayDME[key],
                "All Branches": key == 0 ? 36 : key == 1 ? 21 : 42
            });
        })

        this.setState({
            compComparisonDataSectionWiseDME: {
                keys: ["All Branches", this.state.branchName, branches[branchIndex].divisions[divisionIndex].name, branches[branchIndex].divisions[divisionIndex].sections[sectionIndex].name],
                data: dataArraySection
            }
        }, () => {
            console.log(this.state.compComparisonDataSectionWiseDME);
        })

    }


    render() {
        console.log("LEADER", this.state.subcompetencyForLeadership);
        // let dataCompelete = this.state.data;

        const { branches, allBranches, branchIndex, competencyIndex, divisionIndex, sectionIndex } = this.state;

        return (
            <div style={{ textAlign: 'center' }}>
                <Row className="DMEProfileBox" style={{ background: '#0d1623' }}>

                    <Col md={{ size: 12 }}>

                        <div className="chartBox" style={{ background: 'transparent' }}>
                            <div style={{ height: '400px', position: 'relative' }}>

                                {
                                    this.state.compComparisonDataDMEApi.data.length == 0 ?
                                        <div className="customSpinnerChart">
                                            <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                                        </div>
                                        :
                                        <ResponsiveBar
                                            data={this.state.compComparisonDataDMEApi.data}
                                            keys={this.state.compComparisonDataDMEApi.keys}
                                            indexBy="type"
                                            margin={{ top: 60, right: 100, bottom: 60, left: 100 }}
                                            padding={0.6}
                                            innerPadding={10}
                                            label={d => `${d.value}%`}
                                            // onClick={e => this.barChartClicked(e)}
                                            groupMode="grouped"
                                            colors={["#3d1f42", "#542b5a", "#68366f", "#93519c", "#68356f", "#93519c"]}
                                            // colors={["#f706d5", "#770367" ]}
                                            // colors={["#68366f", "#3d1f42" ]}
                                            layout="vertical"
                                            enableGridY={false}
                                            defs={[
                                                {
                                                    id: 'dots',
                                                    type: 'patternDots',
                                                    background: 'inherit',
                                                    color: '#38bcb2',
                                                    size: 4,
                                                    padding: 1,
                                                    stagger: true
                                                },
                                                {
                                                    id: 'lines',
                                                    type: 'patternLines',
                                                    background: 'inherit',
                                                    color: '#eed312',
                                                    rotation: -45,
                                                    lineWidth: 6,
                                                    spacing: 10
                                                }
                                            ]}
                                            fill={[
                                                {
                                                    match: {
                                                        id: 'fries'
                                                    },
                                                    id: 'dots'
                                                },
                                                {
                                                    match: {
                                                        id: 'sandwich'
                                                    },
                                                    id: 'lines'
                                                }
                                            ]}
                                            borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                                            axisTop={null}
                                            axisRight={null}
                                            axisBottom={{
                                                tickSize: 5,
                                                tickPadding: 5,
                                                tickRotation: 0,
                                                legend: '',
                                                legendPosition: 'middle',
                                                legendOffset: 50
                                            }}
                                            axisLeft={{
                                                tickSize: 5,
                                                tickPadding: 5,
                                                tickRotation: 0,
                                                legend: '',
                                                legendPosition: 'middle',
                                                legendOffset: -60
                                            }}
                                            labelSkipWidth={12}
                                            labelSkipHeight={12}
                                            labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                                            legends={[
                                                {
                                                    dataFrom: 'keys',
                                                    anchor: 'top-right',
                                                    direction: 'column',
                                                    justify: false,
                                                    translateX: -15,
                                                    translateY: -60,
                                                    itemsSpacing: 2,
                                                    itemWidth: 100,
                                                    itemHeight: 25,
                                                    itemDirection: 'left-to-right',
                                                    itemOpacity: 0.85,
                                                    symbolSize: 20,
                                                    effects: [
                                                        {
                                                            on: 'hover',
                                                            style: {
                                                                itemOpacity: 1
                                                            }
                                                        }
                                                    ]
                                                }
                                            ]}
                                            animate={true}
                                            motionStiffness={90}
                                            motionDamping={15}
                                        />

                                }
                            </div>
                        </div>
                    </Col>



                    <div className="seperatorHeading">
                        Average score of {this.state.divisionName} with in {this.state.branchName}

                        <select className="customDropDown" onChange={e => {
                            this.setState({
                                sectionIndex: 0, // reset the sectionIndex
                                divisionIndex: 0,
                                divisionName: e.target.value,
                                compComparisonDataDivisionWiseDMEApi: {
                                    keys: [],
                                    data: []
                                },
                            }, () => {
                                this.props.compareDivisionDMERecord(this.state.selectedBranchIdValue, "2019,2020", this.state.divisionName)
                            })
                        }}>
                            {
                                typeof this.state.allDivisions == 'object' ?
                                    this.state.allDivisions.map((division, key) =>
                                        <option key={key} value={division.DIVISION}>{division.DIVISION}</option>
                                    ) : null
                            }
                        </select>

                    </div>

                    {/* <Col md={{ size: 8, offset: 1 }}>
                        
                        <p style={{color: '#7ba2dc', textAlign: 'left', marginTop: '30px'}}>
                        This bar chart highlights the performance for the Divisions in {this.state.branchName} for each of the four main competencies and has three points of relevance and comparison:
                        <ol style={{marginTop: '20px'}}>
                            <li>Average percentage score of each of the {branches[branchIndex].totalDivisions} divisions for {this.state.branchName}</li>
                            <li>Average percentage score of each Division in {this.state.branchName} as compared to the {this.state.branchName}'s overall scores</li>
                            <li>Average percentage score of each Division in {this.state.branchName} as compared to overall result for this assessment</li>
                        </ol>
                        </p>

                    </Col> */}

                    <Col md={{ size: 9 }} style={{ paddingTop: '25px' }}>
                        <div className="chartBox" style={{ background: 'transparent' }}>
                            <div style={{ height: '400px', position: 'relative' }}>
                                {
                                    this.state.compComparisonDataDivisionWiseDMEApi.data.length == 0 ?
                                        <div className="customSpinnerChart">
                                            <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                                        </div>
                                        :
                                        <ResponsiveBar
                                            data={this.state.compComparisonDataDivisionWiseDMEApi.data}
                                            keys={this.state.compComparisonDataDivisionWiseDMEApi.keys}
                                            indexBy="type"
                                            margin={{ top: 100, right: 0, bottom: 60, left: 100 }}
                                            padding={0.3}
                                            innerPadding={10}
                                            label={d => `${d.value}%`}
                                            // onClick={e => this.barChartClicked(e)}
                                            groupMode="grouped"
                                            colors={["#3d1f42", "#542b5a", "#68366f", "#93519c", "#68356f", "#93519c"]}
                                            // colors={["#f706d5", "#770367" ]}
                                            // colors={["#68366f", "#3d1f42" ]}
                                            layout="vertical"
                                            enableGridY={false}
                                            defs={[
                                                {
                                                    id: 'dots',
                                                    type: 'patternDots',
                                                    background: 'inherit',
                                                    color: '#38bcb2',
                                                    size: 4,
                                                    padding: 1,
                                                    stagger: true
                                                },
                                                {
                                                    id: 'lines',
                                                    type: 'patternLines',
                                                    background: 'inherit',
                                                    color: '#eed312',
                                                    rotation: -45,
                                                    lineWidth: 6,
                                                    spacing: 10
                                                }
                                            ]}
                                            fill={[
                                                {
                                                    match: {
                                                        id: 'fries'
                                                    },
                                                    id: 'dots'
                                                },
                                                {
                                                    match: {
                                                        id: 'sandwich'
                                                    },
                                                    id: 'lines'
                                                }
                                            ]}
                                            borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                                            axisTop={null}
                                            axisRight={null}
                                            axisBottom={{
                                                tickSize: 5,
                                                tickPadding: 5,
                                                tickRotation: 0,
                                                legend: '',
                                                legendPosition: 'middle',
                                                legendOffset: 50
                                            }}
                                            axisLeft={{
                                                tickSize: 5,
                                                tickPadding: 5,
                                                tickRotation: 0,
                                                legend: '',
                                                legendPosition: 'middle',
                                                legendOffset: -60
                                            }}
                                            labelSkipWidth={12}
                                            labelSkipHeight={12}
                                            labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                                            legends={[
                                                {
                                                    dataFrom: 'keys',
                                                    anchor: 'top-right',
                                                    direction: 'column',
                                                    justify: false,
                                                    translateX: -100,
                                                    translateY: -100,
                                                    itemsSpacing: 2,
                                                    itemWidth: 150,
                                                    itemHeight: 25,
                                                    itemDirection: 'left-to-right',
                                                    itemOpacity: 0.85,
                                                    symbolSize: 20,
                                                    effects: [
                                                        {
                                                            on: 'hover',
                                                            style: {
                                                                itemOpacity: 1
                                                            }
                                                        }
                                                    ]
                                                }
                                            ]}
                                            animate={true}
                                            motionStiffness={90}
                                            motionDamping={15}
                                        />
                                }
                            </div>
                        </div>
                    </Col>

                    {/* <Col md={{ size: 3 }}>
                      <div className="schoolBox topSchoolBox bottomBox">
                        <Row>
                          <Col>
                          
                            <div className="oneInfo">
                              <div className="iconBox">
                                
                                <Row>
                                  <Col>
                                    <h3>{this.state.allDivisions.length}</h3>
                                    <p style={{color: '#68366f'}}>Divisions in<br/>{this.state.branchName}</p>
                                  </Col>
                                  <Col>
                                    <img src={devisionicon} />
                                  </Col>
                                </Row>

                              </div>
                            </div>
                            
                            <div className="oneInfo">
                              <div className="iconBox">

                                <Row>
                                  <Col>
                                    <h3>{this.state.allSections.length}</h3>
                                    <p style={{color: '#68366f'}}>Sections in<br/>{this.state.divisionName}</p>
                                  </Col>
                                  <Col>
                                    <img src={sectionicon} />
                                  </Col>
                                </Row>
                                
                              </div>
                            </div>

                          </Col>
                        </Row>
                      </div>
                    </Col> */}


                    {this.state.sectionHideGraph ? null :
                        <React.Fragment>
                            <div className="seperatorHeading">
                                Average score of {this.state.sectionName} with in {this.state.divisionName}

                                <select className="customDropDown" onChange={e => {
                                    this.setState({
                                        sectionIndex: 0,
                                        sectionName: e.target.value,
                                        peopleInSection: 0,
                                        compComparisonDataSectionWiseDMEApi: {
                                            keys: [],
                                            data: []
                                        },
                                    }, () => {
                                        this.props.compareSectionDMERecord(this.state.selectedBranchIdValue, "2019,2020", this.state.sectionName)
                                    })
                                }}>
                                    {
                                        typeof this.state.allSections == 'object' ?
                                            this.state.allSections.map((section, key) =>
                                                <option key={key} value={section.SECTION}>{section.SECTION}</option>
                                            ) : null
                                    }
                                </select>

                            </div>

                            {/* <Col md={{ size: 8, offset: 1 }}>
                        
                        <p style={{color: '#7ba2dc', textAlign: 'left', marginTop: '30px'}}>
                        This bar chart is a further breakdown of each Division and highlights the performance for the Sections in each Division in {this.state.branchName} for each of the four main competencies and has four points of relevance and comparison:
                        <ol style={{marginTop: '20px'}}>
                            <li>Average percentage score of each of the Section in the selected Division for {this.state.branchName}</li>
                            <li>Average percentage score of each Section as compared to the overall scores for the selected Division in {this.state.branchName} as compared to the {this.state.branchName}'s overall scores</li>
                            <li>Average percentage score of each Section for the selected Division as compared to the {this.state.branchName}'s overall scores</li>
                            <li>Average percentage score of each Section for the selected Division in {this.state.branchName} as compared to overall result for this assessment </li>
                        </ol>
                        </p>

                    </Col> */}

                            <Col md={{ size: 9 }} style={{ paddingTop: '25px' }}>
                                <div className="chartBox" style={{ background: 'transparent' }}>
                                    <div style={{ height: '500px', position: 'relative' }}>
                                        {
                                            this.state.compComparisonDataSectionWiseDMEApi && this.state.compComparisonDataSectionWiseDMEApi.data.length != 0 ?
                                                <ResponsiveBar
                                                    data={this.state.compComparisonDataSectionWiseDMEApi.data}
                                                    keys={this.state.compComparisonDataSectionWiseDMEApi.keys}
                                                    indexBy="type"
                                                    margin={{ top: 140, right: 0, bottom: 60, left: 100 }}
                                                    padding={0.25}
                                                    innerPadding={10}
                                                    label={d => `${d.value}%`}
                                                    // onClick={e => this.barChartClicked(e)}
                                                    groupMode="grouped"
                                                    colors={["#3d1f42", "#542b5a", "#68366f", "#93519c", "#68356f", "#93519c"]}
                                                    // colors={["#f706d5", "#770367" ]}
                                                    // colors={["#68366f", "#3d1f42" ]}
                                                    layout="vertical"
                                                    enableGridY={false}
                                                    defs={[
                                                        {
                                                            id: 'dots',
                                                            type: 'patternDots',
                                                            background: 'inherit',
                                                            color: '#38bcb2',
                                                            size: 4,
                                                            padding: 1,
                                                            stagger: true
                                                        },
                                                        {
                                                            id: 'lines',
                                                            type: 'patternLines',
                                                            background: 'inherit',
                                                            color: '#eed312',
                                                            rotation: -45,
                                                            lineWidth: 6,
                                                            spacing: 10
                                                        }
                                                    ]}
                                                    fill={[
                                                        {
                                                            match: {
                                                                id: 'fries'
                                                            },
                                                            id: 'dots'
                                                        },
                                                        {
                                                            match: {
                                                                id: 'sandwich'
                                                            },
                                                            id: 'lines'
                                                        }
                                                    ]}
                                                    borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                                                    axisTop={null}
                                                    axisRight={null}
                                                    axisBottom={{
                                                        tickSize: 5,
                                                        tickPadding: 5,
                                                        tickRotation: 0,
                                                        legend: '',
                                                        legendPosition: 'middle',
                                                        legendOffset: 50
                                                    }}
                                                    axisLeft={{
                                                        tickSize: 5,
                                                        tickPadding: 5,
                                                        tickRotation: 0,
                                                        legend: '',
                                                        legendPosition: 'middle',
                                                        legendOffset: -60
                                                    }}
                                                    labelSkipWidth={12}
                                                    labelSkipHeight={12}
                                                    labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                                                    legends={[
                                                        {
                                                            dataFrom: 'keys',
                                                            anchor: 'top-right',
                                                            direction: 'column',
                                                            justify: false,
                                                            translateX: -100,
                                                            translateY: -135,
                                                            itemsSpacing: 2,
                                                            itemWidth: 150,
                                                            itemHeight: 25,
                                                            itemDirection: 'left-to-right',
                                                            itemOpacity: 0.85,
                                                            symbolSize: 20,
                                                            effects: [
                                                                {
                                                                    on: 'hover',
                                                                    style: {
                                                                        itemOpacity: 1
                                                                    }
                                                                }
                                                            ]
                                                        }
                                                    ]}
                                                    animate={true}
                                                    motionStiffness={90}
                                                    motionDamping={15}
                                                />
                                                : <div className="customSpinnerChart">
                                                    <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                                                </div>
                                        }
                                    </div>
                                </div>
                            </Col>

                            {/* <Col md={{ size: 3 }}>
                    <div className="schoolBox topSchoolBox bottomBox">
                    <Row>
                        <Col>
                        <div className="oneInfo">
                            <div className="iconBox">

                            <Row>
                                <Col>
                                <h3>{this.state.peopleInSection}</h3>
                                <p style={{color: '#68366f'}}>People<br/>in {this.state.branchName}</p>
                                </Col>
                                <Col>
                                <div style={{margin: '30px 0px 10px 0'}}>
                                    <FontAwesomeIcon className="iconHere" icon={faMale} size="2x" />
                                    <FontAwesomeIcon className="iconHere" icon={faFemale} size="2x" />
                                </div>
                                </Col>
                            </Row>
                            
                            </div>
                        </div>
                        </Col>
                    </Row>
                    </div>
                </Col> */}
                        </React.Fragment>}


                    {this.state.dmeCategorisedHideGraph ? null :
                        <React.Fragment>
                            <Col md={{ size: 12 }}>

                                <hr />

                            </Col>

                            <Col md={{ size: 7, offset: 1 }}>
                                <p style={{ color: '#fff', textAlign: 'left', marginBottom: '20px', borderTop: '2px #316ea1 solid', paddingTop: '15px' }}>
                                    The graph below shows the frequency of Candidates in {this.state.branchName} whose scores for CORE competencies, in comparison to the required level, are either deficient, matching or exceeding the benchmark.
                    </p>
                            </Col>

                            <Col md={{ size: 3 }}>
                                <select className="customDropDown" onChange={e => {
                                    this.setState({
                                        competencyIndexs: e.target.value
                                    }, () => {
                                        this.categorizedDMEChangeCore(this.state.competencyIndexs);
                                    })
                                }}>
                                    {
                                        this.state.subcompetencyForCore.map((comp, key) =>
                                            <option key={key} value={comp} selected={key == this.state.competencyIndexs}>{comp}</option>
                                        )
                                    }
                                </select>
                            </Col>


                            <Col md={{ size: 7, offset: 3 }}>
                                <div style={{ height: '200px' }}>
                                    {
                                        this.state.coreCategorisedDMEData && this.state.coreCategorisedDMEData.data.length != 0 ?
                                            <ResponsiveBar
                                                data={this.state.coreCategorisedDMEData.data}
                                                keys={this.state.coreCategorisedDMEData.keys}
                                                indexBy={this.state.respondentsCatogorizeDMEData.indexBy}
                                                margin={{ top: 60, right: 0, bottom: 50, left: 100 }}
                                                padding={0.3}
                                                layout="horizontal"
                                                colors={{ scheme: 'set1' }}
                                                defs={[
                                                    {
                                                        id: 'dots',
                                                        type: 'patternDots',
                                                        background: 'inherit',
                                                        color: '#38bcb2',
                                                        size: 4,
                                                        padding: 1,
                                                        stagger: true
                                                    },
                                                    {
                                                        id: 'lines',
                                                        type: 'patternLines',
                                                        background: 'inherit',
                                                        color: '#eed312',
                                                        rotation: -45,
                                                        lineWidth: 6,
                                                        spacing: 10
                                                    }
                                                ]}
                                                fill={[
                                                    {
                                                        match: {
                                                            id: 'fries'
                                                        },
                                                        id: 'dots'
                                                    },
                                                    {
                                                        match: {
                                                            id: 'sandwich'
                                                        },
                                                        id: 'lines'
                                                    }
                                                ]}
                                                borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                                                axisTop={null}
                                                axisRight={null}

                                                labelSkipWidth={12}
                                                labelSkipHeight={12}
                                                labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                                                legends={[
                                                    {
                                                        dataFrom: 'keys',
                                                        anchor: 'top-left',
                                                        direction: 'row',
                                                        justify: false,
                                                        translateX: -250,
                                                        translateY: -50,
                                                        itemsSpacing: 2,
                                                        itemWidth: 110,
                                                        itemHeight: 20,
                                                        itemDirection: 'left-to-right',
                                                        itemOpacity: 0.85,
                                                        symbolSize: 20,
                                                        effects: [
                                                            {
                                                                on: 'hover',
                                                                style: {
                                                                    itemOpacity: 1
                                                                }
                                                            }
                                                        ]
                                                    }
                                                ]}
                                                animate={true}
                                                motionStiffness={90}
                                                motionDamping={15}
                                            />
                                            : <div className="customSpinnerChart">
                                                <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                                            </div>
                                    }
                                </div>
                            </Col>
                        </React.Fragment>}

                    <React.Fragment>
                        <Col md={{ size: 12 }}>

                            <hr />

                        </Col>

                        <Col md={{ size: 8, offset: 1 }}>
                            <p style={{ color: '#fff', textAlign: 'left', marginBottom: '20px', borderTop: '2px #316ea1 solid', paddingTop: '15px' }}>
                                The graph below shows the frequency of Candidates in {this.state.branchName} whose scores for ORGANIZATIONAL competencies, in comparison to the required level, are either deficient, matching or exceeding the benchmark.
                    </p>
                        </Col>

                        <Col md={{ size: 2 }}>
                            <select className="customDropDown" onChange={e => {
                                this.setState({
                                    competencyIndexs: e.target.value
                                }, () => {
                                    this.categorizedDMEChangeOrganizing(this.state.competencyIndexs);
                                })
                            }}>
                                {
                                    this.state.subcompetencyForOrganizational.map((comp, key) =>
                                        <option key={key} value={comp} selected={key == this.state.competencyIndexs}>{comp}</option>
                                    )
                                }
                            </select>
                        </Col>


                        <Col md={{ size: 7, offset: 3 }}>
                            <div style={{ height: '200px' }}>
                                {
                                    this.state.organizingCategorisedDMEData.data.length != 0 ?
                                        <ResponsiveBar
                                            data={this.state.organizingCategorisedDMEData.data}
                                            keys={this.state.organizingCategorisedDMEData.keys}
                                            indexBy={this.state.respondentsCatogorizeDMEData.indexBy}
                                            margin={{ top: 60, right: 0, bottom: 50, left: 100 }}
                                            padding={0.3}
                                            layout="horizontal"
                                            colors={{ scheme: 'set1' }}
                                            defs={[
                                                {
                                                    id: 'dots',
                                                    type: 'patternDots',
                                                    background: 'inherit',
                                                    color: '#38bcb2',
                                                    size: 4,
                                                    padding: 1,
                                                    stagger: true
                                                },
                                                {
                                                    id: 'lines',
                                                    type: 'patternLines',
                                                    background: 'inherit',
                                                    color: '#eed312',
                                                    rotation: -45,
                                                    lineWidth: 6,
                                                    spacing: 10
                                                }
                                            ]}
                                            fill={[
                                                {
                                                    match: {
                                                        id: 'fries'
                                                    },
                                                    id: 'dots'
                                                },
                                                {
                                                    match: {
                                                        id: 'sandwich'
                                                    },
                                                    id: 'lines'
                                                }
                                            ]}
                                            borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                                            axisTop={null}
                                            axisRight={null}

                                            labelSkipWidth={12}
                                            labelSkipHeight={12}
                                            labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                                            legends={[
                                                {
                                                    dataFrom: 'keys',
                                                    anchor: 'top-left',
                                                    direction: 'row',
                                                    justify: false,
                                                    translateX: -250,
                                                    translateY: -50,
                                                    itemsSpacing: 2,
                                                    itemWidth: 110,
                                                    itemHeight: 20,
                                                    itemDirection: 'left-to-right',
                                                    itemOpacity: 0.85,
                                                    symbolSize: 20,
                                                    effects: [
                                                        {
                                                            on: 'hover',
                                                            style: {
                                                                itemOpacity: 1
                                                            }
                                                        }
                                                    ]
                                                }
                                            ]}
                                            animate={true}
                                            motionStiffness={90}
                                            motionDamping={15}
                                        />
                                        : <div className="customSpinnerChart">
                                            <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                                        </div>
                                }
                            </div>
                        </Col>
                    </React.Fragment>
                    <React.Fragment>
                        <Col md={{ size: 12 }}>

                            <hr />

                        </Col>

                        <Col md={{ size: 7, offset: 1 }}>
                            <p style={{ color: '#fff', textAlign: 'left', marginBottom: '20px', borderTop: '2px #316ea1 solid', paddingTop: '15px' }}>
                                The graph below shows the frequency of Candidates in {this.state.branchName} whose scores for LEADERSHIP competencies, in comparison to the required level, are either deficient, matching or exceeding the benchmark.
                    </p>
                        </Col>

                        <Col md={{ size: 3 }}>
                            <select className="customDropDown" onChange={e => {
                                this.setState({
                                    competencyIndexs: e.target.value
                                }, () => {
                                    this.categorizedDMEChangeLeadership(this.state.competencyIndexs);
                                })
                            }}>
                                {
                                    this.state.subcompetencyForLeadership.map((comp, key) =>
                                        <option key={key} value={comp} selected={key == this.state.competencyIndexs}>{comp}</option>
                                    )
                                }
                            </select>
                        </Col>


                        <Col md={{ size: 7, offset: 3 }}>
                            <div style={{ height: '200px' }}>
                                {
                                    this.state.leadershipCategorisedDMEData.data.length != 0 ?
                                        <ResponsiveBar
                                            data={this.state.leadershipCategorisedDMEData.data}
                                            keys={this.state.leadershipCategorisedDMEData.keys}
                                            indexBy={this.state.respondentsCatogorizeDMEData.indexBy}
                                            margin={{ top: 60, right: 0, bottom: 50, left: 100 }}
                                            padding={0.3}
                                            layout="horizontal"
                                            colors={{ scheme: 'set1' }}
                                            defs={[
                                                {
                                                    id: 'dots',
                                                    type: 'patternDots',
                                                    background: 'inherit',
                                                    color: '#38bcb2',
                                                    size: 4,
                                                    padding: 1,
                                                    stagger: true
                                                },
                                                {
                                                    id: 'lines',
                                                    type: 'patternLines',
                                                    background: 'inherit',
                                                    color: '#eed312',
                                                    rotation: -45,
                                                    lineWidth: 6,
                                                    spacing: 10
                                                }
                                            ]}
                                            fill={[
                                                {
                                                    match: {
                                                        id: 'fries'
                                                    },
                                                    id: 'dots'
                                                },
                                                {
                                                    match: {
                                                        id: 'sandwich'
                                                    },
                                                    id: 'lines'
                                                }
                                            ]}
                                            borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                                            axisTop={null}
                                            axisRight={null}
                                            labelSkipWidth={12}
                                            labelSkipHeight={12}
                                            labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                                            legends={[
                                                {
                                                    dataFrom: 'keys',
                                                    anchor: 'top-left',
                                                    direction: 'row',
                                                    justify: false,
                                                    translateX: -250,
                                                    translateY: -50,
                                                    itemsSpacing: 2,
                                                    itemWidth: 110,
                                                    itemHeight: 20,
                                                    itemDirection: 'left-to-right',
                                                    itemOpacity: 0.85,
                                                    symbolSize: 20,
                                                    effects: [
                                                        {
                                                            on: 'hover',
                                                            style: {
                                                                itemOpacity: 1
                                                            }
                                                        }
                                                    ]
                                                }
                                            ]}
                                            animate={true}
                                            motionStiffness={90}
                                            motionDamping={15}
                                        />
                                        : <div className="customSpinnerChart">
                                            <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                                        </div>
                                }
                            </div>
                        </Col>
                    </React.Fragment>
                    <React.Fragment>
                        <Col md={{ size: 12 }}>

                            <hr />

                        </Col>

                        <Col md={{ size: 6, offset: 1 }}>
                            <p style={{ color: '#fff', textAlign: 'left', marginBottom: '20px', borderTop: '2px #316ea1 solid', paddingTop: '15px' }}>
                                The graph below shows the frequency of Candidates in {this.state.branchName} whose scores for TECHNICAL competencies, in comparison to the required level, are either deficient, matching or exceeding the benchmark.
                    </p>
                        </Col>

                        <Col md={{ size: 4 }}>
                            <select className="customDropDown" onChange={e => {
                                this.setState({
                                    competencyIndexs: e.target.value
                                }, () => {
                                    this.categorizedDMEChangeTechnical(this.state.competencyIndexs);
                                })
                            }}>
                                {
                                    this.state.subcompetencyForTechnical.map((comp, key) =>
                                        <option key={key} value={comp} selected={key == this.state.competencyIndexs}>{comp}</option>
                                    )
                                }
                            </select>
                        </Col>


                        <Col md={{ size: 7, offset: 3 }}>
                            <div style={{ height: '200px' }}>
                                {
                                    this.state.technicalCategorisedDMEData.data.length != 0 ?
                                        <ResponsiveBar
                                            data={this.state.technicalCategorisedDMEData.data}
                                            keys={this.state.technicalCategorisedDMEData.keys}
                                            indexBy={this.state.respondentsCatogorizeDMEData.indexBy}
                                            margin={{ top: 60, right: 0, bottom: 50, left: 100 }}
                                            padding={0.3}
                                            layout="horizontal"
                                            colors={{ scheme: 'set1' }}
                                            defs={[
                                                {
                                                    id: 'dots',
                                                    type: 'patternDots',
                                                    background: 'inherit',
                                                    color: '#38bcb2',
                                                    size: 4,
                                                    padding: 1,
                                                    stagger: true
                                                },
                                                {
                                                    id: 'lines',
                                                    type: 'patternLines',
                                                    background: 'inherit',
                                                    color: '#eed312',
                                                    rotation: -45,
                                                    lineWidth: 6,
                                                    spacing: 10
                                                }
                                            ]}
                                            fill={[
                                                {
                                                    match: {
                                                        id: 'fries'
                                                    },
                                                    id: 'dots'
                                                },
                                                {
                                                    match: {
                                                        id: 'sandwich'
                                                    },
                                                    id: 'lines'
                                                }
                                            ]}
                                            borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                                            axisTop={null}
                                            axisRight={null}
                                            labelSkipWidth={12}
                                            labelSkipHeight={12}
                                            labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                                            legends={[
                                                {
                                                    dataFrom: 'keys',
                                                    anchor: 'top-left',
                                                    direction: 'row',
                                                    justify: false,
                                                    translateX: -250,
                                                    translateY: -50,
                                                    itemsSpacing: 2,
                                                    itemWidth: 110,
                                                    itemHeight: 20,
                                                    itemDirection: 'left-to-right',
                                                    itemOpacity: 0.85,
                                                    symbolSize: 20,
                                                    effects: [
                                                        {
                                                            on: 'hover',
                                                            style: {
                                                                itemOpacity: 1
                                                            }
                                                        }
                                                    ]
                                                }
                                            ]}
                                            animate={true}
                                            motionStiffness={90}
                                            motionDamping={15}
                                        />
                                        : <div className="customSpinnerChart">
                                            <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                                        </div>
                                }
                            </div>
                        </Col>
                    </React.Fragment>

                </Row>

            </div>
        );

    }
}

DMEProfileCompare.propTypes = {
    // triggerFormIframe: PropTypes.func.isRequired,
}

const mapStatesToProps = state => ({
    branchAvgDme: state.webRed.branchAvgDmeCompare,
    dmeByDivision: state.webRed.dmeByDivisionCompare,
    dmeBySection: state.webRed.dmeBySectionCompare
})

export default connect(mapStatesToProps, { branchAvgDMERecord, compareDivisionDMERecord, compareSectionDMERecord })(DMEProfileCompare);