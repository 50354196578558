import React, { Component } from "react";

import { getChildQuestions } from './../../../../actions/formActions';
import { updateAnswerStatusPagcor, restObjVal} from './../../../../actions/webRedActions';

import { getUserData, getEmployeeProfile,getYear } from './../../../../actions/utility';
// import store from './../../store';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import $ from 'jquery'; 

import {
    CustomInput,
    Input,
    Label
} from "reactstrap";
var saveAnswers = {};
class MultiChoiceComponentSingle extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isCheckedState : false
        };
    }
    /**
     * utility function for validate the answers before storing
     * @param answers {object}
     * @return boolean
     */
    validateAnswers(answers) {

        if(Object.keys(answers).length) {
            
            console.log('length:',Object.keys(answers).length)
            var keys = Object.keys(answers);
            var flag = 1;
            var hasRequiredVal = 0 ;
            for(var i=0; i < keys.length; i++) {
                console.log('loop time');
                console.log('in loop:',answers[keys[i]].ans.indexOf('Not At All'))
               
                if(answers[keys[i]].ans.indexOf('Not At All') > 0 ) {
                    hasRequiredVal+=1;
                    console.log('here in iff:',hasRequiredVal);
                }
                
                if(keys.length == flag) {
                    console.log('here data',hasRequiredVal);
                    return hasRequiredVal;
                }
            flag++;    
            }
        }
        else
            return 0;
    }
    componentDidMount() {
        if(getYear() == "2020") {

            try {
                if(window.performance.getEntriesByType('navigation')[0].type == 'reload')
                    localStorage.removeItem('saveAnswers')
            }
            catch(err) {

            }

            if(localStorage.getItem('saveAnswers') !=null) {
                saveAnswers = JSON.parse(localStorage.getItem('saveAnswers'));
                console.log('answers:',saveAnswers);
            }
            else {
                saveAnswers = {};
            }
            var answer = this.props.answer;
            var isSelfRating = localStorage.getItem('isSelfRating');
            var supervisorId = 0;
            console.log('works again');
            if(isSelfRating && isSelfRating != undefined && isSelfRating != ''){

                if(isSelfRating == 0 || isSelfRating == '0'){
                
                var supervisorFormsData = JSON.parse(localStorage.getItem('supervisorFormsData'));

                supervisorId = supervisorFormsData.id;
                }
            }
            let that = this;  
            // using jQuery event handeling for radio button for single options
            $('input[type=radio][name=answer_'+ answer.questionid +']').change(function(e) {
            
                console.log('Faheem you in change event');
                var isChecked = $('input[type=radio][id=answer_' + answer.answerid + '_' + answer.questionid +']').is(':checked');
                // // console.log(e.target.value);
                var userData = getUserData();
                
                if (isChecked) {
                    if(answer.answer.indexOf('Set') != -1) {
                        this.props.restObjVal();
                        saveAnswers = {};
                        console.log(saveAnswers,':i want to check option answers:',answer);
                        
                        let checkBefor = that.validateAnswers(saveAnswers);
                        
                        console.log('here is the response:',checkBefor);

                        if(checkBefor == 0 ) {
                            
                            saveAnswers[answer.questionid] = {ans:answer.answer};
                            
                            localStorage.setItem('saveAnswers',JSON.stringify(saveAnswers));

                            console.log(saveAnswers,'here is the answer:',checkBefor);

                            $("#"+e.target.id).parents('.QuestionComponent').removeClass("errorRequired");

                            $(this).prop('checked', true);

                            this.props.getChildQuestions(true, btoa(userData.form_id), userData.id, userData.engineid, userData.user_type, answer.questionid, answer.answerid, userData.user_token, 2, userData.form_id, 1, "on",supervisorId); // get this answers sub questions if this is true

                            this.updateStatusAnswerPagcorDb(userData, answer,supervisorId);

                            return false;
                            // this.updateLocalStorageAns(answer.questionid, answer.answerid, true); // update storage for answes / values
                        }
                        if(checkBefor == 1 && answer.answer.indexOf('Not At All') == -1) {

                            saveAnswers[answer.questionid] = {ans:answer.answer};
                            
                            localStorage.setItem('saveAnswers',JSON.stringify(saveAnswers));

                            console.log(saveAnswers,'here is the answer:',checkBefor);

                            $("#"+e.target.id).parents('.QuestionComponent').removeClass("errorRequired");

                            $(this).prop('checked', true);

                            this.props.getChildQuestions(true, btoa(userData.form_id), userData.id, userData.engineid, userData.user_type, answer.questionid, answer.answerid, userData.user_token, 2, userData.form_id, 1, "on",supervisorId); // get this answers sub questions if this is true

                            this.updateStatusAnswerPagcorDb(userData, answer,supervisorId);
                            return false;
                            // this.updateLocalStorageAns(answer.questionid, answer.answerid, true); // update storage for answes / values
                        }
                        else {

                            console.log('here is the else');

                            document.getElementById(e.target.id).checked = false;

                            alert('You cannot select the option Not At All / Not Applicable more than once');

                            return false;
                        }
                    }
                    else {
                        let checkBefor = that.validateAnswers(saveAnswers);
                        
                        console.log('here is the response:',checkBefor);

                        if(checkBefor == 0 ) {
                            
                            saveAnswers[answer.questionid] = {ans:answer.answer};
                            
                            localStorage.setItem('saveAnswers',JSON.stringify(saveAnswers));

                            console.log(saveAnswers,'here is the answer:',checkBefor);

                            $("#"+e.target.id).parents('.QuestionComponent').removeClass("errorRequired");

                            $(this).prop('checked', true);

                            this.props.getChildQuestions(true, btoa(userData.form_id), userData.id, userData.engineid, userData.user_type, answer.questionid, answer.answerid, userData.user_token, 2, userData.form_id, 1, "on",supervisorId); // get this answers sub questions if this is true

                            this.updateStatusAnswerPagcorDb(userData, answer,supervisorId);

                            return false;
                            // this.updateLocalStorageAns(answer.questionid, answer.answerid, true); // update storage for answes / values
                        }
                        if(checkBefor == 1 && answer.answer.indexOf('Not At All') == -1) {

                            saveAnswers[answer.questionid] = {ans:answer.answer};
                            
                            localStorage.setItem('saveAnswers',JSON.stringify(saveAnswers));

                            console.log(saveAnswers,'here is the answer:',checkBefor);

                            $("#"+e.target.id).parents('.QuestionComponent').removeClass("errorRequired");

                            $(this).prop('checked', true);

                            this.props.getChildQuestions(true, btoa(userData.form_id), userData.id, userData.engineid, userData.user_type, answer.questionid, answer.answerid, userData.user_token, 2, userData.form_id, 1, "on",supervisorId); // get this answers sub questions if this is true

                            this.updateStatusAnswerPagcorDb(userData, answer,supervisorId);
                            return false;
                            // this.updateLocalStorageAns(answer.questionid, answer.answerid, true); // update storage for answes / values
                        }
                        else {

                            console.log('here is the else');

                            document.getElementById(e.target.id).checked = false;

                            alert('You cannot select the option Not At All / Not Applicable more than once');

                            return false;
                        }
                    }

                }
                else {

                    $(this).prop('checked', false);

                    // alert("Transfer Thai Gayo");
                    this.props.getChildQuestions(false, btoa(userData.form_id), userData.id, userData.engineid, userData.user_type, answer.questionid, answer.answerid, userData.user_token, 2, userData.form_id, 0, "on"); // get this answers sub questions if this is true

                    // this.updateStatusAnswerPagcorDb(userData, answer);

                    // this.updateLocalStorageAns(answer.questionid, answer.answerid, false); // update storage for answes / values

                }
            }.bind(this));


            // var checkValue = localStorage.getItem('value_' + answer.questionid + "_" + answer.answerid);
            // if(checkValue && (checkValue == true || checkValue == 'true')) {
                
            //     $('input[type=radio][id=answer_' + answer.answerid + '_' + answer.questionid +']').prop('checked', true);

            //     var userData = getUserData();
                
            //     this.props.getChildQuestions(true, btoa(userData.form_id), userData.id, userData.engineid, userData.user_type, answer.questionid, answer.answerid, userData.user_token, 2, userData.form_id, 1, "on"); // get this answers sub questions if this is true
            
            // }
        }
        else {
            try {
                if(window.performance.getEntriesByType('navigation')[0].type == 'reload')
                    localStorage.removeItem('userAns')
            }
            catch(err) {

            }
            
            var answer = this.props.answer;

            var userData = getUserData();
            // using jQuery event handeling for radio button for single options
            $('input[type=radio][name=answer_'+ answer.questionid +']').change(function(e) {
                var isChecked = $('input[type=radio][id=answer_' + answer.answerid + '_' + answer.questionid +']').is(':checked');
                console.log('isChecked:',answer.questionid);
                if(isChecked) {
                    $("#"+e.target.id).parents('.QuestionComponent').removeClass("errorRequired");
                    var usrAns = localStorage.userAns;
                    if(usrAns == null) {
                        var prepareToSave = {};
                        prepareToSave[answer.questionid] = answer.answerid;
                        localStorage.setItem('userAns',JSON.stringify(prepareToSave));
                    }
                    else {
                        usrAns = JSON.parse(usrAns);
                        usrAns[answer.questionid] = answer.answerid;
                        localStorage.setItem('userAns',JSON.stringify(usrAns));
                    }
                    $(this).prop('checked', true);

                    this.props.getChildQuestions(true, btoa(userData.form_id), userData.id, userData.engineid, userData.user_type, answer.questionid, answer.answerid, userData.user_token, 2, userData.form_id, 1, "on"); // get this answers sub questions if this is true

                    this.updateStatusAnswerPagcorDb(userData, answer);
                }
                else {
                    $(this).prop('checked', false);
                }

            }.bind(this));
        }
        
    }

    // updateLocalStorageAns = (ques, ans, value) => {
    //     console.log(ans, ques, value);
    //     localStorage.setItem('value_' + ques + "_" + ans, value);
    // }

    updateStatusAnswerPagcorDb = (userData, answer,supId=0) => {
        
        const empData = getEmployeeProfile();

        var isSelfRating = localStorage.getItem('isSelfRating');
        var isSelf = true;
        var subOrdinateIdentity = '';
        if(isSelfRating && isSelfRating != undefined && isSelfRating != ''){
            if(isSelfRating == 0 || isSelfRating == '0'){
                isSelf = false;
                subOrdinateIdentity = JSON.parse(localStorage.getItem('subOrdinateIdentity'));
            }
        }
        // var user_Data = JSON.parse(localStorage.getItem('userData'));
        // var sup_id = ''
        // if(user_Data.id)
        // {
        //     sup_id=user_Data.id
        // }
        this.props.updateAnswerStatusPagcor(userData.form_id, isSelf ? empData.ID_NUMBER : subOrdinateIdentity.ID_NUMBER, userData.id, answer.questionid, answer.answerid, isSelf ? 0 : 1,supId);
    }

    componentWillReceiveProps(nextProps, thisProps) {
        
        
    }


    render() {

        const answer = this.props.answer;
        // console.log(answer)
        const key = this.props.index;

        return (
            <div className="MultiChoiceComponentSingle">
                <Input type="radio" name={'answer_' + answer.questionid}
                // value={'answer_' + answer.answerid + '_' + answer.questionid}
                value={key+1}
                id={'answer_' + answer.answerid + '_' + answer.questionid}
                assessvalue={key+1} />
                <Label for={'answer_' + answer.answerid + '_' + answer.questionid} dangerouslySetInnerHTML={{__html: answer.answer}}></Label>
            </div>
        );
        
    }

}

MultiChoiceComponentSingle.propTypes = {
    getChildQuestions: PropTypes.func.isRequired,
    updateAnswerStatusPagcor: PropTypes.func.isRequired,
    restObjVal : PropTypes.func.isRequired
}

const mapStatesToProps = state => ({
    restObjValRes: state.formRed.restObjValRes
})

export default connect(mapStatesToProps, { getChildQuestions, updateAnswerStatusPagcor,restObjVal })(MultiChoiceComponentSingle);