import React, { Component, Fragment } from "react";
import { Redirect } from "react-router-dom";
// import Header from '../../Header';
// import Footer from "./../Footer";

import {
    fetchCompleteStats, getAdditionalData,
    getMainGroupsData, getAssessGroupDeparts, getAssessGroupDivisions,
    getAssessSOGGroupDivisions, getAssesstDiv,
    getAssesstDivSesction, fethcAdminAssessmentHistoryFilter,
    getAssesstCFDivSesction, getAssesstSOGDivSesction, getAssesstDivHierarchy,
    fetchAssessStatistics,
    fetchSubGroupsOrLocationsOrSOG,
    getAssesstDeptsHierarchy,
    getAssesstSectionHierarchy,
    getAssesstPCHierarchy,
    getLocationDivsHierarchy,
    getLocationSectionHierarchy,
    getLocationPCHierarchy,
    fetchLocationBranches,
    getAssessBranchDivsHierarchy,
    getAssessBranchSectionHierarchy,
    getAssessBranchPCHierarchy
} from '../../../../../src/actions/webRedActions';

// import { getEmployeeProfile } from '../../../../actions/utility';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import axios from 'axios';
import { getBaseUrl, accessToken, getYear, generateToken } from './../../../../actions/utility';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css


// import userData from './../userData';

import {
    Button,
    Container,
    Row,
    Col,
    Media,
    Form,
    FormGroup,
    Label,
    Input,
    Collapse,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Spinner,
    Progress,
    Modal, ModalHeader, ModalBody, ModalFooter
} from "reactstrap";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';

// import readXlsxFile from 'read-excel-file';
import EmployeeProfile from "./EmployeeProfile";
import EmployeeSearchForm from "./EmployeeSearchForm";
import AddEmployeeProfile from "./AddEmployeeProfile";
import AdminHeader from './../AdminHeader';
import SideMenu from './../sidMenu/SideMenu';
import { getAdminUserData } from "../../../../../src/actions/utility";

// import { BrowserRouter as Router, Route, Link } from "react-router-dom";
// import { ProSidebar, SidebarHeader, SidebarFooter, SidebarContent, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faBars,faHome,faUsers } from '@fortawesome/free-solid-svg-icons';
import 'react-pro-sidebar/dist/css/styles.css';
import UpdateYear from "../../../updateyear";

class AssessmentStatistics extends Component {

    constructor(props) {
        super(props);
        this.state = {
            headerTitle: "Assessment Statistics",
            competencies: [],
            completedata: '',
            isOpen: [true, true, true, true],

            divisionList: [],
            sectionList: [],
            branchList: [],
            deptList: [],
            pcList: [],

            DIVISION: '',
            SECTION: '',
            BRANCH: '',
            DEPT: '',
            PC: '',
            branchId: '',
            division: '',
            section: '',
            divisionLoading: false,
            sectionLoading: false,
            locationLoading: false,
            branchLoading: false,
            departmentLoading: false,
            isLoaded: false,
            mainGroupsDataArr: [],
            loadingSubGroups: false,
            loadingDepart: [],
            loadingBranches: [],
            departsData: [],
            divisionData: [],
            deptDivs: [],
            deptDivLoader: false,
            groupId: '',
            deptId: '',
            divisionId: '',
            sectionId: '',
            sectionLoader: false,
            sectionData: [],
            applyFilterLoader: false,
            statisticsData: [],
            page: 1,
            loadMoreEnabled: false,
            isLoading: false,
            ratingRanges: [
                { "value": "0", "label": "All" },
                { "value": "100", "label": "100%" },
                { "value": "90-99", "label": "90-99%" },
                { "value": "80-89", "label": "80-89%" },
                { "value": "70-79", "label": "70-79%" },
                { "value": "60-69", "label": "60-69%" },
                { "value": "50-59", "label": "50-59%" },
                { "value": "0-49", "label": "0-49%" },
            ],
            selectedRating: "0",
            subGroups: [],
            loacations: [],
            sogs: [],
            subGroupId: "",
            pcId: "",
            pcData: [],
            locations: [],
            locationBranches: [],
            pcLoading: false,
            modal: false,
            finishSelectionClicked:false,
            branchesData:[],
            divLoading: false,
            branchIdFun:'',

        };
    }

    componentWillMount() {
        this.props.getMainGroupsData()
    }

    // getAllBranchesData = () => {
    //     axios.get(getBaseUrl() + 'api/branches/get?access_token=' + accessToken() + '&year=' + getYear())
    //         .then((res) => {
    //             console.log(res);
    //             if (res.data.result) {
    //                 this.setState({
    //                     branchList: res.data.result
    //                 }, () => {
    //                     console.log(this.state.branchList)
    //                     this.setState({
    //                         BRANCH: this.state.branchList[0].branch_id
    //                     }, () => {
    //                         if (getYear() > '2020') {
    //                             this.getPASDivisions(this.state.BRANCH);
    //                         }
    //                     })
    //                 })
    //             }
    //         })
    //         .catch((error) => {
    //             console.log(error);
    //         });
    // }
    getPASSections = (branchId, division) => {
        this.setState({ sectionLoading: true });
        this.state.division = division;
        var bodyFormData = new FormData();

        var year = getYear();

        bodyFormData.set('branch_id', branchId);
        bodyFormData.set('division', division);
        bodyFormData.set('acess_token', accessToken());
        bodyFormData.set('year', year);

        axios.post(getBaseUrl() + 'api/admin/branch/sections', bodyFormData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then((res) => {

                if (res.status == 200 && res.data.result) {
                    console.log("this is the res Faheem:", res.data.result);
                    this.setState({
                        sectionList: res.data.result,
                        sectionLoading: false
                    }, () => {
                        this.setState({
                            SECTION: this.state.sectionList != 'null' && this.state.sectionList.length > 0 ? year < '2021' ? this.state.sectionList[0].SECTION : this.state.sectionList[0].section_id : ''
                        })
                    })
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }
    // getSections = (branchId, division) => {
    //     this.setState({ sectionLoading: true });
    //     this.state.division = division;
    //     var bodyFormData = new FormData();

    //     var year = getYear();

    //     bodyFormData.set('branch_id', branchId);
    //     bodyFormData.set('division', division);
    //     bodyFormData.set('acess_token', accessToken());
    //     bodyFormData.set('year', year);

    //     axios.post(getBaseUrl() + 'api/admin/employee/column/data', bodyFormData, {
    //         headers: {
    //             'Content-Type': 'multipart/form-data'
    //         }
    //     })
    //         .then((res) => {

    //             if (res.status == 200 && res.data.result) {
    //                 console.log("this is the res Faheem:", res.data.result);
    //                 this.setState({
    //                     sectionList: res.data.result,
    //                     sectionLoading: false
    //                 }, () => {
    //                     this.setState({
    //                         SECTION: this.state.sectionList != 'null' && this.state.sectionList.length > 0 ? year < '2021' ? this.state.sectionList[0].SECTION : this.state.sectionList[0].section_id : ''
    //                     })
    //                 })
    //             }
    //         })
    //         .catch((error) => {
    //             console.log(error);
    //         });
    // }
    getDivisions = (branchId) => {
        var year = getYear();
        this.setState({ divisionLoading: true });
        this.state.branchId = branchId;
        var bodyFormData = new FormData();
        bodyFormData.set('branch_id', branchId);
        bodyFormData.set('acess_token', accessToken());
        bodyFormData.set('year', year);

        axios.post(getBaseUrl() + 'api/admin/employee/column/data', bodyFormData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then((res) => {

                if (res.status == 200 && res.data.result) {
                    console.log("this is the res Faheem:", res.data.result);
                    this.setState({
                        divisionList: res.data.result,
                        divisionLoading: false
                    }, () => {
                        this.setState({
                            DIVISION: this.state.divisionList != 'null' && this.state.divisionList.length > 0 ? year < '2021' ? this.state.divisionList[0].DIVISION : this.state.divisionList[0].division_id : ''
                        })
                    })
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }
    getPASDivisions = (branchId) => {
        var year = getYear();
        this.setState({ divisionLoading: true });
        this.state.branchId = branchId;
        var bodyFormData = new FormData();
        bodyFormData.set('branch_id', branchId);
        bodyFormData.set('acess_token', accessToken());
        bodyFormData.set('year', year);

        axios.post(getBaseUrl() + 'api/admin/branch/divisions', bodyFormData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then((res) => {

                if (res.status == 200 && res.data.result) {
                    console.log("this is the res Faheem:", res.data.result);
                    this.setState({
                        divisionList: res.data.result,
                        divisionLoading: false
                    }, () => {
                        this.setState({
                            DIVISION: this.state.divisionList != 'null' && this.state.divisionList.length > 0 ? year < '2021' ? this.state.divisionList[0].DIVISION : this.state.divisionList[0].division_id : ''
                        }, () => {
                            if (this.state.DIVISION != '')
                                this.getPASSections(branchId, this.state.DIVISION)
                        })
                    })
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }


    componentWillReceiveProps(nextProps, thisProps) {
        console.log('Faheem in function componentWillReceiveProps');
        if (this.props.getAllAssessmentStatistics != nextProps.getAllAssessmentStatistics) {
            // console.log();
            try {
                var completeData = nextProps.getAllAssessmentStatistics.data;
                if (completeData) {
                    this.setState({
                        completedata: completeData,
                        competencies: Object.keys(completeData[getYear()]).map((comp, key) => {
                            return ({
                                name: comp,
                                subcompetencies: Object.keys(completeData[getYear()][comp]).map((subcomp, key2) => {
                                    return ({
                                        name: subcomp
                                    })
                                })
                            })
                        })
                    }, () => {
                        console.log(this.state.completedata)
                    })
                }
            } catch (e) {

                confirmAlert({
                    title: 'Error',
                    message: "Required stats data could not be found with this selected filter.",
                    closeOnEscape: false,
                    closeOnClickOutside: false,
                    buttons: [
                        {
                            label: 'OK',
                            onClick: () => {
                                this.setState({
                                    isLoaded: true
                                })
                            }
                        }
                    ]
                })
            }

        }
        if (this.props.additionalData != nextProps.additionalData) {
            console.log("additionalData:", nextProps.additionalData.data);
            this.setState({
                // branchList : nextProps.additionalData.data.branches,
                // divisionList : nextProps.additionalData.data.divisions,
                pcList: nextProps.additionalData.data.payClass
                // sectionList : nextProps.additionalData.data.sections,
                // deptList : nextProps.additionalData.data.departments,

            }, () => {
                this.setState({
                    PC: this.state.pcList[0].pc_id
                });
            })
        }
        if (this.props.mainGroupsData != nextProps.mainGroupsData) {
            this.setState({ mainGroupsDataArr: nextProps.mainGroupsData.data }, () => {
                console.log('main groups data fetch and set now in state:', this.state.mainGroupsDataArr);
            })
        }
    }

    updateValue = (e) => {
        console.log("This is the value:", e.target.value)
        this.setState({
            [e.target.name]: e.target.value
        })
        if (e.target.name == "SECTION") {
            console.log('section has changed');
        }
        if (e.target.name == "DIVISION") {
            console.log('division has changed');
            if (getYear() < '2021')
                this.getSections(this.state.branchId, e.target.value);
            else
                this.getPASSections(this.state.branchId, e.target.value);
        }
    }

    updateValueBranch = (e) => {
        let branchId = this.state.branchList[e.target.selectedIndex].branch_id;
        if (getYear() < '2021')
            this.getDivisions(branchId);
        else
            this.getPASDivisions(branchId);

        console.log('branch name:', branchId)
        // this.setState({
        //     [e.target.name] : this.state.branchList[e.target.selectedIndex].branch_id
        // })
    }

    // fetch all distinct values for any column
    getAllDistinctData = (col) => {
        var bodyFormData = new FormData();
        bodyFormData.set('col', col);
        bodyFormData.set('acess_token', accessToken());

        axios.post(getBaseUrl() + 'api/admin/employee/column/data', bodyFormData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then((res) => {
                console.log(res);
                if (res.data.result) {

                    if (col == 'DIVISION') {
                        this.setState({
                            divisionList: res.data.result
                        }, () => {
                            console.log(this.state.divisionList)
                            this.setState({
                                DIVISION: this.state.divisionList[0].DIVISION
                            })
                        })
                    }
                    else if (col == 'SECTION') {
                        this.setState({
                            sectionList: res.data.result
                        }, () => {
                            console.log(this.state.sectionList)
                            this.setState({
                                SECTION: this.state.sectionList[0].SECTION
                            })
                        })
                    }
                    // else if(col == 'BRANCH') {
                    //     this.setState({
                    //         branchList : res.data.result
                    //     }, () => {
                    //         console.log(this.state.branchList)
                    //         this.setState({
                    //             BRANCH : this.state.branchList[0].BRANCH
                    //         })
                    //     })
                    // }
                    else if (col == 'DEPT') {
                        this.setState({
                            deptList: res.data.result
                        }, () => {
                            console.log(this.state.deptList)
                            this.setState({
                                DEPT: this.state.deptList[0].DEPT
                            })
                        })
                    }
                    else if (col == 'PC') {
                        this.setState({
                            pcList: res.data.result
                        }, () => {
                            console.log(this.state.pcList)
                            this.setState({
                                PC: this.state.pcList[0].PC
                            })
                        })
                    }


                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    handleSubmitFilter = (e) => {

        const { DIVISION, SECTION, BRANCH, DEPT, PC } = this.state;

        this.setState({
            competencies: []
        }, () => {

            this.props.fetchCompleteStats({
                DIVISION: DIVISION,
                SECTION: SECTION,
                BRANCH: BRANCH,
                DEPT: DEPT,
                PC: PC
            })

        })
    }

    subGroups = (groupId) => {
        // alert("Hi");
        this.setState({
            loadingSubGroups: true, divisionData: [],
            branchesData:[], branchIdFun:'',
            groupId: groupId, deptDivs: [],
            subGroups: [], locations: [], sogs: [],
            loacations: [], divisionData: [], sectionData: [],
            pcData: [], locations: [], locationBranches: [],
            departsData: [], pcData: [], divisionData: [], sectionData: [],
            subGroupId: '', locationId: "", branchId: "", divisionId: "", sectionId: "",
            deptId: "", pcId: "", statisticsData: []
        })
        let ths = this;
        this.props.fetchSubGroupsOrLocationsOrSOG(groupId, function (res) {
            console.log("this is the sever res:", res)
            if (res != 0) {
                ths.setState({ subGroups: res.result, loadingSubGroups: false })
            }
        })
    }
    sog = (groupId) => {
        // alert("Hi");
        this.setState({
            loadingDepart: true, divisionData: [], groupId: groupId, deptDivs: [],
            subGroups: [], locations: [], sogs: [], pcData: [], locations: [], locationBranches: [],
            subGroupId: '', locationId: "", branchId: "", divisionId: "", sectionId: "",
            deptId: "", pcId: "", statisticsData: []
        })
        let ths = this;
        this.props.fetchSubGroupsOrLocationsOrSOG(groupId, function (res) {
            console.log("this is the sever res:", res)
            if (res != 0) {
                ths.setState({ sogs: res.result, loadingDepart: false })
            }
        })
    }
    locations = (groupId, branchID) => {
        if(groupId== 3){
            this.setState({
                loadingBranches: true, divisionData: [], groupId: groupId, deptDivs: [], branchIdFun: '',branchesData:[],
                sogs: [], subGroups: [], locations: [], sogs: [],
                loacations: [], divisionData: [], sectionData: [], departsData: [],
                pcData: [], locationBranches: [],
                subGroupId: '', locationId: "", branchId: "", divisionId: "", sectionId: "",
                deptId: "", pcId: "", statisticsData: []
            })
            let ths = this;
            this.props.fetchSubGroupsOrLocationsOrSOG(groupId, function (res) {
                console.log("this is the sever res:", res)
                if (res != 0) {
                    ths.setState({ locations: res.result, loadingBranches: false })
                }
            })
        }
        else{
            this.setState({
                loadingBranches: true, divisionData: [], groupId: groupId, deptDivs: [], branchIdFun: branchID,
                sogs: [], subGroups: [], locations: [], sogs: [],
                loacations: [], divisionData: [], sectionData: [], departsData: [],
                pcData: [], locationBranches: [],
                subGroupId: '', locationId: "", branchId: "", divisionId: "", sectionId: "",
                deptId: "", pcId: "", statisticsData: []
            })
            let ths = this;
            this.props.fetchSubGroupsOrLocationsOrSOG(groupId, function (res) {
                console.log("this is the sever res:", res)
                if (res != 0) {
                    ths.setState({ locations: res.result, loadingBranches: false })
                }
            })

        }
    }
    groupDeparts = (subGroupId) => {
        // alert("Hi");
        this.setState({
            departmentLoading: true, divisionData: [], subGroupId: subGroupId, deptDivs: [],
            locations: [], sogs: [], pcData: [], divisionData: [], sectionData: [],
            locationId: "", branchId: "", divisionId: "", sectionId: "",
            deptId: "", pcId: "", statisticsData: []
        })
        let ths = this;
        this.props.getAssesstDeptsHierarchy(subGroupId, function (res) {
            console.log("this is the sever res:", res)
            if (res != 0) {
                ths.setState({ departsData: res.result, departmentLoading: false })
            }
        })
    }
    getDivisions = (deptId) => {
        this.setState({
            divisionLoading: true, divisionData: [], pcData: [], sectionData: [], deptId: deptId,
            locationId: "", branchId: "", divisionId: "", sectionId: "", pcId: "",
            statisticsData: []
        })
        let ths = this;
        this.props.getAssesstDivHierarchy(deptId, this.state.subGroupId, function (res) {
            console.log("this is the sever res for divisions:", res.result)
            if (res != 0) {
                ths.setState({ divisionData: res.result, divisionLoading: false })
            }
        })
    }
    getSections = (divId) => {
        this.setState({
            sectionLoading: true, divisionId: divId, pcData: [],
            locationId: "", branchId: "", sectionId: "", pcId: "",
            statisticsData: []
        })
        const { subGroupId, deptId } = this.state
        let ths = this;
        this.props.getAssesstSectionHierarchy(subGroupId, deptId, divId, function (res) {
            console.log("this is the sever res for divisions:", res.result)
            if (res != 0) {
                ths.setState({ sectionData: res.result, sectionLoading: false })
            }
        })
    }
    getPC = (sectionId) => {
        this.setState({
            pcLoading: true, sectionId: sectionId, locationId: "", branchId: "", pcId: "",
            statisticsData: []
        })
        const { subGroupId, deptId, divisionId } = this.state
        let ths = this;
        this.props.getAssesstPCHierarchy(subGroupId, deptId, divisionId, sectionId, function (res) {
            console.log("this is the sever res for divisions:", res.result)
            if (res != 0) {
                ths.setState({ pcData: res.result, pcLoading: false })
            }
        })
    }
    getlocationDivs = (locationId) => {
        this.setState({
            locationLoading: true, locationId: locationId, divisionData: [], pcData: [], sectionData: [],
            subGroupId: "", branchId: "", divisionId: "", sectionId: "", pcId: "", deptId: "",
            statisticsData: []
        })
        let ths = this;
        this.props.getLocationDivsHierarchy(locationId, (res) => {
            console.log("this is the sever res for divisions:", res.result)
            if (res != 0) {
                ths.setState({ divisionData: res.result, locationLoading: false })
            }
        })
    }
    getlocationSection = (divisionId) => {
        this.setState({
            sectionLoading: true, divisionId: divisionId, sectionData: [], pcData: [],
            subGroupId: "", branchId: "", sectionId: "", pcId: "", deptId: "",
            statisticsData: []
        })
        const { locationId } = this.state;
        let ths = this;
        this.props.getLocationSectionHierarchy(locationId, divisionId, (res) => {
            console.log("this is the sever res for divisions:", res.result)
            if (res != 0) {
                ths.setState({ sectionLoading: false, sectionData: res.result })
            }
        })
    }
    getLocationPC = (sectionId) => {

        this.setState({
            pcLoading: true, sectionId: sectionId, pcData: [],
            subGroupId: "", branchId: "", pcId: "", deptId: "",
            statisticsData: []
        })
        const { locationId, divisionId } = this.state;
        let ths = this;
        this.props.getLocationPCHierarchy(locationId, divisionId, sectionId, (res) => {
            console.log("this is the sever res for divisions:", res.result)
            if (res != 0) {
                ths.setState({ pcData: res.result, pcLoading: false })
            }
        })
    }
    getBranches = (locId) => {
        this.setState({
            locationLoading: true, locationId: locId, locationBranches: [],
            subGroupId: "", branchId: "", divisionId: "", sectionId: "", pcId: "", deptId: "",
            statisticsData: []
        })
        const { locationId, divisionId } = this.state;
        let ths = this;
        this.props.fetchLocationBranches(locId, (res) => {
            console.log("this is the sever res for divisions:", res.result)
            if (res != 0) {
                ths.setState({ locationLoading: false, locationBranches: res.result })
            }
        })

    }
    getBranchDiv = (branchId) => {
        this.setState({
            divisionLoading: true, branchId: branchId, divisionData: [], pcData: [], sectionData: [],
            subGroupId: "", divisionId: "", sectionId: "", pcId: "", deptId: "",
            statisticsData: []
        })
        const { locationId } = this.state
        //getAssessBranchDivsHierarchy
        let ths = this;
        this.props.getAssessBranchDivsHierarchy(locationId, branchId, (res) => {
            console.log("this is the sever res for divisions:", res.result)
            if (res != 0) {
                ths.setState({ divisionLoading: false, divisionData: res.result })
            }
        })
    }
    getlocationBranchSection = (divisionId) => {
        this.setState({
            sectionLoading: true, divisionId: divisionId, sectionData: [], pcData: [],
            subGroupId: "", sectionId: "", pcId: "", deptId: "", statisticsData: []
        })
        const { locationId, branchId } = this.state;
        let ths = this;
        this.props.getAssessBranchSectionHierarchy(locationId, branchId, divisionId, (res) => {
            console.log("this is the sever res for divisions:", res.result)
            if (res != 0) {
                ths.setState({ sectionLoading: false, sectionData: res.result })
            }
        })
    }
    getLocationBranchPC = (sectionId) => {

        this.setState({
            pcLoading: true, sectionId: sectionId, pcData: [],
            subGroupId: "", deptId: "", pcId: "", statisticsData: []
        })
        const { locationId, divisionId, branchId } = this.state;
        let ths = this;
        this.props.getAssessBranchPCHierarchy(locationId, branchId, divisionId, sectionId, (res) => {
            console.log("this is the sever res for divisions:", res.result)
            if (res != 0) {
                ths.setState({ pcData: res.result, pcLoading: false })
            }
        })
    }
    fetchAssessmentStatisticsHist = () => {
        this.setState({ applyFilterLoader: true, isLoading: true, statisticsData: [] })
        if (this.state.groupId == 1) {
            let filter = {
                group_id: this.state.groupId,
                dept_id: this.state.deptId,
                div_id: this.state.divisionId,
                section_id: this.state.sectionId,
                page: this.state.page,
                rating: this.state.selectedRating,
                sub_groupid: this.state.subGroupId,
                pc_id: this.state.pcId
            }
            console.log("This is the filter data:", filter)
            this.props.fetchAssessStatistics(filter, (data) => {
                this.setState({ applyFilterLoader: false, isLoading: false })
                console.log("This is the server data:", data.data)
                //statisticsData
                if (data != 0) {
                    this.setState({ statisticsData: data.data })
                }
            })

        }
        if (this.state.groupId == 2 || this.state.groupId == 3) {
            let filter = {
                group_id: this.state.groupId,
                div_id: this.state.divisionId,
                section_id: this.state.sectionId,
                pc_id: this.state.pcId,
                location_id: this.state.locationId,
                page: this.state.page,
                // branch_id: this.state.branchId,
                branch_id: this.state.branchIdFun,
                rating: this.state.selectedRating,
            }
            console.log("This is the filter data:", filter)
            this.props.fetchAssessStatistics(filter, (data) => {
                console.log("This is the server data:", data.data)
                this.setState({ applyFilterLoader: false, isLoading: false })
                //statisticsData
                if (data != 0) {
                    this.setState({ statisticsData: data.data })
                }
            })

        }
    }
    getAllBranchesDataFetch = (groupId) => {
        this.setState({ loadingSubGroups: true, departsData: [], locations:[],subGroups:[], branchIdFun:'', branchesData:[], deptDivs: [],divisionData:[], groupId: groupId,deptId:"",divisionId:"",sectionId:"",sectionData:"" , locationBranches:[],})
        const token = generateToken();
        axios.get(getBaseUrl() + 'api/branches/get?access_token=' + accessToken() + '&year=' + getYear() + '&groupid=' + groupId, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'authorizedToken': token,
                'appidadmin':localStorage.getItem('app_id_session') // Add your custom token here
            }
        })
            .then((res) => {

                // console.log(res);
                if (res.data.result) {
                    this.setState({
                        branchList: res.data.result, branchesData: res.data.result, loadingSubGroups: false
                    }, () => {
                        console.log(this.state.branchList)
                        this.setState({
                            branch_id: "",
                            branch_name: ""
                        }, () => {
                            //this.fetchHistoryData();
                        })
                    })
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }
    render() {

        const {
            completedata, competencies, isOpen,
            branch, isLoaded, mainGroupsDataArr, loadingDepart,
            divisionData, departsData, deptDivLoader, deptDivs,
            sectionLoader, sectionData, loadingSubGroups,
            ratingRanges, subGroups, locations, sogs, pcData,
            locationBranches, divisionLoading, sectionLoading,
            locationLoading, pcLoading, departmentLoading,
            modal,branchesData,branchId,loadingBranches,branchIdFun

        } = this.state;
        var years = typeof completedata.years !== 'undefined' ? completedata.years : [];
        return (
            <div>
                {/*Side menu*/}
                <SideMenu />

                <AdminHeader title={this.state.headerTitle} style={{ marginLeft: 80 }} />

                <Container className="AssessmentStatistics">
                    <Row>

                    </Row>

                    <Row>
                        <Col md={{ size: 4 }}>
                            <FormGroup>
                                <Label for="BRANCH">Main Group</Label>:
                                <Input type="select" name="main_group" id="main_group" onChange={(event) => {
                                    console.log("sel val:", event.target.value)
                                    if (event.target.value == 1) {
                                        this.subGroups(event.target.value);
                                    }
                                    else if (event.target.value == 2) {
                                        this.getAllBranchesDataFetch(event.target.value);      
                                    }
                                    else if(event.target.value == 3){
                                        this.locations(event.target.value);

                                    }
                                }}>
                                    <option value="0">Select Main Group</option>
                                    {
                                        mainGroupsDataArr.length > 0 ?
                                            mainGroupsDataArr.map((group, index) =>
                                                <option value={group.group_id}>{group.group_name}</option>
                                            )
                                            : null
                                    }
                                </Input>
                            </FormGroup>
                            {
                                loadingSubGroups == true ?
                                    <Spinner color="primary" style={{
                                        width: '1.10rem',
                                        height: '1.10rem',
                                        marginTop: '-40px',
                                        float: 'right'
                                    }} />
                                    : null
                            }
                        </Col>
                        {
                                    branchesData.length > 0 ?
                                    <Col md={{ size: 4 }}>
                                        <FormGroup>
                                            <Label for="BRANCH">Branches</Label>:
                                            <Input type="select" name="main_group" id="main_group" onChange={(e) => {
                                                //  console.log("sel3333 val:",this.state.groupId, e.target.value)
                                                    this.locations(this.state.groupId, e.target.value)
                                            }}>
                                                <option value="0">Select Branch</option>
                                                {
                                                    branchesData.length > 0 ?
                                                    branchesData.map((branches, index) =>
                                                            <option value={branches.branch_id} key={index}>{branches.branch_name}</option>
                                                        )
                                                        : null
                                                }
                                            </Input>
                                           
                                        </FormGroup>
                                        {
                                loadingBranches == true ?
                                    <Spinner color="primary" style={{
                                        width: '1.10rem',
                                        height: '1.10rem',
                                        marginTop: '-40px',
                                        float: 'right'
                                    }} />
                                    : null
                            }
                        </Col>
                                        :null
                                }
                        {
                            subGroups.length > 0 ?
                                <Col md={{ size: 4 }}>
                                    <>
                                        {
                                            subGroups.length > 0 ?
                                                <FormGroup>
                                                    <Label for="BRANCH">Sub Groups</Label>:
                                                    <Input type="select" name="main_group" id="main_group" onChange={(e) => {
                                                        this.groupDeparts(e.target.value)
                                                    }}>
                                                        <option value="0">Select sub group</option>
                                                        {
                                                            subGroups.length > 0 ?
                                                                subGroups.map((sg, index) =>
                                                                    <option value={sg.sub_groupid}>{sg.sub_groupname}</option>
                                                                )
                                                                : null
                                                        }
                                                    </Input>
                                                </FormGroup>
                                                :
                                                null
                                        }
                                    </>

                                    {
                                        departmentLoading == true ?
                                            <Spinner color="primary" style={{
                                                width: '1.10rem',
                                                height: '1.10rem',
                                                marginTop: '-40px',
                                                float: 'right'
                                            }} />
                                            : null
                                    }
                                </Col>
                                : null
                        }
                        {
                            locations.length > 0 ?
                                <Col md={{ size: 4 }}>
                                    <>
                                        {
                                            locations.length > 0 ?
                                                <FormGroup>
                                                    <Label for="BRANCH">Locations</Label>:
                                                    <Input type="select" name="main_group" id="main_group" onChange={(e) => {
                                                        if (this.state.groupId == 2)
                                                            this.getlocationDivs(e.target.value)
                                                        if (this.state.groupId == 3)
                                                            this.getBranches(e.target.value)
                                                    }}>
                                                        <option value="0">Select locations</option>
                                                        {
                                                            locations.length > 0 ?
                                                                locations.map((location, index) =>
                                                                    <option value={location.location_id}>{location.location_name}</option>
                                                                )
                                                                : null
                                                        }
                                                    </Input>
                                                </FormGroup>
                                                :
                                                null
                                        }
                                    </>

                                    {
                                        locationLoading == true ?
                                            <Spinner color="primary" style={{
                                                width: '1.10rem',
                                                height: '1.10rem',
                                                marginTop: '-40px',
                                                float: 'right'
                                            }} />
                                            : null
                                    }
                                </Col>
                                : null
                        }
                        {
                            departsData.length > 0 ?
                                <Col md={{ size: 4 }}>
                                    <FormGroup>
                                        <Label for="BRANCH">Departments</Label>:
                                        <Input type="select" name="main_group" id="main_group" onChange={(e) => {
                                            // alert(e.target.value)
                                            this.getDivisions(e.target.value)
                                        }}>
                                            <option value="0">Select department</option>
                                            {
                                                departsData.length > 0 ?
                                                    departsData.map((department, index) =>
                                                        <option value={department.dept_id}>{department.dept_suffix}</option>
                                                    )
                                                    : null
                                            }
                                        </Input>
                                    </FormGroup>
                                    {
                                        divisionLoading == true ?
                                            <Spinner color="primary" style={{
                                                width: '1.10rem',
                                                height: '1.10rem',
                                                marginTop: '-40px',
                                                float: 'right'
                                            }} />
                                            : null
                                    }
                                </Col>

                                : null
                        }
                        {
                            locationBranches.length > 0 ?
                                <Col md={{ size: 4 }}>
                                    <FormGroup>
                                        <Label for="BRANCH">Branches</Label>:
                                        <Input type="select" name="main_group" id="main_group" onChange={(e) => {
                                            // alert(e.target.value)
                                            this.getBranchDiv(e.target.value)
                                        }}>
                                            <option value="0">Select branch</option>
                                            {
                                                locationBranches.length > 0 ?
                                                    locationBranches.map((branch, index) =>
                                                        <option value={branch.branch_id}>{branch.branch_name}</option>
                                                    )
                                                    : null
                                            }
                                        </Input>
                                    </FormGroup>
                                    {
                                        divisionLoading == true ?
                                            <Spinner color="primary" style={{
                                                width: '1.10rem',
                                                height: '1.10rem',
                                                marginTop: '-40px',
                                                float: 'right'
                                            }} />
                                            : null
                                    }
                                </Col>

                                : null
                        }
                        {
                            divisionData.length > 0 ?
                                <Col md={{ size: 4 }}>
                                    <FormGroup>
                                        <Label for="BRANCH">Divisions</Label>:
                                        <Input type="select" name="main_group" id="main_group" onChange={(e) => {
                                            // alert(e.target.value)
                                            if (this.state.groupId == 1)
                                                this.getSections(e.target.value)
                                            else if (this.state.groupId == 2)
                                                this.getlocationSection(e.target.value)
                                            else if (this.state.groupId == 3)
                                                this.getlocationBranchSection(e.target.value)
                                        }}>
                                            <option value="0">Select Division</option>
                                            {
                                                divisionData.length > 0 ?
                                                    divisionData.map((div, index) =>
                                                        <option value={div.division_id}>{div.division_name}</option>
                                                    )
                                                    : null
                                            }
                                        </Input>
                                    </FormGroup>
                                    {
                                        sectionLoading == true ?
                                            <Spinner color="primary" style={{
                                                width: '1.10rem',
                                                height: '1.10rem',
                                                marginTop: '-40px',
                                                float: 'right'
                                            }} />
                                            : null
                                    }
                                </Col>

                                : null
                        }

                        {
                            sectionData.length > 0 ?
                                <Col md={{ size: 4 }}>
                                    <FormGroup>
                                        <Label for="BRANCH">Sections</Label>:
                                        <Input type="select" name="main_group" id="main_group" onChange={(e) => {
                                            // alert(e.target.value)
                                            if (this.state.groupId == 1)
                                                this.getPC(e.target.value)
                                            else if (this.state.groupId == 2)
                                                this.getLocationPC(e.target.value)
                                            else if (this.state.groupId == 3)
                                                this.getLocationBranchPC(e.target.value)
                                        }}>
                                            <option value="0">Select Section</option>
                                            {
                                                sectionData.length > 0 ?
                                                    sectionData.map((section, index) =>
                                                        <option value={section.section_id}>{section.section_name}</option>
                                                    )
                                                    : null
                                            }
                                        </Input>
                                    </FormGroup>
                                    {
                                        pcLoading == true ?
                                            <Spinner color="primary" style={{
                                                width: '1.10rem',
                                                height: '1.10rem',
                                                marginTop: '-40px',
                                                float: 'right'
                                            }} />
                                            : null
                                    }
                                </Col>

                                : null
                        }
                        {
                            pcData.length > 0 ?
                                <Col md={{ size: 3 }}>
                                    <FormGroup>
                                        <Label for="BRANCH">Job Grade</Label>:
                                        <Input type="select" name="main_group" id="main_group" onChange={(e) => {
                                            // alert(e.target.value)
                                            // this.getPC(e.target.value)
                                            this.setState({ pcId: e.target.value })
                                        }}>
                                            <option value="0">Select Job Grade</option>
                                            {
                                                pcData.length > 0 ?
                                                    pcData.map((pc, index) =>
                                                        <option value={pc.pc_id}>{pc.pc_name}</option>
                                                    )
                                                    : null
                                            }
                                        </Input>
                                    </FormGroup>
                                </Col>

                                : null
                        }
                        <Col md={{ size: 3}}>
                            <FormGroup>
                                <Label for="rating">Ratings</Label>:
                                <Input type="select" name="main_group" id="main_group" onChange={(e) => {
                                    this.setState({ selectedRating: e.target.value })

                                }}>
                                    <option value="0">Select ratings</option>
                                    {
                                        ratingRanges.length > 0 ?
                                            ratingRanges.map((rating, index) =>
                                                <option value={rating.value}>{rating.label}</option>
                                            )
                                            : null
                                    }
                                </Input>
                                
                            </FormGroup>
                        </Col>
                            <UpdateYear/>
                        <Col md={{ size: 2}}  style={{marginTop:'20px'}}>

                        <FormGroup>

                        <Button style={{ margin: '0px 0px 0px 0px', float: 'right' }}  color={"warning"} size="small"
                                    onClick={(e) => {
                                        this.setState({ modal: true, page: 1 })
                                    }}
                                    disabled={this.state.applyFilterLoader}
                                >
                                    {'Finish Selection'}
                                    {
                                        this.state.applyFilterLoader ? <Spinner style={{ margin: '4px 0px 4px 10px' }} color="light" size="sm" /> : null
                                    }
                                </Button>
                            </FormGroup>
                            </Col>



                    </Row>
                    <Row className="bgColorApplied" style={{ paddingTop: '4vh', textTransform: 'uppercase', fontWeight: 'bold' }}>
                        <Col md={{ size: 2 }}>
                            Employee ID
                        </Col>
                        <Col md={{ size: 2 }}>
                            Fullname
                        </Col>
                        <Col md={{ size: 2 }}>
                            Section
                        </Col>
                        <Col md={{ size: 2 }}>
                            Division
                        </Col>
                        <Col md={{ size: 2 }}>
                            DEFICIENT Assessments
                        </Col>
                        <Col md={{ size: 2 }}>
                            DEFICIENT %
                        </Col>
                    </Row>
                    {
                        this.state.statisticsData.length > 0 ?
                            this.state.statisticsData.map((emp, key) =>
                                <Row key={key} className="bgColorApplied">
                                    <Col md={{ size: 2 }}>
                                        {emp?.id_number || '-'}
                                    </Col>
                                    <Col md={{ size: 2 }}>
                                        {emp.FIRST_NAME + " " + emp.LAST_NAME}
                                    </Col>
                                    <Col md={{ size: 2 }}>
                                        {emp?.section_name || '-'}
                                    </Col>
                                    <Col md={{ size: 2 }}>
                                        {emp?.division_name || '-'}
                                    </Col>
                                    <Col md={{ size: 2 }}>
                                        {emp?.deficient_assess + "/" + emp?.total_assess}
                                    </Col>
                                    <Col md={{ size: 2 }}>
                                        {emp?.percentage+"%"}
                                    </Col>
                                </Row>
                            )
                            :
                            this.state.isLoading == true ?
                                <Spinner style={{ margin: '20vh 0' }} size="md" color="dark" />
                                    : null
                    }
                    {
                        this.state.statisticsData.length > 0  ?
                            <>
                                <Row>
                                    <Col>
                                        <Button style={{ textAlign: 'left', marginTop: 20, marginRight: 10 }} size="sm" color="secondary"
                                            disabled={this.state.page == 1 || this.state.loadMoreEnabled ? true : false}
                                            onClick={(e) => {
                                                this.setState({
                                                    page: this.state.page - 1,
                                                    loadMoreEnabled: false,
                                                    isLoading: true
                                                }, () => {
                                                    let filter = "";
                                                    if (this.state.groupId == 1) {
                                                        filter = {
                                                            group_id: this.state.groupId,
                                                            dept_id: this.state.deptId,
                                                            div_id: this.state.divisionId,
                                                            section_id: this.state.sectionId,
                                                            page: this.state.page,
                                                            rating: this.state.selectedRating,
                                                            sub_groupid: this.state.subGroupId,
                                                            pc_id: this.state.pcId
                                                        }
                                                        console.log("This is the filter data:", filter)
                                                        this.props.fetchAssessStatistics(filter, (data) => {
                                                            this.setState({ applyFilterLoader: false, isLoading: false })
                                                            console.log("This is the server data:", data.data)
                                                            //statisticsData
                                                            if (data != 0) {
                                                                this.setState({ statisticsData: data.data })
                                                            }
                                                        })

                                                    }
                                                    if (this.state.groupId == 2 || this.state.groupId == 3) {
                                                        filter = {
                                                            group_id: this.state.groupId,
                                                            div_id: this.state.divisionId,
                                                            section_id: this.state.sectionId,
                                                            pc_id: this.state.pcId,
                                                            location_id: this.state.locationId,
                                                            page: this.state.page,
                                                            rating: this.state.selectedRating,
                                                        }
                                                        console.log("This is the filter data:", filter)
                                                        this.props.fetchAssessStatistics(filter, (data) => {
                                                            console.log("This is the server data:", data.data)
                                                            this.setState({ applyFilterLoader: false, isLoading: false })
                                                            //statisticsData
                                                            if (data != 0) {
                                                                this.setState({ statisticsData: data.data })
                                                            }
                                                        })

                                                    }
                                                    console.log("This is the filter data:", filter)
                                                    this.props.fetchAssessStatistics(filter, (data) => {
                                                        console.log("This is the server data:", data.data)
                                                        this.setState({ isLoading: false })
                                                        //statisticsData
                                                        if (data != 0) {
                                                            this.setState({ statisticsData: data.data })
                                                        }
                                                    })
                                                })
                                            }}>
                                            {
                                                this.state.loadMoreEnabled ?
                                                    <Spinner size="sm" color="light" style={{ marginRight: '5px' }} />
                                                    : null
                                            }
                                            Previous
                                        </Button>
                                        <Button style={{ textAlign: 'left', marginTop: 20 }} size="sm" color="secondary"
                                            disabled={this.state.groupId == "" || this.state.statisticsData.length == 0? true : false}
                                            onClick={(e) => {
                                                this.setState({
                                                    page: this.state.page + 1,
                                                    loadMoreEnabled: false,
                                                    isLoading: true,
                                                    statisticsData: []
                                                }, () => {
                                                    if (this.state.groupId == 1) {
                                                        let filter = {
                                                            group_id: this.state.groupId,
                                                            dept_id: this.state.deptId,
                                                            div_id: this.state.divisionId,
                                                            section_id: this.state.sectionId,
                                                            page: this.state.page,
                                                            rating: this.state.selectedRating,
                                                            sub_groupid: this.state.subGroupId,
                                                            pc_id: this.state.pcId
                                                        }
                                                        console.log("This is the filter data:", filter)
                                                        this.props.fetchAssessStatistics(filter, (data) => {
                                                            this.setState({ applyFilterLoader: false, isLoading: false })
                                                            console.log("This is the server data:", data.data)
                                                            //statisticsData
                                                            if (data != 0) {
                                                                this.setState({ statisticsData: data.data })
                                                            }
                                                        })

                                                    }
                                                    if (this.state.groupId == 2 || this.state.groupId == 3) {
                                                        let filter = {
                                                            group_id: this.state.groupId,
                                                            div_id: this.state.divisionId,
                                                            section_id: this.state.sectionId,
                                                            pc_id: this.state.pcId,
                                                            location_id: this.state.locationId,
                                                            page: this.state.page,
                                                            rating: this.state.selectedRating,
                                                        }
                                                        console.log("This is the filter data:", filter)
                                                        this.props.fetchAssessStatistics(filter, (data) => {
                                                            console.log("This is the server data:", data.data)
                                                            this.setState({ applyFilterLoader: false, isLoading: false })
                                                            //statisticsData
                                                            if (data != 0) {
                                                                this.setState({ statisticsData: data.data })
                                                            }
                                                        })

                                                    }
                                                })
                                            }}
                                        >
                                            {
                                                this.state.loadMoreEnabled ?
                                                    <Spinner size="sm" color="light" style={{ marginRight: '5px' }} />
                                                    : null
                                            }
                                            Next</Button>
                                    </Col>
                                </Row>
                                <Row style={{ marginTop: 10 }}>
                                    <Col>
                                        <Button size="sm" color="primary" onClick={() => {
                                            var filter = {};
                                            if (this.state.groupId == 1) {
                                                filter = {
                                                    group_id: this.state.groupId,
                                                    dept_id: this.state.deptId,
                                                    div_id: this.state.divisionId,
                                                    section_id: this.state.sectionId,
                                                    page: this.state.page,
                                                    rating: this.state.selectedRating,
                                                    sub_groupid: this.state.subGroupId,
                                                    pc_id: this.state.pcId
                                                }
                                            }
                                            if (this.state.groupId == 2 || this.state.groupId == 3) {
                                                filter = {
                                                    group_id: this.state.groupId,
                                                    div_id: this.state.divisionId,
                                                    section_id: this.state.sectionId,
                                                    pc_id: this.state.pcId,
                                                    location_id: this.state.locationId,
                                                    page: this.state.page,
                                                    branch_id: this.state.branchIdFun,
                                                    rating: this.state.selectedRating,
                                                }

                                            }
                                            let queryString = new URLSearchParams(filter).toString();
                                            const token = generateToken()
                                            const tokenConvert = encodeURIComponent(token)
                                            let downloadURL = getBaseUrl() + 'api/download/assess/statistics/pdf/report?is_excel=0&year=' + getYear() + '&access_token=' + accessToken() + '&' + queryString + '&pass_token=' + tokenConvert;
                                            window.open(downloadURL, "_blank");
                                        }}>Download PDF</Button>
                                    </Col>
                                </Row>
                                <Row style={{ marginTop: 10 }}>
                                    <Col>
                                        <Button size="sm" color="primary" onClick={() => {
                                            var filter = {};
                                            if (this.state.groupId == 1) {
                                                filter = {
                                                    group_id: this.state.groupId,
                                                    dept_id: this.state.deptId,
                                                    div_id: this.state.divisionId,
                                                    section_id: this.state.sectionId,
                                                    page: this.state.page,
                                                    rating: this.state.selectedRating,
                                                    sub_groupid: this.state.subGroupId,
                                                    pc_id: this.state.pcId
                                                }
                                            }
                                            if (this.state.groupId == 2 || this.state.groupId == 3) {
                                                filter = {
                                                    group_id: this.state.groupId,
                                                    div_id: this.state.divisionId,
                                                    section_id: this.state.sectionId,
                                                    pc_id: this.state.pcId,
                                                    location_id: this.state.locationId,
                                                    page: this.state.page,
                                                    branch_id: this.state.branchId,
                                                    rating: this.state.selectedRating,
                                                }

                                            }
                                            let queryString = new URLSearchParams(filter).toString();
                                            const token = generateToken()
                                            const tokenConvert = encodeURIComponent(token)
                                            let downloadURL = getBaseUrl() + 'api/download/assess/statistics/pdf/report?is_excel=1&year=' + getYear() + '&access_token=' + accessToken() + '&' + queryString + '&pass_token=' + tokenConvert;
                                            window.open(downloadURL, "_blank");
                                        }}>Download Excel</Button>
                                    </Col>
                                </Row>
                            </>
                            : null

                    }
                    <Modal isOpen={modal} centered size={"sm"}>
                        {/* <ModalHeader >Modal title</ModalHeader> */}
                        <ModalBody>
                            Are you sure you want to
                            proceed and download the
                            report?
                        </ModalBody>
                        <ModalFooter>
                            <Button color="warning" onClick={() => {
                                this.setState({ modal: false,finishSelectionClicked:true }, () => {
                                    this.fetchAssessmentStatisticsHist();
                                })
                            }}>
                                YES
                            </Button>{' '}
                            <Button color="danger" onClick={() => {
                                this.setState({ modal: false })
                            }}>
                                NO
                            </Button>
                        </ModalFooter>
                    </Modal>
                </Container>

            </div>


        );
    }
}

AssessmentStatistics.propTypes = {
    fetchCompleteStats: PropTypes.func.isRequired,
    getAdditionalData: PropTypes.func.isRequired,
    getMainGroupsData: PropTypes.func.isRequired,
    getAssessGroupDeparts: PropTypes.func.isRequired,
    getAssessGroupDivisions: PropTypes.func.isRequired,
    getAssessSOGGroupDivisions: PropTypes.func.isRequired,
    getAssesstDivHierarchy: PropTypes.func.isRequired,
    getAssesstDiv: PropTypes.func.isRequired,
    getAssesstDivSesction: PropTypes.func.isRequired,
    fethcAdminAssessmentHistoryFilter: PropTypes.func.isRequired,
    getAssesstCFDivSesction: PropTypes.func.isRequired,
    getAssesstSOGDivSesction: PropTypes.func.isRequired,
    fetchAssessStatistics: PropTypes.func.isRequired,
    fetchSubGroupsOrLocationsOrSOG: PropTypes.func.isRequired,
    getAssesstDeptsHierarchy: PropTypes.func.isRequired,
    getAssesstSectionHierarchy: PropTypes.func.isRequired,
    getAssesstPCHierarchy: PropTypes.func.isRequired,
    getLocationDivsHierarchy: PropTypes.func.isRequired,
    getLocationSectionHierarchy: PropTypes.func.isRequired,
    getLocationPCHierarchy: PropTypes.func.isRequired,
    fetchLocationBranches: PropTypes.func.isRequired,
    getAssessBranchDivsHierarchy: PropTypes.func.isRequired,
    getAssessBranchSectionHierarchy: PropTypes.func.isRequired,
    getAssessBranchPCHierarchy: PropTypes.func.isRequired
}

const mapStatesToProps = state => ({
    getAllAssessmentStatistics: state.webRed.getAllAssessmentStatistics,
    additionalData: state.webRed.additionalData,
    mainGroupsData: state.webRed.mainGroupsData,
})

export default connect(mapStatesToProps, {
    fetchCompleteStats, getAdditionalData, getMainGroupsData,
    getAssessGroupDeparts, getAssessGroupDivisions, getAssessSOGGroupDivisions,
    getAssesstDivHierarchy, getAssesstDiv, getAssesstDivSesction, fethcAdminAssessmentHistoryFilter,
    getAssesstCFDivSesction, getAssesstSOGDivSesction, fetchAssessStatistics,
    fetchSubGroupsOrLocationsOrSOG,
    getAssesstDeptsHierarchy,
    getAssesstSectionHierarchy,
    getAssesstPCHierarchy,
    getLocationDivsHierarchy,
    getLocationSectionHierarchy,
    getLocationPCHierarchy,
    fetchLocationBranches,
    getAssessBranchDivsHierarchy,
    getAssessBranchSectionHierarchy,
    getAssessBranchPCHierarchy
})(AssessmentStatistics);