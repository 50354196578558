import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import classnames from 'classnames';
// import Header from './../Header';
// import Footer from "./../Footer";

import { getSubAdmins, removeSubAdmin } from './../../../../actions/webRedActions';

import { autoFocusNextField, getBaseUrl } from './../../../../actions/utility';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle, faCheck, faPencilAlt, faShieldAlt, faUserShield } from '@fortawesome/free-solid-svg-icons';
import AdminHeader from './../AdminHeader';
import SideMenu from './../sidMenu/SideMenu';

import axios from 'axios';
import './../../../../public/scss/ProfileComponentEmployee.scss';

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

// import userData from './../userData';


import {
    Button,
    Container,
    Row,
    Col,
    Table,
    Form,
    FormGroup,
    Label,
    Input,
    NavLink,
    Nav,
    NavItem,
    TabPane,
    TabContent,
    Alert,
    Spinner,
    ListGroup,
    ListGroupItem
} from "reactstrap";

import readXlsxFile from 'read-excel-file';
import { getAdminUserData } from "../../../../actions/utility";
import userData from "../../userData";
import ScoreCard from "../../assessment/ScoreCard";
import ScoreCardsList from "../../assessment/ScoreCardsList";

import AddSubAdmins from '../subAdmins/AddSubAdmins';

class SubAdmins extends Component {

    constructor(props) {
        super(props);
        this.state = {
            subAdminsData: [],
            isLoading: true,
            isAdd: false,
            btnMarginTop: 20
        };
    }
    componentWillReceiveProps(nextProps, thisProps) {
        console.log('here is the sub admins data:', nextProps.subAdminsData);
        if (typeof nextProps.subAdminsData.result.status != 'undefined') {
            localStorage.setItem('subAdminsData', JSON.stringify(nextProps.subAdminsData.result.result));
            this.setState({ subAdminsData: nextProps.subAdminsData.result.result, isLoading: false })
        }
    }
    componentWillMount() {
        this.props.getSubAdmins();
    }
    componentDidMount() {


    }
    /**
     * 
     * @param {*} adminId 
     */
    editSubAdmin = (adminEmpId, adminId) => {
        window.location.assign('/#/edit-sub-admin/' + adminEmpId + '/' + adminId)
    }
    /**
     * showing the cofirm boc on remove sub admin
     */
    showConfirmBox = (adminId) => {
        confirmAlert({
            title: 'Confirm to remove',
            message: 'Are you sure to do this.',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        this.props.removeSubAdmin(adminId, function (data) {

                            if (data != 0 && data.status == 'success') {
                                window.location.reload();
                            }
                        });
                    }
                },
                {
                    label: 'No',
                    onClick: () => {
                        //press no
                    }
                }
            ]
        });
    };

    render() {
        return (
            <div>
                <SideMenu />
                <AdminHeader title={'Admin Management'} style={{ marginLeft: 80 }} />
                {
                    this.state.isLoading == false ?
                        this.state.subAdminsData.length > 0 ?

                            <Container>
                                <Form>
                                    <Row style={{
                                        padding: '12px 0',
                                        textAlign: 'left',
                                        margin: '5vh 0',
                                        border: '1px #eaeaea solid'
                                    }}>

                                        <Col md={{ size: 12 }}>
                                            <FormGroup>
                                                <FontAwesomeIcon icon={faShieldAlt} size="2x" style={{ marginRight: '30px', marginLeft: '5px', fontSize: '24px', color: '#999', position: 'relative', top: '-15px' }} />
                                                <div style={{ display: 'inline-block' }}>
                                                    <Label for="section" style={{ marginBottom: 0 }}>Admin roles</Label>
                                                    <h5>{this.state.subAdminsData.length} Admins</h5>

                                                </div>

                                                <Table style={{ marginTop: 5, fontSize: '14px' }} striped >
                                                    <thead>
                                                        <th>Employee ID</th>
                                                        <th>Full Name</th>
                                                        <th>Branch</th>
                                                        <th>Designation</th>
                                                        <th></th>
                                                        <th></th>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            this.state.subAdminsData && this.state.subAdminsData.length ?
                                                                this.state.subAdminsData.map((subAdmin, key) =>
                                                                    <tr key={key}>
                                                                        <td style={{ fontSize: '16px' }}>{subAdmin.ID_NUMBER}</td>
                                                                        <td>{subAdmin.NAME}</td>
                                                                        <td>{subAdmin.BRANCH}</td>
                                                                        <td>{subAdmin.DESIGNATION}</td>
                                                                        <td style={{ color: 'red', fontSize: '12px', cursor: 'pointer' }}
                                                                            onClick={() => {
                                                                                this.showConfirmBox(subAdmin.admin_id)
                                                                            }}
                                                                        >
                                                                            Remove <FontAwesomeIcon icon={faTimesCircle} size="1x" style={{ marginLeft: '5px' }} />
                                                                        </td>
                                                                        <td style={{ color: '#666', fontSize: '12px', cursor: 'pointer' }}
                                                                            id={'_admins' + subAdmin.admin_id}
                                                                            onClick={() => {
                                                                                this.editSubAdmin(subAdmin.ID_NUMBER, key)
                                                                            }}
                                                                        >
                                                                            Edit <FontAwesomeIcon icon={faPencilAlt} size="1x" style={{ marginLeft: '5px' }} />
                                                                        </td>
                                                                    </tr>
                                                                )
                                                                : null
                                                        }

                                                    </tbody>
                                                </Table>
                                            </FormGroup>
                                        </Col>

                                    </Row>
                                </Form>
                                {/* {
                                this.state.subAdminsData.map((subAdmin,index)=>(

                                    <ListGroup  style={{flexDirection:'unset'}} index={index}>
                                        <ListGroupItem style={{border:'none'}}><strong>{subAdmin.ID_NUMBER}</strong></ListGroupItem>
                                        <ListGroupItem style={{border:'none'}}>{subAdmin.NAME}</ListGroupItem>
                                        <ListGroupItem style={{border:'none'}}>{subAdmin.SECTION}</ListGroupItem>
                                        <ListGroupItem style={{border:'none'}}>{subAdmin.DIVISION}</ListGroupItem>
                                        <ListGroupItem style={{border:'none',color:'red', cursor:'pointer' }} onClick={()=>{
                                            this.showConfirmBox(subAdmin.admin_id)
                                            }}><FontAwesomeIcon icon={faTimesCircle} size="1x" style={{marginRight: '10px'}} /> Remove</ListGroupItem>
                                        <ListGroupItem style={{border:'none',cursor:'pointer'}} id={'_admins'+subAdmin.admin_id} onClick={()=>{
                                            this.editSubAdmin(subAdmin.ID_NUMBER,index);
                                        }}><FontAwesomeIcon icon={faCheck} size="1x" style={{marginRight: '10px'}} /> Edit</ListGroupItem>
                                    </ListGroup>
                                ))
                           
                            } */}
                            </Container>

                            :
                            <Container style={{ marginTop: 20 }}>
                                <Alert color="warning">0 admin added yet.</Alert >
                            </Container>
                        :
                        <Container>
                            <Spinner color="primary" style={{ width: '2rem', height: '2rem', margin: '30vh 0' }} />
                        </Container>
                }
                <div >

                    {
                        this.state.isAdd ?

                            <AddSubAdmins />

                            :
                            <Container className="text-right">
                                <Button style={{ margin: "10px 10px 20px 0px" }} size="md" color="primary" onClick={() => {
                                    // window.location.href = "#/admin"
                                    window.location.assign(window.location.origin + "/#/admin");
                                }}>Download</Button>

                                <Button style={{ marginRight: 10, marginTop: 10, marginBottom: 20 }} size="md" color="secondary" onClick={() => {
                                    //if(this.state.isAdd == false)
                                    this.setState({ isAdd: true })

                                }}>Add new admin</Button>
                                {
                                    this.state.isWarn == true ?
                                        <Alert color="warning">0 admin added yet.</Alert >
                                        :
                                        null
                                }

                            </Container>
                    }

                </div>

            </div>
        );
    }
}

SubAdmins.propTypes = {
    getSubAdmins: PropTypes.func.isRequired,
    removeSubAdmin: PropTypes.func.isRequired

}

const mapStatesToProps = state => ({
    subAdminsData: state.webRed.subAdminsData,

})

export default connect(mapStatesToProps, { getSubAdmins, removeSubAdmin })(SubAdmins);