import React, { Component } from "react";
import Header from './Header';
// import Footer from "./Footer";

import { uploadDataDept ,uploadEmpData} from './../../actions/webRedActions';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import axios from 'axios';

import columnsData from './testDataColumns';
import tempData from './branchDataTemp';

import {
    Button,
    Container,
    Row,
    Col,
    Media
  } from "reactstrap";

import readXlsxFile from 'read-excel-file';

// import { BrowserRouter as Router, Route, Link } from "react-router-dom";

class ImportEmpSheetComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            enableLoader : false,
            branchName : '',
            counter: 0,
            empData : {
                "ID_NUMBER" : "group_name",
                "LAST_NAME" : "sub_group",
                "FIRST_NAME": "dept_name",
                "MI" : "location",
                "NAME" : "branch",
                "DESIGNATION" : "division",
                "JG" : "section",
                "BRANCH" : "id_number",
                "DEPT": "last_name",
                "DIVISION" : "first_name",
                "SECTION" : "mi",
                "null_11" : "designation",
                "null_12" : "jg"
            }
        };
    }

    isString = (value) => {
        return typeof value === 'string' || value instanceof String;
    }

    isNumber = (value) => {
        return typeof value === 'number' && isFinite(value);
    }

    isInt = (n) => {
        return n % 1 === 0;
    }
    
    checkColumnType = (value) => {
        if(this.isNumber(value)){
            if(this.isInt(value)){
                return 'int'
            } else {
                return 'float'
            }
        } else if(this.isString(value)){
            return 'string'
        } else {
            return 'string'
        }
    }

    componentDidMount() {

        const input = document.getElementById('input');
 
        input.addEventListener('change', () => {

            if(this.state.branchName == '') { // check if the branch name is not given.
                
                alert('branch name is required');
                return false;

            } else {

                this.setState({
                    enableLoader : true
                }, () => {
    
                    readXlsxFile(input.files[0]).then((rows) => {
                        // `rows` is an array of rows
                        // each row being an array of cells.

                        this.setState({
                            enableLoader : false
                        }, () => {
                            // console.log("Out of the loop",rows.length);
                            // return false;
                            var dataJsonMain = [];
                            if(rows && rows.length) {
                                // var valuesArray = rows[1];
                                console.log("rows:",rows.length)
                                rows.map((row, key) => {
                                   

                                    var obj = {};
                                    if(key > 2 ) { // ignore the heading row from sheet
                                       
                                        row.length && row.map((data, key2) => {
                                            
                                            console.log(columnsData[key2].col_name,"=====",data)
                                            if (typeof this.state.empData[columnsData[key2].col_name] !=="undefined") {
                                                if (data =="-" || data == null)
                                                    obj[this.state.empData[columnsData[key2].col_name]] = 0;
                                                else
                                                    obj[this.state.empData[columnsData[key2].col_name]] = data;
                                            }
                                            //return false;
                                           
                                            // if( this.state.requiredNames.includes(columnsData[key2].col_name) ) {
                                            //     // console.log(data,"In other loop:");
                                            //     if(columnsData[key2].col_name == "FIRST_NAME" && data !="SUB-TOTAL OFFICE OF THE AVP" &&
                                            //     data !="SUB-TOTAL AML COMPLIANCE MONITORING" && data !="SUB-TOTAL TRAINING, POLICY DEVELOPMENT, REPORTS AND ADMIN.") {
                                                    
                                            //         if( this.state.divisions.includes(data) ) {
                                            //             console.log("This division data:",data);
                                            //             //division = data;
                                            //             obj['division'] = data;
                                            //             if( this.state.sections.includes(data) ) {
                                            //                 obj['section'] = data;
                                            //             }
                                            //             else {
                                            //                 obj['section'] = "null";
                                            //             }
                                            //         }
                                            //         else {
                                            //             obj['division'] = "null";
                                            //             if( this.state.sections.includes(data) ) {
                                            //                 obj['section'] = data;
                                            //             }
                                            //             else {
                                            //                 obj['section'] = "null";
                                            //             }
                                            //         }
                                                    
                                            //     }
                                            //     if(columnsData[key2].col_name == "MI" && data !=null && this.checkColumnType(data) == "string") {
                                            //         obj['JG'] = data 
                                            //     }
                                            //     if(columnsData[key2].col_name == "NAME" && data !=null && this.checkColumnType(data) == "string") {
                                            //         obj['designation'] = data 
                                            //     } 
                                            // }

                                        })
                                        // console.log("out of the loop.");
                                        //console.log("obj:",obj)
                                        if(Object.keys(obj).length)
                                            dataJsonMain.push(obj);
                                    }
                                    // console.log("dataJsonMain:",dataJsonMain)
                                    if((rows.length - 1) == key) {
                                        // dataJsonMain.pop();
                                        // console for all rows data in a sheet which is imported
                                        console.log("dataJsonMain:",dataJsonMain);

                                        this.props.uploadEmpData(
                                            this.state.branchName, // this is selected branch
                                            dataJsonMain // this is data for each row
                                            // this.state.counter
                                        );

                                    }

        
                                });
        
                                
                                // rows[0].length && rows[0].map((row, key) => {
                                //     dataJson.push({
                                //         col_name : row != null ? row.replace(" ", "_") : 'null_' + key,
                                //         col_type : this.checkColumnType(valuesArray[key])
                                //     })
        
                                //     if(rows[0].length == (key + 1)){
                                //         console.log(dataJson);
                                //         console.log(JSON.stringify(dataJson));
                                //     }
        
                                // })
                            }
        
                        })
        
                    })
    
                })

            }
            
        })
        
    }

    addColumnsToDB = () => {
        console.log(columnsData);

        var bodyFormData = new FormData();
        // var userdata = getUserData();
        bodyFormData.set('data', JSON.stringify(columnsData));

        axios.post('http://localhost:8000/api/branch/tableschema', bodyFormData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
        .then((res) => {
            console.log(res);
        })
        .catch((error) => {
            console.log(error);
        });
    }

    // sendData = (dataForRow) => {

    //     var bodyFormData = new FormData();
    //     // var userdata = getUserData();
    //     bodyFormData.set('final_data', JSON.stringify(dataForRow));
    //     bodyFormData.set('branch_name', this.state.branchName);

    //     axios.post('http://localhost:8000/api/branch/add', bodyFormData, {
    //         headers: {
    //             'Content-Type': 'multipart/form-data'
    //         }
    //     })
    //     .then((res) => {
    //         console.log(res);
    //     })
    //     .catch((error) => {
    //         console.log(error);
    //     });
    // }


  render() {

    return (
      <div className="HomeComponent">
        <Header />

        <div className={"BannerWithText " + this.state.theme}>

            <Container>
                <Row>
                    <Col style={{textAlign: 'left'}}>
                        <br />
                        <h2 style={{margin: '10px 0'}}>Import Data</h2><br />
                        
                        Dept Name: <br />
                        <input type="text" value={this.state.branchName} id="branchName" onChange={e => {
                            this.setState({
                                branchName : e.target.value
                            }, () => {
                                console.log(this.state.branchName)
                            })
                        }} /> <br /><br />

                        Upload excel sheet: <br />
                        <input disabled={this.state.branchName == '' || this.state.enableLoader} type="file" id="input" />

                        {
                            this.state.enableLoader ?
                                <h3>Loading...</h3>
                            : null
                        }
                        
                        <br/><br />

                        {/* <button disabled={this.state.branchName == '' || this.state.enableLoader} onClick={e => this.sendData(this.state.branchName)}>Send Data</button> */}

                        {/* <button onClick={(e) => this.addColumnsToDB()}>add Columns</button> */}

                    </Col>
                </Row>
            </Container>

        </div>
        
        {/* <Footer /> */}
      </div>
    );
  }
}

ImportEmpSheetComponent.propTypes = {
    uploadDataDept: PropTypes.func.isRequired,
    uploadEmpData : PropTypes.func.isRequired
}

const mapStatesToProps = state => ({
    dataBranchSuccessful : state.webRed.dataBranchSuccessful
})

export default connect( mapStatesToProps, { uploadDataDept,uploadEmpData })( ImportEmpSheetComponent );