import React, { Component } from "react";
import { Redirect } from "react-router-dom";

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getAdminUserData } from "../../../../../src/actions/utility";
// import userData from './../userData';

import {
    Button,
    Container,
    Row,
    Col,
    Media,
    Form,
    FormGroup,
    Label,
    Input
  } from "reactstrap";

import { ProSidebar, SidebarHeader, SidebarFooter, SidebarContent, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faBars,faHome,faUsers,faStickyNote,faHistory,faUserCircle,
   faChartPie, faChartLine,faChartArea, faPlusCircle,
    faDotCircle, faUserFriends, faUser, faRedoAlt,faTasks,
    faChartBar
  } from '@fortawesome/free-solid-svg-icons';

import './custom.css';
import { type } from "jquery";
class SideMenu extends Component {

  constructor(props) {
      super(props);
      this.state = {
        collapsed:true,
        toggled:true,
        showMenuIcon:true,
        canEditAdmin:0,
        isSuperAdmin:0,
        canAddEmp:0,
        showEmpMenuSubItem: false,
        showManageAssessmentSubItems : false
      };
  }
  componentWillMount() {
    
    
    try {
          var adminData = getAdminUserData();
          
          console.log('here is the data:',adminData);

          this.state.canEditAdmin = adminData.admin.can_editadmin;

          this.state.isSuperAdmin = adminData.is_superadmin;

          this.state.canAddEmp = adminData.admin.can_addemp;
    }
    catch(err) {
      console.log('storage error:',err);
    }
  }
  componentDidMount() {
    console.log(this.state.canEditAdmin);      
  }

  render() {

    const {collapsed,toggled,showMenuIcon} = this.state;

    return (
     
        <div style={{ width:100}} onMouseEnter={()=>{
          this.setState({collapsed:false,showMenuIcon:false})
        }}
        onMouseLeave={()=>{
          this.setState({collapsed:true,showMenuIcon:true})
        }}
        >
            <ProSidebar
              //image={image ? sidebarBg : false}
              collapsed={collapsed}
              toggled={toggled}
              breakPoint="md"
              onToggle={(val)=>{
                console.log('toglled');
              }}
            >
              <SidebarHeader>
                <div
                  style={{
                    padding: '0 24px',
                    textTransform: 'uppercase',
                    fontWeight: 'bold',
                    fontSize: 14,
                    letterSpacing: '1px',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }}
                >
                  { showMenuIcon == true ? 
                      <FontAwesomeIcon className="userIconHeader" icon={faBars} size="1x" />
                    :
                      "Pagcor Admin"
                  }
                </div>
              </SidebarHeader>
              <SidebarContent>
              
              {
                this.state.canEditAdmin == 1 ? 
                <Menu iconShape="circle" onClick={()=>{
                    
                    window.location.assign('/#/sub-admin')
                    
                }}>
                  <MenuItem
                    icon={<FontAwesomeIcon className="userIconHeader" icon={faUserFriends} size="1x" />}
                    
                  >
                    {'Admin Management'}
                  </MenuItem>
                
                </Menu>
                :null
              }
              
              {
                this.state.canEditAdmin == 1 ? 
                <Menu iconShape="circle" onClick={()=>{
                    
                    window.location.assign('/#/assessment')
                    
                }}>
                  <MenuItem
                    icon={<FontAwesomeIcon className="userIconHeader" icon={faTasks} size="1x" />}
                    
                  >
                    {'Assessment Sets'}
                  </MenuItem>
                
                </Menu>
                :null
              }
              <Menu iconShape="circle" onClick={() => this.setState({
                showEmpMenuSubItem : !this.state.showEmpMenuSubItem
              })}>
                <MenuItem
                  icon={<FontAwesomeIcon className="userIconHeader" icon={faUser} size="1x" />}
                  
                >
                  {'Employee Management'}
                </MenuItem>
              
              </Menu>

              {
                this.state.showEmpMenuSubItem ? 

                  <Menu iconShape="circle" onClick={()=>{
                    window.location.assign('/#/admin')
                  }}>
                    <MenuItem
                      className="subItem"
                      icon={<FontAwesomeIcon className="userIconHeader" icon={faDotCircle} size="1x" />}
                      
                    >
                      {'Search Existing Employee'}
                    </MenuItem>
                  
                  </Menu>

                : null
              }
              
              {
                this.state.showEmpMenuSubItem && this.state.canAddEmp == 1 ? 

                  <Menu iconShape="circle" onClick={()=>{
                    window.location.assign('/#/employee/add')
                  }}>
                    <MenuItem
                      className="subItem"
                      icon={<FontAwesomeIcon className="userIconHeader" icon={faDotCircle} size="1x" />}
                      
                    >
                      {'Add New Employee'}
                    </MenuItem>
                  
                  </Menu>
                  
                : null
              }
              
              <Menu iconShape="circle" onClick={()=>{
                    window.location.assign('/#/assessment-history')
              }}>
                <MenuItem
                  icon={<FontAwesomeIcon className="userIconHeader" icon={faHistory} size="1x" />}
                  
                >
                  {'Assessment History'}
                </MenuItem>
              
              </Menu>

              
              <Menu iconShape="circle" onClick={()=>{
                    window.location.assign('/#/statistics')
              }}>
                <MenuItem
                  icon={<FontAwesomeIcon className="userIconHeader" icon={faChartLine} size="1x" />}
                  
                >
                  {'Reports & Analytics'}
                </MenuItem>
              
              </Menu>
              
              <Menu iconShape="circle" onClick={()=>{
                    window.location.assign('/#/em-reports')
              }}>
                <MenuItem
                  icon={<FontAwesomeIcon className="userIconHeader" icon={faChartPie} size="1x" />}
                  
                >
                  {'Employee Reports'}
                </MenuItem>
              
              </Menu>
              {
              this.state.isSuperAdmin == 1 ?
              <Menu iconShape="circle" onClick={()=>{
                window.location.assign('/#/audit-logs')
              }}>
                <MenuItem
                  icon={<FontAwesomeIcon className="userIconHeader" icon={faRedoAlt} size="1x" />}
                  
                >
                  {'Audit logs'}
                </MenuItem>
              
              </Menu>
              :null
              }
              {
              this.state.isSuperAdmin == 1 ?
              <Menu iconShape="circle" onClick={()=>{
                this.setState({
                  showManageAssessmentSubItems : !this.state.showManageAssessmentSubItems
                })
              }}>
                <MenuItem
                  icon={<FontAwesomeIcon className="userIconHeader" icon={faTasks} size="1x" />}
                  
                >
                  {'Manage Assessment'}
                </MenuItem>
              
              </Menu>
              
              
              :null
              }
              {
                this.state.showManageAssessmentSubItems ? 

                  <Menu iconShape="circle" onClick={()=>{
                    window.location.assign('/#/search-assessment')
                  }}>
                    <MenuItem
                      className="subItem"
                      icon={<FontAwesomeIcon className="userIconHeader" icon={faDotCircle} size="1x" />}
                      
                    >
                      {'Search Assigned Assessments'}
                    </MenuItem>
                  
                  </Menu>

                : null
              }
              {
                this.state.showManageAssessmentSubItems ? 

                  <Menu iconShape="circle" onClick={()=>{
                    window.location.assign('/#/edit-assessment')
                  }}>
                    <MenuItem
                      className="subItem"
                      icon={<FontAwesomeIcon className="userIconHeader" icon={faDotCircle} size="1x" />}
                      
                    >
                      {'Edit Assessments'}
                    </MenuItem>
                  
                  </Menu>

                : null
              }
              {
                this.state.showManageAssessmentSubItems ? 

                  <Menu iconShape="circle" onClick={()=>{
                    window.location.assign('/#/manage-assessment')
                  }}>
                    <MenuItem
                      className="subItem"
                      icon={<FontAwesomeIcon className="userIconHeader" icon={faDotCircle} size="1x" />}
                      
                    >
                      {'Assign New Designation'}
                    </MenuItem>
                  
                  </Menu>

                : null
              }
              {
              this.state.isSuperAdmin == 1 ?
              <Menu iconShape="circle" onClick={()=>{
                console.log("Load the charts")
                window.location.assign('/#/pagocr-charts')
              }}>
                <MenuItem
                  icon={<FontAwesomeIcon className="userIconHeader" icon={faChartBar} size="1x" />}>
                  {'Charts'}
                </MenuItem>
              
              </Menu>
              
              
              :null
              }
              </SidebarContent>
             
            </ProSidebar>
        </div>
        
    );
  }
}

SideMenu.propTypes = {
    // uploadDataBranch: PropTypes.func.isRequired
}

const mapStatesToProps = state => ({
    fetchEmpDataSuccessAdmin : state.webRed.fetchEmpDataSuccessAdmin,
    fetchedAllAssessmentsForAdmin : state.webRed.fetchedAllAssessmentsForAdmin
})

export default connect( mapStatesToProps, {  })( SideMenu );