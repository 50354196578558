import React, { Component } from "react";
// import Header from './../Header';
// import Footer from "./../Footer";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle, faCheckCircle } from '@fortawesome/free-solid-svg-icons';

import './../../../../public/scss/EmployeeManagment.scss';

import { showHideLoader } from './../../../../actions/webRedActions';
import { autoFocusNextField,getYear } from './../../../../actions/utility';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// import userData from './../userData';

import {
    Button,
    Container,
    Row,
    Col,
    Media,
    Form, FormGroup, Label, Input, FormText,
    Spinner
  } from "reactstrap";

// import readXlsxFile from 'read-excel-file';

// import { BrowserRouter as Router, Route, Link } from "react-router-dom";

class EmployeeSearchForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            
            // userInfo : {
            //     employee_id : '01-1234'
            // }, // dummy user data
            employeeId : ["", "", "-", "", "", "", ""],
            employeeIdString : "",
            employeeIdError : "",
            isLoading: false,
            // enableNextBtn : false

        };
    }
  
    componentDidMount() {

        autoFocusNextField("boxFieldForm"); // auto focus utility method called

    }

    componentWillReceiveProps(nextProps, thisProps) {
        
        //console.log('nextProps:',nextProps)

        if(this.props.handleLoader != nextProps.handleLoader) {
            console.log("handleLoader:",nextProps.handleLoader);
            this.setState({isLoading : nextProps.handleLoader.data})
        }
        if(this.props.resetEmpId != nextProps.resetEmpId) {
            this.setState({employeeId : ["", "", "-", "", "", "", ""]})
        }


    }

    handleChange = (event, index) => {
        event.preventDefault();
        const { name, value } = event.target;

        var employeeIdTemp = this.state.employeeId;
        employeeIdTemp[index] = value;

        this.setState({
            [name] : employeeIdTemp
        }, () => {
            // console.log(this.state.employeeId);

            var emp_id = this.convertEmpIdToString(this.state.employeeId);
            this.setState({
                employeeIdString : emp_id
            }, () => {
                // it means all inputs are done here we need to call action to verify
                
                if(emp_id.length == 7) {
                }
                // console.log(this.state.employeeIdString)
            })

        })

        if(this.state.employeeIdError != "") {
            this.setState({
                employeeIdError : ""
            })
        }
    }

    handleSubmit = (event) => {
        event.preventDefault();
        
    }

    convertEmpIdToString = (empId) => {
        var stringEmpId = "";
        empId.map(val => {
            if(val && val != "" && val != undefined){
                stringEmpId += val;
            }
        })

        return stringEmpId;
    }

    render() {

    const {employeeId, employeeIdError, employeeIdString, isLoading} = this.state;

    return (
      <Container className="EmployeeSearchForm"  style={{padding: '8vh 5vw', textAlign: 'center'}}>

        
        <Row>
            <Col md={{size: 12, offset: 0}}>
                <Form>
                   <Row style={{
                        marginBottom: '30px',
                        padding: '1.5rem 1rem',
                        backgroundColor: '#fef67f',
                        border: '1px #ffd29b solid',
                        borderRadius: '3px'
                    }}>
                     <Col md={4} style={{textAlign: 'right'}}>
                        <p>{typeof this.props.textTitle !=='undefined' ? this.props.textTitle :'Search by employee id:'}</p>
                     </Col>  
                     <Col md={5} style={{textAlign: 'left'}}>
                        <FormGroup className="boxFieldForm" style={{marginBottom: 0}}>
                        
                            <Input type="text" bsSize="lg" maxLength="1" name="employeeId" id="employeeId1"
                                value={employeeId[0]}
                                onChange={evt => this.handleChange(evt, 0)} />

                            <Input type="text" bsSize="lg" maxLength="1" name="employeeId" id="employeeId2" 
                                value={employeeId[1]}
                                onChange={evt => this.handleChange(evt, 1)} />

                            <Input style={{
                                background: '#fef67f',
                                border: 'none',
                                fontSize: '36px',
                                height: '10px'
                            }} type="text" bsSize="lg" value="-" maxLength="1" name="dash" id="dash" disabled />

                            <Input type="text" bsSize="lg" maxLength="1" name="employeeId" id="employeeId3"
                                value={employeeId[3]}
                                onChange={evt => this.handleChange(evt, 3)} />

                            <Input type="text" bsSize="lg" maxLength="1" name="employeeId" id="employeeId4"
                                value={employeeId[4]}
                                onChange={evt => this.handleChange(evt, 4)} />

                            <Input type="text" bsSize="lg" maxLength="1" name="employeeId" id="employeeId5"
                                value={employeeId[5]}
                                onChange={evt => this.handleChange(evt, 5)} />

                            <Input type="text" bsSize="lg" maxLength="1" name="employeeId" id="employeeId6"
                                value={employeeId[6]}
                                onChange={evt => this.handleChange(evt, 6)} />

                        </FormGroup>
                    </Col>
                    <Col md={3} style={{textAlign: 'left'}}>
                    {
                        employeeIdString.length == 7 ?
                            typeof this.props.btnTitle === 'undefined' || this.props.btnTitle == '' ?
                            <Button style={{margin: '0 0 0 0'}} color={"warning"} size="lg"
                                onClick={(e) => {
                                    this.setState({
                                        isLoading : true
                                    }, () => {
                                        console.log('in the function');
                                        this.props.searchDataFunc(this.props.tab,employeeIdString);
                                    })
                                }}
                                disabled={isLoading}
                            >
                                Search
                                {
                                    isLoading ? <Spinner style={{margin: '4px 0px 4px 10px'}} color="light" size="sm" /> : null
                                }
                            </Button>
                            :<Button style={{margin: '0 0 0 0'}} color={"warning"} size="lg"
                            onClick={(e) => {
                                this.setState({
                                    isLoading : true
                                }, () => {
                                    this.props.validateEmpId(employeeIdString);
                                })
                            }}
                            disabled={isLoading}
                        >
                            {this.props.btnTitle}
                            {
                                isLoading ? <Spinner style={{margin: '4px 0px 4px 10px'}} color="light" size="sm" /> : null
                            }
                        </Button>
                        : null
                    }
                    </Col>

                    {
                        employeeIdError && employeeIdError != "" ?
                        <Col>
                            <p style={{color: 'red'}}>
                                <FontAwesomeIcon icon={faTimesCircle} size="1x" style={{marginRight: '10px'}} />
                                {employeeIdError}
                            </p>
                            </Col>
                        : null
                    }
                    </Row>
                </Form>
            </Col>
        </Row>
        <Row>
            <Col style={{textAlign: 'center'}}>
              
            </Col>
        </Row>
      </Container>
    );
  }
}

EmployeeSearchForm.propTypes = {
    showHideLoader : PropTypes.func.isRequired
}

const mapStatesToProps = state => ({
    handleLoader : state.webRed.handleLoader,
    resetEmpId : state.webRed.resetEmpId
})

export default connect( mapStatesToProps, {  })( EmployeeSearchForm );