import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import Header from './../Header';
// import Footer from "./../Footer";

import { uploadDataBranch } from './../../../actions/webRedActions';
import { checkIfEmpIsAdmin } from './../../../actions/utility';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import userData from './../userData';

import EmployeedIdForm from './EmployeedIdForm';
import VerificationCodeForm from './VerificationCodeForm';

import {
    Button,
    Container,
    Row,
    Col,
    Media
  } from "reactstrap";

import readXlsxFile from 'read-excel-file';
import * as Sentry from "@sentry/react";
// import { BrowserRouter as Router, Route, Link } from "react-router-dom";

class LoginComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            
            isUserIdValid : false,
            // redirectToProfile : null,
            userEmail : "",
            employee_id : '',
            isAdmin : 0,
            showView:true,
            adminType:0

        };
    }
    
    componentDidMount() {
        
        if(localStorage.getItem('isLoggedInPagCor') && localStorage.getItem('isLoggedInPagCor') == 'true') {
            
            if(checkIfEmpIsAdmin()) {
                
                if(typeof localStorage.adminRoute !== 'undefined' && localStorage.adminRoute != 'none') {
                    window.location.href = "/#/"+localStorage.adminRoute;
                }
                else if(localStorage.adminRoute == 'none') {
                    this.setState({
                        showView:false
                    })
                }
                else {
                    window.location.href = "/#/admin";
                }
            }
            else {
                window.location.href = "/#/profile";
            }
            //window.location.href = checkIfEmpIsAdmin() ? "/#/admin" : "/#/profile";
        }

        // window.onbeforeunload = function(){
        //   if(this.state.isUserIdValid){
        //     return false;
        //   }
        // }.bind(this);

        //check and just ask for verification code.
        // if(localStorage.getItem('waitingForPinCodePagcor') == true) {
        //     this.setState({
        //         isUserIdValid : true
        //     }, () => {
        //         localStorage.setItem('waitingForPinCodePagcor', true);
        //     })
        // }
    }

    componentWillReceiveProps(nextProps, thisProps) {
        // console.log(nextProps);
        if(this.props.triggerNextPinSuccess != nextProps.triggerNextPinSuccess) {
            // console.log(nextProps.triggerNextPinSuccess)
            if(typeof nextProps.triggerNextPinSuccess.data !=="undefined" && nextProps.triggerNextPinSuccess.data.status == "success") {

                if(nextProps.triggerNextPinSuccess.resend) { // if resend code true
                    confirmAlert({
                        title: 'Successful',
                        message: "we have resend you the new verification code",
                        closeOnEscape: false,
                        closeOnClickOutside: false,
                        buttons: [
                            {
                                label: 'Thanks',
                                onClick: () => {
                                    console.log('Thanks');
                                }
                            }
                        ]
                    })
                }
                else {
                    this.setState({
                        userEmail : nextProps.triggerNextPinSuccess.data.result,
                        employee_id : nextProps.triggerNextPinSuccess.employee_id,
                        isUserIdValid : true,
                        adminType : nextProps.triggerNextPinSuccess.data.admin_type
                    }, () => {
                        // localStorage.setItem('waitingForPinCodePagcor', true);
                        var isAdmin = nextProps.triggerNextPinSuccess.data.is_admin;
                        var adminType = nextProps.triggerNextPinSuccess.data.admin_type;

                        localStorage.setItem("userIsAdmin", isAdmin);
                        localStorage.setItem("adminType", adminType);

                        if(isAdmin == 1) {
                            this.setState({
                                isAdmin : 1
                            })
                        }
                    })
                }

            }
            else
                {
                    // alert("Something went wrong while login please try again.");
                    Sentry.captureMessage(JSON.stringify(nextProps.triggerNextPinSuccess))
                }
        }

        // if(this.props.triggeredLoginAction != nextProps.triggeredLoginAction) {
        //     console.log(nextProps.triggeredLoginAction);
        //     if(nextProps.triggeredLoginAction.data.status == "success") {

        //         localStorage.setItem("isLoggedInPagCor", "true"); // create storage for user session
                
        //         //setting the localstorage for persistency of userData
        //         var userProfile = nextProps.triggeredLoginAction.data.result;
        //         localStorage.setItem("pagCorUserProfile", JSON.stringify(userProfile));
                
        //         this.setState({
        //             redirectToProfile : "/profile"
        //         })

        //     }
            
        // }

    }
    handleDataFromChild = (data) => {
        console.log("Data From Child Component:",data)
        this.setState({ isUserIdValid: data });
      };

  render() {

    // if (this.state.redirectToProfile) {
    //     return <Redirect to={this.state.redirectToProfile} />
    // }
    
    return (
      <div className="LoginComponent">
        <Header />
        {
        this.state.showView == true ?  
        <div>

            <Container>
                <Row>
                    <Col md={{size: 6, offset: 3}} style={{padding: '5vh 15px'}}>
                        {
                            !this.state.isUserIdValid ?
                                <EmployeedIdForm />
                            :
                                <VerificationCodeForm sendDataToParent={this.handleDataFromChild} employeeId={this.state.employee_id} userEmail={this.state.userEmail} isAdmin={this.state.isAdmin} adminType={this.state.adminType}/>
                        }
                    </Col>
                </Row>
            </Container>

        </div>
        :null
        }
        {/* <Footer /> */}
      </div>
    );
  }
}

LoginComponent.propTypes = {
    // uploadDataBranch: PropTypes.func.isRequired
}

const mapStatesToProps = state => ({
    triggerNextPinSuccess : state.webRed.triggerNextPinSuccess,
    // triggeredLoginAction : state.webRed.triggeredLoginAction
})

export default connect( mapStatesToProps, {  })( LoginComponent );