const assessCalcMappingData = [];

assessCalcMappingData["C1"] = {
    "A" : 5,
    "B" : 6,
    "C" : 5,
    "D" : 4
};
assessCalcMappingData["C2"] = {
    "A" : 4,
    "B" : 5,
    "C" : 6,
    "D" : 4
};
assessCalcMappingData["C3"] = {
    "A" : 4,
    "B" : 5,
    "C" : 6,
    "D" : 4
};
assessCalcMappingData["C4"] = {
    "A" : 6,
    "B" : 4,
    "C" : 6,
    "D" : 4
};
assessCalcMappingData["C5"] = {
    "A" : 6,
    "B" : 7,
    "C" : 7,
    "D" : 7
};
assessCalcMappingData["O1"] = {
    "A" : 4,
    "B" : 4,
    "C" : 5,
    "D" : 5
};
assessCalcMappingData["O2"] = {
    "A" : 6,
    "B" : 6,
    "C" : 6,
    "D" : 6
};
assessCalcMappingData["O3"] = {
    "A" : 5,
    "B" : 5,
    "C" : 4,
    "D" : 3
};
assessCalcMappingData["O4"] = {
    "A" : 5,
    "B" : 6,
    "C" : 5,
    "D" : 4
};
assessCalcMappingData["O5"] = {
    "A" : 4,
    "B" : 5,
    "C" : 5,
    "D" : 6
};
assessCalcMappingData["L1"] = {
    "A" : 4,
    "B" : 4,
    "C" : 4,
    "D" : 3
};
assessCalcMappingData["L2"] = {
    "A" : 3,
    "B" : 4,
    "C" : 4,
    "D" : 4
};
assessCalcMappingData["L3"] = {
    "A" : 4,
    "B" : 4,
    "C" : 5,
    "D" : 5
};
assessCalcMappingData["L4"] = {
    "A" : 4,
    "B" : 4,
    "C" : 5,
    "D" : 5
};
assessCalcMappingData["L5"] = {
    "A" : 6,
    "B" : 6,
    "C" : 6,
    "D" : 6
};
assessCalcMappingData["T1"] = {
    "A" : 4,
    "B" : 4,
    "C" : 4,
    "D" : 4
};
assessCalcMappingData["T2"] = {
    "A" : 4,
    "B" : 4,
    "C" : 4,
    "D" : 4
};
assessCalcMappingData["T3"] = {
    "A" : 4,
    "B" : 4,
    "C" : 4,
    "D" : 3
};
assessCalcMappingData["T4"] = {
    "A" : 4,
    "B" : 5,
    "C" : 5,
    "D" : 5
};
assessCalcMappingData["T5"] = {
    "A" : 4,
    "B" : 5,
    "C" : 5,
    "D" : 6
};
assessCalcMappingData["T6"] = {
    "A" : 4,
    "B" : 5,
    "C" : 5,
    "D" : 5
};
assessCalcMappingData["T7"] = {
    "A" : 5,
    "B" : 5,
    "C" : 5,
    "D" : 5
};
assessCalcMappingData["T8"] = {
    "A" : 8,
    "B" : 7,
    "C" : 6,
    "D" : 5
};
assessCalcMappingData["T9"] = {
    "A" : 5,
    "B" : 7,
    "C" : 8,
    "D" : 8
};
assessCalcMappingData["T10"] = {
    "A" : 4,
    "B" : 4,
    "C" : 4,
    "D" : 4
};
assessCalcMappingData["T11"] = {
    "A" : 4,
    "B" : 5,
    "C" : 5,
    "D" : 5
};
assessCalcMappingData["T12"] = {
    "A" : 7,
    "B" : 7,
    "C" : 7,
    "D" : 6
};
assessCalcMappingData["T13"] = {
    "A" : 5,
    "B" : 5,
    "C" : 5,
    "D" : 1
};





assessCalcMappingData["T14"] = {
    "A" : 5,
    "B" : 5,
    "C" : 5,
    "D" : 5
};
assessCalcMappingData["T15"] = {
    "A" : 5,
    "B" : 5,
    "C" : 5,
    "D" : 5
};
assessCalcMappingData["T16"] = {
    "A" : 4,
    "B" : 4,
    "C" : 4,
    "D" : 4
};
assessCalcMappingData["T17"] = {
    "A" : 3,
    "B" : 4,
    "C" : 5,
    "D" : 5
};
assessCalcMappingData["T18"] = {
    "A" : 5,
    "B" : 8,
    "C" : 8,
    "D" : 8
};
assessCalcMappingData["T19"] = {
    "A" : 8,
    "B" : 10,
    "C" : 10,
    "D" : 10
};



assessCalcMappingData["T20"] = {
    "A" : 3,
    "B" : 5,
    "C" : 5,
    "D" : 5
};
assessCalcMappingData["T21"] = {
    "A" : 6,
    "B" : 6,
    "C" : 6,
    "D" : 6
};
assessCalcMappingData["T22"] = {
    "A" : 6,
    "B" : 6,
    "C" : 9,
    "D" : 8
};
assessCalcMappingData["T23"] = {
    "A" : 4,
    "B" : 7,
    "C" : 9,
    "D" : 9
};
assessCalcMappingData["T24"] = {
    "A" : 5,
    "B" : 4,
    "C" : 4,
    "D" : 4
};
assessCalcMappingData["T25"] = {
    "A" : 9,
    "B" : 10,
    "C" : 9,
    "D" : 11
};



assessCalcMappingData["T26"] = {
    "A" : 4,
    "B" : 3,
    "C" : 7,
    "D" : 7
};
assessCalcMappingData["T27"] = {
    "A" : 4,
    "B" : 5,
    "C" : 5,
    "D" : 5
};
assessCalcMappingData["T28"] = {
    "A" : 4,
    "B" : 4,
    "C" : 4,
    "D" : 4
};
assessCalcMappingData["T29"] = {
    "A" : 4,
    "B" : 4,
    "C" : 4,
    "D" : 4
};
assessCalcMappingData["T30"] = {
    "A" : 4,
    "B" : 5,
    "C" : 5,
    "D" : 5
};
assessCalcMappingData["T31"] = {
    "A" : 5,
    "B" : 5,
    "C" : 3,
    "D" : 3
};
assessCalcMappingData["T32"] = {
    "A" : 5,
    "B" : 5,
    "C" : 5,
    "D" : 5
};
assessCalcMappingData["T33"] = {
    "A" : 5,
    "B" : 5,
    "C" : 5,
    "D" : 5
};
assessCalcMappingData["T34"] = {
    "A" : 5,
    "B" : 6,
    "C" : 7,
    "D" : 5
};
assessCalcMappingData["T35"] = {
    "A" : 3,
    "B" : 5,
    "C" : 5,
    "D" : 5
};
assessCalcMappingData["T36"] = {
    "A" : 6,
    "B" : 6,
    "C" : 6,
    "D" : 6
};
assessCalcMappingData["T37"] = {
    "A" : 4,
    "B" : 5,
    "C" : 4,
    "D" : 6
};
assessCalcMappingData["T38"] = {
    "A" : 6,
    "B" : 6,
    "C" : 6,
    "D" : 6
};
assessCalcMappingData["T39"] = {
    "A" : 5,
    "B" : 6,
    "C" : 5,
    "D" : 6
};
assessCalcMappingData["T40"] = {
    "A" : 4,
    "B" : 4,
    "C" : 4,
    "D" : 4
};
assessCalcMappingData["T41"] = {
    "A" : 6,
    "B" : 6,
    "C" : 6,
    "D" : 6
};
assessCalcMappingData["T42"] = {
    "A" : 5,
    "B" : 4,
    "C" : 4,
    "D" : 4
};
assessCalcMappingData["T43"] = {
    "A" : 5,
    "B" : 5,
    "C" : 5,
    "D" : 5
};
assessCalcMappingData["T44"] = {
    "A" : 5,
    "B" : 5,
    "C" : 5,
    "D" : 5
};
assessCalcMappingData["T45"] = {
    "A" : 3,
    "B" : 3,
    "C" : 3,
    "D" : 3
};
assessCalcMappingData["T46"] = {
    "A" : 4,
    "B" : 4,
    "C" : 4,
    "D" : 4
};
assessCalcMappingData["T47"] = {
    "A" : 4,
    "B" : 7,
    "C" : 7,
    "D" : 7
};
assessCalcMappingData["T48"] = {
    "A" : 6,
    "B" : 4,
    "C" : 5,
    "D" : 2
};
assessCalcMappingData["T49"] = {
    "A" : 5,
    "B" : 6,
    "C" : 6,
    "D" : 5
};
assessCalcMappingData["T50"] = {
    "A" : 9,
    "B" : 7,
    "C" : 5,
    "D" : 5
};
assessCalcMappingData["T51"] = {
    "A" : 4,
    "B" : 4,
    "C" : 4,
    "D" : 4
};
assessCalcMappingData["T52"] = {
    "A" : 4,
    "B" : 5,
    "C" : 4,
    "D" : 5
};
assessCalcMappingData["T53"] = {
    "A" : 4,
    "B" : 4,
    "C" : 4,
    "D" : 4
};
assessCalcMappingData["T54"] = {
    "A" : 4,
    "B" : 4,
    "C" : 4,
    "D" : 4
};
assessCalcMappingData["T55"] = {
    "A" : 4,
    "B" : 3,
    "C" : 3,
    "D" : 2
};

export default assessCalcMappingData;