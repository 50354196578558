import React from "react";
import { Col, Input, Label, FormGroup} from "reactstrap";
import { getYear, updateYear } from "../../actions/utility";

function UpdateYear() {
  return (
    <>
      <Col md={{ size: 4 }}>
        <FormGroup>
          <Label for="BRANCH">Year</Label>:
          <Input
            type="select"
            name="update_getyear"
            id="update_getyear"
            onChange={(event) => {
              console.log("sel val:", event.target.value);

              updateYear(event.target.value);
              // console.log("ssss", getYear());
            }}
          >
            <option value="0">Select Year</option>
            <option value="2024">2024</option>
          </Input>
        </FormGroup>
      </Col>
    </>
  );
}

export default UpdateYear;
