import React, { Component, useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";


import {
  Button,
  Container,
  Row,
  Col,
  Media,
  Form,
  FormGroup,
  Label,
  Input,
  Spinner,
} from "reactstrap";
import axios from "axios";
import AdminHeader from "./../AdminHeader";
import SideMenu from "./../sidMenu/SideMenu";
import { generateToken, getAdminUserData, getBaseUrl, getYear } from "../../../../../src/actions/utility";
import "react-pro-sidebar/dist/css/styles.css";
import "./../../../../App.css";

function AssessmentTest() {
    const [AssessmentDataArr, setAssessmentDataArr] = useState([]);

    const updateAssessmentSet = (event) => {

var bodyFormData = new FormData();
    bodyFormData.set('setting_val', event.target.value);
    bodyFormData.set('setting_id', '0');
    bodyFormData.set('setting_title', 'assessment_set');

	bodyFormData.set('access_token', '9b57b0a7bc481a375b3dcf990938c572')
	bodyFormData.set('year', getYear());
	axios.post(getBaseUrl() + 'api/assessment/set/update', bodyFormData, {
		headers: {
			'Content-Type': 'multipart/form-data'
		}


	}).then((res) => {

		}).catch((error) => {
			console.log(error);

		});
            console.log("sel val:", event.target.value);
            if (event.target.value == 1) {
              this.subGroups(event.target.value);
            } else if (
              event.target.value == 2 ||
              event.target.value == 3
            ) {
              this.locations(event.target.value);
            }
    }
    useEffect(()=>{
      const token = generateToken();
        axios.get(`${getBaseUrl()}api/assessment/set/get?access_token=9b57b0a7bc481a375b3dcf990938c572`, {
            headers: {
                'Content-Type': 'application/json',
                'authorizedToken': token,
			'appidadmin':localStorage.getItem('app_id_session') // Add your custom token here
            }
        }).then((res) => {
           
           
           
 setAssessmentDataArr(res.data.result)
           console.log('res.data.result: ..........', res.data.result);
            // getSettings();
           
            })
            .catch((error) => {
                console.log(error);
            
            });
    },[])
  return (
    AssessmentDataArr &&
    <div>
    {/*Side menu*/}
    <SideMenu />

    <AdminHeader title={"Assessment Sets"} style={{ marginLeft: 80 }} />
    <Container className="AssessmentStatistics">
      <Col md={{ size: 12 }}>
        <Row style={{ background: "#d5eafb", padding: "15px" }}>
          <Col md={{ size: 12 }}>
            <h5 style={{ fontSize: "18px", fontWeight: "normal" }}>
            Choose an assessment set to assign to all employees. The selected set will be available for them to attempt
            </h5>
          </Col>
        </Row>
      </Col>
      <Row>
        <Col md={{ size: 4 }}>
          <FormGroup>
            <Label for="BRANCH">Select Assessment Set</Label>:
            <Input
              type="select"
              name="main_group"
              id="main_group"
              onChange={updateAssessmentSet}
              disabled={true}
            >
              <option value="0">Select Assessment</option>
              {AssessmentDataArr
              ?
              <>
              {AssessmentDataArr.assessment_set?.map((item, index) => (
                
                  
                  <option value={item?.comp_set} key={index} selected={AssessmentDataArr.settings[0].setting_val === item?.comp_set}>
                    
                 { item?.comp_set}
                </option>
              ))}
               </>
              : null}
            </Input>
          </FormGroup>
        </Col>
      </Row>
    </Container>
  </div>
  )
}

export default AssessmentTest