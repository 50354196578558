import React, { Component } from 'react';
import { ResponsiveBar } from '@nivo/bar';
import { ResponsivePie } from '@nivo/pie';
import { Container, Row, Col, Spinner, FormGroup, Label, Input } from 'reactstrap';
import {
    fetchChartsData, getMainGroupsData,
    fetchDepartments, fetchDepartmentsData,
    fetchDepartmentDivisionData, fetchDepartmentSectionData,
    fetchDivisionChartData, fetchsectionChartData,
    fetchLocationBranchesDD, fetchBranchChartData,
    getAssessBranchDivsHierarchy, fetchBranchDivChartData,
    getAssessBranchSectionHierarchy,
    fetchBranchDivSectionChartData,
    fetchSubGroupsOrLocationsOrSOG,
    getLocationDivsHierarchy,
    getLocationSectionHierarchy,
    fetchLocationChartData,
    fetchLocationDivChartData,
    fetchLocationDivSectionsChartData

} from '../../../../actions/webRedActions';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import SideMenu from '../sidMenu/SideMenu';
import AdminHeader from '../AdminHeader';
import { Chart } from "react-google-charts";
import '../../../../App.css';
import DarkImage from '../../../../night-mode.png';
import LightImage from '../../../../light.png';

class ChartComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [
                { id: 'Pagcor', value: 300 },
                { id: 'Corporate', value: 500 }
            ],
            chart1: [],
            chart2: [],
            chart3: [],
            chart4: [],
            chart5: [],
            chart6: [],
            chart7: [],
            chart8: [],
            chart9: [],
            chart10: [],
            chart11: [],
            chart12: [],
            loadChart1: false,
            loadChart2: false,
            loadChart3: false,
            loadChart4: false,
            loadChart5: false,
            loadChart6: false,
            loadChart7: false,
            loadChart8: false,
            loadChart9: false,
            loadChart10: false,
            loadChart11: false,
            loadChart12: false,
            mainGroupsDataArr: [],
            selectedGroup: 1,
            departsData: [],
            selectedDepartId: "",
            divisionsDDData: [],
            sectionDDData: [],
            selectedDivId: "",
            selectedSectionId: "",
            locationBranchesDD: [],
            selectedBranch: "",
            sogLoader1: false,
            sogChart1: [],
            sogLoader2: false,
            sogChart2: [],
            sogDivisions: [],
            sogSections: [],
            sogLoader3: false,
            sogChart3: [],
            sogLoader4: false,
            sogChart4: [],
            sogLoader5: false,
            sogChart5: [],
            sogLoader6: false,
            sogChart6: [],
            sogDivId: "",
            locations: [],
            cfChart1: [],
            cfChart2: [],
            cfChart3: [],
            cfChart4: [],
            cfChart5: [],
            cfChart6: [],
            cfLoader1: false,
            cfLoader2: false,
            cfLoader3: false,
            cfLoader4: false,
            cfLoader5: false,
            cfLoader6: false,
            selLocation: "",
            locationDivs: [],
            locationSections: [],
            locationDivId: "",
            groupName: "CORPORATE",
            departName: "",
            divisionName: "",
            sectionName: "",
            locationName: "",
            branchName: "",
            isDarkMode: false

        };
        }
      
        toggleTheme = () => {
            this.setState((prevState) => ({
              isDarkMode: !prevState.isDarkMode,
            }));
          };
    componentWillMount() {
        let ths = this;
        this.setState({
            loadChart1: true, loadChart2: true,
            loadChart3: true, loadChart4: true,
            loadChart5: true, loadChart6: true,
            loadChart7: true, loadChart8: true,
            loadChart9: true, loadChart10: true,
            loadChart11: true, loadChart12: true,
            sogLoader1: true, sogLoader2: true,
            sogLoader3: true, sogLoader4: true,
            sogLoader5: true, sogLoader6: true,
            cfLoader1: true, cfLoader2: true,
            cfLoader3: true, cfLoader4: true,
            cfLoader5: true, cfLoader6: true
        })
        fetchLocationBranchesDD((serverRes) => {
            //alert("Faheem here")
            if (serverRes?.status == "success") {
                console.log("this is the server data for SOG branches:", serverRes?.result)
                let branchId = serverRes?.result[0].branch_id
                let branchName = serverRes?.result[0].branch_name
                // alert(branchName)
                this.setState({ branchName: branchName, locationBranchesDD: serverRes?.result, selectedBranch: branchId })
            }
        })
        fetchDepartments((serverRes) => {
            console.log("This is the server data:", serverRes)
            if (serverRes?.status == "success") {
                let deptId = serverRes?.departments[0]?.dept_id;
                let departName = serverRes?.departments[0]?.dept_suffix
                ths.setState({ departName: departName, departsData: serverRes?.departments, selectedDepartId: deptId }, () => {
                    fetchDepartmentsData(this.state.selectedGroup, deptId, (serverRes) => {
                        if (serverRes?.status == "success") {
                            // console.log("Deparment Chart data:", serverRes?.result)

                            let deptPagcor = serverRes?.result["dept-pagcor"]
                            const data1 = [
                                ["Task", "Department popuplation"],
                                [deptPagcor[0]["id"], deptPagcor[0]["value"]],
                                [deptPagcor[1]["id"], deptPagcor[1]["value"]]
                            ];

                            console.log("chart 3:", data1)

                            let deptPagcor1 = serverRes?.result["dept-group"]
                            const data2 = [
                                ["Task", "Department popuplation"],
                                [deptPagcor1[0]["id"], deptPagcor1[0]["value"]],
                                [deptPagcor1[1]["id"], deptPagcor1[1]["value"]]
                            ];
                            console.log("chart 4:", data2)
                            const data3 = [
                                ["Task", "Department popuplation"],
                            ];
                            let deptPagcor2 = serverRes?.result["all-dept"]
                            if (deptPagcor2?.length > 0) {
                                deptPagcor2.map((val, key) => {
                                    console.log("This is the each depart val:", val)
                                    let eachDept = [val.id, val.value];
                                    data3.push(eachDept);
                                })
                            }
                            console.log("This is the 4 chart data:", data3)
                            ths.setState({ chart3: data1, loadChart3: false, chart4: data2, loadChart4: false, chart5: data3, loadChart5: false })

                        }
                        else {
                            alert("Charts data could'nt fetch, try again and latter");
                        }
                        console.log("this is the departs data:")
                    })
                })
                fetchDepartmentDivisionData(ths.state.selectedGroup, deptId, (serverRes) => {
                    console.log("This is the data_:", serverRes?.divisions)
                    if (serverRes?.status == "success") {
                        ths.setState({ divisionsDDData: serverRes?.divisions })
                        let divId = serverRes?.divisions[0].division_id
                        let divName = serverRes?.divisions[0].division_name
                        fetchDivisionChartData(ths.state.selectedGroup, deptId, divId, (serverChartData) => {
                            console.log("This is the server chart data:", serverChartData)
                            if (serverChartData?.status == "success") {
                                let divPagcor = serverChartData?.result["div-group"]
                                const chart1 = [
                                    ["Task", "Division popuplation"],
                                    [divPagcor[0]["id"], divPagcor[0]["value"]],
                                    [divPagcor[1]["id"], divPagcor[1]["value"]]
                                ];

                                let divDpetPagcor = serverChartData?.result["div-dept"]
                                const chart2 = [
                                    ["Task", "Division popuplation"],
                                    [divDpetPagcor[0]["id"], divDpetPagcor[0]["value"]],
                                    [divDpetPagcor[1]["id"], Math.abs(divDpetPagcor[1]["value"])]
                                ];

                                let divAllPagcor = serverChartData?.result["all-div"]
                                const chart3 = [
                                    ["Task", "Division popuplation"],
                                ];

                                if (divAllPagcor?.length > 0) {
                                    divAllPagcor.map((data, index) => {
                                        let eachDivData = [data.id, data.value];
                                        chart3.push(eachDivData);
                                    })
                                }
                                ths.setState({
                                    chart6: chart1, chart7: chart2, chart8: chart3,
                                    loadChart6: false, loadChart7: false,
                                    loadChart8: false, selectedDivId: divId,
                                    divisionName: divName
                                })


                            }
                        })
                        fetchDepartmentSectionData(ths.state.selectedGroup, deptId, divId, (serverRes) => {
                            console.log("This is the data__:", serverRes?.sections)
                            if (serverRes?.status == "success") {
                                let sectionId = serverRes?.sections[0].section_id
                                let sectionName = serverRes?.sections[0].section_name
                                fetchsectionChartData(ths.state.selectedGroup, deptId, divId, sectionId, (serverChartData) => {
                                    if (serverChartData?.status == "success") {
                                        let sectionPagcor = serverChartData?.result["sec-group"]
                                        const chart1 = [
                                            ["Task", "Section popuplation"],
                                            [sectionPagcor[0]["id"], sectionPagcor[0]["value"]],
                                            [sectionPagcor[1]["id"], sectionPagcor[1]["value"]]
                                        ];

                                        let secDpetPagcor = serverChartData?.result["sec-dept"]
                                        const chart2 = [
                                            ["Task", "Section popuplation"],
                                            [secDpetPagcor[0]["id"], secDpetPagcor[0]["value"]],
                                            [secDpetPagcor[1]["id"], Math.abs(secDpetPagcor[1]["value"])]
                                        ];

                                        let secDivPagcor = serverChartData?.result["sec-div"]
                                        const chart3 = [
                                            ["Task", "Section popuplation"],
                                            [secDivPagcor[0]["id"], secDivPagcor[0]["value"]],
                                            [secDivPagcor[1]["id"], Math.abs(secDivPagcor[1]["value"])]
                                        ];

                                        let secAllPagcor = serverChartData?.result["all-sec"]
                                        const chart4 = [
                                            ["Task", "Section popuplation"],
                                        ];

                                        if (secAllPagcor?.length > 0) {
                                            secAllPagcor.map((data, index) => {
                                                let eachDivData = [data.id, data.value];
                                                chart4.push(eachDivData);
                                            })
                                        }
                                        ths.setState({
                                            chart9: chart1, chart10: chart2, chart11: chart3, chart12: chart4,
                                            loadChart9: false, loadChart10: false, loadChart11: false, loadChart12: false,
                                            selectedSectionId: sectionId, sectionName: sectionName
                                        })
                                    }
                                })
                                ths.setState({ sectionDDData: serverRes?.sections })
                            }
                        })
                    }
                })
            }
        })
        this.props.getMainGroupsData("");
        this.props.fetchSubGroupsOrLocationsOrSOG(2, (locations) => {
            console.log("This is the locations:", locations.result)
            if (locations?.status == "success" && locations?.result) {
                //selLocation
                let location = locations?.result[0].location_id;
                let locationName = locations?.result[0].location_name;
                // alert("This is my location name:"+locationName)
                this.setState({ locations: locations?.result, selLocation: location, locationName: locationName })
            }
        })
    }
    componentDidMount() {
        let ths = this;

        fetchChartsData(this.state.selectedGroup, (server) => {
            console.log("What server has sent:", server)

            if (server?.status == "success") {
                const chart1 = [
                    ["Task", "Total Population"],
                    [server.result["group-pagcor"][0]["id"], server.result["group-pagcor"][0]["value"]],
                    [server.result["group-pagcor"][1]["id"], server.result["group-pagcor"][1]["value"]]
                ];
                console.log("This is the chart val:", chart1)
                let secondChart = server?.result["all-groups"]
                const chart2 = [
                    ["Task", "Groups Population"],
                    [secondChart[0]["id"], secondChart[0]["value"]],
                    [secondChart[1]["id"], secondChart[1]["value"]],
                    [secondChart[2]["id"], secondChart[2]["value"]]
                ];
                ths.setState({ chart1: chart1, chart2: chart2, loadChart1: false, loadChart2: false })
            }
            else
                alert("Charts data could'nt fetch, try again and latter");
        })


    }
    componentWillReceiveProps(nextProps, thisProps) {
        if (this.props.mainGroupsData != nextProps.mainGroupsData) {
            this.setState({ mainGroupsDataArr: nextProps.mainGroupsData.data }, () => {
                console.log('set state works here', this.state.mainGroupsDataArr);
            })
        }
    }
    render() {
        const { loadChart1, loadChart2,
            chart1, chart2, mainGroupsDataArr,
            selectedGroup, loadChart3, loadChart4, loadChart5,
            loadChart6, loadChart7, loadChart8, loadChart9,
            loadChart10, loadChart11, loadChart12,
            chart3, chart4, chart5, chart6, chart7, chart8,
            chart9, chart10, chart11, chart12,
            departsData, divisionsDDData, sectionDDData,
            locationBranchesDD, sogChart1, sogChart2, sogLoader1, sogLoader2,
            sogChart3, sogChart4, sogChart5, sogChart6, sogLoader3, sogLoader4, sogLoader5,
            sogLoader6, sogDivisions, sogSections, locations,
            cfLoader1, cfLoader2, cfLoader3, cfLoader4, cfLoader5, cfLoader6,
            cfChart1, cfChart2, cfChart3, cfChart4, cfChart5, cfChart6,
            locationDivs, locationSections, groupName, departName, divisionName,
            sectionName, locationName, branchName
        } = this.state;
        const { isDarkMode } = this.state;
        return (
            <div>
                <SideMenu />
                <AdminHeader title={"Admin Charts"} style={{ marginLeft: 80 }} />
                <Container fluid style={{paddingLeft:'6.3rem'}} className={isDarkMode ? 'mainDarkContainer' : 'mainLightContainer'}>
                    <div className="mb-4 headerDivStyle" style={{ width: "90%", marginLeft: 20 }} >
                        <FormGroup style={{ marginLeft: '5px', marginRight: '20px', marginBottom: '0' }}>
                        <Label for="BRANCH"  style={{ color:isDarkMode?'#ffffff':'black'}}>Main Group</Label>:
                            <Input type="select" name="main_group" id="main_group" onChange={(event) => {
                                let dynId = "group_name" + event.target.value;
                                let groupName = document.getElementById(dynId).getAttribute("group_name")
                                // alert(groupName)
                                console.log(event.target.group_name, "sel val:", event.target.value)
                                let ths = this;
                                this.setState({ groupName: groupName, loadChart1: true, selectedGroup: event.target.value }, () => {
                                    if (this.state.selectedGroup == 3) {
                                        this.setState({ sogLoader1: true, sogLoader2: true }, () => {
                                            this.props.getAssessBranchDivsHierarchy(4, this.state.selectedBranch, (divsData) => {
                                                console.log("This is the branch divisions:", divsData)
                                                if (divsData?.status == "success" && divsData?.result?.length) {

                                                    let divisionId = divsData?.result[0]["division_id"];
                                                    let divisionName = divsData?.result[0]["division_name"];
                                                    this.setState({ divisionName: divisionName, sogDivisions: divsData?.result, sogDivId: divisionId }, () => {
                                                        fetchBranchDivChartData(this.state.selectedGroup, this.state.selectedBranch, divisionId, (divCharts) => {
                                                            console.log("this is sog div data:", divCharts)
                                                            if (divCharts?.result) {
                                                                let sogDiv = divCharts?.result["sog_div-sog_branch"]
                                                                const chart3 = [
                                                                    ["Task", "SOG popuplation"],
                                                                    [sogDiv[0]["id"], sogDiv[0]["value"]],
                                                                    [sogDiv[1]["id"], sogDiv[1]["value"]]
                                                                ];
                                                                this.setState({ sogChart3: chart3, sogLoader3: false })
                                                                this.props.getAssessBranchSectionHierarchy(4, this.state.selectedBranch, divisionId, (sectionRes) => {
                                                                    console.log("This is the section data:", sectionRes?.result)
                                                                    let sectionId = sectionRes?.result[0]["section_id"]
                                                                    let sectionName = sectionRes?.result[0]["section_name"]
                                                                    if (sectionRes?.result) {
                                                                        this.setState({ sogSections: sectionRes?.result }, () => {
                                                                            fetchBranchDivSectionChartData(this.state.selectedGroup, this.state.selectedBranch, divisionId, sectionId, (sectionChartData) => {
                                                                                console.log("This is the section chart data:", sectionChartData)
                                                                                if (sectionChartData?.result) {
                                                                                    let sectionBranch = sectionChartData.result["sog_sec-sog_branch"]
                                                                                    let sectionDiv = sectionChartData.result["sog_sec-sog_div"]
                                                                                    let allSections = sectionChartData.result["all-sogsec"]
                                                                                    const chart4 = [
                                                                                        ["Task", "SOG popuplation"],
                                                                                        [sectionBranch[0]["id"], sectionBranch[0]["value"]],
                                                                                        [sectionBranch[1]["id"], sectionBranch[1]["value"]]
                                                                                    ];
                                                                                    const chart5 = [
                                                                                        ["Task", "SOG popuplation"],
                                                                                        [sectionDiv[0]["id"], sectionDiv[0]["value"]],
                                                                                        [sectionDiv[1]["id"], sectionDiv[1]["value"]]
                                                                                    ];
                                                                                    const chart6 = [
                                                                                        ["Task", "SOG popuplation"],
                                                                                        [allSections[0]["id"], allSections[0]["value"]],
                                                                                        [allSections[1]["id"], allSections[1]["value"]]
                                                                                    ];
                                                                                    this.setState({ sectionName: sectionName, sogChart4: chart4, sogChart5: chart5, sogChart6: chart6, sogLoader4: false, sogLoader5: false, sogLoader6: false })
                                                                                }
                                                                            })
                                                                        })
                                                                    }
                                                                })
                                                            }
                                                        })
                                                    })
                                                }
                                            })
                                        })
                                        fetchBranchChartData(this.state.selectedGroup, this.state.selectedBranch, (branchCharts) => {

                                            if (branchCharts?.status == "success") {
                                                let allSog = branchCharts?.result["all-sog_branch"]
                                                let sogPagcor = branchCharts?.result["sog_branch-pagcor"]
                                                console.log("sogPagcor******", sogPagcor)
                                                const chart1 = [
                                                    ["Task", "SOG popuplation"],
                                                    [sogPagcor[0]["id"], sogPagcor[0]["value"]],
                                                    [sogPagcor[1]["id"], sogPagcor[1]["value"]]
                                                ];

                                                const chart2 = [
                                                    ["Task", "SOG popuplation"],
                                                ];

                                                if (allSog?.length > 0) {
                                                    allSog.map((data, index) => {
                                                        let eachsogData = [data.id, data.value];
                                                        chart2.push(eachsogData);
                                                    })
                                                }
                                                console.log("This is the branch chart data:", branchCharts?.result)
                                                this.setState({ sogLoader1: false, sogLoader2: false, sogChart1: chart1, sogChart2: chart2 })
                                            }
                                        })
                                    }
                                    if (this.state.selectedGroup == 2) {
                                        //selLocation
                                        this.props.getLocationDivsHierarchy(this.state.selLocation, (locDiv) => {
                                            console.clear();
                                            console.log("This is my location divs:", locDiv);
                                            if (locDiv?.status == "success") {
                                                //locationDivs
                                                const { selLocation } = this.state;
                                                let divId = locDiv?.result[0].division_id;
                                                let divName = locDiv?.result[0].division_name;
                                                fetchLocationDivChartData(this.state.selectedGroup, this.state.selLocation, divId, (locationDivChart) => {
                                                    console.log("This is the location div chart:", locationDivChart)
                                                    //cf_div-cf_location
                                                    if (locationDivChart?.status == "success") {
                                                        let cfDivLocation = locationDivChart?.result["cf_div-cf_location"];
                                                        const chart3 = [
                                                            ["Task", "CF popuplation"],
                                                            [cfDivLocation[0]["id"], cfDivLocation[0]["value"]],
                                                            [cfDivLocation[1]["id"], cfDivLocation[1]["value"]]
                                                        ];
                                                        this.setState({ cfChart3: chart3, cfLoader3: false })
                                                    }
                                                })

                                                this.setState({ divisionName: divName, locationDivs: locDiv?.result, locationDivId: divId }, () => {
                                                    this.props.getLocationSectionHierarchy(selLocation, divId, (locationSecs) => {
                                                        // console.clear();
                                                        console.log("This is my location sections:", locationSecs);
                                                        if (locationSecs?.status == "success") {
                                                            let sectionId = locationSecs?.result[0].section_id;
                                                            let sectionName = locationSecs?.result[0].section_name;

                                                            this.setState({ sectionName: sectionName, locationSections: locationSecs?.result }, () => {
                                                                //call the chart data here
                                                                fetchLocationDivSectionsChartData(this.state.selectedGroup, this.state.selLocation, divId, sectionId, (locationSectionData) => {
                                                                    console.log("*****", locationSectionData)
                                                                    if (locationSectionData?.status == "success") {
                                                                        let allCfSec = locationSectionData?.result["all-cfsec"];
                                                                        let cfSecLocation = locationSectionData?.result["cf_sec-cf_location"];
                                                                        let cfSecDiv = locationSectionData?.result["cf_sec-cf_div"];

                                                                        const chart4 = [
                                                                            ["Task", "CF popuplation"],
                                                                            [cfSecLocation[0]["id"], cfSecLocation[0]["value"]],
                                                                            [cfSecLocation[1]["id"], cfSecLocation[1]["value"]]
                                                                        ];

                                                                        const chart5 = [
                                                                            ["Task", "CF popuplation"],
                                                                            [cfSecDiv[0]["id"], cfSecDiv[0]["value"]],
                                                                            [cfSecDiv[1]["id"], cfSecDiv[1]["value"]]
                                                                        ];
                                                                        const chart6 = [
                                                                            ["Task", "CF popuplation"],
                                                                        ];
                                                                        if (allCfSec?.length) {
                                                                            allCfSec.map((sec, indx) => {
                                                                                chart6.push([sec.id, sec.value])
                                                                            })
                                                                        }
                                                                        this.setState({ cfChart4: chart4, cfChart5: chart5, cfChart6: chart6, cfLoader4: false, cfLoader5: false, cfLoader6: false })
                                                                        console.log(allCfSec, "===", cfSecLocation, "===", cfSecDiv)
                                                                    }
                                                                })
                                                            })

                                                        }
                                                    })
                                                })
                                            }
                                        })
                                        fetchLocationChartData(this.state.selectedGroup, this.state.selLocation, (locationChartData) => {
                                            //console.clear();
                                            console.log("This is my location chart data:", locationChartData);
                                            //cf_location-pagcor
                                            if (locationChartData?.status == "success") {
                                                let cfLocationPagcor = locationChartData.result["cf_location-pagcor"];
                                                let allcfLocationPagcor = locationChartData.result["all-cf_location"];
                                                console.log(cfLocationPagcor, '---', allcfLocationPagcor)

                                                const chart1 = [
                                                    ["Task", "CF popuplation"],
                                                    [cfLocationPagcor[0]["id"], cfLocationPagcor[0]["value"]],
                                                    [cfLocationPagcor[1]["id"], cfLocationPagcor[1]["value"]]
                                                ];
                                                const chart2 = [
                                                    ["Task", "CF popuplation"],
                                                ]
                                                if (allcfLocationPagcor?.length) {

                                                    allcfLocationPagcor.map((item, indx) => {
                                                        console.log(item, "***", indx)
                                                        let eachData = [item.id, item.value];
                                                        chart2.push(eachData)
                                                    })
                                                    console.log("final chart 2 of the cf:", chart2)
                                                }
                                                this.setState({ cfChart1: chart1, cfChart2: chart2, cfLoader1: false, cfLoader2: false })
                                            }
                                        })
                                    }
                                    fetchChartsData(this.state.selectedGroup, (server) => {
                                        console.log("What server has sent:", server)
                                        if (server?.status == "success") {
                                            const chart1 = [
                                                ["Task", "Total Population"],
                                                [server.result["group-pagcor"][0]["id"], server.result["group-pagcor"][0]["value"]],
                                                [server.result["group-pagcor"][1]["id"], server.result["group-pagcor"][1]["value"]]
                                            ];
                                            let secondChart = server?.result["all-groups"]
                                            ths.setState({ chart1: chart1, chart2: secondChart, loadChart1: false })
                                        }
                                        else
                                            alert("Charts data could'nt fetch, try again and latter");
                                    })

                                })

                            }}>
                                {
                                    mainGroupsDataArr.length > 0 ?
                                        mainGroupsDataArr.map((group, index) =>
                                            <option value={group.group_id} selected={selectedGroup == group.group_id} id={"group_name" + group.group_id} group_name={group.group_name}>{group.group_name}</option>
                                        )
                                        : null
                                }
                            </Input>
                        </FormGroup>
                        <button onClick={this.toggleTheme} className='btnDarkLight' style={{ border: `1px solid ${isDarkMode ? 'white' : 'black'}`}}>  <img src={isDarkMode?LightImage:DarkImage} alt='Mode Image' style={{width:'30px', cursor:'pointer'}} /> </button>
                      
                    </div>
                    <Row style={{ padding: '3vh 0' }}>

                        <Col md={{ size: 6 }}>

                            {
                                loadChart1 == false ?
                                    <Chart
                                        chartType="PieChart"
                                        data={chart1}
                                        options={{
                                            title: groupName + " vs. PAGCOR Population",
                                            backgroundColor: isDarkMode? '#1b293e' : '#dee5ed',
                                            
                                            legendTextStyle: { color:isDarkMode? '#FFF':'black' },
                                            titleTextStyle: { color:isDarkMode? '#FFF':'black' },
                                            hAxis: {
                                            color:isDarkMode? '#FFF':'black' ,
                                            }
                                        }}
                                        width={"100%"}
                                        height={"400px"}
                                    />
                                    : <Spinner className="mt-5" style={{ marginRight: "400px" }} size="sm" color="dark" />
                            }
                        </Col>
                        <Col md={{ size: 6 }}>
                            {
                                loadChart2 == false ?
                                    <Chart
                                        chartType="PieChart"
                                        data={chart2}
                                        options={{
                                            title: "All Groups Population",
                                            backgroundColor: isDarkMode? '#1b293e' : '#dee5ed',
                                            
                                            legendTextStyle: { color:isDarkMode? '#FFF':'black' },
                                            titleTextStyle: { color:isDarkMode? '#FFF':'black' },
                                            hAxis: {
                                            color:isDarkMode? '#FFF':'black' ,
                                            }
                                        }}
                                        width={"100%"}
                                        height={"400px"}
                                    />
                                    : <Spinner className="mt-5" style={{ marginRight: "400px" }} size="sm" color="dark" />
                            }
                        </Col>
                    </Row>
                    {
                        this.state.selectedGroup == 1 ?
                            <>
                                <div className="mb-4" style={{ width: "32%", marginLeft: 20 }}>
                                    <FormGroup style={{ marginLeft: '5px', marginRight: '20px', marginBottom: '0' }}>
                                        <Label for="BRANCH" style={{ color:isDarkMode?'#ffffff':'black'}}>Departments</Label>:
                                        <Input type="select" name="main_group" id="main_group" onChange={(event) => {
                                            console.log("sel val:", event.target.value)
                                            let ths = this;
                                            let deptId = event.target.value;
                                            let deptVal = document.getElementById("depart"+deptId).getAttribute("dept_name");
                                            ths.setState({ departName:deptVal,loadChart3: true, loadChart4: true, loadChart5: true, selectedDepartId: deptId }, () => {
                                                fetchDepartmentsData(this.state.selectedGroup, deptId, (serverRes) => {
                                                    if (serverRes?.status == "success") {
                                                        // console.log("Deparment Chart data:", serverRes?.result)

                                                        let deptPagcor = serverRes?.result["dept-pagcor"]
                                                        const data1 = [
                                                            ["Task", "Department popuplation"],
                                                            [deptPagcor[0]["id"], deptPagcor[0]["value"]],
                                                            [deptPagcor[1]["id"], deptPagcor[1]["value"]]
                                                        ];

                                                        console.log("Departments charts .on changes:", data1)

                                                        let deptPagcor1 = serverRes?.result["dept-group"]
                                                        const data2 = [
                                                            ["Task", "Department popuplation"],
                                                            [deptPagcor1[0]["id"], deptPagcor1[0]["value"]],
                                                            [deptPagcor1[1]["id"], deptPagcor1[1]["value"]]
                                                        ];
                                                        console.log("chart 4:6666", data2[0])
                                                        const data3 = [
                                                            ["Task", "Department popuplation"],
                                                        ];
                                                        let deptPagcor2 = serverRes?.result["all-dept"]
                                                        if (deptPagcor2?.length > 0) {
                                                            deptPagcor2.map((val, key) => {
                                                                console.log("This is the each depart val:", val)
                                                                let eachDept = [val.id, val.value];
                                                                data3.push(eachDept);
                                                            })
                                                        }
                                                        console.log("This is the 4 chart data:", data3)
                                                        ths.setState({
                                                            chart3: data1, loadChart3: false, chart4: data2,
                                                            loadChart4: false, chart5: data3, loadChart5: false,
                                                            selectedDepartId: deptId, loadChart6: true, loadChart7: true,
                                                            loadChart8: true, loadChart9: true, loadChart10: true,
                                                            loadChart11: true, loadChart12: true
                                                        }, () => {
                                                            fetchDepartmentDivisionData(ths.state.selectedGroup, deptId, (serverRes) => {
                                                                console.log("This is the data_:", serverRes?.divisions)
                                                                if (serverRes?.status == "success") {
                                                                   
                                                                    let divId = serverRes?.divisions[0].division_id
                                                                    
                                                                    let divName = serverRes?.divisions[0].division_name
                                                                    
                                                                    ths.setState({ divisionsDDData: serverRes?.divisions,divisionName:divName })
                                                                    fetchDivisionChartData(ths.state.selectedGroup, deptId, divId, (serverChartData) => {
                                                                        console.log("This is the server chart data:", serverChartData)
                                                                        if (serverChartData?.status == "success") {
                                                                            let divPagcor = serverChartData?.result["div-group"]
                                                                            const chart1 = [
                                                                                ["Task", "Division popuplation"],
                                                                                [divPagcor[0]["id"], divPagcor[0]["value"]],
                                                                                [divPagcor[1]["id"], divPagcor[1]["value"]]
                                                                            ];

                                                                            let divDpetPagcor = serverChartData?.result["div-dept"]
                                                                            const chart2 = [
                                                                                ["Task", "Division popuplation"],
                                                                                [divDpetPagcor[0]["id"], divDpetPagcor[0]["value"]],
                                                                                [divDpetPagcor[1]["id"], Math.abs(divDpetPagcor[1]["value"])]
                                                                            ];

                                                                            let divAllPagcor = serverChartData?.result["all-div"]
                                                                            const chart3 = [
                                                                                ["Task", "Division popuplation"],
                                                                            ];

                                                                            if (divAllPagcor?.length > 0) {
                                                                                divAllPagcor.map((data, index) => {
                                                                                    let eachDivData = [data.id, data.value];
                                                                                    chart3.push(eachDivData);
                                                                                })
                                                                            }
                                                                            ths.setState({
                                                                                chart6: chart1, chart7: chart2, chart8: chart3,
                                                                                loadChart6: false, loadChart7: false, loadChart8: false, selectedDivId: divId
                                                                            })


                                                                        }
                                                                    })
                                                                    fetchDepartmentSectionData(ths.state.selectedGroup, deptId, divId, (serverRes) => {
                                                                        console.log("This is the data__:", serverRes?.sections)
                                                                        if (serverRes?.status == "success") {
                                                                            let sectionId = serverRes?.sections[0].section_id
                                                                            let sectionName = serverRes?.sections[0].section_name
                                                                            fetchsectionChartData(ths.state.selectedGroup, deptId, divId, sectionId, (serverChartData) => {
                                                                                if (serverChartData?.status == "success") {
                                                                                    let sectionPagcor = serverChartData?.result["sec-group"]
                                                                                    const chart1 = [
                                                                                        ["Task", "Section popuplation"],
                                                                                        [sectionPagcor[0]["id"], sectionPagcor[0]["value"]],
                                                                                        [sectionPagcor[1]["id"], sectionPagcor[1]["value"]]
                                                                                    ];

                                                                                    let secDpetPagcor = serverChartData?.result["sec-dept"]
                                                                                    const chart2 = [
                                                                                        ["Task", "Section popuplation"],
                                                                                        [secDpetPagcor[0]["id"], secDpetPagcor[0]["value"]],
                                                                                        [secDpetPagcor[1]["id"], Math.abs(secDpetPagcor[1]["value"])]
                                                                                    ];

                                                                                    let secDivPagcor = serverChartData?.result["sec-div"]
                                                                                    const chart3 = [
                                                                                        ["Task", "Section popuplation"],
                                                                                        [secDivPagcor[0]["id"], secDivPagcor[0]["value"]],
                                                                                        [secDivPagcor[1]["id"], Math.abs(secDivPagcor[1]["value"])]
                                                                                    ];

                                                                                    let secAllPagcor = serverChartData?.result["all-sec"]
                                                                                    const chart4 = [
                                                                                        ["Task", "Section popuplation"],
                                                                                    ];

                                                                                    if (secAllPagcor?.length > 0) {
                                                                                        secAllPagcor.map((data, index) => {
                                                                                            let eachDivData = [data.id, data.value];
                                                                                            chart4.push(eachDivData);
                                                                                        })
                                                                                    }
                                                                                    ths.setState({
                                                                                        chart9: chart1, chart10: chart2, chart11: chart3, chart12: chart4,
                                                                                        loadChart9: false, loadChart10: false, loadChart11: false, loadChart12: false,
                                                                                        selectedSectionId: sectionId
                                                                                    })
                                                                                }
                                                                            })
                                                                            ths.setState({ sectionDDData: serverRes?.sections,sectionName:sectionName })
                                                                        }
                                                                    })
                                                                }
                                                            })
                                                        })

                                                    }
                                                    else {
                                                        alert("Charts data could'nt fetch, try again and latter");
                                                    }
                                                    console.log("this is the departs data:")
                                                })
                                            })

                                        }}>
                                            {
                                                departsData.length > 0 ?
                                                    departsData.map((departs, index) =>
                                                        <option value={departs.dept_id} selected={selectedGroup == departs.dept_id} id={"depart" + departs.dept_id} dept_name={departs.dept_suffix}>{departs.dept_suffix}</option>
                                                    )
                                                    : null
                                            }
                                        </Input>
                                    </FormGroup>
                                </div>
                                <Row style={{ padding: '3vh 0' }}>

                                    <Col md={{ size: 4 }}>



                                        {
                                            loadChart3 == false ?
                                                <Chart
                                                    chartType="PieChart"
                                                    data={chart3}
                                                    options={{
                                                        title: departName + " vs. total PAGCOR population",
                                                        backgroundColor: isDarkMode? '#1b293e' : '#dee5ed',
                                            
                                                        legendTextStyle: { color:isDarkMode? '#FFF':'black' },
                                                        titleTextStyle: { color:isDarkMode? '#FFF':'black' },
                                                        hAxis: {
                                                        color:isDarkMode? '#FFF':'black' ,
                                                        }
                                                    }}
                                                    width={"100%"}
                                                    height={"400px"}
                                                />
                                                : <Spinner className="mt-5" style={{ marginRight: "400px" }} size="sm" color="dark" />
                                        }
                                    </Col>
                                    <Col md={{ size: 4 }}>
                                        {
                                            loadChart4 == false ?
                                                <Chart
                                                    chartType="PieChart"
                                                    data={chart4}
                                                    options={{
                                                        title: departName + " vs. total " + groupName + " population",
                                                        backgroundColor: isDarkMode? '#1b293e' : '#dee5ed',
                                            
                                                        legendTextStyle: { color:isDarkMode? '#FFF':'black' },
                                                        titleTextStyle: { color:isDarkMode? '#FFF':'black' },
                                                        hAxis: {
                                                        color:isDarkMode? '#FFF':'black' ,
                                                        }
                                                    }}
                                                    width={"100%"}
                                                    height={"400px"}
                                                />
                                                : <Spinner className="mt-5" style={{ marginRight: "400px" }} size="sm" color="dark" />
                                        }
                                    </Col>
                                    <Col md={{ size: 4 }}>
                                        {
                                            loadChart5 == false ?
                                                <Chart
                                                    chartType="PieChart"
                                                    data={chart5}
                                                    options={{
                                                        title: "All Department Population",
                                                        backgroundColor: isDarkMode? '#1b293e' : '#dee5ed',
                                            
                                                        legendTextStyle: { color:isDarkMode? '#FFF':'black' },
                                                        titleTextStyle: { color:isDarkMode? '#FFF':'black' },
                                                        hAxis: {
                                                        color:isDarkMode? '#FFF':'black' ,
                                                        }
                                                    }}
                                                    width={"100%"}
                                                    height={"400px"}
                                                />
                                                : <Spinner className="mt-5" style={{ marginRight: "400px" }} size="sm" color="dark" />
                                        }
                                    </Col>
                                </Row>
                                <div className="mb-4" style={{ width: "32%", marginLeft: 20 }}>
                                    <FormGroup style={{ marginLeft: '5px', marginRight: '20px', marginBottom: '0' }}>
                                        <Label for="BRANCH" style={{ color:isDarkMode?'#ffffff':'black'}}>Divisions</Label>:
                                        <Input type="select" name="main_group" id="main_group" onChange={(event) => {
                                            console.log("sel val:", event.target.value)
                                            let ths = this;
                                            let divId = event.target.value;
                                            let dynId = "div" + divId;
                                            let divisionName = document.getElementById(dynId).getAttribute("division_name")
                                            // alert(divisionName)
                                            this.setState({
                                                loadChart6: true, loadChart7: true, loadChart8: true, selectedDivId: divId,
                                                loadChart9: true, loadChart10: true, loadChart11: true, loadChart12: true,
                                                divisionName: divisionName
                                            }, () => {
                                                fetchDivisionChartData(ths.state.selectedGroup, ths.state.selectedDepartId, divId, (serverChartData) => {
                                                    console.log("This is the server chart data:", serverChartData)
                                                    if (serverChartData?.status == "success") {
                                                        let divPagcor = serverChartData?.result["div-group"]
                                                        const chart1 = [
                                                            ["Task", "Division popuplation"],
                                                            [divPagcor[0]["id"], divPagcor[0]["value"]],
                                                            [divPagcor[1]["id"], divPagcor[1]["value"]]
                                                        ];

                                                        let divDpetPagcor = serverChartData?.result["div-dept"]
                                                        const chart2 = [
                                                            ["Task", "Division popuplation"],
                                                            [divDpetPagcor[0]["id"], divDpetPagcor[0]["value"]],
                                                            [divDpetPagcor[1]["id"], Math.abs(divDpetPagcor[1]["value"])]
                                                        ];

                                                        let divAllPagcor = serverChartData?.result["all-div"]
                                                        const chart3 = [
                                                            ["Task", "Division popuplation"],
                                                        ];

                                                        if (divAllPagcor?.length > 0) {
                                                            divAllPagcor.map((data, index) => {
                                                                let eachDivData = [data.id, data.value];
                                                                chart3.push(eachDivData);
                                                            })
                                                        }
                                                        ths.setState({
                                                            chart6: chart1, chart7: chart2, chart8: chart3,
                                                            loadChart6: false, loadChart7: false, loadChart8: false, selectedDivId: divId
                                                        })


                                                    }
                                                })
                                                fetchDepartmentSectionData(ths.state.selectedGroup, ths.state.selectedDepartId, divId, (serverRes) => {
                                                    console.log("This is the data__:", serverRes?.sections)
                                                    if (serverRes?.status == "success") {
                                                        let sectionId = serverRes?.sections[0].section_id
                                                        let sectionName = serverRes?.sections[0].section_name
                                                        fetchsectionChartData(ths.state.selectedGroup, ths.state.selectedDepartId, divId, sectionId, (serverChartData) => {
                                                            if (serverChartData?.status == "success") {
                                                                let sectionPagcor = serverChartData?.result["sec-group"]
                                                                const chart1 = [
                                                                    ["Task", "Section popuplation"],
                                                                    [sectionPagcor[0]["id"], sectionPagcor[0]["value"]],
                                                                    [sectionPagcor[1]["id"], sectionPagcor[1]["value"]]
                                                                ];

                                                                let secDpetPagcor = serverChartData?.result["sec-dept"]
                                                                const chart2 = [
                                                                    ["Task", "Section popuplation"],
                                                                    [secDpetPagcor[0]["id"], secDpetPagcor[0]["value"]],
                                                                    [secDpetPagcor[1]["id"], Math.abs(secDpetPagcor[1]["value"])]
                                                                ];

                                                                let secDivPagcor = serverChartData?.result["sec-div"]
                                                                const chart3 = [
                                                                    ["Task", "Section popuplation"],
                                                                    [secDivPagcor[0]["id"], secDivPagcor[0]["value"]],
                                                                    [secDivPagcor[1]["id"], Math.abs(secDivPagcor[1]["value"])]
                                                                ];

                                                                let secAllPagcor = serverChartData?.result["all-sec"]
                                                                const chart4 = [
                                                                    ["Task", "Section popuplation"],
                                                                ];

                                                                if (secAllPagcor?.length > 0) {
                                                                    secAllPagcor.map((data, index) => {
                                                                        let eachDivData = [data.id, data.value];
                                                                        chart4.push(eachDivData);
                                                                    })
                                                                }
                                                                ths.setState({
                                                                    chart9: chart1, chart10: chart2, chart11: chart3, chart12: chart4,
                                                                    loadChart9: false, loadChart10: false, loadChart11: false, loadChart12: false,
                                                                    selectedSectionId: sectionId
                                                                })
                                                            }
                                                        })
                                                        ths.setState({ sectionDDData: serverRes?.sections,sectionName:sectionName })
                                                    }
                                                })
                                            })

                                        }}>
                                            {
                                                divisionsDDData.length > 0 ?
                                                    divisionsDDData.map((divs, index) =>
                                                        <option id={"div" + divs.division_id} value={divs.division_id} selected={divs.division_name === divisionName } division_name={divs.division_name}>{divs.division_name}</option>
                                                    )
                                                    : null
                                            }
                                        </Input>
                                    </FormGroup>
                                </div>
                                <Row style={{ padding: '3vh 0' }}>

                                    <Col md={{ size: 4 }}>



                                        {
                                            loadChart6 == false ?
                                                <Chart
                                                    chartType="PieChart"
                                                    data={chart6}
                                                    options={{
                                                        title: divisionName + " vs. total " + groupName + " Population",
                                                        backgroundColor: isDarkMode? '#1b293e' : '#dee5ed',
                                            
                                                        legendTextStyle: { color:isDarkMode? '#FFF':'black' },
                                                        titleTextStyle: { color:isDarkMode? '#FFF':'black' },
                                                        hAxis: {
                                                        color:isDarkMode? '#FFF':'black' ,
                                                        }
                                                    }}
                                                    width={"100%"}
                                                    height={"400px"}
                                                />
                                                : <Spinner className="mt-5" style={{ marginRight: "400px" }} size="sm" color="dark" />
                                        }
                                    </Col>
                                    <Col md={{ size: 4 }}>
                                        {
                                            loadChart7 == false ?
                                                <Chart
                                                    chartType="PieChart"
                                                    data={chart7}
                                                    options={{
                                                        title: divisionName + " vs. total " + departName + " Population",
                                                        backgroundColor: isDarkMode? '#1b293e' : '#dee5ed',
                                            
                                                        legendTextStyle: { color:isDarkMode? '#FFF':'black' },
                                                        titleTextStyle: { color:isDarkMode? '#FFF':'black' },
                                                        hAxis: {
                                                        color:isDarkMode? '#FFF':'black' ,
                                                        }
                                                    }}
                                                    width={"100%"}
                                                    height={"400px"}
                                                />
                                                : <Spinner className="mt-5" style={{ marginRight: "400px" }} size="sm" color="dark" />
                                        }
                                    </Col>
                                    <Col md={{ size: 4 }}>
                                        {
                                            loadChart8 == false ?
                                                <Chart
                                                    chartType="PieChart"
                                                    data={chart8}
                                                    options={{
                                                        title: "All Divisions Populations",
                                                        backgroundColor: isDarkMode? '#1b293e' : '#dee5ed',
                                            
                                                        legendTextStyle: { color:isDarkMode? '#FFF':'black' },
                                                        titleTextStyle: { color:isDarkMode? '#FFF':'black' },
                                                        hAxis: {
                                                        color:isDarkMode? '#FFF':'black' ,
                                                        }
                                                    }}
                                                    width={"100%"}
                                                    height={"400px"}
                                                />
                                                : <Spinner className="mt-5" style={{ marginRight: "400px" }} size="sm" color="dark" />
                                        }
                                    </Col>
                                </Row>
                                <div className="mb-4" style={{ width: "32%", marginLeft: 20 }}>
                                    <FormGroup style={{ marginLeft: '5px', marginRight: '20px', marginBottom: '0' }}>
                                        <Label for="BRANCH" style={{ color:isDarkMode?'#ffffff':'black'}}>Sections</Label>:
                                        <Input type="select" name="main_group" id="main_group" onChange={(event) => {
                                            console.log("sel val:", event.target.value)
                                            let ths = this;
                                            let sectionId = event.target.value;
                                            let dynId = "section" + sectionId;
                                            let sectionName = document.getElementById(dynId).getAttribute("section_name")
                                            // alert(sectionName)
                                            ths.setState({ loadChart9: true, loadChart10: true, loadChart11: true, loadChart12: true, selectedSectionId: sectionId }, () => {
                                                fetchsectionChartData(ths.state.selectedGroup, ths.state.selectedDepartId, ths.state.selectedSectionId, sectionId, (serverChartData) => {
                                                    if (serverChartData?.status == "success") {
                                                        let sectionPagcor = serverChartData?.result["sec-group"]
                                                        const chart1 = [
                                                            ["Task", "Section popuplation"],
                                                            [sectionPagcor[0]["id"], sectionPagcor[0]["value"]],
                                                            [sectionPagcor[1]["id"], sectionPagcor[1]["value"]]
                                                        ];

                                                        let secDpetPagcor = serverChartData?.result["sec-dept"]
                                                        const chart2 = [
                                                            ["Task", "Section popuplation"],
                                                            [secDpetPagcor[0]["id"], secDpetPagcor[0]["value"]],
                                                            [secDpetPagcor[1]["id"], Math.abs(secDpetPagcor[1]["value"])]
                                                        ];

                                                        let secDivPagcor = serverChartData?.result["sec-div"]
                                                        const chart3 = [
                                                            ["Task", "Section popuplation"],
                                                            [secDivPagcor[0]["id"], secDivPagcor[0]["value"]],
                                                            [secDivPagcor[1]["id"], Math.abs(secDivPagcor[1]["value"])]
                                                        ];

                                                        let secAllPagcor = serverChartData?.result["all-sec"]
                                                        const chart4 = [
                                                            ["Task", "Section popuplation"],
                                                        ];

                                                        if (secAllPagcor?.length > 0) {
                                                            secAllPagcor.map((data, index) => {
                                                                let eachDivData = [data.id, data.value];
                                                                chart4.push(eachDivData);
                                                            })
                                                        }
                                                        ths.setState({
                                                            chart9: chart1, chart10: chart2, chart11: chart3, chart12: chart4,
                                                            loadChart9: false, loadChart10: false, loadChart11: false, loadChart12: false,
                                                            selectedSectionId: sectionId,
                                                            sectionName: sectionName
                                                        })
                                                    }
                                                })
                                            })

                                        }}>
                                            {
                                                sectionDDData.length > 0 ?
                                                    sectionDDData.map((section, index) =>
                                                        <option id={"section" + section.section_id} value={section.section_id} selected={sectionName == section.section_name} section_name={section.section_name}>{section.section_name}</option>
                                                    )
                                                    : null
                                            }
                                        </Input>
                                    </FormGroup>
                                </div>
                                <Row style={{ padding: '3vh 0' }}>

                                    <Col md={{ size: 3 }}>



                                        {
                                            loadChart9 == false ?
                                                <Chart
                                                    chartType="PieChart"
                                                    data={chart9}
                                                    options={{
                                                        title: sectionName + " vs. total " + groupName + " Populations",
                                                        backgroundColor: isDarkMode? '#1b293e' : '#dee5ed',
                                            
                                                        legendTextStyle: { color:isDarkMode? '#FFF':'black' },
                                                        titleTextStyle: { color:isDarkMode? '#FFF':'black' },
                                                        hAxis: {
                                                        color:isDarkMode? '#FFF':'black' ,
                                                        }

                                                    }}
                                                    width={"100%"}
                                                    height={"400px"}
                                                />
                                                : <Spinner className="mt-5" style={{ marginRight: "400px" }} size="sm" color="dark" />
                                        }
                                    </Col>
                                    <Col md={{ size: 3 }}>
                                        {
                                            loadChart10 == false ?
                                                <Chart
                                                    chartType="PieChart"
                                                    data={chart10}
                                                    options={{
                                                        title: sectionName + " vs. total " + departName + " Populations",
                                                        backgroundColor: isDarkMode? '#1b293e' : '#dee5ed',
                                            
                                                        legendTextStyle: { color:isDarkMode? '#FFF':'black' },
                                                        titleTextStyle: { color:isDarkMode? '#FFF':'black' },
                                                        hAxis: {
                                                        color:isDarkMode? '#FFF':'black' ,
                                                        }
                                                    }}
                                                    width={"100%"}
                                                    height={"400px"}
                                                />
                                                : <Spinner className="mt-5" style={{ marginRight: "400px" }} size="sm" color="dark" />
                                        }
                                    </Col>
                                    <Col md={{ size: 3 }}>
                                        {
                                            loadChart11 == false ?
                                                <Chart
                                                    chartType="PieChart"
                                                    data={chart11}
                                                    options={{
                                                        title: sectionName + " vs. total " + divisionName + " Populations",
                                                        backgroundColor: isDarkMode? '#1b293e' : '#dee5ed',
                                            
                                                        legendTextStyle: { color:isDarkMode? '#FFF':'black' },
                                                        titleTextStyle: { color:isDarkMode? '#FFF':'black' },
                                                        hAxis: {
                                                        color:isDarkMode? '#FFF':'black' ,
                                                        }
                                                    }}
                                                    width={"100%"}
                                                    height={"400px"}
                                                />
                                                : <Spinner className="mt-5" style={{ marginRight: "400px" }} size="sm" color="dark" />
                                        }
                                    </Col>
                                    <Col md={{ size: 3 }}>
                                        {
                                            loadChart12 == false ?
                                                <Chart
                                                    chartType="PieChart"
                                                    data={chart12}
                                                    options={{
                                                        title: "All Sections Populcations",
                                                        backgroundColor: isDarkMode? '#1b293e' : '#dee5ed',
                                            
                                                        legendTextStyle: { color:isDarkMode? '#FFF':'black' },
                                                        titleTextStyle: { color:isDarkMode? '#FFF':'black' },
                                                        hAxis: {
                                                        color:isDarkMode? '#FFF':'black' ,
                                                        }
                                                    }}
                                                    width={"100%"}
                                                    height={"400px"}
                                                />
                                                : <Spinner className="mt-5" style={{ marginRight: "400px" }} size="sm" color="dark" />
                                        }
                                    </Col>
                                </Row>
                            </>

                            :
                            this.state.selectedGroup == "3" ?
                                <>
                                    <div className="mb-4" style={{ width: "32%", marginLeft: 20 }}>
                                        <FormGroup style={{ marginLeft: '5px', marginRight: '20px', marginBottom: '0' }}>
                                            <Label for="BRANCH" style={{ color:isDarkMode?'#ffffff':'black'}}>Branches</Label>:
                                            <Input type="select" name="main_group" id="main_group" onChange={(event) => {
                                                console.log("sel val:", event.target.value)
                                                let dynId = "branch" + event.target.value;
                                                let branchName = document.getElementById(dynId).getAttribute("branch_name");
                                                this.setState({ branchName: branchName, sogLoader3: true, sogLoader4: true, sogLoader5: true, sogLoader6: true })
                                                this.props.getAssessBranchDivsHierarchy(4, event.target.value, (divsData) => {
                                                    console.log("This is the branch divisions:", divsData)
                                                    if (divsData?.status == "success" && divsData?.result?.length) {

                                                        let divisionId = divsData?.result[0]["division_id"];
                                                        let divisionName = divsData?.result[0]["division_name"];
                                                        this.setState({ divisionName: divisionName, sogDivisions: divsData?.result }, () => {
                                                            fetchBranchDivChartData(this.state.selectedGroup, this.state.selectedBranch, divisionId, (divCharts) => {
                                                                console.log("this is sog div data:", divCharts)
                                                                if (divCharts?.result) {
                                                                    let sogDiv = divCharts?.result["sog_div-sog_branch"]
                                                                    const chart3 = [
                                                                        ["Task", "SOG popuplation"],
                                                                        [sogDiv[0]["id"], sogDiv[0]["value"]],
                                                                        [sogDiv[1]["id"], sogDiv[1]["value"]]
                                                                    ];
                                                                    this.setState({ sogChart3: chart3, sogLoader3: false })
                                                                    this.props.getAssessBranchSectionHierarchy(4, this.state.selectedBranch, divisionId, (sectionRes) => {
                                                                        console.log("This is the section data:", sectionRes?.result)
                                                                        let sectionId = sectionRes?.result[0]["section_id"]
                                                                        let sectionName = sectionRes?.result[0]["section_name"]
                                                                        if (sectionRes?.result) {
                                                                            this.setState({ sogSections: sectionRes?.result }, () => {
                                                                                fetchBranchDivSectionChartData(this.state.selectedGroup, this.state.selectedBranch, divisionId, sectionId, (sectionChartData) => {
                                                                                    console.log("This is the section chart data:", sectionChartData)
                                                                                    if (sectionChartData?.result) {
                                                                                        let sectionBranch = sectionChartData.result["sog_sec-sog_branch"]
                                                                                        let sectionDiv = sectionChartData.result["sog_sec-sog_div"]
                                                                                        let allSections = sectionChartData.result["all-sogsec"]
                                                                                        const chart4 = [
                                                                                            ["Task", "SOG popuplation"],
                                                                                            [sectionBranch[0]["id"], sectionBranch[0]["value"]],
                                                                                            [sectionBranch[1]["id"], sectionBranch[1]["value"]]
                                                                                        ];
                                                                                        const chart5 = [
                                                                                            ["Task", "SOG popuplation"],
                                                                                            [sectionDiv[0]["id"], sectionDiv[0]["value"]],
                                                                                            [sectionDiv[1]["id"], sectionDiv[1]["value"]]
                                                                                        ];
                                                                                        const chart6 = [
                                                                                            ["Task", "SOG popuplation"],
                                                                                            [allSections[0]["id"], allSections[0]["value"]],
                                                                                            [allSections[1]["id"], allSections[1]["value"]]
                                                                                        ];
                                                                                        this.setState({ sectionName: sectionName, sogChart4: chart4, sogChart5: chart5, sogChart6: chart6, sogLoader4: false, sogLoader5: false, sogLoader6: false })
                                                                                    }
                                                                                })
                                                                            })
                                                                        }
                                                                    })
                                                                }
                                                            })
                                                        })
                                                    }
                                                })
                                                this.setState({ selectedBranch: event.target.value, sogLoader1: true }, () => {
                                                    fetchBranchChartData(this.state.selectedGroup, this.state.selectedBranch, (branchCharts) => {

                                                        if (branchCharts?.status == "success") {
                                                            //let allSog = branchCharts?.result["all-cf_sog_branch"]
                                                            let sogPagcor = branchCharts?.result["sog_branch-pagcor"]
                                                            console.log("sogPagcor******", sogPagcor)
                                                            const chart1 = [
                                                                ["Task", "SOG popuplation"],
                                                                [sogPagcor[0]["id"], sogPagcor[0]["value"]],
                                                                [sogPagcor[1]["id"], sogPagcor[1]["value"]]
                                                            ];

                                                            // const chart2 = [
                                                            //     ["Task", "SOG popuplation"],
                                                            // ];

                                                            // if (allSog?.length > 0) {
                                                            //     allSog.map((data, index) => {
                                                            //         let eachsogData = [data.id, data.value];
                                                            //         chart2.push(eachsogData);
                                                            //     })
                                                            // }
                                                            console.log("This is the branch chart data:", branchCharts?.result)
                                                            this.setState({ sogLoader1: false, sogChart1: chart1 })
                                                        }
                                                    })
                                                })

                                            }}>
                                                {
                                                    locationBranchesDD.length > 0 ?
                                                        locationBranchesDD.map((location, index) =>
                                                            <option id={"branch" + location.branch_id} branch_name={location.branch_name} value={location.branch_id} selected={this.state.selectedBranch == location.branch_id}>{location.branch_name}</option>
                                                        )
                                                        : null
                                                }
                                            </Input>
                                        </FormGroup>
                                    </div>
                                    <Row style={{ padding: '3vh 0' }}>

                                        <Col md={{ size: 6 }}>

                                            {
                                                sogLoader1 == false ?
                                                    <Chart
                                                        chartType="PieChart"
                                                        data={sogChart1}
                                                        options={{
                                                            title: branchName + " vs. total PAGCOR Population",
                                                            backgroundColor: isDarkMode? '#1b293e' : '#dee5ed',
                                            
                                                            legendTextStyle: { color:isDarkMode? '#FFF':'black' },
                                                            titleTextStyle: { color:isDarkMode? '#FFF':'black' },
                                                            hAxis: {
                                                            color:isDarkMode? '#FFF':'black' ,
                                                            }
                                                        }}
                                                        width={"100%"}
                                                        height={"400px"}
                                                    />
                                                    : <Spinner className="mt-5" style={{ marginRight: "400px" }} size="sm" color="dark" />
                                            }
                                        </Col>
                                        <Col md={{ size: 6 }}>
                                            {
                                                sogLoader2 == false ?
                                                    <Chart
                                                        chartType="PieChart"
                                                        data={sogChart2}
                                                        options={{
                                                            title: "All Branches Population",
                                                            backgroundColor: isDarkMode? '#1b293e' : '#dee5ed',
                                            
                                                            legendTextStyle: { color:isDarkMode? '#FFF':'black' },
                                                            titleTextStyle: { color:isDarkMode? '#FFF':'black' },
                                                            hAxis: {
                                                            color:isDarkMode? '#FFF':'black' ,
                                                            }
                                                        }}
                                                        width={"100%"}
                                                        height={"400px"}
                                                    />
                                                    : <Spinner className="mt-5" style={{ marginRight: "400px" }} size="sm" color="dark" />
                                            }
                                        </Col>
                                    </Row>
                                    <div className="mb-4" style={{ width: "32%", marginLeft: 20 }}>
                                        <FormGroup style={{ marginLeft: '5px', marginRight: '20px', marginBottom: '0' }}>
                                            <Label for="BRANCH" style={{ color:isDarkMode?'#ffffff':'black'}}>Divisions</Label>:
                                            <Input type="select" name="main_group" id="main_group" onChange={(event) => {
                                                console.log("sel val:", event.target.value)
                                                let divisionId = event.target.value;
                                                let dynId = "div" + divisionId;
                                                let divisionName = document.getElementById(dynId).getAttribute("division_name");
                                                this.setState({ divisionName: divisionName, sogLoader3: true, sogLoader4: true, sogLoader5: true, sogLoader6: true, sogDivId: divisionId })
                                                fetchBranchDivChartData(this.state.selectedGroup, this.state.selectedBranch, divisionId, (divCharts) => {
                                                    console.log("this is sog div data:", divCharts)
                                                    if (divCharts?.result) {
                                                        let sogDiv = divCharts?.result["sog_div-sog_branch"]
                                                        const chart3 = [
                                                            ["Task", "SOG popuplation"],
                                                            [sogDiv[0]["id"], sogDiv[0]["value"]],
                                                            [sogDiv[1]["id"], sogDiv[1]["value"]]
                                                        ];
                                                        this.setState({ sogChart3: chart3, sogLoader3: false })
                                                        this.props.getAssessBranchSectionHierarchy(4, this.state.selectedBranch, divisionId, (sectionRes) => {
                                                            console.log("This is the section data:", sectionRes?.result)
                                                            let sectionId = sectionRes?.result[0]["section_id"]
                                                            let sectionName = sectionRes?.result[0]["section_name"]
                                                            if (sectionRes?.result) {
                                                                this.setState({ sectionName: sectionName, sogSections: sectionRes?.result }, () => {
                                                                    fetchBranchDivSectionChartData(this.state.selectedGroup, this.state.selectedBranch, divisionId, sectionId, (sectionChartData) => {
                                                                        console.log("This is the section chart data:", sectionChartData)
                                                                        if (sectionChartData?.result) {
                                                                            // let sectionBranch = sectionChartData.result["cf_sog_sec-cf_sog_branch"]
                                                                            // let sectionDiv = sectionChartData.result["cf_sog_sec-cf_sog_div"]
                                                                            // let allSections = sectionChartData.result["all-cf_sogsec"]
                                                                            let sectionBranch = sectionChartData.result["sog_sec-sog_branch"]
                                                                            let sectionDiv = sectionChartData.result["sog_sec-sog_div"]
                                                                            let allSections = sectionChartData.result["all-sogsec"]
                                                                            const chart4 = [
                                                                                ["Task", "SOG popuplation"],
                                                                                [sectionBranch[0]["id"], sectionBranch[0]["value"]],
                                                                                [sectionBranch[1]["id"], sectionBranch[1]["value"]]
                                                                            ];
                                                                            const chart5 = [
                                                                                ["Task", "SOG popuplation"],
                                                                                [sectionDiv[0]["id"], sectionDiv[0]["value"]],
                                                                                [sectionDiv[1]["id"], sectionDiv[1]["value"]]
                                                                            ];
                                                                            let chart6 = [];
                                                                            if(typeof allSections[1] !=="undefined") {
                                                                                chart6 = [
                                                                                    ["Task", "SOG popuplation"],
                                                                                    [allSections[0]["id"], allSections[0]["value"]],
                                                                                    [allSections[1]["id"], allSections[1]["value"]]
                                                                                ];
                                                                            }
                                                                            else {
                                                                                chart6 = [
                                                                                    ["Task", "SOG popuplation"],
                                                                                    [allSections[0]["id"], allSections[0]["value"]],
                                                                                    //[allSections[1]["id"], allSections[1]["value"]]
                                                                                ];
                                                                            }
                                                                            this.setState({ sogChart4: chart4, sogChart5: chart5, sogChart6: chart6, sogLoader4: false, sogLoader5: false, sogLoader6: false })
                                                                        }
                                                                    })
                                                                })
                                                            }
                                                        })
                                                    }
                                                })

                                            }}>
                                                {
                                                    sogDivisions.length > 0 ?
                                                        sogDivisions.map((div, index) =>
                                                            <option id={"div" + div.division_id} value={div.division_id} division_name={div.division_name} selected={div.division_name === divisionName}>{div.division_name}</option>
                                                        )
                                                        : null
                                                }
                                            </Input>
                                        </FormGroup>
                                    </div>
                                    <Row style={{ padding: '3vh 0' }}>

                                        <Col md={{ size: 6 }}>

                                            {
                                                sogLoader3 == false ?
                                                    <Chart
                                                        chartType="PieChart"
                                                        data={sogChart3}
                                                        options={{
                                                            title: divisionName + " vs. total " + branchName + " Population",
                                                            backgroundColor: isDarkMode? '#1b293e' : '#dee5ed',
                                            
                                                            legendTextStyle: { color:isDarkMode? '#FFF':'black' },
                                                            titleTextStyle: { color:isDarkMode? '#FFF':'black' },
                                                            hAxis: {
                                                            color:isDarkMode? '#FFF':'black' ,
                                                            }
                                                        }}
                                                        width={"100%"}
                                                        height={"400px"}
                                                    />
                                                    : <Spinner className="mt-5" style={{ marginRight: "400px" }} size="sm" color="dark" />
                                            }
                                        </Col>
                                        <Col md={{ size: 6 }}>
                                            {
                                                sogLoader4 == false ?
                                                    <Chart
                                                        chartType="PieChart"
                                                        data={sogChart4}
                                                        options={{
                                                            title: sectionName + " vs. total " + branchName + " Population",
                                                            backgroundColor: isDarkMode? '#1b293e' : '#dee5ed',
                                            
                                                            legendTextStyle: { color:isDarkMode? '#FFF':'black' },
                                                            titleTextStyle: { color:isDarkMode? '#FFF':'black' },
                                                            hAxis: {
                                                            color:isDarkMode? '#FFF':'black' ,
                                                            }
                                                        }}
                                                        width={"100%"}
                                                        height={"400px"}
                                                    />
                                                    : <Spinner className="mt-5" style={{ marginRight: "400px" }} size="sm" color="dark" />
                                            }
                                        </Col>
                                    </Row>
                                    <div className="mb-4" style={{ width: "32%", marginLeft: 20 }}>
                                        <FormGroup style={{ marginLeft: '5px', marginRight: '20px', marginBottom: '0' }}>
                                            <Label for="BRANCH" style={{ color:isDarkMode?'#ffffff':'black'}}>Sections</Label>:
                                            <Input type="select" name="main_group" id="main_group" onChange={(event) => {
                                                console.log("sel val:", event.target.value)
                                                let sectionId = event.target.value
                                                let dynId = "section" + sectionId
                                                let sectionName = document.getElementById(dynId).getAttribute("section_name")
                                                this.setState({ sectionName: sectionName, sogLoader5: true, sogLoader6: true })
                                                fetchBranchDivSectionChartData(this.state.selectedGroup, this.state.selectedBranch, this.state.sogDivId, sectionId, (sectionChartData) => {
                                                    console.log("This is the section chart data:", sectionChartData)
                                                    if (sectionChartData?.result) {
                                                        let sectionBranch = sectionChartData.result["sog_sec-sog_branch"]
                                                        let sectionDiv = sectionChartData.result["sog_sec-sog_div"]
                                                        let allSections = sectionChartData.result["all-sogsec"]
                                                        // const chart4 = [
                                                        //     ["Task", "SOG popuplation"],
                                                        //     [sectionBranch[0]["id"], sectionBranch[0]["value"]],
                                                        //     [sectionBranch[1]["id"], sectionBranch[1]["value"]]
                                                        // ];
                                                        const chart5 = [
                                                            ["Task", "SOG popuplation"],
                                                            [sectionDiv[0]["id"], sectionDiv[0]["value"]],
                                                            [sectionDiv[1]["id"], sectionDiv[1]["value"]]
                                                        ];
                                                        const chart6 = [
                                                            ["Task", "SOG popuplation"],
                                                            [allSections[0]["id"], allSections[0]["value"]],
                                                            [allSections[1]["id"], allSections[1]["value"]]
                                                        ];
                                                        this.setState({ sogChart5: chart5, sogChart6: chart6, sogLoader5: false, sogLoader6: false })
                                                    }
                                                })
                                            }}>
                                                {
                                                    sogSections.length > 0 ?
                                                        sogSections.map((section, index) =>
                                                            <option id={"section" + section.section_id} value={section.section_id} section_name={section.section_name} selected={sectionName === section.section_name}>{section.section_name}</option>
                                                        )
                                                        : null
                                                }
                                            </Input>
                                        </FormGroup>
                                    </div>
                                    <Row style={{ padding: '3vh 0' }}>

                                        <Col md={{ size: 6 }}>

                                            {
                                                sogLoader5 == false ?
                                                    <Chart
                                                        chartType="PieChart"
                                                        data={sogChart5}
                                                        options={{
                                                            title: sectionName + " vs. total " + divisionName + " Population",
                                                            backgroundColor: isDarkMode? '#1b293e' : '#dee5ed',
                                            
                                                            legendTextStyle: { color:isDarkMode? '#FFF':'black' },
                                                            titleTextStyle: { color:isDarkMode? '#FFF':'black' },
                                                            hAxis: {
                                                            color:isDarkMode? '#FFF':'black' ,
                                                            }
                                                        }}
                                                        width={"100%"}
                                                        height={"400px"}
                                                    />
                                                    : <Spinner className="mt-5" style={{ marginRight: "400px" }} size="sm" color="dark" />
                                            }
                                        </Col>
                                        <Col md={{ size: 6 }}>
                                            {
                                                sogLoader6 == false ?
                                                    <Chart
                                                        chartType="PieChart"
                                                        data={sogChart6}
                                                        options={{
                                                            title: "All Sections of " + divisionName,
                                                            backgroundColor: isDarkMode? '#1b293e' : '#dee5ed',
                                            
                                                            legendTextStyle: { color:isDarkMode? '#FFF':'black' },
                                                            titleTextStyle: { color:isDarkMode? '#FFF':'black' },
                                                            hAxis: {
                                                            color:isDarkMode? '#FFF':'black' ,
                                                            }
                                                        }}
                                                        width={"100%"}
                                                        height={"400px"}
                                                    />
                                                    : <Spinner className="mt-5" style={{ marginRight: "400px" }} size="sm" color="dark" />
                                            }
                                        </Col>
                                    </Row>
                                </>
                                :
                                <>
                                    <div className="mb-4" style={{ width: "32%", marginLeft: 20 }}>
                                        <FormGroup style={{ marginLeft: '5px', marginRight: '20px', marginBottom: '0' }}>
                                            <Label for="BRANCH" style={{ color:isDarkMode?'#ffffff':'black'}}>Locations</Label>:
                                            <Input type="select" name="location" id="location" onChange={(event) => {
                                                console.log("sel val:", event.target.value)
                                                let dynId = "location" + event.target.value;
                                                let locationName = document.getElementById(dynId).getAttribute("location_name");
                                                // alert(locationName)
                                                this.setState({ locationName: locationName, selLocation: event.target.value, cfLoader1: true, cfLoader2: true, cfLoader3: true, cfLoader4: true, cfLoader5: true, cfLoader6: true }, () => {
                                                    this.props.getLocationDivsHierarchy(this.state.selLocation, (locDiv) => {
                                                        console.clear();
                                                        console.log("This is my location divs:", locDiv);
                                                        if (locDiv?.status == "success") {
                                                            //locationDivs
                                                            const { selLocation } = this.state;
                                                            let divId = locDiv?.result[0].division_id;
                                                            let divName = locDiv?.result[0].division_name;
                                                            fetchLocationDivChartData(this.state.selectedGroup, this.state.selLocation, divId, (locationDivChart) => {
                                                                console.log("This is the location div chart:", locationDivChart)
                                                                //cf_div-cf_location
                                                                if (locationDivChart?.status == "success") {
                                                                    let cfDivLocation = locationDivChart?.result["cf_div-cf_location"];
                                                                    const chart3 = [
                                                                        ["Task", "CF popuplation"],
                                                                        [cfDivLocation[0]["id"], cfDivLocation[0]["value"]],
                                                                        [cfDivLocation[1]["id"], cfDivLocation[1]["value"]]
                                                                    ];
                                                                    this.setState({ cfChart3: chart3, cfLoader3: false })
                                                                }
                                                            })
                                                            //alert(divName)
                                                            this.setState({ divisionName: divName, locationDivs: locDiv?.result, locationDivId: divId }, () => {
                                                                this.props.getLocationSectionHierarchy(selLocation, divId, (locationSecs) => {
                                                                    // console.clear();
                                                                    console.log("This is my location sections:", locationSecs);
                                                                    if (locationSecs?.status == "success") {
                                                                        let sectionId = locationSecs?.result[0].section_id;
                                                                        let sectionName = locationSecs?.result[0].section_name;
                                                                        this.setState({ sectionName: sectionName, locationSections: locationSecs?.result }, () => {
                                                                            //call the chart data here
                                                                            fetchLocationDivSectionsChartData(this.state.selectedGroup, this.state.selLocation, divId, sectionId, (locationSectionData) => {
                                                                                console.log("*****", locationSectionData)
                                                                                if (locationSectionData?.status == "success") {
                                                                                    let allCfSec = locationSectionData?.result["all-cfsec"];
                                                                                    let cfSecLocation = locationSectionData?.result["cf_sec-cf_location"];
                                                                                    let cfSecDiv = locationSectionData?.result["cf_sec-cf_div"];

                                                                                    const chart4 = [
                                                                                        ["Task", "CF popuplation"],
                                                                                        [cfSecLocation[0]["id"], cfSecLocation[0]["value"]],
                                                                                        [cfSecLocation[1]["id"], cfSecLocation[1]["value"]]
                                                                                    ];

                                                                                    const chart5 = [
                                                                                        ["Task", "CF popuplation"],
                                                                                        [cfSecDiv[0]["id"], cfSecDiv[0]["value"]],
                                                                                        [cfSecDiv[1]["id"], cfSecDiv[1]["value"]]
                                                                                    ];
                                                                                    const chart6 = [
                                                                                        ["Task", "CF popuplation"],
                                                                                    ];
                                                                                    if (allCfSec?.length) {
                                                                                        allCfSec.map((sec, indx) => {
                                                                                            chart6.push([sec.id, sec.value])
                                                                                        })
                                                                                    }
                                                                                    this.setState({ cfChart4: chart4, cfChart5: chart5, cfChart6: chart6, cfLoader4: false, cfLoader5: false, cfLoader6: false })
                                                                                    console.log(allCfSec, "===", cfSecLocation, "===", cfSecDiv)
                                                                                }
                                                                            })
                                                                        })

                                                                    }
                                                                })
                                                            })
                                                        }
                                                    })
                                                    fetchLocationChartData(this.state.selectedGroup, this.state.selLocation, (locationChartData) => {
                                                        //console.clear();
                                                        console.log("This is my location chart data:", locationChartData);
                                                        //cf_location-pagcor
                                                        if (locationChartData?.status == "success") {
                                                            let cfLocationPagcor = locationChartData.result["cf_location-pagcor"];
                                                            let allcfLocationPagcor = locationChartData.result["all-cf_location"];
                                                            console.log(cfLocationPagcor, '---', allcfLocationPagcor)

                                                            const chart1 = [
                                                                ["Task", "CF popuplation"],
                                                                [cfLocationPagcor[0]["id"], cfLocationPagcor[0]["value"]],
                                                                [cfLocationPagcor[1]["id"], cfLocationPagcor[1]["value"]]
                                                            ];
                                                            const chart2 = [
                                                                ["Task", "CF popuplation"],
                                                            ]
                                                            if (allcfLocationPagcor?.length) {

                                                                allcfLocationPagcor.map((item, indx) => {
                                                                    console.log(item, "***", indx)
                                                                    let eachData = [item.id, item.value];
                                                                    chart2.push(eachData)
                                                                })
                                                                console.log("final chart 2 of the cf:", chart2)
                                                            }
                                                            this.setState({ cfChart1: chart1, cfChart2: chart2, cfLoader1: false, cfLoader2: false })
                                                        }
                                                    })
                                                })
                                            }}>
                                                {
                                                    locations.length > 0 ?
                                                        locations.map((location, index) =>
                                                            <option id={"location" + location.location_id} value={location.location_id} location_name={location.location_name}>{location.location_name}</option>
                                                        )
                                                        : null
                                                }
                                            </Input>
                                        </FormGroup>
                                    </div>
                                    <Row style={{ padding: '3vh 0' }}>

                                        <Col md={{ size: 6 }}>

                                            {
                                                cfLoader1 == false ?
                                                    <Chart
                                                        chartType="PieChart"
                                                        data={cfChart1}
                                                        options={{
                                                            title: locationName + " vs. PAGCOR Populations",
                                                            backgroundColor: isDarkMode? '#1b293e' : '#dee5ed',
                                            
                                                            legendTextStyle: { color:isDarkMode? '#FFF':'black' },
                                                            titleTextStyle: { color:isDarkMode? '#FFF':'black' },
                                                            hAxis: {
                                                            color:isDarkMode? '#FFF':'black' ,
                                                            }
                                                        }}
                                                        width={"100%"}
                                                        height={"400px"}
                                                    />
                                                    : <Spinner className="mt-5" style={{ marginRight: "400px" }} size="sm" color="dark" />
                                            }
                                        </Col>
                                        <Col md={{ size: 6 }}>
                                            {
                                                cfLoader2 == false ?
                                                    <Chart
                                                        chartType="PieChart"
                                                        data={cfChart2}
                                                        options={{
                                                            title: "All Location Populations",
                                                            backgroundColor: isDarkMode? '#1b293e' : '#dee5ed',
                                            
                                                            legendTextStyle: { color:isDarkMode? '#FFF':'black' },
                                                            titleTextStyle: { color:isDarkMode? '#FFF':'black' },
                                                            hAxis: {
                                                            color:isDarkMode? '#FFF':'black' ,
                                                            }
                                                        }}
                                                        width={"100%"}
                                                        height={"400px"}
                                                    />
                                                    : <Spinner className="mt-5" style={{ marginRight: "400px" }} size="sm" color="dark" />
                                            }
                                        </Col>
                                    </Row>
                                    <div className="mb-4" style={{ width: "32%", marginLeft: 20 }}>
                                        <FormGroup style={{ marginLeft: '5px', marginRight: '20px', marginBottom: '0' }}>
                                            <Label for="BRANCH" style={{ color:isDarkMode?'#ffffff':'black'}}>Divisions</Label>:
                                            <Input type="select" name="division" id="division" onChange={(event) => {
                                                console.log("sel val:", event.target.value)
                                                let divId = event.target.value;
                                                let dynId = "divName" + divId;
                                                let divName = document.getElementById(dynId).getAttribute('division_name');
                                                const { selLocation } = this.state;
                                                this.setState({ divisionName: divName, locationDivId: divId, cfLoader3: true, cfLoader4: true, cfLoader5: true, cfLoader6: true }, () => {
                                                    fetchLocationDivChartData(this.state.selectedGroup, this.state.selLocation, divId, (locationDivChart) => {
                                                        console.log("This is the location div chart:", locationDivChart)
                                                        //cf_div-cf_location
                                                        if (locationDivChart?.status == "success") {
                                                            let cfDivLocation = locationDivChart?.result["cf_div-cf_location"];
                                                            const chart3 = [
                                                                ["Task", "CF popuplation"],
                                                                [cfDivLocation[0]["id"], cfDivLocation[0]["value"]],
                                                                [cfDivLocation[1]["id"], cfDivLocation[1]["value"]]
                                                            ];
                                                            this.setState({ cfChart3: chart3, cfLoader3: false })
                                                        }
                                                    })

                                                    this.props.getLocationSectionHierarchy(selLocation, divId, (locationSecs) => {
                                                        // console.clear();
                                                        console.log("This is my location sections:", locationSecs);
                                                        if (locationSecs?.status == "success") {
                                                            let sectionId = locationSecs?.result[0].section_id;
                                                            let sectionName = locationSecs?.result[0].section_name;

                                                            this.setState({ sectionName: sectionName, locationSections: locationSecs?.result }, () => {
                                                                //call the chart data here
                                                                fetchLocationDivSectionsChartData(this.state.selectedGroup, this.state.selLocation, divId, sectionId, (locationSectionData) => {
                                                                    console.log("*****", locationSectionData)
                                                                    if (locationSectionData?.status == "success") {
                                                                        let allCfSec = locationSectionData?.result["all-cfsec"];
                                                                        let cfSecLocation = locationSectionData?.result["cf_sec-cf_location"];
                                                                        let cfSecDiv = locationSectionData?.result["cf_sec-cf_div"];

                                                                        const chart4 = [
                                                                            ["Task", "CF popuplation"],
                                                                            [cfSecLocation[0]["id"], cfSecLocation[0]["value"]],
                                                                            [cfSecLocation[1]["id"], cfSecLocation[1]["value"]]
                                                                        ];

                                                                        const chart5 = [
                                                                            ["Task", "CF popuplation"],
                                                                            [cfSecDiv[0]["id"], cfSecDiv[0]["value"]],
                                                                            [cfSecDiv[1]["id"], cfSecDiv[1]["value"]]
                                                                        ];
                                                                        const chart6 = [
                                                                            ["Task", "CF popuplation"],
                                                                        ];
                                                                        if (allCfSec?.length) {
                                                                            allCfSec.map((sec, indx) => {
                                                                                chart6.push([sec.id, sec.value])
                                                                            })
                                                                        }
                                                                        this.setState({ cfChart4: chart4, cfChart5: chart5, cfChart6: chart6, cfLoader4: false, cfLoader5: false, cfLoader6: false })
                                                                        console.log(allCfSec, "===", cfSecLocation, "===", cfSecDiv)
                                                                    }
                                                                })
                                                            })

                                                        }
                                                    })
                                                })
                                            }}>
                                                {
                                                    locationDivs.length > 0 ?
                                                        locationDivs.map((div, index) =>
                                                            <option value={div.division_id} id={'divName' + div.division_id} division_name={div.division_name} selected={div.division_name === divisionName}>{div.division_name}</option>
                                                        )
                                                        : null
                                                }
                                            </Input>
                                        </FormGroup>
                                    </div>
                                    <Row style={{ padding: '3vh 0' }}>

                                        <Col md={{ size: 6 }}>

                                            {
                                                cfLoader3 == false ?
                                                    <Chart
                                                        chartType="PieChart"
                                                        data={cfChart3}
                                                        options={{
                                                            title: divisionName + " vs. " + locationName + " Populations",
                                                            backgroundColor: isDarkMode? '#1b293e' : '#dee5ed',
                                            
                                                            legendTextStyle: { color:isDarkMode? '#FFF':'black' },
                                                            titleTextStyle: { color:isDarkMode? '#FFF':'black' },
                                                            hAxis: {
                                                            color:isDarkMode? '#FFF':'black' ,
                                                            }
                                                        }}
                                                        width={"100%"}
                                                        height={"400px"}
                                                    />
                                                    : <Spinner className="mt-5" style={{ marginRight: "400px" }} size="sm" color="dark" />
                                            }
                                        </Col>
                                        <Col md={{ size: 6 }}>
                                            {
                                                cfLoader4 == false ?
                                                    <Chart
                                                        chartType="PieChart"
                                                        data={cfChart4}
                                                        options={{
                                                            title: sectionName + " vs. total " + locationName + " Population",
                                                            backgroundColor: isDarkMode? '#1b293e' : '#dee5ed',
                                            
                                                            legendTextStyle: { color:isDarkMode? '#FFF':'black' },
                                                            titleTextStyle: { color:isDarkMode? '#FFF':'black' },
                                                            hAxis: {
                                                            color:isDarkMode? '#FFF':'black' ,
                                                            }
                                                        }}
                                                        width={"100%"}
                                                        height={"400px"}
                                                    />
                                                    : <Spinner className="mt-5" style={{ marginRight: "400px" }} size="sm" color="dark" />
                                            }
                                        </Col>
                                    </Row>
                                    <div className="mb-4" style={{ width: "32%", marginLeft: 20 }}>
                                        <FormGroup style={{ marginLeft: '5px', marginRight: '20px', marginBottom: '0' }}>
                                            <Label for="BRANCH" style={{ color:isDarkMode?'#ffffff':'black'}}>Sections</Label>:
                                            <Input type="select" name="section" id="section" onChange={(event) => {
                                                console.log("sel val:", event.target.value)
                                                let sectionId = event.target.value;
                                                let divId = this.state.locationDivId
                                                let dynId = "section" + sectionId;
                                                let sectionName = document.getElementById(dynId).getAttribute('section_name')
                                                this.setState({ sectionName: sectionName, cfLoader4: true, cfLoader5: true, cfLoader6: true }, () => {
                                                    //call the chart data here
                                                    fetchLocationDivSectionsChartData(this.state.selectedGroup, this.state.selLocation, divId, sectionId, (locationSectionData) => {
                                                        console.log("*****", locationSectionData)
                                                        if (locationSectionData?.status == "success") {
                                                            let allCfSec = locationSectionData?.result["all-cfsec"];
                                                            let cfSecLocation = locationSectionData?.result["cf_sec-cf_location"];
                                                            let cfSecDiv = locationSectionData?.result["cf_sec-cf_div"];

                                                            const chart4 = [
                                                                ["Task", "CF popuplation"],
                                                                [cfSecLocation[0]["id"], cfSecLocation[0]["value"]],
                                                                [cfSecLocation[1]["id"], cfSecLocation[1]["value"]]
                                                            ];

                                                            const chart5 = [
                                                                ["Task", "CF popuplation"],
                                                                [cfSecDiv[0]["id"], cfSecDiv[0]["value"]],
                                                                [cfSecDiv[1]["id"], cfSecDiv[1]["value"]]
                                                            ];
                                                            const chart6 = [
                                                                ["Task", "CF popuplation"],
                                                            ];
                                                            if (allCfSec?.length) {
                                                                allCfSec.map((sec, indx) => {
                                                                    chart6.push([sec.id, sec.value])
                                                                })
                                                            }
                                                            this.setState({ cfChart4: chart4, cfChart5: chart5, cfChart6: chart6, cfLoader4: false, cfLoader5: false, cfLoader6: false })
                                                            console.log(allCfSec, "===", cfSecLocation, "===", cfSecDiv)
                                                        }
                                                    })
                                                })

                                            }}>
                                                {
                                                    locationSections.length > 0 ?
                                                        locationSections.map((section, index) =>
                                                            <option value={section.section_id} id={"section" + section.section_id} section_name={section.section_name} selected={sectionName == section.section_name}>{section.section_name}</option>
                                                        )
                                                        : null
                                                }
                                            </Input>
                                        </FormGroup>
                                    </div>
                                    <Row style={{ padding: '3vh 0' }}>

                                        <Col md={{ size: 6 }}>

                                            {
                                                cfLoader5 == false ?
                                                    <Chart
                                                        chartType="PieChart"
                                                        data={cfChart5}
                                                        options={{
                                                            title: sectionName + " vs. total " + divisionName + " Population",
                                                            backgroundColor: isDarkMode? '#1b293e' : '#dee5ed',
                                            
                                                            legendTextStyle: { color:isDarkMode? '#FFF':'black' },
                                                            titleTextStyle: { color:isDarkMode? '#FFF':'black' },
                                                            hAxis: {
                                                            color:isDarkMode? '#FFF':'black' ,
                                                            }
                                                        }}
                                                        width={"100%"}
                                                        height={"400px"}
                                                    />
                                                    : <Spinner className="mt-5" style={{ marginRight: "400px" }} size="sm" color="dark" />
                                            }
                                        </Col>
                                        <Col md={{ size: 6 }}>
                                            {
                                                cfLoader6 == false ?
                                                    <Chart
                                                        chartType="PieChart"
                                                        data={cfChart6}
                                                        options={{
                                                            title: "All Sections of " + divisionName,
                                                            backgroundColor: isDarkMode? '#1b293e' : '#dee5ed',
                                            
                                                            legendTextStyle: { color:isDarkMode? '#FFF':'black' },
                                                            titleTextStyle: { color:isDarkMode? '#FFF':'black' },
                                                            hAxis: {
                                                            color:isDarkMode? '#FFF':'black' ,
                                                            }
                                                        }}
                                                        width={"100%"}
                                                        height={"400px"}
                                                    />
                                                    : <Spinner className="mt-5" style={{ marginRight: "400px" }} size="sm" color="dark" />
                                            }
                                        </Col>
                                    </Row>
                                </>
                    }
                </Container>
            </div>
        )
    }
}
ChartComponent.propTypes = {
    getMainGroupsData: PropTypes.func.isRequired,
    getAssessBranchDivsHierarchy: PropTypes.func.isRequired,
    getAssessBranchSectionHierarchy: PropTypes.func.isRequired,
    fetchSubGroupsOrLocationsOrSOG: PropTypes.func.isRequired,
    getLocationDivsHierarchy: PropTypes.func.isRequired,
    getLocationSectionHierarchy: PropTypes.func.isRequired,
}
const mapStatesToProps = state => ({
    mainGroupsData: state.webRed.mainGroupsData,
})
export default connect(mapStatesToProps, {
    getMainGroupsData,
    getAssessBranchDivsHierarchy,
    getAssessBranchSectionHierarchy,
    fetchSubGroupsOrLocationsOrSOG,
    getLocationDivsHierarchy,
    getLocationSectionHierarchy
})(ChartComponent);
