import React, { Component,Fragment } from "react";
import { Redirect } from "react-router-dom";
import Header from './../Header';
// import Footer from "./../Footer";

import './../../../public/scss/ProfileComponent.scss';

import { proceedToAssessments, sendUpdateProfileRequest } from './../../../actions/webRedActions';

import { getEmployeeProfile,getEmployeeRelatedData } from './../../../actions/utility';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// import userData from './../userData';

import {
    Button,
    Container,
    Row,
    Col,
    Media,
    Form,
    FormGroup,
    Label,
    Input,
    Alert,
    Table
  } from "reactstrap";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';

// import { BrowserRouter as Router, Route, Link } from "react-router-dom";

class ProfileComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            allowAssessments : false,
            // showSubmitUpdateBtn : false,
            updateRequest : []
        };
    }

    componentDidMount() {
        var isAssessAllowed = localStorage.getItem('allowAssessments');
        if(isAssessAllowed && isAssessAllowed == 'allowed') {
        this.setState({
            allowAssessments : true
        })
        }
    }

    componentWillReceiveProps(nextProps, thisProps) {
        
    }

    selectUpdate = (col, value) => {
        // var field = {
        //     column : col,
        //     value : value
        // };
        var field = col;

        var tempUpdateRequest = this.state.updateRequest;
        var index = -1;
        tempUpdateRequest.map((item, key) => {
            if(item == col){
                index = key;
            }
        });
        // console.log(index)
        if(index > -1){ // if this exist
            tempUpdateRequest.splice(index, 1);
        } else {
            tempUpdateRequest.push(field);
        }

        this.setState({
            updateRequest : tempUpdateRequest
        }, () => {
            console.log(this.state.updateRequest)
        })
    }

    highlightUpdate = (col) => {
        console.log(col)
        this.state.updateRequest.map(item => {
            if(col == item.column) {
                return "highlight";
            }
        });

        return null;
    }

    callActionUpdate = (emp_Id, requestData) => {
      const empData = getEmployeeProfile();
      this.props.sendUpdateProfileRequest(emp_Id, requestData);
    }

  render() {
    const userData = getEmployeeProfile();
    const userRelatedData = getEmployeeRelatedData();
    var years = null;
    try {
        years = Object.keys(userRelatedData.groupsHistory);
    }
    catch(err) {
        years = null;
    }
    // console.log(userData);

    return (
      <div className="ProfileComponent" style={{backgroundColor: '#f6fbff'}}>
        <Header />

        <div>

            <Container>
                
                <Form>
                    <Row style={{padding: '6vh 5vw', textAlign: 'left'}}>

                        
                        <Col md={{size: 12}} style={{paddingBottom: '2vh'}}>
                            <Alert color="warning" className="alertMessage">
                                Before you start, please review the information on your profile and click &nbsp;<span style={{color: 'red'}}>update</span>
                                <FontAwesomeIcon icon={faPencilAlt} size="1x" />&nbsp;if you think changes need to be made. <strong>PAGCOR's HR Team</strong> will be notified of your update request and they will make the changes.
                            </Alert>
                            
                            {
                                !this.state.allowAssessments && !this.state.updateRequest.length ?
                                    <Alert color="warning" className="alertMessage light">
                                        The information in my profile is correct, i want to proceed to the assessment &nbsp;&nbsp;
                                        <Button onClick={(e) => {
                                            this.props.proceedToAssessments();
                                        }} color="success" size="md">
                                            Proceed
                                        </Button>
                                    </Alert>
                                :   null
                            }

                            {
                                this.state.updateRequest && this.state.updateRequest.length ?
                                    <div style={{width: '100%', textAlign: 'center'}}>
                                        <Button color="danger" size="lg"
                                            style={{margin: '25px 0'}}
                                            onClick={(e) => this.callActionUpdate(userData.ID_NUMBER, this.state.updateRequest)}
                                        >Submit update request to HR</Button>
                                    </div>
                                : null
                            }
                            
                        </Col>
                        
                        <Col md={{size: 4}}>
                            <FormGroup className={
                                this.state.updateRequest.map(item => {
                                    if(item == 'FIRST_NAME') {
                                        return "highlight";
                                    }
                                })
                            }>
                                <FontAwesomeIcon className="updateIcon" icon={faPencilAlt} size="1x" 
                                    onClick={(e) => this.selectUpdate('FIRST_NAME', userData.FIRST_NAME)}
                                />
                                <Label for="firstname">First name</Label>
                                <Input type="text" name="firstname" id="firstname" placeholder="" value={userData.FIRST_NAME} disabled />
                            </FormGroup>
                        </Col>
                        <Col md={{size: 4}}>
                            <FormGroup className={
                                this.state.updateRequest.map(item => {
                                    if(item == 'MI') {
                                        return "highlight";
                                    }
                                })
                            }>
                                <FontAwesomeIcon className="updateIcon" icon={faPencilAlt} size="1x" 
                                    onClick={(e) => this.selectUpdate('MI', userData.MI)}
                                />
                                <Label for="middlename">MI</Label>
                                <Input type="text" name="middlename" id="middlename" placeholder="" value={userData.MI} disabled />
                            </FormGroup>
                        </Col>
                        <Col md={{size: 4}}>
                            <FormGroup className={
                                this.state.updateRequest.map(item => {
                                    if(item == 'LAST_NAME') {
                                        return "highlight";
                                    }
                                })
                            }>
                                <FontAwesomeIcon className="updateIcon" icon={faPencilAlt} size="1x" 
                                    onClick={(e) => this.selectUpdate('LAST_NAME', userData.LAST_NAME)}
                                />
                                <Label for="lastname">Last name</Label>
                                <Input type="text" name="lastname" id="lastname" placeholder="" value={userData.LAST_NAME} disabled />
                            </FormGroup>
                        </Col>
                        <Col md={{size: 12}}>
                            <FormGroup>
                                <Label for="emp_id">Employee ID</Label>
                                <Input type="text" name="emp_id" id="emp_id" placeholder="" value={userData.ID_NUMBER} disabled />
                            </FormGroup>
                        </Col>
                        
                        <Col md={{size: 12}}>
                            <FormGroup>
                                <Label for="email">Email id</Label>
                                <Input type="text" name="email" id="email" placeholder="" value={userData.email} disabled />
                            </FormGroup>
                        </Col>                        
                        
                        <Col md={{size: 12}}>
                            <FormGroup className={
                                this.state.updateRequest.map(item => {
                                    if(item == 'DESIGNATION') {
                                        return "highlight";
                                    }
                                })
                            }>
                                <FontAwesomeIcon className="updateIcon" icon={faPencilAlt} size="1x" 
                                    onClick={(e) => this.selectUpdate('DESIGNATION', userData.DESIGNATION)}
                                />
                                <Label for="designation">Designation</Label>
                                <Input type="text" name="designation" id="designation" placeholder="" value={userData.DESIGNATION} disabled />
                            </FormGroup>
                        </Col>
                        { 
                        userData.branch_name !=null ? 
                        <Col md={{size: 12}}>
                            <FormGroup className={
                                this.state.updateRequest.map(item => {
                                    if(item == 'BRANCH') {
                                        return "highlight";
                                    }
                                })
                            }>
                                <FontAwesomeIcon className="updateIcon" icon={faPencilAlt} size="1x" 
                                    onClick={(e) => this.selectUpdate('branch_name', userData.branch_name)}
                                />
                                <Label for="branch">Branch</Label>
                                <Input type="text" name="branch_name" id="branch_name" placeholder="" value={userData.branch_name} disabled />
                            </FormGroup>
                        </Col>
                        :null
                        }
                        { 
                        userData.dept_name !=null ? 
                            <Col md={{size: 12}}>
                                <FormGroup className={
                                    this.state.updateRequest.map(item => {
                                        if(item == 'dept_name') {
                                            return "highlight";
                                        }
                                    })
                                }>
                                    <FontAwesomeIcon className="updateIcon" icon={faPencilAlt} size="1x" 
                                        onClick={(e) => this.selectUpdate('dept_name', userData.dept_name)}
                                    />
                                    <Label for="department">Department</Label>
                                    <Input type="text" name="department" id="department" placeholder="" value={userData.dept_name} disabled />
                                </FormGroup>
                            </Col> 
                            : null
                        }
                        {
                            userData.group_name !=null ? 
                            <Col md={{size: 12}}>
                                <FormGroup className={
                                    this.state.updateRequest.map(item => {
                                        if(item == 'group_name') {
                                            return "highlight";
                                        }
                                    })
                                }>
                                    <FontAwesomeIcon className="updateIcon" icon={faPencilAlt} size="1x" 
                                        onClick={(e) => this.selectUpdate('group_name', userData.group_name)}
                                    />
                                    <Label for="group name">Group name</Label>
                                    <Input type="text" name="group_name" id="group_name" placeholder="" value={userData.group_name} disabled />
                                </FormGroup>
                            </Col>
                            : null
                        }
                        {
                            userData.sub_groupname !=null ? 
                            <Col md={{size: 12}}>
                                <FormGroup className={
                                    this.state.updateRequest.map(item => {
                                        if(item == 'sub_groupname') {
                                            return "highlight";
                                        }
                                    })
                                }>
                                    <FontAwesomeIcon className="updateIcon" icon={faPencilAlt} size="1x" 
                                        onClick={(e) => this.selectUpdate('sub_groupname', userData.sub_groupname)}
                                    />
                                    <Label for="sub group name">Sub group name</Label>
                                    <Input type="text" name="sub_groupname" id="sub_groupname" placeholder="" value={userData.sub_groupname} disabled />
                                </FormGroup>
                            </Col>
                            : null
                        }
                                    {
                            userData.pc_name !=null ? 
                            <Col md={{size: 12}}>
                                <FormGroup className={
                                    this.state.updateRequest.map(item => {
                                        if(item == 'job_grade') {
                                            
                                            return "highlight";
                                        }
                                    })
                                }>
                                    <FontAwesomeIcon className="updateIcon" icon={faPencilAlt} size="1x" 
                                        onClick={(e) => this.selectUpdate('job_grade', userData.pc_name)}
                                    />
                                    <Label for="job_grade">Job Grade</Label>
                                    <Input type="text" name="job_grade" id="job_grade" placeholder="" value={userData.pc_name} disabled />
                                </FormGroup>
                            </Col>
                            : null
                        }
                        {
                            userData.location_name !=null ? 
                            <Col md={{size: 12}}>
                                <FormGroup className={
                                    this.state.updateRequest.map(item => {
                                        if(item == 'location_name') {
                                            return "highlight";
                                        }
                                    })
                                }>
                                    <FontAwesomeIcon className="updateIcon" icon={faPencilAlt} size="1x" 
                                        onClick={(e) => this.selectUpdate('location_name', userData.location_name)}
                                    />
                                    <Label for="location name">Location name</Label>
                                    <Input type="text" name="location_name" id="location_name" placeholder="" value={userData.location_name} disabled />
                                </FormGroup>
                            </Col>
                            : null
                        }
                        {
                            userData.satellite_name !=null ? 
                            <Col md={{size: 12}}>
                                <FormGroup className={
                                    this.state.updateRequest.map(item => {
                                        if(item == 'satellite_name') {
                                            return "highlight";
                                        }
                                    })
                                }>
                                    <FontAwesomeIcon className="updateIcon" icon={faPencilAlt} size="1x" 
                                        onClick={(e) => this.selectUpdate('satellite_name', userData.satellite_name)}
                                    />
                                    <Label for="satellite name">Satellite name</Label>
                                    <Input type="text" name="satellite_name" id="satellite_name" placeholder="" value={userData.satellite_name} disabled />
                                </FormGroup>
                            </Col>
                            : null
                        }
                        {
                        typeof userRelatedData.subOrds !=="undefined" && userRelatedData.subOrds !=0?     
                        <Col md={{size: 12}}>
                            <FormGroup>
                                <Label for="section">Supervising <b>{userRelatedData.subOrds.length} {userRelatedData.subOrds.length == 1 ? 'Empolyee' : 'Empolyees'}</b></Label>
                                <Table style={{marginTop:5}}>
                                
                                    <tbody>
                                        {
                                        userRelatedData && userRelatedData.subOrds.length && userRelatedData.subOrds.map((subOrdinate, key) =>
                                            <tr key={key}>
                                                <td >{subOrdinate.ID_NUMBER}</td>
                                                <td>{subOrdinate.NAME}</td>
                                                <td>{subOrdinate.SECTION}</td>
                                                <td>{subOrdinate.DIVISION}</td>
                                                
                                            </tr>
                                        )
                                        }

                                    </tbody>
                                </Table>
                            </FormGroup>
                        </Col>
                        :null
                        }
                        {
                            typeof userRelatedData.supervision !=="undefined" && userRelatedData.supervision !=0 ? 
                            <Col md={{size: 12}}>
                                <FormGroup>
                                    <Label for="section">Reporting to <b>{userRelatedData.supervision.length} Supervisor</b></Label>
                                    <Table style={{marginTop:5}}>
                                        <tbody>
                                        {
                                            userRelatedData.supervision.map((supervisor, key) =>
                                                <tr key={key}>
                                                    <td>{supervisor.ID_NUMBER}</td>
                                                    <td>{supervisor.NAME}</td>
                                                </tr>
                                            )
                                            }
                                        </tbody>
                                    </Table>
                                </FormGroup>
                            </Col> 
                            :null
                        }
                        {
                        years !=null ? 
                        <Col md={{size: 12}}>
                            <Label for="Years">Year wise groups history</Label>
                            <Form>
                                <FormGroup>
                                    <Row>
                                        {
                                            years.length > 0 && Object.keys(userRelatedData.groupsHistory).length && years.map((year, key) => 
                                                <Col>{year}
                                                    <div>
                                                        {
                                                        typeof userRelatedData.groupsHistory[year] !=="undefined" && 
                                                        typeof userRelatedData.groupsHistory[year][0] !=="undefined" && 
                                                        typeof userRelatedData.groupsHistory[year][0].group_name !=="undefined" && 
                                                        userRelatedData.groupsHistory[year][0].group_name !=null ?     
                                                        <Fragment>
                                                            <p>Main Group : {userRelatedData.groupsHistory[year][0].group_name}</p>
                                                            <br/>
                                                        </Fragment>
                                                        :null
                                                        }
                                                        {
                                                        typeof userRelatedData.groupsHistory[year] !=="undefined" && 
                                                        typeof userRelatedData.groupsHistory[year][0] !=="undefined"&&
                                                        typeof userRelatedData.groupsHistory[year][0].sub_groupname !=="undefined"&&    
                                                        userRelatedData.groupsHistory[year][0].sub_groupname !=null ? 
                                                        <Fragment>
                                                            <p>Sub Group : {userRelatedData.groupsHistory[year][0].sub_groupname}</p>
                                                            <br/>
                                                        </Fragment>
                                                        :null
                                                        }
                                                        {
                                                        typeof userRelatedData.groupsHistory[year] !=="undefined" && 
                                                        typeof userRelatedData.groupsHistory[year][0] !=="undefined" &&
                                                        typeof userRelatedData.groupsHistory[year][0].dept_name !=="undefined" && 
                                                        userRelatedData.groupsHistory[year][0].dept_name !=null ?
                                                        <Fragment>
                                                                <p>Department : {userRelatedData.groupsHistory[year][0].dept_name}</p>
                                                        </Fragment>    
                                                        :null
                                                        }
                                                        {
                                                        typeof userRelatedData.groupsHistory[year] !=="undefined" && 
                                                        typeof userRelatedData.groupsHistory[year][0] !=="undefined" && 
                                                        typeof userRelatedData.groupsHistory[year][0].location_name !=="undefined" &&   
                                                        userRelatedData.groupsHistory[year][0].location_name !=null ?
                                                        <Fragment>
                                                                <p>Location : {userRelatedData.groupsHistory[year][0].location_name}</p>
                                                        </Fragment>    
                                                        :null
                                                        }
                                                        {
                                                        typeof userRelatedData.groupsHistory[year] !=="undefined" && 
                                                        typeof userRelatedData.groupsHistory[year][0] !=="undefined" &&
                                                        typeof userRelatedData.groupsHistory[year][0].satellite_name !=="undefined" &&   
                                                        userRelatedData.groupsHistory[year][0].satellite_name !=null ?
                                                        <Fragment>
                                                                <p>Satellite : {userRelatedData.groupsHistory[year][0].satellite_name}</p>
                                                        </Fragment>    
                                                        :null
                                                        }
                                                    </div>
                                                </Col>
                                                
                                            )
                                        }
                                    </Row>
                                </FormGroup>
                            </Form>
                        </Col>
                        :null
                        }
                        {/* <Col md={{size: 12}}>
                            <FormGroup className={
                                this.state.updateRequest.map(item => {
                                    if(item == 'DIVISION') {
                                        return "highlight";
                                    }
                                })
                            }>
                                <FontAwesomeIcon className="updateIcon" icon={faPencilAlt} size="1x" 
                                    onClick={(e) => this.selectUpdate('DIVISION', userData.DIVISION)}
                                />
                                <Label for="division">Division</Label>
                                <Input type="text" name="division" id="division" placeholder="" value={userData.DIVISION} disabled />
                            </FormGroup>
                        </Col> */}
                        
                        {/* <Col md={{size: 12}}>
                            <FormGroup className={
                                this.state.updateRequest.map(item => {
                                    if(item == 'SECTION') {
                                        return "highlight";
                                    }
                                })
                            }>
                                <FontAwesomeIcon className="updateIcon" icon={faPencilAlt} size="1x" 
                                    onClick={(e) => this.selectUpdate('SECTION', userData.SECTION)}
                                />
                                <Label for="section">Section</Label>
                                <Input type="text" name="section" id="section" placeholder="" value={userData.SECTION} disabled />
                            </FormGroup>
                        </Col> */}
                        
                        {/* <Col md={{size: 12}}>
                            <FormGroup className={
                                this.state.updateRequest.map(item => {
                                    if(item == 'PC') {
                                        return "highlight";
                                    }
                                })
                            }>
                                <FontAwesomeIcon className="updateIcon" icon={faPencilAlt} size="1x" 
                                    onClick={(e) => this.selectUpdate('PC', userData.PC)}
                                />
                                <Label for="section">Job Grade</Label>
                                <Input type="text" name="payclass" id="payclass" placeholder="" value={userData.PC} disabled />
                            </FormGroup>
                        </Col> */}
                        
                        {
                            !this.state.allowAssessments && !this.state.updateRequest.length ?

                                <Col md={{size: 12}} style={{paddingTop: '2vh'}}>
                                    <Alert color="warning" className="alertMessage light">
                                        The information in my profile is correct, i want to proceed to the assessment &nbsp;&nbsp;
                                        <Button onClick={(e) => {
                                            this.props.proceedToAssessments();
                                        }} color="success" size="md">
                                            Proceed
                                        </Button>
                                    </Alert>
                                </Col>

                            :   null
                        }

                        
                        {
                            this.state.updateRequest && this.state.updateRequest.length ?
                                <Col md={{size: 12}} style={{paddingTop: '1vh', textAlign: 'center'}}>
                                    <Button color="danger" size="lg"
                                        style={{margin: '25px 0'}}
                                        onClick={(e) => this.callActionUpdate(userData.ID_NUMBER, this.state.updateRequest)}
                                    >Submit update request to HR</Button>
                                </Col>
                            : null
                        }


                    </Row>
                </Form>

            </Container>

        </div>
        
        {/* <Footer /> */}
      </div>
    );
  }
}

ProfileComponent.propTypes = {
    proceedToAssessments: PropTypes.func.isRequired,
    sendUpdateProfileRequest: PropTypes.func.isRequired,
}

const mapStatesToProps = state => ({
    // triggerNextPinSuccess : state.webRed.triggerNextPinSuccess,
    // triggeredLoginAction : state.webRed.triggeredLoginAction
})

export default connect( mapStatesToProps, { proceedToAssessments, sendUpdateProfileRequest })( ProfileComponent );