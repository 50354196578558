import React, { Component, Fragment } from "react";
import { Redirect } from "react-router-dom";
import classnames from 'classnames';
// import Header from './../Header';
// import Footer from "./../Footer";

import { updateProfileData, assignNewAdmin, assignNewSubordinate, adminFetchEmployeeInformation, 
    removeSubordinate,validateEmpEmail, getAdditionalData, getMainGroupsData,
    fetchSubGroupsOrLocationsOrSOG,
    fetchSubGroupsDepartments,
    fetchLocationBranches,
    fetchBrancheSatellite,
    updateGroupData,fetchYearWiseGroupsData,
    getAssesstDeptsHierarchy,
    getAssesstDivHierarchy,
    getAssesstSectionHierarchy,
    getAssesstPCHierarchy,
    getAssesstDesignationHierarchy,
    getLocationDivsHierarchy,
    getLocationSectionHierarchy,
    getLocationPCHierarchy,
    getLocationDesigHierarchy,
    getAssessBranchDivsHierarchy,
    getAssessBranchSectionHierarchy,
    getAssessBranchPCHierarchy,
    getAssessBranchDesignationHierarchy,
    updateProfileYear
 } from './../../../../actions/webRedActions';

import { autoFocusNextField, getBaseUrl,validateEmail,getYear, updateYear } from './../../../../actions/utility';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle, faCheck } from '@fortawesome/free-solid-svg-icons';

import axios from 'axios';
import './../../../../public/scss/ProfileComponentEmployee.scss';
// import userData from './../userData';

import AssessmentManagement from './AssessmentManagement';

import {
    Button,
    Container,
    Row,
    Col,
    Table,
    Form,
    FormGroup,
    Label,
    Input,
    NavLink,
    Nav,
    NavItem,
    TabPane,
    TabContent,
    Alert,
    Spinner
  } from "reactstrap";

import readXlsxFile from 'read-excel-file';
import { getAdminUserData } from "../../../../actions/utility";
import userData from "../../userData";
import ScoreCard from "../../assessment/ScoreCard";
import ScoreCardsList from "../../assessment/ScoreCardsList";
import $ from "jquery";

// import { BrowserRouter as Router, Route, Link } from "react-router-dom";

class EmployeeProfile extends Component {

    constructor(props) {
        super(props);
        this.state = {
            activeTab : 'p',
            userDataMain : this.props.userData,
            userData : this.props.userData,
            userSupervisorData : this.props.userSupervisorData,
            userReportingData : this.props.userReportingData,
            assessmentMangement : this.props.assessmentMangement,
            highlights : {},

            designationList : [],
            divisionList : [],
            sectionList : [],
            branchList : [],
            deptList : [],
            pcList : [],
            
            employeeId : ["", "", "-", "", "", "", ""],
            employeeIdString : "",
            employeeIdError : "",
            isLoading: false,
            years: ['2019','2020','2021','2024'],
            
            employeeIdSub : ["", "", "-", "", "", "", ""],
            employeeIdStringSub : "",
            employeeIdErrorSub : "",
            isLoadingSub: false,
            mainGroupsDataArr : [],
            subGroupDataArr : [],
            departmentsArr : [],
            locationsArr : [],
            branchesArr : [],
            satelliteArr : [],
            relatedDataType : '',
            ddLoader1 : false,
            ddLoader2 : false,
            ddLoader3 : false,
            ddLoader4 : false,
            divLoader : false,
            mainGroup:'',
            subGroup:'',
            department:'',
            year : '',
            showUpdateBtn : false,
            location : '',
            branch : '',
            satellite : '',
            showUpdateBtn1 : false,
            updateBtnLoader : false,
            yearWiseDataArr : [],
            yearWiseDataLoading : false,
            deptId : 0,
            divisionsArr : [],
            divId : 0,
            sectionLoader : false,
            sectionsArr : [],
            sectionId:0,
            pcLoader : false,
            pcDataArr : [],
            pcId:0,
            desigLoader : false,
            desigId:0,
            desigArr : [],
            locationDivArr : [],
            locationDivLoader : false,
            locationSectionArr : [],
            locationSectionLoader : false,
            locationDivId : 0,
            locationPCArr:[],
            locationPCLoader : false,
            locationSectionId : 0,
            locationPCId : 0,
            locationDesigArr : [],
            locationDesigId : 0,
            locationBranchId:0,
            locationBranchDivLoader: false,
            locationBranchDivArr : [],
            locationBranchSectionLoader :false,
            locationBranchSectionArr : [],
            locationBranchSectionId : 0,
            locationBranchPCLoader : false,
            locationBranchPCArr : [],
            locationBranchPCId : 0,
            locationBranchDesigLoader : false,
            locationBranchDesigArr : [],
            locationBranchDesigId : 0,
            locationBranchDivId : 0,
            selectedYear: null,
        };
    }

    toggleTab = (tab) => {
        if(this.state.activeTab !== tab) {
            this.setState({
                activeTab : tab
            })
        }
    }

    selectUpdate = (col, value) => {

        const {userData, highlights} = this.state;
        if(col == 'email' && !validateEmail(value)) {
            alert("Please provide valie email address");
            return false;
        }
        else if(col == 'email' && validateEmail(value)) {
            var ths = this;
            this.props.validateEmpEmail(value,function(res) {
                console.log('here is the data:',res);
                if(typeof res ==='object' && (res.status == 'success' && res.result == 1))
                    alert('Email already assigned, try unique one')
                else 
                    ths.props.updateProfileData(userData.ID_NUMBER, col, value); //calls action for updating profile data
            })
        }
        else  
            this.props.updateProfileData(userData.ID_NUMBER, col, value); //calls action for updating profile data

        this.setState({
            highlights : {
                NAME : col == 'NAME' ? false : highlights.NAME,
                FIRST_NAME : col == 'FIRST_NAME' ? false : highlights.FIRST_NAME,
                MI : col == 'MI' ? false : highlights.MI,
                LAST_NAME : col == 'LAST_NAME' ? false : highlights.LAST_NAME,
                ID_NUMBER : col == 'ID_NUMBER' ? false : highlights.ID_NUMBER,
                email : col == 'email' ? false : highlights.email,
                DESIGNATION : col == 'DESIGNATION' ? false : highlights.DESIGNATION,
                BRANCH : col == 'BRANCH' ? false : highlights.BRANCH,
                DEPT : col == 'DEPT' ? false : highlights.DEPT,
                DIVISION : col == 'DIVISION' ? false : highlights.DIVISION,
                SECTION : col == 'SECTION' ? false : highlights.SECTION,
                PC : col == 'PC' ? false : highlights.PC
            }
        })

    }

    updateValue = (e) => {
        var name = e.target.name;
        var value = e.target.value;
        const {userData, highlights} = this.state;

        this.setState({
            userData : {
                NAME : name == 'NAME' ? value : userData.NAME,
                FIRST_NAME : name == 'FIRST_NAME' ? value : userData.FIRST_NAME,
                MI : name == 'MI' ? value : userData.MI,
                LAST_NAME : name == 'LAST_NAME' ? value : userData.LAST_NAME,
                ID_NUMBER : name == 'ID_NUMBER' ? value : userData.ID_NUMBER,
                email : name == 'email' ? value : userData.email,
                DESIGNATION : name == 'DESIGNATION' ? value : userData.DESIGNATION,
                BRANCH : name == 'BRANCH' ? value : userData.BRANCH,
                DEPT : name == 'DEPT' ? value : userData.DEPT,
                DIVISION : name == 'DIVISION' ? value : userData.DIVISION,
                SECTION : name == 'SECTION' ? value : userData.SECTION,
                PC : name == 'PC' ? value : userData.PC,
            }
        }, () => {
            // console.log(this.state.userData);
            this.setState({
                highlights : {
                    NAME : name == 'NAME' ? value !== userData.NAME ? true : false : highlights.NAME,
                    FIRST_NAME : name == 'FIRST_NAME' ? value !== userData.FIRST_NAME ? true : false : highlights.FIRST_NAME,
                    MI : name == 'MI' ? value !== userData.MI ? true : false : highlights.MI,
                    LAST_NAME : name == 'LAST_NAME' ? value !== userData.LAST_NAME ? true : false : highlights.LAST_NAME,
                    ID_NUMBER : name == 'ID_NUMBER' ? value !== userData.ID_NUMBER ? true : false : highlights.ID_NUMBER,
                    email : name == 'email' ? value !== userData.email ? true : false : highlights.email,
                    DESIGNATION : name == 'DESIGNATION' ? value !== userData.DESIGNATION ? true : false : highlights.DESIGNATION,
                    BRANCH : name == 'BRANCH' ? value !== userData.BRANCH ? true : false : highlights.BRANCH,
                    DEPT : name == 'DEPT' ? value !== userData.DEPT ? true : false : highlights.DEPT,
                    DIVISION : name == 'DIVISION' ? value !== userData.DIVISION ? true : false : highlights.DIVISION,
                    SECTION : name == 'SECTION' ? value !== userData.SECTION ? true : false : highlights.SECTION,
                    PC : name == 'PC' ? value !== userData.PC ? true : false : highlights.PC
                }
            })
        })
    }

    componentDidMount() {
        console.log("userData:",this.state.userData);
        autoFocusNextField("boxFieldForm"); // auto focus utility method called

        this.fetchAllDistinctValues();

    }

    fetchAllDistinctValues = () => {
        
        if(getYear() > '2020') {
            // this.props.getAdditionalData();
            this.props.getMainGroupsData(this.state.userData.ID_NUMBER);
            let that = this;
            that.setState({yearWiseDataLoading:true});
            this.props.fetchYearWiseGroupsData(this.state.userData.ID_NUMBER,this.state.years,(response)=>{
                if(typeof response.status !=="undefined" && response.status == 'success') {
                    that.setState({yearWiseDataArr : response.result,yearWiseDataLoading:false});
                }
                console.log('this is the years response:',response);
            });
        }
        else {
            //fetch all distinct values
            this.getAllDistinctData('DESIGNATION');
            this.getAllDistinctData('BRANCH');
            this.getAllDistinctData('DEPT');
            this.getAllDistinctData('DIVISION');
            this.getAllDistinctData('SECTION');
            this.getAllDistinctData('PC');
        }
    }

    componentWillReceiveProps(nextProps, thisProps) {
        // console.log(nextProps.userSupervisorData);

        if(this.props.userData != nextProps.userData) {
            this.setState({
                userDataMain : nextProps.userData,
                userData : nextProps.userData,
                highlights : {}
            }, () => {
                
                this.fetchAllDistinctValues();
                
            })
        }

        if(this.props.userSupervisorData != nextProps.userSupervisorData) {
            this.setState({
                userSupervisorData : nextProps.userSupervisorData
            })
        }

        if(this.props.userReportingData != nextProps.userReportingData) {
            this.setState({
                userReportingData : nextProps.userReportingData
            })
        }

        if(this.props.assessmentMangement != nextProps.assessmentMangement) {
            this.setState({
                assessmentMangement : nextProps.assessmentMangement
            })
        }


        // when the supervisor is successfully assigned
        if(this.props.assignedSupDataSuccessAdmin != nextProps.assignedSupDataSuccessAdmin) {
            
            console.log('this is the my response:',nextProps.assignedSupDataSuccessAdmin);
            if(nextProps.assignedSupDataSuccessAdmin.result.status == 'error') {
                this.setState({
                    employeeId : ["", "", "-", "", "", "", ""],
                    employeeIdString : "",
                    employeeIdError : "",
                    isLoading: false
                }, () => {
                    alert(nextProps.assignedSupDataSuccessAdmin.result.message);
                })
            }
            else {
                this.setState({
                    employeeId : ["", "", "-", "", "", "", ""],
                    employeeIdString : "",
                    employeeIdError : "",
                    isLoading: false
                }, () => {
                    this.props.adminFetchEmployeeInformation(this.state.userData.ID_NUMBER);
                })
            }
        }

        // when the subordinate is successfully assigned
        if(this.props.assignedSubordinateDataSuccessAdmin != nextProps.assignedSubordinateDataSuccessAdmin) {
            
            console.log('this is the my response:',nextProps.assignedSubordinateDataSuccessAdmin.result);
            if(nextProps.assignedSubordinateDataSuccessAdmin.result.status == 'error') {
                this.setState({
                    employeeIdSub : ["", "", "-", "", "", "", ""],
                    employeeIdStringSub : "",
                    employeeIdErrorSub : "",
                    isLoadingSub: false
                }, () => {
                    alert(nextProps.assignedSubordinateDataSuccessAdmin.result.message);
                })
            }
            else {
                this.setState({
                    employeeIdSub : ["", "", "-", "", "", "", ""],
                    employeeIdStringSub : "",
                    employeeIdErrorSub : "",
                    isLoadingSub: false
                }, () => {
                    this.props.adminFetchEmployeeInformation(this.state.userData.ID_NUMBER);
                })
            }
        }

        // when the subordinate is successfully removed
        if(this.props.removedSubordinateDataSuccessAdmin != nextProps.removedSubordinateDataSuccessAdmin) {
            this.setState({
                employeeIdSub : ["", "", "-", "", "", "", ""],
                employeeIdStringSub : "",
                employeeIdErrorSub : "",
                isLoadingSub: false
            }, () => {
                this.props.adminFetchEmployeeInformation(this.state.userData.ID_NUMBER);
            })
        }
        if(this.props.additionalData != nextProps.additionalData) {
            this.setState({
                designationList : nextProps.additionalData.data.designation,
                divisionList    : nextProps.additionalData.data.divisions,
                sectionList     : nextProps.additionalData.data.sections,
                branchList      : nextProps.additionalData.data.branches,
                deptList        : nextProps.additionalData.data.departments,
                pcList          : nextProps.additionalData.data.payClass
            })
        }
        if(this.props.mainGroupsData != nextProps.mainGroupsData) {
            this.setState({mainGroupsDataArr : nextProps.mainGroupsData.data},()=>{
                console.log('set state works here');
            })
        }
        

    }
    
    
    handleChange = (event, index) => {
        event.preventDefault();
        const { name, value } = event.target;

        var employeeIdTemp = this.state.employeeId;
        employeeIdTemp[index] = value;

        this.setState({
            [name] : employeeIdTemp
        }, () => {
            // console.log(this.state.employeeId);

            var emp_id = this.convertEmpIdToString(this.state.employeeId);
            this.setState({
                employeeIdString : emp_id
            }, () => {
                // it means all inputs are done here we need to call action to verify
                
                if(emp_id.length == 7) {
                }
                // console.log(this.state.employeeIdString)
            })

        })

        if(this.state.employeeIdError != "") {
            this.setState({
                employeeIdError : ""
            })
        }
    }
    fetchDesignation = (event,index) => {
        const {name,value} = event.target;
        const{subGroup,department,divId,sectionId} =  this.state;
        if (value !='select') {
            this.setState({desigLoader:true,pcId:value})
            let ths = this;
            this.props.getAssesstDesignationHierarchy(subGroup,department,divId,sectionId,value,(desigData) => {
                console.log('desigData:',desigData)
                if (desigData !=0) {
                    ths.setState({desigLoader:false,desigArr :desigData.result})
                }
                else {
                    ths.setState({desigLoader:false})
                    alert("Designation not found.")
                }
            })
        }

    }
    fetchPayClass = (event,index) => {
        const {name,value} = event.target;
        const{subGroup,department,divId} =  this.state;
        console.log("go for fetch payclass");
        if (value !='select') {
            this.setState({pcLoader : true,sectionId : value, desigArr : []})
            let ths = this;
            this.props.getAssesstPCHierarchy(subGroup,department,divId,value,(pcData) => {
                console.log("pcData:",pcData);
                if (pcData !=0) {
                    ths.setState({pcLoader : false, pcDataArr : pcData.result})
                }
                else {
                    ths.setState({pcLoader : false})
                    alert("Job Grade not found.")
                }
            })
        }
    }
    fetchSections = (event,index) => {
        const { name, value } = event.target;
        if(value !='select') {
            this.setState({divId : value,sectionLoader:true, pcDataArr : [], desigArr : []});
            console.log('fetch section:',value)
            let ths = this;
            this.props.getAssesstSectionHierarchy(this.state.subGroup,this.state.department,value,(sectionData)=> {
                console.log("sectionData:",sectionData)
                if (sectionData !=0) {
                    ths.setState({sectionsArr : sectionData.result,sectionLoader:false})
                }
                else {
                    ths.setState({sectionLoader:false},()=> {
                        alert("Section not found.")
                    })
                }
            })
        }
    }
    fetchDivisions = (event,index) => {
        const { name, value } = event.target;
        if(value !='select') {
            this.setState({divLoader:true,department : value, sectionsArr : [], pcDataArr : [], desigArr : []})
            console.log("This is the department:",value);
            let ths = this;
            this.props.getAssesstDivHierarchy(value,this.state.subGroup,(divisionData) => {
                console.log("divisionData:",divisionData.result);
                if (divisionData !=0) {
                    ths.setState({divisionsArr : divisionData.result, divLoader:false})
                }
                else {
                    ths.setState({divLoader:false},()=> {
                        alert("Division not found.")
                    })
                }
            })
        }
    }
    fetchLocationDivisions = (event,index) => {
        const { name, value } = event.target;
        console.log(value)
        if (value !="select") {
            if (value == 4) {
                this.setState({locationDivLoader:true,location:value,locationPCArr:[],locationSectionArr:[],locationDivArr:[],locationDesigArr:[]})
                console.log("Location for get the branches")
                this.fetchLocationBranches(event,index)
            }
            else {
                this.setState({locationDivLoader:true,location:value,locationPCArr:[],locationSectionArr:[],locationDivArr:[],locationDesigArr:[]})
                let ths = this;
                this.props.getLocationDivsHierarchy(value,(locationDivArrRess)=>{
                    console.log("locationDivArrRess:",locationDivArrRess);
                    if (locationDivArrRess !=0) {
                        ths.setState({locationDivLoader:false,locationDivArr:locationDivArrRess.result})
                    }
                    else {
                        alert("Divisions not found.")
                        ths.setState({locationDivLoader:false,locationDivArr:[]})
                    }
                })
            }
        }
      
    }
    fetchLocationSections = (event,index) => {
        const { name, value } = event.target;
        if (value !="select") {
            const{location} = this.state;
            this.setState({locationDivId:value,locationSectionLoader:true,locationPCArr:[],locationDesigArr:[]})
            let ths = this;
            this.props.getLocationSectionHierarchy(location,value,(locationSectionRes) => {
                console.log("this is the section:",locationSectionRes);
                if (locationSectionRes !=0) {
                    ths.setState({locationSectionLoader:false,locationSectionArr:locationSectionRes.result})
                }
                else {
                    alert("Section not found");
                    ths.setState({locationSectionLoader:false})
                }
            })
        }
    }
    fetchLocationPC = (event,index) => {
        const { name, value } = event.target;
        if (value !="select") {
            console.log("fetch pc method");
            const{location,locationDivId} = this.state;
            this.setState({locationSectionId:value,locationPCLoader:true,locationDesigArr:[] })
            let ths = this;
            this.props.getLocationPCHierarchy(location,locationDivId,value,(locationPCData)=> {
                console.log("this is the pc:",locationPCData);
                if (locationPCData !=0) {
                    ths.setState({locationPCLoader:false,locationPCArr:locationPCData.result})
                }
                else {
                    alert("Job Grade data not found.")
                    ths.setState({locationPCLoader:false})
                }
            })
        }
    }
    fetchLocationDesignations = (event,index) => {
        const { name, value } = event.target;
        if (value !="select") {
            const{location,locationDivId,locationSectionId} = this.state;
            this.setState({locationDesigLoader:true,locationPCId : value})
            console.log("fetch designation");
            let ths = this;
            this.props.getLocationDesigHierarchy(location,locationDivId,locationSectionId,value,(locationDesigRes)=> {
                if (locationDesigRes !=0) {
                    ths.setState({locationDesigLoader:false,locationDesigArr:locationDesigRes.result})
                }
                else {
                    alert("Designation not found.");
                    ths.setState({locationDesigLoader:false})
                }
            })
        }
    }
    changeYears = (event,index) => {
        const { name, value } = event.target;
        if(value !='select') {
            this.setState({year:value});
        }
    }
    fetchLocationBrachDivisions = (event, index) => {
        const { name, value } = event.target;
        if(value !='select') {
            const {location} = this.state
            
            this.setState({locationBranchId:value,locationBranchDivLoader:true,  locationBranchSectionArr:[], locationBranchPCArr:[], locationBranchDesigArr :[]})
            let ths = this;
            this.props.getAssessBranchDivsHierarchy(location,value,(branchDivRes)=>{
                console.log("branchDivRes:",branchDivRes)
                if (branchDivRes !=0) {
                    ths.setState({locationBranchDivLoader:false,locationBranchDivArr:branchDivRes.result})
                }
                else {
                    alert("Division not found.")
                    ths.setState({locationBranchDivLoader:false})
                }
            })
        }
    }
    fetchLocationBrachSections = (event,index) => {
        const{value} = event.target
        if (value !=0) {
            const{location,locationBranchId} = this.state
            this.setState({locationBranchSectionLoader : true,locationBranchDivId : value,locationBranchPCArr:[], locationBranchDesigArr :[]})
            let ths = this
            this.props.getAssessBranchSectionHierarchy(location,locationBranchId,value,(locationBranchSectionRes) => {
                if (locationBranchSectionRes !=0) {
                    ths.setState({locationBranchSectionArr : locationBranchSectionRes.result, locationBranchSectionLoader : false})
                }
                else {
                    alert("Section not found.");
                    ths.setState({locationBranchSectionLoader : false})

                }
            })
        }
    }
    fetchLocationBrachPC = (event,index) => {
        const{value} = event.target
        if (value !="select") {
            const{location,locationBranchId,locationBranchDivId} = this.state
            this.setState({locationBranchPCLoader : true,locationBranchSectionId: value, locationBranchDesigArr :[]})
            let ths = this;
            this.props.getAssessBranchPCHierarchy(location,locationBranchId,locationBranchDivId,value,(locationBranchPCRes)=> {
                if (locationBranchPCRes !=0) {
                    ths.setState({locationBranchPCLoader : false,locationBranchPCArr :locationBranchPCRes.result })
                }
                else {
                    alert("Job Grade not found.");
                    ths.setState({locationBranchPCLoader : false})
                }
            })
        }
    }
    fetchLocationBrachDesig = (event,index) => {
        const{value} = event.target
        if (value !="select") {
            const{location,locationBranchId,locationBranchDivId,locationBranchSectionId} = this.state
            this.setState({locationBranchDesigLoader : true,locationBranchPCId: value})
            let ths = this;
            this.props.getAssessBranchDesignationHierarchy(location,locationBranchId,locationBranchDivId,locationBranchSectionId,value,(locationBranchDesigRes)=> {
                if (locationBranchDesigRes !=0) {
                    ths.setState({locationBranchDesigLoader : false,locationBranchDesigArr :locationBranchDesigRes.result })
                }
                else {
                    alert("Job Grade not found.");
                    ths.setState({locationBranchDesigLoader : false})
                }
            })
        }
    }

    satelliteChange = (event,index) => {
        const { name, value } = event.target;
        if(value !='select') {
            this.setState({satellite:value});
        }
    }
    fetchLocationBranches = (event, index) => {
        const { name, value } = event.target;
        
        if(value !='select') {
            this.setState({ddLoader3:true,location:value,locationBranchSectionArr:[], locationBranchPCArr:[], locationBranchDesigArr :[],locationBranchDivArr:[]})
            let that = this;
            console.log(name,':name:',value)
            this.props.fetchLocationBranches(value,(data) => {
                console.log('here is the branches data:',data);
                if(data !=0)
                    that.setState({locationDivLoader:false,branchesArr:data.result},()=>{
                        console.log('this is btton:',that.state.showUpdateBtn1)
                    })
                else
                    alert('Data not found.');

            }) 
        }
    }
    fetchSubGroupDepartments = (event, index) => {
        event.preventDefault();
        const { name, value } = event.target;
        if(value !="select") {
            console.log(name,':name:',value)
            let that = this;
            this.setState({ddLoader2:true,subGroup:value, divisionsArr:[],sectionsArr : [], pcDataArr : [], desigArr : []});
            this.props.getAssesstDeptsHierarchy(value,(data) => {
                console.log("This is the hierarchy department:",data);
                if(data !=0)  {
                    that.setState({
                        departmentsArr:data.result,
                        ddLoader2:false
                    })
                }
                else
                    alert('Data not found.');
            })
            // this.props.fetchSubGroupsDepartments(value,(data)=>{
            //     if(data !=0) 
            //         that.setState({departmentsArr:data.result,ddLoader2:false})
            //     else
            //         alert('Data not found.');
            // })
        }
    }
    fetchRelatedData = (event, index) => {
        
        event.preventDefault();
        
        const { name, value } = event.target;
        if(value !="select") {
            this.setState({
                ddLoader1 : true,
                mainGroup : value,
                locationDivArr:[],
                locationSectionArr:[],
                locationPCArr:[],
                locationDesigArr:[],
                locationBranchDivArr :[],
                locationBranchSectionArr:[],
                locationBranchPCArr:[],
                locationBranchDesigArr :[],
                branchesArr : [],
                updateBtnLoader : false
            });
            let that = this;
            this.props.fetchSubGroupsOrLocationsOrSOG(value,(data)=>{
                console.log('i found the data:',data);
                if(data !=0) {
                    if(data.type == 'sub_groups') {
                        that.setState({
                            ddLoader1 : false,subGroupDataArr : data.result,
                            relatedDataType : data.type,branchesArr:[],locationsArr:[],satelliteArr:[],
                            divisionsArr:[],sectionsArr : [], pcDataArr : [], desigArr : []

                        });
                    }
                    else {
                        that.setState({
                            ddLoader1 : false,locationsArr : data.result,
                            relatedDataType : data.type,departmentsArr:[],
                            divisionsArr:[],sectionsArr : [], pcDataArr : [], desigArr : []
                        });
                    }
                }
                else
                    alert('Data not found.');
            });
        }

        console.log(name,':change event data:',value)
        
    }

    convertEmpIdToString = (empId) => {
        var stringEmpId = "";
        empId.map(val => {
            if(val && val != "" && val != undefined){
                stringEmpId += val;
            }
        })

        return stringEmpId;
    }


    // change handle for subordinates only
    handleChangeSub = (event, index) => {
        event.preventDefault();
        const { name, value } = event.target;

        var employeeIdTemp = this.state.employeeIdSub;
        employeeIdTemp[index] = value;

        this.setState({
            [name] : employeeIdTemp
        }, () => {
            // console.log(this.state.employeeId);

            var emp_id = this.convertEmpIdToStringSub(this.state.employeeIdSub);
            this.setState({
                employeeIdStringSub : emp_id
            }, () => {
                // it means all inputs are done here we need to call action to verify
                
                if(emp_id.length == 7) {
                }
                // console.log(this.state.employeeIdString)
            })

        })

        if(this.state.employeeIdErrorSub != "") {
            this.setState({
                employeeIdErrorSub : ""
            })
        }
    }

    convertEmpIdToStringSub = (empId) => {
        var stringEmpId = "";
        empId.map(val => {
            if(val && val != "" && val != undefined){
                stringEmpId += val;
            }
        })

        return stringEmpId;
    }

    
    // fetch all distinct values for any column
    getAllDistinctData = (col) => {
        var bodyFormData = new FormData();
        bodyFormData.set('col', col);

        axios.post( getBaseUrl() + 'api/admin/employee/column/data', bodyFormData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
        .then((res) => {
            console.log(res);
            if(res.data.result) {

                if(col == 'DESIGNATION') {
                    this.setState({
                        designationList : res.data.result
                    }, () => {
                        console.log(this.state.designationList)
                    })
                }
                else if(col == 'DIVISION') {
                    this.setState({
                        divisionList : res.data.result
                    }, () => {
                        console.log(this.state.divisionList)
                    })
                }
                else if(col == 'SECTION') {
                    this.setState({
                        sectionList : res.data.result
                    }, () => {
                        console.log(this.state.sectionList)
                    })
                }
                else if(col == 'BRANCH') {
                    this.setState({
                        branchList : res.data.result
                    }, () => {
                        console.log(this.state.branchList)
                    })
                }
                else if(col == 'DEPT') {
                    this.setState({
                        deptList : res.data.result
                    }, () => {
                        console.log(this.state.deptList)
                    })
                }
                else if(col == 'PC') {
                    this.setState({
                        pcList : res.data.result
                    }, () => {
                        console.log(this.state.pcList)
                    })
                }
                

            }
        })
        .catch((error) => {
            console.log(error);
        });
    }
    handleYearClick = (year) => {
        console.log('obejcccccccccccc', year);
        this.setState({ selectedYear: year });
        this.props.updateProfileYear(year);
        
        // Disable the select input if the year is 2021
        if (year == 2019 || year == 2020 || year == 2021 ) {
            console.log('working 1')
            
            document.getElementById('year').disabled = true;
            document.getElementById('main_groupid').disabled = true;
        } else {
            console.log('working 2')
            document.getElementById('year').disabled = false;
            document.getElementById('main_groupid').disabled = false;

        }
    
        var elements = document.querySelectorAll('.selectedyear');
                if (elements.length > 0) {
            var lastElement = elements[elements.length - 1];
            if (lastElement.children.length > 0) {
                lastElement.children[0].style.borderTop = '';
            }
        }
    }
    render() {
        const adminData = getAdminUserData(); // user which is logged in usually ad admin user here.
        // const { userData } = this.props; // empData to review and update the details is required by admin.
        const { userData, activeTab, userSupervisorData, userReportingData, highlights, 
            employeeId, employeeIdError, employeeIdString, isLoading, employeeIdSub, 
            employeeIdErrorSub, employeeIdStringSub, isLoadingSub,mainGroupsDataArr,selectedYear, yearWiseDataArr  } = this.state;
        
        console.log('adminData:',adminData)
        try {
            var  handleEditEmp =  adminData.admin.can_editemp == 0 ? 'disabled' : '';

            var handleEditSubOrds =  adminData.admin.can_editsubords == 0 ? 'disabled' : '';

            var handleRPL =  adminData.admin.can_editrpl == 0 ? 'disabled' : '';

            var handleEditSup =  adminData.admin.can_editsup == 0 ? 'disabled' : '';
            
            var lockReports =  adminData.admin.can_viewreports == 0 ? 'disabled' : '';
        }
        catch(err) {
            var  handleEditEmp =   '';

            var handleEditSubOrds =   '';

            var handleRPL =  '';

            var handleEditSup =   '';
            
            var lockReports =   '';
        }
        // console.log('handleEditEmp:',adminData);

        return (
        <div className="ProfileComponentEmployee" >
        

            <div>

                <Container fluid>

                    <Nav tabs>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: activeTab === 'p' })}
                                onClick={() => { this.toggleTab('p'); }} // profile
                            >
                                {userData.NAME}
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: activeTab === 'jd' })}
                                onClick={() => { this.toggleTab('jd'); }} // job description
                            >
                                Job Description
                            </NavLink>
                        </NavItem>
                        {/* <NavItem>
                            <NavLink
                                className={classnames({ active: activeTab === 'am' })}
                                onClick={() => { this.toggleTab('am'); }} // assessment management
                            >
                                Assessment Management
                            </NavLink>
                        </NavItem> */}
                        <NavItem>
                            <NavLink
                                disabled={lockReports}
                                className={classnames({ active: activeTab === 'sc' })}
                                onClick={() => { this.toggleTab('sc'); }} // score card
                            >
                                Score Card
                            </NavLink>
                        </NavItem>
                    </Nav>
                    <TabContent activeTab={activeTab}>
                        <TabPane tabId="p">

                            <Container>
                                <Form>
                                    <Row style={{padding: '6vh 3vw', textAlign: 'left'}}>

                                        <Col md={{size : 12}} style={{paddingBottom: '30px'}}>
                                            <Alert color="primary" className="alertMessage">
                                                Please review the profile of {userData.NAME} and click on the respective field to update information. Click Update &nbsp;<FontAwesomeIcon icon={faCheck} size="1x" />&nbsp;&nbsp; to save changes for each field.
                                            </Alert>
                                        </Col>

                                        <Col md={{size: 4}}>
                                            <FormGroup className={highlights.FIRST_NAME ? 'changed' : null}>
                                                <FontAwesomeIcon className="updateIcon" icon={faCheck} size="1x" 
                                                    onClick={(e) => this.selectUpdate('FIRST_NAME', userData.FIRST_NAME)}
                                                />
                                                <Label for="FIRST_NAME">First name</Label>
                                                <Input disabled={handleEditEmp} type="text" name="FIRST_NAME" id="FIRST_NAME" placeholder="" value={userData.FIRST_NAME} onChange={(e) => this.updateValue(e)} />
                                            </FormGroup>
                                        </Col>
                                        <Col md={{size: 4}}>
                                            <FormGroup className={highlights.MI ? 'changed' : null}>
                                                <FontAwesomeIcon className="updateIcon" icon={faCheck} size="1x" 
                                                    onClick={(e) => this.selectUpdate('MI', userData.MI)}
                                                />
                                                <Label for="MI">MI</Label>
                                                <Input disabled={handleEditEmp} type="text" name="MI" id="MI" placeholder="" value={userData.MI}  onChange={(e) => this.updateValue(e)}/>
                                            </FormGroup>
                                        </Col>
                                        <Col md={{size: 4}}>
                                            <FormGroup className={highlights.LAST_NAME ? 'changed' : null}>
                                                <FontAwesomeIcon className="updateIcon" icon={faCheck} size="1x" 
                                                    onClick={(e) => this.selectUpdate('LAST_NAME', userData.LAST_NAME)}
                                                />
                                                <Label for="LAST_NAME">Last name</Label>
                                                <Input disabled={handleEditEmp} type="text" name="LAST_NAME" id="LAST_NAME" placeholder="" value={userData.LAST_NAME}  onChange={(e) => this.updateValue(e)}/>
                                            </FormGroup>
                                        </Col>
                                        <Col md={{size: 12}}>
                                            <FormGroup>
                                                <Label for="ID_NUMBER">Employee ID</Label>
                                                <Input type="text" name="ID_NUMBER" id="ID_NUMBER" placeholder="" value={userData.ID_NUMBER} disabled />
                                            </FormGroup>
                                        </Col>
                                        
                                        <Col md={{size: 12}}>
                                            <FormGroup className={highlights.email ? 'changed' : null}>
                                                <FontAwesomeIcon className="updateIcon" icon={faCheck} size="1x" 
                                                    onClick={(e) => this.selectUpdate('email', userData.email)}
                                                />
                                                <Label for="email">Email id</Label>
                                                <Input disabled={handleEditEmp} type="text" name="email" id="email" placeholder="" value={userData.email}  onChange={(e) => this.updateValue(e)}/>
                                            </FormGroup>
                                        </Col>
                                        <Col md={{size: 12}}>
                                            <Label for="Years">Employee years wise groups details</Label>
                                            {
                                                this.state.yearWiseDataLoading == true ? 
                                                <div style={{
                                                    marginTop: -35,
                                                    marginBottom: 10,
                                                    marginLeft: 255
                                                }}>
                                                    <Spinner color="primary" />
                                                </div>
                                                :null
                                            }
                                            <Form>
                                            {/* <Label for="years" style={{textAlign:'center'}}>years</Label> */}
                                                <FormGroup>
                                                    <Row>
                                                        {
                                                            this.state.years.length > 0 && Object.keys(this.state.yearWiseDataArr).length && this.state.years.map((year, key) => 
                                                                <Col className='selectedyear' onClick={() => this.handleYearClick(year)} >{year}
                                                                    <div style={{borderTop: selectedYear === year ?'2px solid black' : ''}} disabled={handleEditEmp}>
                                                                        {
                                                                        this.state.yearWiseDataArr[year] !=null &&     
                                                                        typeof this.state.yearWiseDataArr[year] !=="undefined" && 
                                                                        typeof this.state.yearWiseDataArr[year][0] !=="undefined" && 
                                                                        typeof this.state.yearWiseDataArr[year][0].group_name !=="undefined" && 
                                                                        this.state.yearWiseDataArr[year][0].group_name !=null ?     
                                                                        <Fragment>
                                                                            <p>Main Group : {this.state.yearWiseDataArr[year][0].group_name}</p>
                                                                            <br/>
                                                                        </Fragment>
                                                                        :null
                                                                        }
                                                                        {
                                                                        this.state.yearWiseDataArr[year] !=null && 
                                                                        typeof this.state.yearWiseDataArr[year] !=="undefined" && 
                                                                        typeof this.state.yearWiseDataArr[year][0] !=="undefined"&&
                                                                        typeof this.state.yearWiseDataArr[year][0].sub_groupname !=="undefined"&&    
                                                                        this.state.yearWiseDataArr[year][0].sub_groupname !=null ? 
                                                                        <Fragment>
                                                                            <p>Sub Group : {this.state.yearWiseDataArr[year][0].sub_groupname}</p>
                                                                            <br/>
                                                                        </Fragment>
                                                                        :null
                                                                        }
                                                                        {
                                                                        this.state.yearWiseDataArr[year] !=null && 
                                                                        typeof this.state.yearWiseDataArr[year] !=="undefined" && 
                                                                        typeof this.state.yearWiseDataArr[year][0] !=="undefined" &&
                                                                        typeof this.state.yearWiseDataArr[year][0].dept_name !=="undefined" && 
                                                                        this.state.yearWiseDataArr[year][0].dept_name !=null ?
                                                                        <Fragment>
                                                                             <p>Department : {this.state.yearWiseDataArr[year][0].dept_name}</p>
                                                                        </Fragment>    
                                                                       :null
                                                                       }
                                                                       {
                                                                        this.state.yearWiseDataArr[year] !=null && 
                                                                        typeof this.state.yearWiseDataArr[year] !=="undefined" && 
                                                                        typeof this.state.yearWiseDataArr[year][0] !=="undefined" && 
                                                                        typeof this.state.yearWiseDataArr[year][0].location_name !=="undefined" &&   
                                                                        this.state.yearWiseDataArr[year][0].location_name !=null ?
                                                                        <Fragment>
                                                                             <p>Location : {this.state.yearWiseDataArr[year][0].location_name}</p>
                                                                        </Fragment>    
                                                                       :null
                                                                       }
                                                                       {
                                                                        this.state.yearWiseDataArr[year] !=null && 
                                                                        typeof this.state.yearWiseDataArr[year] !=="undefined" && 
                                                                        typeof this.state.yearWiseDataArr[year][0] !=="undefined" &&
                                                                        typeof this.state.yearWiseDataArr[year][0].satellite_name !=="undefined" &&   
                                                                        this.state.yearWiseDataArr[year][0].satellite_name !=null ?
                                                                        <Fragment>
                                                                             <p>Satellite : {this.state.yearWiseDataArr[year][0].satellite_name}</p>
                                                                        </Fragment>    
                                                                       :null
                                                                       }
                                                                    </div>
                                                                </Col>
                                                                
                                                            )
                                                        }
                                                    </Row>
                                                    
                                                </FormGroup>
                                            </Form>
                                        </Col>

                                        <Col md={{size: 12}}>
                                            <FormGroup className={highlights.BRANCH ? 'changed' : null}>
                                                <FontAwesomeIcon className="updateIcon" icon={faCheck} size="1x" 
                                                    onClick={(e) => this.selectUpdate('BRANCH', userData.BRANCH)}
                                                />
                                                <Label for="Years">Years</Label>
                                                {/* working on it */}
                                                
                                                <Input  type="select" name="year" id="year" onChange={(e) => this.changeYears(e)}>
                                                    {console.log("Year### ", this.selectedYear)}
                                                    <option value="select" key={0} disabled={handleEditEmp}>Select</option>
                                                        {
                                                            this.state.years.length > 0 && this.state.years.map((year, key) => 
                                                                <>
                                                               { year==2024?  (<option
                                                                    key={key}
                                                                    value={year}
                                                                    
                                                                >
                                                                    {year}
                                                                </option> ) : null
                                                                   }                                                          
                                                                </>
                                                            )

                                                        }
                                                </Input>
                                            
                                            </FormGroup>
                                        </Col>
                                        <Col md={{size: 12}}>
                                            <FormGroup className={highlights.BRANCH ? 'changed' : null}>
                                                <FontAwesomeIcon className="updateIcon" icon={faCheck} size="1x" 
                                                    onClick={(e) => this.selectUpdate('BRANCH', userData.BRANCH)}
                                                />
                                                <Label for="Main Groups">Main Groups</Label>
                                                
                                                <Input  type="select" name="main_groupid" id="main_groupid" onChange={(e) => this.fetchRelatedData(e)} disabled={handleEditEmp}>
                                                    <option value="select" key={0} disabled={handleEditEmp}>Select</option>
                                                        {

                                                            mainGroupsDataArr.length > 0 && mainGroupsDataArr.map((data, key) => 
                                                            
                                                                <option
                                                                    key={key}
                                                                    value={data.group_id}
                                                                    
                                                                >
                                                                    {data.group_name}
                                                                </option>
                                                            )

                                                        }
                                                </Input>
                                                {
                                                    this.state.ddLoader1 == true ? 
                                                        <div style={{float:'right', marginTop:'-70px'}} disabled={handleEditEmp}><Spinner color="primary"  size='small' /></div>
                                                    :null
                                                }
                                            </FormGroup>
                                        </Col>
                                        {
                                        this.state.subGroupDataArr.length > 0 && this.state.relatedDataType == "sub_groups" ?
                                        <Col md={{size: 12}}>
                                            <FormGroup className={highlights.DESIGNATION ? 'changed' : null}>
                                                <FontAwesomeIcon className="updateIcon" icon={faCheck} size="1x" 
                                                    onClick={(e) => this.selectUpdate('DESIGNATION', userData.DESIGNATION)}
                                                />
                                                <Label for="SubGroups">Sub Groups</Label>
                                                
                                                <Input disabled={handleEditEmp} type="select" name="sub_groupid" id="sub_groupid" onChange={(e) => this.fetchSubGroupDepartments(e)}>
                                                    <option value="select" key={0}>Select</option>
                                                    {
                                                        this.state.subGroupDataArr.map((data, key) => 
                                                            <option
                                                                key={key}
                                                                value={data.sub_groupid}
                                                            >
                                                            {data.sub_groupname}
                                                            </option>
                                                        )

                                                    }
                                                </Input>
                                                {
                                                    this.state.ddLoader2 == true ? 
                                                        <div style={{float:'right', marginTop:'-70px'}} disabled={handleEditEmp}><Spinner color="primary"  size='small' /></div>
                                                    :null
                                                }
                                            </FormGroup>
                                        </Col>
                                        :null
                                        }
                                        {
                                        this.state.departmentsArr.length > 0  ?
                                        <Col md={{size: 12}}>
                                            <FormGroup className={highlights.DESIGNATION ? 'changed' : null}>
                                                <FontAwesomeIcon className="updateIcon" icon={faCheck} size="1x" 
                                                    onClick={(e) => this.selectUpdate('DESIGNATION', userData.DESIGNATION)}
                                                />
                                                <Label for="SubGroups">Departments</Label>
                                                
                                                <Input disabled={handleEditEmp} type="select" name="dept_id" id="dept_id" onChange={(e) => this.fetchDivisions(e)}>
                                                    <option value="select" key={0}>Select</option>
                                                   {
                                                        this.state.departmentsArr.map((data, key) => 
                                                            <option
                                                                key={key}
                                                                value={data.dept_id}
                                                            >
                                                            {data.dept_name} - {data.dept_suffix}
                                                            </option>
                                                        )

                                                    }
                                                </Input>
                                                {
                                                    this.state.divLoader == true ? 
                                                        <div style={{float:'right', marginTop:'-70px'}}><Spinner color="primary"  size='small' /></div>
                                                    :null
                                                }
                                            </FormGroup>
                                        </Col>
                                        :null
                                        }
                                        {
                                        this.state.divisionsArr.length > 0 ?
                                        <Col md={{size: 12}}>
                                            <FormGroup className={highlights.DESIGNATION ? 'changed' : null}>
                                                <FontAwesomeIcon className="updateIcon" icon={faCheck} size="1x" 
                                                    onClick={(e) => this.selectUpdate('DESIGNATION', userData.DESIGNATION)}
                                                />
                                                <Label for="SubGroups">Divisions</Label>
                                                
                                                <Input disabled={handleEditEmp} type="select" name="division_id" id="division_id" onChange={(e) => this.fetchSections(e)}>
                                                    <option value="select" key={0}>Select</option>
                                                    {
                                                        this.state.divisionsArr.map((data, key) => 
                                                            <option
                                                                key={key}
                                                                value={data.division_id}
                                                            >
                                                            {data.division_name}
                                                            </option>
                                                        )

                                                    }
                                                </Input>
                                                {
                                                    this.state.sectionLoader == true ? 
                                                        <div style={{float:'right', marginTop:'-70px'}}><Spinner color="primary"  size='small' /></div>
                                                    :null
                                                }
                                            </FormGroup>
                                            
                                        </Col>
                                        :null
                                        }
                                        {
                                        this.state.sectionsArr.length > 0 ? 
                                        <Col md={{size: 12}}>
                                            <FormGroup className={highlights.DESIGNATION ? 'changed' : null}>
                                                <FontAwesomeIcon className="updateIcon" icon={faCheck} size="1x" 
                                                    onClick={(e) => this.selectUpdate('DESIGNATION', userData.DESIGNATION)}
                                                />
                                                <Label for="SubGroups">Sections</Label>
                                                
                                                <Input disabled={handleEditEmp} type="select" name="section_id" id="section_id" onChange={(e) => this.fetchPayClass(e)}>
                                                    <option value="select" key={0}>Select</option>
                                                    {
                                                        this.state.sectionsArr.map((data, key) => 
                                                            <option
                                                                key={key}
                                                                value={data.section_id}
                                                            >
                                                            {data.section_name}
                                                            </option>
                                                        )

                                                    }
                                                </Input>
                                                {
                                                    this.state.pcLoader == true ? 
                                                        <div style={{float:'right', marginTop:'-70px'}}><Spinner color="primary"  size='small' /></div>
                                                    :null
                                                }
                                            </FormGroup>
                                            
                                        </Col>
                                        :null
                                        }

                                        {
                                        this.state.pcDataArr.length > 0 ? 
                                        <Col md={{size: 12}}>
                                            <FormGroup className={highlights.DESIGNATION ? 'changed' : null}>
                                                <FontAwesomeIcon className="updateIcon" icon={faCheck} size="1x" 
                                                    onClick={(e) => this.selectUpdate('DESIGNATION', userData.DESIGNATION)}
                                                />
                                                <Label for="SubGroups">Job Grade</Label>
                                                
                                                <Input disabled={handleEditEmp} type="select" name="pc_id" id="pc_id" onChange={(e) => this.fetchDesignation(e)}>
                                                    <option value="select" key={0}>Select</option>
                                                    {
                                                        this.state.pcDataArr.map((data, key) => 
                                                            <option
                                                                key={key}
                                                                value={data.pc_id}
                                                            >
                                                            {data.pc_name}
                                                            </option>
                                                        )

                                                    }
                                                </Input>
                                                {
                                                    this.state.desigLoader == true ? 
                                                        <div style={{float:'right', marginTop:'-70px'}}><Spinner color="primary"  size='small' /></div>
                                                    :null
                                                }
                                            </FormGroup>
                                            
                                        </Col>
                                        :null
                                        }

                                        {
                                        this.state.desigArr.length > 0 ? 
                                        <Col md={{size: 12}}>
                                            <FormGroup className={highlights.DESIGNATION ? 'changed' : null}>
                                                <FontAwesomeIcon className="updateIcon" icon={faCheck} size="1x" 
                                                    onClick={(e) => this.selectUpdate('DESIGNATION', userData.DESIGNATION)}
                                                />
                                                <Label for="SubGroups">Designation</Label>
                                                
                                                <Input disabled={handleEditEmp} type="select" name="desig_id" id="desig_id" onChange={(e) => {
                                                    const {value,indx} = e.target
                                                    if (value !="select") {
                                                        this.setState({desigId:value})
                                                    }
                                                }}>
                                                    <option value="select" key={0}>Select</option>
                                                    {
                                                        this.state.desigArr.map((data, key) => 
                                                            <option
                                                                key={key}
                                                                value={data.desig_id}
                                                            >
                                                            {data.desig_name}
                                                            </option>
                                                        )

                                                    }
                                                </Input>
                                               
                                            </FormGroup>
                                             <div style={{float:'right', marginBottom:'10px',marginTop:"-5px",marginRight:"-2px"}}>
                                                
                                                <Button color="success" onClick={()=>{
                                                    const {mainGroup,subGroup,department,userData,year,divId,sectionId,pcId,desigId} = this.state;
                                                   
                                                    if(year == "") {
                                                        alert('Please select the year.');
                                                        return false;
                                                    }
                                                    // else if(mainGroup == "") {
                                                    //     alert('Please select the main group.');
                                                    //     return false;
                                                    // }
                                                    // else if(subGroup == "") {
                                                    //     alert('Please select the sub  group.');
                                                    //     return false;
                                                    // }
                                                    // else if(department == "") {
                                                    //     alert('Please select the department.');
                                                    //     return false;
                                                    // }
                                                    // else if(divId == "") {
                                                    //     alert('Please select the division.');
                                                    //     return false;
                                                    // }
                                                    // else if(sectionId == "" && sectionId ) {
                                                    //     alert('Please select the section.');
                                                    //     return false;
                                                    // }
                                                    else if(desigId == 0) {
                                                        alert('Please select the designation.');
                                                        return false;
                                                    }
                                                    else {
                                                        if (window.confirm("This user's score will reset, do you really want update...?")) {
                                                            this.setState({updateBtnLoader:true});
                                                            let param = {
                                                                "emp_id"        : userData.ID_NUMBER,
                                                                "main_group"    : mainGroup,
                                                                "year"          : year,
                                                                "sub_groupid"   : subGroup,
                                                                "desig_id"      : desigId,
                                                                "section_id"    : sectionId,
                                                                "division_id"   : divId,
                                                                "pc_id"         : pcId,
                                                                "dept_id"       : department,
                                                                "type"          : 1
                                                            }
                                                            let that = this;
                                                            this.props.updateGroupData(param,(res)=>{
                                                                that.setState({updateBtnLoader:false});
                                                                if(typeof res === 'object' && typeof res.result === "object" && res.result.hasOwnProperty('message')) {
                                                                    console.log('here is the res ',res.result);
                                                                    alert(res.result.message)
                                                                    return false;
                                                                }
                                                                else
                                                                    alert('Record has been updated.');
                                                            }) 
                                                        }
                                                       
                                                    }
                                                }}>Update
                                                {
                                                    this.state.updateBtnLoader == true ? <Spinner color="light"  size='small' />:null
                                                }
                                                </Button>
                                            </div>
                                        </Col>
                                        :null
                                        }
                                        
                                        {
                                        this.state.locationsArr.length > 0 && this.state.relatedDataType == "locations" ?     
                                        <Col md={{size: 12}}>
                                            <FormGroup className={highlights.BRANCH ? 'changed' : null}>
                                                <FontAwesomeIcon className="updateIcon" icon={faCheck} size="1x" 
                                                    onClick={(e) => this.selectUpdate('BRANCH', userData.BRANCH)}
                                                />
                                                <Label for="BRANCH">Locations</Label>
                                                {/* <Input type="text" name="BRANCH" id="BRANCH" placeholder="" value={userData.BRANCH} onChange={(e) => this.updateValue(e)} />  */}
                                                    <Input disabled={handleEditEmp} type="select" name="location" id="location" onChange={(e) => this.fetchLocationDivisions(e)}>
                                                        <option value="select" key={0}>Select</option>
                                                        {

                                                            this.state.locationsArr.map((data, key) => 
                                                                <option
                                                                    key={key}
                                                                    value={data.location_id}
                                                                
                                                                >
                                                                    {data.location_name}
                                                                </option>
                                                            )

                                                        }
                                                    </Input>
                                                    {
                                                    this.state.locationDivLoader == true ? 
                                                        <div style={{float:'right', marginTop:'-70px'}}><Spinner color="primary"  size='small' /></div>
                                                    :null
                                                }
                                            </FormGroup>
                                            
                                        </Col>
                                        :null
                                        }
                                        
                                        {
                                        this.state.locationDivArr.length > 0 ? 
                                            <Col md={{size: 12}}>
                                                <FormGroup className={highlights.BRANCH ? 'changed' : null}>
                                                    <FontAwesomeIcon className="updateIcon" icon={faCheck} size="1x" 
                                                        onClick={(e) => this.selectUpdate('BRANCH', userData.BRANCH)}
                                                    />
                                                    <Label for="BRANCH">Divisions</Label>
                                                    {/* <Input type="text" name="BRANCH" id="BRANCH" placeholder="" value={userData.BRANCH} onChange={(e) => this.updateValue(e)} />  */}
                                                        <Input disabled={handleEditEmp} type="select" name="location" id="location" onChange={(e) => this.fetchLocationSections(e)}>
                                                            <option value="select" key={0}>Select</option>
                                                            {

                                                                this.state.locationDivArr.map((data, key) => 
                                                                    <option
                                                                        key={key}
                                                                        value={data.division_id}
                                                                    
                                                                    >
                                                                        {data.division_name}
                                                                    </option>
                                                                )

                                                            }
                                                        </Input>
                                                        {
                                                        this.state.locationSectionLoader == true ? 
                                                            <div style={{float:'right', marginTop:'-70px'}}><Spinner color="primary"  size='small' /></div>
                                                        :null
                                                    }
                                                </FormGroup>
                                            </Col>
                                        : null
                                        }

                                        {
                                        this.state.locationSectionArr.length > 0 ? 
                                            <Col md={{size: 12}}>
                                                <FormGroup className={highlights.BRANCH ? 'changed' : null}>
                                                    <FontAwesomeIcon className="updateIcon" icon={faCheck} size="1x" 
                                                        onClick={(e) => this.selectUpdate('BRANCH', userData.BRANCH)}
                                                    />
                                                    <Label for="BRANCH">Sections</Label>
                                                    {/* <Input type="text" name="BRANCH" id="BRANCH" placeholder="" value={userData.BRANCH} onChange={(e) => this.updateValue(e)} />  */}
                                                        <Input disabled={handleEditEmp} type="select" name="location" id="location" onChange={(e) => this.fetchLocationPC(e)}>
                                                            <option value="select" key={0}>Select</option>
                                                            {

                                                                this.state.locationSectionArr.map((data, key) => 
                                                                    <option
                                                                        key={key}
                                                                        value={data.section_id}
                                                                    
                                                                    >
                                                                        {data.section_name}
                                                                    </option>
                                                                )

                                                            }
                                                        </Input>
                                                        {
                                                        this.state.locationPCLoader == true ? 
                                                            <div style={{float:'right', marginTop:'-70px'}}><Spinner color="primary"  size='small' /></div>
                                                        :null
                                                    }
                                                </FormGroup>
                                            </Col>
                                        : null
                                        }

                                        {
                                        this.state.locationPCArr.length > 0 ? 
                                            <Col md={{size: 12}}>
                                                <FormGroup className={highlights.BRANCH ? 'changed' : null}>
                                                    <FontAwesomeIcon className="updateIcon" icon={faCheck} size="1x" 
                                                        onClick={(e) => this.selectUpdate('BRANCH', userData.BRANCH)}
                                                    />
                                                    <Label for="BRANCH">Job Grade</Label>
                                                    {/* <Input type="text" name="BRANCH" id="BRANCH" placeholder="" value={userData.BRANCH} onChange={(e) => this.updateValue(e)} />  */}
                                                        <Input disabled={handleEditEmp} type="select" name="location" id="location" onChange={(e) => this.fetchLocationDesignations(e)}>
                                                            <option value="select" key={0}>Select</option>
                                                            {

                                                                this.state.locationPCArr.map((data, key) => 
                                                                    <option
                                                                        key={key}
                                                                        value={data.pc_id}
                                                                    
                                                                    >
                                                                        {data.pc_name}
                                                                    </option>
                                                                )

                                                            }
                                                        </Input>
                                                        {
                                                        this.state.locationDesigLoader == true ? 
                                                            <div style={{float:'right', marginTop:'-70px'}}><Spinner color="primary"  size='small' /></div>
                                                        :null
                                                    }
                                                </FormGroup>
                                            </Col>
                                        : null
                                        }

                                        {
                                        this.state.locationDesigArr.length > 0 ? 
                                            <Col md={{size: 12}}>
                                                <FormGroup className={highlights.BRANCH ? 'changed' : null}>
                                                    <FontAwesomeIcon className="updateIcon" icon={faCheck} size="1x" 
                                                        onClick={(e) => this.selectUpdate('BRANCH', userData.BRANCH)}
                                                    />
                                                    <Label for="BRANCH">Designations</Label>
                                                    {/* <Input type="text" name="BRANCH" id="BRANCH" placeholder="" value={userData.BRANCH} onChange={(e) => this.updateValue(e)} />  */}
                                                        <Input disabled={handleEditEmp} type="select" name="location" id="location" onChange={(e) => {
                                                            const{value,index} = e.target
                                                            if (value !=0) {
                                                                this.setState({locationDesigId:value})
                                                            }
                                                        }}>
                                                            <option value="select" key={0}>Select</option>
                                                            {

                                                                this.state.locationDesigArr.map((data, key) => 
                                                                    <option
                                                                        key={key}
                                                                        value={data.desig_id}
                                                                    
                                                                    >
                                                                        {data.desig_name}
                                                                    </option>
                                                                )

                                                            }
                                                        </Input>
                                                       
                                                </FormGroup>
                                              
                                                <div style={{float:'right', marginBottom:'10px',marginTop:"0px",marginRight:"0px"}}>
                                                
                                                    <Button disabled={handleEditEmp} color="success" onClick={()=>{
                                                        const {mainGroup,userData,year
                                                            ,location,locationDivId,locationSectionId,locationPCId,locationDesigId} = this.state;
                                                        if(year == "") {
                                                            alert('Please select the year.');
                                                            return false;
                                                        }
                                                        // else if(mainGroup == "") {
                                                        //     alert('Please select the main group.');
                                                        //     return false;
                                                        // }
                                                        else if(locationDesigId == "0") {
                                                            alert('Please select the Designation.');
                                                            return false;
                                                        }
                                                        else {
                                                           
                                                            if (window.confirm("This user's score will reset, do you really want update...?")) {
                                                                this.setState({updateBtnLoader:true});
                                                                let param = {
                                                                    "emp_id"        : userData.ID_NUMBER,
                                                                    "main_group"    : mainGroup,
                                                                    "year"          : year,
                                                                    "location"      : location,
                                                                    "desig_id"      : locationDesigId,
                                                                    "section_id"    : locationSectionId,
                                                                    "division_id"   : locationDivId,
                                                                    "pc_id"         : locationPCId,
                                                                    "type"          : 2
                                                                }
                                                                let that = this;
                                                                this.props.updateGroupData(param,(res) => {
                                                                    console.log('here is the res:',res);
                                                                    that.setState({updateBtnLoader:false});
                                                                    if(typeof res === 'object' && typeof res.result === "object" && res.result.hasOwnProperty('message')) {
                                                                        console.log('here is the res ',res.result);
                                                                        alert(res.result.message)
                                                                        return false;
                                                                    }
                                                                    else
                                                                    alert('Record has been updated.');
                                                                })
                                                            }
                                                        }
                                                    }}>Update
                                                    {
                                                        this.state.updateBtnLoader == true ? <Spinner color="light"  size='small' />:null
                                                    }
                                                    </Button>
                                                </div>
                                                
                                            </Col>
                                        : null
                                        }


                                        {
                                        this.state.branchesArr.length > 0 ?     
                                            <Col md={{size: 12}}>
                                                <FormGroup className={highlights.DEPT ? 'changed' : null}>
                                                    <FontAwesomeIcon className="updateIcon" icon={faCheck} size="1x" 
                                                        onClick={(e) => this.selectUpdate('DEPT', userData.DEPT)}
                                                    />
                                                    <Label for="DEPT">Branches</Label>
                                                    {/*<Input type="text" name="DEPT" id="DEPT" placeholder="" value={userData.DEPT} onChange={(e) => this.updateValue(e)} /> */}
                                                    
                                                        <Input disabled={handleEditEmp} type="select" name="branch" id="branch" onChange={(e) => this.fetchLocationBrachDivisions(e)}>
                                                            <option value="select" key={0}>Select</option>
                                                            {

                                                                this.state.branchesArr.map((data, key) => 
                                                                    <option
                                                                        key={key}
                                                                        value={data.branch_id}
                                                                    >
                                                                        {data.branch_name}
                                                                    </option>
                                                                )

                                                            }
                                                        </Input>
                                                        {
                                                            this.state.locationBranchDivLoader == true ? 
                                                                <div style={{float:'right', marginTop:'-70px'}}><Spinner color="primary"  size='small' /></div>
                                                            :null
                                                        }
                                                        
                                                </FormGroup>
                                               
                                            </Col>
                                            :null
                                        }

                                        {
                                        this.state.locationBranchDivArr.length > 0 ?     
                                            <Col md={{size: 12}}>
                                                <FormGroup className={highlights.DEPT ? 'changed' : null}>
                                                    <FontAwesomeIcon className="updateIcon" icon={faCheck} size="1x" 
                                                        onClick={(e) => this.selectUpdate('DEPT', userData.DEPT)}
                                                    />
                                                    <Label for="DEPT">Divisions</Label>
                                                    {/*<Input type="text" name="DEPT" id="DEPT" placeholder="" value={userData.DEPT} onChange={(e) => this.updateValue(e)} /> */}
                                                    
                                                        <Input disabled={handleEditEmp} type="select" name="branch" id="branch" onChange={(e) => this.fetchLocationBrachSections(e)}>
                                                            <option value="select" key={0}>Select</option>
                                                            {

                                                                this.state.locationBranchDivArr.map((data, key) => 
                                                                    <option
                                                                        key={key}
                                                                        value={data.division_id}
                                                                    >
                                                                        {data.division_name}
                                                                    </option>
                                                                )

                                                            }
                                                        </Input>
                                                        {
                                                            this.state.locationBranchSectionLoader == true ? 
                                                                <div style={{float:'right', marginTop:'-70px'}}><Spinner color="primary"  size='small' /></div>
                                                            :null
                                                        }
                                                        
                                                </FormGroup>
                                                
                                            </Col>
                                            :null
                                        }
                                        {
                                        this.state.locationBranchSectionArr.length > 0 ? 
                                            <Col md={{size: 12}}>
                                                <FormGroup className={highlights.DEPT ? 'changed' : null}>
                                                    <FontAwesomeIcon className="updateIcon" icon={faCheck} size="1x" 
                                                        onClick={(e) => this.selectUpdate('DEPT', userData.DEPT)}
                                                    />
                                                    <Label for="DEPT">Sections</Label>
                                                    {/*<Input type="text" name="DEPT" id="DEPT" placeholder="" value={userData.DEPT} onChange={(e) => this.updateValue(e)} /> */}
                                                    
                                                        <Input disabled={handleEditEmp} type="select" name="branch" id="branch" onChange={(e) => this.fetchLocationBrachPC(e)}>
                                                            <option value="select" key={0}>Select</option>
                                                            {

                                                                this.state.locationBranchSectionArr.map((data, key) => 
                                                                    <option
                                                                        key={key}
                                                                        value={data.section_id}
                                                                    >
                                                                        {data.section_name}
                                                                    </option>
                                                                )

                                                            }
                                                        </Input>
                                                        {
                                                            this.state.locationBranchPCLoader == true ? 
                                                                <div style={{float:'right', marginTop:'-70px'}}><Spinner color="primary"  size='small' /></div>
                                                            :null
                                                        }
                                                        
                                                </FormGroup>
                                                
                                            </Col>
                                        :null
                                        }

                                        {
                                        this.state.locationBranchPCArr.length > 0 ? 
                                            <Col md={{size: 12}}>
                                                <FormGroup className={highlights.DEPT ? 'changed' : null}>
                                                    <FontAwesomeIcon className="updateIcon" icon={faCheck} size="1x" 
                                                        onClick={(e) => this.selectUpdate('DEPT', userData.DEPT)}
                                                    />
                                                    <Label for="DEPT">Job Grade</Label>
                                                    {/*<Input type="text" name="DEPT" id="DEPT" placeholder="" value={userData.DEPT} onChange={(e) => this.updateValue(e)} /> */}
                                                    
                                                        <Input disabled={handleEditEmp} type="select" name="branch" id="branch" onChange={(e) => this.fetchLocationBrachDesig(e)}>
                                                            <option value="select" key={0}>Select</option>
                                                            {

                                                                this.state.locationBranchPCArr.map((data, key) => 
                                                                    <option
                                                                        key={key}
                                                                        value={data.pc_id}
                                                                    >
                                                                        {data.pc_name}
                                                                    </option>
                                                                )

                                                            }
                                                        </Input>
                                                        {
                                                            this.state.locationBranchDesigLoader == true ? 
                                                                <div style={{float:'right', marginTop:'-70px'}}><Spinner color="primary"  size='small' /></div>
                                                            :null
                                                        }
                                                        
                                                </FormGroup>
                                                
                                            </Col>
                                        :null
                                        }

                                        {
                                        this.state.locationBranchDesigArr.length > 0 ? 
                                            <Col md={{size: 12}}>
                                                <FormGroup className={highlights.DEPT ? 'changed' : null}>
                                                    <FontAwesomeIcon className="updateIcon" icon={faCheck} size="1x" 
                                                        onClick={(e) => this.selectUpdate('DEPT', userData.DEPT)}
                                                    />
                                                    <Label for="DEPT">Designations</Label>
                                                    {/*<Input type="text" name="DEPT" id="DEPT" placeholder="" value={userData.DEPT} onChange={(e) => this.updateValue(e)} /> */}
                                                    
                                                        <Input disabled={handleEditEmp} type="select" name="branch" id="branch" onChange={(e) => {
                                                            const{value} = e.target
                                                            if (value !="0") {
                                                                this.setState({locationBranchDesigId : value})
                                                            }
                                                        }}>
                                                            <option value="select" key={0}>Select</option>
                                                            {

                                                                this.state.locationBranchDesigArr.map((data, key) => 
                                                                    <option
                                                                        key={key}
                                                                        value={data.desig_id}
                                                                    >
                                                                        {data.desig_name}
                                                                    </option>
                                                                )

                                                            }
                                                        </Input>
                                                        <div style={{float:'right', marginBottom:'10px',marginTop:"20px",marginRight:"-10px"}}>
                                                        <Button disabled={handleEditEmp} color="success" onClick={()=>{
                                                            const {
                                                                mainGroup,userData,year,
                                                                location,locationBranchId,locationBranchDivId,
                                                                locationBranchSectionId,locationBranchPCId,
                                                                locationBranchDesigId
                                                            } = this.state;
                                                            if(year == "") {
                                                                alert('Please select the year.');
                                                                return false;
                                                            }
                                                            // else if(mainGroup == "") {
                                                            //     alert('Please select the main group.');
                                                            //     return false;
                                                            // }
                                                            else if(locationBranchDesigId == "0") {
                                                                alert('Please select the Designation.');
                                                                return false;
                                                            }
                                                            else {
                                                                
                                                                if (window.confirm("This user's score will reset, do you really want update...?")) {
                                                                    this.setState({updateBtnLoader:true});
                                                                    let param = {
                                                                        "emp_id"        : userData.ID_NUMBER,
                                                                        "main_group"    : mainGroup,
                                                                        "year"          : year,
                                                                        "location"      : location,
                                                                        "desig_id"      : locationBranchDesigId,
                                                                        "section_id"    : locationBranchSectionId,
                                                                        "division_id"   : locationBranchDivId,
                                                                        "pc_id"         : locationBranchPCId,
                                                                        "branch_id"     : locationBranchId,
                                                                        "type"          : 3
                                                                    }
                                                                    let that = this;
                                                                    this.props.updateGroupData(param,(res) => {
                                                                        console.log('here is the res:',res);
                                                                        that.setState({updateBtnLoader:false});
                                                                        if(typeof res === 'object' && typeof res.result === "object" && res.result.hasOwnProperty('message')) {
                                                                            console.log('here is the res ',res.result);
                                                                            alert(res.result.message)
                                                                            return false;
                                                                        }
                                                                        else
                                                                        alert('Record has been updated.');
                                                                    })
                                                                }
                                                            }
                                                        }}>Update
                                                        {
                                                            this.state.updateBtnLoader == true ? <Spinner color="light"  size='small' />:null
                                                        }
                                                        </Button>
                                                    </div>
                                                </FormGroup>
                                                
                                            </Col>
                                        :null
                                        }
                                        {
                                        this.state.satelliteArr.length > 0 ?     
                                            <Col md={{size: 12}}>
                                                <FormGroup className={highlights.DEPT ? 'changed' : null}>
                                                    <FontAwesomeIcon className="updateIcon" icon={faCheck} size="1x" 
                                                        onClick={(e) => this.selectUpdate('DEPT', userData.DEPT)}
                                                    />
                                                    <Label for="satellite">Satellite</Label>
                                                    {/*<Input type="text" name="DEPT" id="DEPT" placeholder="" value={userData.DEPT} onChange={(e) => this.updateValue(e)} /> */}
                                                    
                                                        <Input disabled={handleEditEmp} type="select" name="branch" id="branch" onChange={(e) => this.satelliteChange(e)}>
                                                            <option value="select" key={0}>Select</option>
                                                            <option value="0" key={"01"}>Other satellite</option>
                                                            {

                                                                this.state.satelliteArr.map((data, key) => 
                                                                    <option
                                                                        key={key}
                                                                        value={data.satellite_id}
                                                                    >
                                                                        {data.satellite_name}
                                                                    </option>
                                                                )

                                                            }
                                                        </Input> 
                                                </FormGroup>
                                                <div style={{float:'right', marginBottom:'10px'}}>
                                                
                                                    <Button color="success" onClick={()=>{
                                                        const {mainGroup,subGroup,department,userData,year,
                                                            location,branch,satellite} = this.state;
                                                        if(year == "") {
                                                            alert('Please select the year.');
                                                            return false;
                                                        }
                                                        else if(mainGroup == "") {
                                                            alert('Please select the main group.');
                                                            return false;
                                                        }
                                                        else if(location == "") {
                                                            alert('Please select the location.');
                                                            return false;
                                                        }
                                                        else if(branch == "") {
                                                            alert('Please select the branch.');
                                                            return false;
                                                        }
                                                        else if(satellite == "") {
                                                            alert('Please select the satellite.');
                                                            return false;
                                                        }
                                                        else {
                                                            this.setState({updateBtnLoader:true});
                                                            let that = this;
                                                            this.props.updateGroupData(userData.ID_NUMBER,mainGroup,'','',year,location,branch,satellite,(res) => {
                                                                console.log("here is the res:",res);
                                                                that.setState({updateBtnLoader:false});
                                                                if(typeof res === 'object' && typeof res.result === "object" && res.result.hasOwnProperty('message')) {
                                                                    console.log('here is the res ',res.result);
                                                                    alert(res.result.message)
                                                                    return false;
                                                                }
                                                                else
                                                                    alert('Record has been updated.');
                                                            })
                                                        }
                                                    }}>Update
                                                    {
                                                        this.state.updateBtnLoader == true ? <Spinner color="light"  size='small' />:null
                                                    }
                                                    </Button>
                                                </div>
                                            </Col>
                                            :null
                                        }
                                        <Col md={{size: 12}}>
                                        <FormGroup className={highlights.DESIGNATION ? 'changed' : null}>
                                            <FontAwesomeIcon className="updateIcon" icon={faCheck} size="1x" 
                                                onClick={(e) => this.selectUpdate('DESIGNATION', userData.DESIGNATION)}
                                            />
                                            <Label for="DESIGNATION">Assigned Designation</Label>
                                            
                                            <Input type="text" name="DESIGNATION" id="DESIGNATION" placeholder="" value={userData.DESIGNATION}  onChange={(e) => this.updateValue(e)} disabled/>

                                            {/*
                                                getYear() > '2020' ? 
                                                <Input type="select" name="DESIGNATION" id="DESIGNATION" onChange={(e) => this.updateValue(e)}>
                                                    <option value="select" key={0} selected={userData.DESIGNATION =="" ? "selected" : ''} >Select</option>
                                                    {
                                                        this.state.designationList.map((data, key) => 
                                                            userData.DESIGNATION == ""? 
                                                            <option
                                                                key={key}
                                                                value={data.desig_id}
                                                                
                                                            >
                                                                
                                                                {data.desig_name}
                                                            </option>
                                                            :
                                                            <option
                                                                key={key}
                                                                value={data.desig_id}
                                                                selected={data.desig_name == userData.DESIGNATION}
                                                                
                                                            >
                                                                
                                                                {data.desig_name}
                                                            </option>
                                                        )

                                                    }
                                                </Input>
                                                :
                                                <Input disabled={handleEditEmp} type="select" name="DESIGNATION" id="DESIGNATION" onChange={(e) => this.updateValue(e)}>
                                                    {

                                                        this.state.designationList.map((data, key) => 
                                                            <option
                                                                key={key}
                                                                val={data.DESIGNATION}
                                                                selected={data.DESIGNATION == userData.DESIGNATION}
                                                            >
                                                                {data.DESIGNATION}
                                                            </option>
                                                        )

                                                    }
                                                </Input>
                                           */ }
                                        </FormGroup>
                                    </Col>
                                        {/* { 
                                        this.state.divisionList.length > 0 ?  */}
                                        <Col md={{size: 12}}>
                                            <FormGroup className={highlights.DIVISION ? 'changed' : null}>
                                                <FontAwesomeIcon className="updateIcon" icon={faCheck} size="1x" 
                                                    onClick={(e) => this.selectUpdate('DIVISION', userData.DIVISION)}
                                                />
                                                <Label for="DIVISION">Assigned Division</Label>
                                                <Input type="text" name="DIVISION" id="DIVISION" placeholder="" value={userData.division_name !=null && userData.division_name !=0 ? userData.division_name : "Other division"} onChange={(e) => this.updateValue(e)} disabled/>
                                                
                                                    
                                                    {/* <Input disabled={handleEditEmp} type="select" name="DIVISION" id="DIVISION" onChange={(e) => this.updateValue(e)} search={true}>
                                                        <option value="0" key={"01"}>Other division</option>
                                                        {
                                                            this.state.divisionList.map((data, key) => 
                                                                <option
                                                                    key={key}
                                                                    value={data.division_id}
                                                                    selected={data.division_name == userData.division_name}
                                                                >
                                                                    {data.division_name}
                                                                </option>
                                                            )

                                                        }
                                                    </Input> */}
                                                    

                                            </FormGroup>
                                        </Col>
                                        {/* :null
                                         */}
                                        <Col md={{size: 12}}>
                                            <FormGroup className={highlights.SECTION ? 'changed' : null}>
                                                <FontAwesomeIcon className="updateIcon" icon={faCheck} size="1x" 
                                                    onClick={(e) => this.selectUpdate('SECTION', userData.SECTION)}
                                                />
                                                <Label for="SECTION">Assigned Section</Label>
                                                <Input type="text" name="SECTION" id="SECTION" placeholder="" value={userData.section_name !=null && userData.SECTION !=0 ? userData.section_name : "Other section"} onChange={(e) => this.updateValue(e)} disabled/>
                                                {/*
                                                    getYear() > '2020' ?
                                                    <Input type="select" name="SECTION" id="SECTION" onChange={(e) => this.updateValue(e)}>
                                                    <option val="select" key={0} selected={userData.SECTION =="" ? "selected" : ''} >Select</option>
                                                    <option value="0" key={"01"}>Other section</option>
                                                        {

                                                            this.state.sectionList.map((data, key) => 
                                                                userData.SECTION !="" ? 
                                                                <option
                                                                    key={key}
                                                                    value={data.section_id}
                                                                    selected={data.section_name == userData.section_name}
                                                                >
                                                                    {data.section_name}
                                                                </option>
                                                                :
                                                                <option
                                                                    key={key}
                                                                    value={data.section_id}
                                                                    
                                                                >
                                                                    {data.section_name}
                                                                </option>
                                                            )

                                                        }
                                                    </Input>
                                                    :
                                                    <Input disabled={handleEditEmp} type="select" name="SECTION" id="SECTION" onChange={(e) => this.updateValue(e)}>
                                                        {

                                                            this.state.sectionList.map((data, key) => 
                                                                <option
                                                                    key={key}
                                                                    val={data.SECTION}
                                                                    selected={data.SECTION == userData.SECTION}
                                                                >
                                                                    {data.SECTION}
                                                                </option>
                                                            )

                                                        }
                                                    </Input>
                                                    */}
                                            </FormGroup>
                                        </Col> 
                                        
                                        <Col md={{size: 12}}>
                                            <FormGroup className={highlights.PC ? 'changed' : null}>
                                                <FontAwesomeIcon className="updateIcon" icon={faCheck} size="1x" 
                                                    onClick={(e) => this.selectUpdate('PC', userData.PC)}
                                                />
                                                <Label for="PC">Assigned Job Grade</Label>
                                                <Input type="text" name="PC" id="PC" placeholder=""value={this.state.userData.pc_name} onChange={(e) => this.updateValue(e)} disabled/>
                                                {/*
                                                    getYear() > '2020' ? 
                                                    <Input  type="select" name="PC" id="PC" onChange={(e) => this.updateValue(e)}>
                                                    <option value="select" key={0} selected={userData.PC =="" ? "selected" : ''} >Select</option>
                                                        {
    
                                                            this.state.pcList.map((data, key) => 
                                                                userData.pc_name !="" ? 
                                                                <option
                                                                    key={key}
                                                                    value={data.pc_id}
                                                                    selected={data.pc_name == userData.pc_name}
                                                                    
                                                                >
                                                                    {data.pc_name}
                                                                </option>
                                                                :
                                                                <option
                                                                    key={key}
                                                                    value={data.pc_id}
                                                                    
                                                                >
                                                                    {data.pc_name}
                                                                </option>
                                                            )
    
                                                        }
                                                    </Input>
                                                    :
                                                    <Input disabled={handleEditEmp} type="select" name="PC" id="PC" onChange={(e) => this.updateValue(e)}>
                                                        {

                                                            this.state.pcList.map((data, key) => 
                                                                <option
                                                                    key={key}
                                                                    val={data.PC}
                                                                    selected={data.PC == userData.PC}
                                                                >
                                                                    {data.PC}
                                                                </option>
                                                            )

                                                        }
                                                    </Input>
                                                */}
                                            </FormGroup>
                                        </Col>

                                        <Col md={{size: 12}}>
                                            <FormGroup>
                                                <Label for="section">Supervising <b>{userReportingData.length} {userReportingData.length == 1 ? 'Employee' : 'Employees'}</b></Label>
                                                <Table style={{marginTop:5}}>
                                                
                                                    <tbody>
                                                        {
                                                        userReportingData && userReportingData.length && userReportingData.map((subOrdinate, key) =>
                                                            <tr key={key}>
                                                                <td >{subOrdinate.ID_NUMBER}</td>
                                                                <td>{subOrdinate.NAME}</td>
                                                                <td>{subOrdinate.SECTION}</td>
                                                                <td>{subOrdinate.DIVISION}</td>
                                                                { handleEditSubOrds=='' ? 
                                                                <td style={{color:'red', fontSize: '12px', cursor: 'pointer'}}
                                                                    onClick={(e) => this.props.removeSubordinate(adminData.admin.admin_id, subOrdinate.supervisor_id,subOrdinate.ID_NUMBER)}    
                                                                >
                                                                    <FontAwesomeIcon icon={faTimesCircle} size="1x" style={{marginRight: '10px'}} /> Remove
                                                                </td>
                                                                :null
                                                                }
                                                            </tr>
                                                        )
                                                        }

                                                        <tr>
                                                            <td colSpan="2" style={{textAlign: 'right'}}>
                                                                Add new subordinate: 
                                                            </td>
                                                            <td colSpan="2">
                                                                <FormGroup className="boxFieldForm" style={{marginBottom: 0}}>
                            
                                                                    <Input disabled={handleEditEmp} type="text" bsSize="lg" maxLength="1" name="employeeIdSub" id="employeeIdSub1"
                                                                        value={employeeIdSub[0]}
                                                                        onChange={evt => this.handleChangeSub(evt, 0)} />

                                                                    <Input type="text" bsSize="lg" maxLength="1" name="employeeIdSub" id="employeeIdSub2" 
                                                                        value={employeeIdSub[1]}
                                                                        onChange={evt => this.handleChangeSub(evt, 1)} />

                                                                    <Input style={{
                                                                        background: '#fef67f',
                                                                        border: 'none',
                                                                    }} type="text" bsSize="lg" value="-" maxLength="1" name="dash" id="dash" disabled />

                                                                    <Input type="text" bsSize="lg" maxLength="1" name="employeeIdSub" id="employeeIdSub3"
                                                                        value={employeeIdSub[3]}
                                                                        onChange={evt => this.handleChangeSub(evt, 3)} />

                                                                    <Input type="text" bsSize="lg" maxLength="1" name="employeeIdSub" id="employeeIdSub4"
                                                                        value={employeeIdSub[4]}
                                                                        onChange={evt => this.handleChangeSub(evt, 4)} />

                                                                    <Input type="text" bsSize="lg" maxLength="1" name="employeeIdSub" id="employeeIdSub5"
                                                                        value={employeeIdSub[5]}
                                                                        onChange={evt => this.handleChangeSub(evt, 5)} />

                                                                    <Input type="text" bsSize="lg" maxLength="1" name="employeeIdSub" id="employeeIdSub6"
                                                                        value={employeeIdSub[6]}
                                                                        onChange={evt => this.handleChangeSub(evt, 6)} />

                                                                </FormGroup>
                                                            </td>
                                                            <td style={{textAlign: 'left'}}>
                                                                {
                                                                employeeIdStringSub.length == 7 ?
                                                                    <Button disabled={handleEditSubOrds !='' ? true : isLoadingSub} style={{margin: '0 0 0 0'}} color={"warning"} size="md"
                                                                        onClick={(e) => {
                                                                            this.setState({
                                                                                isLoadingSub : true
                                                                            }, () => {

                                                                                if( employeeIdStringSub !=userData.ID_NUMBER && employeeIdStringSub != adminData.admin.employee_id) {
                                                                                    this.props.assignNewSubordinate(adminData.admin.admin_id, userData.ID_NUMBER, employeeIdStringSub)
                                                                                    return false;
                                                                                }

                                                                                if(employeeIdStringSub != adminData.admin.employee_id && employeeIdStringSub == userData.ID_NUMBER ) {
                                                                                    alert('You have entered the same Employee ID as the profile. Please add a valid Employee ID to add sub-ordinate for '+userData.NAME)
                                                                                    this.setState({
                                                                                        isLoadingSub : false
                                                                                    })
                                                                                    return false;
                                                                                }
                                                                                
                                                                                if(employeeIdStringSub == adminData.admin.employee_id && employeeIdStringSub != userData.ID_NUMBER) {
                                                                                    alert('You have entered your Employee ID. Please add a valid Employee ID to add sub-ordinate.')
                                                                                    this.setState({
                                                                                        isLoadingSub : false
                                                                                    })
                                                                                    return false;
                                                                                }
                                                                            })
                                                                        }}
                                                                        
                                                                    >
                                                                        Add
                                                                        {
                                                                            isLoadingSub ? <Spinner style={{margin: '4px 0px 4px 10px'}} color="light" size="sm" /> : null
                                                                        }
                                                                    </Button>
                                                                : null
                                                                }
                                                            </td>
                                                        </tr>

                                                    </tbody>
                                                </Table>
                                            </FormGroup>
                                        </Col>
                                        <Col md={{size: 12}}>
                                            <FormGroup>
                                                <Label for="section">Reporting to <b>{userSupervisorData.length} Supervisor</b></Label>
                                                <Table style={{marginTop:5}} >  
                                                    <tbody>
                                                        {
                                                        userSupervisorData && userSupervisorData.length ?
                                                            userSupervisorData.map((supervisor, key) =>
                                                                <tr key={key}>
                                                                    <td>{supervisor.ID_NUMBER}</td>
                                                                    <td>{supervisor.NAME}</td>
                                                                    <td>{supervisor.SECTION}</td>
                                                                    <td>{supervisor.DIVISION}</td>
                                                                    {handleEditSup =='' ? 
                                                                    <td style={{color:'green', fontSize: '12px', cursor: 'pointer'}} onClick={(e) => this.setState({
                                                                        userSupervisorData : []
                                                                    })}>
                                                                        <FontAwesomeIcon icon={faTimesCircle} size="1x" style={{marginRight: '10px'}} /> Change
                                                                    </td>
                                                                    :null}
                                                                </tr>
                                                            )
                                                        : null
                                                        }
                                                        
                                                        {
                                                        !userSupervisorData.length ?
                                                            <tr>
                                                                <td colSpan="2">
                                                                    Employee id of new supervisor: 
                                                                </td>
                                                                <td>
                                                                    <FormGroup className="boxFieldForm" style={{marginBottom: 0}}>
                                
                                                                        <Input type="text" bsSize="lg" maxLength="1" name="employeeId" id="employeeId1"
                                                                            value={employeeId[0]}
                                                                            onChange={evt => this.handleChange(evt, 0)} />

                                                                        <Input type="text" bsSize="lg" maxLength="1" name="employeeId" id="employeeId2" 
                                                                            value={employeeId[1]}
                                                                            onChange={evt => this.handleChange(evt, 1)} />

                                                                        <Input style={{
                                                                            background: '#fef67f',
                                                                            border: 'none',
                                                                        }} type="text" bsSize="lg" value="-" maxLength="1" name="dash" id="dash" disabled />

                                                                        <Input type="text" bsSize="lg" maxLength="1" name="employeeId" id="employeeId3"
                                                                            value={employeeId[3]}
                                                                            onChange={evt => this.handleChange(evt, 3)} />

                                                                        <Input type="text" bsSize="lg" maxLength="1" name="employeeId" id="employeeId4"
                                                                            value={employeeId[4]}
                                                                            onChange={evt => this.handleChange(evt, 4)} />

                                                                        <Input type="text" bsSize="lg" maxLength="1" name="employeeId" id="employeeId5"
                                                                            value={employeeId[5]}
                                                                            onChange={evt => this.handleChange(evt, 5)} />

                                                                        <Input type="text" bsSize="lg" maxLength="1" name="employeeId" id="employeeId6"
                                                                            value={employeeId[6]}
                                                                            onChange={evt => this.handleChange(evt, 6)} />

                                                                    </FormGroup>
                                                                </td>
                                                                <td>
                                                                    {
                                                                    employeeIdString.length == 7 ?
                                                                        
                                                                        <Button disabled={handleEditSup !='' ? true : isLoading} style={{margin: '0 0 0 0'}} color={"warning"} size="md"
                                                                            onClick={(e) => {
                                                                                this.setState({
                                                                                    isLoading : true
                                                                                }, () => {

                                                                                    if(employeeIdString != userData.ID_NUMBER && employeeIdString != adminData.admin.employee_id)  {
                                                                                        this.props.assignNewAdmin(adminData.admin.admin_id, employeeIdString, userData.ID_NUMBER)
                                                                                        return false
                                                                                    }

                                                                                    if(employeeIdString != adminData.admin.employee_id && employeeIdString == userData.ID_NUMBER) {
                                                                                        alert('You have entered the same Employee ID as the profile. Please add a valid Employee ID to add supervisor for '+userData.NAME);
                                                                                        this.setState({
                                                                                            isLoading : false
                                                                                        })
                                                                                        return false;
                                                                                    }

                                                                                    if(employeeIdString == adminData.admin.employee_id && employeeIdString != userData.ID_NUMBER) {
                                                                                        alert('You have entered your Employee ID. Please add a valid Employee ID to add supervisor.');
                                                                                        this.setState({
                                                                                            isLoading : false
                                                                                        })
                                                                                        return false;
                                                                                    }
                                                                                })
                                                                            }}
                                                                        
                                                                        >
                                                                            Add
                                                                            {
                                                                                isLoading ? <Spinner style={{margin: '4px 0px 4px 10px'}} color="light" size="sm" /> : null
                                                                            }
                                                                        </Button>
                                                                    : null
                                                                    }
                                                                </td>
                                                            </tr>
                                                            : null
                                                        }
                                                    </tbody>
                                                </Table>
                                            </FormGroup>
                                        </Col>

                                    </Row>
                                </Form>
                            </Container>

                        </TabPane>
                        <TabPane tabId="jd">
                            <Row>
                                {/* <Col sm="12">
                                <h4>Tab 1 jd</h4>
                                </Col> */}
                            </Row>
                        </TabPane>
                        {/* <TabPane tabId="am">

                            <Row style={{margin: 0}}>
                                <AssessmentManagement userFetchedProfile={userData} assessmentMangement={this.state.assessmentMangement} handleEdit={handleRPL}/>
                            </Row>

                        </TabPane> */}
                        <TabPane tabId="sc">
                        
                                <ScoreCardsList fromAdmin={true} userFetchedProfile={userData} />
                                {/* <Col sm="12">
                                    <h4>Tab 1 sc</h4>
                                </Col> */}
                
                        </TabPane>
                    </TabContent>

                </Container>

            </div>
            
            {/* <Footer /> */}
        </div>
        );
    }
}

EmployeeProfile.propTypes = {
    updateProfileData: PropTypes.func.isRequired,
    assignNewAdmin : PropTypes.func.isRequired,
    assignNewSubordinate : PropTypes.func.isRequired,
    adminFetchEmployeeInformation : PropTypes.func.isRequired,
    removeSubordinate : PropTypes.func.isRequired,
    validateEmpEmail : PropTypes.func.isRequired,
    getMainGroupsData : PropTypes.func.isRequired,
    fetchSubGroupsOrLocationsOrSOG : PropTypes.func.isRequired,
    fetchSubGroupsDepartments : PropTypes.func.isRequired,
    fetchLocationBranches : PropTypes.func.isRequired,
    fetchBrancheSatellite : PropTypes.func.isRequired,
    updateGroupData : PropTypes.func.isRequired,
    fetchYearWiseGroupsData : PropTypes.func.isRequired,
    getAssesstDeptsHierarchy : PropTypes.func.isRequired,
    getAssesstDivHierarchy : PropTypes.func.isRequired,
    getAssesstSectionHierarchy : PropTypes.func.isRequired,
    getAssesstPCHierarchy : PropTypes.func.isRequired,
    getAssesstDesignationHierarchy : PropTypes.func.isRequired,
    getLocationDivsHierarchy : PropTypes.func.isRequired,
    getLocationSectionHierarchy : PropTypes.func.isRequired,
    getLocationPCHierarchy : PropTypes.func.isRequired,
    getLocationDesigHierarchy : PropTypes.func.isRequired,
    getAssessBranchDivsHierarchy : PropTypes.func.isRequired,
    getAssessBranchSectionHierarchy : PropTypes.func.isRequired,
    getAssessBranchPCHierarchy : PropTypes.func.isRequired,
    getAssessBranchDesignationHierarchy : PropTypes.func.isRequired,
    updateProfileYear : PropTypes.func.isRequired,

}

const mapStatesToProps = state => ({
    assignedSupDataSuccessAdmin : state.webRed.assignedSupDataSuccessAdmin,
    assignedSubordinateDataSuccessAdmin : state.webRed.assignedSubordinateDataSuccessAdmin,
    removedSubordinateDataSuccessAdmin : state.webRed.removedSubordinateDataSuccessAdmin,
    additionalData : state.webRed.additionalData,
    mainGroupsData : state.webRed.mainGroupsData
    
})

export default connect( mapStatesToProps, { updateProfileData, assignNewAdmin, assignNewSubordinate, 
    adminFetchEmployeeInformation, removeSubordinate,validateEmpEmail,getAdditionalData,getMainGroupsData,
    fetchSubGroupsOrLocationsOrSOG,fetchSubGroupsDepartments,fetchLocationBranches,fetchBrancheSatellite,
    updateGroupData,fetchYearWiseGroupsData,getAssesstDeptsHierarchy,getAssesstDivHierarchy,
    getAssesstSectionHierarchy,getAssesstPCHierarchy,getAssesstDesignationHierarchy,getLocationDivsHierarchy,
    getLocationSectionHierarchy,getLocationPCHierarchy,getLocationDesigHierarchy,getAssessBranchDivsHierarchy,
    getAssessBranchSectionHierarchy,getAssessBranchPCHierarchy,getAssessBranchDesignationHierarchy,
    updateProfileYear
})( EmployeeProfile );