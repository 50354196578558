import React, { Component } from "react";
import { Redirect } from "react-router-dom";
// import Header from '../../Header';
// import Footer from "./../Footer";

import { adminFetchEmployeeInformation } from './../../../../actions/webRedActions';

// import { getEmployeeProfile } from '../../../../actions/utility';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// import userData from './../userData';

import {
    Button,
    Container,
    Row,
    Col,
    Media,
    Form,
    FormGroup,
    Label,
    Input,
    Spinner,
    Alert
  } from "reactstrap";

import SubAdminProfile from "./../subAdmins/SubAdminProfile";
import AdminHeader from './../AdminHeader';
import SideMenu from'./../sidMenu/SideMenu';

class EditSubAdmins extends Component {

    constructor(props) {
        super(props);
        this.state = {
          empProfileData : '',
          empSupervisorData : '',
          empReportingData : '',
          assessmentMangement : '',
          subAdminData : {},
          adminId:''
        };
    }
    componentWillMount() {
        
        var employeeId = this.props.match.params.employeeId;
        
        this.state.adminId = this.props.match.params.adminId;
        
        console.log("here i am getting id:",this.state.adminId)

        this.props.adminFetchEmployeeInformation(employeeId);
    }
    

    componentWillReceiveProps(nextProps, thisProps) {
        console.log('Faheem Here:',nextProps);
        // return false;
      if(this.props.fetchEmpDataSuccessAdmin != nextProps.fetchEmpDataSuccessAdmin) {
         
        // console.log(nextProps.fetchEmpDataSuccessAdmin);
        if(nextProps.fetchEmpDataSuccessAdmin.result) {
            if(nextProps.fetchEmpDataSuccessAdmin.result.profile) {
                if(nextProps.fetchEmpDataSuccessAdmin.result.profile !== null) {
                  this.setState({
                    empProfileData : nextProps.fetchEmpDataSuccessAdmin.result.profile,
                    employeeId  :   nextProps.fetchEmpDataSuccessAdmin.result.profile.ID_NUMBER,
                    showError : false
                  }, () => {
                    console.log(this.state.empProfileData)
                  })
                } else {
                    this.setState({
                      empProfileData : ''
                    })
                }
            }

            if(nextProps.fetchEmpDataSuccessAdmin.result.supervision && nextProps.fetchEmpDataSuccessAdmin.result.supervision.length) {
                this.setState({
                  empSupervisorData : nextProps.fetchEmpDataSuccessAdmin.result.supervision
                })
            } else {
              this.setState({
                empSupervisorData : ''
              })
            }

            if(nextProps.fetchEmpDataSuccessAdmin.result.sub_ords && nextProps.fetchEmpDataSuccessAdmin.result.sub_ords.length) {
                this.setState({
                  empReportingData : nextProps.fetchEmpDataSuccessAdmin.result.sub_ords
                })
            } else {
              this.setState({
                empReportingData : ''
              })
            }

        }
      }

      if(this.props.fetchedAllAssessmentsForAdmin != nextProps.fetchedAllAssessmentsForAdmin) {
        if(nextProps.fetchedAllAssessmentsForAdmin.result) {
          this.setState({
            assessmentMangement : nextProps.fetchedAllAssessmentsForAdmin.result
          }, () => {
            console.log(this.state.assessmentMangement)
          })
        }
      }

    }

  render() {

    const {empProfileData, empSupervisorData, empReportingData, assessmentMangement} = this.state;

    return (
      <div>
        <SideMenu/>
        <AdminHeader title={'Employee Management'} style={{marginLeft:80}}/>
        <div class='container' style={{textAlign:'left', marginTop: 60}}>  
            {/* <Label><b>Edit sub admin</b></Label> */}
        </div>
        {
          empProfileData && empProfileData != '' ?
          <SubAdminProfile userData={empProfileData} userSupervisorData={empSupervisorData} userReportingData={empReportingData} assessmentMangement={assessmentMangement} isEdit={1} adminId={this.state.adminId}/>
            
          : <Container>
              <Spinner color="primary" style={{ width: '2rem', height: '2rem', margin: '20vh 0' }}></Spinner>
          </Container>
        }
        
      </div>
     

    );
  }
}

EditSubAdmins.propTypes = {
    adminFetchEmployeeInformation: PropTypes.func.isRequired,
}

const mapStatesToProps = state => ({
    fetchEmpDataSuccessAdmin : state.webRed.fetchEmpDataSuccessAdmin,
    fetchedAllAssessmentsForAdmin : state.webRed.fetchedAllAssessmentsForAdmin
})

export default connect( mapStatesToProps, { adminFetchEmployeeInformation })( EditSubAdmins );