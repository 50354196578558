import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import Header from './../Header';
// import Footer from "./../Footer";

import AssessmentList from './AssessmentList';

import { getAllAssessments } from './../../../actions/webRedActions';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// import dataDummy from './../dataDummy';

import {
    Button,
    Container,
    Row,
    Col,
    Media,
    Form,
    FormGroup,
    Label,
    Input,
    Spinner
  } from "reactstrap";

import readXlsxFile from 'read-excel-file';

// import { BrowserRouter as Router, Route, Link } from "react-router-dom";

class SubordinateAssessmentsComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            enableAssessListing : false,
            subOrdinateId: "",
            selectedSubOrdinate:"select subordinate",
            subOrdinatesAll : JSON.parse(localStorage.getItem("userSubOrdinates")),
            subOrdinates : [],

            mainCompetencies : [],
            subCompetencies : [],
            subComptStatus : [],
            competencies : {},
            listingLoading : false
        };
    }

    componentDidMount() {

        try {
            const {subOrdinatesAll} = this.state;
            this.setState({
                subOrdinates : Array.from(new Set(subOrdinatesAll.map(subOr => subOr.ID_NUMBER)))
                                .map(ID_NUMBER => {
                                    return {
                                        ID_NUMBER : ID_NUMBER,
                                        BRANCH: subOrdinatesAll.find(subOr => subOr.ID_NUMBER === ID_NUMBER).BRANCH,
                                        DEPT: subOrdinatesAll.find(subOr => subOr.ID_NUMBER === ID_NUMBER).DEPT,
                                        DESIGNATION: subOrdinatesAll.find(subOr => subOr.ID_NUMBER === ID_NUMBER).DESIGNATION,
                                        DIVISION: subOrdinatesAll.find(subOr => subOr.ID_NUMBER === ID_NUMBER).DIVISION,
                                        FIRST_NAME: subOrdinatesAll.find(subOr => subOr.ID_NUMBER === ID_NUMBER).FIRST_NAME,
                                        LAST_NAME: subOrdinatesAll.find(subOr => subOr.ID_NUMBER === ID_NUMBER).LAST_NAME,
                                        MI: subOrdinatesAll.find(subOr => subOr.ID_NUMBER === ID_NUMBER).MI,
                                        NAME: subOrdinatesAll.find(subOr => subOr.ID_NUMBER === ID_NUMBER).NAME,
                                        PC: subOrdinatesAll.find(subOr => subOr.ID_NUMBER === ID_NUMBER).PC,
                                        SECTION: subOrdinatesAll.find(subOr => subOr.ID_NUMBER === ID_NUMBER).SECTION,
                                        branch_id: subOrdinatesAll.find(subOr => subOr.ID_NUMBER === ID_NUMBER).branch_id,
                                        email: subOrdinatesAll.find(subOr => subOr.ID_NUMBER === ID_NUMBER).email,
                                        employee_id: subOrdinatesAll.find(subOr => subOr.ID_NUMBER === ID_NUMBER).employee_id
                                    }
                                })
            }, () => {
                console.log(this.state.subOrdinates,this.state.subOrdinateId)
                localStorage.setItem('subOrdinates',JSON.stringify(this.state.subOrdinates))
            
            })
        } catch(e) {
            console.log(e);
        }


        // var urlEmpId = new URLSearchParams().get('empid');
    
        // var urlParams = new URLSearchParams(window.location.search);
        // var urlEmpId = urlParams.get('empid');
     
        var url = window.location.hash
        var employId= url
        if(url.indexOf('?'))
        {        
            url = url.split('empid=')[1]
            console.log(url)
            employId = url
            
        }

        if(employId && employId != null) {
            console.log(employId);
       
           
            this.setState({
                enableAssessListing : true,
                listingLoading : true,
                subOrdinateId:  localStorage.getItem('subOrdinateId'),
                selectedSubOrdinate:localStorage.getItem('subOrdinateId'),
                subOrdinates:  JSON.parse(localStorage.getItem('subOrdinates'))
           
            }, () => {
             
                this.props.getAllAssessments(employId);
            })
        }
    }

    componentWillReceiveProps(nextProps, thisProps) {
        // console.log(nextProps.fetchedAllAssessments);
        if(this.props.fetchedAllAssessments != nextProps.fetchedAllAssessments) {
          const data = nextProps.fetchedAllAssessments.result;
          this.setState({
            mainCompetencies : data.mainCompt,
            subCompetencies : data.subCompt,
            subComptStatus : data.subComptStatus
          }, () => {
    
            this.setState({
              competencies : this.state.mainCompetencies.map((comp) => {
                return {
                  'id' : comp.competency_id,
                  'name' : comp.competency_name,
                  'subCompetencies' : this.state.subCompetencies[comp.competency_id]
                };
              }),
              listingLoading : false
            }, () => {
              console.log(this.state.competencies);
            })
    
          })
        }
    }

    handleChange = (event) => {
        event.preventDefault();
        localStorage.setItem('subOrdinateId',event.target.value)
        this.setState({
            enableAssessListing : true,
            listingLoading : true,
            subOrdinateId : event.target.value,
            selectedSubOrdinate:event.target.value
        }, (event) => {
      
            this.props.getAllAssessments(this.state.subOrdinates[this.state.subOrdinateId].employee_id);
        })
    }

  render() {

    const {subOrdinates, subOrdinateId, enableAssessListing, listingLoading} = this.state;

    return (
      <div className="SubordinateAssessmentsComponent" style={{background:'#f6fbff'}}>
        <Header />
            <Container style={{textAlign: 'left'}}>
                <Row style={{padding: '6vh 8vw 0'}}>
                    <Col md={{size: 12}} style={{
                        // paddingTop: '2vh',
                        marginBottom: '30px',
                        padding: '1.5rem 8rem',
                        backgroundColor: '#fef67f',
                        border: '1px #ffd29b solid',
                        borderRadius: '3px'
                    }}>
                        <Form className="subordinateForm" id="subordinateForm">
                            <FormGroup>
                                <Label for="subOrdinateId">Select a subordinate to be assessed:</Label>
                                <Input type="select" name="select" id="subOrdinateId" 
                                    // onChange={(e) => 
                                        
                                    // }
                                    onChange={evt => this.handleChange(evt)}
                                    value={this.state.selectedSubOrdinate}
                                >
                                    <option key={0} value={"select subordinate"} disabled selected>Select</option>
                                    {
                                        subOrdinates && subOrdinates.length && subOrdinates.map((emp, key) => 
                                            <option id={"subord"+emp.ID_NUMBER} key={key+1} value={key}>{emp.NAME}</option>
                                        )
                                    }
                                    
                                </Input>
                                <span style={{
                                    textAlign: 'right',
                                    width: '100%',
                                    display: 'inline-block',
                                    color: '#cabd00',
                                    fontSize: '12px'
                                }}>These subordinates have been assigned to you by PAGCOR's HR team</span>
                            </FormGroup>
                        </Form>
                    </Col>
                </Row>
            </Container>

            {
                enableAssessListing ?
                    listingLoading ?
                        <Col md={{size: 12}} style={{textAlign: 'center', padding: '30vh 0'}}>
                            <Spinner color="primary" />
                        </Col>
                    :
                        <AssessmentList type="sup" competencies={this.state.competencies} subComptStatus={this.state.subComptStatus} subOrdinateIdentity={subOrdinates[subOrdinateId]} />
                : null
            }
        
        {/* <Footer /> */}
      </div>
    );
  }
}

SubordinateAssessmentsComponent.propTypes = {
    getAllAssessments: PropTypes.func.isRequired
}

const mapStatesToProps = state => ({
    fetchedAllAssessments : state.webRed.fetchedAllAssessments
})

export default connect( mapStatesToProps, { getAllAssessments })( SubordinateAssessmentsComponent );