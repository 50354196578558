import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import Header from './../Header';
// import Footer from "./../Footer";

import { uploadDataBranch } from './../../../actions/webRedActions';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import './../../../public/scss/Assessments.scss';

// import dataDummy from './../dataDummy';
import { getYear } from './../../../actions/utility';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import instruction1 from "../../../public/images/Instructions/instruction1.png";
import instruction2 from "../../../public/images/Instructions/instructions2.jpg";
import instruction3 from "../../../public/images/Instructions/instruction3.png";
import instruction4 from "../../../public/images/Instructions/instruction4.png";
import instruction5 from "../../../public/images/Instructions/instruction5.png";
import instruction6 from "../../../public/images/Instructions/instruction6.png";
import {
  Button,
  Container,
  Row,
  Col,
  Collapse,
  Card,
  CardBody,
  FormGroup,
  Label,
  Input
} from "reactstrap";

import readXlsxFile from 'read-excel-file';

// import { BrowserRouter as Router, Route, Link } from "react-router-dom";

class Instruction extends Component {

  constructor(props) {
    super(props);
    this.state = {
      collapseState: [true, true, true, true],
      isChecked :false
    };
  }

  componentDidMount() {

  }

  componentWillReceiveProps(nextProps, thisProps) {

  }

  render() {
    const { competencies, subComptStatus, type, subOrdinateIdentity,isChecked} = this.props;
    const { collapseState } = this.state;

    // console.log(competencies);

    return (
      <div className="instructions" style={{ padding: '5vh', background: '#f6fbff' }}>
        <Container>
          <Row>
            <Col md="12">
              <h2 className="text-center">Instructions</h2>

              <p className="font-weight-bold">Welcome!</p>
              <p><span className="font-weight-bold">Hello</span> {typeof this.props.empData.NAME !=="undefined" ? this.props.empData.NAME : "" },</p>

              <p>You will now be taking an online competency-based assessment for competencies assigned to you under for {this.props.mainCompetenciesNames} Competency. Please consider the following points before taking the test and ensure the accuracy of information to be gathered using this assessment:</p>

              <ol>
                <li>
                  To complete this online assessment, make sure that you have a stable internet connection.
                </li>

                <li>
                  The assessment of this competency is made up of a total of ten (10) items, with varying difficulties. You will see all the questions which you are required to answer in order to submit the assessment.
                </li>

                <li>
                  For each question, you will see a list of choices that you can select your answers from.
                  <div className="m-4 text-center">
                    <img src={instruction1} className="img-fluid" width="900" height="900" />
                  </div>
                  {/* This point will not be relevant */}
                </li>

                {/* <li>
                  Below each item, you will see three function buttons you can use to skip, submit, and abort the test.
                  <div className="m-4 text-center">
                    <img src={instruction2} className="img-fluid" />
                  </div>

                  You can use these buttons to:

                      <ul>
                    <li>
                      Skip – Skip the item and move on to the next item
                        </li>
                    <li>
                      Submit – Submit your answer to proceed to the next item
                        </li>
                    <li>
                      Abort the test – abandon the assessment and go back to the main dashboard
                        </li>
                  </ul>
                </li> */}

                <li>
                  Please read each item carefully and choose the best answer from the options provided. To select your answer, click on the tick-box placed beside the choice you would like to use as your response.
                  <div className="m-4 text-center">
                    <img src={instruction3} className="img-fluid" width="900" height="900" />
                  </div>
                </li>

                <li>
                  Once all questions are answered, click on the Submit button to proceed to submitting your assessment.
                  <div className="m-4 text-center">
                    <img src={instruction4} className="img-fluid" width="900" height="900" />
                  </div>
                </li>

                <li>
                  After submitting the assessment on the competency you selected, you will get a pop-up notification for confirmation. <span className="font-weight-bold">Click on ‘Yes’ to proceed. If you want to re-attempt any question, click on ‘No’.</span>

                  <div className="text-center m-4">
                    <img src={instruction5} className="img-fluid" />
                  </div>
                </li>

                <li>
                  Once you click on ‘Yes’, your assessment response will be submitted.
                  <div className="text-center m-4">
                    <img src={instruction6} className="img-fluid" width="323" />
                  </div>
                </li>

                <li>
                  Repeat the aforementioned steps until you complete all assessments assigned to you.
                </li>
              </ol>

              <h5 className="mt-5">IMPORTANT NOTES</h5>
              <ol>
                <li className="font-weight-bold">Please remember that you are encouraged to complete each of the questions in the assessment.</li>
                <li className="font-weight-bold">If your browser hangs or your internet connection got intermittent, please refresh your browser and proceed on taking the same item you left earlier.</li>
              </ol>
            </Col>
          </Row>
          <Row className="mt-5">
              <Col>
                <FormGroup check>
                    <Label check>
                        <Input onChange={(e)=>{
                            this.isChecked = e.target.checked;
                           
                        }}type="checkbox" />{' '}
                            <strong>I have read the instructions carefully. I would like to proceed to the assessments.</strong>
                            <br/>
                    </Label>
                            <button onClick={()=>{
                              if(this.isChecked){
                                this.props.onAgree(this.isChecked);
                               
                              }
                            }} style={{backgroundColor:'#ffc107', padding:'.3rem 1.5rem', border:'none', borderRadius:'5px', outline:'none', font:'bold', marginTop:'1rem',marginLeft:'15rem'}}>Ok</button>

                </FormGroup>
              </Col>
          </Row>
        </Container>

      </div>
    );
  }
}

Instruction.propTypes = {
  // uploadDataBranch: PropTypes.func.isRequired
}

const mapStatesToProps = state => ({
  // triggerNextPinSuccess : state.webRed.triggerNextPinSuccess,
  // triggeredLoginAction : state.webRed.triggeredLoginAction
})

export default connect(mapStatesToProps, {})(Instruction);