import React, { Component } from "react";
import Header from './Header';
// import Footer from "./Footer";

import { uploadDataDept } from './../../actions/webRedActions';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import axios from 'axios';

import columnsData from './testDataColumns';
import tempData from './branchDataTemp';

import {
    Button,
    Container,
    Row,
    Col,
    Media
  } from "reactstrap";

import readXlsxFile from 'read-excel-file';

// import { BrowserRouter as Router, Route, Link } from "react-router-dom";

class ImportDeptSheetComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            enableLoader : false,
            branchName : '',
            counter: 0,
            requiredNames : ["ID_NUMBER","LAST_NAME","FIRST_NAME","MI","NAME","DESIGNATION","JG","BRANCH","DEPT","DIVISION"],
            divisions : ["AML COMPLIANCE MONITORING","TRAINING,POLICY DEVELOPMENT, REPORTS AND ADMIN."],
            sections : ["REPORTS AND ADMIN.","Reports, Analysis and Evaluation","Administrative","TRAINING AND POLICY DEVELOPMENT","AML Training","AML Policy Development"],
            orgniseColumnsCooperateNames :{
                "ID_NUMBER" : "main_group",
                "LAST_NAME" : "subgroup_name",
                "FIRST_NAME" : "department",
                "DESIGNATION" :"division",
                "JG" : "section",
                "BRANCH" : "desig_name",
                "DEPT" : "pay_class",
                "DIVISION" : "C1",
                "SECTION" : "C2",
                "null_11" : "C3" ,
                "null_12" : "C4",
                "C1_INC" : "O1",
                "C1_SUP" : "O2",
                "C1_AVE" : "O3",
                "C1_RPL" : "O4",
                "C1_GAP" : "O5",
                "null_18":"O6",
                "C1":"L1",
                "null_20" :"L2",
                "null_21":"L3",
                "C2_INC":"L4",
                "C2_SUP":"T1",
                "C2_AVE":"T2",
                "C2_RPL":"T3",
                "C2_GAP":"T4",
                "null_27":"T5",
                "C2":"T6",
                "null_29":"T7",
                "null_30":"T8",
                "C3_INC":"T9",
                "C3_SUP":"T10",
                "C3_AVE":"T11",
                "C3_RPL":"T12",
                "C3_GAP":"T13",
                "null_36":"T14",
                "C3" : "T15",
                "null_38":"T16",
                "null_39":"T17",
                "C4_INC":"T18",
                "C4_SUP":"T19",
                "C4_AVE":"T20",
                "C4_RPL":"T21",
                "C4_GAP":"T22",
                "null_45":"T23",
                "C4":"T24",
                "null_47":"T25",
                "null_48":"T26",
                "C5_INC":"T27",
                "C5_SUP":"T28",
                "C5_AVE":"T29",
                "C5_RPL":"T30",
                "C5_GAP":"T31",
                "null_54":"T32",
                "C5": "T33",
                "null_56":"T34",
                "null_57":"T35",
                "O1_INC":"T36",
                "O1_SUP":"T37",
                "O1_AVE":"T38",
                "O1_RPL":"T39",
                "O1_GAP":"T40",
                "null_63":"T41",
                "O1":"T42",
                "null_65":"T43",
                "null_66":"T44",
                "O2_INC":"T45",
                "O2_SUP":"T46",
                "O2_AVE":"T47",
                "O2_RPL":"T48",
                "O2_GAP":"T49",
                "null_72":"T50",
                "O2":"T51",
                "null_74" : "T52",
                "null_75" : "T53",
                "O3_INC" : "T54"
            },
            orgniseColumnsNames :{
                "ID_NUMBER" : "main_group",
                "MI" : "location",
                "DESIGNATION" : "division",
                "NAME" : "branch_name",
                "JG" : "section",
                "BRANCH" : "desig_name",
                "DEPT" :"pay_class",
                "DIVISION" : "C1",
                "SECTION" : "C2",
                "null_11" : "C3",
                "null_12" : "C4",
                "C1_INC" : "O1",
                "C1_SUP" : "O2" ,
                "C1_AVE" : "O3",
                "C1_RPL" : "O4",
                "C1_GAP" : "O5",
                "null_18" : "O6",
                "C1" : "L1",
                "null_20" : "L2",
                "null_21":"L3",
                "C2_INC":"L4",
                "C2_SUP" :"T1",
                "C2_AVE":"T2",
                "C2_RPL":"T3",
                "C2_GAP":"T4",
                "null_27":"T5",
                "C2":"T6",
                "null_29":"T7",
                "null_30":"T8",
                "C3_INC":"T9",
                "C3_SUP":"T10",
                "C3_AVE":"T11",
                "C3_RPL":"T12",
                "C3_GAP":"T13",
                "null_36":"T14",
                "C3":"T15",
                "null_38":"T16",
                "null_39":"T17",
                "C4_INC" : "T18",
                "C4_SUP":"T19",
                "C4_AVE":"T20",
                "C4_RPL":"T21",
                "C4_GAP":"T22",
                "null_45":"T23",
                "C4":"T24",
                "null_47":"T25",
                "null_48":"T26",
                "C5_INC":"T27",
                "C5_SUP":"T28",
                "C5_AVE":"T29",
                "C5_RPL":"T30",
                "C5_GAP":"T31",
                "null_54":"T32",
                "C5":"T33",
                "null_56":"T34",
                "null_57":"T35",
                "O1_INC": "T36",
                "O1_SUP":"T37",
                "O1_AVE":"T38",
                "O1_RPL":"T39",
                "O1_GAP":"T40",
                "null_63":"T41",
                "O1":"T42",
                "null_65":"T43",
                "null_66":"T44",
                "O2_INC":"T45",
                "O2_SUP":"T46",
                "O2_AVE":"T47",
                "O2_RPL":"T48",
                "O2_GAP":"T49",
                "null_72":"T50",
                "O2":"T51",
                "null_74":"T52",
                "null_75":"T53",
                "O3_INC":"T54"
            },
            orgniseColumnsSOGNames :{
                "ID_NUMBER" : "main_group",
                "NAME" : "branch",
                "DESIGNATION" : "division",
                "JG" : "section",
                "BRANCH" : "desig_name",
                "DEPT" :"pay_class",
                "DIVISION" : "C1",
                "SECTION" : "C2",
                "null_11" : "C3",
                "null_12" : "C4",
                "C1_INC" : "O1",
                "C1_SUP" : "O2" ,
                "C1_AVE" : "O3",
                "C1_RPL" : "O4",
                "C1_GAP" : "O5",
                "null_18" : "O6",
                "C1" : "L1",
                "null_20" : "L2",
                "null_21":"L3",
                "C2_INC":"L4",
                "C2_SUP" :"T1",
                "C2_AVE":"T2",
                "C2_RPL":"T3",
                "C2_GAP":"T4",
                "null_27":"T5",
                "C2":"T6",
                "null_29":"T7",
                "null_30":"T8",
                "C3_INC":"T9",
                "C3_SUP":"T10",
                "C3_AVE":"T11",
                "C3_RPL":"T12",
                "C3_GAP":"T13",
                "null_36":"T14",
                "C3":"T15",
                "null_38":"T16",
                "null_39":"T17",
                "C4_INC" : "T18",
                "C4_SUP":"T19",
                "C4_AVE":"T20",
                "C4_RPL":"T21",
                "C4_GAP":"T22",
                "null_45":"T23",
                "C4":"T24",
                "null_47":"T25",
                "null_48":"T26",
                "C5_INC":"T27",
                "C5_SUP":"T28",
                "C5_AVE":"T29",
                "C5_RPL":"T30",
                "C5_GAP":"T31",
                "null_54":"T32",
                "C5":"T33",
                "null_56":"T34",
                "null_57":"T35",
                "O1_INC": "T36",
                "O1_SUP":"T37",
                "O1_AVE":"T38",
                "O1_RPL":"T39",
                "O1_GAP":"T40",
                "null_63":"T41",
                "O1":"T42",
                "null_65":"T43",
                "null_66":"T44",
                "O2_INC":"T45",
                "O2_SUP":"T46",
                "O2_AVE":"T47",
                "O2_RPL":"T48",
                "O2_GAP":"T49",
                "null_72":"T50",
                "O2":"T51",
                "null_74":"T52",
                "null_75":"T53",
                "O3_INC":"T54"
            },
            excludeValues:["Reports, Analysis and Evaluation","Administrative","AML Training",
            "AML Policy Development","Media Relations","Audio-Visual","Vault Operations",
            "Logistics","Gaming Compliance","Gaming Systems","Gaming Manpower and Records Admin.",
            "Gaming Training","Policy Formulation and Development","Casino Operational Request and Evaluation",
            "Gaming Site Operational Request & Evaluation","Bingo Licensing","Electronic Games / Poker Licensing",
            "Project Oversight & Evaluation","Cultural Heritage","Gaming Employment License","GEL Policy Devt.",
            "Verification Inspection","Responsible Gaming","Logistics / Administrative","Records",
            "Financial Operational and Compliance Audit Section","Administrative Section","Data Protection Coordination",
            "Breach Coordination","Technical Support","Help Desk","SOG Support","Admin. Support","Procurement Support",
            "Records Management","Repairs and Maintenance","Radio and Telephone Communications","Systems Admin","Database Admin",
            "Personnel Services and Budget","Banning","Training","Emergency Response Unit","Explosive/ Ordinance Disposal",
            "Photo Laboratory","Criminalistic","Production","Counter Intelligence","PAGCOR Main Corporate","PAGCOR Imus Complex","PAGCOR Executive Office",
            "Supplies Planning","Insurance","Real Estate","Assets/ Records Mgt. & Disposal","Assets & Records Management","Disposal",
            "Fixed Assets Monitoring","Warehouse Supplies","Receiving","Inventory Control","Issuing / Dispatching",
            "Corporate Supplies","Planning and Monitoring","Canvassing","Supplier Services","Records Management","Payment Processing",
            "Regular Procurement (BAC 2 & 4)","Small Value Procurement, DBM & SOG reqt.","Advertising","Creatives","Travel & Trade",
            "Strategic Partnership",""],
            remainingCooperate : [
                {
                    "main_group" : "CORPORATE",
                    "subgroup_name" : "LRG",
                    "department" : "OGLD",
                    "division" : "ADMINISTRATIVE",
                    "section" : 0,
                    "desig_name" : "SR. OFFSHORE GAMING OFFICER",
                    "pay_class" : 10,
                    "C1" : 80,
                    "C2" : 80,
                    "C3" : 80,
                    "C4" : 80,
                    "O1" : 80,
                    "O2" : 80,
                    "O3" : 80,
                    "O4" : 80,
                    "O5" : 80,
                    "O6" : 80,
                    "L1" : 80,
                    "L2" : 80,
                    "L3" : 80,
                    "L4" : 80,
                    "T1" : 0,
                    "T2" : 80,
                    "T3" : 80,
                    "T4" : 80,
                    "T5" : 80,
                    "T6" : 0,
                    "T7" : 0,
                    "T8" : 80,
                    "T9" : 80,
                    "T10" : 80,
                    "T11" : 80,
                    "T12" : 0,
                    "T13" : 0,
                    "T14" : 0,
                    "T15" : 0,
                    "T16" : 0,
                    "T17" : 0,
                    "T18" : 0,
                    "T19" : 0,
                    "T20" : 0,
                    "T21" : 0,
                    "T22" : 0,
                    "T23" : 0,
                    "T24" : 0,
                    "T25" : 0,
                    "T26" : 0,
                    "T27" : 0,
                    "T28" : 0,
                    "T29" : 0,
                    "T30" : 0,
                    "T31" : 0,
                    "T32" : 0,
                    "T33" : 0,
                    "T34" : 0,
                    "T35" : 0,
                    "T36" : 0,
                    "T37" : 0,
                    "T38" : 0,
                    "T39" : 0,
                    "T40" : 0,


                }
            ]
        };
    }

    isString = (value) => {
        return typeof value === 'string' || value instanceof String;
    }

    isNumber = (value) => {
        return typeof value === 'number' && isFinite(value);
    }

    isInt = (n) => {
        return n % 1 === 0;
    }
    
    checkColumnType = (value) => {
        if(this.isNumber(value)){
            if(this.isInt(value)){
                return 'int'
            } else {
                return 'float'
            }
        } else if(this.isString(value)){
            return 'string'
        } else {
            return 'string'
        }
    }

    componentDidMount() {

        const input = document.getElementById('input');
 
        input.addEventListener('change', () => {

            if(this.state.branchName == '') { // check if the branch name is not given.
                
                alert('branch name is required');
                return false;

            } else {

                this.setState({
                    enableLoader : true
                }, () => {
    
                    readXlsxFile(input.files[0]).then((rows) => {
                        // `rows` is an array of rows
                        // each row being an array of cells.

                        this.setState({
                            enableLoader : false
                        }, () => {
                            // console.log("Out of the loop",rows.length);
                            // return false;
                            var dataJsonMain = [];
                            if(rows && rows.length) {
                                // var valuesArray = rows[1];
                                // console.log("rows:",rows)
                                rows.map((row, key) => {
                                   
                                    
                                    var obj = {};
                                    if(key > 2) { // ignore the heading row from sheet
                                        // console.log("row:",row)
                                        row.length && row.map((data, key2) => {
                                            
                                            // console.log(key2,"=====",data)
                                            console.log(columnsData[key2].col_name,"===",data);
                                            if (data =="-" || data == null) {
                                                // obj[this.state.orgniseColumnsNames[columnsData[key2].col_name]] = 0;
                                                if (typeof this.state.orgniseColumnsNames[columnsData[key2].col_name] !== "undefined") {
                                                    obj[this.state.orgniseColumnsNames[columnsData[key2].col_name]] = 0;
                                                }
                                            }
                                            else {
                                                // if (this.state.excludeValues.includes(data))
                                                //     obj[this.state.orgniseColumnsNames[columnsData[key2].col_name]] = null;
                                                // else
                                                // if (typeof this.state.orgniseColumnsNames[columnsData[key2].col_name] !== "undefined")
                                                    obj[this.state.orgniseColumnsNames[columnsData[key2].col_name]] = data;
                                            }
                                            //return false;
                                           
                                            // if( this.state.requiredNames.includes(columnsData[key2].col_name) ) {
                                            //     // console.log(data,"In other loop:");
                                            //     if(columnsData[key2].col_name == "FIRST_NAME" && data !="SUB-TOTAL OFFICE OF THE AVP" &&
                                            //     data !="SUB-TOTAL AML COMPLIANCE MONITORING" && data !="SUB-TOTAL TRAINING, POLICY DEVELOPMENT, REPORTS AND ADMIN.") {
                                                    
                                            //         if( this.state.divisions.includes(data) ) {
                                            //             console.log("This division data:",data);
                                            //             //division = data;
                                            //             obj['division'] = data;
                                            //             if( this.state.sections.includes(data) ) {
                                            //                 obj['section'] = data;
                                            //             }
                                            //             else {
                                            //                 obj['section'] = "null";
                                            //             }
                                            //         }
                                            //         else {
                                            //             obj['division'] = "null";
                                            //             if( this.state.sections.includes(data) ) {
                                            //                 obj['section'] = data;
                                            //             }
                                            //             else {
                                            //                 obj['section'] = "null";
                                            //             }
                                            //         }
                                                    
                                            //     }
                                            //     if(columnsData[key2].col_name == "MI" && data !=null && this.checkColumnType(data) == "string") {
                                            //         obj['JG'] = data 
                                            //     }
                                            //     if(columnsData[key2].col_name == "NAME" && data !=null && this.checkColumnType(data) == "string") {
                                            //         obj['designation'] = data 
                                            //     } 
                                            // }

                                        })
                                        // console.log("out of the loop.");
                                        //console.log("obj:",obj)
                                        //if(Object.keys(obj).length)
                                            dataJsonMain.push(obj);
                                    }
                                    //console.log("row:",key)
                                    if((rows.length - 1) == key) {

                                        // console for all rows data in a sheet which is imported
                                        console.log("dataJsonMain:",dataJsonMain);

                                        this.props.uploadDataDept(
                                            this.state.branchName, // this is selected branch
                                            dataJsonMain // this is data for each row
                                            // this.state.counter
                                        );

                                    }

        
                                });
        
                                
                                // rows[0].length && rows[0].map((row, key) => {
                                //     dataJson.push({
                                //         col_name : row != null ? row.replace(" ", "_") : 'null_' + key,
                                //         col_type : this.checkColumnType(valuesArray[key])
                                //     })
        
                                //     if(rows[0].length == (key + 1)){
                                //         console.log(dataJson);
                                //         console.log(JSON.stringify(dataJson));
                                //     }
        
                                // })
                            }
        
                        })
        
                    })
    
                })

            }
            
        })
        
    }

    addColumnsToDB = () => {
        console.log(columnsData);

        var bodyFormData = new FormData();
        // var userdata = getUserData();
        bodyFormData.set('data', JSON.stringify(columnsData));

        axios.post('http://localhost:8000/api/branch/tableschema', bodyFormData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
        .then((res) => {
            console.log(res);
        })
        .catch((error) => {
            console.log(error);
        });
    }

    // sendData = (dataForRow) => {

    //     var bodyFormData = new FormData();
    //     // var userdata = getUserData();
    //     bodyFormData.set('final_data', JSON.stringify(dataForRow));
    //     bodyFormData.set('branch_name', this.state.branchName);

    //     axios.post('http://localhost:8000/api/branch/add', bodyFormData, {
    //         headers: {
    //             'Content-Type': 'multipart/form-data'
    //         }
    //     })
    //     .then((res) => {
    //         console.log(res);
    //     })
    //     .catch((error) => {
    //         console.log(error);
    //     });
    // }


  render() {

    return (
      <div className="HomeComponent">
        <Header />

        <div className={"BannerWithText " + this.state.theme}>

            <Container>
                <Row>
                    <Col style={{textAlign: 'left'}}>
                        <br />
                        <h2 style={{margin: '10px 0'}}>Import Data</h2><br />
                        
                        Dept Name: <br />
                        <input type="text" value={this.state.branchName} id="branchName" onChange={e => {
                            this.setState({
                                branchName : e.target.value
                            }, () => {
                                console.log(this.state.branchName)
                            })
                        }} /> <br /><br />

                        Upload excel sheet: <br />
                        <input disabled={this.state.branchName == '' || this.state.enableLoader} type="file" id="input" />

                        {
                            this.state.enableLoader ?
                                <h3>Loading...</h3>
                            : null
                        }
                        
                        <br/><br />

                        {/* <button disabled={this.state.branchName == '' || this.state.enableLoader} onClick={e => this.sendData(this.state.branchName)}>Send Data</button> */}

                        {/* <button onClick={(e) => this.addColumnsToDB()}>add Columns</button> */}

                    </Col>
                </Row>
            </Container>

        </div>
        
        {/* <Footer /> */}
      </div>
    );
  }
}

ImportDeptSheetComponent.propTypes = {
    uploadDataDept: PropTypes.func.isRequired
}

const mapStatesToProps = state => ({
    dataBranchSuccessful : state.webRed.dataBranchSuccessful
})

export default connect( mapStatesToProps, { uploadDataDept })( ImportDeptSheetComponent );