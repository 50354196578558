import React, { Component } from "react";
// import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
// import {browserHistory} from "react-router";
// import "./../../../public/scss/talentMap_statistics.scss";
// import Header from '../../website/Header';
// import Footer from "../../website/Footer";

// import store from './../../store';
import PropTypes from 'prop-types';
import { branchAvgDMERecord, divisionDMERecord, sectionDMERecord } from '../../../actions/webRedActions';
// import { validateEmailAddress } from './../../actions/utility';
import { connect } from 'react-redux';

import {
    Container,
    Row,
    Col,
    Tooltip,
    Button,
    Spinner,
    FormGroup,
    Input,
    Media,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter
} from "reactstrap";

import { ResponsiveBar } from '@nivo/bar';

import './../../../public/scss/DMEProfile.scss';

import devisionicon from './../../../public/images/branches/divisionIcon2.png';
import sectionicon from './../../../public/images/branches/sectionIcon2.png';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMale, faFemale } from '@fortawesome/free-solid-svg-icons';

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faMale } from '@fortawesome/free-solid-svg-icons';


class DMEProfileComponent extends Component {

    constructor(props) {
        super(props);

        this.state = {
            // data : this.props.data,
            respondentsCatogorizeDMEData: '',

            dataMappingArray: [{
                name: 'Deficient'
            }, {
                name: 'Matching'
            }, {
                name: 'Exceeding'
            }],
            allBranches: this.props.allData.allBranches,
            branches: this.props.allData.branches,
            branchIndex: this.props.allData.branchIndex,
            competencyIndex: this.props.allData.competencyIndex,
            divisionIndex: this.props.allData.divisionIndex,
            sectionIndex: this.props.allData.sectionIndex,

            compComparisonDataDME: {
                keys: [],
                data: []
            },
            compComparisonDataDivisionWiseDME: {
                keys: [],
                data: []
            },
            compComparisonDataBranchWiseTempDivisionArrayDME: [], // temporary random scores array for persistency while switching in different divisions to show in sections comparison chart
            compComparisonDataDivisionWiseTempDivisionArrayDME: [],
            compComparisonDataSectionWiseDME: {
                keys: [],
                data: []
            },
            selectedBranchIdValue: this.props.allData.selectedBranchIdValue,
            dataMappingArrayDME: [{
                name: 'D'
            }, {
                name: 'M'
            }, {
                name: 'E'
            }],
            compComparisonDataDMEApi: {
                keys: [],
                data: []
            },
            branchName: "",
            allDivisions: [],
            allSections: [],
            divisionName: '',
            sectionName: '',
            compComparisonDataDivisionWiseDMEApi: {
                keys: [],
                data: []
            },
            compComparisonDataSectionWiseDMEApi: {
                keys: [],
                data: []
            },
            mainCompetency: [],
            respondentsCatogorizeDMEDataApi: {
                data: []
            },
            competencyName: '',
            competencyIndexs: '',
            peopleInSection: '',
            selectedYear: '',
            sectionHideGraph: false,
            divisionHideGraph: false,
            dmeCategorisedHideGraph: false
        };

    }

    componentDidMount() {
        // console.log(this.state.data);
        // var tempTooltipArray = [];
        // for(var i=0; i<=7; i++){
        //     tempTooltipArray[i] = false;
        // }
        // this.setState({
        //     tooltipOpen : tempTooltipArray
        // })

        this.DMEDataMapping(this.state.branchIndex, this.state.competencyIndex);
        // this.props.branchAvgDMERecord(this.state.selectedBranchIdValue , "2019");
    }

    componentWillReceiveProps(nextProps, thisProps) {
        // if (this.props.allData.allDivisions != nextProps.allData.allDivisions) {
        //     debugger;
        //     this.setState({
        //         divisionName: nextProps.allData.allDivisions[0].DIVISION
        //     })
        // }
        // if (nextProps.allData.allSections.length > 0 && this.props.allData.allSections != nextProps.allData.allSections) {
        //     this.setState({
        //         sectionName: nextProps.allData.allSections[0].SECTION
        //     })
        // }
        this.setState({
            // data : nextProps.data,

            allBranches: nextProps.allData.allBranches,
            branches: nextProps.allData.branches,
            branchIndex: nextProps.allData.branchIndex,
            competencyIndex: nextProps.allData.competencyIndex,
            divisionIndex: nextProps.allData.divisionIndex,
            sectionIndex: nextProps.allData.sectionIndex,
            selectedBranchIdValue: nextProps.allData.selectedBranchIdValue,
            branchName: nextProps.allData.branchName,
            // allDivisions: nextProps.allData.allDivisions,
            // allSections: nextProps.allData.allSections,
            mainCompetency: nextProps.allData.mainCompetency,
            selectedYear: nextProps.allData.selectedYear,
            divisionHideGraph: nextProps.allData.divisionHideGraph,
            // sectionHideGraph: nextProps.allData.sectionHideGraph

        }, () => {
            console.log(nextProps);

            this.averageWithAllBranchesMappingDME();

            this.DMEDataMapping(this.state.branchIndex, this.state.competencyIndex);
        })

        if (this.props.allData.selectedBranchIdValue != nextProps.allData.selectedBranchIdValue) {
            debugger;
            this.setState({
                compComparisonDataDMEApi: {
                    keys: [],
                    data: []
                },
                respondentsCatogorizeDMEDataApi: {
                    data: []
                },
                compComparisonDataDivisionWiseDMEApi: {
                    keys: [],
                    data: []
                },
                compComparisonDataSectionWiseDMEApi: {
                    keys: [],
                    data: []
                },
                peopleInSection: 0,
                competencyIndexs: 0,
                selectedBranchIdValue: nextProps.allData.selectedBranchIdValue,
            }, () => {
                // this.props.branchAvgDMERecord(this.state.selectedBranchIdValue , this.state.selectedYear);
            })
            // this.props.divisionDMERecord(this.state.selectedBranchIdValue , '2019' , this.state.allDivisions[0].DIVISION)
        }


        else if (this.props.allData.selectedYear != nextProps.allData.selectedYear) {
            debugger;
            this.setState({
                compComparisonDataDMEApi: {
                    keys: [],
                    data: []
                },
                respondentsCatogorizeDMEDataApi: {
                    data: []
                },
                compComparisonDataDivisionWiseDMEApi: {
                    keys: [],
                    data: []
                },
                compComparisonDataSectionWiseDMEApi: {
                    keys: [],
                    data: []
                },
                peopleInSection: 0,
                competencyIndexs: 0,
                selectedBranchIdValue: nextProps.allData.selectedBranchIdValue,
            }, () => {
                // this.props.branchAvgDMERecord(this.state.selectedBranchIdValue , this.state.selectedYear);
            })
        }

        else if(this.props.divisionsAndSections != nextProps.divisionsAndSections){
            debugger;
            if (nextProps.divisionsAndSections.division.length > 0) {
                if (nextProps.divisionsAndSections.section[nextProps.divisionsAndSections.division[0].DIVISION].length > 0) {
                        this.setState({
                            allDivisions: nextProps.divisionsAndSections.division,
                            allSections: nextProps.divisionsAndSections.section[nextProps.divisionsAndSections.division[0].DIVISION],
                            divisionName: nextProps.divisionsAndSections.division[0].DIVISION,
                            sectionName: nextProps.divisionsAndSections.section[nextProps.divisionsAndSections.division[0].DIVISION][0].SECTION,
                        })
                }
                else{
                        this.setState({
                            allDivisions: nextProps.divisionsAndSections.division,
                            allSections: nextProps.divisionsAndSections.section[nextProps.divisionsAndSections.division[0].DIVISION],
                            divisionName: nextProps.divisionsAndSections.division[0].DIVISION,
                            sectionName: "",
                            sectionHideGraph: true
                        })
                }
            }
            else {
                this.setState({
                  divisionHideGraph: true,
                  sectionHideGraph: true
                })
              }
    }
        else if (this.props.branchAvgDme != nextProps.branchAvgDme) {
            debugger;
            try {
                var dataArray = [];
                this.state.dataMappingArrayDME.map((type, key) => {
                    dataArray.push({
                        "type": type.name,
                        [this.state.branchName]: nextProps.branchAvgDme.branchData[type.name].perc,
                        "All Branches": nextProps.branchAvgDme.allData[type.name].perc
                    });
                })


                this.setState({
                    compComparisonDataDMEApi: {
                        keys: ["All Branches", this.state.branchName],
                        data: dataArray
                    },
                }, () => {
                    console.log(this.state.compComparisonDataDMEApi);
                })
            }
            catch (err) {
                debugger;
                window.alert(err)
            }
        }


        else if (this.props.dmeByDivision != nextProps.dmeByDivision) {
            debugger;
            var dataArray = [];
            this.state.dataMappingArrayDME.map((type, key) => {
                dataArray.push({
                    "type": type.name,
                    [this.state.branchName]: nextProps?.branchAvgDme?.branchData?.[type.name]?.perc,
                    [this.state.divisionName]: nextProps?.dmeByDivision?.divisionData?.[type.name]?.perc,
                    "All Branches": nextProps?.branchAvgDme?.allData?.[type.name]?.perc
                });
            })

            this.setState({
                compComparisonDataDivisionWiseDMEApi: {
                    keys: ["All Branches", this.state.branchName, this.state.divisionName],
                    data: dataArray
                },
                divisionHideGraph: false
            }, () => {
                console.log(this.state.compComparisonDataDivisionWiseDMEApi);
                if (this.state.allSections.length > 0) {
                    debugger;
                    this.setState({
                        sectionHideGraph: false
                    }, () => {
                        this.props.sectionDMERecord(this.state.selectedBranchIdValue, this.state.selectedYear, this.state.sectionName)
                    })
                }
                else {
                    debugger;
                    this.setState({
                        sectionHideGraph: true
                    })
                }
            })

            if (nextProps.dmeBySection != "") {
                debugger;
                var dataArray = [];
                this.state.dataMappingArrayDME.map((type, key) => {
                    dataArray.push({
                        "type": type.name,
                        [this.state.branchName]: nextProps.branchAvgDme.branchData[type.name].perc,
                        [this.state.divisionName]: nextProps.dmeByDivision.divisionData[type.name].perc,
                        [this.state.sectionName]: nextProps.dmeBySection.sectionData[type.name].perc,
                        "All Branches": nextProps.branchAvgDme.allData[type.name].perc
                    });
                })

                this.setState({
                    compComparisonDataSectionWiseDMEApi: {
                        keys: ["All Branches", this.state.branchName, this.state.divisionName, this.state.sectionName],
                        data: dataArray
                    }
                }, () => { })

            }
            // else {
            //     debugger;
            //     this.props.sectionDMERecord(this.state.selectedBranchIdValue , this.state.selectedYear , this.state.sectionName)
            // }

        }


        else if (this.props.dmeBySection != nextProps.dmeBySection) {
            debugger;
            var dataArray = [];
            this.state.dataMappingArrayDME.map((type, key) => {
                dataArray.push({
                    "type": type.name,
                    [this.state.branchName]: nextProps?.branchAvgDme?.branchData?.[type.name]?.perc,
                    [this.state.divisionName]: nextProps?.dmeByDivision?.divisionData?.[type.name]?.perc,
                    [this.state.sectionName]: nextProps?.dmeBySection?.sectionData?.[type.name]?.perc,
                    "All Branches": nextProps?.branchAvgDme?.allData?.[type.name]?.perc
                });
            })

            var SectionTotal = nextProps.dmeBySection.sectionData['D'].totall + nextProps.dmeBySection.sectionData['E'].totall + nextProps.dmeBySection.sectionData['M'].totall;
            this.setState({
                peopleInSection: SectionTotal
            })
            this.setState({
                compComparisonDataSectionWiseDMEApi: {
                    keys: ["All Branches", this.state.branchName, this.state.divisionName, this.state.sectionName],
                    data: dataArray
                }
            }, () => {
                console.log(this.state.compComparisonDataDivisionWiseDMEApi);
            })
        }

        else if (this.props.allData.dmeCategorised != nextProps.allData.dmeCategorised) {
            var tempArrayData = [];
            var objectKeys = [];
            var check = Object.keys(nextProps.allData.dmeCategorised).length > 0;
            debugger;
            if (Object.keys(nextProps.allData.dmeCategorised.comptwiseData).length > 0) {
                objectKeys = Object.keys(nextProps.allData.dmeCategorised.comptwiseData[nextProps.allData.mainCompetency[0].competency_name]);
                objectKeys.map((item, index) => {
                    tempArrayData.push({
                        "competency": item,
                        "Deficient": nextProps?.allData?.dmeCategorised?.comptwiseData[nextProps.allData.mainCompetency[0]?.competency_name][item]['D']?.totall,
                        "Matching": nextProps?.allData?.dmeCategorised?.comptwiseData[nextProps.allData.mainCompetency[0]?.competency_name][item]['M']?.totall,
                        "Exceeding": nextProps?.allData.dmeCategorised?.comptwiseData?.[nextProps.allData.mainCompetency[0]?.competency_name][item]['E']?.totall
                    })
                })
                
                console.log("HAMZA is on:", tempArrayData)
                console.log("HAMZA Object Keys:", Object.keys(nextProps.allData.dmeCategorised.comptwiseData[nextProps.allData.mainCompetency[0].competency_name]))
                setTimeout(() => {
                    this.setState({
                        respondentsCatogorizeDMEDataApi: {
                            data: tempArrayData,
                            keys: ['Deficient', 'Matching', 'Exceeding'],
                            indexBy: 'competency'
                        },
                        competencyName: this.state.mainCompetency[0].competency_name,
                        dmeCategorisedHideGraph: false
                    },
                        () => {
                            console.log(this.state.respondentsCatogorizeDMEDataApi);
                        })

                }, 200)
            }
            else {
                debugger;
                this.setState({
                    dmeCategorisedHideGraph: true
                })
            }
        }
    }

    categorizedDMEChange = (branchIndex, competencyIndex) => {
        this.setState({
            respondentsCatogorizeDMEDataApi: {
                data: []
            },
            competencyName: this.state.mainCompetency[competencyIndex].competency_name
        })
        var tempArrayData = [];
        var objectKeys = [];
        debugger;
        objectKeys = Object.keys(this.props.allData.dmeCategorised.comptwiseData[this.props.allData.mainCompetency[competencyIndex].competency_name]);
        objectKeys.map((item, index) => {
            tempArrayData.push({
                "competency": item,
                "Deficient": this.props.allData.dmeCategorised.comptwiseData[this.props.allData.mainCompetency[competencyIndex].competency_name][item]['D'].totall,
                "Matching": this.props.allData.dmeCategorised.comptwiseData[this.props.allData.mainCompetency[competencyIndex].competency_name][item]['M'].totall,
                "Exceeding": this.props.allData.dmeCategorised.comptwiseData[this.props.allData.mainCompetency[competencyIndex].competency_name][item]['E'].totall
            })
        })
        debugger;
        console.log("HAMZA is on:", tempArrayData)
        console.log("HAMZA Object Keys:", Object.keys(this.props.allData.dmeCategorised.comptwiseData[this.props.allData.mainCompetency[competencyIndex].competency_name]))
        setTimeout(() => {
            this.setState({
                respondentsCatogorizeDMEDataApi: {
                    data: tempArrayData,
                    keys: ['Deficient', 'Matching', 'Exceeding'],
                    indexBy: 'competency'
                }
            },
                () => {
                    console.log(this.state.respondentsCatogorizeDMEDataApi);
                })

        }, 200)
    }

    DMEDataMapping = (branchIndex, competencyIndex) => {

        console.log(branchIndex, competencyIndex);

        const { branches } = this.state;
        // mapping of DME pie charts data starts here
        var tempArrayData = [];
        // var tempArrayKeys = [];
        // var d_array = [];
        // var d_totalNumber = 0;

        // var m_array = [];
        // var m_totalNumber = 0;

        // var e_array = [];
        // var e_totalNumber = 0;

        branches[branchIndex].mainCompetencies[competencyIndex].subCompetencies.map((comp, key) => {
            tempArrayData.push({
                "competency": comp?.name,
                "Deficient": comp?.d_total,
                "Matching": comp?.m_total,
                "Exceeding": comp?.e_total
            })
            // d_array.push({
            //     "id" : comp.name,
            //     "label" : comp.name,
            //     "value" : comp.d_total ? comp.d_total : 1
            // });
            // d_totalNumber += comp.d_total ? comp.d_total : 1;

            // m_array.push({
            //     "id" : comp.name,
            //     "label" : comp.name,
            //     "value" : comp.m_total ? comp.m_total : 1
            // });
            // m_totalNumber += comp.m_total ? comp.m_total : 1;

            // e_array.push({
            //     "id" : comp.name,
            //     "label" : comp.name,
            //     "value" : comp.e_total ? comp.e_total : 1
            // });
            // e_totalNumber += comp.e_total ? comp.e_total : 1;
        })

        setTimeout(() => {

            this.setState({
                // respondentsCatogorizeDMEData : [{
                //         name : "deificient",
                //         heading : "<span>D</span>eficient",
                //         para : "Participants whose average score lies under required competencies score",
                //         data : d_array,
                //         total : d_totalNumber,
                //     },{
                //         name : "matching",
                //         heading : "<span>M</span>atching",
                //         para : "Participants whose average score is matching the required competencies score",
                //         data : m_array,
                //         total : m_totalNumber
                //     },{
                //         name : "exceeding",
                //         heading : "<span>E</span>xceeding",
                //         para : "Participants whose average score is exceeding the required competencies score",
                //         data : e_array,
                //         total : e_totalNumber
                //     }]
                respondentsCatogorizeDMEData: {
                    data: tempArrayData,
                    keys: ['Deficient', 'Matching', 'Exceeding'],
                    indexBy: 'competency'
                }
            },
                () => {
                    console.log(this.state.respondentsCatogorizeDMEData);
                })

        }, 200);

        // mapping of DME pie charts data ends here

    }

    getRandomDMEValue = (min, max) => {
        return parseInt(Math.random() * (max - min) + min);
    }

    averageWithAllBranchesMappingDME = () => {

        // mapping data for comparison all branches chart for DME

        const { branches, branchIndex, competencyIndex, allBranches, divisionIndex, sectionIndex } = this.state;

        var dataArray = [];
        var tempArrayRandomVal = [];
        this.state.dataMappingArray.map((type, key) => {
            var randomValue = this.getRandomDMEValue(10, 43);
            tempArrayRandomVal.push(randomValue);
            // if(key == 1) {
            //     randomValue = this.getRandomDMEValue(10, 43);
            // }
            // if(key == 2) {
            //     randomValue = this.getRandomDMEValue(10, 43);
            // }
            dataArray.push({
                "type": type.name,
                [branches[branchIndex].name]: randomValue,
                "All Branches": key == 0 ? 36 : key == 1 ? 21 : 42
            });
        })

        this.setState({
            compComparisonDataDME: {
                keys: ["All Branches", branches[branchIndex].name],
                data: dataArray
            }
        }, () => {
            console.log(this.state.compComparisonDataDME);

            this.setState({
                compComparisonDataBranchWiseTempDivisionArrayDME: tempArrayRandomVal
            }, () => {
                this.averageWithBranchesMappingForDivisionsDME(); // reflects the divisions chart
            })
        })

    }

    averageWithBranchesMappingForDivisionsDME = () => {

        // mapping data for comparison all branches chart

        const { branches, branchIndex, allBranches, divisionIndex } = this.state;

        var dataArrayDivision = [];
        var tempArrayRandomVal = [];
        this.state.dataMappingArray.map((type, key) => {

            //temperory work done starts here
            var randomValue = this.getRandomDMEValue(10, 43);
            tempArrayRandomVal.push(randomValue);
            //temperory work done stops here

            dataArrayDivision.push({
                "type": type.name,
                [branches[branchIndex].divisions[divisionIndex].name]: randomValue, // temporary random average score
                [branches[branchIndex].name]: this.state.compComparisonDataBranchWiseTempDivisionArrayDME[key], // temporary random average score,
                "All Branches": key == 0 ? 36 : key == 1 ? 21 : 42
            });
        })

        this.setState({
            compComparisonDataDivisionWiseDME: {
                keys: ["All Branches", branches[branchIndex].name, branches[branchIndex].divisions[divisionIndex].name],
                data: dataArrayDivision
            }
        }, () => {
            console.log(this.state.compComparisonDataDivisionWiseDME);

            this.setState({
                compComparisonDataDivisionWiseTempDivisionArrayDME: tempArrayRandomVal
            }, () => {
                this.averageWithBranchesMappingForSectionsDME(); // reflects the sections chart
            })
        })


    }

    averageWithBranchesMappingForSectionsDME = () => {

        const { branches, branchIndex, allBranches, divisionIndex, sectionIndex } = this.state;

        var dataArraySection = [];
        this.state.dataMappingArray.map((type, key) => {
            dataArraySection.push({
                "type": type.name,
                [branches[branchIndex].divisions[divisionIndex].sections[sectionIndex].name]: this.getRandomDMEValue(10, 43), // temporary random average score,
                [branches[branchIndex].divisions[divisionIndex].name]: this.state.compComparisonDataDivisionWiseTempDivisionArrayDME[key], // temporary random average score
                [branches[branchIndex].name]: this.state.compComparisonDataBranchWiseTempDivisionArrayDME[key],
                "All Branches": key == 0 ? 36 : key == 1 ? 21 : 42
            });
        })

        this.setState({
            compComparisonDataSectionWiseDME: {
                keys: ["All Branches", this.state.branchName, branches[branchIndex]?.divisions[divisionIndex]?.name, branches[branchIndex]?.divisions[divisionIndex]?.sections[sectionIndex]?.name],
                data: dataArraySection
            }
        }, () => {
            console.log(this.state.compComparisonDataSectionWiseDME);
        })

    }

    divisionDropDownChange = () => {
        debugger;
        if(this.props.divisionsAndSections.section[this.state.divisionName].length > 0){
          this.setState({
            sectionName: this.props.divisionsAndSections.section[this.state.divisionName][0].SECTION,
          })
        }
        this.setState({
          allSections: this.props.divisionsAndSections.section[this.state.divisionName],
        })
        this.props.divisionDMERecord(this.state.selectedBranchIdValue, this.state.selectedYear, this.state.divisionName)
    }
    render() {

        // let dataCompelete = this.state.data;

        const { branches, allBranches, branchIndex, competencyIndex, divisionIndex, sectionIndex } = this.state;

        return (
            <div style={{ textAlign: 'center' }}>

                {/* <Media style={{width: '100%'}} object src={samProfile} alt="" /> */}

                <Row className="DMEProfileBox" style={{ background: '#0d1623' }}>
                    {/* <Col md={{size: '8', offset: '2'}} style={{color: '#fff', fontSize: '1rem', marginBottom: '7vh'}}>
                    A SAM profile can be created for each city. The SAM profile is brokendown into 12 different archetypes, each one of which describe an individuals skill level, how actively there are looking for a job, how motivated they are excel in their career. The graph below (illustrates what our city's SAM profile could look like). if you would like a SAM profile to be created for your city please<br/><span style={{textDecoration: 'underline', cursor: 'pointer'}} onClick=
                    {e => this.props.triggerFormIframe('info')} >schedule an appointment to meet with us</span>
                </Col> */}

                    {/* {
                    dataCompelete.map((item, key) => 
                    <Col md={{size: 12}} className="col" key={key}>
                        <h3 dangerouslySetInnerHTML={{__html: item.heading}}></h3>
                        <p style={{fontSize: '1.2rem', marginBottom: '15px'}} dangerouslySetInnerHTML={{__html: item.para}}></p>

                        <div style={{height: '500px', marginBottom: '30px'}}>
                            <ResponsivePie
                                data={item.data}
                                margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
                                innerRadius={0.5}
                                padAngle={0.7}
                                cornerRadius={3}
                                colors={{ scheme: key == 0 ? 'spectral' : key == 1 ? 'category10' : 'nivo' }}
                                borderWidth={1}
                                borderColor={{ from: 'color', modifiers: [ [ 'darker', 0.2 ] ] }}
                                radialLabelsSkipAngle={10}
                                radialLabelsTextXOffset={6}
                                radialLabelsTextColor="#333333"
                                radialLabelsLinkOffset={0}
                                radialLabelsLinkDiagonalLength={16}
                                radialLabelsLinkHorizontalLength={24}
                                radialLabelsLinkStrokeWidth={1}
                                radialLabelsLinkColor={{ from: 'color' }}
                                sliceLabel={function(e){return parseFloat((e.value / item.total)*100).toFixed(1) + '%'}}
                                slicesLabelsSkipAngle={10}
                                slicesLabelsTextColor="#333333"
                                animate={true}
                                motionStiffness={90}
                                motionDamping={15}
                                defs={[
                                    {
                                        id: 'dots',
                                        type: 'patternDots',
                                        background: 'inherit',
                                        color: 'rgba(255, 255, 255, 0.3)',
                                        size: 4,
                                        padding: 1,
                                        stagger: true
                                    },
                                    {
                                        id: 'lines',
                                        type: 'patternLines',
                                        background: 'inherit',
                                        color: 'rgba(255, 255, 255, 0.3)',
                                        rotation: -45,
                                        lineWidth: 6,
                                        spacing: 10
                                    }
                                ]}
                                fill={[
                                    {
                                        match: {
                                            id: 'ruby'
                                        },
                                        id: 'dots'
                                    },
                                    {
                                        match: {
                                            id: 'c'
                                        },
                                        id: 'dots'
                                    },
                                    {
                                        match: {
                                            id: 'go'
                                        },
                                        id: 'dots'
                                    },
                                    {
                                        match: {
                                            id: 'python'
                                        },
                                        id: 'dots'
                                    },
                                    {
                                        match: {
                                            id: 'scala'
                                        },
                                        id: 'lines'
                                    },
                                    {
                                        match: {
                                            id: 'lisp'
                                        },
                                        id: 'lines'
                                    },
                                    {
                                        match: {
                                            id: 'elixir'
                                        },
                                        id: 'lines'
                                    },
                                    {
                                        match: {
                                            id: 'javascript'
                                        },
                                        id: 'lines'
                                    }
                                ]}
                            />
                        </div>

                    </Col>
                    )
                } */}


                    {/* <Col md={{size: 8, offset: 1}}>
                            <p style={{color: '#fff', textAlign: 'left', marginBottom: '20px'}}>
                                Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.. 
                            </p>
                        </Col> */}

                    <Col md={{ size: 12 }}>

                        <div className="chartBox" style={{ background: 'transparent' }}>
                            <div style={{ height: '400px', position: 'relative' }}>

                                {
                                    this.state.compComparisonDataDMEApi.data.length == 0 ?
                                        <div className="customSpinnerChart">
                                            <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                                        </div>
                                        :
                                        <ResponsiveBar
                                            data={this.state.compComparisonDataDMEApi.data}
                                            keys={this.state.compComparisonDataDMEApi.keys}
                                            indexBy="type"
                                            margin={{ top: 60, right: 100, bottom: 60, left: 100 }}
                                            padding={0.6}
                                            innerPadding={10}
                                            label={d => `${d.value}%`}
                                            // onClick={e => this.barChartClicked(e)}
                                            groupMode="grouped"
                                            colors={["#3d1f42", "#542b5a", "#68366f", "#93519c", "#68356f", "#93519c"]}
                                            // colors={["#f706d5", "#770367" ]}
                                            // colors={["#68366f", "#3d1f42" ]}
                                            layout="vertical"
                                            enableGridY={false}
                                            defs={[
                                                {
                                                    id: 'dots',
                                                    type: 'patternDots',
                                                    background: 'inherit',
                                                    color: '#38bcb2',
                                                    size: 4,
                                                    padding: 1,
                                                    stagger: true
                                                },
                                                {
                                                    id: 'lines',
                                                    type: 'patternLines',
                                                    background: 'inherit',
                                                    color: '#eed312',
                                                    rotation: -45,
                                                    lineWidth: 6,
                                                    spacing: 10
                                                }
                                            ]}
                                            fill={[
                                                {
                                                    match: {
                                                        id: 'fries'
                                                    },
                                                    id: 'dots'
                                                },
                                                {
                                                    match: {
                                                        id: 'sandwich'
                                                    },
                                                    id: 'lines'
                                                }
                                            ]}
                                            borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                                            axisTop={null}
                                            axisRight={null}
                                            axisBottom={{
                                                tickSize: 5,
                                                tickPadding: 5,
                                                tickRotation: 0,
                                                legend: '',
                                                legendPosition: 'middle',
                                                legendOffset: 50
                                            }}
                                            axisLeft={{
                                                tickSize: 5,
                                                tickPadding: 5,
                                                tickRotation: 0,
                                                legend: '',
                                                legendPosition: 'middle',
                                                legendOffset: -60
                                            }}
                                            labelSkipWidth={12}
                                            labelSkipHeight={12}
                                            labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                                            legends={[
                                                {
                                                    dataFrom: 'keys',
                                                    anchor: 'top-right',
                                                    direction: 'column',
                                                    justify: false,
                                                    translateX: -15,
                                                    translateY: -60,
                                                    itemsSpacing: 2,
                                                    itemWidth: 100,
                                                    itemHeight: 25,
                                                    itemDirection: 'left-to-right',
                                                    itemOpacity: 0.85,
                                                    symbolSize: 20,
                                                    effects: [
                                                        {
                                                            on: 'hover',
                                                            style: {
                                                                itemOpacity: 1
                                                            }
                                                        }
                                                    ]
                                                }
                                            ]}
                                            animate={true}
                                            motionStiffness={90}
                                            motionDamping={15}
                                        />

                                }
                            </div>
                        </div>
                    </Col>

                    
                    {this.state.divisionHideGraph ? null :
                    <React.Fragment>

                    <div className="seperatorHeading">
                        Average score of {this.state.divisionName} with in {this.state.branchName}

                        <select className="customDropDown" onChange={e => {
                            this.setState({
                                sectionIndex: 0, // reset the sectionIndex
                                divisionIndex: 0,
                                divisionName: e.target.value,
                                compComparisonDataDivisionWiseDMEApi: {
                                    keys: [],
                                    data: []
                                },
                            }, () => {
                                this.divisionDropDownChange();
                                // this.props.divisionDMERecord(this.state.selectedBranchIdValue, this.state.selectedYear, this.state.divisionName)
                            })
                        }}>
                            {
                                this.state.allDivisions.map((division, key) =>
                                    <option key={key} value={division.DIVISION}>{division.DIVISION}</option>
                                )
                            }
                        </select>

                    </div>

                    {/* <Col md={{ size: 8, offset: 1 }}>
                        
                        <p style={{color: '#7ba2dc', textAlign: 'left', marginTop: '30px'}}>
                        This bar chart highlights the performance for the Divisions in {this.state.branchName} for each of the four main competencies and has three points of relevance and comparison:
                        <ol style={{marginTop: '20px'}}>
                            <li>Average percentage score of each of the {branches[branchIndex].totalDivisions} divisions for {this.state.branchName}</li>
                            <li>Average percentage score of each Division in {this.state.branchName} as compared to the {this.state.branchName}'s overall scores</li>
                            <li>Average percentage score of each Division in {this.state.branchName} as compared to overall result for this assessment</li>
                        </ol>
                        </p>

                    </Col> */}

                    <Col md={{ size: 9 }} style={{ paddingTop: '25px' }}>
                        <div className="chartBox" style={{ background: 'transparent' }}>
                            <div style={{ height: '400px', position: 'relative' }}>
                                {
                                    this.state.compComparisonDataDivisionWiseDMEApi.data.length == 0 ?
                                        <div className="customSpinnerChart">
                                            <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                                        </div>
                                        :
                                        <ResponsiveBar
                                            data={this.state.compComparisonDataDivisionWiseDMEApi.data}
                                            keys={this.state.compComparisonDataDivisionWiseDMEApi.keys}
                                            indexBy="type"
                                            margin={{ top: 100, right: 0, bottom: 60, left: 100 }}
                                            padding={0.3}
                                            innerPadding={10}
                                            label={d => `${d.value}%`}
                                            // onClick={e => this.barChartClicked(e)}
                                            groupMode="grouped"
                                            colors={["#3d1f42", "#542b5a", "#68366f", "#93519c", "#68356f", "#93519c"]}
                                            // colors={["#f706d5", "#770367" ]}
                                            // colors={["#68366f", "#3d1f42" ]}
                                            layout="vertical"
                                            enableGridY={false}
                                            defs={[
                                                {
                                                    id: 'dots',
                                                    type: 'patternDots',
                                                    background: 'inherit',
                                                    color: '#38bcb2',
                                                    size: 4,
                                                    padding: 1,
                                                    stagger: true
                                                },
                                                {
                                                    id: 'lines',
                                                    type: 'patternLines',
                                                    background: 'inherit',
                                                    color: '#eed312',
                                                    rotation: -45,
                                                    lineWidth: 6,
                                                    spacing: 10
                                                }
                                            ]}
                                            fill={[
                                                {
                                                    match: {
                                                        id: 'fries'
                                                    },
                                                    id: 'dots'
                                                },
                                                {
                                                    match: {
                                                        id: 'sandwich'
                                                    },
                                                    id: 'lines'
                                                }
                                            ]}
                                            borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                                            axisTop={null}
                                            axisRight={null}
                                            axisBottom={{
                                                tickSize: 5,
                                                tickPadding: 5,
                                                tickRotation: 0,
                                                legend: '',
                                                legendPosition: 'middle',
                                                legendOffset: 50
                                            }}
                                            axisLeft={{
                                                tickSize: 5,
                                                tickPadding: 5,
                                                tickRotation: 0,
                                                legend: '',
                                                legendPosition: 'middle',
                                                legendOffset: -60
                                            }}
                                            labelSkipWidth={12}
                                            labelSkipHeight={12}
                                            labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                                            legends={[
                                                {
                                                    dataFrom: 'keys',
                                                    anchor: 'top-right',
                                                    direction: 'column',
                                                    justify: false,
                                                    translateX: -100,
                                                    translateY: -100,
                                                    itemsSpacing: 2,
                                                    itemWidth: 150,
                                                    itemHeight: 25,
                                                    itemDirection: 'left-to-right',
                                                    itemOpacity: 0.85,
                                                    symbolSize: 20,
                                                    effects: [
                                                        {
                                                            on: 'hover',
                                                            style: {
                                                                itemOpacity: 1
                                                            }
                                                        }
                                                    ]
                                                }
                                            ]}
                                            animate={true}
                                            motionStiffness={90}
                                            motionDamping={15}
                                        />
                                }
                            </div>
                        </div>
                    </Col>

                    <Col md={{ size: 3 }}>
                        <div className="schoolBox topSchoolBox bottomBox">
                            <Row>
                                <Col>

                                    <div className="oneInfo">
                                        <div className="iconBox">

                                            <Row>
                                                <Col>
                                                    <h3>{this.state.allDivisions.length}</h3>
                                                    <p style={{ color: '#68366f' }}>Divisions in<br />{this.state.branchName}</p>
                                                </Col>
                                                <Col>
                                                    <img src={devisionicon} />
                                                </Col>
                                            </Row>

                                        </div>
                                    </div>

                                    <div className="oneInfo">
                                        <div className="iconBox">

                                            <Row>
                                                <Col>
                                                    <h3>{this.state.allSections.length}</h3>
                                                    <p style={{ color: '#68366f' }}>Sections in<br />{this.state.divisionName}</p>
                                                </Col>
                                                <Col>
                                                    <img src={sectionicon} />
                                                </Col>
                                            </Row>

                                        </div>
                                    </div>

                                </Col>
                            </Row>
                        </div>
                    </Col> 
                    </React.Fragment>
    }


                    {this.state.sectionHideGraph ? null :
                        <React.Fragment>
                            <div className="seperatorHeading">
                                Average score of {this.state.sectionName} with in {this.state.divisionName}

                                <select className="customDropDown" onChange={e => {
                                    this.setState({
                                        sectionIndex: 0,
                                        sectionName: e.target.value,
                                        peopleInSection: 0,
                                        compComparisonDataSectionWiseDMEApi: {
                                            keys: [],
                                            data: []
                                        },
                                    }, () => {
                                        this.props.sectionDMERecord(this.state.selectedBranchIdValue, this.state.selectedYear, this.state.sectionName)
                                    })
                                }}>
                                    {
                                        this.state.allSections.map((section, key) =>
                                            <option key={key} value={section.SECTION}>{section.SECTION}</option>
                                        )
                                    }
                                </select>

                            </div>

                            {/* <Col md={{ size: 8, offset: 1 }}>
                        
                        <p style={{color: '#7ba2dc', textAlign: 'left', marginTop: '30px'}}>
                        This bar chart is a further breakdown of each Division and highlights the performance for the Sections in each Division in {this.state.branchName} for each of the four main competencies and has four points of relevance and comparison:
                        <ol style={{marginTop: '20px'}}>
                            <li>Average percentage score of each of the Section in the selected Division for {this.state.branchName}</li>
                            <li>Average percentage score of each Section as compared to the overall scores for the selected Division in {this.state.branchName} as compared to the {this.state.branchName}'s overall scores</li>
                            <li>Average percentage score of each Section for the selected Division as compared to the {this.state.branchName}'s overall scores</li>
                            <li>Average percentage score of each Section for the selected Division in {this.state.branchName} as compared to overall result for this assessment </li>
                        </ol>
                        </p>

                    </Col> */}

                            <Col md={{ size: 9 }} style={{ paddingTop: '25px' }}>
                                <div className="chartBox" style={{ background: 'transparent' }}>
                                    <div style={{ height: '500px', position: 'relative' }}>
                                        {
                                            this.state.compComparisonDataSectionWiseDMEApi && this.state.compComparisonDataSectionWiseDMEApi.data.length != 0 ?
                                                <ResponsiveBar
                                                    data={this.state.compComparisonDataSectionWiseDMEApi.data}
                                                    keys={this.state.compComparisonDataSectionWiseDMEApi.keys}
                                                    indexBy="type"
                                                    margin={{ top: 140, right: 0, bottom: 60, left: 100 }}
                                                    padding={0.25}
                                                    innerPadding={10}
                                                    label={d => `${d.value}%`}
                                                    // onClick={e => this.barChartClicked(e)}
                                                    groupMode="grouped"
                                                    colors={["#3d1f42", "#542b5a", "#68366f", "#93519c", "#68356f", "#93519c"]}
                                                    // colors={["#f706d5", "#770367" ]}
                                                    // colors={["#68366f", "#3d1f42" ]}
                                                    layout="vertical"
                                                    enableGridY={false}
                                                    defs={[
                                                        {
                                                            id: 'dots',
                                                            type: 'patternDots',
                                                            background: 'inherit',
                                                            color: '#38bcb2',
                                                            size: 4,
                                                            padding: 1,
                                                            stagger: true
                                                        },
                                                        {
                                                            id: 'lines',
                                                            type: 'patternLines',
                                                            background: 'inherit',
                                                            color: '#eed312',
                                                            rotation: -45,
                                                            lineWidth: 6,
                                                            spacing: 10
                                                        }
                                                    ]}
                                                    fill={[
                                                        {
                                                            match: {
                                                                id: 'fries'
                                                            },
                                                            id: 'dots'
                                                        },
                                                        {
                                                            match: {
                                                                id: 'sandwich'
                                                            },
                                                            id: 'lines'
                                                        }
                                                    ]}
                                                    borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                                                    axisTop={null}
                                                    axisRight={null}
                                                    axisBottom={{
                                                        tickSize: 5,
                                                        tickPadding: 5,
                                                        tickRotation: 0,
                                                        legend: '',
                                                        legendPosition: 'middle',
                                                        legendOffset: 50
                                                    }}
                                                    axisLeft={{
                                                        tickSize: 5,
                                                        tickPadding: 5,
                                                        tickRotation: 0,
                                                        legend: '',
                                                        legendPosition: 'middle',
                                                        legendOffset: -60
                                                    }}
                                                    labelSkipWidth={12}
                                                    labelSkipHeight={12}
                                                    labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                                                    legends={[
                                                        {
                                                            dataFrom: 'keys',
                                                            anchor: 'top-right',
                                                            direction: 'column',
                                                            justify: false,
                                                            translateX: -100,
                                                            translateY: -135,
                                                            itemsSpacing: 2,
                                                            itemWidth: 150,
                                                            itemHeight: 25,
                                                            itemDirection: 'left-to-right',
                                                            itemOpacity: 0.85,
                                                            symbolSize: 20,
                                                            effects: [
                                                                {
                                                                    on: 'hover',
                                                                    style: {
                                                                        itemOpacity: 1
                                                                    }
                                                                }
                                                            ]
                                                        }
                                                    ]}
                                                    animate={true}
                                                    motionStiffness={90}
                                                    motionDamping={15}
                                                />
                                                : <div className="customSpinnerChart">
                                                    <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                                                </div>
                                        }
                                    </div>
                                </div>
                            </Col>

                            <Col md={{ size: 3 }}>
                                <div className="schoolBox topSchoolBox bottomBox">
                                    <Row>
                                        <Col>
                                            <div className="oneInfo">
                                                <div className="iconBox">

                                                    <Row>
                                                        <Col>
                                                            <h3>{this.state.peopleInSection}</h3>
                                                            <p style={{ color: '#68366f' }}>People<br />in {this.state.sectionName}</p>
                                                        </Col>
                                                        <Col>
                                                            <div style={{ margin: '30px 0px 10px 0' }}>
                                                                <FontAwesomeIcon className="iconHere" icon={faMale} size="2x" />
                                                                <FontAwesomeIcon className="iconHere" icon={faFemale} size="2x" />
                                                            </div>
                                                        </Col>
                                                    </Row>

                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </React.Fragment>}


                    {this.state.dmeCategorisedHideGraph ? null :
                        <React.Fragment>
                            <Col md={{ size: 12 }}>

                                <hr />

                            </Col>

                            <Col md={{ size: 8, offset: 1 }}>
                                <p style={{ color: '#fff', textAlign: 'left', marginBottom: '20px', borderTop: '2px #316ea1 solid', paddingTop: '15px' }}>
                                    The graph below shows the frequency of Candidates in {this.state.branchName} whose scores for {this.state.competencyName} competencies, in comparison to the required level, are either deficient, matching or exceeding the benchmark.
                    </p>
                            </Col>

                            <Col md={{ size: 2 }}>
                                <select className="customDropDown" onChange={e => {
                                    this.setState({
                                        competencyIndexs: e.target.value
                                    }, () => {
                                        // this.DMEDataMapping(this.state.branchIndex, this.state.competencyIndex);
                                        this.categorizedDMEChange(this.state.branchIndex, this.state.competencyIndexs);
                                    })
                                }}>
                                    {
                                        this.state.mainCompetency.map((comp, key) =>
                                            <option key={key} value={key} selected={key == this.state.competencyIndexs}>{comp.competency_name}</option>
                                        )
                                    }
                                </select>
                            </Col>


                            <Col md={{ size: 7, offset: 3 }}>
                                <div style={{ height: this.state.respondentsCatogorizeDMEDataApi.data.length > 10 ? '1000px' : '400px' }}>
                                    {
                                        this.state.respondentsCatogorizeDMEDataApi && this.state.respondentsCatogorizeDMEDataApi.data.length != 0 ?
                                            <ResponsiveBar
                                                data={this.state.respondentsCatogorizeDMEDataApi.data}
                                                keys={this.state.respondentsCatogorizeDMEDataApi.keys}
                                                indexBy={this.state.respondentsCatogorizeDMEData.indexBy}
                                                margin={{ top: 60, right: 0, bottom: 50, left: 100 }}
                                                padding={0.3}
                                                layout="horizontal"
                                                colors={{ scheme: 'set1' }}
                                                defs={[
                                                    {
                                                        id: 'dots',
                                                        type: 'patternDots',
                                                        background: 'inherit',
                                                        color: '#38bcb2',
                                                        size: 4,
                                                        padding: 1,
                                                        stagger: true
                                                    },
                                                    {
                                                        id: 'lines',
                                                        type: 'patternLines',
                                                        background: 'inherit',
                                                        color: '#eed312',
                                                        rotation: -45,
                                                        lineWidth: 6,
                                                        spacing: 10
                                                    }
                                                ]}
                                                fill={[
                                                    {
                                                        match: {
                                                            id: 'fries'
                                                        },
                                                        id: 'dots'
                                                    },
                                                    {
                                                        match: {
                                                            id: 'sandwich'
                                                        },
                                                        id: 'lines'
                                                    }
                                                ]}
                                                borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                                                axisTop={null}
                                                axisRight={null}
                                                // axisBottom={{
                                                //     tickSize: 5,
                                                //     tickPadding: 5,
                                                //     tickRotation: 0,
                                                //     legend: 'country',
                                                //     legendPosition: 'middle',
                                                //     legendOffset: 32
                                                // }}
                                                // axisLeft={{
                                                //     tickSize: 5,
                                                //     tickPadding: 5,
                                                //     tickRotation: 0,
                                                //     legend: 'food',
                                                //     legendPosition: 'middle',
                                                //     legendOffset: -40
                                                // }}
                                                labelSkipWidth={12}
                                                labelSkipHeight={12}
                                                labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                                                legends={[
                                                    {
                                                        dataFrom: 'keys',
                                                        anchor: 'top-left',
                                                        direction: 'row',
                                                        justify: false,
                                                        translateX: -250,
                                                        translateY: -50,
                                                        itemsSpacing: 2,
                                                        itemWidth: 110,
                                                        itemHeight: 20,
                                                        itemDirection: 'left-to-right',
                                                        itemOpacity: 0.85,
                                                        symbolSize: 20,
                                                        effects: [
                                                            {
                                                                on: 'hover',
                                                                style: {
                                                                    itemOpacity: 1
                                                                }
                                                            }
                                                        ]
                                                    }
                                                ]}
                                                animate={true}
                                                motionStiffness={90}
                                                motionDamping={15}
                                            />
                                            : <div className="customSpinnerChart">
                                                <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                                            </div>
                                    }
                                </div>
                            </Col>
                        </React.Fragment>}





                    {/* <Col>
                    <h3><span>M</span>atching</h3>
                    <p>Participants whose average score is matching the required competencies score</p>
                </Col>
                <Col>
                    <h3><span>E</span>xceeding</h3>
                    <p>Participants whose average score is exceeding the required competencies score</p>
                </Col> */}
                </Row>

            </div>
        );

    }
}

DMEProfileComponent.propTypes = {
    // triggerFormIframe: PropTypes.func.isRequired,
}

const mapStatesToProps = state => ({
    branchAvgDme: state.webRed.branchAvgDme,
    dmeByDivision: state.webRed.dmeByDivision,
    dmeBySection: state.webRed.dmeBySection,
    divisionsAndSections: state.webRed.divisionsAndSections
})

export default connect(mapStatesToProps, { branchAvgDMERecord, divisionDMERecord, sectionDMERecord })(DMEProfileComponent);