import React, { Component, Fragment } from "react";

import {
  getMainGroupsData,
  fetchSubGroupsOrLocationsOrSOG,
  fetchSubGroupsDepartments,
  getAssesstDivHierarchy,
  getAssesstSectionHierarchy,
  getAssesstPCHierarchy,
  getAssesstDesignationHierarchy,
  fetchLocationBranches,
  getAssesstDeptsHierarchy,
  getLocationDivsHierarchy,
  getLocationSectionHierarchy,
  getLocationPCHierarchy,
  getLocationDesigHierarchy,
  getAssessBranchDivsHierarchy,
  getAssessBranchSectionHierarchy,
  getAssessBranchPCHierarchy,
  getAssessBranchDesignationHierarchy,
  editAssignedAssessment,
  updateAssignedAssessment,
} from "../../../../../src/actions/webRedActions";

import PropTypes from "prop-types";

import { connect } from "react-redux";

import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

import {
  Button,
  Container,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  Collapse,
  Card,
  CardBody,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";

import AdminHeader from "./../AdminHeader";

import SideMenu from "./../sidMenu/SideMenu";

import {
  getAdminUserData,
  convertInArray,
} from "../../../../../src/actions/utility";

import "react-pro-sidebar/dist/css/styles.css";

import $ from "jquery";

class AdminEditAssessment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      mainGroupsDataArr: [],
      subGroupDataArr: [],
      deptDataArr: [],
      desigDataArr: [],
      pcDataArr: [],
      divisionsArr: [],
      sectionsArr: [],
      isAllRequiredDataSelected: false,
      loadingSubGroups: false,
      isOpen: [true, true, true, true],
      subGroupType: "none",
      loadingDepartment: false,
      loadingDivision: false,
      loadingSection: false,
      loadingPC: false,
      loadingDesig: false,
      addDesigSubGroupType: "none",
      loadingAddDesigBranchs: false,
      addDesignBranches: [],
      addDesigLocationId: 0,
      addDesigBranchId: 0,
      subGroupId: 0,
      deptId: 0,
      divisionId: 0,
      sectionId: 0,
      pcId: 0,
      desigId: 0,
      assignedAssessArr: [],
      loadingLocationDivs: false,
      loadingLocationBranchDivs: false,
      locationId: 0,
      groupId: 0,
      branchDataArr: [],
      isLocationBranch: false,
      branchId: 0,
      isLocationBranchSection: false,
      isLocationBranchPC: false,
      hierarchyComplete: false,
      searchAssess: false,
      selAssessments: [],
      selRpls: [],
      selRcls: [],
      isDone: false,
      searchData: false,
      updatehData: false,
    };
  }

  componentWillMount() {
    this.props.getMainGroupsData("");
  }
  componentDidMount() {
    var that = this;
    // $(document).off("click",".assessmentListChk").on("click",".assessmentListChk",function() {
    //     const {selAssessments} =that.state;
    //     console.log("selAssessments:",selAssessments)
    //     let isChecked  = $(this).is(":checked");
    //     if (isChecked) {
    //         selAssessments.push($(this).val());
    //         //that.setState({selAssessments:selAssessments})
    //         that.state.selAssessments = selAssessments;
    //     }
    //     else {
    //         const index = selAssessments.indexOf($(this).val());
    //         if (index > -1) {
    //             selAssessments.splice(index, 1);
    //             that.state.selAssessments = selAssessments;
    //             //that.setState({selAssessments:selAssessments})
    //         }
    //     }
    //     console.log( that.state.selAssessments )
    // })
    $(document)
      .off("change", ".assessmentListSel")
      .on("change", ".assessmentListSel", function () {
        const { selRpls, assignedAssessArr } = that.state;

        let rpl = $(this).val();
        console.log('rpllllllllllllllllllllllllll;',rpl)
        let rplKeyWord = rpl.split("_")[0];
        let val = rpl.split("_")[1];
        // Check if assignedAssessArr.rpls exists and initialize it if it's null or undefined
        if (!assignedAssessArr.rpls) {
          assignedAssessArr.rpls = {};
        }
        assignedAssessArr.rpls[rplKeyWord + "_rpl"] = parseInt(val);
        that.setState({ assignedAssessArr: assignedAssessArr });
        console.log(parseInt(val), "assignedAssessArr:", assignedAssessArr);
        if (rpl != 0) {
          if (that.state.selRpls.length) {
            let keyExist = false;
            let flag = 1;
            that.state.selRpls.map((rpl, index) => {
              console.log("My given rpl:", rpl);
              if (typeof rpl[rplKeyWord] !== "undefined") {
                that.state.selRpls[index][rplKeyWord] = val;
                return false;
              } else {
                if (that.state.selRpls.length == flag) {
                  let obj = {};
                  obj[rplKeyWord] = val;
                  console.log("obj:", obj);
                  that.state.selRpls.push(obj);
                }
              }
              flag++;
            });
          } else {
            console.log("in else");
            let obj = {};
            obj[rplKeyWord] = val;
            console.log("obj:", obj);
            that.state.selRpls.push(obj);
          }
        }
        console.log("selRpls:", that.state.selRpls);
      });
    $(document)
      .off("change", ".assessmentListSelRcl")
      .on("change", ".assessmentListSelRcl", function () {
        const { selRcls, assignedAssessArr } = that.state;

        let rcl = $(this).val();
        let rclKeyWord = rcl.split("_")[0];
        let val = rcl.split("_")[1];

        // Check if assignedAssessArr.rcls exists and initialize it if it's null or undefined
        if (!assignedAssessArr.rcls) {
          assignedAssessArr.rcls = {};
        }
        assignedAssessArr.rcls[rclKeyWord + "_rcl"] = parseInt(val);
        that.setState({ assignedAssessArr: assignedAssessArr });
        console.log(parseInt(val), "assignedAssessArr:", assignedAssessArr);
        if (rcl != 0) {
          if (that.state.selRcls.length) {
            let keyExist = false;
            let flag = 1;
            that.state.selRcls.map((rpl, index) => {
              console.log("My given rpl:", rpl);
              if (typeof rpl[rclKeyWord] !== "undefined") {
                that.state.selRcls[index][rclKeyWord] = val;
                return false;
              } else {
                if (that.state.selRcls.length == flag) {
                  let obj = {};
                  obj[rclKeyWord] = val;
                  console.log("obj:", obj);
                  that.state.selRcls.push(obj);
                }
              }
              flag++;
            });
          } else {
            console.log("in else");
            let obj = {};
            obj[rclKeyWord] = val;
            console.log("obj:", obj);
            that.state.selRcls.push(obj);
          }
        }
        console.log("selRcls:", that.state.selRcls);
      });
  }

  componentWillReceiveProps(nextProps, thisProps) {
    if (this.props.mainGroupsData != nextProps.mainGroupsData) {
      this.setState(
        { mainGroupsDataArr: nextProps.mainGroupsData.data, isLoading: false },
        () => {
          console.log("set state works here", this.state.mainGroupsDataArr);
        }
      );
    }
  }

  fetchGroupRelatedData = (event) => {
    console.log(event.target.value);

    var groupId = event.target.value;
    if (groupId != 0) {
      this.setState(
        {
          loadingSubGroups: true,
          subGroupDataArr: [],
          deptDataArr: [],
          divisionsArr: [],
          sectionsArr: [],
          pcDataArr: [],
          desigDataArr: [],
          assignedAssessArr: [],
          groupId: groupId,
          branchDataArr: [],
        },
        () => {
          var that = this;
          this.props.fetchSubGroupsOrLocationsOrSOG(groupId, (data) => {
            if (data != 0) {
              if (data.status == "success") {
                that.setState({
                  loadingSubGroups: false,
                  subGroupDataArr: data.result,
                  subGroupType: data.type,
                });
              } else {
                that.setState({ loadingSubGroups: false }, () => {
                  alert(data.message);
                });
              }
            } else {
              that.setState({ loadingSubGroups: false }, () => {
                alert("data not found");
              });
            }
          });
        }
      );
    }
  };
  fetchDepartments = (event) => {
    var subGroupId = event.target.value;

    if (subGroupId != 0) {
      this.setState({ loadingDepartment: true }, () => {
        console.log("Loading data here");
        var that = this;
        this.props.fetchSubGroupsDepartments(subGroupId, (departments) => {
          console.log("departments:", departments);
          if (departments != 0) {
            if (departments.status == "success") {
              that.setState({
                loadingDepartment: false,
                deptDataArr: departments.result,
              });
            } else {
              that.setState({ loadingDepartment: false }, () => {
                alert(departments.message);
              });
            }
          } else {
            that.setState({ loadingDepartment: false }, () => {
              alert("data not found");
            });
          }
        });
      });
    }
  };
  fetchDepartmentsHierarchy = (event) => {
    var subGroupId = event.target.value;
    if (subGroupId != 0) {
      this.setState({
        loadingDepartment: true,
        subGroupId: subGroupId,
        deptDataArr: [],
        divisionsArr: [],
        sectionsArr: [],
        pcDataArr: [],
        desigDataArr: [],
        assignedAssessArr: [],
      });
      var that = this;
      this.props.getAssesstDeptsHierarchy(subGroupId, (depts) => {
        console.log("depts:", depts);
        if (depts.status == "success") {
          that.setState({
            loadingDepartment: false,
            deptDataArr: depts.result,
          });
        } else {
          that.setState({ loadingDepartment: false }, () => {
            alert(depts.message);
          });
        }
      });
    }
  };
  fetchDivisions = (event) => {
    var deptId = event.target.value;
    if (deptId != "-0") {
      this.setState({
        loadingDivision: true,
        deptId: deptId,
        divisionsArr: [],
        sectionsArr: [],
        pcDataArr: [],
        desigDataArr: [],
        assignedAssessArr: [],
      });
      console.log("get hierarchy");
      var that = this;
      this.props.getAssesstDivHierarchy(
        deptId,
        this.state.subGroupId,
        (divisions) => {
          console.log("in divisions:", divisions);
          if (divisions.status == "success") {
            that.setState({
              loadingDivision: false,
              divisionsArr: divisions.result,
            });
          } else {
            that.setState({ loadingDivision: false }, () => {
              alert("data not found");
            });
          }
        }
      );
    }
  };
  fetchSections = (event) => {
    var divId = event.target.value;
    const { subGroupType, isLocationBranch } = this.state;
    if (divId != "-0" && subGroupType == "sub_groups") {
      const { subGroupId, deptId } = this.state;
      this.setState({
        loadingSection: true,
        divisionId: divId,
        sectionsArr: [],
        pcDataArr: [],
        desigDataArr: [],
        assignedAssessArr: [],
      });
      var that = this;
      this.props.getAssesstSectionHierarchy(
        subGroupId,
        deptId,
        divId,
        (sections) => {
          console.log("sections data:", sections);
          if (sections.status == "success") {
            that.setState({
              loadingSection: false,
              sectionsArr: sections.result,
            });
          } else {
            that.setState({ loadingSection: false }, () => {
              alert("data not found");
            });
          }
        }
      );
    } else if (divId != "-0" && subGroupType == "locations") {
      console.log("load the section of location");

      const { locationId, isLocationBranch, branchId } = this.state;
      if (isLocationBranch == false) {
        this.setState({
          isLocationBranchSection: false,
          loadingSection: true,
          divisionId: divId,
          sectionsArr: [],
          pcDataArr: [],
          desigDataArr: [],
          assignedAssessArr: [],
        });

        var that = this;

        this.props.getLocationSectionHierarchy(
          locationId,
          divId,
          (sectionData) => {
            console.log("sectionData:", sectionData);
            if (sectionData.status == "success") {
              that.setState({
                loadingSection: false,
                sectionsArr: sectionData.result,
              });
            } else {
              that.setState({ loadingSection: false }, () => {
                alert("data not found");
              });
            }
          }
        );
      } else {
        this.setState({
          isLocationBranchSection: true,
          loadingSection: true,
          divisionId: divId,
          sectionsArr: [],
          pcDataArr: [],
          desigDataArr: [],
          assignedAssessArr: [],
        });

        var that = this;

        this.props.getAssessBranchSectionHierarchy(
          locationId,
          branchId,
          divId,
          (sectionData) => {
            console.log("sectionData:", sectionData);
            if (sectionData.status == "success") {
              that.setState({
                loadingSection: false,
                sectionsArr: sectionData.result,
              });
            } else {
              that.setState({ loadingSection: false }, () => {
                alert("data not found");
              });
            }
          }
        );
      }
    }
  };
  fetchPayClass = (event) => {
    var sectionId = event.target.value;
    const { subGroupType, branchId } = this.state;
    if (sectionId != "-0" && subGroupType == "sub_groups") {
      const { subGroupId, deptId, divisionId } = this.state;
      this.setState({
        loadingPC: true,
        sectionId: sectionId,
        pcDataArr: [],
        desigDataArr: [],
        assignedAssessArr: [],
      });
      var that = this;
      this.props.getAssesstPCHierarchy(
        subGroupId,
        deptId,
        divisionId,
        sectionId,
        (pcData) => {
          console.log("jgData:", pcData);
          if (pcData.status == "success") {
            that.setState({ loadingPC: false, pcDataArr: pcData.result });
          } else {
            that.setState({ loadingPC: false }, () => {
              alert("data not found");
            });
          }
        }
      );
    } else if (sectionId != "-0" && subGroupType == "locations") {
      const { locationId, divisionId, isLocationBranchSection } = this.state;
      if (isLocationBranchSection == false) {
        this.setState({
          isLocationBranchPC: false,
          loadingPC: true,
          sectionId: sectionId,
          pcDataArr: [],
          desigDataArr: [],
          assignedAssessArr: [],
        });
        var that = this;
        this.props.getLocationPCHierarchy(
          locationId,
          divisionId,
          sectionId,
          (pcData) => {
            console.log("jgData:", pcData);
            if (pcData.status == "success") {
              that.setState({ loadingPC: false, pcDataArr: pcData.result });
            } else {
              that.setState({ loadingPC: false }, () => {
                alert("data not found");
              });
            }
          }
        );
      } else {
        console.log("load branch payclass");
        this.setState({
          isLocationBranchPC: true,
          loadingPC: true,
          sectionId: sectionId,
          pcDataArr: [],
          desigDataArr: [],
          assignedAssessArr: [],
        });
        var that = this;
        this.props.getAssessBranchPCHierarchy(
          locationId,
          branchId,
          divisionId,
          sectionId,
          (pcData) => {
            console.log("jgData:", pcData);
            if (pcData.status == "success") {
              that.setState({ loadingPC: false, pcDataArr: pcData.result });
            } else {
              that.setState({ loadingPC: false }, () => {
                alert("data not found");
              });
            }
          }
        );
      }
    }
  };
  fetchDesignation = (event) => {
    const { subGroupType } = this.state;
    var pcId = event.target.value;
    if (pcId != "-0" && subGroupType == "sub_groups") {
      const { subGroupId, deptId, divisionId, sectionId } = this.state;
      this.setState({
        loadingDesig: true,
        pcId: pcId,
        desigDataArr: [],
        assignedAssessArr: [],
      });
      var that = this;
      this.props.getAssesstDesignationHierarchy(
        subGroupId,
        deptId,
        divisionId,
        sectionId,
        pcId,
        (desigData) => {
          console.log("desigData:", desigData);
          if (desigData.status == "success") {
            that.setState({
              loadingDesig: false,
              desigDataArr: desigData.result,
            });
          } else {
            that.setState({ loadingDesig: false }, () => {
              alert("data not found");
            });
          }
        }
      );
    } else if (pcId != "-0" && subGroupType == "locations") {
      const {
        locationId,
        divisionId,
        sectionId,
        isLocationBranchPC,
        branchId,
      } = this.state;
      if (isLocationBranchPC == false) {
        this.setState({
          loadingDesig: true,
          pcId: pcId,
          desigDataArr: [],
          assignedAssessArr: [],
        });
        var that = this;
        this.props.getLocationDesigHierarchy(
          locationId,
          divisionId,
          sectionId,
          pcId,
          (desigData) => {
            console.log("desigData:", desigData);
            if (desigData.status == "success") {
              that.setState({
                loadingDesig: false,
                desigDataArr: desigData.result,
              });
            } else {
              that.setState({ loadingDesig: false }, () => {
                alert("data not found");
              });
            }
          }
        );
      } else {
        console.log("load the braches designation");
        this.setState({
          loadingDesig: true,
          pcId: pcId,
          desigDataArr: [],
          assignedAssessArr: [],
        });
        var that = this;
        this.props.getAssessBranchDesignationHierarchy(
          locationId,
          branchId,
          divisionId,
          sectionId,
          pcId,
          (desigData) => {
            console.log("desigData:", desigData);
            if (desigData.status == "success") {
              that.setState({
                loadingDesig: false,
                desigDataArr: desigData.result,
              });
            } else {
              that.setState({ loadingDesig: false }, () => {
                alert("data not found");
              });
            }
          }
        );
      }
    }
  };
  fetchLocationDivsHierarchy = (event) => {
    let locationId = event.target.value;

    const { groupId } = this.state;

    if (locationId != "0" && (groupId == 1 || groupId == 2)) {
      this.setState({
        branchId: 0,
        isLocationBranch: false,
        locationId: locationId,
        loadingLocationDivs: true,
        divisionsArr: [],
        sectionsArr: [],
        pcDataArr: [],
        desigDataArr: [],
        assignedAssessArr: [],
      });
      console.log("go for bracnhes:", locationId);
      var that = this;
      this.props.getLocationDivsHierarchy(locationId, (divsData) => {
        console.log("divsData:", divsData);
        if (
          typeof divsData.status !== "undefined" &&
          divsData.status == "success"
        ) {
          that.setState({
            divisionsArr: divsData.result,
            loadingLocationDivs: false,
          });
        } else if (
          typeof divsData.status !== "undefined" &&
          divsData.status == "error"
        ) {
          that.setState(
            { divisionsArr: [], loadingLocationDivs: false },
            () => {
              alert(divsData.message);
            }
          );
        } else {
          that.setState(
            { divisionsArr: [], loadingLocationDivs: false },
            () => {
              alert("Unknown error.");
            }
          );
        }
      });
    } else if (locationId != "0" && groupId == 3) {
      this.setState({
        branchId: 0,
        locationId: locationId,
        loadingLocationDivs: true,
        branchDataArr: [],
        divisionsArr: [],
        sectionsArr: [],
        pcDataArr: [],
        desigDataArr: [],
        assignedAssessArr: [],
      });
      var that = this;
      this.props.fetchLocationBranches(locationId, (locationBranch) => {
        console.log("locationBranch:", locationBranch);
        if (
          typeof locationBranch.status !== "undefined" &&
          locationBranch.status == "success"
        ) {
          that.setState({
            branchDataArr: locationBranch.result,
            loadingLocationDivs: false,
          });
        } else if (
          typeof locationBranch.status !== "undefined" &&
          locationBranch.status == "error"
        ) {
          that.setState(
            { branchDataArr: [], loadingLocationDivs: false },
            () => {
              alert(locationBranch.message);
            }
          );
        } else {
          that.setState(
            { branchDataArr: [], loadingLocationDivs: false },
            () => {
              alert("Unknown error.");
            }
          );
        }
      });
    }
  };
  fetchLocationBranchDivs = (event) => {
    let branchId = event.target.value;
    if (branchId != "0") {
      const { locationId } = this.state;
      var that = this;
      this.setState({
        branchId: branchId,
        isLocationBranch: true,
        loadingLocationBranchDivs: true,
        divisionsArr: [],
        sectionsArr: [],
        pcDataArr: [],
        desigDataArr: [],
        assignedAssessArr: [],
      });
      this.props.getAssessBranchDivsHierarchy(
        locationId,
        branchId,
        (divsData) => {
          console.log("branchDivisions:", divsData);
          if (
            typeof divsData.status !== "undefined" &&
            divsData.status == "success"
          ) {
            that.setState({
              divisionsArr: divsData.result,
              loadingLocationBranchDivs: false,
            });
          } else if (
            typeof divsData.status !== "undefined" &&
            divsData.status == "error"
          ) {
            that.setState(
              { divisionsArr: [], loadingLocationBranchDivs: false },
              () => {
                alert(divsData.message);
              }
            );
          } else {
            that.setState(
              { divisionsArr: [], loadingLocationBranchDivs: false },
              () => {
                alert("Unknown error.");
              }
            );
          }
        }
      );
    }
  };
  render() {
    const {
      isLoading,
      mainGroupsDataArr,
      subGroupDataArr,
      deptDataArr,
      desigDataArr,
      pcDataArr,
      divisionsArr,
      sectionsArr,
      loadingSubGroups,
      subGroupType,
      loadingDepartment,
      loadingDivision,
      loadingSection,
      loadingPC,
      loadingDesig,
      assignedAssessArr,
      loadingLocationDivs,
      branchDataArr,
      loadingLocationBranchDivs,
      selAssessments,
      searchAssess,
      isOpen,
      searchData,
      updatehData,
    } = this.state;
    //console.log("Faheem in rander addDesigDepartments:",desigAllData);
    // if(typeof assignedAssessArr.assess_data !=="undefined" && selAssessments.length == 0) {
    //     let that = this;
    //     try {
    //         convertInArray(JSON.parse(assignedAssessArr.assess_data),(convertedData)=>{
    //             console.log("convertedData:",convertedData)
    //             that.state.selAssessments = convertedData;
    //         })
    //     }
    //     catch(err) {
    //         //console.log("this is the error:",err)
    //     }
    // }

    return (
      <div>
        {/*Side menu*/}
        <SideMenu />

        <AdminHeader
          title={" Assessment Management"}
          style={{ marginLeft: 80 }}
        />
        {isLoading == false ? (
          <Container className="AssessmentStatistics ManageAssessment">
            <Row>
              <Col md={{ size: 12 }}>
                <Row style={{ background: "#d5eafb", padding: "15px" }}>
                  <Col md={{ size: 6 }}>
                    <h5 style={{ fontSize: "18px", fontWeight: "normal" }}>
                      EDIT ASSESSMENT
                    </h5>
                  </Col>
                </Row>

                <fieldset>
                  <Row>
                    <Col md={{ size: 6 }}>
                      <FormGroup>
                        <Label for="BRANCH">Main Group</Label>:
                        <Input
                          type="select"
                          name="main_group"
                          id="main_group"
                          onChange={this.fetchGroupRelatedData}
                        >
                          <option value="0">Select Main Group</option>
                          {mainGroupsDataArr.length > 0
                            ? mainGroupsDataArr.map((group, index) => (
                                <option value={group.group_id}>
                                  {group.group_name}
                                </option>
                              ))
                            : null}
                        </Input>
                      </FormGroup>
                      {loadingSubGroups == true ? (
                        <Spinner
                          color="primary"
                          style={{
                            width: "1.10rem",
                            height: "1.10rem",
                            marginTop: "-40px",
                            float: "right",
                          }}
                        />
                      ) : null}
                    </Col>
                    {subGroupDataArr.length > 0 &&
                    subGroupType == "sub_groups" ? (
                      <Fragment>
                        <Col md={{ size: 6 }} />
                        <Col md={{ size: 6 }}>
                          <FormGroup>
                            <Label for="BRANCH">Sub Group</Label>:
                            <Input
                              type="select"
                              name="report_year"
                              id="report_year"
                              onChange={this.fetchDepartmentsHierarchy}
                            >
                              <option value="0">Select Sub Group</option>
                              {subGroupDataArr.map((subGroup, index) => (
                                <option value={subGroup.sub_groupid}>
                                  {subGroup.sub_groupname}
                                </option>
                              ))}
                            </Input>
                          </FormGroup>
                          {loadingDepartment == true ? (
                            <Spinner
                              color="primary"
                              style={{
                                width: "1.10rem",
                                height: "1.10rem",
                                marginTop: "-40px",
                                float: "right",
                              }}
                            />
                          ) : null}
                        </Col>
                      </Fragment>
                    ) : subGroupDataArr.length > 0 &&
                      subGroupType == "locations" ? (
                      <Fragment>
                        <Col md={{ size: 6 }} />
                        <Col md={{ size: 6 }}>
                          <FormGroup>
                            <Label for="BRANCH">Locations</Label>:
                            <Input
                              type="select"
                              name="report_year"
                              id="report_year"
                              onChange={this.fetchLocationDivsHierarchy}
                            >
                              <option value="0">Select Location</option>
                              {subGroupDataArr.map((location, index) => (
                                <option value={location.location_id}>
                                  {location.location_name}
                                </option>
                              ))}
                            </Input>
                          </FormGroup>
                          {loadingLocationDivs == true ? (
                            <Spinner
                              color="primary"
                              style={{
                                width: "1.10rem",
                                height: "1.10rem",
                                marginTop: "-40px",
                                float: "right",
                              }}
                            />
                          ) : null}
                        </Col>
                      </Fragment>
                    ) : null}
                    {branchDataArr.length > 0 ? (
                      <Fragment>
                        <Col md={{ size: 6 }} />
                        <Col md={{ size: 6 }}>
                          <FormGroup>
                            <Label for="BRANCH">Branches</Label>:
                            <Input
                              type="select"
                              name="report_year"
                              id="report_year"
                              onChange={this.fetchLocationBranchDivs}
                            >
                              <option value="0">Select Branch</option>
                              {branchDataArr.map((branch, index) => (
                                <option value={branch.branch_id}>
                                  {branch.branch_name}
                                </option>
                              ))}
                            </Input>
                          </FormGroup>
                          {loadingLocationBranchDivs == true ? (
                            <Spinner
                              color="primary"
                              style={{
                                width: "1.10rem",
                                height: "1.10rem",
                                marginTop: "-40px",
                                float: "right",
                              }}
                            />
                          ) : null}
                        </Col>
                      </Fragment>
                    ) : null}
                    {deptDataArr.length > 0 ? (
                      <Fragment>
                        <Col md={{ size: 6 }} />
                        <Col md={{ size: 6 }}>
                          <FormGroup>
                            <Label for="BRANCH">Departments</Label>:
                            <Input
                              type="select"
                              name="report_year"
                              id="report_year"
                              onChange={this.fetchDivisions}
                            >
                              <option value="-0">Select Department</option>
                              {deptDataArr.map((dept, index) => (
                                <option value={dept.dept_id}>
                                  {dept.dept_suffix}
                                </option>
                              ))}
                            </Input>
                          </FormGroup>
                          {loadingDivision == true ? (
                            <Spinner
                              color="primary"
                              style={{
                                width: "1.10rem",
                                height: "1.10rem",
                                marginTop: "-40px",
                                float: "right",
                              }}
                            />
                          ) : null}
                        </Col>
                      </Fragment>
                    ) : null}

                    {divisionsArr.length > 0 ? (
                      <Fragment>
                        <Col md={{ size: 6 }} />
                        <Col md={{ size: 6 }}>
                          <FormGroup>
                            <Label for="BRANCH">Divisions</Label>:
                            <Input
                              type="select"
                              name="report_year"
                              id="report_year"
                              onChange={this.fetchSections}
                            >
                              <option value="-0">Select Division</option>
                              {divisionsArr.map((division, index) => (
                                <option value={division.division_id}>
                                  {division.division_name}
                                </option>
                              ))}
                            </Input>
                          </FormGroup>
                          {loadingSection == true ? (
                            <Spinner
                              color="primary"
                              style={{
                                width: "1.10rem",
                                height: "1.10rem",
                                marginTop: "-40px",
                                float: "right",
                              }}
                            />
                          ) : null}
                        </Col>
                      </Fragment>
                    ) : null}

                    {sectionsArr.length > 0 ? (
                      <Fragment>
                        <Col md={{ size: 6 }} />
                        <Col md={{ size: 6 }}>
                          <FormGroup>
                            <Label for="BRANCH">Sections</Label>:
                            <Input
                              type="select"
                              name="report_year"
                              id="report_year"
                              onChange={this.fetchPayClass}
                            >
                              <option value="-0">Select Section</option>
                              {sectionsArr.map((section, index) => (
                                <option value={section.section_id}>
                                  {section.section_name}
                                </option>
                              ))}
                            </Input>
                          </FormGroup>
                          {loadingPC == true ? (
                            <Spinner
                              color="primary"
                              style={{
                                width: "1.10rem",
                                height: "1.10rem",
                                marginTop: "-40px",
                                float: "right",
                              }}
                            />
                          ) : null}
                        </Col>
                      </Fragment>
                    ) : null}

                    {pcDataArr.length > 0 ? (
                      <Fragment>
                        <Col md={{ size: 6 }} />

                        <Col md={{ size: 6 }}>
                          <FormGroup>
                            <Label for="BRANCH">Job Grade</Label>:
                            <Input
                              type="select"
                              name="report_year"
                              id="report_year"
                              onChange={this.fetchDesignation}
                            >
                              <option value="-0">Select Job Grade</option>
                              {pcDataArr.map((pc, index) => (
                                <option value={pc.pc_id}>{pc.pc_name}</option>
                              ))}
                            </Input>
                          </FormGroup>
                          {loadingDesig == true ? (
                            <Spinner
                              color="primary"
                              style={{
                                width: "1.10rem",
                                height: "1.10rem",
                                marginTop: "-40px",
                                float: "right",
                              }}
                            />
                          ) : null}
                        </Col>
                      </Fragment>
                    ) : null}
                    {desigDataArr.length > 0 ? (
                      <Fragment>
                        <Col md={{ size: 6 }} />

                        <Col md={{ size: 6 }}>
                          <FormGroup>
                            <Label for="BRANCH">Designation</Label>:
                            <Input
                              type="select"
                              name="report_year"
                              id="report_year"
                              onChange={(event) => {
                                console.log("check value:", event.target.value);
                                if (event.target.value != "-0")
                                  this.setState({
                                    searchAssess: true,
                                    desigId: event.target.value,
                                  });
                                else this.setState({ searchAssess: false });
                              }}
                            >
                              <option value="-0">Select Designation</option>
                              {desigDataArr.map((desig, index) => (
                                <option value={desig.desig_id}>
                                  {desig.desig_name}
                                </option>
                              ))}
                            </Input>
                          </FormGroup>
                        </Col>
                      </Fragment>
                    ) : null}

                    <Col md={{ size: 6 }} />

                    <Col
                      md={{ size: 12 }}
                      className="text-center"
                      style={{
                        display: searchAssess == true ? "block" : "none",
                      }}
                    >
                      <Button
                        size="sm"
                        className="btn btn-dark"
                        onClick={() => {
                          const {
                            subGroupDataArr,
                            deptDataArr,
                            branchDataArr,
                            subGroupType,
                          } = this.state;
                          console.log(
                            subGroupDataArr.length,
                            deptDataArr.length,
                            subGroupType
                          );
                          this.setState({ searchData: true });
                          let that = this;
                          if (
                            subGroupDataArr.length > 0 &&
                            deptDataArr.length > 0 &&
                            subGroupType == "sub_groups"
                          ) {
                            const {
                              groupId,
                              subGroupId,
                              deptId,
                              divisionId,
                              sectionId,
                              pcId,
                              desigId,
                            } = this.state;
                            this.props.editAssignedAssessment(
                              groupId,
                              subGroupId,
                              deptId,
                              0,
                              divisionId,
                              sectionId,
                              pcId,
                              desigId,
                              0,
                              (searchData) => {
                                console.log("searchData:", searchData);
                                if (
                                  typeof searchData.status !== "undefined" &&
                                  searchData.status == "success"
                                ) {
                                  that.setState({
                                    searchData: false,
                                    assignedAssessArr: searchData.result,
                                  });
                                } else if (
                                  typeof searchData.status !== "undefined" &&
                                  searchData.status == "error"
                                ) {
                                  that.setState({
                                    searchData: false,
                                    assignedAssessArr: [],
                                  });
                                  alert(searchData.message);
                                } else {
                                  that.setState({
                                    searchData: false,
                                    assignedAssessArr: [],
                                  });
                                  alert("Unknown error occured.");
                                }
                              }
                            );
                          } else if (
                            subGroupDataArr.length > 0 &&
                            branchDataArr.length > 0 &&
                            subGroupType == "locations"
                          ) {
                            const {
                              groupId,
                              locationId,
                              branchId,
                              divisionId,
                              sectionId,
                              pcId,
                              desigId,
                            } = this.state;
                            this.props.editAssignedAssessment(
                              groupId,
                              0,
                              0,
                              locationId,
                              divisionId,
                              sectionId,
                              pcId,
                              desigId,
                              branchId,
                              (searchData) => {
                                console.log("searchData:", searchData);
                                if (
                                  typeof searchData.status !== "undefined" &&
                                  searchData.status == "success"
                                ) {
                                  that.setState({
                                    searchData: false,
                                    assignedAssessArr: searchData.result,
                                  });
                                } else if (
                                  typeof searchData.status !== "undefined" &&
                                  searchData.status == "error"
                                ) {
                                  that.setState({
                                    searchData: false,
                                    assignedAssessArr: [],
                                  });
                                  alert(searchData.message);
                                } else {
                                  that.setState({
                                    searchData: false,
                                    assignedAssessArr: [],
                                  });
                                  alert("Unknown error occured.");
                                }
                              }
                            );
                          } else if (
                            subGroupDataArr.length > 0 &&
                            branchDataArr.length == 0 &&
                            subGroupType == "locations"
                          ) {
                            const {
                              groupId,
                              locationId,
                              branchId,
                              divisionId,
                              sectionId,
                              pcId,
                              desigId,
                            } = this.state;
                            this.props.editAssignedAssessment(
                              groupId,
                              0,
                              0,
                              locationId,
                              divisionId,
                              sectionId,
                              pcId,
                              desigId,
                              0,
                              (searchData) => {
                                console.log("searchData:", searchData);
                                if (
                                  typeof searchData.status !== "undefined" &&
                                  searchData.status == "success"
                                ) {
                                  that.setState({
                                    searchData: false,
                                    assignedAssessArr: searchData.result,
                                  });
                                } else if (
                                  typeof searchData.status !== "undefined" &&
                                  searchData.status == "error"
                                ) {
                                  that.setState({
                                    searchData: false,
                                    assignedAssessArr: [],
                                  });
                                  alert(searchData.message);
                                } else {
                                  that.setState({
                                    searchData: false,
                                    assignedAssessArr: [],
                                  });
                                  alert("Unknown error occured.");
                                }
                              }
                            );
                          }
                        }}
                      >
                        Search
                        {searchData == true ? (
                          <Spinner
                            color="primary"
                            size="sm"
                            style={{ marginLeft: 5 }}
                          />
                        ) : null}
                      </Button>
                    </Col>
                  </Row>
                </fieldset>
              </Col>
            </Row>

            <br />

            <Row>
              <Col md={{ size: 12 }}>
                {typeof assignedAssessArr.compt_data !== "undefined" &&
                assignedAssessArr.compt_data.length
                  ? assignedAssessArr.compt_data.map((comp, key) => (
                    
                      <div key={key} className="text-left">
                        <Row className="text-left">
                          <Col md={{ size: 4 }}>
                            <FontAwesomeIcon
                              className="toggleIconHere"
                              icon={isOpen[key] ? faMinus : faPlus}
                              size="1x"
                              onClick={(e) => {
                                var is_open = isOpen;
                                is_open[key] = !is_open[key];
                                this.setState({
                                  isOpen: is_open,
                                });
                              }}
                            />
                            <h5>{comp.competency_name}</h5>
                          </Col>
                          <Col md={{ size: 2 }}>Assign</Col>
                          <Col md={{ size: 3 }}>RPL</Col>
                          <Col md={{ size: 3 }}>RCL</Col>
                        </Row>
                        <Collapse isOpen={isOpen[key]} className="text-left">
                          <Card style={{ border: "none" }}>
                            <CardBody style={{ border: 0, padding: 0 }}>
                              {assignedAssessArr?.sub_compts[
                                comp?.competency_id
                              ] &&
                                assignedAssessArr?.sub_compts[
                                  comp?.competency_id
                                ]?.length &&
                                assignedAssessArr?.sub_compts[
                                  comp?.competency_id
                                ]?.map((subComp, key2) => {
                                  let keyWord = subComp.sub_comptkeyword;
                                  keyWord = keyWord.toLowerCase();
                                  let rpl = assignedAssessArr?.rpls
                                    ? assignedAssessArr?.rpls[keyWord + "_rpl"]
                                    : 0;
                                  let rcl = assignedAssessArr?.rcls
                                    ? assignedAssessArr?.rcls[keyWord + "_rcl"]
                                    : 0;
                                    
                                  let subComptName = subComp.sub_comptname;
                                  let subComptId = subComp.sub_comptid;
                                  //let isAssigned = assignedAssessArr.assess_data.includes(subComptId);
                                  console.log(
                                    keyWord,
                                    ":this is the rpl:",
                                    rpl
                                  );
                                //   let isAssigned = rpl != 0  ? true : false;
                                let isAssigned = (typeof rpl !== 'undefined' && rpl !== 0) ? true : false;
                                  let rclIsAssigned = rcl != 0 ? true : false;
                                  return (
                                    <Row key={key2}>
                                      <Col md={{ size: 4 }}>{subComptName}</Col>
                                      <Col md={{ size: 2 }}>
                                        <FormGroup check>
                                          <Input
                                            type="checkbox"
                                            className="assessmentListChk"
                                            value={subComptId}
                                            checked={isAssigned}
                                          />
                                        </FormGroup>
                                      </Col>
                                      <Col md={{ size: 3 }}>
                                        <FormGroup className="mt-0">
                                          <Input
                                            type="select"
                                            name="report_year"
                                            id="report_year"
                                            className="assessmentListSel"
                                            keyWord={keyWord}
                                          >
                                            <option value={keyWord + "_0"}>
                                              Select RPL{" "}
                                            </option>
                                            <option
                                              value={keyWord + "_10"}
                                              selected={
                                                rpl == "10" ? "selected" : null
                                              }
                                            >
                                              10
                                            </option>
                                            <option
                                              value={keyWord + "_20"}
                                              selected={
                                                rpl == "20" ? "selected" : null
                                              }
                                            >
                                              20
                                            </option>
                                            <option
                                              value={keyWord + "_30"}
                                              selected={
                                                rpl == "30" ? "selected" : null
                                              }
                                            >
                                              30
                                            </option>
                                            <option
                                              value={keyWord + "_40"}
                                              selected={
                                                rpl == "40" ? "selected" : null
                                              }
                                            >
                                              40
                                            </option>
                                            <option
                                              value={keyWord + "_50"}
                                              selected={
                                                rpl == "50" ? "selected" : null
                                              }
                                            >
                                              50
                                            </option>
                                            <option
                                              value={keyWord + "_60"}
                                              selected={
                                                rpl == "60" ? "selected" : null
                                              }
                                            >
                                              60
                                            </option>
                                            <option
                                              value={keyWord + "_70"}
                                              selected={
                                                rpl == "70" ? "selected" : null
                                              }
                                            >
                                              70
                                            </option>
                                            <option
                                              value={keyWord + "_80"}
                                              selected={
                                                rpl == "80" ? "selected" : null
                                              }
                                            >
                                              80
                                            </option>
                                            <option
                                              value={keyWord + "_90"}
                                              selected={
                                                rpl == "90" ? "selected" : null
                                              }
                                            >
                                              90
                                            </option>
                                            <option
                                              value={keyWord + "_100"}
                                              selected={
                                                rpl == "100" ? "selected" : null
                                              }
                                            >
                                              100
                                            </option>
                                          </Input>
                                        </FormGroup>
                                      </Col>
                                      <Col md={{ size: 3 }}>
                                        <FormGroup className="mt-0">
                                          <Input
                                            type="select"
                                            name="report_year2"
                                            id="report_year2"
                                            className="assessmentListSelRcl"
                                            keyWord={keyWord}
                                          >
                                            <option value={keyWord + "_0"}>
                                              Select RCL{" "}
                                            </option>
                                            <option
                                              value={keyWord + "_1"}
                                              selected={
                                                rcl == "1" ? "selected" : null
                                              }
                                            >
                                              1
                                            </option>
                                            <option
                                              value={keyWord + "_2"}
                                              selected={
                                                rcl == "2" ? "selected" : null
                                              }
                                            >
                                              2
                                            </option>
                                            <option
                                              value={keyWord + "_3"}
                                              selected={
                                                rcl == "3" ? "selected" : null
                                              }
                                            >
                                              3
                                            </option>
                                            <option
                                              value={keyWord + "_4"}
                                              selected={
                                                rcl == "4" ? "selected" : null
                                              }
                                            >
                                              4
                                            </option>
                                          </Input>
                                        </FormGroup>
                                      </Col>
                                    </Row>
                                  );
                                })}
                            </CardBody>
                          </Card>
                        </Collapse>
                      </div>
                    ))
                  : null}
              </Col>
              <Col
                md={{ size: 12 }}
                className="text-right"
                style={{
                  display:
                    searchAssess == true &&
                    typeof assignedAssessArr.compt_data !== "undefined" &&
                    assignedAssessArr.compt_data.length
                      ? "block"
                      : "none",
                }}
              >
                <Button
                  size="sm"
                  className="btn btn-dark"
                  onClick={() => {
                    console.log(
                      this.state.selAssessments,
                      "****",
                      this.state.selRpls
                    );
                    const {
                      subGroupDataArr,
                      deptDataArr,
                      branchDataArr,
                      subGroupType,
                    } = this.state;
                    // return false;
                    console.log(
                      subGroupDataArr.length,
                      deptDataArr.length,
                      subGroupType
                    );
                    this.setState({ updatehData: true });
                    let that = this;
                    if (
                      subGroupDataArr.length > 0 &&
                      deptDataArr.length > 0 &&
                      subGroupType == "sub_groups"
                    ) {
                      const {
                        groupId,
                        subGroupId,
                        deptId,
                        divisionId,
                        sectionId,
                        pcId,
                        desigId,
                      } = this.state;
                      this.props.updateAssignedAssessment(
                        groupId,
                        subGroupId,
                        deptId,
                        0,
                        divisionId,
                        sectionId,
                        pcId,
                        desigId,
                        0,
                        this.state.selRpls,
                        this.state.selRcls,
                        (searchData) => {
                          console.log("searchData:", searchData);
                          that.setState({ updatehData: false }, () => {
                            // window.location.reload()
                          });
                          // if (typeof searchData.status !=="undefined" && searchData.status == "success") {
                          //     that.setState({searchData:false,assignedAssessArr : searchData.result});
                          // }
                          // else if (typeof searchData.status !=="undefined" && searchData.status == "error") {
                          //     that.setState({searchData:false,assignedAssessArr : []});
                          //     alert(searchData.message)
                          // }
                          // else {
                          //     that.setState({searchData:false,assignedAssessArr : []});
                          //     alert("Unknown error occured.")
                          // }
                        }
                      );
                    } else if (
                      subGroupDataArr.length > 0 &&
                      branchDataArr.length > 0 &&
                      subGroupType == "locations"
                    ) {
                      const {
                        groupId,
                        locationId,
                        branchId,
                        divisionId,
                        sectionId,
                        pcId,
                        desigId,
                      } = this.state;
                      this.props.updateAssignedAssessment(
                        groupId,
                        0,
                        0,
                        locationId,
                        divisionId,
                        sectionId,
                        pcId,
                        desigId,
                        branchId,
                        this.state.selRpls,
                        this.state.selRcls,
                        (searchData) => {
                          console.log("searchData:", searchData);
                          that.setState({ updatehData: false }, () => {
                            window.location.reload();
                          });
                          // if (typeof searchData.status !=="undefined" && searchData.status == "success") {
                          //     that.setState({searchData:false,assignedAssessArr : searchData.result});
                          // }
                          // else if (typeof searchData.status !=="undefined" && searchData.status == "error") {
                          //     that.setState({searchData:false,assignedAssessArr : []});
                          //     alert(searchData.message)
                          // }
                          // else {
                          //     that.setState({searchData:false,assignedAssessArr : []});
                          //     alert("Unknown error occured.")
                          // }
                        }
                      );
                    } else if (
                      subGroupDataArr.length > 0 &&
                      branchDataArr.length == 0 &&
                      subGroupType == "locations"
                    ) {
                      const {
                        groupId,
                        locationId,
                        branchId,
                        divisionId,
                        sectionId,
                        pcId,
                        desigId,
                      } = this.state;
                      this.props.updateAssignedAssessment(
                        groupId,
                        0,
                        0,
                        locationId,
                        divisionId,
                        sectionId,
                        pcId,
                        desigId,
                        0,
                        this.state.selRpls,
                        this.state.selRcls,
                        (searchData) => {
                          console.log("searchData:", searchData);
                          that.setState({ updatehData: false }, () => {
                            // window.location.reload()
                          });
                          // if (typeof searchData.status !=="undefined" && searchData.status == "success") {
                          //     that.setState({searchData:false,assignedAssessArr : searchData.result});
                          // }
                          // else if (typeof searchData.status !=="undefined" && searchData.status == "error") {
                          //     that.setState({searchData:false,assignedAssessArr : []});
                          //     alert(searchData.message)
                          // }
                          // else {
                          //     that.setState({searchData:false,assignedAssessArr : []});
                          //     alert("Unknown error occured.")
                          // }
                        }
                      );
                    }
                  }}
                >
                  Update
                  {updatehData == true ? (
                    <Spinner
                      color="primary"
                      size="sm"
                      style={{ marginLeft: 5 }}
                    />
                  ) : null}
                </Button>
              </Col>
            </Row>
          </Container>
        ) : (
          <Container>
            <Spinner
              color="primary"
              style={{ width: "2rem", height: "2rem", margin: "30vh 0" }}
            />
          </Container>
        )}
      </div>
    );
  }
}

AdminEditAssessment.propTypes = {
  getMainGroupsData: PropTypes.func.isRequired,
  fetchSubGroupsOrLocationsOrSOG: PropTypes.func.isRequired,
  fetchSubGroupsDepartments: PropTypes.func.isRequired,
  getAssesstDivHierarchy: PropTypes.func.isRequired,
  getAssesstSectionHierarchy: PropTypes.func.isRequired,
  getAssesstPCHierarchy: PropTypes.func.isRequired,
  getAssesstDesignationHierarchy: PropTypes.func.isRequired,
  fetchLocationBranches: PropTypes.func.isRequired,
  getAssesstDeptsHierarchy: PropTypes.func.isRequired,
  getLocationDivsHierarchy: PropTypes.func.isRequired,
  getLocationSectionHierarchy: PropTypes.func.isRequired,
  getLocationDesigHierarchy: PropTypes.func.isRequired,
  getAssessBranchDivsHierarchy: PropTypes.func.isRequired,
  getAssessBranchSectionHierarchy: PropTypes.func.isRequired,
  getAssessBranchPCHierarchy: PropTypes.func.isRequired,
  getAssessBranchDesignationHierarchy: PropTypes.func.isRequired,
  editAssignedAssessment: PropTypes.func.isRequired,
  updateAssignedAssessment: PropTypes.func.isRequired,
};

const mapStatesToProps = (state) => ({
  mainGroupsData: state.webRed.mainGroupsData,
});

export default connect(mapStatesToProps, {
  getMainGroupsData,
  fetchSubGroupsOrLocationsOrSOG,
  fetchSubGroupsDepartments,
  getAssesstSectionHierarchy,
  getAssesstPCHierarchy,
  getAssesstDesignationHierarchy,
  getAssesstDivHierarchy,
  fetchLocationBranches,
  getAssesstDeptsHierarchy,
  getLocationDivsHierarchy,
  getLocationSectionHierarchy,
  getLocationPCHierarchy,
  getLocationDesigHierarchy,
  getAssessBranchDivsHierarchy,
  getAssessBranchSectionHierarchy,
  getAssessBranchPCHierarchy,
  getAssessBranchDesignationHierarchy,
  editAssignedAssessment,
  updateAssignedAssessment,
})(AdminEditAssessment);
