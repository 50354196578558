import React, { Component } from "react";
import { Redirect } from "react-router-dom";
// import Header from '../../Header';
// import Footer from "./../Footer";

import { fetchAdminData } from '../../../../../src/actions/webRedActions';

// import { getEmployeeProfile } from '../../../../actions/utility';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// import userData from './../userData';

import {
    Button,
    Container,
    Row,
    Col,
    Media,
    Form,
    FormGroup,
    Label,
    Input
  } from "reactstrap";

// import readXlsxFile from 'read-excel-file';
import EmployeeProfile from "./EmployeeProfile";
import EmployeeSearchForm from "./EmployeeSearchForm";
import AddEmployeeProfile from "./AddEmployeeProfile";
import AdminHeader from './../AdminHeader';
import SideMenu from'./../sidMenu/SideMenu';
import { getAdminUserData } from "../../../../../src/actions/utility";
// import { BrowserRouter as Router, Route, Link } from "react-router-dom";
// import { ProSidebar, SidebarHeader, SidebarFooter, SidebarContent, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faBars,faHome,faUsers } from '@fortawesome/free-solid-svg-icons';
import 'react-pro-sidebar/dist/css/styles.css';

class AddEmployee extends Component {

    constructor(props) {
        super(props);
        this.state = {
          empProfileData : '',
          empSupervisorData : '',
          empReportingData : '',
          assessmentMangement : '',
          adminData:'',
          isAddEmp:0,
          headerTitle:'Add A New Employee',
          textTitle:'Assign new employee id:',
          btnTitle:'Check',
          isValidEmpID:false,
          validEmpId:''

        };
    }
    componentWillMount() {
      
      console.log("these are the params:",this.props.match.params);

      try {
        
        var adminData = getAdminUserData();
        
        this.state.adminData = adminData;

        if(adminData.is_superadmin == 0) {

          let employeeId = adminData.admin.employee_id
          //checking for updated data on server
          this.props.fetchAdminData(employeeId,function(adminResData) {
            
            // console.log('here is the length:',Object.keys(adminResData).length);
            
            if(adminResData && typeof adminResData ==='object' && adminResData.status == 'success' && Object.keys(adminResData).length) {
              
              localStorage.setItem('userAdminData',JSON.stringify(adminResData.result));
            }
          });
        }
      }
      catch(err) {
        console.log('error',err);
      }
      // console.log('adminData:',adminData);
    }
    

    componentWillReceiveProps(nextProps, thisProps) {

      if(this.props.fetchEmpDataSuccessAdmin != nextProps.fetchEmpDataSuccessAdmin) {
        // console.log(nextProps.fetchEmpDataSuccessAdmin);
        if(nextProps.fetchEmpDataSuccessAdmin.result) {
            if(nextProps.fetchEmpDataSuccessAdmin.result.profile) {
                if(nextProps.fetchEmpDataSuccessAdmin.result.profile !== null) {
                  this.setState({
                    empProfileData : nextProps.fetchEmpDataSuccessAdmin.result.profile
                  }, () => {
                    console.log(this.state.empProfileData)
                  })
                } else {
                    this.setState({
                      empProfileData : ''
                    })
                }
            }

            if(nextProps.fetchEmpDataSuccessAdmin.result.supervision && nextProps.fetchEmpDataSuccessAdmin.result.supervision.length) {
                this.setState({
                  empSupervisorData : nextProps.fetchEmpDataSuccessAdmin.result.supervision
                })
            } else {
              this.setState({
                empSupervisorData : ''
              })
            }

            if(nextProps.fetchEmpDataSuccessAdmin.result.sub_ords && nextProps.fetchEmpDataSuccessAdmin.result.sub_ords.length) {
                this.setState({
                  empReportingData : nextProps.fetchEmpDataSuccessAdmin.result.sub_ords
                })
            } else {
              this.setState({
                empReportingData : ''
              })
            }

        }
      }

      if(this.props.fetchedAllAssessmentsForAdmin != nextProps.fetchedAllAssessmentsForAdmin) {
        if(nextProps.fetchedAllAssessmentsForAdmin.result) {
          this.setState({
            assessmentMangement : nextProps.fetchedAllAssessmentsForAdmin.result
          }, () => {
            console.log(this.state.assessmentMangement)
          })
        }
      }

      if(this.props.validateEmailIDRes != nextProps.validateEmailIDRes) {
          
        if(nextProps.validateEmailIDRes.result.status == 'success' && nextProps.validateEmailIDRes.result.result == '0') {
            this.setState(
              {
                isValidEmpID:true,
                validEmpId:nextProps.validateEmailIDRes.result.employee_id
              }
            )
          }
      }

    }

  render() {
    
    const {empProfileData, empSupervisorData, empReportingData, assessmentMangement} = this.state;
    
    return (
      <div>
        {/*Side menu*/}
        <SideMenu/>

        <AdminHeader title={this.state.headerTitle} style={{marginLeft:80}}/>
        
        <EmployeeSearchForm textTitle={this.state.textTitle} btnTitle={this.state.btnTitle}/>
        {
          this.state.isValidEmpID == true ? 
          <AddEmployeeProfile validEmpId={this.state.validEmpId} userReportingData={[]} userSupervisorData={[]}/>
          :null
        }
        
        {/* <Footer /> */}
        
       
      </div>
     

    );
  }
}

AddEmployee.propTypes = {
    fetchAdminData: PropTypes.func.isRequired
}

const mapStatesToProps = state => ({
    fetchEmpDataSuccessAdmin : state.webRed.fetchEmpDataSuccessAdmin,
    fetchedAllAssessmentsForAdmin : state.webRed.fetchedAllAssessmentsForAdmin,
    validateEmailIDRes : state.webRed.validateEmailIDRes
})

export default connect( mapStatesToProps, { fetchAdminData })( AddEmployee );