import {

	loginCredentialsResponse,
	_getParentQuestions,
	_getQuestions,
	_getSubmitReponse

} from '../actions/form_types';

const initialState = {

	loginResponse : '',
	parentQuestions : '',
	childQuestions : '',
	submittedResponse : ''
};

export default function (state = initialState, action) {
	switch (action.type) {

		case loginCredentialsResponse:
			return {
				...state,
				loginResponse: action.payload
			};

		case _getParentQuestions:
			return {
				...state,
				parentQuestions: action.payload
			};
		
		case _getQuestions:
			return {
				...state,
				childQuestions: action.payload
			};
		
		case _getSubmitReponse:
			return {
				...state,
				submittedResponse: action.payload
			};


		default:
			return state;

	}
}
