import React, { Component, Fragment } from "react";

import { getMainGroupsData,
fetchSubGroupsOrLocationsOrSOG,
fetchSubGroupsDepartments,
getAssesstDivHierarchy,
getAssesstSectionHierarchy,
getAssesstPCHierarchy,
getAssesstDesignationHierarchy,
getAdditionalData,
addNewDesignation,
fetchLocationBranches,
getAssesstDeptsHierarchy,
getAssessRpl,
getLocationDivsHierarchy,
getLocationSectionHierarchy,
getLocationPCHierarchy,
getLocationDesigHierarchy,
getAssessLocationRpl,
getAssessBranchDivsHierarchy,
getAssessBranchSectionHierarchy,
getAssessBranchPCHierarchy,
getAssessBranchDesignationHierarchy,
assignSubGroupAssessment,
assignLocationAssessment
} from '../../../../../src/actions/webRedActions';

import PropTypes from 'prop-types';

import { connect } from 'react-redux';


import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css


import {
    Button,
    Container,
    Row,
    Col,
    FormGroup,
    Label,
    Input,
    Collapse,
    Card,
    CardBody,
    Spinner,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter
} from "reactstrap";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';

import AdminHeader from './../AdminHeader';

import SideMenu from './../sidMenu/SideMenu';

import { getAdminUserData,getBaseUrl } from "../../../../../src/actions/utility";

import 'react-pro-sidebar/dist/css/styles.css';

import $ from 'jquery';

class AdminManageAssessment extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            mainGroupsDataArr: [],
            subGroupDataArr : [],
            deptDataArr : [],
            desigDataArr : [],
            pcDataArr : [],
            divisionsArr : [],
            sectionsArr : [],
            isAllRequiredDataSelected : false,
            loadingSubGroups: false,
            isOpen: [true, true, true, true],
            openModal: true,
            subGroupType : "none",
            loadingDepartment : false,
            loadingDivision : false,
            loadingSection : false,
            loadingPC : false,
            loadingDesig : false,
            competencies: [{
                name: "CORE",
                subcompetencies: [{
                    name: "Customer Focus"
                }, {
                    name: "Communicating Effectively"
                }],
            },
            {
                name: "ORGANIZATIONAL",
                subcompetencies: [{
                    name: "Planning & Organizing"
                }, {
                    name: "Managing Information"
                }],
            }
            ],
            completedata: {
                CORE: {
                    "Customer Focus": {
                        totallPerc: 30
                    },
                    "Communicating Effectively": {
                        totallPerc: 15
                    }
                },
                ORGANIZATIONAL: {
                    "Planning & Organizing": {
                        totallPerc: 30
                    },
                    "Managing Information": {
                        totallPerc: 15
                    }
                },

            },
            addDesignSubGroups : [],
            addDesigDepartments : [],
            loadingAddDesigSubGroups : false,
            addDesigSubGroupType : "none",
            desigAllData : [],
            divisionAllData : [],
            pcAllData : [],
            sectionAllData : [],
            loadingAddDesig : false,
            loadingAddDesigDepartments : false,
            addDesigGroupId : 0,
            addDesigSubGroupId : 0,
            addDesigDeptId : 0,
            addDesigPCId : 0,
            addDesigDivisionId : 0,
            addDesigSectionId : 0,
            addDesigName : "",
            addNewDesigLoader:false,
            loadingAddDesigBranchs:false,
            addDesignBranches : [],
            addDesigLocationId : 0,
            addDesigBranchId : 0,
            subGroupId:0,
            deptId : 0,
            divisionId : 0,
            sectionId : 0,
            pcId : 0 ,
            desigId : 0,
            loadingRpl:false,
            isRplLoaded : false,
            rplDataArr : [],
            loadingLocationDivs : false,
            loadingLocationBranchDivs : false,
            locationId : 0,
            groupId : 0,
            branchDataArr : [],
            isLocationBranch : false,
            branchId : 0,
            isLocationBranchSection : false,
            isLocationBranchPC : false,
            hierarchyComplete : false,
            enableAssign : false,
            selAssessments : [],
            isDone:false,
            hideContent:true

        };
    }

    componentWillMount() {
        this.props.getMainGroupsData("");
        this.props.getAdditionalData();
    }
    componentDidMount() {
        var that = this;
        $(document).on("click",".assessmentList",function() {
            const {selAssessments} =that.state;
            let isChecked  = $(this).is(":checked");
            if (isChecked) {
                selAssessments.push($(this).val());
                that.setState({selAssessments:selAssessments})
            }
            else {
                const index = selAssessments.indexOf($(this).val());
                if (index > -1) {
                    selAssessments.splice(index, 1);
                    that.setState({selAssessments:selAssessments})
                }
            }
        })
    }

    componentWillReceiveProps(nextProps, thisProps) {
        if (this.props.mainGroupsData != nextProps.mainGroupsData) {
            this.setState({ mainGroupsDataArr: nextProps.mainGroupsData.data, isLoading: false }, () => {
                console.log('set state works here', this.state.mainGroupsDataArr);
            })
        }
        if (this.props.additionalData != nextProps.additionalData) {
            console.log("This is my additional data:",nextProps.additionalData.data.departments);
            this.setState({
                desigAllData : nextProps.additionalData.data.designation,
                pcAllData : nextProps.additionalData.data.payClass,
                divisionAllData : nextProps.additionalData.data.divisions,
                sectionAllData : nextProps.additionalData.data.sections
            })
        }
    }

    toggle = () => {
        this.setState({
            openModal: !this.state.openModal
        })
    }
    fetchGroupRelatedData = (event) => {
        console.log(event.target.value)
        const {openModal} = this.state;
        var groupId = event.target.value;
        if(groupId !=0 ) {
            this.setState({... (openModal == false) && {loadingSubGroups : true},...(openModal == true) && {loadingAddDesigSubGroups:true,addDesigGroupId:groupId},...{
            subGroupDataArr : [], deptDataArr : [], divisionsArr:[],sectionsArr:[],pcDataArr:[],desigDataArr:[],rplDataArr:[],groupId : groupId,branchDataArr:[],
            addDesignSubGroups : [],addDesigDepartments : [],addDesignBranches:[]
            }
            },()=> {
                var that = this;
                this.props.fetchSubGroupsOrLocationsOrSOG(groupId,(data)=>{
                    if (data !=0) {
                        if (data.status == "success") {
                            if (openModal == true)
                                that.setState({loadingAddDesigSubGroups : false,addDesignSubGroups : data.result,addDesigSubGroupType:data.type})
                            else
                                that.setState({loadingSubGroups : false,subGroupDataArr : data.result,subGroupType:data.type})
                        }
                        else {
                            that.setState({...(openModal == false) && {loadingSubGroups : false},...(openModal == true) && {loadingAddDesigSubGroups:true}},()=>{
                                alert(data.message)
                            })
                        }
                    }
                    else {
                        that.setState({...(openModal == false) && {loadingSubGroups:false},...(openModal == true) && {loadingAddDesigSubGroups:true}},()=>{
                            alert("data not found")
                        })
                    }
                })
            })
        }
    }
    fetchDepartments = (event) => {
        var subGroupId = event.target.value;
        const {openModal} = this.state;
        if (subGroupId !=0) {
            this.setState({...(openModal == false) && {loadingDepartment : true},...(openModal == true) && {loadingAddDesigDepartments :true,addDesigSubGroupId:subGroupId}},()=>{
                console.log('Loading data here')
                var that = this;
                this.props.fetchSubGroupsDepartments(subGroupId,(departments) => {
                    console.log("departments:",departments);
                    if (departments !=0) {
                        if (departments.status == "success") {
                            if (openModal == true)
                                that.setState({loadingAddDesigDepartments : false, addDesigDepartments : departments.result})
                            else
                                that.setState({loadingDepartment : false, deptDataArr : departments.result})
                        }
                        else {
                            that.setState({...(openModal == false) && {loadingDepartment : false},...(openModal == true) && {loadingAddDesigDepartments :true}},()=>{
                                alert(departments.message)
                            })
                        }
                    }
                    else {
                        that.setState({...(openModal == false) && {loadingDepartment:false},...(openModal == true) && {loadingAddDesigDepartments :true}},()=>{
                            alert("data not found")
                        })
                    }
                })
            })
        }
    }
    fetchDepartmentsHierarchy = (event) => {
        var subGroupId = event.target.value;
        if (subGroupId !=0) {
            this.setState({loadingDepartment:true,subGroupId:subGroupId,deptDataArr : [], divisionsArr:[],sectionsArr:[],pcDataArr:[],desigDataArr:[],rplDataArr:[]})
            var that = this;
            this.props.getAssesstDeptsHierarchy(subGroupId,(depts)=>{
                console.log("depts:",depts)
                if (depts.status == "success") {
                    that.setState({loadingDepartment:false,deptDataArr:depts.result})
                }
                else {
                    that.setState({loadingDepartment:false},()=>{
                        alert(depts.message)
                    })
                }
            });   
        }
    }
    fetchDivisions = (event) => {
        var deptId = event.target.value;
        if (deptId !='-0') {
            this.setState({loadingDivision:true,deptId:deptId,divisionsArr:[],sectionsArr:[],pcDataArr:[],desigDataArr:[],rplDataArr:[]})
            console.log('get hierarchy')
            var that = this;
            this.props.getAssesstDivHierarchy(deptId,this.state.subGroupId,(divisions)=>{
                console.log("in divisions:",divisions);
                if (divisions.status == "success") {
                    that.setState({loadingDivision:false,divisionsArr: divisions.result})
                }
                else {
                    that.setState({loadingDivision:false},()=>{
                        alert("data not found");
                    })
                }
            });
        }

    }
    fetchSections = (event) => {
        var divId = event.target.value;
        const {subGroupType,isLocationBranch} = this.state
        if (divId !='-0' && subGroupType == "sub_groups") {
            const {subGroupId,deptId} = this.state;
            this.setState({loadingSection:true,divisionId:divId,sectionsArr:[],pcDataArr:[],desigDataArr:[],rplDataArr:[]});
            var that = this;
            this.props.getAssesstSectionHierarchy(subGroupId,deptId,divId,(sections)=>{
                console.log("sections data:",sections)
                if (sections.status == "success") {
                    that.setState({loadingSection:false,sectionsArr: sections.result})
                }
                else {
                    that.setState({loadingSection:false},()=>{
                        alert("data not found");
                    })
                }
            });
        }
        else if (divId !='-0' && subGroupType == "locations") {
            
            console.log("load the section of location")
            
            const {locationId,isLocationBranch,branchId} = this.state;
            if (isLocationBranch == false) {
                
                this.setState({isLocationBranchSection : false, loadingSection:true,divisionId:divId,sectionsArr:[],pcDataArr:[],desigDataArr:[],rplDataArr:[]});
                
                var that = this;

                this.props.getLocationSectionHierarchy(locationId,divId,(sectionData) => {
                    console.log("sectionData:",sectionData)
                    if (sectionData.status == "success") {
                        that.setState({loadingSection:false,sectionsArr: sectionData.result})
                    }
                    else {
                        that.setState({loadingSection:false},()=>{
                            alert("data not found");
                        })
                    }
                });
            }
            else {
                
                this.setState({isLocationBranchSection : true,loadingSection:true,divisionId:divId,sectionsArr:[],pcDataArr:[],desigDataArr:[],rplDataArr:[]});
                
                var that = this;

                this.props.getAssessBranchSectionHierarchy(locationId,branchId,divId,(sectionData)=> {
                    console.log("sectionData:",sectionData)
                    if (sectionData.status == "success") {
                        that.setState({loadingSection:false,sectionsArr: sectionData.result})
                    }
                    else {
                        that.setState({loadingSection:false},()=>{
                            alert("data not found");
                        })
                    }
                });
            }
        }
    }
    fetchPayClass = (event) => {
        var sectionId = event.target.value;
        const {subGroupType,branchId} = this.state
        if (sectionId !='-0' && subGroupType == "sub_groups") {
            const {subGroupId,deptId,divisionId} = this.state;
            this.setState({loadingPC:true,sectionId:sectionId,pcDataArr:[],desigDataArr:[],rplDataArr:[]})
            var that = this;
            this.props.getAssesstPCHierarchy(subGroupId,deptId,divisionId,sectionId,(pcData) => {
                console.log('pcData:',pcData)
                if (pcData.status == "success") {
                    that.setState({loadingPC:false,pcDataArr : pcData.result})
                }
                else {
                    that.setState({loadingPC:false},()=>{
                        alert("data not found");
                    })
                }
            })
        }
        else if (sectionId !='-0' && subGroupType == "locations") {
            const {locationId,divisionId,isLocationBranchSection} = this.state;
            if (isLocationBranchSection == false) {
                this.setState({isLocationBranchPC : false,loadingPC:true,sectionId:sectionId,pcDataArr:[],desigDataArr:[],rplDataArr:[]})
                var that = this;
                this.props.getLocationPCHierarchy(locationId,divisionId,sectionId,(pcData) => {
                    console.log('jgData:',pcData)
                    if (pcData.status == "success") {
                        that.setState({loadingPC:false,pcDataArr : pcData.result})
                    }
                    else {
                        that.setState({loadingPC:false},()=>{
                            alert("data not found");
                        })
                    }
                })
            }
            else {
                console.log("load branch payclass")
                this.setState({isLocationBranchPC : true, loadingPC:true,sectionId:sectionId,pcDataArr:[],desigDataArr:[],rplDataArr:[]})
                var that = this;
                this.props.getAssessBranchPCHierarchy(locationId,branchId,divisionId,sectionId,(pcData) => {
                    console.log('jgData:',pcData)
                    if (pcData.status == "success") {
                        that.setState({loadingPC:false,pcDataArr : pcData.result})
                    }
                    else {
                        that.setState({loadingPC:false},()=>{
                            alert("data not found");
                        })
                    }
                })
            }
        }
    }
    fetchDesignation = (event) => {
        const {subGroupType} = this.state
        var pcId = event.target.value;
        if (pcId !='-0' && subGroupType == "sub_groups") {
            const {subGroupId,deptId,divisionId,sectionId} = this.state;
            this.setState({loadingDesig : true,pcId:pcId,desigDataArr:[],rplDataArr:[]})
            var that = this;
            this.props.getAssesstDesignationHierarchy(subGroupId,deptId,divisionId,sectionId,pcId,(desigData)=> {
                console.log('desigData:',desigData)
                if(desigData.status == "success") {
                    that.setState({loadingDesig:false,desigDataArr : desigData.result})
                }
                else {
                    that.setState({loadingDesig:false},()=>{
                        alert("data not found");
                    })
                }
            });
        }
        else if(pcId !='-0' && subGroupType == "locations") {
            const {locationId,divisionId,sectionId,isLocationBranchPC,branchId} = this.state;
            if (isLocationBranchPC ==  false) {

                this.setState({loadingDesig : true,pcId:pcId,desigDataArr:[],rplDataArr:[]})
                var that = this;
                this.props.getLocationDesigHierarchy(locationId,divisionId,sectionId,pcId,(desigData)=> {
                    console.log('desigData:',desigData)
                    if(desigData.status == "success") {
                        that.setState({loadingDesig:false,desigDataArr : desigData.result})
                    }
                    else {
                        that.setState({loadingDesig:false},()=>{
                            alert("data not found");
                        })
                    }
                });
            }
            else {
                console.log("load the braches designation");
                this.setState({loadingDesig : true,pcId:pcId,desigDataArr:[],rplDataArr:[]})
                var that = this;
                this.props.getAssessBranchDesignationHierarchy(locationId,branchId,divisionId,sectionId,pcId,(desigData)=> {
                    console.log('desigData:',desigData)
                    if(desigData.status == "success") {
                        that.setState({loadingDesig:false,desigDataArr : desigData.result})
                    }
                    else {
                        that.setState({loadingDesig:false},()=>{
                            alert("data not found");
                        })
                    }
                });
            }
        }
    }
    fetchLocationDivsHierarchy = (event) => {
        let locationId = event.target.value

        const {groupId} =  this.state;
        
        if (locationId !="0" && (groupId == 1 || groupId == 2) ) {
            this.setState({branchId:0,isLocationBranch:false,locationId : locationId,loadingLocationDivs : true,divisionsArr:[],sectionsArr:[],pcDataArr:[],desigDataArr:[],rplDataArr:[]})
            console.log("go for bracnhes:",locationId);
            var that = this;
            this.props.getLocationDivsHierarchy(locationId,(divsData)=>{
                console.log("divsData:",divsData)
                if (typeof divsData.status !=="undefined" && divsData.status == "success") {
                    that.setState({divisionsArr:divsData.result,loadingLocationDivs : false})
                }
                else if (typeof divsData.status !=="undefined" && divsData.status == "error") {
                    that.setState({divisionsArr:[],loadingLocationDivs : false},()=>{
                        alert(divsData.message)
                    })
                }
                else {
                    that.setState({divisionsArr:[],loadingLocationDivs : false},()=>{
                        alert("Unknown error.")
                    })
                }
            })
        }
        else if(locationId !="0" && groupId == 3) {
            this.setState({branchId:0,locationId : locationId, loadingLocationDivs : true,branchDataArr:[],divisionsArr:[],sectionsArr:[],pcDataArr:[],desigDataArr:[],rplDataArr:[]})
            var that = this;
            this.props.fetchLocationBranches(locationId,(locationBranch) => {
                console.log("locationBranch:",locationBranch)
                if (typeof locationBranch.status !=="undefined" && locationBranch.status == "success") {
                    that.setState({branchDataArr:locationBranch.result,loadingLocationDivs : false})
                }
                else if (typeof locationBranch.status !=="undefined" && locationBranch.status == "error") {
                    that.setState({branchDataArr:[],loadingLocationDivs : false},()=>{
                        alert(locationBranch.message)
                    })
                }
                else {
                    that.setState({branchDataArr:[],loadingLocationDivs : false},()=>{
                        alert("Unknown error.")
                    })
                }
            });
        }
    }
    fetchLocationBranchDivs = (event) => {
        let branchId = event.target.value;
        if (branchId !="0") {
            const{locationId} = this.state;
            var that = this;
            this.setState({branchId : branchId,isLocationBranch:true,loadingLocationBranchDivs : true,divisionsArr:[],sectionsArr:[],pcDataArr:[],desigDataArr:[],rplDataArr:[]});
            this.props.getAssessBranchDivsHierarchy(locationId,branchId,(divsData) => {
                console.log("branchDivisions:",divsData)
                if (typeof divsData.status !=="undefined" && divsData.status == "success") {
                    that.setState({divisionsArr:divsData.result,loadingLocationBranchDivs : false})
                }
                else if (typeof divsData.status !=="undefined" && divsData.status == "error") {
                    that.setState({divisionsArr:[],loadingLocationBranchDivs : false},()=>{
                        alert(divsData.message)
                    })
                }
                else {
                    that.setState({divisionsArr:[],loadingLocationBranchDivs : false},()=>{
                        alert("Unknown error.")
                    })
                }
            });
        }
    }
    render() {

        const { 
            isLoading,
            mainGroupsDataArr,
            competencies, 
            subGroupDataArr,
            deptDataArr,
            desigDataArr,
            pcDataArr,
            divisionsArr,
            sectionsArr,
            loadingSubGroups,
            subGroupType,
            loadingDepartment,
            loadingDivision,
            loadingSection,
            loadingPC,
            loadingDesig,
            isOpen, 
            openModal,
            addDesignSubGroups,
            loadingAddDesigSubGroups,
            addDesigSubGroupType,
            desigAllData,
            pcAllData,
            divisionAllData,
            sectionAllData,
            loadingAddDesigDepartments,
            addDesigDepartments,
            addNewDesigLoader,
            loadingAddDesigBranchs,
            addDesignBranches,
            loadingRpl,
            rplDataArr,
            loadingLocationDivs,
            branchDataArr,
            loadingLocationBranchDivs,
            hierarchyComplete,
            enableAssign,
            isDone
        } = this.state;
        //console.log("Faheem in rander addDesigDepartments:",desigAllData);

        return (
            <div>
                {/*Side menu*/}
                <SideMenu />

                <AdminHeader title={" Assessment Management"} style={{ marginLeft: 80 }} />
                {
                    isLoading == false ?
                        <Container className="AssessmentStatistics ManageAssessment">
                            {/*Modal start */}
                            <Row>
                                <Col md={{ size: 12 }}>
                                    <Row className="justify-content-center" style={{ background: '#d5eafb', padding: "15px" }}>
                                        <Col md={{ size: 6 }} className="text-center">
                                            
                                                    <Row>
                                                        <Col md={{ size: 12 }}>
                                                        <h5 style={{ fontSize: '18px', fontWeight: 'normal' }}>ASSIGN DESIGNATION</h5>
                                                            <FormGroup>
                                                                <Label for="BRANCH">Main Group</Label>:
                                                                <Input type="select" name="report_year" id="report_year" onChange={this.fetchGroupRelatedData}>
                                                                <option value="0">Select Main Group</option>
                                                                {
                                                                mainGroupsDataArr.length > 0 ? 
                                                                    
                                                                    mainGroupsDataArr.map((group,index) =>
                                                                       
                                                                        <option  key={index} value={group.group_id}>{group.group_name}</option>
                                                                    )
                                                                   
                                                                :null
                                                                }
                                                             </Input>
                                                            </FormGroup>
                                                            {
                                                            loadingAddDesigSubGroups == true ? 
                                                                <Spinner color="primary" style={{
                                                                    width: '1.10rem',
                                                                    height: '1.10rem',
                                                                    marginTop: '-40px',
                                                                    float: 'right' 
                                                                }} />
                                                            :null
                                                            }
                                                        </Col>
                                                        {
                                                        addDesignSubGroups.length > 0 && addDesigSubGroupType == "sub_groups"?   
                                                        <Col md={{ size: 12 }}>
                                                            <FormGroup>
                                                                <Label for="BRANCH">Sub Group</Label>:
                                                                <Input type="select" name="report_year" id="report_year" onChange={this.fetchDepartments}>
                                                                    <option value="0">Select Sub Group</option>
                                                                    {
                                                                        addDesignSubGroups.map((subGroup,index) =>
                                                                        <option  key={index} value={subGroup.sub_groupid}>{subGroup.sub_groupname}</option>
                                                                        )
                                                                    }
                                                                    
                                                                </Input>
                                                            </FormGroup>
                                                            {
                                                            loadingAddDesigDepartments == true ? 
                                                                <Spinner color="primary" style={{
                                                                    width: '1.10rem',
                                                                    height: '1.10rem',
                                                                    marginTop: '-40px',
                                                                    float: 'right' 
                                                                }} />
                                                            :null
                                                            }
                                                        </Col>
                                                        : null
                                                        }
                                                        {
                                                        addDesignSubGroups.length > 0 && addDesigSubGroupType == "locations"?   
                                                        <Col md={{ size: 12 }}>
                                                            <FormGroup>
                                                                <Label for="BRANCH">Locations</Label>:
                                                                <Input type="select" name="report_year" id="report_year" onChange={(event)=>{
                                                                    let locationId = event.target.value;
                                                                    if (locationId !=0 && this.state.addDesigGroupId == 3) {
                                                                        this.setState({loadingAddDesigBranchs: true,addDesigLocationId : locationId})
                                                                        var that = this
                                                                        this.props.fetchLocationBranches(locationId,(locationData)=>{
                                                                            console.log("locationData:",locationData)
                                                                            if (typeof locationData.status !=="undefined" && locationData.status == "success") {
                                                                                that.setState({addDesignBranches:locationData.result,loadingAddDesigBranchs:false})
                                                                            }
                                                                            else {
                                                                                that.setState({loadingAddDesigBranchs:false},()=>{
                                                                                    alert(locationData.message)
                                                                                })
                                                                            }
                                                                        });
                                                                    }
                                                                    else
                                                                        this.setState({addDesigLocationId : locationId})
                                                                }}>
                                                                    <option value="0">Select Location</option>
                                                                    {
                                                                        addDesignSubGroups.map((subGroup,index) =>
                                                                        <option  key={index} value={subGroup.location_id}>{subGroup.location_name}</option>
                                                                        )
                                                                    }
                                                                    
                                                                </Input>
                                                                {
                                                                    loadingAddDesigBranchs == true ? 
                                                                        <Spinner color="primary" style={{
                                                                            width: '1.10rem',
                                                                            height: '1.10rem',
                                                                            marginTop: '-40px',
                                                                            float: 'right' 
                                                                        }} />
                                                                    :null
                                                                }
                                                            </FormGroup>
                                                        </Col>
                                                        : null
                                                        }
                                                        {
                                                        addDesignBranches.length > 0 ? 
                                                            <Col md={{ size: 12 }}>
                                                                <FormGroup>
                                                                    <Label for="BRANCH">Branches</Label>:
                                                                    <Input type="select" name="report_year" id="report_year" onChange={(event)=>{
                                                                        let branchId = event.target.value;
                                                                        console.log("branch Id:",branchId);
                                                                        if (branchId !=0) {
                                                                            this.setState({addDesigBranchId : branchId})
                                                                        }
                                                                    }}>
                                                                        <option value="0">Select Branch</option>
                                                                        {
                                                                        addDesignBranches.length > 0 ?
                                                                            addDesignBranches.map((branch,index) => 
                                                                                <option value={branch.branch_id}>{branch.branch_name}</option>
                                                                            )
                                                                        :null
                                                                        }
                                                                    </Input>
                                                                </FormGroup>
                                                            </Col>
                                                        :null
                                                        }
                                                        {
                                                        addDesigDepartments.length > 0 ? 
                                                            <Col md={{ size: 12 }}>
                                                                <FormGroup>
                                                                    <Label for="BRANCH">Departemens</Label>:
                                                                    <Input type="select" name="report_year" id="report_year" onChange={(event)=>{
                                                                        let deptId = event.target.value;
                                                                        console.log("Dept Id:",deptId);
                                                                        if (deptId !=0) {
                                                                            this.setState({addDesigDeptId : deptId})
                                                                        }
                                                                    }}>
                                                                        <option value="0">Select Department</option>
                                                                        {
                                                                        addDesigDepartments.length > 0 ?
                                                                            addDesigDepartments.map((dept,index) => 
                                                                                <option value={dept.dept_id}>{dept.dept_name} - {dept.dept_suffix}</option>
                                                                            )
                                                                        :null
                                                                        }
                                                                    </Input>
                                                                </FormGroup>
                                                            </Col>
                                                            :null
                                                        }

                                                        <Col md={{ size: 12 }}>
                                                            <FormGroup>
                                                                <Label for="BRANCH">Divisions</Label>:
                                                                    <Input type="select" name="report_year" id="report_year" onChange={(event)=>{
                                                                        let divisionId = event.target.value
                                                                        // if (divisionId !=0) {
                                                                            this.setState({addDesigDivisionId : divisionId})
                                                                        // }
                                                                    }}>
                                                                    <option value="0">Other division</option>
                                                                    {
                                                                        divisionAllData.length > 0 ?
                                                                            divisionAllData.map((division,index)=>
                                                                                <option value={division.division_id}>{division.division_name}</option>
                                                                            )
                                                                        :null
                                                                    }
                                                                </Input>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col md={{ size: 12 }}>
                                                            <FormGroup>
                                                                <Label for="BRANCH">Sections</Label>:
                                                                    <Input type="select" name="report_year" id="report_year" onChange={(event) => {
                                                                        let sectionId = event.target.value
                                                                        // if(sectionId !=0) {
                                                                            this.setState({addDesigSectionId:sectionId})
                                                                        // }
                                                                    }}>
                                                                    <option value="0">Other section</option>
                                                                    {
                                                                        sectionAllData.length > 0 ?
                                                                        sectionAllData.map((section,index)=>
                                                                                <option value={section.section_id}>{section.section_name}</option>
                                                                            )
                                                                        :null
                                                                    }
                                                                </Input>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col md={{ size: 12 }}>
                                                            <FormGroup>
                                                                <Label for="BRANCH">Job Grade</Label>:
                                                                    <Input type="select" name="report_year" id="report_year" onChange={(event)=>{
                                                                        let pcId = event.target.value
                                                                        if (pcId !=0) {
                                                                            this.setState({addDesigPCId : pcId})
                                                                        }
                                                                    }}>
                                                                    <option value="0">Select Job Grade</option>
                                                                    {
                                                                        pcAllData.length > 0 ?
                                                                            pcAllData.map((pc,index)=>
                                                                                <option value={pc.pc_id}>{pc.pc_name}</option>
                                                                            )
                                                                        :null
                                                                    }
                                                                </Input>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col md={{ size: 12 }}>
                                                            <FormGroup>
                                                                <Label for="BRANCH">Designation</Label>:
                                                                <Input type="text" name="report_year" id="report_year" onChange={(event) => {
                                                                    this.setState({addDesigName : event.target.value})
                                                                }}>
                                            
                                                                </Input>
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                               
                                                    <Button className="btn btn-block" color="primary" onClick={()=>{
                                                        const{
                                                        addDesigName,
                                                        addDesigDeptId,
                                                        addDesigDivisionId,
                                                        addDesigPCId,
                                                        addDesigSectionId,
                                                        addDesigGroupId,
                                                        addDesigSubGroupId,
                                                        addDesigSubGroupType,
                                                        addDesigLocationId,
                                                        addDesigBranchId
                                                        } = this.state
                                                        console.log("Here i am adding the new designation")
                                                        if (addDesigSubGroupType == "sub_groups") {
                                                            if (addDesigDeptId == 0) {
                                                                alert("Select department to procceed. Select main group then sub group then department")
                                                                return false
                                                            }
                                                            else if (addDesigPCId == 0 ) {
                                                                alert("Select Job Grade to procceed")
                                                                return false
                                                            }
                                                            // else if (addDesigDivisionId == 0) {
                                                            //     alert("Select division to procceed")
                                                            //     return false
                                                            // }
                                                            // else if (addDesigSectionId == 0) {
                                                            //     alert("Select section to procceed")
                                                            //     return false
                                                            // }
                                                            else if (addDesigName == "") {
                                                                alert("Designation name required to procceed")
                                                                return false
                                                            }
                                                            else {
                                                                this.setState({addNewDesigLoader : true});
                                                                console.log(addDesigDeptId,":addDesigDeptId:",addDesigPCId,":addDesigPCId:",addDesigSectionId,":addDesigSectionId:",addDesigName,":addDesigName")
                                                                var that = this
                                                                this.props.addNewDesignation(addDesigGroupId,addDesigSubGroupId,addDesigDeptId,addDesigPCId,addDesigDivisionId,addDesigSectionId,addDesigName,0,0,(data)=>{
                                                                    that.setState({addNewDesigLoader : false});
                                                                    if (typeof data.status !=="undefined" && data.status == "success") {
                                                                        window.location.reload();
                                                                    }
                                                                    else 
                                                                        alert(data.message)
                                                                })
                                                            }
                                                        }
                                                        else if (addDesigSubGroupType == "locations") {
                                                            
                                                            if (addDesigLocationId == 0) {
                                                                alert("Select location to procceed. Select main group then location")
                                                                return false
                                                            }
                                                            if (addDesigBranchId == 0 && addDesigGroupId == 3) {
                                                                alert("Select branch to procceed. Select main group then location then branch")
                                                                return false
                                                            }
                                                            else if (addDesigDivisionId == 0) {
                                                                alert("Select division to procceed")
                                                                return false
                                                            }
                                                            else if (addDesigSectionId == 0) {
                                                                alert("Select section to procceed")
                                                                return false
                                                            }
                                                            else if (addDesigPCId == 0 ) {
                                                                alert("Select Job Grade to procceed")
                                                                return false
                                                            }
                                                            else if (addDesigName == "") {
                                                                alert("Designation name required to procceed")
                                                                return false
                                                            }
                                                            else {
                                                                this.setState({addNewDesigLoader : true});
                                                                console.log(addDesigDeptId,":addDesigDeptId:",addDesigPCId,":addDesigPCId:",addDesigSectionId,":addDesigSectionId:",addDesigName,":addDesigName")
                                                                var that = this
                                                                this.props.addNewDesignation(addDesigGroupId,addDesigSubGroupId,addDesigDeptId,addDesigPCId,addDesigDivisionId,addDesigSectionId,addDesigName,addDesigLocationId,addDesigBranchId,(data)=>{
                                                                    that.setState({addNewDesigLoader : false});
                                                                    if (typeof data.status !=="undefined" && data.status == "success") {
                                                                        window.location.reload();
                                                                    }
                                                                    else 
                                                                        alert(data.message)
                                                                })
                                                            }
                                                        }
                                                        else {
                                                            alert("Select main group to procceed")
                                                            return false
                                                        }
                                                    }}>Add
                                                    {
                                                     addNewDesigLoader == true ?    
                                                        <Spinner size="sm" color="light" style={{marginLeft:5}}/>
                                                     :null
                                                     }
                                                    </Button>
                                        </Col>
                                    </Row>
                                   
                                    {this.state.hideContent == false?
                                    <fieldset disabled={hierarchyComplete} >
                                        <Row>
                                            <Col md={{ size: 6 }}>
                                                <FormGroup>
                                                    <Label for="BRANCH">Main Group</Label>:
                                                    <Input type="select" name="main_group" id="main_group" onChange={this.fetchGroupRelatedData}>
                                                        <option value="0">Select main group</option>
                                                        {
                                                            mainGroupsDataArr.length > 0 ? 
                                                                mainGroupsDataArr.map((group,index)=>
                                                                    <option value={group.group_id}>{group.group_name}</option>
                                                                )
                                                            :null
                                                        }
                                                    </Input>
                                                </FormGroup>
                                                {
                                                loadingSubGroups == true ? 
                                                    <Spinner color="primary" style={{
                                                        width: '1.10rem',
                                                        height: '1.10rem',
                                                        marginTop: '-40px',
                                                        float: 'right' 
                                                    }} />
                                                :null
                                                }
                                            </Col>
                                            {
                                            subGroupDataArr.length > 0 && subGroupType == "sub_groups" ?
                                                <Fragment>
                                                    <Col md={{ size: 6 }} />
                                                    <Col md={{ size: 6 }}>
                                                        <FormGroup>
                                                            <Label for="BRANCH">Sub Group</Label>:
                                                            <Input type="select" name="report_year" id="report_year" onChange={this.fetchDepartmentsHierarchy}>
                                                                <option value="0">Select sub group</option>
                                                                {
                                                                    subGroupDataArr.map((subGroup,index)=>
                                                                        <option value={subGroup.sub_groupid}>{subGroup.sub_groupname}</option>
                                                                    )
                                                                }
                                                            </Input>
                                                        </FormGroup>
                                                        {
                                                        loadingDepartment == true ? 
                                                            <Spinner color="primary" style={{
                                                                width: '1.10rem',
                                                                height: '1.10rem',
                                                                marginTop: '-40px',
                                                                float: 'right' 
                                                            }} />
                                                        :null
                                                        }
                                                    </Col>
                                                </Fragment>
                                            :
                                            subGroupDataArr.length > 0 && subGroupType == "locations" ? 
                                                <Fragment>
                                                        <Col md={{ size: 6 }} />
                                                        <Col md={{ size: 6 }}>
                                                            <FormGroup>
                                                                <Label for="BRANCH">Locations</Label>:
                                                                <Input type="select" name="report_year" id="report_year" onChange={this.fetchLocationDivsHierarchy}>
                                                                    <option value="0">Select location</option>
                                                                    {
                                                                        subGroupDataArr.map((location,index)=>
                                                                            <option value={location.location_id}>{location.location_name}</option>
                                                                        )
                                                                    }
                                                                </Input>
                                                            </FormGroup>
                                                            {
                                                            loadingLocationDivs == true ? 
                                                                <Spinner color="primary" style={{
                                                                    width: '1.10rem',
                                                                    height: '1.10rem',
                                                                    marginTop: '-40px',
                                                                    float: 'right' 
                                                                }} />
                                                            :null
                                                            }
                                                        </Col>
                                                    </Fragment>
                                                :null
                                            }
                                            {
                                            branchDataArr.length > 0 ?
                                            <Fragment>
                                                        <Col md={{ size: 6 }} />
                                                        <Col md={{ size: 6 }}>
                                                            <FormGroup>
                                                                <Label for="BRANCH">Branches</Label>:
                                                                <Input type="select" name="report_year" id="report_year" onChange={this.fetchLocationBranchDivs}>
                                                                    <option value="0">Select branch</option>
                                                                    {
                                                                        branchDataArr.map((branch,index)=>
                                                                            <option value={branch.branch_id}>{branch.branch_name}</option>
                                                                        )
                                                                    }
                                                                </Input>
                                                            </FormGroup>
                                                            {
                                                            loadingLocationBranchDivs == true ? 
                                                                <Spinner color="primary" style={{
                                                                    width: '1.10rem',
                                                                    height: '1.10rem',
                                                                    marginTop: '-40px',
                                                                    float: 'right' 
                                                                }} />
                                                            :null
                                                            }
                                                        </Col>
                                                    </Fragment>
                                            :null 
                                            }
                                            {
                                            deptDataArr.length > 0 ? 
                                            <Fragment>
                                                <Col md={{ size: 6 }} />
                                                <Col md={{ size: 6 }}>
                                                    <FormGroup>
                                                        <Label for="BRANCH">Departments</Label>:
                                                        <Input type="select" name="report_year" id="report_year" onChange={this.fetchDivisions}>
                                                            <option value="-0">Select department</option>
                                                            {
                                                                deptDataArr.map((dept,index)=>
                                                                    <option value={dept.dept_id}>{dept.dept_suffix}</option>
                                                                )
                                                            }
                                                        </Input>
                                                    </FormGroup>
                                                    {
                                                    loadingDivision == true ? 
                                                        <Spinner color="primary" style={{
                                                            width: '1.10rem',
                                                            height: '1.10rem',
                                                            marginTop: '-40px',
                                                            float: 'right' 
                                                        }} />
                                                    :null
                                                    }
                                                </Col>
                                            </Fragment>
                                            :null
                                            }

                                            {
                                            divisionsArr.length > 0 ? 
                                            <Fragment>
                                                <Col md={{ size: 6 }} />
                                                <Col md={{ size: 6 }}>
                                                    <FormGroup>
                                                        <Label for="BRANCH">Divisions</Label>:
                                                        <Input type="select" name="report_year" id="report_year" onChange={this.fetchSections}>
                                                            <option value="-0">Select Division</option>
                                                            {
                                                                divisionsArr.map((division,index)=>
                                                                    <option value={division.division_id}>{division.division_name}</option>
                                                                )
                                                            }
                                                        </Input>
                                                    </FormGroup>
                                                    {
                                                    loadingSection == true ? 
                                                        <Spinner color="primary" style={{
                                                            width: '1.10rem',
                                                            height: '1.10rem',
                                                            marginTop: '-40px',
                                                            float: 'right' 
                                                        }} />
                                                    :null
                                                    }
                                                </Col>
                                            </Fragment>
                                            :null
                                            }

                                            {
                                            sectionsArr.length > 0 ? 
                                            <Fragment>
                                                <Col md={{ size: 6 }} />
                                                <Col md={{ size: 6 }}>
                                                    <FormGroup>
                                                        <Label for="BRANCH">sections</Label>:
                                                        <Input type="select" name="report_year" id="report_year" onChange={this.fetchPayClass}>
                                                            <option value="-0">Select selection</option>
                                                            {
                                                                sectionsArr.map((section,index)=>
                                                                    <option value={section.section_id}>{section.section_name}</option>
                                                                )
                                                            }
                                                        </Input>
                                                    </FormGroup>
                                                    {
                                                    loadingPC == true ? 
                                                        <Spinner color="primary" style={{
                                                            width: '1.10rem',
                                                            height: '1.10rem',
                                                            marginTop: '-40px',
                                                            float: 'right' 
                                                        }} />
                                                    :null
                                                    }
                                                </Col>
                                            </Fragment>
                                            :null
                                            }

                                            {
                                            pcDataArr.length > 0 ? 
                                            <Fragment>
                                                <Col md={{ size: 6 }} />

                                                <Col md={{ size: 6 }}>
                                                    <FormGroup>
                                                        <Label for="BRANCH">Job Grade</Label>:
                                                        <Input type="select" name="report_year" id="report_year" onChange={this.fetchDesignation}>
                                                            <option value="-0">Select Job Grade</option>
                                                            {
                                                            pcDataArr.map((pc,index)=>
                                                                <option value={pc.pc_id}>{pc.pc_name}</option>
                                                                )
                                                            }
                                                        </Input>
                                                    </FormGroup>
                                                    {
                                                    loadingDesig == true ? 
                                                        <Spinner color="primary" style={{
                                                            width: '1.10rem',
                                                            height: '1.10rem',
                                                            marginTop: '-40px',
                                                            float: 'right' 
                                                        }} />
                                                    :null
                                                    }
                                                </Col>
                                            </Fragment>
                                            :null
                                            }
                                            {
                                            desigDataArr.length > 0 ? 
                                                <Fragment>
                                                    <Col md={{ size: 6 }} />

                                                    <Col md={{ size: 6 }}>
                                                        <FormGroup>
                                                            <Label for="BRANCH">Designation</Label>:
                                                            <Input type="select" name="report_year" id="report_year" onChange={(event)=>{
                                                                console.log("fetch the rpls");
                                                                const desigId = event.target.value;
                                                                let type = this.state.subGroupType;
                                                                if (desigId !="-0" && type == "sub_groups") {
                                                                    const {subGroupId,deptId,divisionId,sectionId,pcId} =  this.state;
                                                                    this.setState({loadingRpl:true,rplDataArr:[],enableAssign:true,desigId:desigId})
                                                                    var that = this;
                                                                    this.props.getAssessRpl(subGroupId,deptId,divisionId,sectionId,pcId,desigId,(rplData) => {
                                                                        console.log("rplData:",rplData)
                                                                        //that.setState({loadingRpl:false})
                                                                        if (rplData.status == "success") {
                                                                            console.log("this length:",rplData.result.length)
                                                                            that.setState({loadingRpl:false,rplDataArr: rplData.result});
                                                                        }
                                                                    });
                                                                }
                                                                else if (desigId !="-0" && type == "locations") {
                                                                    const {locationId,divisionId,sectionId,pcId,isLocationBranchPC,branchId} = this.state;

                                                                    this.setState({loadingRpl:true,rplDataArr:[],enableAssign:true,desigId:desigId})
                                                                    var that = this;
                                                                    if (isLocationBranchPC == false) {
                                                                        this.props.getAssessLocationRpl(locationId,divisionId,sectionId,pcId,desigId,0,(rplData) => {
                                                                            console.log("rplData:",rplData)
                                                                            //that.setState({loadingRpl:false})
                                                                            if (rplData.status == "success") {
                                                                                console.log("this length:",rplData.result.length)
                                                                                that.setState({loadingRpl:false,rplDataArr: rplData.result});
                                                                            }
                                                                        });
                                                                    }
                                                                    else {
                                                                        this.props.getAssessLocationRpl(locationId,divisionId,sectionId,pcId,desigId,branchId,(rplData) => {
                                                                            console.log("rplData:",rplData)
                                                                            //that.setState({loadingRpl:false})
                                                                            if (rplData.status == "success") {
                                                                                console.log("this length:",rplData.result.length)
                                                                                that.setState({loadingRpl:false,rplDataArr: rplData.result});
                                                                            }
                                                                        });
                                                                    }
                                                                
                                                                }
                                                            }}>
                                                                <option value="-0">Select designation</option>
                                                                {
                                                                    desigDataArr.map((desig,index) => 
                                                                        <option value={desig.desig_id}>{desig.desig_name}</option>
                                                                    )
                                                                }
                                                                
                                                            </Input>
                                                        </FormGroup>
                                                        {
                                                        loadingRpl == true ? 
                                                            <Spinner color="primary" style={{
                                                                width: '1.10rem',
                                                                height: '1.10rem',
                                                                marginTop: '-40px',
                                                                float: 'right' 
                                                            }} />
                                                        :null
                                                        }
                                                    </Col>
                                                    
                                                </Fragment>
                                            :null
                                            }
                                            
                                            <Col md={{ size: 6 }} />

                                            <Col md={{ size: 12 }} className="text-center" style={{display:enableAssign == true ? "block" : "none"}}>
                                                <Button size="sm" className="btn btn-dark" onClick={()=>{
                                                    this.setState({hierarchyComplete :true})
                                                }}>Assign</Button>
                                            </Col>
                                        </Row>
                                    </fieldset>
                                    :null}
                                </Col>
                            </Row>

                            <br />

                            <Row>

                                <Col md={{ size: 12 }}>
                                    {
                                        typeof rplDataArr.main_compt !=="undefined" && rplDataArr.main_compt.length && hierarchyComplete == true?
                                            rplDataArr.main_compt.map((comp, key) =>
                                                
                                                    <div key={key} className="text-left">

                                                        <Row className="text-left">
                                                            <Col md={{ size: 4 }}>

                                                                <FontAwesomeIcon
                                                                    className="toggleIconHere"
                                                                    icon={isOpen[key] ? faMinus : faPlus} size="1x"
                                                                    onClick={(e) => {
                                                                        var is_open = isOpen;
                                                                        is_open[key] = !is_open[key];
                                                                        this.setState({
                                                                            isOpen: is_open
                                                                        })
                                                                    }}
                                                                />
                                                                <h5>{comp.competency_name}</h5>
                                                            </Col>
                                                            <Col md={{ size: 2 }}>Assign</Col>
                                                            <Col md={{ size: 3 }}>RPL</Col>
                                                        </Row>
                                                        <Collapse isOpen={isOpen[key]} className="text-left">
                                                            <Card style={{ border: 'none' }}>
                                                                <CardBody style={{ border: 0, padding: 0 }}>
                                                                    {
                                                                        rplDataArr.sub_compt[comp.competency_id] && Object.keys(rplDataArr.sub_compt[comp.competency_id]).length &&  Object.keys(rplDataArr.sub_compt[comp.competency_id]).map((subComp, key2) => {
                                                                            let  keyWord =  rplDataArr.sub_compt[comp.competency_id][subComp].sub_comptkeyword;
                                                                            keyWord = keyWord.toLowerCase()
                                                                            let rpl = rplDataArr.sub_compt[comp.competency_id][subComp][keyWord+"_rpl"];
                                                                            let subComptName = rplDataArr.sub_compt[comp.competency_id][subComp].sub_comptname;
                                                                            let subComptId = rplDataArr.sub_compt[comp.competency_id][subComp].sub_comptid;
                                                                            console.log(rpl,":keyWord:",keyWord)
                                                                            return (
                                                                                    <Row key={key2}>
                                                                                        <Col md={{ size: 4 }}>{subComptName}</Col>
                                                                                        <Col md={{ size: 2 }}>
                                                                                            <FormGroup check>
                                                                                                <Input type="checkbox" className="assessmentList" value={subComptId}/>
                                                                                            </FormGroup>
                                                                                        </Col>
                                                                                        <Col md={{ size: 3 }}>
                                                                                            <FormGroup className="mt-0">
                                                                                                <Input type="select" name="report_year" id="report_year">
                                                                                                    <option value="0">Select RPL </option>
                                                                                                    <option value="10" selected={rpl == "10" ? "selected": null}>10</option>
                                                                                                    <option value="20" selected={rpl == "20" ? "selected": null}>20</option>
                                                                                                    <option value="30" selected={rpl == "30" ? "selected": null}>30</option>
                                                                                                    <option value="40" selected={rpl == "40" ? "selected": null}>40</option>
                                                                                                    <option value="50" selected={rpl == "50" ? "selected": null}>50</option>
                                                                                                    <option value="60" selected={rpl == "60" ? "selected": null}>60</option>
                                                                                                    <option value="70" selected={rpl == "70" ? "selected": null}>70</option>
                                                                                                    <option value="80" selected={rpl == "80" ? "selected": null}>80</option>
                                                                                                    <option value="90" selected={rpl == "90" ? "selected": null}>90</option>
                                                                                                    <option value="100" selected={rpl == "100" ? "selected": null}>100</option>
                                                                                                </Input>
                                                                                            </FormGroup>
                                                                                        </Col>
                                                                                    </Row>
                                                                                )
                                                                            })
                                                                    }
                                                                </CardBody>
                                                            </Card>
                                                        </Collapse>
                                                    </div>
                                                    
                                            )
                                            :null
                                    }
                                </Col>
                                <Col md={{ size: 12 }} className="text-right" style={{display:hierarchyComplete == true ? "block" : "none"}}>
                                    <Button size="sm" className="btn btn-dark" onClick={()=>{
                                            const {selAssessments,groupId,
                                                subGroupId,deptId,
                                                divisionId,sectionId,
                                                pcId,desigId,
                                                locationId,branchId,
                                                isLocationBranch
                                            }
                                            = this.state;
                                            this.setState({isDone:true});
                                            var that = this;
                                            if(groupId !=0 && subGroupId !=0 && deptId !=0) {
                                                this.props.assignSubGroupAssessment(groupId,subGroupId,deptId,divisionId,sectionId,pcId,desigId,selAssessments,(addAssessRes)=>{
                                                    that.setState({isDone:false});
                                                    console.log("addAssessRes:",addAssessRes)
                                                    if (addAssessRes.status == "success") {
                                                        window.location.reload();
                                                    }
                                                    else
                                                        alert("Assessments could not assigned, please try again");
                                                })
                                            }
                                            else {
                                                if(isLocationBranch == false) {
                                                    this.props.assignLocationAssessment(groupId,locationId,divisionId,sectionId,pcId,desigId,selAssessments,0,branchId,(addAssessRes)=>{
                                                        that.setState({isDone:false});
                                                        console.log("addAssessRes:",addAssessRes)
                                                        if (addAssessRes.status == "success") {
                                                            window.location.reload();
                                                        }
                                                        else
                                                            alert("Assessments could not assigned, please try again");
                                                    })
                                                }
                                                else {
                                                    this.props.assignLocationAssessment(groupId,locationId,divisionId,sectionId,pcId,desigId,selAssessments,1,branchId,(addAssessRes)=>{
                                                        console.log("addAssessRes:",addAssessRes)
                                                        that.setState({isDone:false});
                                                        console.log("addAssessRes:",addAssessRes)
                                                        if (addAssessRes.status == "success") {
                                                            window.location.reload();
                                                        }
                                                        else
                                                            alert("Assessments could not assigned, please try again");
                                                    })
                                                }
                                            }
                                            console.log("go for assign assessment:",selAssessments)
                                    }} disabled={this.state.selAssessments.length == 0 ? true:false }>Done
                                    {
                                    isDone == true ? 
                                        <Spinner color="primary" style={{
                                                                    width: '1.10rem',
                                                                    height: '1.10rem',
                                                                    marginLeft: '5px',
                                                                    float: 'right' 
                                        }} />
                                    :null
                                    }
                                    </Button>
                                </Col>
                            </Row>
                        </Container>
                        :
                        <Container>
                            <Spinner color="primary" style={{ width: '2rem', height: '2rem', margin: '30vh 0' }} />
                        </Container>
                }

            </div>


        );
    }
}

AdminManageAssessment.propTypes = {
    getMainGroupsData                   : PropTypes.func.isRequired,
    fetchSubGroupsOrLocationsOrSOG      : PropTypes.func.isRequired,
    fetchSubGroupsDepartments           : PropTypes.func.isRequired,
    getAssesstDivHierarchy              : PropTypes.func.isRequired,
    getAssesstSectionHierarchy          : PropTypes.func.isRequired,
    getAssesstPCHierarchy               : PropTypes.func.isRequired,
    getAssesstDesignationHierarchy      : PropTypes.func.isRequired,
    getAdditionalData                   : PropTypes.func.isRequired,
    addNewDesignation                   : PropTypes.func.isRequired,
    fetchLocationBranches               : PropTypes.func.isRequired,
    getAssesstDeptsHierarchy            : PropTypes.func.isRequired,
    getAssessRpl                        : PropTypes.func.isRequired,
    getLocationDivsHierarchy            : PropTypes.func.isRequired,
    getLocationSectionHierarchy         : PropTypes.func.isRequired,
    getLocationDesigHierarchy           : PropTypes.func.isRequired,
    getAssessLocationRpl                : PropTypes.func.isRequired,
    getAssessBranchDivsHierarchy        : PropTypes.func.isRequired,
    getAssessBranchSectionHierarchy     : PropTypes.func.isRequired,
    getAssessBranchPCHierarchy          : PropTypes.func.isRequired,
    getAssessBranchDesignationHierarchy : PropTypes.func.isRequired,
    assignSubGroupAssessment            : PropTypes.func.isRequired,
    assignLocationAssessment            : PropTypes.func.isRequired
}

const mapStatesToProps = state => ({
    mainGroupsData  : state.webRed.mainGroupsData,
    additionalData  : state.webRed.additionalData
})

export default connect(mapStatesToProps, { 
    getMainGroupsData,
    fetchSubGroupsOrLocationsOrSOG,
    fetchSubGroupsDepartments,
    getAssesstSectionHierarchy,
    getAssesstPCHierarchy,
    getAssesstDesignationHierarchy,
    getAdditionalData,
    addNewDesignation,
    getAssesstDivHierarchy,
    fetchLocationBranches,
    getAssesstDeptsHierarchy,
    getAssessRpl,
    getLocationDivsHierarchy,
    getLocationSectionHierarchy,
    getLocationPCHierarchy,
    getLocationDesigHierarchy,
    getAssessLocationRpl,
    getAssessBranchDivsHierarchy,
    getAssessBranchSectionHierarchy,
    getAssessBranchPCHierarchy,
    getAssessBranchDesignationHierarchy,
    assignSubGroupAssessment,
    assignLocationAssessment
})(AdminManageAssessment);