import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import Header from './../Header';
// import Footer from "./../Footer";

import AssessmentList from './AssessmentList';

import { getAllAssessments,getScoreCardData } from './../../../actions/webRedActions';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// import dataDummy from './../dataDummy';

import {
    Button,
    Container,
    Row,
    Col,
    Media,
    Form,
    FormGroup,
    Label,
    Input,
    Spinner
  } from "reactstrap";

import readXlsxFile from 'read-excel-file';
import ScoreCardsList from "./ScoreCardsList";

// import { BrowserRouter as Router, Route, Link } from "react-router-dom";
var user={}
class SocreCard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            enableAssessListing : false,
            subOrdinateId: "",
            selectedSubOrdinate:"select subordinate",
            subOrdinatesAll : JSON.parse(localStorage.getItem("userSubOrdinates")),
            subOrdinates : [],
            selectedYear:'2020',
            mainCompetencies : [],
            subCompetencies : [],
            subComptStatus : [],
            competencies : {},
            listingLoading : false
        };
    }

    componentDidMount() {

     
   
     
    }

    componentWillReceiveProps(nextProps, thisProps) {
     

   
    }

    handleChange = (event) => {
        event.preventDefault();
        localStorage.setItem('subOrdinateId',event.target.value)
        this.setState({
            enableAssessListing : true,
            listingLoading : true,
            subOrdinateId : event.target.value,
            selectedSubOrdinate:event.target.value
        }, (event) => {
      
            this.props.getScoreCardData('02-0345','2020');
        })
    }

  render() {

    const {subOrdinates, subOrdinateId, enableAssessListing, listingLoading} = this.state;

    return (
      <div className="SubordinateAssessmentsComponent" style={{background:'#f6fbff'}}>
        <Header />
            <Container style={{textAlign: 'left'}}>
                 </Container>
                 
            
              <ScoreCardsList scoreCard={this.state.scoreCard}/>
            
              
        
        {/* <Footer /> */}
      </div>
    );
  }
}

SocreCard.propTypes = {
    getAllAssessments: PropTypes.func.isRequired
}

const mapStatesToProps = state => ({
    fetchedAllAssessments : state.webRed.fetchedAllAssessments,
    getScoreCardDataSet : state.webRed.getScoreCardDataSet

})

export default connect( mapStatesToProps, { getAllAssessments,getScoreCardData })( SocreCard );