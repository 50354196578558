import React from 'react';
import Page from './Page';

const MultiPageV2 = ({ id,data,userName,date,month,year,time }) => {
    console.log('here is the data:',data);
    const getScore =(keyword)=>{
        if(keyword=='Rarely')
        {
            return 1
        }
        else if(keyword=='Sometimes'){
            return 2
        }
        else if(keyword=='Often'){
            return 3
        }
        else if(keyword=='Always'){
            return 4
        }
        else{
            return '--'
        }
    }
    console.log('in modal report data:',data);
    return (
        <Page id={id}><div className="container">
            
            <div className="row" id="printReport">
                <div className="col-md-12">
                    <h4>Assessment Report</h4>
                    <h5>{userName}</h5>
                    <label>{date} {month} {year}</label> <br />
                    <label>{time}</label>
                </div>
                <div className="col-md-12">
                        {
                         data && data.length > 0 && data.map((comp, key) =>
                        <React.Fragment>
                       
                        <table className='table'>
                            <th scope="col">{comp.compt_title} COMPETENCY</th>
                            <th scope="col">Score</th>
                            <th scope="col">Cut-off Scores</th>
                            <th scope="col">DME</th>
                            <th scope="col">{comp.percentage==null?"N/A":comp.percentage+"%"}</th>
                        </table>
                           
                        {
                            
                        typeof comp.subCompt !== 'undefined' && Array.isArray(comp.subCompt)==true && comp.subCompt.length > 0 ?
                        
                            comp.subCompt.map((subComp,keySUb)=>{
                                return(
                                    <table className='table'>
                                        <React.Fragment>
                                            <thead class="thead-light">
                                                <tr>
                                                    <td scope="col">{subComp.sub_compt_title}</td>
                                                    <td scope="col">{subComp.stats_data[subComp.sub_compt_keyword.toLowerCase()+"_score"]}</td>
                                                    <td scope="col">{subComp.stats_data[subComp.sub_compt_keyword.toLowerCase()+"_rpl"]}</td>
                                                    <td scope="col">{subComp.stats_data[subComp.sub_compt_keyword.toLowerCase()+"_gap"]}</td>
                                                    <td scope="col">{subComp.precentage=="null"?"N/A":Math.round(subComp.precentage)+"%"}</td>
                                                </tr>
                                            </thead>
                                            <br/>
                                            <tbody>   
                                            
                                                {
                                        
                                                typeof subComp.questions !== 'undefined' && Array.isArray(subComp.questions)==true && subComp.questions.length > 0 ?
                                                    
                                                    Object.keys(subComp.questions[0].result.questions.questions).map((question,qIndex)=>{
                                                        return(
                                                        <tr>
                                                        <th scope="row"> Question:{qIndex+1}{ <div dangerouslySetInnerHTML={{ __html: subComp.questions[0].result.questions.questions[question].question }} /> }</th>
                                                        
                                                            {
                                                            subComp.questions[0].result.answers[question].options.length > 0 ?
                                                            //console.log( subComp.questions[0].result.answers[question])
                                                            subComp.questions[0].result.answers[question].options.map((option,optIndx)=> {   
                                                                return(
                                                                    subComp.questions[0].result.questions.questions[question].user_answer.answer_id == option.answerid ?
                                                                    <td style={{background: "#7dfb4f"}}>
                                                                        
                                                                        { <div  dangerouslySetInnerHTML={{ __html: option.answer }} /> }
                                                                        {/* <input type="checkbox" checked={true}/> */}
                                            
                                                                    </td>
                                                                    // : option.iscorrect == 1 ?
                                                                    // <td style={{background: "yellow"}}>
                                                                    //     { <div  dangerouslySetInnerHTML={{ __html: option.answer }} /> }
                                                                        
                                                                    // </td>
                                                                    : <td >
                                                                    { <div  dangerouslySetInnerHTML={{ __html: option.answer }} /> }
                                                                    
                                                                </td>
                                                                )
                                                                // return(
                                                                //     (subComp.questions[0].result.questions.questions[question].user_answer.answer_id == option.answerid) && option.iscorrect == 1?
                                                                //     <td>
                                                                        
                                                                //         { <div  dangerouslySetInnerHTML={{ __html: option.answer }} /> }
                                                                //         <input type="checkbox" checked={true}/>
                                            
                                                                //     </td>
                                                                //     :
                                                                //     <td >
                                                                //         { <div  dangerouslySetInnerHTML={{ __html: option.answer }} /> }
                                                                        
                                                                //     </td>
                                                                // )
                                                            })
                                                            :null
                                                            }
                                                        
                                                        </tr>
                                                        )
                                                    })
                                            
                                                :null
                                                }
                                            
                                            </tbody>
                                            
                                    </React.Fragment>
                                  </table>    
                                )
                            })
                        :
                        typeof comp.subCompt !== 'undefined' && Object.keys(comp.subCompt).length > 0 && Object.entries(comp.subCompt).map((subComp,keySUb)=>{
                            
                            return(
                                <table className='table'>
                                    <React.Fragment>
                                        <thead class="thead-light">
                                            <tr>
                                                <td scope="col">{subComp[1].sub_compt_title}</td>
                                                <td scope="col">{subComp[1].stats_data[subComp[1].sub_compt_keyword.toLowerCase()+"_score"]=='null'?'N/A':subComp[1].stats_data[subComp[1].sub_compt_keyword.toLowerCase()+"_score"]}</td>
                                                <td scope="col">{subComp[1].stats_data[subComp[1].sub_compt_keyword.toLowerCase()+"_rpl"]=='null'?'N/A':subComp[1].stats_data[subComp[1].sub_compt_keyword.toLowerCase()+"_rpl"]}</td>
                                                <td scope="col">{subComp[1].stats_data[subComp[1].sub_compt_keyword.toLowerCase()+"_gap"]=='null'?'N/A':subComp[1].stats_data[subComp[1].sub_compt_keyword.toLowerCase()+"_gap"]}</td>
                                                <td scope="col">{subComp[1].precentage=="null"?"N/A":Math.round(subComp[1].precentage)+"%"}</td>
                                            </tr>
                                        </thead>
                                        <br/>
                                        <tbody>   
                                        
                                            {
                                    
                                            typeof subComp[1].questions !== 'undefined' && Array.isArray(subComp[1].questions)==true && subComp[1].questions.length > 0 ?
                                                
                                                Object.keys(subComp[1].questions[0].result.questions.questions).map((question,qIndex)=>{
                                                    return(
                                                    <tr>
                                                    <th scope="row"> Question:{qIndex+1}{ <div dangerouslySetInnerHTML={{ __html: subComp[1].questions[0].result.questions.questions[question].question }} /> }</th>
                                                    
                                                        {
                                                        subComp[1].questions[0].result.answers[question].options.length > 0 ?
                                                        //console.log( subComp.questions[0].result.answers[question])
                                                        subComp[1].questions[0].result.answers[question].options.map((option,optIndx)=> {   
                                                            return(
                                                                (subComp[1].questions[0].result.questions.questions[question].user_answer.answer_id == option.answerid) ?
                                                                <td style={{background: "#7dfb4f"}}>
                                                                    { <div  dangerouslySetInnerHTML={{ __html: option.answer }} /> }
                                                                    {/* <input type="checkbox" checked={true}/> */}
                                        
                                                                </td>
                                                                :
                                                                <td >
                                                                    { <div  dangerouslySetInnerHTML={{ __html: option.answer }} /> }
                                                                    
                                                                </td>
                                                            )
                                                        })
                                                        :null
                                                        }
                                                    
                                                    </tr>
                                                    )
                                                })
                                        
                                            :null
                                            }
                                        
                                        </tbody>
                                        
                                </React.Fragment>
                              </table>    
                            )   
                        })
                        
                        }
                        {
                        typeof comp.subCompt === 'undefined' ?
                        <tr> 
                            <td scope="col">-</td>
                            <td scope="col">-</td>
                            <td scope="col">-</td>
                            <td scope="col">-</td>
                            <td scope="col">-</td>
                        </tr>
                        :
                        
                        null
                        }
                      
                        </React.Fragment>
                        )
                        }
                       

                    
                </div>
                <div className="col-md-12 text-center">
                    <hr style={{ backgroundColor: "black" }} />
                    <label style={{ fontSize: 12 }}>Report For: </label> &nbsp;
                    <label className="font-weight-bold">PAGCOR Assessment {year}</label>
                </div>
                
            </div>
        </div></Page>)
};

export default MultiPageV2;