import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import Header from './../Header';

import axios from 'axios';

import "./../../../public/scss/FormComponent.scss";
// import LoginComponent from "./login/LoginComponent";

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import assessCalcMappingData from './assessCalcMappingData';

import $ from 'jquery'; 

// import store from './../store';
import PropTypes from 'prop-types';
import { sendLoginCredentials, getParetQuestions, submitFormNow,registerSupervisor } from './../../../actions/formActions';
import { submitAssessmentForm,submitPASForm } from './../../../actions/webRedActions';

import { connect } from 'react-redux';
import { getUserData, getEmployeeProfile, getBaseUrl,getYear,accessToken, generateToken } from './../../../actions/utility';
import * as Sentry from "@sentry/react";

import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  Button,
  Container,
  Row,
  Media,
  Col,
  DropdownItem,
  DropdownToggle,
  Dropdown,
  DropdownMenu,
  Spinner
} from "reactstrap";
import QuestionComponent from "./anwers/QuestionComponent";

import userData from './../userData';
// const userData123 = userData;

class FormComponent extends Component {
  constructor(props) {
    
    super(props);

    this.state = {
      colorScheme : "blue",
      darkMode : "Light",
      colorMenuOpen : false,
      modeMenuOpen : false,
      showLoginPopup: false,
      formId: '',
      pageTitle : 'Form',
      userDropdownOpen : false,
      userName : '',
      checkLogoutDone : false,
      isSubmitted : false,
      formBanner : '',
      formLogo : '',
      formName : '',
      formDetail : '',
      formSuccessMessage: '',
      allParentQuestions : [],
      isFormIntegrated : false,
      redirectToAssessments : null,
      questionsLoading : false,
      computingResultLoading : false
    };

  }

  componentWillReceiveProps(nextProps, thisProps) {

    if(this.props.loginResponse != nextProps.loginResponse) {

      if(nextProps.loginResponse != 'error' && nextProps.loginResponse.data.message == "Successfully Registered."){
          var userData = nextProps.loginResponse.data.userData;
          localStorage.setItem('userData', '');
          localStorage.setItem('userData', JSON.stringify(userData));
          
          this.setState({
            userName : userData.user_name,
            questionsLoading : true
          },
          () => {
            this.props.getParetQuestions(this.props.match.params.formId, userData.id, userData.engineid, userData.user_type); // calls parent questions
          })

      }
    }

    if(this.props.parentQuestions != nextProps.parentQuestions) {
      
      try{

        var banner = nextProps.parentQuestions.data.testdata.form_banner;
        var logo = nextProps.parentQuestions.data.testdata.form_logo;
        var formName = nextProps.parentQuestions.data.testdata.testname;
        var formDetail = nextProps.parentQuestions.data.testdata.testdetail;
        var formSuccessMessage = nextProps.parentQuestions.data.testdata.success_message;
        
        if(!banner || banner == null && banner == ''){
          banner = '';
        }
        if(!logo || logo == null && logo == ''){
          logo = '';
        }
        if(!formName && formName == null && formName == ''){
          formName = '';
        }
        if(!formDetail && formDetail == null && formDetail == ''){
          formDetail = '';
        }
        if(!formSuccessMessage || formSuccessMessage == null && formSuccessMessage == ''){
          formSuccessMessage = 'Assessment has been submitted.';
        }
  
        this.setState({
          allParentQuestions : nextProps.parentQuestions.data.questions,
          formBanner : banner,
          formLogo : logo,
          formName : formName,
          formDetail : formDetail,
          formSuccessMessage : formSuccessMessage,
          questionsLoading : false
        })

      }
      catch{

        console.log('error in data loading')

      }

    }

    // if(this.props.submittedResponse != nextProps.submittedResponse){ // if form submitted successfully.
    //   this.setState({
    //     checkLogoutDone : true
    //   },
    //   () => {

    //     confirmAlert({
    //       title: 'Thank you.',
    //       message: "This assessment has been submitted.",
    //       closeOnEscape: false,
    //       closeOnClickOutside: false,
    //       buttons: [
    //           {
    //             label: 'View more assessments',
    //             onClick: () => {
    //                 // localStorage.clear();
    //                 // window.location.reload();
    //                 // console.log('Okay');
    //                 this.setState({
    //                     redirectToAssessments : "/assess-myself"
    //                 })
    //             }
    //           }
    //       ]
    //     })

    //   })
    // }

    if(this.props.doneWithAssessmentForm != nextProps.doneWithAssessmentForm) {

      var redirectHere = "/assess-myself";
      if(nextProps.doneWithAssessmentForm.isSup == 1) { // this means this is self rating submission
        var subOrdinateIdentity = JSON.parse(localStorage.getItem('subOrdinateIdentity'));
        redirectHere = "/assess-subordinate?empid=" + subOrdinateIdentity.ID_NUMBER;
      }

      this.setState({
        checkLogoutDone : true
      },
      () => {

        confirmAlert({
          title: 'Thank you.',
          message: "This assessment has been submitted.",
          closeOnEscape: false,
          closeOnClickOutside: false,
          buttons: [
              {
                label: 'View more assessments',
                onClick: () => {
                    // localStorage.clear();
                    // window.location.reload();
                    // console.log('Okay');
                    this.setState({
                        redirectToAssessments : redirectHere
                    })
                }
              }
          ]
        })

      })
    }

  }

  componentWillMount() {
    var formId = this.props.match.params.formId;
    console.log(formId)
    this.setState({
      formId : atob(formId)
    }, () => {
    //   console.log(userData123)
    //   var urlParams = new URLSearchParams(window.location.search);

      const empData = getEmployeeProfile();

      
      var isSelfRating = localStorage.getItem('isSelfRating');
      var isSelf = true;
      var subOrdinateIdentity = '';
      var supervisorData = '';
      if(isSelfRating && isSelfRating != undefined && isSelfRating != ''){
        if(isSelfRating == 0 || isSelfRating == '0'){
          isSelf = false;
          subOrdinateIdentity = JSON.parse(localStorage.getItem('subOrdinateIdentity'));
         //here i am registering the supervisor against this form on forms
          try {
            
            supervisorData = JSON.parse(localStorage.getItem('pagCorUserProfile'));
            
            this.props.registerSupervisor(this.state.formId,supervisorData.email,supervisorData.FIRST_NAME,supervisorData.ID_NUMBER,function(result) {
                
              if(result !=0) {
                  localStorage.setItem('supervisorFormsData',JSON.stringify(result.userData))
              }  
            });
          }
          catch(err) {

          }
        }
      }

      var nested = true;
      var email = isSelf ? empData.email : subOrdinateIdentity.email; // emp email
      var phone = isSelf ? empData.ID_NUMBER : subOrdinateIdentity.ID_NUMBER;
      var name = isSelf ? empData.NAME : subOrdinateIdentity.NAME;
      
      if(nested && email && phone && name) { // check if form type is nested or integrated with in iFrame
        console.log(this.state.formId, email, name, phone);
        this.setState({
          isFormIntegrated : true
        }, () => {
          this.props.sendLoginCredentials(this.state.formId, email, name, phone);
        })
      }
      else{
        
        if(localStorage.getItem('userData') && localStorage.getItem('userData') != ''){

          var userData = JSON.parse(localStorage.getItem('userData'));
          
          this.setState({
            userName : userData.user_name
          },
          () => {
            // this.props.getParetQuestions(this.props.match.params.formId, userData.id, userData.engineid, userData.user_type); // calls parent questions
            this.props.sendLoginCredentials(this.state.formId, userData.user_email, userData.user_name, userData.contact);
          })
          
        }
        else{
          this.setState({
            showLoginPopup : this.state.formId + '/' + Math.random()
          })
        }

      }

    })
  }

  
  componentDidMount() {
    //console.log('params:',this.props.match.params);
    // window.onbeforeunload = function(){
    //   if(!this.state.checkLogoutDone){
    //     return false;
    //   }
    // }.bind(this);

    document.title = this.state.pageTitle;

    // var formId = this.props.match.params.formId;

    // this.setState({
    //   formId : atob(formId)
    // }, () => {


      // var urlParams = new URLSearchParams(window.location.search);
      // var nested = urlParams.get('nested');
      // var email = urlParams.get('email');
      // var phone = urlParams.get('phone');
      // var name = urlParams.get('name');
      
      // if(nested && email && phone && name) { // check if form type is nested or integrated with in iFrame
      //   console.log(this.state.formId, email, name, phone);
      //   this.setState({
      //     isFormIntegrated : true
      //   }, () => {
      //     this.props.sendLoginCredentials(this.state.formId, email, name, phone);

      //     if(this.state.formId == "8693" || this.state.formId == "8692"){ // if it's integrated form for talentMap dashboard
      //       this.setState({
      //         darkMode : 'Dark',
      //         checkLogoutDone : true
      //       })
      //     }

      //   })
      // }
      // else{
        
      //   if(localStorage.getItem('userData') && localStorage.getItem('userData') != ''){

      //     var userData = JSON.parse(localStorage.getItem('userData'));
          
      //     this.setState({
      //       userName : userData.user_name
      //     },
      //     () => {
      //       // this.props.getParetQuestions(this.props.match.params.formId, userData.id, userData.engineid, userData.user_type); // calls parent questions
      //       this.props.sendLoginCredentials(this.state.formId, userData.user_email, userData.user_name, userData.contact);
      //     })
          
      //   }
      //   else{
      //     this.setState({
      //       showLoginPopup : this.state.formId + '/' + Math.random()
      //     })
      //   }

      // }
        
    // })
    
    
  }

  componentWillUnmount() {
    
  }

  toggleUserDrop = () => {
    // clear userData from storage and reload the page
    var logOutMessage = "";
    if(!this.state.isSubmitted){
      logOutMessage = "you have not submitted form yet.";
    }
    confirmAlert({
        title: 'Are you sure ?',
        message: 'You want to logout. ' + logOutMessage,
        closeOnEscape: false,
        closeOnClickOutside: false,
        buttons: [
            {
                label: 'YES',
                onClick: () => {
                  this.setState({
                    checkLogoutDone : true
                  },
                  () => {
                    // localStorage.setItem('userData', '');
                    localStorage.clear();
                    window.location.href = "/#/login";
                    window.location.reload();
                  })
                }
            },
            {
                label: 'NO',
                onClick: () => {
                    console.log('no.');
                }
            }
        ]
    })
  }

  DoneForm = () => {    

    if(this.validateAllFields()) { // if all fields are checked or selected
        confirmAlert({
            title: 'Quick confirmation',
            message: 'Ready to submit assessment?',
            closeOnEscape: false,
            closeOnClickOutside: false,
            buttons: [
                {
                    label: 'YES',
                    onClick: () => {
                      if(getYear() == "2020") {
                        this.setState({
                          isSubmitted : true
                        }, () => {
      
                          var userData = getUserData();
                          this.props.submitFormNow(userData.form_id, userData.id); // calls submit form action
                          
                          var sub_comptkeyword = localStorage.getItem('sub_comptkeyword');
                          var comp_keyword = "C1"; // keyword dynamic
                          if(sub_comptkeyword && sub_comptkeyword != undefined && sub_comptkeyword != ''){
                            comp_keyword = sub_comptkeyword;
                          }

                          const empData = getEmployeeProfile();

                          var isSelfRating = localStorage.getItem('isSelfRating');
                          var isSelf = true;
                          var subOrdinateIdentity = '';
                          if(isSelfRating && isSelfRating != undefined && isSelfRating != ''){
                            if(isSelfRating == 0 || isSelfRating == '0'){
                              isSelf = false;
                              subOrdinateIdentity = JSON.parse(localStorage.getItem('subOrdinateIdentity'));
                            }
                          }
                          
                          var bodyFormData = new FormData();
                          bodyFormData.set('id_number', isSelf ? empData.ID_NUMBER : subOrdinateIdentity.ID_NUMBER);
                          bodyFormData.set('branch_id', isSelf ? empData.branch_id : subOrdinateIdentity.branch_id);
                          bodyFormData.set('subcompt_keyword', comp_keyword);

                          // var this2 = this;
                          const token = generateToken();
                          axios.post( getBaseUrl() + 'api/competencies/based/rpl', bodyFormData, {
                            headers: {
                              'Content-Type': 'multipart/form-data',
                              'authorizedToken': token,
			'appidadmin':localStorage.getItem('app_id_session') // Add your custom token here
                            }
                          })
                          .then((res) => {
                            var result = res.data.result;
                            var rpl_key = Object.keys(result);
                            var rpl_value = parseInt(result[rpl_key]);

                            this.calculateFinalScore(comp_keyword, rpl_value); // final score for assessment

                          })
                          .catch((error) => {
                            console.log(error);
                          });

                        })
                      }
                      else {
                        this.setState({computingResultLoading : true})
                        console.log('go for 2021 result');
                        var userData = getUserData();
                        const empData = getEmployeeProfile();
                        this.props.submitFormNow(userData.form_id, userData.id); // calls submit form action
                        var sub_comptkeyword = localStorage.getItem('sub_comptkeyword');
                        var rpl = localStorage.getItem('sub_comptrpl');
                        var comp_keyword = ""; // keyword dynamic
                        if(sub_comptkeyword && sub_comptkeyword != undefined && sub_comptkeyword != ''){
                          comp_keyword = sub_comptkeyword;
                        }
                        var bodyFormData = new FormData();
                        var nuUserId = userData.id;
                        var usrAns = localStorage.userAns;
                        bodyFormData.set( 'employee_id',    empData.ID_NUMBER);
                        bodyFormData.set( 'assess_id',    this.state.formId);
                        bodyFormData.set( 'usrAns',    usrAns);
                        bodyFormData.set( 'year',    getYear());
                        bodyFormData.set( 'nu_userid',    nuUserId);
                        bodyFormData.set( 'comp_keyword',    comp_keyword);
                        bodyFormData.set( 'rpl',    rpl);
                        bodyFormData.set( 'acess_token',    accessToken());
                        const token = generateToken();
                        
                        if (empData.ID_NUMBER == "00-4032") {
                            let assessData = {
                                "employee_id" : empData.ID_NUMBER,
                                "assess_id" : this.state.formId,
                                "usrAns" : JSON.parse(usrAns),
                                "year" : getYear(),
                                "nu_userid" : nuUserId,
                                "comp_keyword" : comp_keyword,
                                "rpl" : rpl,
                            }
                            Sentry.setUser(assessData);
                            Sentry.captureMessage("Result submited.!")
                        }
                        axios.post( getBaseUrl() + 'api/add/emp/assess/result', bodyFormData, {
                          headers: {
                            'Content-Type': 'multipart/form-data',
                            'authorizedToken': token,
			'appidadmin':localStorage.getItem('app_id_session') // Add your custom token here
                          }
                        })
                        .then((res) => {
                          this.setState({computingResultLoading : false})
                          if( res.data.status == 'error') {
                            confirmAlert({
                              title: 'Quick confirmation',
                              message: res.data.message,
                              closeOnEscape: false,
                              closeOnClickOutside: false,
                              buttons: [{
                                label: 'OK',
                                }
                              ]
                            })
                          }
                          else {
                           this.props.submitPASForm();
                          }

                        })
                        .catch((error) => {
                          console.log(error);
                        });

                      }
                    }
                },
                {
                    label: 'NO',
                    onClick: () => {
                        console.log('not submitted.');
                    }
                }
            ]
        }) 
    }

  }

  calculateFinalScore = (comp_keyword, rpl_value) => {
    // console.log(assessCalcMappingData);

    // var A = assessCalcMappingData[comp_keyword].A,
    // A_total = 0;
    // var B = assessCalcMappingData[comp_keyword].B,
    // B_total = 0;
    // var C = assessCalcMappingData[comp_keyword].C,
    // C_total = 0;
    // var D = assessCalcMappingData[comp_keyword].D,
    // D_total = 0;

    // $(".QuestionComponent").each(function(index){
    //   var inputField = $(this).find('input[type=radio]:checked');

    //   var inp_val = parseInt(inputField.val());

    //   if(inp_val != 5){
    //     if(index >= 0 && index < A) {
    //       A_total += inp_val;
    //     } else if(index >= A && index < A+B){
    //       B_total += inp_val;
    //     } else if(index >= A+B && index < A+B+C){
    //       C_total += inp_val;
    //     } else if(index >= A+B+C && index < A+B+C+D){
    //       D_total += inp_val;
    //     }
    //   }

    // })
    
    // var A_avg = A_total / A;
    // var B_avg = B_total / B;
    // var C_avg = C_total / C;
    // var D_avg = D_total / D;
    // var maxAvgScore = Math.max(A_avg, B_avg, C_avg, D_avg);
    
    // [A_avg, B_avg, C_avg, D_avg].map((val, key) => {
    //   if(val == maxAvgScore){

    //     var finalScore = key + 1;

        var finalScore = parseInt( $(".QuestionComponent").find('input[type=radio]:checked').val() );
        
        // console.log(finalScore);
        const empData = getEmployeeProfile();
        
        var isSelfRating = localStorage.getItem('isSelfRating');
        var isSelf = true;
        var subOrdinateIdentity = '';
        if(isSelfRating && isSelfRating != undefined && isSelfRating != ''){
          if(isSelfRating == 0 || isSelfRating == '0'){
            isSelf = false;
            subOrdinateIdentity = JSON.parse(localStorage.getItem('subOrdinateIdentity'));
          }
        }
        
        var data = {
          'branch_id': !isSelf ? subOrdinateIdentity.branch_id : empData.branch_id,
          'id_number' : !isSelf ? subOrdinateIdentity.ID_NUMBER : empData.ID_NUMBER,
          'last_name' : !isSelf ? subOrdinateIdentity.LAST_NAME : empData.LAST_NAME,
          'first_name' : !isSelf ? subOrdinateIdentity.FIRST_NAME : empData.FIRST_NAME,
          'mi' : !isSelf ? subOrdinateIdentity.MI : empData.MI,
          'name' : !isSelf ? subOrdinateIdentity.NAME : empData.NAME,
          'designation' : !isSelf ? subOrdinateIdentity.DESIGNATION : empData.DESIGNATION,
          'section' : !isSelf ? subOrdinateIdentity.SECTION : empData.SECTION,
          'division' : !isSelf ? subOrdinateIdentity.DIVISION : empData.DIVISION,
          'pc' : !isSelf ? subOrdinateIdentity.PC : empData.PC,
          'branch' : !isSelf ? subOrdinateIdentity.BRANCH : empData.BRANCH,
          'dept' : !isSelf ? subOrdinateIdentity.DEPT : empData.DEPT,
          'subcompt_keyword' : comp_keyword,
          'subcompt_rpl' : rpl_value,
          'is_sup' : !isSelf ? 1 : 0, // it's self rating
          'rating_val' : finalScore
        };

        // console.log(data)

        this.props.submitAssessmentForm(data); //sends assessment data

    //   }
    // })

  }

  validateAllFields = () => {
    
    var flagID = [];
    $(".QuestionComponent").each(function(index){
        var inputField = $(this).find('input[type=radio]');
        var isChecked = inputField.is(':checked');
        
        if(!isChecked) {
            flagID.push(inputField.attr('id'));
        }
    })
  
    $('.QuestionComponent').removeClass('errorRequired');
    if(flagID.length){
        flagID.map(id => {
            $('#'+id).closest('.QuestionComponent').addClass('errorRequired');
        })
        return false;
    }
    else { 
        return true;
    }

  }


  render() {

    var isSelfRating = localStorage.getItem('isSelfRating');
    var isSelf = true;
    var subOrdinateIdentity = '';
    if(isSelfRating && isSelfRating != undefined && isSelfRating != ''){
      if(isSelfRating == 0 || isSelfRating == '0'){
        isSelf = false;
        subOrdinateIdentity = JSON.parse(localStorage.getItem('subOrdinateIdentity'));
      }
    }
    

    if (this.state.redirectToAssessments) {
        return <Redirect to={this.state.redirectToAssessments} />
    }
    return (
      <div className={"MainFormComponent " + this.state.colorScheme + " " + this.state.darkMode}>

        <Header />
        {/* <LoginComponent showPopup={this.state.showLoginPopup} /> */}

        {
          !isSelf ?
            <div className="bannerImage" style={{ 
              background: '#fef67f',
              color: '#000',
              padding: '10px 0'
             }}>
              <Container style={{textAlign: 'left'}}>
                  <Row>
                    <Col md={{ size: 8, offset: 1 }}>
                      You are assessing <strong>{subOrdinateIdentity.NAME}</strong>        
                    </Col>
                  </Row>
              </Container>
            </div>
          : null
        }

        <div className="bannerImage" style={{ backgroundImage: this.state.formBanner !== "" ? "url("+ this.state.formBanner +")" : null, color: '#fff' }}>
          <Container style={{textAlign: 'left'}}>
            {
              this.state.userName != '' ?
              
                <Row style={{padding: '3vh 0', display: this.state.isFormIntegrated ? 'none' : 'inherit'}}>
                  <Col style={{textAlign: 'right'}}>

                    <Dropdown style={{display: 'inline-block', marginRight: '15px'}} size="sm" isOpen={this.state.modeMenuOpen} toggle={e => this.setState({modeMenuOpen: !this.state.modeMenuOpen})}>
                      <DropdownToggle color="info" caret>
                        {this.state.darkMode}
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem onClick={e => this.setState({ darkMode: 'Light' })}>Light Mode</DropdownItem>
                        <DropdownItem onClick={e => this.setState({ darkMode: 'Dark' })}>Dark Mode</DropdownItem>
                      </DropdownMenu>
                    </Dropdown>

                    <Dropdown style={{display: 'inline-block', marginRight: '15px'}} size="sm" isOpen={this.state.colorMenuOpen} toggle={e => this.setState({colorMenuOpen: !this.state.colorMenuOpen})}>
                      <DropdownToggle color="info" caret>
                        {this.state.colorScheme}
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem onClick={e => this.setState({ colorScheme: 'blue' })}>Blue</DropdownItem>
                        <DropdownItem onClick={e => this.setState({ colorScheme: 'green' })}>Green</DropdownItem>
                        {/* <DropdownItem onClick={e => this.setState({ colorScheme: 'yellow' })}>Yellow</DropdownItem> */}
                      </DropdownMenu>
                    </Dropdown>

                    <Button style={{marginRight: '12px'}} disabled size="sm" color="secondary">{this.state.userName}</Button>
                    <Button color="warning" size="sm" onClick={this.toggleUserDrop}>Logout</Button>

                  </Col>
                </Row>
                
                : null
            }
              <Row>
                <Col md={{ size: 8, offset: 1 }}>
                  {
                    this.state.formLogo != '' ?
                      <NavbarBrand style={{margin: '0'}} >
                        <img src={this.state.formLogo} alt="" width="160" />
                      </NavbarBrand> : null
                  }
                  {
                    this.state.formName != '' ?
                      <h3 style={{margin: '4vh 0 2.5vh 0'}}>{this.state.formName}</h3> : null
                  }
                  {
                    this.state.formDetail != '' ?
                      <p style={{marginBottom: '5vh'}} dangerouslySetInnerHTML={{__html: this.state.formDetail}}></p> : null
                  }
                  
                </Col>
              </Row>
          </Container>
        </div>

        



        <div style={{padding: '2vh 0'}}>
          <Container style={{textAlign: 'left', fontSize: '14px'}}>
            <Row>
              <Col md={{ size: 10, offset: 1 }}>
                {
                  this.state.allParentQuestions.length > -1 && this.state.allParentQuestions.map((question, key) => {
                    // console.log(question);
                    return (
                      <QuestionComponent key={key} key2={key} question={question} />
                    )
                  })
                }

              </Col>
              {
                this.state.questionsLoading ?
                  <Col md={{size: 12}} style={{textAlign: 'center', padding: '30vh 0'}}>
                    <Spinner color="primary" />
                  </Col>
                :
                  this.state.userName != '' ?
                    <Col md={{size: 10, offset: 1}} style={{textAlign: 'right', padding: '3vh 30px'}}>
                      <Button color="primary" size="lg" onClick={()=>{
                         const empData = getEmployeeProfile();
                         if(empData.ID_NUMBER == "11-1111") {
                          alert("You are only allowed to view the assessment, you can't submit assessment.")
                          return false;
                         }
                         else { 
                            this.DoneForm()
                         }
                        }}>
                        {
                          this.state.computingResultLoading == true ? 
                            <Spinner color="success"  style={{paddingLeft:20}}/>
                          :null
                        }
                            Submit
                        </Button>
                    </Col>
                  : null
              }
            </Row>
          </Container>
        </div>

      </div>
    );
  }
}

FormComponent.propTypes = {
  sendLoginCredentials: PropTypes.func.isRequired,
  getParetQuestions: PropTypes.func.isRequired,
  submitFormNow: PropTypes.func.isRequired,
  submitAssessmentForm: PropTypes.func.isRequired,
  registerSupervisor: PropTypes.func.isRequired,
  submitPASForm : PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  loginResponse: state.formRed.loginResponse,
  parentQuestions: state.formRed.parentQuestions,
  submittedResponse: state.formRed.submittedResponse,
  doneWithAssessmentForm: state.webRed.doneWithAssessmentForm
})

export default connect(mapStateToProps, { sendLoginCredentials, getParetQuestions, submitFormNow, submitAssessmentForm,registerSupervisor,submitPASForm })(FormComponent);
