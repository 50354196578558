import React, { Component, Fragment } from "react";
import { Redirect } from "react-router-dom";
// import Header from '../../Header';
// import Footer from "./../Footer";

import { fetchCompleteStats,getAdditionalData } from '../../../../../src/actions/webRedActions';

// import { getEmployeeProfile } from '../../../../actions/utility';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import axios from 'axios';
import { getBaseUrl,accessToken,getYear } from './../../../../actions/utility';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css


// import userData from './../userData';

import {
    Button,
    Container,
    Row,
    Col,
    Media,
    Form,
    FormGroup,
    Label,
    Input,
    Collapse,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Spinner,
    Progress
  } from "reactstrap";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';

// import readXlsxFile from 'read-excel-file';
import EmployeeProfile from "./EmployeeProfile";
import EmployeeSearchForm from "./EmployeeSearchForm";
import AddEmployeeProfile from "./AddEmployeeProfile";
import AdminHeader from './../AdminHeader';
import SideMenu from'./../sidMenu/SideMenu';
import { getAdminUserData } from "../../../../../src/actions/utility";
// import { BrowserRouter as Router, Route, Link } from "react-router-dom";
// import { ProSidebar, SidebarHeader, SidebarFooter, SidebarContent, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faBars,faHome,faUsers } from '@fortawesome/free-solid-svg-icons';
import 'react-pro-sidebar/dist/css/styles.css';

class AssessmentStatistics extends Component {

    constructor(props) {
        super(props);
        this.state = {
            headerTitle : "Assessment Statistics",
            competencies : [],
            completedata : '',
            isOpen : [true, true, true, true],
            
            divisionList : [],
            sectionList : [],
            branchList : [],
            deptList : [],
            pcList : [],

            DIVISION : '',
            SECTION : '',
            BRANCH : '',
            DEPT : '',
            PC : '',
            branchId:0,
            division:'',
            section:'',
            divisionLoading:false,
            sectionLoading:false,
            isLoaded:false
        };
    }

    componentWillMount() {
        // get all stats data
        this.props.fetchCompleteStats('');

       
        if(getYear() > '2020') {
            this.props.getAdditionalData()
            this.getAllBranchesData();
        }
        else {
            //this.getAllDistinctData('DIVISION');
            //this.getAllDistinctData('SECTION');
            // this.getAllDistinctData('BRANCH');
            this.getAllBranchesData();
            // this.getAllDistinctData('DEPT');
            this.getAllDistinctData('PC');
        }

        

    }

    getAllBranchesData = () => {
        axios.get( getBaseUrl() + 'api/branches/get?access_token='+accessToken()+'&year='+getYear())
        .then((res) => {
            console.log(res);
            if(res.data.result) {
                this.setState({
                    branchList : res.data.result
                }, () => {
                    console.log(this.state.branchList)
                    this.setState({
                        BRANCH : this.state.branchList[0].branch_id
                    },()=>{
                        if(getYear() > '2020') {
                            this.getPASDivisions(this.state.BRANCH);
                        }
                    })
                })
            }
        })
        .catch((error) => {
            console.log(error);
        });
    }
    getPASSections = (branchId,division) => {
        this.setState({sectionLoading:true});
        this.state.division = division;
        var bodyFormData = new FormData();
        
        var year = getYear();

        bodyFormData.set('branch_id', branchId);
        bodyFormData.set('division', division);
        bodyFormData.set('acess_token', accessToken());
        bodyFormData.set('year',year);

        axios.post( getBaseUrl() + 'api/admin/branch/sections', bodyFormData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
        .then((res)=>{
           
            if(res.status == 200 && res.data.result) {
                console.log("this is the res Faheem:",res.data.result);
                this.setState({
                    sectionList : res.data.result,
                    sectionLoading : false
                },()=>{
                    this.setState({
                        SECTION : this.state.sectionList !='null' && this.state.sectionList.length > 0 ? year < '2021' ? this.state.sectionList[0].SECTION :this.state.sectionList[0].section_id: ''
                    })
                })
            }
        })
        .catch((error) => {
            console.log(error);
        });
    }
    getSections = (branchId,division) => {
        this.setState({sectionLoading:true});
        this.state.division = division;
        var bodyFormData = new FormData();
        
        var year = getYear();

        bodyFormData.set('branch_id', branchId);
        bodyFormData.set('division', division);
        bodyFormData.set('acess_token', accessToken());
        bodyFormData.set('year',year);

        axios.post( getBaseUrl() + 'api/admin/employee/column/data', bodyFormData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
        .then((res)=>{
           
            if(res.status == 200 && res.data.result) {
                console.log("this is the res Faheem:",res.data.result);
                this.setState({
                    sectionList : res.data.result,
                    sectionLoading : false
                },()=>{
                    this.setState({
                        SECTION : this.state.sectionList !='null' && this.state.sectionList.length > 0 ? year < '2021' ? this.state.sectionList[0].SECTION :this.state.sectionList[0].section_id: ''
                    })
                })
            }
        })
        .catch((error) => {
            console.log(error);
        });
    }
    getDivisions = (branchId) => {
        var year = getYear();
        this.setState({divisionLoading:true});
        this.state.branchId = branchId;
        var bodyFormData = new FormData();
        bodyFormData.set('branch_id', branchId);
        bodyFormData.set('acess_token', accessToken());
        bodyFormData.set('year',year);

        axios.post( getBaseUrl() + 'api/admin/employee/column/data', bodyFormData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
        .then((res)=>{
           
            if(res.status == 200 && res.data.result) {
                console.log("this is the res Faheem:",res.data.result);
                this.setState({
                    divisionList : res.data.result,
                    divisionLoading :  false
                },()=>{
                    this.setState({
                        DIVISION : this.state.divisionList !='null' && this.state.divisionList.length > 0 ? year < '2021' ? this.state.divisionList[0].DIVISION : this.state.divisionList[0].division_id: ''
                    })
                })
            }
        })
        .catch((error) => {
            console.log(error);
        });
    }
    getPASDivisions = (branchId) => {
        var year = getYear();
        this.setState({divisionLoading:true});
        this.state.branchId = branchId;
        var bodyFormData = new FormData();
        bodyFormData.set('branch_id', branchId);
        bodyFormData.set('acess_token', accessToken());
        bodyFormData.set('year',year);

        axios.post( getBaseUrl() + 'api/admin/branch/divisions', bodyFormData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
        .then((res)=>{
           
            if(res.status == 200 && res.data.result) {
                console.log("this is the res Faheem:",res.data.result);
                this.setState({
                    divisionList : res.data.result,
                    divisionLoading :  false
                },()=>{
                    this.setState({
                        DIVISION : this.state.divisionList !='null' && this.state.divisionList.length > 0 ? year < '2021' ? this.state.divisionList[0].DIVISION : this.state.divisionList[0].division_id: ''
                    },()=>{
                        if(this.state.DIVISION !='')
                            this.getPASSections(branchId,this.state.DIVISION)
                    })
                })
            }
        })
        .catch((error) => {
            console.log(error);
        });
    }
    

    componentWillReceiveProps(nextProps, thisProps) {
        console.log('Faheem in function componentWillReceiveProps');
        if(this.props.getAllAssessmentStatistics != nextProps.getAllAssessmentStatistics) {
            // console.log();
            try {
                var completeData = nextProps.getAllAssessmentStatistics.data;
                if(completeData) {
                    this.setState({
                        completedata : completeData,
                        competencies : Object.keys(completeData[getYear()]).map((comp, key) => {
                            return ({
                                name : comp,
                                subcompetencies : Object.keys(completeData[getYear()][comp]).map((subcomp, key2) => {
                                    return ({
                                        name : subcomp
                                    })
                                })
                            })
                        })
                    }, () => {
                        console.log(this.state.completedata)
                    })
                }
            } catch(e) {

                confirmAlert({
                    title: 'Error',
                    message: "Required stats data could not be found with this selected filter.",
                    closeOnEscape: false,
                    closeOnClickOutside: false,
                    buttons: [
                        {
                          label: 'OK',
                          onClick: () => {
                              this.setState({
                                  isLoaded:true
                              })
                          }
                        }
                    ]
                })
            }
            
        }
        if(this.props.additionalData != nextProps.additionalData) {
            console.log("additionalData:",nextProps.additionalData.data);
            this.setState( {
                // branchList : nextProps.additionalData.data.branches,
                // divisionList : nextProps.additionalData.data.divisions,
                pcList : nextProps.additionalData.data.payClass
                // sectionList : nextProps.additionalData.data.sections,
                // deptList : nextProps.additionalData.data.departments,

            },()=>{
                this.setState({
                    PC : this.state.pcList[0].pc_id
                });
            })
        }
    }

    updateValue = (e) => {
        console.log( "This is the value:",e.target.value)
        this.setState({
            [e.target.name] : e.target.value
        })
        if(e.target.name == "SECTION") {
            console.log('section has changed');
        }
        if(e.target.name == "DIVISION") {
            console.log('division has changed');
            if(getYear() < '2021')
                this.getSections(this.state.branchId,e.target.value);
            else
                this.getPASSections(this.state.branchId,e.target.value);
        }
    }

    updateValueBranch = (e) => {
        let branchId = this.state.branchList[e.target.selectedIndex].branch_id;
        if(getYear() < '2021')
            this.getDivisions(branchId);
        else
            this.getPASDivisions(branchId);

        console.log('branch name:',branchId)
        // this.setState({
        //     [e.target.name] : this.state.branchList[e.target.selectedIndex].branch_id
        // })
    }

    // fetch all distinct values for any column
    getAllDistinctData = (col) => {
        var bodyFormData = new FormData();
        bodyFormData.set('col', col);
        bodyFormData.set('acess_token', accessToken());

        axios.post( getBaseUrl() + 'api/admin/employee/column/data', bodyFormData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
        .then((res) => {
            console.log(res);
            if(res.data.result) {

                if(col == 'DIVISION') {
                    this.setState({
                        divisionList : res.data.result
                    }, () => {
                        console.log(this.state.divisionList)
                        this.setState({
                            DIVISION : this.state.divisionList[0].DIVISION
                        })
                    })
                }
                else if(col == 'SECTION') {
                    this.setState({
                        sectionList : res.data.result
                    }, () => {
                        console.log(this.state.sectionList)
                        this.setState({
                            SECTION : this.state.sectionList[0].SECTION
                        })
                    })
                }
                // else if(col == 'BRANCH') {
                //     this.setState({
                //         branchList : res.data.result
                //     }, () => {
                //         console.log(this.state.branchList)
                //         this.setState({
                //             BRANCH : this.state.branchList[0].BRANCH
                //         })
                //     })
                // }
                else if(col == 'DEPT') {
                    this.setState({
                        deptList : res.data.result
                    }, () => {
                        console.log(this.state.deptList)
                        this.setState({
                            DEPT : this.state.deptList[0].DEPT
                        })
                    })
                }
                else if(col == 'PC') {
                    this.setState({
                        pcList : res.data.result
                    }, () => {
                        console.log(this.state.pcList)
                        this.setState({
                            PC : this.state.pcList[0].PC
                        })
                    })
                }
                

            }
        })
        .catch((error) => {
            console.log(error);
        });
    }

    handleSubmitFilter = (e) => {

        const {DIVISION, SECTION, BRANCH, DEPT, PC} = this.state;

        this.setState({
            competencies : []
        }, ()=>{
          
            this.props.fetchCompleteStats({
                DIVISION : DIVISION,
                SECTION : SECTION,
                BRANCH : BRANCH,
                DEPT : DEPT,
                PC : PC
            })

        })
    }

  render() {
    
    const {completedata, competencies, isOpen, branch,isLoaded} = this.state;
    //console.log('completedata:',completedata);
    var years = typeof completedata.years !=='undefined' ? completedata.years : [];
    return (
      <div>
        {/*Side menu*/}
        <SideMenu/>

        <AdminHeader title={this.state.headerTitle} style={{marginLeft:80}}/>
        
        <Container className="AssessmentStatistics">
            <Row>

                <Col md={{size: 2}}>
                    <FormGroup>                        
                        <Label for="BRANCH">Branch/Dept</Label>
                        
                        <Input type="select" name="BRANCH" id="BRANCH" onChange={(e) => this.updateValueBranch(e)}>
                            
                            {
                                this.state.branchList && this.state.branchList.map((data, key) =>
                                    <option
                                        key={key}
                                        value={data.branch_id}
                                    >
                                        {data.branch_name}
                                    </option>
                                )
                            }
                        </Input>
                    </FormGroup>
                </Col>

                {/* <Col md={{size: 2}}>
                    <FormGroup>                        
                        <Label for="DEPT">Dept</Label>
                        {
                            getYear() > '2020' ? 
                            <Input type="select" name="DEPT" id="DEPT" onChange={(e) => this.updateValue(e)}>
                                {
                                    this.state.deptList && this.state.deptList.map((data, key) =>
                                        <option
                                            key={key}
                                            value={data.dept_id}
                                        >
                                            {data.dept_name}
                                        </option>
                                    )
                                }
                            </Input>
                            :
                            <Input type="select" name="DEPT" id="DEPT" onChange={(e) => this.updateValue(e)}>
                                {
                                    this.state.deptList && this.state.deptList.map((data, key) =>
                                        <option
                                            key={key}
                                            val={data.DEPT}
                                        >
                                            {data.DEPT}
                                        </option>
                                    )
                                }
                            </Input>
                        }
                    </FormGroup>
                </Col> */}
                
                <Col md={{size: 2}}>
                    <FormGroup>
                        {
                        this.state.divisionLoading == true  ?
                            <Spinner color="primary" size="sm" style={{marginRight:5}}/> 
                            :null
                        }                       
                        <Label for="DIVISION">Division</Label>
                       
                        {
                            getYear() > '2020' ? 
                            <Input type="select" name="DIVISION" id="DIVISION" onChange={(e) => this.updateValue(e)}>
                                 
                                {
                                    this.state.divisionList && this.state.divisionList.map((data, key) =>
                                        <option
                                            key={key}
                                            value={data.division_id}
                                        >
                                            {data.division_name}
                                        </option>
                                    )
                                }
                            </Input>
                            :
                            <Input type="select" name="DIVISION" id="DIVISION" onChange={(e) => this.updateValue(e)} disabled={this.state.divisionList.length > 0 ? false:true}>
                           
                            {
                                this.state.divisionList && this.state.divisionList.map((data, key) =>
                                    <option
                                        key={key}
                                        val={data.DIVISION}
                                    >
                                        {data.DIVISION}
                                    </option>
                                )
                            }
                            </Input>
                        }
                    </FormGroup>
                </Col>
                
                <Col md={{size: 2}}>
                    <FormGroup>
                        {
                        this.state.sectionLoading == true  ?
                            <Spinner color="primary" size="sm" style={{marginRight:5}}/> 
                            :null
                        }                           
                        <Label for="SECTION">Section</Label>
                       
                        {
                            getYear() > '2020' ?
                            <Input type="select" name="SECTION" id="SECTION" onChange={(e) => this.updateValue(e)}>
                                
                                {
                                    this.state.sectionList && this.state.sectionList.map((data, key) =>
                                        <option
                                            key={key}
                                            value={data.section_id}
                                        >
                                            {data.section_name}
                                        </option>
                                    )
                                }
                            </Input>
                            :
                            <Input type="select" name="SECTION" id="SECTION" onChange={(e) => this.updateValue(e)} disabled={this.state.sectionList.length > 0 ? false:true}>
                            {
                                this.state.sectionList && this.state.sectionList.map((data, key) =>
                                    <option
                                        key={key}
                                        val={data.SECTION}
                                    >
                                        {data.SECTION}
                                    </option>
                                )
                            }
                            </Input>
                        }
                    </FormGroup>
                </Col>
                
                <Col md={{size: 2}}>
                    <FormGroup>                        
                        <Label for="PC">Job Grade</Label>
                        {
                            getYear() > '2020' ? 
                            <Input type="select" name="PC" id="PC" onChange={(e) => this.updateValue(e)}>
                                 
                                {
                                    this.state.pcList && this.state.pcList.map((data, key) =>
                                        <option
                                            key={key}
                                            value={data.pc_id}
                                        >
                                            {data.pc_name}
                                        </option>
                                    )
                                }
                            </Input>
                            :
                            <Input type="select" name="PC" id="PC" onChange={(e) => this.updateValue(e)}>
                                {
                                    this.state.pcList && this.state.pcList.map((data, key) =>
                                        <option
                                            key={key}
                                            val={data.PC}
                                        >
                                            {data.PC}
                                        </option>
                                    )
                                }
                            </Input>
                        }
                    </FormGroup>
                </Col>
                
                <Col md={{size: 2}}>
                    <FormGroup>
                        <Button onClick={(e) => this.handleSubmitFilter()} color="warning" size="sm" style={{marginTop: '22px'}}>
                            Compare
                        </Button>
                    </FormGroup>
                </Col>

            </Row>
            
            <Row>
                <Col md={{size : 12}} className="text-left">
                    <p className="breadcrumbs">Comparison of <strong>Pagcor - Bacolod</strong></p>
                </Col>
                <Col md={{size: 12}}>
                    
                    <Row className="text-left" style={{marginBottom: '10px'}}>
                        <Col md={{size: 5}}></Col>
                        {
                            years.length > 0 ?
                                years.map((year,key) =>
                                    <Fragment key={key}>
                                        <Col md={{size: 2}}><strong>{year.year}</strong></Col>
                                        {/* <Col md={{size: 2}}><strong>2020</strong></Col>  */}
                                    </Fragment>
                                )
                            :
                            null
                           
                        }
                       
                        <Col md={{size: 3}}></Col>
                    </Row>
                    {
                        competencies && competencies.length ? 
                            competencies.map((comp, key) => 
                                comp.name !== 'totallPerc' ?
                                    <div key={key} className="text-left">
                                        
                                        <Row className="text-left">
                                            <Col md={{size: 5}}>
                                                
                                                <FontAwesomeIcon
                                                    className="toggleIconHere"
                                                    icon={isOpen[key] ? faMinus : faPlus} size="1x"
                                                    onClick={(e) => {
                                                        var is_open = isOpen;
                                                        is_open[key] = !is_open[key];
                                                        this.setState({
                                                            isOpen : is_open
                                                        })
                                                    }}
                                                />
                                                <h5>{comp.name}</h5>
                                            </Col>
                                            <Col md={{size: 2}}>{completedata[2019][comp.name].totallPerc}%</Col>
                                            <Col md={{size: 2}}>{completedata[2020][comp.name].totallPerc}%</Col> 
                                            <Col md={{size: 2}}>{completedata?.[2021]?.[comp.name]?.totallPerc}%</Col> 
                                            <Col md={{size: 3}}></Col>
                                        </Row>
                                        <Collapse isOpen={isOpen[key]} className="text-left">
                                            <Card style={{border: 'none'}}>
                                                <CardBody style={{border:0, padding: 0}}>
                                                    {
                                                        comp.subcompetencies && comp.subcompetencies.length && comp.subcompetencies.map((subComp, key2) => 
                                                            subComp.name !== 'totallPerc' ?
                                                                <Row key={key2}>
                                                                    <Col md={{size : 5}}>{subComp.name}</Col>
                                                                    <Col md={{size : 2}}>{completedata?.[2019]?.[comp.name]?.[subComp.name]?.totallPerc}%</Col>
                                                                    <Col md={{size : 2}}>{completedata?.[2020]?.[comp.name]?.[subComp.name]?.totallPerc}%</Col> 
                                                                    <Col md={{size : 2}}>{completedata?.[2021]?.[comp.name]?.[subComp.name]?.totallPerc}%</Col> 
                                                                    <Col md={{size : 3}}>
                                                                        <p className="inlineB">2019</p>
                                                                        <Progress className="progressBar1" color="info" value={completedata?.[2019]?.[comp.name]?.[subComp.name]?.totallPerc} /><br/>
                                                                        <p className="inlineB">2020</p>
                                                                        <Progress className="progressBar2" value={completedata?.[2020]?.[comp.name]?.[subComp.name]?.totallPerc} />
                                                                        <p className="inlineB">2021</p>
                                                                        <Progress className="progressBar2" value={completedata?.[2021]?.[comp.name]?.[subComp.name]?.totallPerc} />
                                                                    </Col>
                                                                </Row>
                                                            : null
                                                        )
                                                    }
                                                </CardBody>
                                            </Card>
                                        </Collapse>                                
                                    </div>
                                : null
                            )
                        :
                        competencies && competencies.length == 0 && isLoaded == true ?
                        <Row>
                            <Col>No record found against selected filter, select another filter.</Col>
                        </Row>
                        :
                        <Spinner style={{margin: '20vh 0'}} size="md" color="dark" />
                    }
                </Col>
            </Row>
        </Container>

      </div>
     

    );
  }
}

AssessmentStatistics.propTypes = {
    fetchCompleteStats: PropTypes.func.isRequired,
    getAdditionalData : PropTypes.func.isRequired
}

const mapStatesToProps = state => ({
    getAllAssessmentStatistics : state.webRed.getAllAssessmentStatistics,
    additionalData : state.webRed.additionalData
})

export default connect( mapStatesToProps, { fetchCompleteStats,getAdditionalData })( AssessmentStatistics );