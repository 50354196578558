export const _addedDataSuccessfull = '_addedDataSuccessfull';
export const _triggerNextToPin = '_triggerNextToPin';
export const _triggerLoginBtn = '_triggerLoginBtn';
export const _triggerSwitchTab = '_triggerSwitchTab';
export const _getAllAssessment = '_getAllAssessment';
export const _assessmentFormDone = '_assessmentFormDone';
export const _switchBranchFromSidebar = '_switchBranchFromSidebar';
export const _getAllBranchesList = '_getAllBranchesList';

export const _proceedToAssessments = '_proceedToAssessments';
export const _updateRequestSent = '_updateRequestSent';

export const _fetchEmployeeDataAdmin = '_fetchEmployeeDataAdmin';
export const _assignedSupervisorSuccess = '_assignedSupervisorSuccess';
export const _assignedSubordinateSuccess = '_assignedSubordinateSuccess';
export const _removedSubordinateSuccess = '_removedSubordinateSuccess';
export const _fetchedAllCompetenciesListAdmin = '_fetchedAllCompetenciesListAdmin';
export const _getScoreCard = '_getScoreCard';
export const _getScoreCardStatus = '_getScoreCardStatus';

export const _getSubAdmins = '_getSubAdmins';

export const _restValue = '_restValue';

export const _validateEmailId = '_validateEmailId';


//Type for assessment statistics
export const _getAllAssessStats = '_getAllAssessStats';
export const _getAssessmentsHistory = '_getAssessmentsHistory';
export const _getAllAdminAuditLogs = '_getAllAdminAuditLogs';

export const _getAllEmpReportsData = '_getAllEmpReportsData';

export const _showScoreCardLoader = '_showScoreCardLoader';

export const _totalPeopleAssesst = '_totalPeopleAssesst';
export const _fetchMainCompetencies = '_fetchMainCompetencies';
export const _getSubCompetency = '_getSubCompetency';
export const _allCompetencyAvg = '_allCompetencyAvg';
export const _fetchDivisions = '_fetchDivisions';
export const _competencyByDivision = '_competencyByDivision';
export const _competencyBySecions = '_competencyBySecions';
export const _branchAvgDme = '_branchAvgDme';
export const _dmeByDivision = '_dmeByDivision';
export const _dmeBySections = '_dmeBySections';
export const _dmeCategorised = '_dmeCategoriseds';
export const _candidateDistribution = '_candidateDistribution';
export const _competencyYear = '_competencyYear';

//Types for Comparision Graphs
export const _totalPeopleAssesstCompare = '_totalPeopleAssesstCompare';
export const _competencyAvgCompare = '_competencyAvgCompare';
export const _competencyByDivisionCompare = '_competencyByDivisionCompare';
export const _competencyBySecionsCompare = '_competencyBySecionsCompare';
export const _branchAvgDmeCompare = '_branchAvgDmeCompare';
export const _compareDmeByDivision = '_compareDmeByDivision';
export const _compareDmeBySections = '_compareDmeBySections';
export const _compareDmeCategorised = '_compareDmeCategorised';


//Types for All Branches Stats
export const _totalPeopleAssesstAllBranches = '_totalPeopleAssesstAllBranches';
export const _fetchMainCompetenciesAllBranches = '_fetchMainCompetenciesAllBranches';
export const _getSubCompetencyAllBranches = '_getSubCompetencyAllBranches';
export const _candidateDistributionAllBranches = '_candidateDistributionAllBranches';
export const _dmeCategorisedAllBranches = '_dmeCategorisedAllBranches';


//Types for All Branches Stats Compare
export const _totalPeopleAssesstCompareAllBranches = '_totalPeopleAssesstCompareAllBranches';
export const _competencyAvgCompareAllBranches = '_competencyAvgCompareAllBranches';
export const _competencyByDivisionCompareAllBranches = '_competencyByDivisionCompareAllBranches';
export const _competencyBySecionsCompareAllBranches = '_competencyBySecionsCompareAllBranches';
export const _branchAvgDmeCompareAllBranches = '_branchAvgDmeCompareAllBranches';
export const _compareDmeByDivisionAllBranches = '_compareDmeByDivisionAllBranches';
export const _compareDmeBySectionsAllBranches = '_compareDmeBySectionsAllBranches';
export const _compareDmeCategorisedAllBranches = '_compareDmeCategorisedAllBranches';
export const _fetchAdditionalData = '_fetchAdditionalData';
export const _fetchMainGroupsData = '_fetchMainGroupsData';
export const _fetchSubGroupsData = '_fetchSubGroupsData';

//Type Employee
export const _fetchEmployeeActivityLogs = "_fetchEmployeeActivityLogs";
export const _showHideLoader = "_showHideLoader";
export const _searchEmployeeLogs = "_searchEmployeeLogs";
export const _resetEmpId = "_resetEmpId";

//ASSESSMENT HISTORY
export const _assessHistDeperts = "_assessHistDeperts";

export const _setPrfileYear = "_setPrfileYear";
