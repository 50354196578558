import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import logoHere from "./../../public/images/logo.png";
import "../../public/scss/Header.scss";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';

import userData from './userData';

// import store from './../../store';
import PropTypes from 'prop-types';
import {  } from './../../actions/webRedActions';
import { connect } from 'react-redux';
import {getEmployeeProfile, checkIfEmpSupervisor, checkIfEmpIsAdmin , getYear} from './../../actions/utility';
import { getAllAssessments,getScoreCardDataStatus,
  restObjVal,addEmployeeLogoutTime,addEmployeeLoginTime
} from './../../actions/webRedActions';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css


import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Row,
  Col,
  Media,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  CustomInput,
  FormGroup
  // NavLink
} from "reactstrap";
var user ={};
class Header extends Component {
  constructor(props) {
    super(props);
    const thisRoute = window.location.hash;

    this.toggle = this.toggle.bind(this);

    this.state = {
      isOpen: false,
      showScoreCard:false,
    showScareCard:true,
      isUserLoggedIn : localStorage.getItem('isLoggedInPagCor') && localStorage.getItem('isLoggedInPagCor') == 'true' ? true : false,
      userInfo : getEmployeeProfile(),
      activeTab : thisRoute && thisRoute != undefined && thisRoute != null ? thisRoute.replace("#/","") : '',
      redirectToProfile : null,
      confirmModal : false,
      isConfirmChecked : false,
      allowAssessments : false,
      isSupervisor : getYear() == '2020' ? checkIfEmpSupervisor() : 0, //if it's supervisor too,
      confirmSubAdminModal:false,
      showView:true,
      isCheckedBox:false
    };

  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  componentWillMount () {
    console.log(this.state.activeTab);
    // this.props.getScoreCardData(this.state.userInfo.ID_NUMBER,'2020');
  }

  componentDidMount () {

    user= localStorage.getItem('pagCorUserProfile')
    if(user)
    { 
      user = JSON.parse(user)
      console.log('user profile',user)
      this.props.getScoreCardDataStatus(user.ID_NUMBER,'2020');

    }

    // if(!this.state.isUserLoggedIn) {
    //   if(!(window.location.href.indexOf('/login') > -1)) {
    //     window.location.href = "/#/login";
    //   }
    // }

    if(checkIfEmpIsAdmin()){
      
      var isAssessAllowed = localStorage.getItem('allowAssessments');
      if(isAssessAllowed && isAssessAllowed == 'allowed') {
        this.setState({
          allowAssessments : true
        })
      }

      if(typeof localStorage.adminRoute !=='undefined' && localStorage.adminRoute != 'none' &&  isAssessAllowed != 'allowed') {
        window.location.href = "/#/"+localStorage.adminRoute;
    }
    else if(localStorage.adminRoute == 'none') {
        this.setState({
            showView:false,confirmSubAdminModal:true
        })
    }
      
      //window.location.href = "/#/admin"; 
    }
    else {
      
      var isAssessAllowed = localStorage.getItem('allowAssessments');
      if(isAssessAllowed && isAssessAllowed == 'allowed') {
        this.setState({
          allowAssessments : true
        })
      }
    }

  }

  componentWillReceiveProps(nextProps, thisProps) {
    if(this.props.getScoreCardStatusSet != nextProps.getScoreCardStatusSet) {
      // console.log("see status",nextProps.getScoreCardStatusSet.result.is_complete);
      if(typeof nextProps.getScoreCardStatusSet.result !=="undefined" && nextProps.getScoreCardStatusSet.result.is_complete==1)
      { 
        console.log('chec check check')
        this.setState({
          showScoreCard:true
        })
      }
      else if(typeof nextProps.getScoreCardStatusSet.result !=="undefined" && nextProps.getScoreCardStatusSet.result.is_complete==0)
      {
        console.log('chec check check')
        this.setState({
          showScoreCard:false
        })
      }
      // if(nextProps.getScoreCardStatusSe.result){
      //   console.log(nextProps.getScoreCardStatusSe.result)
      //   if()
      // }

    }
    if(this.props.triggeredLoginAction != nextProps.triggeredLoginAction) {
      console.log(nextProps.triggeredLoginAction);
      if(typeof nextProps.triggeredLoginAction.data !=="undefined" && nextProps.triggeredLoginAction.data.status == "success") {
         
          localStorage.setItem("isLoggedInPagCor", "true"); // create storage for user session
          
          //setting the localstorage for persistency of userData
          var userProfile = nextProps.triggeredLoginAction.data.result;
          this.props.addEmployeeLoginTime(userProfile?.ID_NUMBER);
          var relatedData = nextProps.triggeredLoginAction.data.userRelatedData;

          localStorage.setItem("pagCorUserProfile", JSON.stringify(userProfile));
          localStorage.setItem("userRelatedData", JSON.stringify(relatedData));
    
          var isSupervior = nextProps.triggeredLoginAction.data.is_supervisor;
          if(isSupervior && isSupervior == '1'){
            localStorage.setItem("userIsSupervisor", isSupervior);
            var subOrdinates = nextProps.triggeredLoginAction.data.sub_ord_data;
            localStorage.setItem("userSubOrdinates", JSON.stringify(subOrdinates));
          }

          var isAdmin = nextProps.triggeredLoginAction.data.is_admin;
         
          var isSuperAdmin =0; 
          
          try {
            isSuperAdmin = nextProps.triggeredLoginAction.data.admin_data.is_superadmin;
          }
          catch(err) {
            //error
          }

          if(isAdmin && isAdmin == '1' ){
            localStorage.setItem("userAdminData", JSON.stringify(nextProps.triggeredLoginAction.data.admin_data));
            if(isSuperAdmin == 0) {
              localStorage.setItem("adminRoute",'none');
              console.log('show here the sub admin confrim modal'); 
              this.setState({
                confirmSubAdminModal:true
              });
            }
            else {
               this.setState({
              redirectToProfile : "/admin"
            })
            }
            // this.setState({
            //   redirectToProfile : "/admin"
            // })
          } else {
            this.setState({
              confirmModal : true
            })
          }

      }
    }

    if(this.props.proceedToAssessmentsTrigger != nextProps.proceedToAssessmentsTrigger) {
      console.log(nextProps.proceedToAssessmentsTrigger);
      this.setState({
        allowAssessments : true,
        redirectToProfile : "/assess-myself"
      }, () => {
        localStorage.setItem('allowAssessments', 'allowed'); // set localstorage for assessments allowed trigger
      })
    }

    
    if(this.props.updateRequestSentTrigger != nextProps.updateRequestSentTrigger) {
      console.log(nextProps.updateRequestSentTrigger);

      if(nextProps.updateRequestSentTrigger.result == "1"){
        
        confirmAlert({
          title: 'Request sent',
          message: "Your update request has been sent to PAGCOR's HR Team. Once they have process the updates you will be able to proceed to the assessement.",
          closeOnEscape: false,
          closeOnClickOutside: false,
          buttons: [
              {
                label: 'OK',
                onClick: () => {
                  this.setState({
                    allowAssessments : false
                  }, () => {
                    
                    // remove all the localstorage data stored for user
                    // localStorage.clear();
                    // localStorage.removeItem("isLoggedInPagCor");
                    // localStorage.removeItem("pagCorUserProfile");
                    // localStorage.removeItem("allowAssessments");
                    // localStorage.removeItem("userIsSupervisor");
                    // localStorage.removeItem("userSubOrdinates");
                    // localStorage.removeItem("userIsAdmin");
                    
                    // window.location.reload();
                    
                  })
                }
              }
          ]
        })

      }
      
      
    }

    

  }

  toggleConfirmModal = () => {
    this.setState({
      confirmModal : !this.state.confirmModal
    })
  }

  // validateRouteForActive = (route) => {
  //   if(window.location.href.indexOf('/'+route) > -1)
  //     return true;
  //   else 
  //     return false;
  // }

  // Event handler to update the state when the checkbox is clicked
  handleCheckboxChange = () => {
    this.setState(prevState => ({
      isCheckedBox: !prevState.isCheckedBox // Toggle the checked state
    }));
  };


  render() {

    const { userInfo, activeTab, confirmModal, isConfirmChecked, allowAssessments,confirmSubAdminModal,isCheckedBox } = this.state;
    
    if (this.state.redirectToProfile) {
      return <Redirect to={this.state.redirectToProfile} />
    }

    return (
      <div className="MainHeader">

        <div>

        <Modal isOpen={confirmModal} centered size="lg" className={"confirmModal"}>
          <ModalHeader>Instructions</ModalHeader>
          <ModalBody>
            {/* <h4>Instructions</h4> */}
            <p>
              Hello PAGCOR Employee!<br/><br/>
              You are about to take the PAGCOR’s Competency-Based Assessment {getYear()}. This assessment measures your knowledge, skills, values, abilities based on the core, organizational, leadership, and technical competencies expected for all PAGCOR employees. <br/> <br/>
              We have shifted from an inventory type of assessment to a competency-based one for us to facilitate a more objective, clear-cut, and specific measurement and evaluation of your proficiencies.<br/> <br/>
              Kindly read the directions carefully as you take the required tests for your position. If you have any questions or clarifications, please seek the assistance of the test administrator.<br/> <br/>
              Good luck! <br/> <br/>
              PTCMD, HRCOE <br/> <br/>
            </p>
          </ModalBody>
          <ModalFooter>

            <Row>
              <Col md={{size: '12'}}>
                <FormGroup style={{fontSize: '12px'}}>
                  <CustomInput type="checkbox" name={'isConfirmChecked'}
                    value={isConfirmChecked}
                    id={'isConfirmChecked'}
                    onChange={(e) =>{
                      this.handleCheckboxChange();
                      this.setState({
                        isConfirmChecked : !isConfirmChecked
                      })
                    }}
                    label="I understand the significance of this competency assessment and how this can guide me/my team member towards improvement of my work performance. I will read the instructions and items intently and will respond truthfully."
                    checked={isCheckedBox}
                  />
                </FormGroup>
              </Col>
              
              <Col md={{size: '12'}} style={{textAlign: 'right', paddingBottom: '15px'}}>
                <Button disabled={!isCheckedBox} color="primary" size="lg" onClick={(e) => {
                  // Access the current URL
                  const currentUrl = window.location.href;
                  
                  // Check if the current URL contains a specific substring
                  const containsSubstring = currentUrl.includes("/profile");
                  if(containsSubstring){
                    window.location.reload();
                  }
                  else{
                    this.setState({
                      redirectToProfile :  "/profile"
                    })
                  }
                
                  console.log('currentUrl: ', currentUrl);
                  
                }}>Next</Button>
              </Col>
            </Row>

          </ModalFooter>
        </Modal>
        {/*confirm box for admin whether he want to use the amdmin view or want give the assessment*/}
        <Modal isOpen={confirmSubAdminModal} centered size="lg" className={"confirmModal"}>
          <ModalHeader>Select</ModalHeader>
          <ModalBody>
            {/* <h4>Instructions</h4> */}
            <p>
              Please choose to navigate
            </p>
          </ModalBody>
          <ModalFooter>

            <Row>
             
              
              <Col md={{size: '12'}} style={{textAlign: 'right', paddingBottom: '15px'}}>
                <Button  color="primary" size="lg" onClick={(e) => {
                  this.setState({
                    redirectToProfile : "/admin"
                  },()=>{
                    localStorage.setItem('adminRoute','admin');
                  })
                }}>Go to admin</Button>
                <Button style={{marginLeft:5}} color="primary" size="lg" onClick={(e) => {
                  this.setState({
                    confirmModal :true 
                  },()=>{
                    localStorage.setItem('adminRoute','profile');
                  })
                }}>Go to assessment</Button>
              </Col>
             
            </Row>

          </ModalFooter>
        </Modal>

        <Navbar color="light" light expand="md">
            <NavbarBrand href="##">
              PAGCOR ASSESSMENTS PROGRAM
            </NavbarBrand>
            <NavbarToggler onClick={this.toggle} />
            <Collapse isOpen={this.state.isOpen} navbar>
              <Nav className="ml-auto text-left" navbar>

                {
                  !this.state.isUserLoggedIn ?
                    <NavItem>
                      <Link className="nav-link" to="/login">
                        Login
                      </Link>
                    </NavItem>
                    : null
                }

                {
                  this.state.isUserLoggedIn ?
                    <UncontrolledDropdown nav inNavbar>
                      <DropdownToggle nav>
                        { userInfo.FIRST_NAME + " " + userInfo.MI + ". " + userInfo.LAST_NAME }
                        <FontAwesomeIcon className="userIconHeader" icon={faUser} size="1x" />
                      </DropdownToggle>
                      <DropdownMenu right>
                        <DropdownItem>
                          <Link className="nav-link" to="#"
                            onClick={(e) => {
                              this.props.addEmployeeLogoutTime(userInfo.ID_NUMBER,()=>{
                                // remove all the localstorage data stored for user
                                localStorage.clear();
                                localStorage.removeItem("isLoggedInPagCor");
                                localStorage.removeItem("pagCorUserProfile");
                                localStorage.removeItem("allowAssessments");
                                localStorage.removeItem("userIsSupervisor");
                                localStorage.removeItem("userSubOrdinates");
                                localStorage.removeItem("userIsAdmin");
                                window.location.href = "/#/login";
                                window.location.reload();
                              })
                            }}
                          >
                            Logout
                          </Link>
                          <Link className="nav-link" to="#"  
                              onClick={(e) => {
                                this.handleCheckboxChange();
                                // this.isConfirmChecked = true;
                                this.setState({
                                  confirmModal :true 
                                })
                              }}>Read Instructions
                          </Link>
                        </DropdownItem>
                      </DropdownMenu>
                  </UncontrolledDropdown>
                  : null
                }
                
              </Nav>
            </Collapse>
        </Navbar>

        {
          this.state.isUserLoggedIn && this.state.showView == true ?
          
            <Navbar className="tabsNavigation" color="light" light expand="md" style={{textAlign: 'center'}}>
              <Nav navbar style={{margin: 'auto'}}>

                <NavItem className={activeTab == 'profile' ? 'isActive' : ''}>
                  <Link className="nav-link" to="/profile" onClick={(e) => this.setState({
                    activeTab : 'profile'
                  })}>
                    { userInfo.FIRST_NAME + " " + userInfo.MI + ". " + userInfo.LAST_NAME }
                  </Link>
                </NavItem>

                <NavItem style={{opacity : allowAssessments ? 1 : 0.3}} className={activeTab == 'assess-myself' ? 'isActive' : ''}>
                  <Link className="nav-link" to={ allowAssessments ? "/assess-myself" : "#"} onClick={(e) => {
                    this.props.restObjVal()//
                    if(allowAssessments)
                      this.setState({
                        activeTab : 'assess-myself'
                      })
                  }}>
                    {getYear() == '2020' ? "Assess myself" : "Assess" }
                  </Link>
                </NavItem>
                
                {
                  this.state.isSupervisor ?
                    <NavItem style={{opacity : allowAssessments ? 1 : 0.3}} className={activeTab == 'assess-subordinate' ? 'isActive' : ''}>
                      <Link className="nav-link" to={ allowAssessments ? "/assess-subordinate" : "#"} onClick={(e) => {
                        this.props.restObjVal()//
                        if(allowAssessments)
                          this.setState({
                            activeTab : 'assess-subordinate'
                          })
                      }}>
                        Assess subordinate
                      </Link>
                    </NavItem>
                  : null
                }
                    {
                  this.state.showScareCard?
                  this.state.showScoreCard==true?
                    <NavItem style={{opacity : allowAssessments ? 1 : 0.3}} className={(activeTab  == 'scorecard')? 'isActive' : ''}>
                      <Link className="nav-link"onClick={(e) => {

                        if(allowAssessments)
                          this.setState({
                            activeTab : 'scorecard'
                          })
                      }}
                      to={ allowAssessments ? "/scorecard" : "#"} 
                      >
                        Score Card
                      </Link>
                    </NavItem>
                    : 
                    <NavItem style={{opacity : 0.7}} className={(activeTab  == 'scorecard')? 'isActive' : ''}>
                    <Link className="nav-link"
                    to={'#'}
                    >
                      Score Card
                    </Link>
                  </NavItem>
                  :null
                }

                
              </Nav>
            </Navbar>
          : null
        }
          

        </div>
      </div>
    );
  }
}

// export default Header;

Header.propTypes = {
  restObjVal: PropTypes.func.isRequired,
}

const mapStatesToProps = state => ({
  triggeredLoginAction : state.webRed.triggeredLoginAction,
  proceedToAssessmentsTrigger: state.webRed.proceedToAssessmentsTrigger,
  updateRequestSentTrigger : state.webRed.updateRequestSentTrigger,
  getScoreCardStatusSet : state.webRed.getScoreCardStatusSet
})

export default connect(mapStatesToProps, { getScoreCardDataStatus,restObjVal,addEmployeeLogoutTime,addEmployeeLoginTime })(Header);