import React, { Component } from "react";
import "./../../../public/scss/pagcorDashboard_statistics.scss";
import PropTypes from 'prop-types';
import { branchAvgDMERecord, triggerYearChange, candidateDistributionRecordAllBranches, categorisedDMERecordAllBranches, sectionDMERecord, divisionDMERecord, competencyBySectionRecord, competencyByDivisionRecord, getAllDivisions, getAllCompetencyAvgRecord, getAllBranches, getTotalPeopleAssesstAllBranches, updateBranchAction, getMainCompetencyListAllBranches, getSubCompetencyRecordAllBranches, getAllSkills, getAllCitiesData, getDemographicAvg, getGenderClassificationAvg, getAgeClassificationAvg, getPositionClassificationAvg, getMediumsClassificationAvg, getTransportClassificationAvg } from './../../../actions/webRedActions';
import { connect } from 'react-redux';
import {
  Container,
  Row,
  Col,
  Button,
  Spinner,
  FormGroup,
  Input,
  Media,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  Tooltip
} from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMale, faFemale, faTimes, faBars, faSchool, faInfo, faComment, faCalendarCheck, faCalendar } from '@fortawesome/free-solid-svg-icons';
import { ResponsiveBubble } from '@nivo/circle-packing';
import { ResponsiveTreeMap } from '@nivo/treemap';
import { ResponsiveHeatMap } from '@nivo/heatmap';
import { ResponsiveBar } from '@nivo/bar';
import branchicon from './../../../public/images/branches/branchicon.png';
import devisionicon from './../../../public/images/branches/divisionIcon.png';
import sectionicon from './../../../public/images/branches/sectionIcon.png';
import branchData1 from './branchData1';
import branchData2 from './branchData2';
import archetypesData from './architypesData';
import { Link } from "react-router-dom";

class AllBranchesStats extends Component {

  constructor(props) {
    super(props);

    this.state = {

      branchIndex: 0,
      competencyIndex: 0,
      divisionIndex: 0,
      sectionIndex: 0,
      branches: [
        branchData1,
        branchData2
      ],
      allBranches: {
        mainCompetencies: [{
          name: "CORE",
          totalAverage: 84.3
        }, {
          name: "ORGANIZATIONAL",
          totalAverage: 90
        }, {
          name: "LEADERSHIP",
          totalAverage: 95.3
        }, {
          name: "TECHNICAL",
          totalAverage: 93
        }]
      },
      archetypesList: archetypesData,
      archetypesDataChart: [],
      compComparisonData: {
        keys: [],
        data: []
      },
      compComparisonDataDivisionWise: {
        keys: [],
        data: []
      },
      compComparisonDataDivisionWiseTempDivisionArray: [], // temporary random scores array for persistency while switching in different divisions to show in sections comparison chart
      compComparisonDataSectionWise: {
        keys: [],
        data: []
      },
      divisionSectionData: {
        "name": "loading",
        "color": "hsl(241, 70%, 50%)",
        "sample": 1
      },
      respondentsCatogorizeDMEData: '',
      competencyFrameworkData: '',
      branchesListToggle: false,
      archetypesTreeMapData: '',
      tooltipOpen: [],



      branchesListData: [],
      selectedBranchIdValue: '',



      selectedSkill: this.props.match.params.skillId,
      selectedSkillId: 0,


      skillUpdateLoader: false,
      GenderUpdateLoader: false,
      userTypeScoresDataPie1: [[], []],
      userTypeScoresDataPie1Loc: 0,
      genderScoresData: [],
      genderScoresDataLoc: 0,
      ageScoreDataLoc: 0,
      userTypeAgeScoresData: [{
        keys: [],
        data: []
      }, {
        keys: [],
        data: []
      }],
      positionScoreDataLoc: 0,
      userTypePositionsScoresData: [{
        keys: [],
        data: []
      }, {
        keys: [],
        data: []
      }],
      mediumScoreDataLoc: 0,
      userTypeMediumsScoresData: [{
        keys: [],
        data: []
      }, {
        keys: [],
        data: []
      }],
      transportScoreDataLoc: 0,
      userTypeTransportsScoresData: [{
        keys: [],
        data: []
      }, {
        keys: [],
        data: []
      }],
      swarmDataAllRegions: [],
      isBranchesLoading: false,
      allCities: [{
        cityid: 0,
        cityname: "Manila"
      }],
      cityUpdateLoader: false,
      situationsAvgDataLoc: 0,
      citySearchText: '',
      selectedMayer: "Isko Moreno",
      // showModalInfo : false,
      // showModalChat : false,
      integratedFormId: "",
      integratedFormToggle: false,
      formSource: "",
      totalAssesst: 'loading',
      totalDivision: 'loading',
      totalSection: 'loading',
      branchName: "",
      mainCompetency: [],
      isMainCompetencyLoading: true,
      mainCompetencyAverage: "0",
      subCompetency: [],
      arrray: [],
      subCompPerc: "0",
      mapCompetencyonTree: [],
      compComparisonDataApi: {
        keys: [],
        data: []
      },
      allDivisions: [],
      allSections: [],
      compComparisonDataDivisionWiseApi: {
        keys: [],
        data: []
      },
      divisionName: "",
      sectionName: "",
      compComparisonDataSectionWiseApi: {
        keys: [],
        data: []
      },
      respondentsCatogorizeDMEDataApi: {
        keys: [],
        data: []
      },
      competencyName: "",
      divisionSectionDataApi: '',
      dummydivisionSectionDataApi: {
        "name": "Test Branch",
          "color": "hsl(241, 70%, 50%)",
          "children": [{
            "name" : "Division 1",
            "children" : [{
              "name" : "Section 1",
              "children" : [{
                "name": "Designation 1",
                "sample": "5"
              },
              {
                "name": "Designation 2",
                "sample": "0"
              }]
            }]
          },
        ]
      },
      subcompetencyLoader: true,
      selectedYear: '2019',
      totalPeopleinSection: 0,
      sectionHideGraph: false,
      divisionHideGraph: false,
      bacolodImg: 'https://banner2.cleanpng.com/20180614/qrv/kisspng-placewell-international-services-corp-bacolod-cit-city-logo-5b2271ccec1ca1.5961857215289840129671.jpg',
      noImg: 'https://ctt.trains.com/sitefiles/images/no-preview-available.png'
    };


  }

  componentDidMount() {
    window.scrollTo(0, 0); // scroll to top after rendering the component
    this.props.getTotalPeopleAssesstAllBranches(this.state.selectedYear);
    this.props.getMainCompetencyListAllBranches(this.state.selectedYear);
    this.props.candidateDistributionRecordAllBranches(this.state.selectedYear);
  }

  updateCompetency = (comp, index) => {
    this.state.subCompetency = [];
    this.setState({
      competencyIndex: index,
      competencyName: comp.competency_name,
      subcompetencyLoader: true,
      subCompPerc: 0
    }, () => {
      debugger;
      this.props.getSubCompetencyRecordAllBranches(comp.competency_id,this.state.selectedYear)
      // this.DMEDataMapping(this.state.branchIndex, this.state.competencyIndex);
    })
  }

  updateCompetencyFromDropDown = (compId) => {
    // this.state.subCompetency = [];
    debugger;
    this.setState({
      competencyIndex: compId,
    }, () => {
      debugger;
      this.props.getSubCompetencyRecordAllBranches(this.state.mainCompetency[compId].competency_id,this.state.selectedYear)
      // this.DMEDataMapping(this.state.branchIndex, this.state.competencyIndex);
    })
  }

  componentWillReceiveProps(nextProps, thisProps) {
    if (this.props.switchedBranch != nextProps.switchedBranch) {
      debugger;
      this.state.subCompetency = [];
      this.setState({
        selectedBranchIdValue: nextProps.switchedBranch.selectedBranch.id,
        branchName: nextProps.switchedBranch.selectedBranch.name,
        branchesListToggle : false,
        //First Section States Zero for loader
        totalAssesst: 'loading',
        totalDivision: 'loading',
        totalSection: 'loading',
        //Second Main and Sub Competency loader
        isMainCompetencyLoading: true,
        subcompetencyLoader: true,
        competencyIndex: 0,
        subCompPerc: 0,
        mainCompetencyAverage: 0,
        mainCompetency: [],
        //Third Tree Map
        mapCompetencyonTree: '',
        //Fourth COmpetency Avg
        compComparisonDataApi: {
          keys: [],
          data: []
        },
        //Fifth Competency with Division
        compComparisonDataDivisionWiseApi: {
          keys: [],
          data: []
        },
        //Sixth Competency with Section
        compComparisonDataSectionWiseApi: {
          keys: [],
          data: []
        },
        //Seventh Bubble Graph for Designatino
        divisionSectionDataApi: ''
      }, () => {
        console.log('branchIndex', this.state.selectedBranchIdValue);
        // this.DMEDataMapping(this.state.branchIndex, this.state.competencyIndex);
        this.props.getTotalPeopleAssesst(this.state.selectedBranchIdValue, this.state.selectedYear);
        this.props.getMainCompetencyList(this.state.selectedBranchIdValue, this.state.selectedYear);
        // this.props.getAllDivisions(this.state.selectedBranchIdValue , this.state.selectedYear);
        this.props.branchAvgDMERecord(this.state.selectedBranchIdValue, this.state.selectedYear);
        this.props.candidateDistributionRecordAllBranches(this.state.selectedYear);
      })
    }

    else if (this.props.competencyYear != nextProps.competencyYear) {
      debugger;
      this.state.subCompetency = [];
      this.setState({
        selectedYear: nextProps.competencyYear,
        //First Section States Zero for loader
        totalAssesst: 'loading',
        totalDivision: 'loading',
        totalSection: 'loading',
        //Second Main and Sub Competency loader
        isMainCompetencyLoading: true,
        subcompetencyLoader: true,
        competencyIndex: 0,
        subCompPerc: 0,
        mainCompetencyAverage: 0,
        mainCompetency: [],
        //Third Tree Map
        mapCompetencyonTree: '',
        //Fourth COmpetency Avg
        compComparisonDataApi: {
          keys: [],
          data: []
        },
        //Fifth Competency with Division
        compComparisonDataDivisionWiseApi: {
          keys: [],
          data: []
        },
        //Sixth Competency with Section
        compComparisonDataSectionWiseApi: {
          keys: [],
          data: []
        },
        //Seventh Bubble Graph for Designatino
        divisionSectionDataApi: '',
        respondentsCatogorizeDMEDataApi: {
            keys: [],
            data: []
          },
      }, () => {
        console.log('branchIndex', this.state.selectedBranchIdValue);
        this.props.getTotalPeopleAssesstAllBranches(this.state.selectedYear);
        this.props.getMainCompetencyListAllBranches(this.state.selectedYear);
        this.props.candidateDistributionRecordAllBranches(this.state.selectedYear);
      })
    }

    else if (this.props.totalPeopleAssest != nextProps.totalPeopleAssest) {
      this.setState({
        totalAssesst: nextProps.totalPeopleAssest.result.totallPeopleAssesst,
        totalDivision: nextProps.totalPeopleAssest.result.divisions.length,
        totalSection: nextProps.totalPeopleAssest.result.sections.length
      }, () => {
        console.log('branchIndex', this.state.selectedBranchIdValue);
      })
    }


    else if (this.props.mainCompetencies != nextProps.mainCompetencies) {
      try {
        
        this.setState({
          mainCompetency: nextProps.mainCompetencies.result,
          mainCompetencyAverage: nextProps.mainCompetencies.mainComptPerc,
          isMainCompetencyLoading: false,
          competencyName: nextProps.mainCompetencies.result[0].competency_name
        }, () => {
          this.props.getSubCompetencyRecordAllBranches(this.state.mainCompetency[0].competency_id, this.state.selectedYear)
          this.props.categorisedDMERecordAllBranches(this.state.selectedYear)
        //   this.props.getAllCompetencyAvgRecord('', this.state.selectedYear);
          console.log('branchIndex', this.state.selectedBranchIdValue);
        })

      }
      catch (err) {
        window.alert(err);
      }
    }

    else if (this.props.getSubCompetency != nextProps.getSubCompetency) {
        debugger;
      if(Object.keys(nextProps.getSubCompetency.result).length > 0){
      var dummmyArray = [];
        nextProps.mainCompetencies.result.map((item, index) => {
          dummmyArray.push({
            averageScore: nextProps.mainCompetencies.everyMainComptPerc[item.competency_id].totallPerc,
            name: item.competency_name,
            fullname: item.competency_name + " - Total responses",
            totalSample: nextProps.mainCompetencies.everyMainComptPerc[item.competency_id].totallCnt
          })
        })
        setTimeout(() => {
          this.setState({
            mapCompetencyonTree: {
              "name": this.state.branchName,
              "fullname": this.state.branchName,
              "children": dummmyArray
            }
          },
            () => {
              console.log(this.state.mapCompetencyonTree);
            })
        }, 500);
      }
      else{
        this.setState({
          mapCompetencyonTree: 'No Record'
        })
        debugger;
      }



      this.state.subCompetency = [];
      var subCompAvg = 0;
      var count = 0;
      Object.keys(nextProps.getSubCompetency.result).map(item =>
        this.state.subCompetency.push({
          name: item,
          totall: nextProps.getSubCompetency.result[item].totall,
          totallPerc: nextProps.getSubCompetency.result[item].totallPerc
        }))
      Object.keys(nextProps.getSubCompetency.result).map(itemss => {
        subCompAvg += Number(nextProps.getSubCompetency.result[itemss].totallPerc)
        count++;
      })
      console.log("LENGTH:", count);
      var avg;
      if (subCompAvg == 0) {
        avg = 0;
      }
      else {
        avg = Math.round(subCompAvg / count);
      }
      this.setState({
        subCompPerc: avg,
        subcompetencyLoader: false
      })
    }


    else if (this.props.dmeCategorised != nextProps.dmeCategorised) {
        debugger;
        var tempArrayData = [];
        var objectKeys = [];
        var check = Object.keys(nextProps.dmeCategorised).length > 0;
        debugger;
        if (Object.keys(nextProps.dmeCategorised.comptwiseData).length > 0) {
            objectKeys = Object.keys(nextProps.dmeCategorised.comptwiseData[this.state.mainCompetency[0].competency_name]);
            debugger;
            objectKeys.map((item, index) => {
                tempArrayData.push({
                    "competency": item,
                    "Deficient": nextProps.dmeCategorised.comptwiseData[this.state.mainCompetency[0].competency_name][item]['D'].totall,
                    "Matching": nextProps.dmeCategorised.comptwiseData[this.state.mainCompetency[0].competency_name][item]['M'].totall,
                    "Exceeding": nextProps.dmeCategorised.comptwiseData[this.state.mainCompetency[0].competency_name][item]['E'].totall
                })
            })
            
            console.log("HAMZA is on:", tempArrayData)
            console.log("HAMZA Object Keys:", Object.keys(nextProps.dmeCategorised.comptwiseData[this.state.mainCompetency[0].competency_name]))
            setTimeout(() => {
                this.setState({
                    respondentsCatogorizeDMEDataApi: {
                        data: tempArrayData,
                        keys: ['Deficient', 'Matching', 'Exceeding'],
                        indexBy: 'competency'
                    },
                    competencyName: this.state.mainCompetency[0].competency_name,
                    dmeCategorisedHideGraph: false
                },
                    () => {
                        console.log(this.state.respondentsCatogorizeDMEDataApi);
                    })

            }, 200)
        }
        else {
            debugger;
            this.setState({
                dmeCategorisedHideGraph: true
            })
        }
    }
        
    else if (this.props.candidateDistribution != nextProps.candidateDistribution) {
      var divisionsArray = [];

      nextProps.candidateDistribution.branchDivisions.map((division, index) => {

        var sectionsArray = [];

        nextProps?.candidateDistribution?.[division.DIVISION]?.sections.map((section, indexx) => {

          var designationsArray = [];

          nextProps.candidateDistribution[division.DIVISION][section.SECTION].DESIGNATION.map((designation, indexxx) => {
            designationsArray.push({
              "name": designation.DESIGNATION,
              "sample": nextProps.candidateDistribution[division.DIVISION][section.SECTION][designation.DESIGNATION].totall
            })

          })

          sectionsArray.push({
            "name": section.SECTION,
            "children": designationsArray
          })

        })

        divisionsArray.push({
          "name": division.DIVISION,
          "children": sectionsArray
        });

      })
      if(divisionsArray.length > 0){
        if(divisionsArray[0].children.length > 0){
          setTimeout(() => {
            this.setState({
              divisionSectionDataApi: {
                "name": this.state.branchName,
                "color": "hsl(241, 70%, 50%)",
                "children": divisionsArray
              }
            },
              () => {
                console.log(this.state.divisionSectionDataApi);
              })
    
          }, 500);
        }
        else{
          debugger;
          this.setState({
            divisionSectionDataApi : 'No Record'
          })
        }
      }
      else{
        debugger;
          this.setState({
            divisionSectionDataApi : 'No Record'
          })
      }
    }


  }


  toggleBranchesBar = () => {
    this.setState({
      branchesListToggle: !this.state.branchesListToggle
    }, () => {
      // if(this.state.branchesListToggle) {
      //   $('body').css({overflow: 'hidden'})
      // }else{
      //   $('body').css({overflow: ''})
      // }
    })
  }

  toggleModalInfo = () => {
    this.setState({
      integratedFormId: "ODY5Mg==",
      integratedFormToggle: true
    }, () => {

      // var randomEmail = parseInt(Math.random()*5000 + 10) + "@yopmail.com";
      // var randomPhone = parseInt(Math.random()*100000 + 200);

      this.setState({
        // formSource : "https://form.assessmentfund.com/"+ this.state.integratedFormId +"?nested=true&email="+ randomEmail +"&phone="+ randomPhone +"&name=DashboardTalentMapUser"
        formSource: "https://form.assessmentfund.com/" + this.state.integratedFormId
      }, () => {
        console.log(this.state.formSource);
      })

    })
  }

  toggleModalChat = () => {
    this.setState({
      integratedFormId: "ODY5Mw==",
      integratedFormToggle: true
    }, () => {

      // var randomEmail = parseInt(Math.random()*5000 + 10) + "@yopmail.com";
      // var randomPhone = parseInt(Math.random()*100000 + 200);

      this.setState({
        // formSource : "https://form.assessmentfund.com/"+ this.state.integratedFormId +"?nested=true&email="+ randomEmail +"&phone="+ randomPhone +"&name=DashboardTalentMapUser"
        formSource: "https://form.assessmentfund.com/" + this.state.integratedFormId
      }, () => {
        console.log(this.state.formSource);
      })

    })
  }

  closeModalForm = () => {
    this.setState({
      integratedFormToggle: false,
      formSource: ""
    })
  }

  toggleTooltip = (key) => {
    var tempArrayTooltip = this.state.tooltipOpen;
    tempArrayTooltip[key] = !tempArrayTooltip[key];
    this.setState({
      tooltipOpen: tempArrayTooltip
    }, () => {
      // console.log(this.state.tooltipOpen);
    })
  };

  yearChange = (e) => {
    console.log(e.target.value);
    this.setState({
      selectedYear: e.target.value
    }, () => {
      this.props.triggerYearChange(this.state.selectedYear);
    })
  }


  categorizedDMEChange = (branchIndex, competencyIndex) => {
    this.setState({
        respondentsCatogorizeDMEDataApi: {
            data: []
        },
        competencyName: this.state.mainCompetency[competencyIndex].competency_name
    })
    var tempArrayData = [];
    var objectKeys = [];
    debugger;
    objectKeys = Object.keys(this.props.dmeCategorised.comptwiseData[this.state.mainCompetency[competencyIndex].competency_name]);
    objectKeys.map((item, index) => {
        tempArrayData.push({
            "competency": item,
            "Deficient": this.props.dmeCategorised.comptwiseData[this.state.mainCompetency[competencyIndex].competency_name][item]['D'].totall,
            "Matching": this.props.dmeCategorised.comptwiseData[this.state.mainCompetency[competencyIndex].competency_name][item]['M'].totall,
            "Exceeding": this.props.dmeCategorised.comptwiseData[this.state.mainCompetency[competencyIndex].competency_name][item]['E'].totall
        })
    })
    debugger;
    console.log("HAMZA is on:", tempArrayData)
    console.log("HAMZA Object Keys:", Object.keys(this.props.dmeCategorised.comptwiseData[this.state.mainCompetency[competencyIndex].competency_name]))
    setTimeout(() => {
        this.setState({
            respondentsCatogorizeDMEDataApi: {
                data: tempArrayData,
                keys: ['Deficient', 'Matching', 'Exceeding'],
                indexBy: 'competency'
            }
        },
            () => {
                console.log(this.state.respondentsCatogorizeDMEDataApi);
            })

    }, 200)
}

  
  render() {
    const { branches, allBranches, branchIndex, competencyIndex, divisionIndex, sectionIndex, archetypesDataChart, selectedBranchIdValue, branchName, totalDivision, totalAssesst, totalSection
      , mainCompetency, isMainCompetencyLoading, mainCompetencyAverage, allDivisions, allSections, selectedYear } = this.state;

    var subCompetenciesDataArrayAPI = []; // for sub competencies with average bar chart with API

    this.state.subCompetency.map((comp, key) => {

      var averageValue = comp.totallPerc;

      subCompetenciesDataArrayAPI.push({
        score: Math.round(averageValue),
        value: comp.name
      })


    });
    var subCompetenciesDataArray = []; // for sub competencies with average bar chart

    branches[branchIndex].mainCompetencies[competencyIndex].subCompetencies.map((comp, key) => {

      var averageValue = comp.average;

      subCompetenciesDataArray.push({
        score: averageValue,
        value: comp.name
      })


    });

    var subCompetenciesDataArrayCountAPI = []; // for sub competencies with average bar chart API

    this.state.subCompetency.map((comp, key) => {

      var total = comp.totall;

      subCompetenciesDataArrayCountAPI.push({
        score: total,
        value: comp.name
      })


    });
    var subCompetenciesDataArrayCount = []; // for sub competencies with average bar chart 

    branches[branchIndex].mainCompetencies[competencyIndex].subCompetencies.map((comp, key) => {

      var total = parseInt(comp.d_total + comp.m_total + comp.e_total);

      subCompetenciesDataArrayCount.push({
        score: total,
        value: comp.name
      })


    });
    console.log("MAP STATE", this.state.divisionSectionDataApi);
    console.log("HASEEB STATE", this.state.divisionSectionData);
    console.log("TREE", this.state.respondentsCatogorizeDMEDataApi);
    return (
      <div className="PagcorStatsComponent">
        {/* <Header /> */}

        <div>

          <Container fluid>
            <Row style={{ padding: '3vh 0' }}>
              <Col style={{ padding: '6vh 0' }} md={{ size: 6 }} className="text-right">
                <h5>Select the year to see the competency assessment talent map - </h5>
              </Col>
              <Col style={{ padding: '5vh 0' }} md={{ size: 6 }} className="text-left">
                <Button className={selectedYear == '2019' ? "yearSelected" : "yearButton"} value="2019" onClick={this.yearChange}>2019</Button>
                <Button className={selectedYear == '2020' ? "yearSelected" : "yearButton"} value="2020" onClick={this.yearChange}>2020</Button>
                <Link to="/pagcor/AllBranchesStatsCompare">
                  <Button className={"yearButton"} >Comparision Year</Button>
                </Link>
                <Link to="/pagcor/talentmap">
                  <Button className={"yearButton"} >Go Back</Button>
                </Link>
              </Col>
              <Col style={{ padding: '1vh 0' }} md={{ size: 12 }} className="text-center">
                <h5>ALL BRANCHES DATA</h5>
              </Col>

              {/* section for mayer information and city additional info - starts here */}
              <Col md={{ size: 12 }}>
                <div className="chartBox">
                  <div>
                    <Row style={{ padding: '25px' }}>

                      <Col md={{ size: 4 }}>
                        <div className="schoolBox topSchoolBox">
                          <Row>
                            <Col md={{ size: 12 }}>
                              <div style={{ verticalAlign: 'middle', padding: '0vh 0' }}>
                        
                                 <img src={branchicon} />
                                <p style={{ margin: 0, color: '#7a8596', position: 'relative', top: '8px' }}>Branch</p>
                                <h3 className="cityName">All Branches</h3>
                              </div>
                            </Col>
                          </Row>
                          {/* <p className="titleTag">City</p> */}
                        </div>
                      </Col>

                      <Col md={{ size: 4 }}>
                        <div className="schoolBox topSchoolBox">
                          <Row>
                            <Col md={{ size: 12 }}>
                              <div style={{ verticalAlign: 'middle', padding: '0vh 0' }}>
                                <div className="circleBox imageBack">
                                  <FontAwesomeIcon className="iconHere" icon={faMale} size="4x" />
                                  <FontAwesomeIcon className="iconHere" icon={faFemale} size="4x" />
                                </div>
                                <p style={{ margin: 0, color: '#7a8596', position: 'relative', top: '8px' }}>People assessed</p>
                                {totalAssesst == 'loading' ? <Spinner className="citySpinner mt-2" size="sm" color="light" /> :
                                  <h3 className="cityName">{totalAssesst}</h3>}
                              </div>
                            </Col>
                          </Row>
                          {/* <p className="titleTag">Mayer</p> */}
                        </div>
                      </Col>

                      <Col md={{ size: 4 }}>
                        <div className="schoolBox topSchoolBox">
                          <Row>
                            <Col>
                              <h3 className="cityName"></h3>
                              <div className="oneInfo">
                                <div className="iconBox">
                                  {/* <FontAwesomeIcon className="iconHere" icon={faMale} size="4x" />
                                        <FontAwesomeIcon className="iconHere" icon={faFemale} size="4x" /> */}
                                  <img src={devisionicon} />
                                </div>
                                <p style={{ color: '#7a8596' }}>Divisions</p>
                                {totalDivision == 'loading' ? <Spinner className="citySpinner mt-2" size="sm" color="light" /> :
                                  <h3>{totalDivision}</h3>}
                              </div>
                              <div className="oneInfo">
                                <div className="iconBox">
                                  {/* <FontAwesomeIcon className="iconHere" icon={faSchool} size="4x" /> */}
                                  <img src={sectionicon} />
                                </div>
                                <p style={{ color: '#7a8596', maxWidth: "140px" }}>Sections</p>
                                {totalSection == 'loading' ? <Spinner className="citySpinner mt-2" size="sm" color="light" /> :
                                  <h3>{totalSection}</h3>}
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </Col>



                    </Row>



                  </div>
                </div>
              </Col>
              {/* section for mayer information and city additional info - ends here */}







              <Col md={{ size: 12 }}>
                <div className="chartBox">
                  <div className="titleHead">
                    <Row>
                      <Col md={{ size: 8 }}>
                        <h5>Overview of competencies in {branchName} </h5>
                      </Col>

                      <Col style={{ textAlign: 'right' }} md={{ size: 4 }}>

                        <div className="quickIcons">
                          <FontAwesomeIcon className="customIcon" icon={faCalendarCheck} size="1x" onClick={e => this.toggleModalInfo()} />
                          <FontAwesomeIcon className="customIcon" icon={faComment} size="1x" onClick={e => this.toggleModalChat()} />
                        </div>

                        {/* <Switch
                                checked={this.state.situationsAvgDataLoc == 1 ? true : false}
                                onChange={e => this.setState({ situationsAvgDataLoc : this.state.situationsAvgDataLoc == 0 ? 1 : 0 }) }
                                uncheckedIcon={
                                  <div className="switchLabel">
                                    {this.state.selectedRegion}
                                  </div>
                                }
                                checkedIcon={
                                  <div className="switchLabel">
                                    All Cities
                                  </div>
                                }
                                className={this.state.situationsAvgDataLoc == 0 ? 'react-switch medium lowest' : 'react-switch medium highest'}
                                id="icon-switch"
                              /> */}
                      </Col>
                    </Row>
                  </div>
                  <div>
                    <Row style={{ padding: '25px' }}>

                      <Col md={{ size: 6 }}>
                        <div className="schoolBox">
                          <Row>
                            <Col md={{ size: 7 }}>
                              <div style={{ verticalAlign: 'middle', padding: '5vh 0' }}>
                                <div className="circleBox">
                                  <h2>{mainCompetency.length}</h2>
                                  <p>*Main competencies tested for<br />the <strong>{branchName}</strong></p>
                                  <div className="smallCircle">
                                    {mainCompetencyAverage}<span>%</span>
                                    <p>*Average<br />score</p>
                                  </div>
                                </div>
                              </div>
                            </Col>
                            <Col md={{ size: 5 }} style={{ textAlign: 'left' }}>

                              <div style={{ color: '#fff', position: 'relative', top: '50%', transform: 'translateY(-50%)' }}>
                                {isMainCompetencyLoading ? <div className="text-center"><Spinner className="" size="lg" color="light" /> </div> :

                                  mainCompetency.map((m_competency, key) => {

                                    var isThisOne = false;
                                    if (key == competencyIndex) { // compare with skill id from url
                                      isThisOne = true;
                                    }

                                    return (
                                      <span style={{
                                        opacity: !isThisOne && this.state.competencyUpdateLoader ? '0.4' : '1'
                                      }} key={key} className={isThisOne ? 'skillHere highlighted' : 'skillHere'} onClick={e => this.updateCompetency(m_competency, key)}>
                                        {
                                          isThisOne && this.state.competencyUpdateLoader ?
                                            <Spinner className="skillSpinner" size="sm" color="light" />
                                            : null
                                        }
                                        {m_competency.competency_name}
                                      </span>
                                    )
                                  })
                                }

                              </div>
                            </Col>
                            {/* <Col className="bottomLabel" md={{size: 12}}>
                                      <p>* Skills recommended by the World Bank<br />for 21st century workforce</p>
                                  </Col> */}
                          </Row>
                        </div>
                      </Col>

                      <Col md={{ size: 6 }}>
                        <div className="schoolBox">
                          <Row>
                            <Col md={{ size: 6 }}>
                              <div style={{ verticalAlign: 'middle', padding: '7vh 0' }}>
                                <div className="circleBox2" style={{ width: '280px', height: '280px' }}>
                                  <h2 style={{ lineHeight: '190px' }}>{this.state.subCompetency.length}</h2>
                                  <p>*Competencies tested in
                                          <br />
                                    <strong style={{ fontSize: '18px' }}>{this.state.competencyName}</strong>
                                  </p>
                                  {/* animated infinite bounce delay-2s */}
                                  <div className="smallCircle">
                                    {this.state.subCompPerc}<span>%</span>
                                    <p>*Average<br />score</p>
                                  </div>
                                </div>
                              </div>
                            </Col>
                            <Col md={{ size: 6 }} style={{ textAlign: 'left', paddingRight: '0', position: 'relative', zIndex: '999' }}>
                              {this.state.subcompetencyLoader ? <div style={{ color: '#fff', position: 'relative', top: '50%', transform: 'translateY(-50%)' }}>
                                <div className="text-center"><Spinner className="" size="lg" color="light" /> </div>
                              </div> :
                                <div className="situationsParent">

                                  {
                                    this.state.subCompetency.map((competency, key) => {

                                      // var isSkill = false;
                                      var dotColor = "red";
                                      var averageValue = competency.totallPerc;

                                      if (averageValue >= 80 && averageValue <= 89) {
                                        dotColor = "yellow";
                                      }
                                      if (averageValue >= 90 && averageValue <= 100) {
                                        dotColor = "#2cf32c";
                                      }

                                      return (
                                        <div key={key} className='small skillHere'>
                                          {competency.name}
                                          {
                                            averageValue != 0 ?
                                              <span className="dot" style={{ background: dotColor, color: dotColor }}>
                                                <span className="dotText">
                                                  {competency.totallPerc}
                                                  <span style={{ fontSize: '10px' }}>%</span>
                                                </span>
                                              </span>
                                              : null
                                          }
                                        </div>
                                      )
                                    })
                                  }
                                </div>
                              }

                            </Col>
                            <Col className="bottomLabel" md={{ size: 12 }}>

                              <p>* Average percentage of required<br />competencies met in {this.state.competencyName} competency</p>

                              {/* {
                                        this.state.situationsAvgDataLoc == 0 ?
                                          <p className="contactForCity" onClick={e => this.toggleModalInfo()}>* This graph represents partial data for this city. <span>Contact us</span> for the complete dataset for this city.</p>
                                        : null
                                      } */}

                            </Col>
                          </Row>
                        </div>
                      </Col>

                    </Row>

                    <Row style={{ padding: '0 15px 15px 15px' }} className="competencyFramworkChartBox">
                      <Col md={{ size: 12 }}>
                        <h5 style={{ marginTop: '20px' }}>This map depicts the average percentage score for each of the four main competency for {branchName}</h5>
                        <div style={{ height: 'calc(60vh - 60px)' }}>
                          {
                            this.state.mapCompetencyonTree && this.state.mapCompetencyonTree != '' && this.state.mapCompetencyonTree != 'No Record' ?
                              <ResponsiveTreeMap
                                root={this.state.mapCompetencyonTree}
                                identity="fullname"
                                value="totalSample"
                                tile="binary"
                                innerPadding={10}
                                outerPadding={10}
                                margin={{ top: 10, right: 10, bottom: 10, left: 10 }}
                                label={(e) => { return e.name + " - " + e.averageScore + "%" }}
                                // labelFormat=".0s"
                                labelSkipSize={10}
                                labelTextColor={{ theme: 'background' }}
                                colors={["#172740", "#0d1623", "#a6cee3"]}
                                // colors={{ scheme: 'purpleRed_green' }}
                                borderColor={{ from: 'color', modifiers: [['darker', 0.3]] }}
                                animate={true}
                                motionStiffness={90}
                                motionDamping={11}
                              />
                              : this.state.mapCompetencyonTree == 'No Record' ? <h5 className="mt-5 text-danger">No Record Found</h5> :  <div className="customSpinnerChart">
                                <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                              </div>}
                        </div>

                      </Col>
                    </Row>

                  </div>
                </div>
              </Col>




              <Col md={{ size: 12 }}>
                <div className="chartBox">
                  <div style={{ height: 'auto' }}>

                    <Row style={{ padding: '25px' }}>

                      

                      <Col md={{ size: 12 }}>
                        <div className="chartBox" style={{ border: '1px #0544a2 solid', borderRadius: '0' }}>
                          <div className="titleHead" style={{ background: '#0b2548', borderBottom: '1px #0544a2 solid' }}>
                            <Row>
                              <Col md={{ size: 12 }}>
                                <h5>Candidates' distribution in divisions, sections & designations in <strong>{branchName}</strong></h5>
                              </Col>
                              {/* <Col style={{textAlign: 'right'}} md={{ size : 5 }}>
                                
                                <div className="quickIcons">
                                  <FontAwesomeIcon className="customIcon" icon={faCalendarCheck} size="1x" onClick={e => this.toggleModalInfo()} />
                                  <FontAwesomeIcon className="customIcon" icon={faComment} size="1x" onClick={e => this.toggleModalChat()} />
                                </div>
                                
                                <Switch
                                  checked={this.state.userTypeScoresDataPie1Loc == 1 ? true : false}
                                  onChange={e => this.setState({ userTypeScoresDataPie1Loc : this.state.userTypeScoresDataPie1Loc == 0 ? 1 : 0 }) }
                                  uncheckedIcon={
                                    <div className="switchLabel">
                                      {this.state.selectedRegion}
                                    </div>
                                  }
                                  checkedIcon={
                                    <div className="switchLabel">
                                      All Cities
                                    </div>
                                  }
                                  className={this.state.userTypeScoresDataPie1Loc == 0 ? 'react-switch medium lowest' : 'react-switch medium highest'}
                                  id="icon-switch"
                                />
                            </Col> */}
                            </Row>
                          </div>
                          <div>
                            {/* {
                            this.state.skillUpdateLoader ? 
                              <div className="customSpinnerChart">
                                
                                <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                              </div>
                            : null
                          } */}

                            <Row>
                              {/* <Col md={{ size : 12 }}>
                              <h2>asdadasd</h2>
                            </Col> */}
                              <Col md={{ size: 12 }} style={{ height: '660px', background: '#05162d', position: 'relative', overflow: 'hidden' }}>

                                <p style={{ color: '#7ba2dc', textAlign: 'left', padding: '15px 20px 0px 20px' }}>
                                  The following representation provides in-depth view of the volume of people assessed in {branchName}. This is further broken down to give a complete overview of volume of people assessed in each Division, Section and Position for {branchName}.
                              </p>
                                {
                                  this.state.divisionSectionDataApi && this.state.divisionSectionDataApi  != '' && this.state.divisionSectionDataApi  != 'No Record' ?
                                    <ResponsiveBubble
                                      root={this.state.divisionSectionDataApi}
                                      margin={{ top: 30, right: 20, bottom: 130, left: 20 }}
                                      identity="name"
                                      value="sample"
                                      colors={{ scheme: 'paired' }}
                                      // colors={["#f1e15b", "#1f78b4"]}
                                      padding={6}
                                      label="value"
                                      labelTextColor={{ from: 'color', modifiers: [['darker', 0.8]] }}
                                      borderWidth={2}
                                      borderColor={{ from: 'color' }}
                                      defs={[
                                        {
                                          id: 'lines',
                                          type: 'patternLines',
                                          background: 'none',
                                          color: 'inherit',
                                          rotation: -45,
                                          lineWidth: 5,
                                          spacing: 8
                                        }
                                      ]}
                                      // fill={[ { match: { depth: 1 }, id: 'lines' } ]}
                                      animate={true}
                                      motionStiffness={90}
                                      motionDamping={12}
                                    /> : this.state.divisionSectionDataApi  == 'No Record' ? <h6 className="mt-5 text-danger">No Record Found - No Employee is assigned in any Section</h6> :
                                    <div className="customSpinnerChart">
                                      <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                                    </div>}
                              </Col>
                            </Row>

                          </div>
                        </div>
                      </Col>
                    </Row>


                  </div>
                </div>
              </Col>


              <Col md={{ size: 6 }}>
                <div className="chartBox">
                  <div className="titleHead">
                    <Row>
                      <Col md={{ size: 8 }}>

                        <h5>Avg. score for {subCompetenciesDataArrayAPI.length} competencies assessed within <strong>{this.state.competencyName}</strong></h5>

                      </Col>
                      <Col style={{ textAlign: 'right' }} md={{ size: 4 }}>

                        {/* <div className="quickIcons">
                                <FontAwesomeIcon className="customIcon" icon={faCalendarCheck} size="1x" onClick={e => this.toggleModalInfo()} />
                                <FontAwesomeIcon className="customIcon" icon={faComment} size="1x" onClick={e => this.toggleModalChat()} />
                              </div> */}

                        <select className="customDropDown" style={{ top: 0 }} onChange={e => {
                          // this.updateCompetency(branches[branchIndex].mainCompetencies[e.target.value].name, e.target.value);
                          debugger;
                          this.updateCompetencyFromDropDown(e.target.value)
                        }}>
                          {
                            mainCompetency.map((comp, key) =>
                              <option key={key} value={key} selected={key == this.state.competencyIndex}>{comp.competency_name}</option>
                            )
                          }
                        </select>
                      </Col>
                    </Row>
                  </div>
                  <div style={{ height: subCompetenciesDataArrayAPI.length > 15 ? '940px' : '440px', position: 'relative' }}>
                    {
                      this.state.subcompetencyLoader ?
                        <div className="customSpinnerChart">
                          <Spinner style={{ width: '3rem', height: '3rem' }} size="lg" color="light" />
                        </div> :
                        subCompetenciesDataArrayAPI.length == 0 ? <h5 className="mt-5 text-danger">No Record Found</h5>  :
                        <ResponsiveBar
                          data={subCompetenciesDataArrayAPI}
                          keys={['score']}
                          indexBy="value"
                          margin={{ top: 30, right: 40, bottom: 60, left: 400 }}
                          padding={0.2}
                          innerPadding={5}
                          label={d => `${d.value}%`}
                          // onClick={e => this.barChartClicked(e)}
                          groupMode="stacked"
                          // colors={{ scheme: 'paired' }}
                          colors={["#d63333", "#f706d5"]}
                          layout="horizontal"
                          enableGridY={false}
                          defs={[
                            {
                              id: 'dots',
                              type: 'patternDots',
                              background: 'inherit',
                              color: '#38bcb2',
                              size: 4,
                              padding: 1,
                              stagger: true
                            },
                            {
                              id: 'lines',
                              type: 'patternLines',
                              background: 'inherit',
                              color: '#eed312',
                              rotation: -45,
                              lineWidth: 6,
                              spacing: 10
                            }
                          ]}
                          fill={[
                            {
                              match: {
                                id: 'fries'
                              },
                              id: 'dots'
                            },
                            {
                              match: {
                                id: 'sandwich'
                              },
                              id: 'lines'
                            }
                          ]}
                          borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                          axisTop={null}
                          axisRight={null}
                          axisBottom={{
                            tickSize: 5,
                            tickPadding: 5,
                            tickRotation: 0,
                            legend: '',
                            legendPosition: 'middle',
                            legendOffset: 50
                          }}
                          axisLeft={{
                            tickSize: 5,
                            tickPadding: 5,
                            tickRotation: 0,
                            legend: '',
                            legendPosition: 'middle',
                            legendOffset: -60
                          }}
                          labelSkipWidth={12}
                          labelSkipHeight={12}
                          labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                          // legends={[
                          //     {
                          //         dataFrom: 'keys',
                          //         anchor: 'top-right',
                          //         direction: 'column',
                          //         justify: false,
                          //         translateX: -15,
                          //         translateY: -40,
                          //         itemsSpacing: 2,
                          //         itemWidth: 100,
                          //         itemHeight: 25,
                          //         itemDirection: 'left-to-right',
                          //         itemOpacity: 0.85,
                          //         symbolSize: 20,
                          //         effects: [
                          //             {
                          //                 on: 'hover',
                          //                 style: {
                          //                     itemOpacity: 1
                          //                 }
                          //             }
                          //         ]
                          //     }
                          // ]}
                          animate={true}
                          motionStiffness={90}
                          motionDamping={15}
                        />
                    }

                    {/* {
                            this.state.situationsAvgDataLoc == 0 ?
                              <p style={{fontSize: '12px', color: '#435f84', left: '5px'}} className="contactForCity" onClick={e => this.toggleModalInfo()}>* This graph represents partial data for this city. <span>Contact us</span> for the complete dataset for this city.</p>
                            : null
                          } */}

                  </div>
                </div>
              </Col>

              <Col md={{ size: 6 }}>
                <div className="chartBox">

                  <div className="titleHead">
                    <Row>
                      <Col md={{ size: 8 }}>

                        <h5>People assessed for {subCompetenciesDataArrayCountAPI.length} sub-competencies within <strong>{this.state.competencyName}</strong></h5>

                      </Col>
                      <Col style={{ textAlign: 'right' }} md={{ size: 4 }}>

                        <select className="customDropDown" style={{ top: 0 }} onChange={e => {
                          // this.updateCompetency(branches[branchIndex].mainCompetencies[e.target.value].name, e.target.value);
                          this.updateCompetencyFromDropDown(e.target.value)
                        }}>
                          {
                            mainCompetency.map((comp, key) =>
                              <option key={key} value={key} selected={key == this.state.competencyIndex}>{comp.competency_name}</option>
                            )
                          }
                        </select>
                      </Col>
                    </Row>
                  </div>

                  <div style={{ height: subCompetenciesDataArrayCountAPI.length > 15 ? '940px' : '440px', position: 'relative' }}>
                    {
                      this.state.subcompetencyLoader ?
                        <div className="customSpinnerChart">
                          <Spinner style={{ width: '3rem', height: '3rem' }} size="lg" color="light" />
                        </div>
                         : subCompetenciesDataArrayCountAPI.length == 0 ? <h5 className="mt-5 text-danger">No Record Found</h5> :
                        <ResponsiveBar
                          data={subCompetenciesDataArrayCountAPI}
                          keys={['score']}
                          indexBy="value"
                          margin={{ top: 30, right: 40, bottom: 60, left: 400 }}
                          padding={0.2}
                          innerPadding={5}
                          label={d => `${d.value}`}
                          // onClick={e => this.barChartClicked(e)}
                          groupMode="stacked"
                          // colors={{ scheme: 'paired' }}
                          colors={["#1f78b4", "#f706d5"]}
                          layout="horizontal"
                          enableGridY={false}
                          defs={[
                            {
                              id: 'dots',
                              type: 'patternDots',
                              background: 'inherit',
                              color: '#38bcb2',
                              size: 4,
                              padding: 1,
                              stagger: true
                            },
                            {
                              id: 'lines',
                              type: 'patternLines',
                              background: 'inherit',
                              color: '#eed312',
                              rotation: -45,
                              lineWidth: 6,
                              spacing: 10
                            }
                          ]}
                          fill={[
                            {
                              match: {
                                id: 'fries'
                              },
                              id: 'dots'
                            },
                            {
                              match: {
                                id: 'sandwich'
                              },
                              id: 'lines'
                            }
                          ]}
                          borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                          axisTop={null}
                          axisRight={null}
                          axisBottom={{
                            tickSize: 5,
                            tickPadding: 5,
                            tickRotation: 0,
                            legend: '',
                            legendPosition: 'middle',
                            legendOffset: 50
                          }}
                          axisLeft={{
                            tickSize: 5,
                            tickPadding: 5,
                            tickRotation: 0,
                            legend: '',
                            legendPosition: 'middle',
                            legendOffset: -60
                          }}
                          labelSkipWidth={12}
                          labelSkipHeight={12}
                          labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                          animate={true}
                          motionStiffness={90}
                          motionDamping={15}
                        />
                    }
                  </div>
                </div>
              </Col>


              <Col md={{ size: 12 }}>
                <div className="chartBox">
                  <div className="titleHead">
                    <Row>
                      <Col md={{ size: 8 }}>
                        {/* <h5>Candidates' distribution in <strong>{ branches[branchIndex].mainCompetencies[competencyIndex].name }</strong> competency based on Deficient, Matching and Exceeding (DME)</h5> */}
                        <h5>Average score comparison based on Deficient, Matching and Exceeding (DME)</h5>
                      </Col>
                      <Col md={{ size: 4 }}>
                        <div className="quickIcons" style={{ right: '15px' }}>
                          <FontAwesomeIcon className="customIcon" icon={faCalendarCheck} size="1x" onClick={e => this.toggleModalInfo()} />
                          <FontAwesomeIcon className="customIcon" icon={faComment} size="1x" onClick={e => this.toggleModalChat()} />
                        </div>
                      </Col>
                    </Row>
                  </div>
                    <Row>
                    <Col md={{ size: 8, offset: 1 }}>
                                <p style={{ color: '#fff', textAlign: 'left', marginBottom: '20px', paddingTop: '15px' }}>
                                    The graph below shows the frequency of Candidates in {this.state.branchName} whose scores for {this.state.competencyName} competencies, in comparison to the required level, are either deficient, matching or exceeding the benchmark.
                    </p>
                            </Col>

                            <Col md={{ size: 2 }}>
                                <select className="customDropDown" onChange={e => {
                                    this.setState({
                                        competencyIndexs: e.target.value
                                    }, () => {
                                        // this.DMEDataMapping(this.state.branchIndex, this.state.competencyIndex);
                                        this.categorizedDMEChange(this.state.branchIndex, this.state.competencyIndexs);
                                    })
                                }}>
                                    {
                                        this.state.mainCompetency.map((comp, key) =>
                                            <option key={key} value={key} selected={key == this.state.competencyIndexs}>{comp.competency_name}</option>
                                        )
                                    }
                                </select>
                            </Col>
                    <Col md={{ size: 7, offset: 3 }}>
                                <div style={{ height: this.state.respondentsCatogorizeDMEDataApi.data.length > 10 ? '1000px' : '400px' }}>
                                    {
                                        this.state.respondentsCatogorizeDMEDataApi && this.state.respondentsCatogorizeDMEDataApi.data.length != 0 ?
                                            <ResponsiveBar
                                                data={this.state.respondentsCatogorizeDMEDataApi.data}
                                                keys={this.state.respondentsCatogorizeDMEDataApi.keys}
                                                indexBy={this.state.respondentsCatogorizeDMEDataApi.indexBy}
                                                margin={{ top: 60, right: 0, bottom: 50, left: 100 }}
                                                padding={0.3}
                                                layout="horizontal"
                                                colors={{ scheme: 'set1' }}
                                                defs={[
                                                    {
                                                        id: 'dots',
                                                        type: 'patternDots',
                                                        background: 'inherit',
                                                        color: '#38bcb2',
                                                        size: 4,
                                                        padding: 1,
                                                        stagger: true
                                                    },
                                                    {
                                                        id: 'lines',
                                                        type: 'patternLines',
                                                        background: 'inherit',
                                                        color: '#eed312',
                                                        rotation: -45,
                                                        lineWidth: 6,
                                                        spacing: 10
                                                    }
                                                ]}
                                                fill={[
                                                    {
                                                        match: {
                                                            id: 'fries'
                                                        },
                                                        id: 'dots'
                                                    },
                                                    {
                                                        match: {
                                                            id: 'sandwich'
                                                        },
                                                        id: 'lines'
                                                    }
                                                ]}
                                                borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                                                axisTop={null}
                                                axisRight={null}
                                                // axisBottom={{
                                                //     tickSize: 5,
                                                //     tickPadding: 5,
                                                //     tickRotation: 0,
                                                //     legend: 'country',
                                                //     legendPosition: 'middle',
                                                //     legendOffset: 32
                                                // }}
                                                // axisLeft={{
                                                //     tickSize: 5,
                                                //     tickPadding: 5,
                                                //     tickRotation: 0,
                                                //     legend: 'food',
                                                //     legendPosition: 'middle',
                                                //     legendOffset: -40
                                                // }}
                                                labelSkipWidth={12}
                                                labelSkipHeight={12}
                                                labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                                                legends={[
                                                    {
                                                        dataFrom: 'keys',
                                                        anchor: 'top-left',
                                                        direction: 'row',
                                                        justify: false,
                                                        translateX: -250,
                                                        translateY: -50,
                                                        itemsSpacing: 2,
                                                        itemWidth: 110,
                                                        itemHeight: 20,
                                                        itemDirection: 'left-to-right',
                                                        itemOpacity: 0.85,
                                                        symbolSize: 20,
                                                        effects: [
                                                            {
                                                                on: 'hover',
                                                                style: {
                                                                    itemOpacity: 1
                                                                }
                                                            }
                                                        ]
                                                    }
                                                ]}
                                                animate={true}
                                                motionStiffness={90}
                                                motionDamping={15}
                                            />
                                            : <div className="customSpinnerChart">
                                                <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                                            </div>
                                    }
                                </div>
                            </Col>
                    </Row>

                    {/* <DMEProfileComponent
                      allData={{
                        branches: branches,
                        allBranches: allBranches,
                        branchIndex: branchIndex,
                        divisionIndex: divisionIndex,
                        sectionIndex: sectionIndex,
                        competencyIndex: competencyIndex,
                        selectedBranchIdValue: this.state.selectedBranchIdValue,
                        branchName: this.state.branchName,
                        allDivisions: this.state.allDivisions,
                        allSections: this.state.allSections,
                        dmeByDivision: this.props.dmeByDivision,
                        dmeBySection: this.props.dmeBySection,
                        mainCompetency: this.state.mainCompetency,
                        dmeCategorised: this.props.dmeCategorised,
                        selectedYear: this.state.selectedYear,
                        divisionHideGraph: this.state.divisionHideGraph,
                      }}
                    /> */}

                </div>
              </Col>


              {/* <Col md={{ size: 12 }}>

                      {
                        this.state.situations && this.state.situations.length ?
                          <DistributionAndComparisonTopCitiesComponent selectedRegion={this.state.selectedRegion} selectedSkill={this.state.selectedSkill} selectedSkillId={this.state.selectedSkillId} situations={this.state.situations} situationsAvg={this.state.situationsAvg} situationsAvgSpecificCity={this.state.situationsAvgSpecificCity} />
                        : null
                      }

                    </Col> */}



              <Col md={{ size: 12 }} style={{ display: 'none' }}>
                <div className="chartBox">
                  <div className="titleHead">
                    <Row>
                      <Col md={{ size: 7 }}>
                        <h5>Candidates of {branchName} were evaluated on each of the following Archetypes</h5>
                      </Col>
                      <Col style={{ textAlign: 'right' }} md={{ size: 5 }}>

                      </Col>
                    </Row>
                  </div>
                  <div>
                    <Row style={{ padding: '30px 50px' }}>

                      <Col md={{ size: 3 }}>
                        <Row>
                          {
                            this.state.archetypesList.map((archetype, key) =>
                              <Col key={key} md={{ size: 6 }} style={{ padding: 0 }}>
                                <div className="archetypeNameBox" id={"tooltip_" + key}>
                                  {
                                    archetype.name
                                  }
                                </div>
                                <Tooltip placement="right" isOpen={this.state.tooltipOpen[key]} target={"tooltip_" + key} toggle={e => this.toggleTooltip(key)} innerClassName="customTooltip">
                                  <p style={{ textAlign: 'left', padding: '10px', background: '#d63333', fontSize: '1rem' }}>
                                    {
                                      archetype.desc
                                    }
                                  </p>
                                </Tooltip>
                              </Col>
                            )
                          }
                        </Row>
                      </Col>

                      <Col md={{ size: 9 }}>
                        <Row style={{ padding: '0' }} className="competencyFramworkChartBox smallFont">
                          <Col md={{ size: 12 }} style={{ paddingRight: 0 }}>
                            <p style={{ color: '#7ba2dc', textAlign: 'left', padding: '12px 20px 0px 20px' }}>
                              The pattern of Archetypes across {branchName} can be seen in the following representation
                                  </p>
                            {
                              this.state.archetypesTreeMapData && this.state.archetypesTreeMapData != '' ?
                                <div style={{ height: 'calc(82vh)' }}>
                                  <ResponsiveTreeMap
                                    root={this.state.archetypesTreeMapData}
                                    identity="name"
                                    value="totalSample"
                                    tile="resquarify"
                                    innerPadding={2}
                                    outerPadding={2}
                                    margin={{ top: 10, right: 0, bottom: 10, left: 10 }}
                                    label={(e) => { return e.name }}
                                    // labelFormat=".0s"
                                    labelSkipSize={10}
                                    labelTextColor={{ theme: 'background' }}
                                    colors={["#0544a2", "#020a15", "#a6cee3"]}
                                    // colors={{ scheme: 'purpleRed_green' }}
                                    borderColor={{ from: 'color', modifiers: [['darker', 0.3]] }}
                                    animate={true}
                                    motionStiffness={90}
                                    motionDamping={11}
                                  />
                                </div>
                                : null
                            }
                          </Col>
                        </Row>
                      </Col>

                    </Row>



                    <Row>

                      <Col md={{ size: 10, offset: 1 }}>
                        <p style={{ color: '#7ba2dc', textAlign: 'center', padding: '15px 20px 0px 20px' }}>
                          The following framework represents the distribution of each Archetype across the Divisions in {branchName}.
                              </p>
                      </Col>

                      <Col md={{ size: 12 }}>
                        <div style={{ height: '500px', position: 'relative' }}>
                          {
                            archetypesDataChart.data && archetypesDataChart.data.length ?
                              <ResponsiveHeatMap
                                data={archetypesDataChart.data}
                                keys={archetypesDataChart.keys}
                                indexBy={archetypesDataChart.indexBy}
                                margin={{ top: 120, right: 60, bottom: 20, left: 280 }}
                                forceSquare={true}
                                axisTop={{ orient: 'top', tickSize: 15, tickPadding: 10, tickRotation: -40, legend: 'Archetypes', legendOffset: -110, legendPosition: 'middle' }}
                                axisRight={null}
                                axisBottom={null}
                                axisLeft={{
                                  orient: 'left',
                                  tickSize: 10,
                                  tickPadding: 5,
                                  tickRotation: 0,
                                  legend: 'Divisions',
                                  legendPosition: 'middle',
                                  legendOffset: -230
                                }}
                                cellOpacity={1}
                                cellBorderColor={{ from: 'color', modifiers: [['darker', 0.4]] }}
                                labelTextColor={{ from: 'color', modifiers: [['darker', 1.8]] }}
                                defs={[
                                  {
                                    id: 'lines',
                                    type: 'patternLines',
                                    background: 'inherit',
                                    color: 'rgba(0, 0, 0, 0.1)',
                                    rotation: -45,
                                    lineWidth: 4,
                                    spacing: 7
                                  }
                                ]}
                                fill={[{ id: 'lines' }]}
                                animate={true}
                                motionStiffness={80}
                                motionDamping={9}
                                hoverTarget="cell"
                                cellHoverOthersOpacity={0.25}
                              />
                              : null
                          }
                        </div>
                      </Col>

                    </Row>
                  </div>

                </div>
              </Col>







            </Row>
          </Container>
        </div>

        {/* <div className={this.state.branchesListToggle ? "citiesListBarContainer active" : 'citiesListBarContainer'}>
          <Container fluid>
            <Row style={{ textAlign: 'left' }}>
              {
                this.state.isBranchesLoading ?
                  <div className="customSpinnerChart">
                    <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                  </div>
                  :
                  <BranchesListSidebar selectedBranchId={selectedBranchIdValue} ></BranchesListSidebar>
              }

            </Row>
          </Container>

          <Button onClick={e => this.toggleBranchesBar()} className={this.state.branchesListToggle ? "citiesListBarSwitch opened" : "citiesListBarSwitch"} color="primary">
            {
              this.state.branchesListToggle ?
                <FontAwesomeIcon className="iconHere" icon={this.state.branchesListToggle ? faTimes : faBars} size="1x" />
                :
                <span>change<br />branch</span>
            }
          </Button>
        </div> */}

        <div className={this.state.integratedFormToggle ? "integratedFormSideBar active" : 'integratedFormSideBar'}>
          {
            this.state.formSource !== "" ?
              <iframe src={this.state.formSource}></iframe>
              : null
          }

          <Button onClick={e => this.closeModalForm()} className={this.state.integratedFormToggle ? this.state.integratedFormId == "ODY5Mg==" ? "citiesListBarSwitch green opened" : "citiesListBarSwitch yellow opened" : "citiesListBarSwitch"} color="primary">
            <FontAwesomeIcon className="iconHere" icon={faTimes} size="1x" />
          </Button>

        </div>

        {/* <Footer /> */}
      </div>
    );

  }
}

AllBranchesStats.propTypes = {
  getAllBranches: PropTypes.func.isRequired,
}

const mapStatesToProps = state => ({
  switchedBranch: state.webRed.switchedBranch,
  totalPeopleAssest: state.webRed.totalPeopleAssestAllBranches,
  mainCompetencies: state.webRed.mainCompetenciesAllBranches,
  getSubCompetency: state.webRed.getSubCompetencyBranches,
  getAllCompetencyAvg: state.webRed.getAllCompetencyAvg,
  divisionsAndSections: state.webRed.divisionsAndSections,
  competencyByDivision: state.webRed.competencyByDivision,
  competencyBySection: state.webRed.competencyBySection,
  dmeByDivision: state.webRed.dmeByDivision,
  dmeBySection: state.webRed.dmeBySection,
  dmeCategorised: state.webRed.dmeCategorisedAllBranches,
  candidateDistribution: state.webRed.candidateDistributionAllBranch,
  competencyYear: state.webRed.competencyYear
})

export default connect(mapStatesToProps, { getAllBranches, getTotalPeopleAssesstAllBranches, updateBranchAction, getMainCompetencyListAllBranches, getSubCompetencyRecordAllBranches, getAllCompetencyAvgRecord, getAllDivisions, competencyByDivisionRecord, competencyBySectionRecord, divisionDMERecord, sectionDMERecord, categorisedDMERecordAllBranches, candidateDistributionRecordAllBranches, triggerYearChange, branchAvgDMERecord })(AllBranchesStats);