import React from 'react';
import Page from './Page';

const MultiPage = ({ id,data,userName,date,month,year,time }) => {
    console.log('here is the data:',data);
    const getScore =(keyword)=>{
        if(keyword=='Rarely')
        {
            return 1
        }
        else if(keyword=='Sometimes'){
            return 2
        }
        else if(keyword=='Often'){
            return 3
        }
        else if(keyword=='Always'){
            return 4
        }
        else{
            return '--'
        }
    }
    console.log('in modal report data:',data);
    return (
        <Page id={id}><div className="container">
            
            <div className="row" id="printReport">
                <div className="col-md-12">
                    <h4>Assessment Report</h4>
                    <h5>{userName}</h5>
                    <label>{date} {month} {year}</label> <br />
                    <label>{time}</label>
                </div>
                <div className="col-md-12">
                        {
                         data && data.length > 0 && data.map((comp, key) =>
                        <React.Fragment>
                        <table className="table">
                        <thead className="headCol">
                            <tr className="vendorListHeading">
                                <th scope="col">{comp.compt_title} COMPETENCY</th>
                                <th scope="col">Self Rating</th>
                                <th scope="col">Supervisor Rating</th>
                                <th scope="col">Average Score</th>
                                <th scope="col">RPL</th>
                                <th scope="col">DME</th>
                                <th scope="col">{comp.percentage==null?"N/A":comp.percentage+"%"}</th>
                            </tr>
                        </thead>
                        </table>
                        <br />
                        {
                        typeof comp.subCompt !== 'undefined' && Array.isArray(comp.subCompt)==true && comp.subCompt.length > 0 ?
                            comp.subCompt.map((subComp,keySUb)=>{
                                return(
                                    <table className='table'>
                                        <React.Fragment>
                                                <thead class="thead-light">
                                                <tr>
                                                    <th scope="col">{subComp.sub_compt_title}</th>
                                                    <th scope="col">{subComp.stats_data[subComp.sub_compt_keyword+"_INC"]=='null'?'N/A':subComp.stats_data[subComp.sub_compt_keyword+"_INC"]}</th>
                                                    <th scope="col">{subComp.stats_data[subComp.sub_compt_keyword+"_SUP"]=="null"?'N/A':subComp.stats_data[subComp.sub_compt_keyword+"_SUP"]}</th>
                                                    <th scope="col">{subComp.stats_data[subComp.sub_compt_keyword+"_AVE"]=="null"?"N/A":subComp.stats_data[subComp.sub_compt_keyword+"_AVE"]}</th>
                                                    <th scope="col">{subComp.stats_data[subComp.sub_compt_keyword+"_RPL"]=='null'?"N/A":subComp.stats_data[subComp.sub_compt_keyword+"_RPL"]}</th>
                                                    <th scope="col">{subComp.stats_data[subComp.sub_compt_keyword+"_GAP"]=="null"?"N/A":subComp.stats_data[subComp.sub_compt_keyword+"_GAP"]}</th>
                                                    <th scope="col">{subComp.precentage=="null"?"N/A":Math.round(subComp.precentage)+"%"}</th>
                                                </tr>
                                            </thead>
                                            <br/>
                                            <tbody>
                                            {
                                                subComp.questions[0].status !='error' && (subComp.questions[0].result.length > 0 || Object.keys(subComp.questions[0].result).length > 0 ) ?
                                                Object.entries(subComp.questions[0].result).map((qes,key)=>{
                                                    //console.log(comp.compt_title,"here is the question:",qes)
                                                    return(
                                                        <tr>
                                                            <th scope="row">{qes[1].question_title}</th>
                                                            <td>{qes[1].answers.emloyee_answer==0?"--":getScore(qes[1].answers.emloyee_answer)}</td>
                                                            <td>{qes[1].answers.sup_answer==0?"--":getScore(qes[1].answers.sup_answer)}</td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                        </tr>
                                                        
                                                    )
                                                })
                                                
                                            :
                                            <tr>
                                                <th scope="row">--</th>
                                                <td>--</td>
                                                <td>--</td>
                                                <td>--</td>
                                                <td>--</td>
                                                <td>--</td>
                                                <td>--</td>
                                            </tr>
                                            }
                                        </tbody>
                                        </React.Fragment>
                                    </table>
                                    
                                )
                            })
                        :
                        typeof comp.subCompt !== 'undefined' && Object.keys(comp.subCompt).length > 0 && Object.entries(comp.subCompt).map((subComp,keySUb)=>{
                            return(
                                <table className="table changePage">
                                <React.Fragment>
                                    <thead class="thead-light">
                                    <tr>
                                        <th scope="col">{subComp[1].sub_compt_title}</th>
                                        <th scope="col">{subComp[1].stats_data[subComp[1].sub_compt_keyword+"_INC"]=='null'?'N/A':subComp[1].stats_data[subComp[1].sub_compt_keyword+"_INC"]}</th>
                                        <th scope="col">{subComp[1].stats_data[subComp[1].sub_compt_keyword+"_SUP"]=="null"?'N/A':subComp[1].stats_data[subComp[1].sub_compt_keyword+"_SUP"]}</th>
                                        <th scope="col">{subComp[1].stats_data[subComp[1].sub_compt_keyword+"_AVE"]=="null"?"N/A":subComp[1].stats_data[subComp[1].sub_compt_keyword+"_AVE"]}</th>
                                        <th scope="col">{subComp[1].stats_data[subComp[1].sub_compt_keyword+"_RPL"]=='null'?"N/A":subComp[1].stats_data[subComp[1].sub_compt_keyword+"_RPL"]}</th>
                                        <th scope="col">{subComp[1].stats_data[subComp[1].sub_compt_keyword+"_GAP"]=="null"?"N/A":subComp[1].stats_data[subComp[1].sub_compt_keyword+"_GAP"]}</th>
                                        <th scope="col">{subComp[1].precentage=="null"?"N/A":Math.round(subComp[1].precentage)+"%"}</th>
                                    </tr>
                                </thead>
                                <br/>
                                <tbody>
                                {
                                    subComp[1].questions[0].status !='error' && (subComp[1].questions[0].result.length > 0 || Object.keys(subComp[1].questions[0].result).length > 0 ) ?
                                    Object.entries(subComp[1].questions[0].result).map((qes,key)=>{
                                       // console.log(comp.compt_title,"here is the question:",qes)
                                        return(
                                            <tr>
                                                <th scope="row">{qes[1].question_title}</th>
                                                <td>{qes[1].answers.emloyee_answer==0?"--":getScore(qes[1].answers.emloyee_answer)}</td>
                                                <td>{qes[1].answers.sup_answer==0?"--":getScore(qes[1].answers.sup_answer)}</td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                            
                                        )
                                    })
                                    
                                :
                                <tr>
                                    <th scope="row">--</th>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                </tr>
                                }
                            </tbody>
                            </React.Fragment>
                            </table> 
                            )   
                        })
                        }
                        </React.Fragment>
                        )
                        }

                    
                </div>
                <div className="col-md-12 text-center">
                    <hr style={{ backgroundColor: "black" }} />
                    <label style={{ fontSize: 12 }}>Report For: </label> &nbsp;
                    <label className="font-weight-bold">PAGCOR Assessment {year}</label>
                </div>
                {/* <PrintButton id={"multiPage"} label={"Print multiplate pages"} />
    <MultiPage id={"multiPage"} /> */}
            </div>
        </div></Page>)
};

export default MultiPage;