import React, { Component } from "react";
import { Redirect } from "react-router-dom";
// import Header from '../../Header';
// import Footer from "./../Footer";

import { addNewSubAdmin, getSubAdmins} from './../../../../actions/webRedActions';

// import { getEmployeeProfile } from '../../../../actions/utility';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// import userData from './../userData';

import {
    Button,
    Container,
    Row,
    Col,
    Media,
    Form,
    FormGroup,
    Label,
    Input,
    Spinner,
    Alert
  } from "reactstrap";

// import readXlsxFile from 'read-excel-file';
import SubAdminProfile from "./../subAdmins/SubAdminProfile";
import EmployeeSearchForm from "./../employeeComponent/EmployeeSearchForm";


class AddSubAdmins extends Component {

    constructor(props) {
        super(props);
        this.state = {
          empProfileData : '',
          empSupervisorData : '',
          empReportingData : '',
          assessmentMangement : '',
          employeeId:'',
          showError:false,
          errorMessage:'Please select any employee to add as sub admin',
          btnLoader:false,
          showControlls:false,
          whichTab:'',
          newAddedAdminId:0,
        };
    }

    componentDidMount() {
        
    }

    componentWillReceiveProps(nextProps, thisProps) {
        console.log('Faheem Here:',nextProps);
        // return false;
      if(this.props.fetchEmpDataSuccessAdmin != nextProps.fetchEmpDataSuccessAdmin) {
         
        // console.log(nextProps.fetchEmpDataSuccessAdmin);
        if(nextProps.fetchEmpDataSuccessAdmin.result) {
            if(nextProps.fetchEmpDataSuccessAdmin.result.profile) {
                if(nextProps.fetchEmpDataSuccessAdmin.result.profile !== null) {
                  this.setState({
                    empProfileData : nextProps.fetchEmpDataSuccessAdmin.result.profile,
                    employeeId  :   nextProps.fetchEmpDataSuccessAdmin.result.profile.ID_NUMBER,
                    showError : false
                  }, () => {
                    console.log(this.state.empProfileData)
                  })
                } else {
                    this.setState({
                      empProfileData : ''
                    })
                }
            }

            if(nextProps.fetchEmpDataSuccessAdmin.result.supervision && nextProps.fetchEmpDataSuccessAdmin.result.supervision.length) {
                this.setState({
                  empSupervisorData : nextProps.fetchEmpDataSuccessAdmin.result.supervision
                })
            } else {
              this.setState({
                empSupervisorData : ''
              })
            }

            if(nextProps.fetchEmpDataSuccessAdmin.result.sub_ords && nextProps.fetchEmpDataSuccessAdmin.result.sub_ords.length) {
                this.setState({
                  empReportingData : nextProps.fetchEmpDataSuccessAdmin.result.sub_ords
                })
            } else {
              this.setState({
                empReportingData : ''
              })
            }

        }
      }

      if(this.props.fetchedAllAssessmentsForAdmin != nextProps.fetchedAllAssessmentsForAdmin) {
        if(nextProps.fetchedAllAssessmentsForAdmin.result) {
          this.setState({
            assessmentMangement : nextProps.fetchedAllAssessmentsForAdmin.result
          }, () => {
            console.log(this.state.assessmentMangement)
          })
        }
      }

    }

  render() {

    const {empProfileData, empSupervisorData, empReportingData, assessmentMangement,showControlls,whichTab,newAddedAdminId} = this.state;
    //alert(newAddedAdminId);
    return (
      <div>
       <Container className="text-left" style={{marginBottom: '-5vh', paddingLeft: '5vw'}}>Add new admin</Container>
        <EmployeeSearchForm />
        {
          empProfileData && empProfileData != '' ?
          <SubAdminProfile userData={empProfileData} userSupervisorData={empSupervisorData} userReportingData={empReportingData} assessmentMangement={assessmentMangement} isEdit={showControlls} activeTab={whichTab} newAddedAdminId={newAddedAdminId}/>
            
          : null
        }
        <Container>
            {
                this.state.showError == true ?
                <Alert color="danger">
                    {this.state.errorMessage}
                </Alert>
                :null
            }
            {this.state.showControlls == false ? 
            <Button style={{margin:'auto', marginBottom:40 }} size="md" color="secondary" onClick={()=>{
                if(this.state.employeeId == '')
                    this.setState({showError:true});
                else {
                    
                    var that = this;
                    
                    that.setState({btnLoader:true});

                    this.props.addNewSubAdmin(this.state.employeeId,function(response) {
                        
                        that.setState({btnLoader:false});

                        if(response !=0 && response.status == 'error') {
                            that.setState({showError:true,errorMessage: response.message});
                        }
                        else if(response == 0){
                            that.setState({showError:true,errorMessage:'Unknow error.'});
                        }
                        else if(response !=0 && response.status == 'success') {
                            //window.location.reload();
                            that.props.getSubAdmins();
                            that.setState({showControlls:true,whichTab:'jd',newAddedAdminId:response.result});
                        }
                    })
                }        
        
            }}>Add sub admin
                {
                    this.state.btnLoader == true ? <Spinner size="sm" color="light" style={{marginLeft: 10}}/> : null
                }
            </Button>
           : 
           <Button style={{ margin:'auto', marginTop: 20, marginBottom:40}} size="md" color="secondary" onClick={()=>{
             
              window.location.reload();

           }}>
              Done
           </Button>
          }
        </Container>    
        
      </div>
     

    );
  }
}

AddSubAdmins.propTypes = {
    addNewSubAdmin: PropTypes.func.isRequired,
    getSubAdmins: PropTypes.func.isRequired
}

const mapStatesToProps = state => ({
    fetchEmpDataSuccessAdmin : state.webRed.fetchEmpDataSuccessAdmin,
    fetchedAllAssessmentsForAdmin : state.webRed.fetchedAllAssessmentsForAdmin
})

export default connect( mapStatesToProps, { addNewSubAdmin,getSubAdmins })( AddSubAdmins );