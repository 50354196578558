import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import classnames from 'classnames';
// import Header from './../Header';
// import Footer from "./../Footer";

import { updateProfileData, assignNewAdmin, assignNewSubordinate,
    adminFetchEmployeeInformation, removeSubordinate,updateSubAdmin,
    getAdditionalData
 } from './../../../../actions/webRedActions';

import { autoFocusNextField, getBaseUrl,accessToken, getYear } from './../../../../actions/utility';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle, faCheck } from '@fortawesome/free-solid-svg-icons';

import $ from 'jquery'; 

import axios from 'axios';
import './../../../../public/scss/ProfileComponentEmployee.scss';
// import userData from './../userData';

import AssessmentManagement from './../employeeComponent/AssessmentManagement';

import {
    Button,
    Container,
    Row,
    Col,
    Table,
    Form,
    FormGroup,
    Label,
    Input,
    NavLink,
    Nav,
    NavItem,
    TabPane,
    TabContent,
    Alert,
    Spinner,
    InputGroup,
    InputGroupAddon,
    InputGroupText
  } from "reactstrap";

import readXlsxFile from 'read-excel-file';
import { getAdminUserData } from "../../../../actions/utility";
import userData from "../../userData";
import ScoreCard from "../../assessment/ScoreCard";
import ScoreCardsList from "../../assessment/ScoreCardsList";

// import { BrowserRouter as Router, Route, Link } from "react-router-dom";

class SubAdminProfile extends Component {

    constructor(props) {
        super(props);
        this.state = {
            activeTab : 'p',
            userDataMain : this.props.userData,
            userData : this.props.userData,
            userSupervisorData : this.props.userSupervisorData,
            userReportingData : this.props.userReportingData,
            assessmentMangement : this.props.assessmentMangement,
            highlights : {},

            designationList : [],
            divisionList : [],
            sectionList : [],
            branchList : [],
            deptList : [],
            pcList : [],
            
            employeeId : ["", "", "-", "", "", "", ""],
            employeeIdString : "",
            employeeIdError : "",
            isLoading: false,

            
            employeeIdSub : ["", "", "-", "", "", "", ""],
            employeeIdStringSub : "",
            employeeIdErrorSub : "",
            isLoadingSub: false,
           showControllManagement:false,
           adminId : '',
           isRequestComplete:1,
           canEditAdmin : false,
           canAddEmp : false,
           canEditEmp : false,
           canEditSubOrds:false,
           canEditSup:false,
           canViewCandidate : true,
           canEditRPL:false,
           canViewReports:false,
           canManageAssess:false,
           canSeeSubOrdReports:false,
           newAddedAdminId:0
        };
    }

    toggleTab = (tab) => {
        if(this.state.activeTab !== tab) {
            this.setState({
                activeTab : tab
            })
        }
    }

    selectUpdate = (col, value) => {

        const {userData, highlights} = this.state;
        this.props.updateProfileData(userData.ID_NUMBER, col, value); //calls action for updating profile data

        this.setState({
            highlights : {
                NAME : col == 'NAME' ? false : highlights.NAME,
                FIRST_NAME : col == 'FIRST_NAME' ? false : highlights.FIRST_NAME,
                MI : col == 'MI' ? false : highlights.MI,
                LAST_NAME : col == 'LAST_NAME' ? false : highlights.LAST_NAME,
                ID_NUMBER : col == 'ID_NUMBER' ? false : highlights.ID_NUMBER,
                email : col == 'email' ? false : highlights.email,
                DESIGNATION : col == 'DESIGNATION' ? false : highlights.DESIGNATION,
                BRANCH : col == 'BRANCH' ? false : highlights.BRANCH,
                DEPT : col == 'DEPT' ? false : highlights.DEPT,
                DIVISION : col == 'DIVISION' ? false : highlights.DIVISION,
                SECTION : col == 'SECTION' ? false : highlights.SECTION,
                PC : col == 'PC' ? false : highlights.PC
            }
        })

    }

    updateValue = (e) => {
        var name = e.target.name;
        var value = e.target.value;
        const {userData, highlights} = this.state;

        this.setState({
            userData : {
                NAME : name == 'NAME' ? value : userData.NAME,
                FIRST_NAME : name == 'FIRST_NAME' ? value : userData.FIRST_NAME,
                MI : name == 'MI' ? value : userData.MI,
                LAST_NAME : name == 'LAST_NAME' ? value : userData.LAST_NAME,
                ID_NUMBER : name == 'ID_NUMBER' ? value : userData.ID_NUMBER,
                email : name == 'email' ? value : userData.email,
                DESIGNATION : name == 'DESIGNATION' ? value : userData.DESIGNATION,
                BRANCH : name == 'BRANCH' ? value : userData.BRANCH,
                DEPT : name == 'DEPT' ? value : userData.DEPT,
                DIVISION : name == 'DIVISION' ? value : userData.DIVISION,
                SECTION : name == 'SECTION' ? value : userData.SECTION,
                PC : name == 'PC' ? value : userData.PC,
            }
        }, () => {
            // console.log(this.state.userData);
            this.setState({
                highlights : {
                    NAME : name == 'NAME' ? value !== userData.NAME ? true : false : highlights.NAME,
                    FIRST_NAME : name == 'FIRST_NAME' ? value !== userData.FIRST_NAME ? true : false : highlights.FIRST_NAME,
                    MI : name == 'MI' ? value !== userData.MI ? true : false : highlights.MI,
                    LAST_NAME : name == 'LAST_NAME' ? value !== userData.LAST_NAME ? true : false : highlights.LAST_NAME,
                    ID_NUMBER : name == 'ID_NUMBER' ? value !== userData.ID_NUMBER ? true : false : highlights.ID_NUMBER,
                    email : name == 'email' ? value !== userData.email ? true : false : highlights.email,
                    DESIGNATION : name == 'DESIGNATION' ? value !== userData.DESIGNATION ? true : false : highlights.DESIGNATION,
                    BRANCH : name == 'BRANCH' ? value !== userData.BRANCH ? true : false : highlights.BRANCH,
                    DEPT : name == 'DEPT' ? value !== userData.DEPT ? true : false : highlights.DEPT,
                    DIVISION : name == 'DIVISION' ? value !== userData.DIVISION ? true : false : highlights.DIVISION,
                    SECTION : name == 'SECTION' ? value !== userData.SECTION ? true : false : highlights.SECTION,
                    PC : name == 'PC' ? value !== userData.PC ? true : false : highlights.PC
                }
            })
        })
    }
    registerClickEvent() {
        var ths = this;
       
        $('.admin-permissions').on('click',function() {
            
            var val = $(this).is(':checked') == true ? 1 : 0;
            
            var col = $(this).attr('col');
            if(col == 'can_editadmin')
                ths.setState({canEditAdmin:$(this).is(':checked')});
            else if (col == 'can_editemp')
                ths.setState({canEditEmp:$(this).is(':checked')});
            else if(col == 'can_editsup')
                ths.setState({canEditSup:$(this).is(':checked')});
            else if(col == 'can_manageassess')
                ths.setState({canManageAssess:$(this).is(':checked')});
            else if(col == 'can_editrpl')
                ths.setState({canEditRPL:$(this).is(':checked')});
            else if(col == 'can_viewreports')
                ths.setState({canViewReports:$(this).is(':checked'),canSeeSubOrdReports:false}); 
            else if(col == 'can_addemp')
                ths.setState({canAddEmp:$(this).is(':checked')});
            else if(col == 'can_editsubords')
                ths.setState({canEditSubOrds:$(this).is(':checked')});
            else if(col == 'can_seesubord_reports')
                ths.setState({canSeeSubOrdReports:$(this).is(':checked'),canViewReports:false});      
                         
            if(ths.state.adminId !='' && ths.state.isRequestComplete == 1) {
                
                var subAdminsData = localStorage.getItem('subAdminsData');

                try {

                    subAdminsData =JSON.parse(subAdminsData);
                    
                    var subAdmin = subAdminsData[ths.state.adminId];
                    
                    var adminIdPK =subAdmin.admin_id;

                    ths.state.isRequestComplete = 0;

                    ths.props.updateSubAdmin(adminIdPK,col,val,function(res) {
                        
                        ths.state.isRequestComplete = 1;
                        
                        if(typeof res.status !=='undefined' && res.status == 'success') {
                            
                            var obj = {};
                            
                            obj[col] = val;
                            
                            $.extend(subAdmin,obj);
                           
                            subAdminsData[ths.state.adminId] = subAdmin;

                            //console.log(subAdminsData)

                            localStorage.setItem('subAdminsData',JSON.stringify(subAdminsData));
                            
                        }
                        
                        console.log('callback response:',res);
                    });


                }
                catch(err) {
                    ths.state.isRequestComplete = 1;
                }
                //ths.props.updateSubAdmin();

            }
            if(ths.state.newAddedAdminId !=0 && ths.state.isRequestComplete == 1) {
                console.log('Faheem in elseif');
                ths.state.isRequestComplete = 0;

                ths.props.updateSubAdmin(ths.state.newAddedAdminId,col,val,function(res) {
                    
                    ths.state.isRequestComplete = 1;
                    
                });
            }
            console.log((ths.state.newAddedAdminId !=0 && ths.state.isRequestComplete == 1),':condition:here is the data:',ths.state.isRequestComplete);
            console.log(col,'col:My click event:val',val,':adminId:',ths.state.newAddedAdminId);
        })
    }
    componentDidMount() {
        
        //autoFocusNextField("boxFieldForm"); // auto focus utility method called

        this.fetchAllDistinctValues();
        
        this.registerClickEvent();

        var employeeData = Object();
        
        if(typeof this.props.adminId !=='undefined') {
        
            try {
                
                employeeData = JSON.parse(localStorage.subAdminsData);
                
                employeeData = employeeData[this.props.adminId];
                
                this.setState({
                        canEditAdmin:employeeData.can_editadmin == 1        ? true: false,
                        canAddEmp:employeeData.can_addemp == 1              ? true: false,
                        canEditEmp: employeeData.can_editemp == 1           ? true: false,
                        canEditSubOrds: employeeData.can_editsubords == 1   ? true: false,
                        canEditSup: employeeData.can_editsup == 1           ? true: false,
                        canEditRPL: employeeData.can_editrpl == 1           ? true: false,
                        canViewReports: employeeData.can_viewreports == 1   ? true: false,
                        canManageAssess: employeeData.can_manageassess == 1 ? true: false,
                        canSeeSubOrdReports: employeeData.can_seesubord_reports == 1 ? true: false
                })
               
            }
            catch(err) {
    
            } 
         }

    }

    fetchAllDistinctValues = () => {
        if(getYear() > '2020')
            this.props.getAdditionalData();
        else {
            //fetch all distinct values
            this.getAllDistinctData('DESIGNATION');
            this.getAllDistinctData('BRANCH');
            this.getAllDistinctData('DEPT');
            this.getAllDistinctData('DIVISION');
            this.getAllDistinctData('SECTION');
            this.getAllDistinctData('PC');
        }
    }
    
    componentWillReceiveProps(nextProps, thisProps) {
        
        this.registerClickEvent();
        console.log('Faheem event has been registerd');
        if(this.props.userData != nextProps.userData) {
            this.setState({
                userDataMain : nextProps.userData,
                userData : nextProps.userData,
                highlights : {}
            }, () => {
                
                this.fetchAllDistinctValues();
                
            })
        }

        if(this.props.userSupervisorData != nextProps.userSupervisorData) {
            this.setState({
                userSupervisorData : nextProps.userSupervisorData
            })
        }

        if(this.props.userReportingData != nextProps.userReportingData) {
            this.setState({
                userReportingData : nextProps.userReportingData
            })
        }

        if(this.props.assessmentMangement != nextProps.assessmentMangement) {
            this.setState({
                assessmentMangement : nextProps.assessmentMangement
            })
        }


        // when the supervisor is successfully assigned
        if(this.props.assignedSupDataSuccessAdmin != nextProps.assignedSupDataSuccessAdmin) {
            this.setState({
                employeeId : ["", "", "-", "", "", "", ""],
                employeeIdString : "",
                employeeIdError : "",
                isLoading: false
            }, () => {
                this.props.adminFetchEmployeeInformation(this.state.userData.ID_NUMBER);
            })
        }

        // when the subordinate is successfully assigned
        if(this.props.assignedSubordinateDataSuccessAdmin != nextProps.assignedSubordinateDataSuccessAdmin) {
            this.setState({
                employeeIdSub : ["", "", "-", "", "", "", ""],
                employeeIdStringSub : "",
                employeeIdErrorSub : "",
                isLoadingSub: false
            }, () => {
                this.props.adminFetchEmployeeInformation(this.state.userData.ID_NUMBER);
            })
        }

        // when the subordinate is successfully removed
        if(this.props.removedSubordinateDataSuccessAdmin != nextProps.removedSubordinateDataSuccessAdmin) {
            this.setState({
                employeeIdSub : ["", "", "-", "", "", "", ""],
                employeeIdStringSub : "",
                employeeIdErrorSub : "",
                isLoadingSub: false
            }, () => {
                this.props.adminFetchEmployeeInformation(this.state.userData.ID_NUMBER);
            })
        }
        
        if(this.props.additionalData != nextProps.additionalData) {
            this.setState({
                designationList : nextProps.additionalData.data.designation,
                divisionList    : nextProps.additionalData.data.divisions,
                sectionList     : nextProps.additionalData.data.sections,
                branchList      : nextProps.additionalData.data.branches,
                deptList        : nextProps.additionalData.data.departments,
                pcList          : nextProps.additionalData.data.payClass
            })
        }

    }
    
    
    handleChange = (event, index) => {
        event.preventDefault();
        const { name, value } = event.target;

        var employeeIdTemp = this.state.employeeId;
        employeeIdTemp[index] = value;

        this.setState({
            [name] : employeeIdTemp
        }, () => {
            // console.log(this.state.employeeId);

            var emp_id = this.convertEmpIdToString(this.state.employeeId);
            this.setState({
                employeeIdString : emp_id
            }, () => {
                // it means all inputs are done here we need to call action to verify
                
                if(emp_id.length == 7) {
                }
                // console.log(this.state.employeeIdString)
            })

        })

        if(this.state.employeeIdError != "") {
            this.setState({
                employeeIdError : ""
            })
        }
    }

    // handleSubmit = (event) => {
    //     event.preventDefault();
        
    // }

    convertEmpIdToString = (empId) => {
        var stringEmpId = "";
        empId.map(val => {
            if(val && val != "" && val != undefined){
                stringEmpId += val;
            }
        })

        return stringEmpId;
    }


    // change handle for subordinates only
    handleChangeSub = (event, index) => {
        event.preventDefault();
        const { name, value } = event.target;

        var employeeIdTemp = this.state.employeeIdSub;
        employeeIdTemp[index] = value;

        this.setState({
            [name] : employeeIdTemp
        }, () => {
            // console.log(this.state.employeeId);

            var emp_id = this.convertEmpIdToStringSub(this.state.employeeIdSub);
            this.setState({
                employeeIdStringSub : emp_id
            }, () => {
                // it means all inputs are done here we need to call action to verify
                
                if(emp_id.length == 7) {
                }
                // console.log(this.state.employeeIdString)
            })

        })

        if(this.state.employeeIdErrorSub != "") {
            this.setState({
                employeeIdErrorSub : ""
            })
        }
    }

    convertEmpIdToStringSub = (empId) => {
        var stringEmpId = "";
        empId.map(val => {
            if(val && val != "" && val != undefined){
                stringEmpId += val;
            }
        })

        return stringEmpId;
    }

    
    // fetch all distinct values for any column
    getAllDistinctData = (col) => {
        var bodyFormData = new FormData();
        bodyFormData.set('col', col);
        bodyFormData.set('acess_token', accessToken());

        axios.post( getBaseUrl() + 'api/admin/employee/column/data', bodyFormData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
        .then((res) => {
            console.log(col,"Here is the column response",res.data.result);
            if(res.data.result) {

                if(col == 'DESIGNATION') {
                    this.setState({
                        designationList : res.data.result
                    }, () => {
                        console.log('sate is ready:',this.state.designationList)
                    })
                }
                else if(col == 'DIVISION') {
                    this.setState({
                        divisionList : res.data.result
                    }, () => {
                        console.log(this.state.divisionList)
                    })
                }
                else if(col == 'SECTION') {
                    this.setState({
                        sectionList : res.data.result
                    }, () => {
                        console.log(this.state.sectionList)
                    })
                }
                else if(col == 'BRANCH') {
                    this.setState({
                        branchList : res.data.result
                    }, () => {
                        console.log(this.state.branchList)
                    })
                }
                else if(col == 'DEPT') {
                    this.setState({
                        deptList : res.data.result
                    }, () => {
                        console.log(this.state.deptList)
                    })
                }
                else if(col == 'PC') {
                    this.setState({
                        pcList : res.data.result
                    }, () => {
                        console.log(this.state.pcList)
                    })
                }
                

            }
        })
        .catch((error) => {
            console.log(error);
        });
    }

  render() {
    
    this.state.adminId = this.props.adminId
    this.state.newAddedAdminId =  this.props.newAddedAdminId
    if(this.props.newAddedAdminId)
        //this.registerClickEvent();
    console.log('here is the re render:',this.props.newAddedAdminId);
    
    const adminData = getAdminUserData(); // user which is logged in usually ad admin user here.
    // const { userData } = this.props; // empData to review and update the details is required by admin.
    var { showControllManagement,userData, activeTab,
        userSupervisorData, userReportingData, highlights,
        employeeId, employeeIdError, employeeIdString, isLoading,
        employeeIdSub, employeeIdErrorSub, employeeIdStringSub, isLoadingSub,
        canEditAdmin,canAddEmp,canEditEmp,canEditSubOrds,canEditSup,canViewCandidate,canEditRPL,
        canViewReports,canManageAssess,canSeeSubOrdReports

    } = this.state;

    var handleControll = '';
    if(showControllManagement == false && typeof this.props.isEdit ==='undefined') {
        handleControll = 'disabled'
    }
    else if(showControllManagement == false && ( typeof this.props.isEdit !=='undefined' && this.props.isEdit == false ) ) {
        handleControll = 'disabled'
    }
    else if(showControllManagement == false && ( (typeof this.props.isEdit !='undefined' && this.props.isEdit) && typeof this.props.activeTab !='undefined')) {
        
        handleControll = ''
        activeTab = this.props.activeTab
        
        console.log(activeTab,':in iff:',handleControll);
    }
    console.log("This hits Faheem");
    // console.log(userSupervisorData);

    return (
      <div className="ProfileComponentEmployee" >
       

        <div>

            <Container fluid>

                <Nav tabs>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: activeTab === 'p' })}
                            onClick={() => { this.toggleTab('p'); }} // profile
                        >
                            {userData.NAME}
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            disabled={handleControll}
                            className={classnames({ active: activeTab === 'jd' })}
                            onClick={() => { this.toggleTab('jd'); }} // job description
                        >
                            Controls management
                        </NavLink>
                    </NavItem>
                    {/* <NavItem>
                        <NavLink
                            disabled 
                            className={classnames({ active: activeTab === 'am' })}
                            onClick={() => { this.toggleTab('am'); }} // assessment management
                        >
                            Reports
                        </NavLink>
                    </NavItem> */}
                    {/*<NavItem>
                         <NavLink
                            disabled
                            className={classnames({ active: activeTab === 'sc' })}
                            onClick={() => { this.toggleTab('sc'); }} // score card
                        >
                            Score Card
                        </NavLink> 
                    </NavItem>*/}
                </Nav>
                <TabContent activeTab={activeTab}>
                    <TabPane tabId="p">

                        <Container>
                            <Form>
                                <Row style={{padding: '6vh 3vw', textAlign: 'left'}}>

                                    {/* <Col md={{size : 12}} style={{paddingBottom: '30px'}}>
                                        <Alert color="primary" className="alertMessage">
                                            Please review the profile of {userData.NAME} and click on the respective field to update information. Click Update &nbsp;<FontAwesomeIcon icon={faCheck} size="1x" />&nbsp;&nbsp; to save changes for each field.
                                        </Alert>
                                    </Col> */}

                                    <Col md={{size: 4}}>
                                        <FormGroup className={highlights.FIRST_NAME ? 'changed' : null}>
                                            <FontAwesomeIcon className="updateIcon" icon={faCheck} size="1x" 
                                                onClick={(e) => this.selectUpdate('FIRST_NAME', userData.FIRST_NAME)}
                                            />
                                            <Label for="FIRST_NAME">First name</Label>
                                            <Input disabled type="text" name="FIRST_NAME" id="FIRST_NAME" placeholder="" value={userData.FIRST_NAME} onChange={(e) => this.updateValue(e)} />
                                        </FormGroup>
                                    </Col>
                                    <Col md={{size: 4}}>
                                        <FormGroup className={highlights.MI ? 'changed' : null}>
                                            <FontAwesomeIcon className="updateIcon" icon={faCheck} size="1x" 
                                                onClick={(e) => this.selectUpdate('MI', userData.MI)}
                                            />
                                            <Label for="MI">MI</Label>
                                            <Input disabled type="text" name="MI" id="MI" placeholder="" value={userData.MI}  onChange={(e) => this.updateValue(e)}/>
                                        </FormGroup>
                                    </Col>
                                    <Col md={{size: 4}}>
                                        <FormGroup className={highlights.LAST_NAME ? 'changed' : null}>
                                            <FontAwesomeIcon className="updateIcon" icon={faCheck} size="1x" 
                                                onClick={(e) => this.selectUpdate('LAST_NAME', userData.LAST_NAME)}
                                            />
                                            <Label for="LAST_NAME">Last name</Label>
                                            <Input disabled type="text" name="LAST_NAME" id="LAST_NAME" placeholder="" value={userData.LAST_NAME}  onChange={(e) => this.updateValue(e)}/>
                                        </FormGroup>
                                    </Col>
                                    <Col md={{size: 12}}>
                                        <FormGroup>
                                            <Label for="ID_NUMBER">Employee ID</Label>
                                            <Input type="text" name="ID_NUMBER" id="ID_NUMBER" placeholder="" value={userData.ID_NUMBER} disabled />
                                        </FormGroup>
                                    </Col>
                                    
                                    <Col md={{size: 12}}>
                                        <FormGroup>
                                            <Label for="email">Email id</Label>
                                            <Input type="text" name="email" id="email" placeholder="" value={userData.email} disabled />
                                        </FormGroup>
                                    </Col>                        
                                    
                                    <Col md={{size: 12}}>
                                        <FormGroup className={highlights.DESIGNATION ? 'changed' : null}>
                                            <FontAwesomeIcon className="updateIcon" icon={faCheck} size="1x" 
                                                onClick={(e) => this.selectUpdate('DESIGNATION', userData.DESIGNATION)}
                                            />
                                            <Label for="DESIGNATION">Designation</Label>
                                            {/* <Input type="text" name="DESIGNATION" id="DESIGNATION" placeholder="" value={userData.DESIGNATION} onChange={(e) => this.updateValue(e)} /> */}

                                            {
                                                getYear() > '2020' ? 
                                                <Input type="select" name="DESIGNATION" id="DESIGNATION" onChange={(e) => this.updateValue(e)}>
                                                    <option value="select" key={0} selected={userData.DESIGNATION =="" ? "selected" : ''} >Select</option>
                                                    {
                                                        this.state.designationList.map((data, key) => 
                                                            userData.DESIGNATION == ""? 
                                                            <option
                                                                key={key}
                                                                value={data.desig_id}
                                                                
                                                            >
                                                                
                                                                {data.desig_name}
                                                            </option>
                                                            :
                                                            <option
                                                                key={key}
                                                                value={data.desig_id}
                                                                selected={data.desig_name == userData.DESIGNATION}
                                                                
                                                            >
                                                                
                                                                {data.desig_name}
                                                            </option>
                                                        )

                                                    }
                                                </Input>
                                                :
                                                <Input disabled='disabled' type="select" name="DESIGNATION" id="DESIGNATION" onChange={(e) => this.updateValue(e)}>
                                                    {

                                                        this.state.designationList.map((data, key) => 
                                                            <option
                                                                key={key}
                                                                val={data.DESIGNATION}
                                                                selected={data.DESIGNATION == userData.DESIGNATION}
                                                            >
                                                                {data.DESIGNATION}
                                                            </option>
                                                        )

                                                    }
                                                </Input>
                                            }
                                        </FormGroup>
                                    </Col>
                                    
                                    <Col md={{size: 12}}>
                                        <FormGroup className={highlights.BRANCH ? 'changed' : null}>
                                            <FontAwesomeIcon className="updateIcon" icon={faCheck} size="1x" 
                                                onClick={(e) => this.selectUpdate('BRANCH', userData.BRANCH)}
                                            />
                                            <Label for="BRANCH">Branch</Label>
                                            {/* <Input type="text" name="BRANCH" id="BRANCH" placeholder="" value={userData.BRANCH} onChange={(e) => this.updateValue(e)} /> */}
                                            {
                                                getYear() > '2020' ? 
                                                <Input  type="select" name="BRANCH" id="BRANCH" onChange={(e) => this.updateValue(e)}>
                                                <option value="select" key={0} selected={userData.BRANCH =="" ? "selected" : ''} >Select</option>
                                                    {

                                                        this.state.branchList.map((data, key) => 
                                                            userData.BRANCH !="" ?
                                                            <option
                                                                key={key}
                                                                value={data.branch_id}
                                                                selected={data.branch_name == userData.BRANCH}
                                                            >
                                                                {data.branch_name}
                                                            </option>
                                                            :
                                                            <option
                                                                key={key}
                                                                value={data.branch_id}
                                                                
                                                            >
                                                                {data.branch_name}
                                                            </option>
                                                        )

                                                    }
                                                </Input>
                                                :
                                                <Input disabled='disabled' type="select" name="BRANCH" id="BRANCH" onChange={(e) => this.updateValue(e)}>
                                                    {

                                                        this.state.branchList.map((data, key) => 
                                                            <option
                                                                key={key}
                                                                val={data.BRANCH}
                                                                selected={data.BRANCH == userData.BRANCH}
                                                            >
                                                                {data.BRANCH}
                                                            </option>
                                                        )

                                                    }
                                                </Input>
                                            }
                                        </FormGroup>
                                    </Col>
                                    
                                    <Col md={{size: 12}}>
                                        <FormGroup className={highlights.DEPT ? 'changed' : null}>
                                            <FontAwesomeIcon className="updateIcon" icon={faCheck} size="1x" 
                                                onClick={(e) => this.selectUpdate('DEPT', userData.DEPT)}
                                            />
                                            <Label for="DEPT">Department</Label>
                                            {/* <Input type="text" name="DEPT" id="DEPT" placeholder="" value={userData.DEPT} onChange={(e) => this.updateValue(e)} /> */}

                                            {
                                                getYear() > '2020' ? 
                                                <Input  type="select" name="DEPT" id="DEPT" onChange={(e) => this.updateValue(e)}>
                                                <option value="select" key={0} selected={userData.DEPT =="" ? "selected" : ''} >Select</option>
                                                    {

                                                        this.state.deptList.map((data, key) => 
                                                        userData.DEPT !="" ?
                                                            <option
                                                                key={key}
                                                                value={data.dept_id}
                                                                selected={data.dept_name == userData.DEPT}
                                                            >
                                                                {data.dept_name}
                                                            </option>
                                                            :
                                                            <option
                                                                key={key}
                                                                value={data.dept_id}
                                                                
                                                            >
                                                                {data.dept_name}
                                                            </option>
                                                        )

                                                    }
                                                </Input>
                                                :
                                                <Input disabled='disabled' type="select" name="DEPT" id="DEPT" onChange={(e) => this.updateValue(e)}>
                                                    {

                                                        this.state.deptList.map((data, key) => 
                                                            <option
                                                                key={key}
                                                                val={data.DEPT}
                                                                selected={data.DEPT == userData.DEPT}
                                                            >
                                                                {data.DEPT}
                                                            </option>
                                                        )

                                                    }
                                                </Input>
                                            }
                                        </FormGroup>
                                    </Col>
                                    
                                    <Col md={{size: 12}}>
                                        <FormGroup className={highlights.DIVISION ? 'changed' : null}>
                                            <FontAwesomeIcon className="updateIcon" icon={faCheck} size="1x" 
                                                onClick={(e) => this.selectUpdate('DIVISION', userData.DIVISION)}
                                            />
                                            <Label for="DIVISION">Division</Label>
                                            {/* <Input type="text" name="DIVISION" id="DIVISION" placeholder="" value={userData.DIVISION} onChange={(e) => this.updateValue(e)} /> */}
                                            {
                                                getYear() > '2020' ? 
                                                <Input  type="select" name="DIVISION" id="DIVISION" onChange={(e) => this.updateValue(e)}>
                                                <option value="select" key={0} selected={userData.DIVISION =="" ? "selected" : ''} >Select</option>
                                                    {

                                                        this.state.divisionList.map((data, key) => 
                                                            userData.DIVISION !="" ? 
                                                            <option
                                                                key={key}
                                                                value={data.division_id}
                                                                selected={data.division_name == userData.DIVISION}
                                                            >
                                                                {data.division_name}
                                                            </option>
                                                            :
                                                            <option
                                                                key={key}
                                                                value={data.division_id}
                                                                
                                                            >
                                                                {data.division_name}
                                                            </option>
                                                        )

                                                    }
                                                </Input>
                                                :
                                                <Input disabled='disabled' type="select" name="DIVISION" id="DIVISION" onChange={(e) => this.updateValue(e)}>
                                                    {

                                                        this.state.divisionList.map((data, key) => 
                                                            <option
                                                                key={key}
                                                                val={data.DIVISION}
                                                                selected={data.DIVISION == userData.DIVISION}
                                                            >
                                                                {data.DIVISION}
                                                            </option>
                                                        )

                                                    }
                                                </Input>
                                            }

                                        </FormGroup>
                                    </Col>
                                    
                                    <Col md={{size: 12}}>
                                        <FormGroup className={highlights.SECTION ? 'changed' : null}>
                                            <FontAwesomeIcon className="updateIcon" icon={faCheck} size="1x" 
                                                onClick={(e) => this.selectUpdate('SECTION', userData.SECTION)}
                                            />
                                            <Label for="SECTION">Section</Label>
                                            {/* <Input type="text" name="SECTION" id="SECTION" placeholder="" value={userData.SECTION} onChange={(e) => this.updateValue(e)} /> */}
                                            
                                            <Input disabled='disabled' type="select" name="SECTION" id="SECTION" onChange={(e) => this.updateValue(e)}>
                                                {

                                                    this.state.sectionList.map((data, key) => 
                                                        <option
                                                            key={key}
                                                            val={data.SECTION}
                                                            selected={data.SECTION == userData.SECTION}
                                                        >
                                                            {data.SECTION}
                                                        </option>
                                                    )

                                                }
                                            </Input>
                                        </FormGroup>
                                    </Col>
                                    
                                    <Col md={{size: 12}}>
                                        <FormGroup className={highlights.PC ? 'changed' : null}>
                                            <FontAwesomeIcon className="updateIcon" icon={faCheck} size="1x" 
                                                onClick={(e) => this.selectUpdate('PC', userData.PC)}
                                            />
                                            <Label for="PC">Job Grade</Label>
                                            {/* <Input type="text" name="PC" id="PC" placeholder="" value={userData.PC} onChange={(e) => this.updateValue(e)} /> */}
                                            
                                            <Input disabled='disabled' type="select" name="PC" id="PC" onChange={(e) => this.updateValue(e)}>
                                                {

                                                    this.state.pcList.map((data, key) => 
                                                        <option
                                                            key={key}
                                                            val={data.PC}
                                                            selected={data.PC == userData.PC}
                                                        >
                                                            {data.PC}
                                                        </option>
                                                    )

                                                }
                                            </Input>
                                        </FormGroup>
                                    </Col>

                                    <Col md={{size: 12}}>
                                        <FormGroup>
                                            <Label for="section">Supervising <b>{userReportingData.length} {userReportingData.length == 1 ? 'Employee' : 'Employees'}</b></Label>
                                            <Table style={{marginTop:5}}>
                                            
                                                <tbody>
                                                    {
                                                    userReportingData && userReportingData.length && userReportingData.map((subOrdinate, key) =>
                                                        <tr key={key}>
                                                            <td >{subOrdinate.ID_NUMBER}</td>
                                                            <td>{subOrdinate.NAME}</td>
                                                            <td>{subOrdinate.SECTION}</td>
                                                            <td>{subOrdinate.DIVISION}</td>
                                                            {/* <td style={{color:'red', fontSize: '12px', cursor: 'pointer'}}
                                                                onClick={(e) => this.props.removeSubordinate(adminData.admin.admin_id, subOrdinate.supervisor_id)}    
                                                            >
                                                                <FontAwesomeIcon icon={faTimesCircle} size="1x" style={{marginRight: '10px'}} /> Remove
                                                            </td> */}
                                                        </tr>
                                                    )
                                                    }

                                                    <tr>
                                                        {/* <td colSpan="2" style={{textAlign: 'right'}}>
                                                            Add new subordinate: 
                                                        </td>
                                                        <td colSpan="2">
                                                            <FormGroup className="boxFieldForm" style={{marginBottom: 0}}>
                        
                                                                <Input type="text" bsSize="lg" maxLength="1" name="employeeIdSub" id="employeeIdSub1"
                                                                    value={employeeIdSub[0]}
                                                                    onChange={evt => this.handleChangeSub(evt, 0)} />

                                                                <Input type="text" bsSize="lg" maxLength="1" name="employeeIdSub" id="employeeIdSub2" 
                                                                    value={employeeIdSub[1]}
                                                                    onChange={evt => this.handleChangeSub(evt, 1)} />

                                                                <Input style={{
                                                                    background: '#fef67f',
                                                                    border: 'none',
                                                                }} type="text" bsSize="lg" value="-" maxLength="1" name="dash" id="dash" disabled />

                                                                <Input type="text" bsSize="lg" maxLength="1" name="employeeIdSub" id="employeeIdSub3"
                                                                    value={employeeIdSub[3]}
                                                                    onChange={evt => this.handleChangeSub(evt, 3)} />

                                                                <Input type="text" bsSize="lg" maxLength="1" name="employeeIdSub" id="employeeIdSub4"
                                                                    value={employeeIdSub[4]}
                                                                    onChange={evt => this.handleChangeSub(evt, 4)} />

                                                                <Input type="text" bsSize="lg" maxLength="1" name="employeeIdSub" id="employeeIdSub5"
                                                                    value={employeeIdSub[5]}
                                                                    onChange={evt => this.handleChangeSub(evt, 5)} />

                                                                <Input type="text" bsSize="lg" maxLength="1" name="employeeIdSub" id="employeeIdSub6"
                                                                    value={employeeIdSub[6]}
                                                                    onChange={evt => this.handleChangeSub(evt, 6)} />

                                                            </FormGroup>
                                                        </td> */}
                                                        <td style={{textAlign: 'left'}}>
                                                            {
                                                            employeeIdStringSub.length == 7 ?
                                                                <Button style={{margin: '0 0 0 0'}} color={"warning"} size="md"
                                                                    onClick={(e) => {
                                                                        this.setState({
                                                                            isLoadingSub : true
                                                                        }, () => {
                                                                            this.props.assignNewSubordinate(adminData.admin.admin_id, userData.ID_NUMBER, employeeIdStringSub)
                                                                        })
                                                                    }}
                                                                    disabled={isLoadingSub}
                                                                >
                                                                    Add
                                                                    {
                                                                        isLoadingSub ? <Spinner style={{margin: '4px 0px 4px 10px'}} color="light" size="sm" /> : null
                                                                    }
                                                                </Button>
                                                            : null
                                                            }
                                                        </td>
                                                    </tr>

                                                </tbody>
                                            </Table>
                                        </FormGroup>
                                    </Col>
                                    <Col md={{size: 12}}>
                                        <FormGroup>
                                            <Label for="section">Reporting to <b>{userSupervisorData.length} Supervisor</b></Label>
                                            <Table style={{marginTop:5}} >  
                                                <tbody>
                                                    {
                                                    userSupervisorData && userSupervisorData.length ?
                                                        userSupervisorData.map((supervisor, key) =>
                                                            <tr key={key}>
                                                                <td>{supervisor.ID_NUMBER}</td>
                                                                <td>{supervisor.NAME}</td>
                                                                <td>{supervisor.SECTION}</td>
                                                                <td>{supervisor.DIVISION}</td>
                                                                {/* <td style={{color:'green', fontSize: '12px', cursor: 'pointer'}} onClick={(e) => this.setState({
                                                                    userSupervisorData : []
                                                                })}>
                                                                    <FontAwesomeIcon icon={faTimesCircle} size="1x" style={{marginRight: '10px'}} /> Change
                                                                </td> */}
                                                            </tr>
                                                        )
                                                    : null
                                                    }
                                                    
                                                    {
                                                    !userSupervisorData.length ?
                                                        <tr>
                                                            {/* <td colSpan="2">
                                                                Employee id of new supervisor: 
                                                            </td>
                                                            <td>
                                                                <FormGroup className="boxFieldForm" style={{marginBottom: 0}}>
                            
                                                                    <Input type="text" bsSize="lg" maxLength="1" name="employeeId" id="employeeId1"
                                                                        value={employeeId[0]}
                                                                        onChange={evt => this.handleChange(evt, 0)} />

                                                                    <Input type="text" bsSize="lg" maxLength="1" name="employeeId" id="employeeId2" 
                                                                        value={employeeId[1]}
                                                                        onChange={evt => this.handleChange(evt, 1)} />

                                                                    <Input style={{
                                                                        background: '#fef67f',
                                                                        border: 'none',
                                                                    }} type="text" bsSize="lg" value="-" maxLength="1" name="dash" id="dash" disabled />

                                                                    <Input type="text" bsSize="lg" maxLength="1" name="employeeId" id="employeeId3"
                                                                        value={employeeId[3]}
                                                                        onChange={evt => this.handleChange(evt, 3)} />

                                                                    <Input type="text" bsSize="lg" maxLength="1" name="employeeId" id="employeeId4"
                                                                        value={employeeId[4]}
                                                                        onChange={evt => this.handleChange(evt, 4)} />

                                                                    <Input type="text" bsSize="lg" maxLength="1" name="employeeId" id="employeeId5"
                                                                        value={employeeId[5]}
                                                                        onChange={evt => this.handleChange(evt, 5)} />

                                                                    <Input type="text" bsSize="lg" maxLength="1" name="employeeId" id="employeeId6"
                                                                        value={employeeId[6]}
                                                                        onChange={evt => this.handleChange(evt, 6)} />

                                                                </FormGroup>
                                                            </td> */}
                                                            <td>
                                                                {
                                                                employeeIdString.length == 7 ?
                                                                    <Button style={{margin: '0 0 0 0'}} color={"warning"} size="md"
                                                                        onClick={(e) => {
                                                                            this.setState({
                                                                                isLoading : true
                                                                            }, () => {
                                                                                this.props.assignNewAdmin(adminData.admin.admin_id, employeeIdString, userData.ID_NUMBER)
                                                                            })
                                                                        }}
                                                                        disabled={isLoading}
                                                                    >
                                                                        Add
                                                                        {
                                                                            isLoading ? <Spinner style={{margin: '4px 0px 4px 10px'}} color="light" size="sm" /> : null
                                                                        }
                                                                    </Button>
                                                                : null
                                                                }
                                                            </td>
                                                        </tr>
                                                        : null
                                                    }
                                                </tbody>
                                            </Table>
                                        </FormGroup>
                                    </Col>
                                   </Row>
                            </Form>
                        </Container>

                    </TabPane>
                    <TabPane tabId="jd">
                        <Row>
                            <Col sm="12">
                                {/* <div class='container' style={{marginTop:10, marginBottom:2,textAlign:'left'}}>
                                    <b>Grant admin permissions</b>
                                </div>    */}
                            
                                <Container className="controlsManageContainer">
                              
                                    <InputGroup style={{display:'block'}}>
                                        <InputGroupText className={canEditAdmin ? 'selected' : ''}>
                                            <Input checked={canEditAdmin} className="admin-permissions" col='can_editadmin' addon type="checkbox" aria-label="Checkbox for following text input" />
                                            <span className="assignLabel" style={{color : canEditAdmin ? '#92d36e':'#606060'}}>
                                                {
                                                    canEditAdmin ? 'Assigned' : 'Assign role'
                                                }
                                            </span>
                                            <span style={{marginLeft:10}}>Access to assign and delete other admin roles</span>
                                        </InputGroupText>
                                        
                                    </InputGroup>

                                    <InputGroup style={{display:'block'}}>
                                        <InputGroupText className={canEditEmp ? 'selected' : ''}>
                                            <Input checked={canEditEmp} className="admin-permissions" col='can_editemp' addon type="checkbox" aria-label="Checkbox for following text input" />
                                            <span className="assignLabel" style={{color : canEditEmp ? '#92d36e':'#606060'}}>
                                                {
                                                    canEditEmp ? 'Assigned' : 'Assign role'
                                                }
                                            </span>
                                            <span style={{marginLeft:10}}>Access to update the information on candidate's profile</span>
                                        </InputGroupText>
                                    </InputGroup>
                                    
                                    <InputGroup style={{display:'block'}}>
                                        <InputGroupText className={canEditSup ? 'selected' : ''}>
                                            <Input checked={canEditSup} className="admin-permissions" col='can_editsup' addon type="checkbox" aria-label="Checkbox for following text input" />
                                            <span className="assignLabel" style={{color : canEditSup ? '#92d36e':'#606060'}}>
                                                {
                                                    canEditSup ? 'Assigned' : 'Assign role'
                                                }
                                            </span>
                                            <span style={{marginLeft:10}}>Access to allocate or delete supervisor for candidate</span>
                                        </InputGroupText>
                                    </InputGroup>

                                    <InputGroup style={{display:'block'}}>
                                        <InputGroupText className={canManageAssess ? 'selected' : ''}>
                                            <Input checked={canManageAssess} className="admin-permissions" col='can_manageassess' addon type="checkbox" aria-label="Checkbox for following text input" />
                                            <span className="assignLabel" style={{color : canManageAssess ? '#92d36e':'#606060'}}>
                                                {
                                                    canManageAssess ? 'Assigned' : 'Assign role'
                                                }
                                            </span>
                                            <span style={{marginLeft:10}}>Access to manage assessments for candidate</span>
                                        </InputGroupText>
                                    </InputGroup>
                                    
                                    <InputGroup style={{display:'block'}}>
                                        <InputGroupText className={canEditRPL ? 'selected' : ''}>
                                            <Input checked={canEditRPL} className="admin-permissions" col='can_editrpl' addon type="checkbox" aria-label="Checkbox for following text input" />
                                            <span className="assignLabel" style={{color : canEditRPL ? '#92d36e':'#606060'}}>
                                                {
                                                    canEditRPL ? 'Assigned' : 'Assign role'
                                                }
                                            </span>
                                            <span style={{marginLeft:10}}>Access to set RPL for each assessment assigned for each candidate</span>
                                        </InputGroupText>
                                    </InputGroup>

                                    <InputGroup style={{display:'block'}}>
                                        <InputGroupText className={canViewCandidate ? 'selected' : ''}>
                                            <Input checked addon type="checkbox" aria-label="Checkbox for following text input" readOnly={true}
                                                onChange={(e) => {
                                                    this.setState({
                                                        canViewCandidate : !canViewCandidate
                                                    })
                                                }}
                                            />
                                            <span className="assignLabel" style={{color : canViewCandidate ? '#92d36e':'#606060'}}>
                                                {
                                                    canViewCandidate ? 'Assigned' : 'Assign role'
                                                }
                                            </span>
                                            <span style={{marginLeft:10}}>Access to view the resports of the candidates</span>

                                        </InputGroupText>
                                    </InputGroup>
                                    
                                    {
                                        canViewCandidate ?

                                        <InputGroup style={{display:'block'}}>
                                            <InputGroupText className={canSeeSubOrdReports ? 'selected nested' : 'nested'}>
                                                <Input type="checkbox" checked={canSeeSubOrdReports} name="can_seesubord_reports" className="admin-permissions" col='can_seesubord_reports' />
                                                <span className="assignLabel" style={{color : canSeeSubOrdReports ? '#92d36e':'#606060'}}>
                                                    {
                                                        canSeeSubOrdReports ? 'Assigned' : 'Assign role'
                                                    }
                                                </span>
                                                <span style={{marginLeft:10}}>Only {userData.FIRST_NAME} subordinates</span> 
                                            </InputGroupText>                                        
                                        </InputGroup>

                                        : null
                                    }

                                    {
                                        canViewCandidate ?

                                        <InputGroup style={{display:'block'}}>
                                            <InputGroupText className={canViewReports ? 'selected nested' : 'nested'}>
                                                <Input type="checkbox" checked={canViewReports} name="can_viewreports"  className="admin-permissions" col='can_viewreports' />
                                                <span className="assignLabel" style={{color : canViewReports ? '#92d36e':'#606060'}}>
                                                    {
                                                        canViewReports ? 'Assigned' : 'Assign role'
                                                    }
                                                </span>
                                                <span style={{marginLeft:10}}>Full access to reporting module</span> 
                                            </InputGroupText>                                        
                                        </InputGroup>

                                        : null
                                    }

                                    
                                    <InputGroup style={{display:'block'}}>
                                        <InputGroupText className={canAddEmp ? 'selected' : ''}>
                                            <Input checked={canAddEmp} className="admin-permissions" col='can_addemp' addon type="checkbox" aria-label="Checkbox for following text input" />
                                            <span className="assignLabel" style={{color : canAddEmp ? '#92d36e':'#606060'}}>
                                                {
                                                    canAddEmp ? 'Assigned' : 'Assign role'
                                                }
                                            </span>
                                            <span style={{marginLeft:10}}>Allow access to add new employee</span>
                                            
                                        </InputGroupText>
                                    </InputGroup>

                                    <InputGroup style={{display:'block'}}>
                                        <InputGroupText className={canEditSubOrds ? 'selected' : ''}>
                                            <Input checked={canEditSubOrds} className="admin-permissions" col='can_editsubords' addon type="checkbox" aria-label="Checkbox for following text input" />
                                            <span className="assignLabel" style={{color : canEditSubOrds ? '#92d36e':'#606060'}}>
                                                {
                                                    canEditSubOrds ? 'Assigned' : 'Assign role'
                                                }
                                            </span>
                                            <span style={{marginLeft:10}}>Allow access to allocate or delete subordinate for candidate</span>
                                        </InputGroupText>
                                    </InputGroup>

                                </Container>
                            </Col>
                        </Row>
                    </TabPane>
                    <TabPane tabId="am">

                        <Row style={{margin: 0}}>
                            
                        </Row>

                    </TabPane>
                   
                </TabContent>
               
            </Container>

        </div>
        
        {/* <Footer /> */}
      </div>
    );
  }
}

SubAdminProfile.propTypes = {
    updateProfileData: PropTypes.func.isRequired,
    assignNewAdmin : PropTypes.func.isRequired,
    assignNewSubordinate : PropTypes.func.isRequired,
    adminFetchEmployeeInformation : PropTypes.func.isRequired,
    removeSubordinate : PropTypes.func.isRequired,
    updateSubAdmin : PropTypes.func.isRequired,
    getAdditionalData : PropTypes.func.isRequired
}

const mapStatesToProps = state => ({
    assignedSupDataSuccessAdmin : state.webRed.assignedSupDataSuccessAdmin,
    assignedSubordinateDataSuccessAdmin : state.webRed.assignedSubordinateDataSuccessAdmin,
    removedSubordinateDataSuccessAdmin : state.webRed.removedSubordinateDataSuccessAdmin,
    additionalData : state.webRed.additionalData
})

export default connect( mapStatesToProps, { updateProfileData, assignNewAdmin, assignNewSubordinate, adminFetchEmployeeInformation, removeSubordinate, updateSubAdmin, getAdditionalData})( SubAdminProfile );