import {
	_addedDataSuccessfull,
	_triggerNextToPin,
	_triggerLoginBtn,
	_triggerSwitchTab,
	_getAllAssessment,
	_assessmentFormDone,
	_switchBranchFromSidebar,
	_getAllBranchesList,
	_proceedToAssessments,
	_updateRequestSent,
	_fetchEmployeeDataAdmin,
	_assignedSupervisorSuccess,
	_assignedSubordinateSuccess,
	_removedSubordinateSuccess,
	_fetchedAllCompetenciesListAdmin,
	_getScoreCard,
	_getScoreCardStatus,
	_getSubAdmins,
	_restValue,
	_validateEmailId,
	_getAllAssessStats,
	_getAllEmpReportsData,
	_getAssessmentsHistory,
	_getAllAdminAuditLogs,
	_showScoreCardLoader,
	_totalPeopleAssesst,
	_fetchMainCompetencies,
	_getSubCompetency,
	_allCompetencyAvg,
	_fetchDivisions,
	_competencyByDivision,
	_competencyBySecions,
	_branchAvgDme,
	_dmeByDivision,
	_dmeBySections,
	_dmeCategorised,
	_candidateDistribution,
	_competencyYear,
	_totalPeopleAssesstCompare,
	_competencyAvgCompare,
	_competencyByDivisionCompare,
	_competencyBySecionsCompare,
	_branchAvgDmeCompare,
	_compareDmeByDivision,
	_compareDmeBySections,
	_compareDmeCategorised,
	_totalPeopleAssesstAllBranches,
	_fetchMainCompetenciesAllBranches,
	_getSubCompetencyAllBranches,
	_candidateDistributionAllBranches,
	_dmeCategorisedAllBranches,
	_totalPeopleAssesstCompareAllBranches,
	_competencyAvgCompareAllBranches,
	_competencyByDivisionCompareAllBranches,
	_competencyBySecionsCompareAllBranches,
	_branchAvgDmeCompareAllBranches,
	_compareDmeByDivisionAllBranches,
	_compareDmeBySectionsAllBranches,
	_compareDmeCategorisedAllBranches,
	_fetchAdditionalData,
	_fetchMainGroupsData,
	_fetchSubGroupsData,
	_fetchEmployeeActivityLogs,
	_showHideLoader,
	_searchEmployeeLogs,
	_resetEmpId,
	_assessHistDeperts,
	_setPrfileYear

} from './types';

import axios from 'axios';
import { getBaseUrl, accessToken, getYear, getAdminUserData, generateToken, relogin } from './utility';
import * as Sentry from "@sentry/react";

// import ndjsonStream from 'can-ndjson-stream';
// import { platform } from 'os';

// import tokenProvider from 'axios-token-interceptor';

// const instance = axios.create({
// 	baseURL: 'https://iponym.com/getdata'
// });

// 	// Configure the provider with the necessary options.
// 	const options = {
// 		token: 'abc',
// 		headers: {
// 			'Content-Type': 'application/json'
// 		}
// 	};
// 	instance.interceptors.request.use(tokenProvider(options));

export const uploadDataBranch = (branch_name, dataToUpload) => async (dispatch) => {

	// console.log('index', index);

	var bodyFormData = new FormData();
	bodyFormData.set('final_data', JSON.stringify(dataToUpload));
	bodyFormData.set('branch_name', branch_name);
	bodyFormData.set('access_token', accessToken())
	bodyFormData.set('year', getYear());
	const token = generateToken();
	axios.post(getBaseUrl() + 'api/branch/add', bodyFormData, {
		// axios.post( 'http://localhost:8000/api/branch/add', bodyFormData, {
		headers: {
			'Content-Type': 'multipart/form-data',
			'authorizedToken': token,
			'appidadmin':localStorage.getItem('app_id_session'),
		}
	})
		.then((res) => {

			console.log(res);

			// if (res.data.status == "success") { // if success only
			// 	dispatch({
			// 		type: _addedDataSuccessfull,
			// 		payload: res.data.result
			// 	});
			// }
			// else {
			// 	dispatch({
			// 		type: _addedDataSuccessfull,
			// 		payload: 'error'
			// 	})
			// }

		}).catch((error) => {
			const errorMessage = error.response ? error.response.data.message : error.message;
			const statusCode = error.response ? error.response.status : 500;
			relogin(errorMessage, statusCode);
		});
};
export const uploadDataDept = (deptName, dataDept) => async (dispatch) => {
	var bodyFormData = new FormData();
	bodyFormData.set('dept_data', JSON.stringify(dataDept));
	bodyFormData.set('dept_name', deptName);
	bodyFormData.set('access_token', accessToken())
	bodyFormData.set('year', getYear());
	const token = generateToken();
	axios.post(getBaseUrl() + 'api/dept/add/er', bodyFormData, {
		// axios.post( 'http://localhost:8000/api/branch/add', bodyFormData, {
		headers: {
			'Content-Type': 'multipart/form-data',
			'authorizedToken': token,
			'appidadmin':localStorage.getItem('app_id_session')
		}
	})
		.then((res) => {

			console.log(res);

		})
		.catch((error) => {
			console.log(error);
		});
};
export const uploadEmpData = (deptName, dataDept) => async (dispatch) => {
	var bodyFormData = new FormData();
	bodyFormData.set('dept_data', JSON.stringify(dataDept));
	bodyFormData.set('dept_name', deptName);
	bodyFormData.set('access_token', accessToken())
	bodyFormData.set('year', getYear());
	const token = generateToken();
	axios.post(getBaseUrl() + 'api/add/emp/excel/datasw', bodyFormData, {
		// axios.post( 'http://localhost:8000/api/branch/add', bodyFormData, {
		headers: {
			'Content-Type': 'multipart/form-data',
			'authorizedToken': token,
			'appidadmin':localStorage.getItem('app_id_session')
		}
	})
		.then((res) => {

			console.log(res);

		})
		.catch((error) => {
			const errorMessage = error.response ? error.response.data.message : error.message;
			const statusCode = error.response ? error.response.status : 500;
			relogin(errorMessage, statusCode);
		});
};

export const submitAssessmentForm = (data) => async (dispatch) => {

	var bodyFormData = new FormData();
	bodyFormData.set('branch_id', data.branch_id);
	bodyFormData.set('id_number', data.id_number);
	bodyFormData.set('last_name', data.last_name);
	bodyFormData.set('first_name', data.first_name);
	bodyFormData.set('mi', data.mi);
	bodyFormData.set('name', data.name);
	bodyFormData.set('designation', data.designation);
	bodyFormData.set('section', data.section);
	bodyFormData.set('division', data.division);
	bodyFormData.set('JG', data.pc);
	bodyFormData.set('branch', data.branch);
	bodyFormData.set('dept', data.dept);
	bodyFormData.set('subcompt_keyword', data.subcompt_keyword);
	bodyFormData.set('subcompt_rpl', data.subcompt_rpl);
	bodyFormData.set('is_sup', data.is_sup);
	bodyFormData.set('rating_val', data.rating_val);
	bodyFormData.set('access_token', accessToken());
	bodyFormData.set('year', getYear());
	console.log(bodyFormData);
	const token = generateToken();
	axios.post(getBaseUrl() + 'api/competencies/forms/data/import', bodyFormData, {
		// axios.post( 'http://localhost:8000/api/competencies/forms/data/import', bodyFormData, {
		headers: {
			'Content-Type': 'multipart/form-data',
			'authorizedToken': token,
			'appidadmin':localStorage.getItem('app_id_session')
		}
	})
		.then((res) => {

			console.log(res);

			if (res.data.status == "success") { // if success only
				dispatch({
					type: _assessmentFormDone,
					payload: {
						data: res.data.result,
						isSup: data.is_sup
					}
				});
			}
			else {
				dispatch({
					type: _assessmentFormDone,
					payload: 'error'
				})
			}

		})
		.catch((error) => {
			console.log(error);
			dispatch({
				type: _assessmentFormDone,
				payload: error
			});
				const errorMessage = error.response ? error.response.data.message : error.message;
				const statusCode = error.response ? error.response.status : 500;
				relogin(errorMessage, statusCode);

		});
};

export const submitPASForm = () => async (dispatch) => {
	dispatch({
		type: _assessmentFormDone,
		payload: {
			data: [],
			isSup: 0
		}
	});
}
// export const triggerNextToSecurityPin = () => async (dispatch) => {

// 	dispatch({
// 		type: _triggerNextToPin,
// 		payload: {
// 			message : "success",
// 			random: Math.random()
// 		}
// 	});

// };

export const loginWithVerificationCode = (employee_id, verification_code, is_admin, admin_type) => async (dispatch) => {


	var bodyFormData = new FormData();

	bodyFormData.set('employee_id', employee_id);
	bodyFormData.set('verification_code', verification_code);
	bodyFormData.set('admin_type', admin_type);
	bodyFormData.set('access_token', accessToken());
	bodyFormData.set('year', getYear());

	if (is_admin == 1) {
		bodyFormData.set('is_admin', is_admin);
	}
	const token = generateToken();
	axios.post(getBaseUrl() + 'api/user/verify/code', bodyFormData, {
		headers: {
			'Content-Type': 'multipart/form-data',
			'authorizedToken': token,
			'appidadmin':localStorage.getItem('app_id_session')
		}
	})
		.then((res) => {
			console.log(res);
			// if (res.data.status == "success") { // if success only

			dispatch({
				type: _triggerLoginBtn,
				payload: {
					data: res.data,
					random: Math.random()
				}
			});
			// }
			// else {
			// 	dispatch({
			// 		type: _triggerNextToPin,
			// 		payload: {
			// 			data : res.data,
			// 			random : Math.random()
			// 		}
			// 	});
			// }
		})
		.catch((error) => {
			console.log(error);
			dispatch({
				type: _triggerLoginBtn,
				payload: 'error'
			});
		
				const errorMessage = error.response ? error.response.data.message : error.message;
				const statusCode = error.response ? error.response.status : 500;
				relogin(errorMessage, statusCode);
			});

};

export const triggerSwitchMainTab = (tab_name) => async (dispatch) => {

	dispatch({
		type: _triggerSwitchTab,
		payload: {
			tabName: tab_name,
			random: Math.random()
		}
	});

};

export const sendValidateEmpId = (employee_id, is_resend) => async (dispatch) => {

	// var bodyFormData = new FormData();
	var userParam = {};
	var isDev = window.location.href.search("isDev=1");

	if (isDev != "-1") {
		// bodyFormData.set('isDev', 1);
		userParam['isDev'] = 1
	}
	// console.log("bodyFormData:",bodyFormData);
	// return false;
	// bodyFormData.set('employee_id', employee_id);
	// bodyFormData.set('access_token', accessToken());
	// bodyFormData.set('year', getYear());
	userParam['employee_id'] = employee_id;
	userParam['access_token'] = accessToken();
	userParam['year'] = getYear();

	localStorage.setItem('emppid',employee_id);

	Sentry.setUser({ 'employee_id': employee_id });

	// var sendingParams = {type:"login_param", user_data : userParam}

	// Sentry.captureMessage(JSON.stringify(sendingParams));

	const token = generateToken();


	axios.post(getBaseUrl() + 'api/user/login', userParam, {

		headers: {
			'Content-Type': 'application/json',
			'authorizedToken': token,
			'appidadmin':localStorage.getItem('app_id_session') // Add your custom token here
		}
	})
		.then((res) => {
			console.log(res);
			// if (res.data.status == "success") { // if success only
			dispatch({
				type: _triggerNextToPin,
				payload: {
					data: res.data,
					employee_id: employee_id,
					resend: is_resend,
					random: Math.random()
				}
			});
			// }
			// else {
			// 	dispatch({
			// 		type: _triggerNextToPin,
			// 		payload: {
			// 			data : res.data,
			// 			random : Math.random()
			// 		}
			// 	});
			// }
		})
		.catch((error) => {
			console.log(error);
			var errMessage = { type: "error_login", msg: error }
			Sentry.captureMessage(JSON.stringify(errMessage));
			dispatch({
				type: _triggerNextToPin,
				payload: 'error'
			});

			const errorMessage = error.response ? error.response.data.message : error.message;
				const statusCode = error.response ? error.response.status : 500;
				relogin(errorMessage, statusCode);
		
		});
}

export const getAllAssessments = (employee_id) => async (dispatch) => {

	var bodyFormData = new FormData();

	bodyFormData.set('employee_id', employee_id);
	bodyFormData.set('access_token', accessToken());
	bodyFormData.set('year', getYear());

	const token = generateToken();

	axios.post(getBaseUrl() + 'api/frontend/emp/competencies', bodyFormData, {
		headers: {
			'Content-Type': 'multipart/form-data',
			'authorizedToken': token,
			'appidadmin':localStorage.getItem('app_id_session') // Add your custom token here
		}
	})
		.then((res) => {
			console.log(res);
			if (res.data.status == "success") { // if success only
				dispatch({
					type: _getAllAssessment,
					payload: {
						result: res.data.result,
						random: Math.random()
					}
				});
			}
			else {
				dispatch({
					type: _getAllAssessment,
					payload: {
						result: "error",
						random: Math.random()
					}
				});
			}
		})
		.catch((error) => {
			console.log(error);
			dispatch({
				type: _getAllAssessment,
				payload: {
					result: "error",
					random: Math.random()
				}
			});

				// const errorMessage = error.response ? error.response.data.message : error.message;
				// const statusCode = error.response ? error.response.status : 500;
				// relogin(errorMessage, statusCode);

				if (error.response) {
					const errorMessage = error.response.data.message || 'An error occurred';
					const statusCode = error.response.status || 500;
					relogin(errorMessage, statusCode);
				} else {
					const errorMessage = error.message || 'Network error';
					relogin(errorMessage, 500);  // Fallback status code for network issues
				}

		});
}

export const updateBranchAction = (selectedBranchId, selectedBranch) => async (dispatch) => {

	dispatch({
		type: _switchBranchFromSidebar,
		payload: {
			selectedBranch: {
				id: selectedBranchId,
				name: selectedBranch,
				random: Math.random()
			}
		}
	});

};

// not defined header 
export const getAllBranches = () => async (dispatch) => {
	// axios.get(getBaseUrl() + 'api/branches/get?access_token=' + accessToken())
	const token = generateToken();
	axios.get(getBaseUrl() + 'api/branches/get?access_token=' + accessToken() + '&year=' + getYear(),{
		headers: {
			'Content-Type': 'multipart/form-data',
			'authorizedToken': token,
			'appidadmin':localStorage.getItem('app_id_session') // Add your custom token here
		}
	})
		.then((res) => {
			console.log(res);
			if (res.data.status == "success") { // if success only
				dispatch({
					type: _getAllBranchesList,
					payload: {
						result: res.data.result,
						random: Math.random()
					}
				});
			}
			else {
				dispatch({
					type: _getAllBranchesList,
					payload: {
						result: res.data.result,
						random: Math.random()
					}
				});
			}
		})
		.catch((error) => {
			console.log(error);
			dispatch({
				type: _getAllBranchesList,
				payload: 'error'
			});
			
				const errorMessage = error.response ? error.response.data.message : error.message;
				const statusCode = error.response ? error.response.status : 500;
				relogin(errorMessage, statusCode);
		});
}


export const proceedToAssessments = () => async (dispatch) => {

	dispatch({
		type: _proceedToAssessments,
		payload: {
			random: Math.random()
		}
	});

};

export const sendUpdateProfileRequest = (employee_id, updateRequest) => async (dispatch) => {

	var bodyFormData = new FormData();

	bodyFormData.set('employee_id', employee_id);
	bodyFormData.set('incorrect_data', JSON.stringify(updateRequest));
	bodyFormData.set('access_token', accessToken());
	bodyFormData.set('year', getYear());
	const token = generateToken();
	axios.post(getBaseUrl() + 'api/user/incorrect/information', bodyFormData, {
		headers: {
			'Content-Type': 'multipart/form-data',
			'authorizedToken': token,
			'appidadmin':localStorage.getItem('app_id_session') // Add your custom token here
		}
	})
		.then((res) => {
			// console.log(res);
			if (res.data.status == "success") { // if success only
				dispatch({
					type: _updateRequestSent,
					payload: {
						result: res.data.result,
						random: Math.random()
					}
				});
			}
			else {
				dispatch({
					type: _updateRequestSent,
					payload: {
						result: res.data.result,
						random: Math.random()
					}
				});
			}
		})
		.catch((error) => {
			// console.log(error);
			dispatch({
				type: _updateRequestSent,
				payload: 'error'
			});
			
				const errorMessage = error.response ? error.response.data.message : error.message;
				const statusCode = error.response ? error.response.status : 500;
				relogin(errorMessage, statusCode);
			
		});
}


export const updateAnswerStatusPagcor = (assess_id, employee_id, nu_userid, question_id, ans_id, is_sup, sup_id) => async (dispatch) => {

	var bodyFormData = new FormData();

	bodyFormData.set('assess_id', assess_id);
	bodyFormData.set('employee_id', employee_id);
	bodyFormData.set('nu_userid', nu_userid);
	bodyFormData.set('question_id', question_id);
	bodyFormData.set('ans_id', ans_id);
	bodyFormData.set('is_sup', is_sup);
	bodyFormData.set('sup_id', sup_id);
	bodyFormData.set('access_token', accessToken());
	bodyFormData.set('year', getYear());
	const token = generateToken();
	axios.post(getBaseUrl() + 'api/save/user/assessment/logs', bodyFormData, {
		headers: {
			'Content-Type': 'multipart/form-data',
			'authorizedToken': token,
			'appidadmin':localStorage.getItem('app_id_session') // Add your custom token here
		}
	})
		.then((res) => {
			if (employee_id == "00-4032") {
				Sentry.setUser({ 'employee_id': employee_id });
				let logData = res.data;
				logData['is_logsaved'] = 1;
				logData['nu_userid'] = nu_userid;
				logData['assess_id'] = assess_id;
				logData['question_id'] = question_id;
				logData['ans_id'] = ans_id;
				logData['event'] = Math.random();
				Sentry.captureMessage(JSON.stringify(logData))
			}
			// console.log("This is logs response:",res.data);

		})
		.catch((error) => {
			
				const errorMessage = error.response ? error.response.data.message : error.message;
				const statusCode = error.response ? error.response.status : 500;
				relogin(errorMessage, statusCode);
			
			console.log(error);
		});
}

export const adminFetchEmployeeInformation = (employee_id, year) => async (dispatch) => {

	var bodyFormData = new FormData();

	bodyFormData.set('employee_id', employee_id);
	bodyFormData.set('access_token', accessToken());
	if (year)
		bodyFormData.set('year', year);
	const token = generateToken();
	axios.post(getBaseUrl() + 'api/admin/employee/profile', bodyFormData, {
		headers: {
			'Content-Type': 'multipart/form-data',
			'authorizedToken': token,
			'appidadmin':localStorage.getItem('app_id_session') // Add your custom token here
		}
	})
		.then((res) => {
			if (res.data.status == "success") { // if success only
				dispatch({
					type: _fetchEmployeeDataAdmin,
					payload: {
						result: res.data.result,
						random: Math.random()
					}
				});
			}
			else {
				dispatch({
					type: _fetchEmployeeDataAdmin,
					payload: {
						result: res.data.result,
						random: Math.random()
					}
				});
			}
		})
		.catch((error) => {
			dispatch({
				type: _fetchEmployeeDataAdmin,
				payload: 'error'
			});
			
				const errorMessage = error.response ? error.response.data.message : error.message;
				const statusCode = error.response ? error.response.status : 500;
				relogin(errorMessage, statusCode);
			
		});
}

export const updateProfileData = (employee_id, col, val) => async (dispatch) => {

	var bodyFormData = new FormData();
	var adminData = getAdminUserData();
	var adminId = adminData != false ? adminData.admin.admin_id : 0;

	bodyFormData.set('employee_id', employee_id);
	bodyFormData.set('col', col);
	bodyFormData.set('val', val);
	bodyFormData.set('access_token', accessToken());
	bodyFormData.set('year', getYear());
	bodyFormData.set('admin_id', adminId);
	const token = generateToken();

	axios.post(getBaseUrl() + 'api/admin/employee/profile/update', bodyFormData, {
		headers: {
			'Content-Type': 'multipart/form-data',
			'authorizedToken': token,
			'appidadmin':localStorage.getItem('app_id_session') // Add your custom token here
		}
	})
		.then((res) => {
			console.log(res);
			// if (res.data.status == "success") { // if success only
			// 	dispatch({
			// 		type: _fetchEmployeeDataAdmin,
			// 		payload: {
			// 			result : res.data.result,
			// 			random : Math.random()
			// 		}
			// 	});
			// }
			// else {
			// 	dispatch({
			// 		type: _fetchEmployeeDataAdmin,
			// 		payload: {
			// 			result : res.data.result,
			// 			random : Math.random()
			// 		}
			// 	});
			// }
		})
		.catch((error) => {
			console.log(error);
			
				const errorMessage = error.response ? error.response.data.message : error.message;
				const statusCode = error.response ? error.response.status : 500;
				relogin(errorMessage, statusCode);
			
			// dispatch({
			// 	type: _fetchEmployeeDataAdmin,
			// 	payload: 'error'
			// });
		});
}

export const assignNewAdmin = (admin_id, supervisor_id, employee_id) => async (dispatch) => {

	var bodyFormData = new FormData();

	bodyFormData.set('admin_id', admin_id);
	bodyFormData.set('supervisor_id', supervisor_id);
	bodyFormData.set('employee_id', employee_id);
	bodyFormData.set('access_token', accessToken());
	bodyFormData.set('year', getYear());
	const token = generateToken();

	axios.post(getBaseUrl() + 'api/admin/supervisor/assign', bodyFormData, {
		headers: {
			'Content-Type': 'multipart/form-data',
			'authorizedToken': token,
			'appidadmin':localStorage.getItem('app_id_session') // Add your custom token here
		}
	})
		.then((res) => {
			// console.log(res);
			if (res.data.status == "success") { // if success only
				dispatch({
					type: _assignedSupervisorSuccess,
					payload: {
						result: res.data.result,
						random: Math.random()
					}
				});
			}
			else {
				dispatch({
					type: _assignedSupervisorSuccess,
					payload: {
						result: res.data,
						random: Math.random()
					}
				});
			}
		})
		.catch((error) => {
			// console.log(error);
			dispatch({
				type: _assignedSupervisorSuccess,
				payload: 'error'
			});
			// .catch((error) => {
				const errorMessage = error.response ? error.response.data.message : error.message;
				const statusCode = error.response ? error.response.status : 500;
				relogin(errorMessage, statusCode);
			// });
		});
}


export const assignNewSubordinate = (admin_id, supervisor_id, employee_id) => async (dispatch) => {

	var bodyFormData = new FormData();

	bodyFormData.set('admin_id', admin_id);
	bodyFormData.set('supervisor_id', supervisor_id);
	bodyFormData.set('employee_id', employee_id);
	bodyFormData.set('access_token', accessToken());
	bodyFormData.set('year', getYear());
	const token = generateToken();

	axios.post(getBaseUrl() + 'api/admin/subords/assign', bodyFormData, {
		headers: {
			'Content-Type': 'multipart/form-data',
			'authorizedToken': token,
			'appidadmin':localStorage.getItem('app_id_session') // Add your custom token here
		}
	})
		.then((res) => {
			// console.log(res);
			if (res.data.status == "success") { // if success only
				dispatch({
					type: _assignedSubordinateSuccess,
					payload: {
						result: res.data.result,
						random: Math.random()
					}
				});
			}
			else {
				dispatch({
					type: _assignedSubordinateSuccess,
					payload: {
						result: res.data,
						random: Math.random()
					}
				});
			}
		})
		.catch((error) => {
			// console.log(error);
			dispatch({
				type: _assignedSubordinateSuccess,
				payload: 'error'
			});

				const errorMessage = error.response ? error.response.data.message : error.message;
				const statusCode = error.response ? error.response.status : 500;
				relogin(errorMessage, statusCode);
		});
}

export const removeSubordinate = (admin_id, supervisor_id, empId) => async (dispatch) => {

	// var bodyFormData = new FormData();

	// bodyFormData.set('admin_id', admin_id);
	// bodyFormData.set('supervisor_id', supervisor_id);
	// bodyFormData.set('employee_id', employee_id);
	const token = generateToken();
	axios.delete(getBaseUrl() + 'api/admin/subords/remove/' + admin_id + '/' + supervisor_id + '/' + empId + '?access_token=' + accessToken() + '&year=' + getYear(), {
		headers: {
			'Content-Type': 'multipart/form-data',
			'authorizedToken': token,
			'appidadmin':localStorage.getItem('app_id_session') // Add your custom token here
		}
	})
		.then((res) => {
			// console.log(res);
			if (res.data.status == "success") { // if success only
				dispatch({
					type: _removedSubordinateSuccess,
					payload: {
						result: res.data.result,
						random: Math.random()
					}
				});
			}
		})
		.catch((error) => {
			// console.log(error);
			dispatch({
				type: _removedSubordinateSuccess,
				payload: 'error'
			});
			
				const errorMessage = error.response ? error.response.data.message : error.message;
				const statusCode = error.response ? error.response.status : 500;
				relogin(errorMessage, statusCode);
			
		});
}


export const getAllAssessmentsForAdmin = (employee_id) => async (dispatch) => {

	var bodyFormData = new FormData();

	bodyFormData.set('employee_id', employee_id);
	bodyFormData.set('access_token', accessToken());
	bodyFormData.set('year', getYear());
	const token = generateToken();

	axios.post(getBaseUrl() + 'api/admin/all/competencies', bodyFormData, {
		headers: {
			'Content-Type': 'multipart/form-data',
			'authorizedToken': token,
			'appidadmin':localStorage.getItem('app_id_session') // Add your custom token here
		}
	})
		.then((res) => {
			// console.log(res);
			if (res.data.status == "success") { // if success only
				dispatch({
					type: _fetchedAllCompetenciesListAdmin,
					payload: {
						result: res.data.result,
						random: Math.random()
					}
				});
			}
		})
		.catch((error) => {
			// console.log(error);
			dispatch({
				type: _fetchedAllCompetenciesListAdmin,
				payload: 'error'
			});
				const errorMessage = error.response ? error.response.data.message : error.message;
				const statusCode = error.response ? error.response.status : 500;
				relogin(errorMessage, statusCode);
		});
}




export const getScoreCardData = (employee_id, year) => async (dispatch) => {
	const token = generateToken();
	axios.post(getBaseUrl() + 'api/user/assessments/stats?employee_id=' + employee_id + '&year=' + year + '&access_token=' + accessToken(), {}, {
		headers: {
			'Content-Type': 'application/json',
			'authorizedToken': token,
			'appidadmin':localStorage.getItem('app_id_session') // Add your custom token here
		}
	})
		.then((res) => {
			console.log(res.data.result);
			if (res.data.status == "success") { // if success only
				dispatch({
					type: _getScoreCard,
					payload: {
						result: res.data.result,
					}
				});
			}
		})
		.catch((error) => {
			// console.log(error);
			dispatch({
				type: _getScoreCard,
				payload: 'error'
			});
			
				const errorMessage = error.response ? error.response.data.message : error.message;
				const statusCode = error.response ? error.response.status : 500;
				relogin(errorMessage, statusCode);
			
		});
}

export const getScoreCardDataStatus = (employee_id, year) => async (dispatch) => {
	const token = generateToken();
	axios.get(getBaseUrl() + 'api/user/assessments/status?employee_id=' + employee_id + '&access_token=' + accessToken() + '&year=' + getYear(), {
		headers: {
			'Content-Type': 'application/json',
			'authorizedToken': token,
			'appidadmin':localStorage.getItem('app_id_session') // Add your custom token here
		}
	})
		.then((res) => {
			console.log(res.data);
			if (res.data.status == "success") { // if success only
				dispatch({
					type: _getScoreCardStatus,
					payload: {
						result: res.data,
					}
				});
			}
		})
		.catch((error) => {
			// console.log(error);
			dispatch({
				type: _getScoreCardStatus,
				payload: 'error'
			});
			
				const errorMessage = error.response ? error.response.data.message : error.message;
				const statusCode = error.response ? error.response.status : 500;
				relogin(errorMessage, statusCode);
			
		});
}
/**
 * fetch all sub admins 
 */
export const getSubAdmins = () => async (dispatch) => {
	const token = generateToken();
	axios.get(getBaseUrl() + 'api/admin/subadmin/get?access_token=' + accessToken() + '&year=' + getYear(), {
		headers: {
			'Content-Type': 'application/json',
			'authorizedToken': token,
			'appidadmin':localStorage.getItem('app_id_session') // Add your custom token here
		}
	})
		.then((res) => {
			console.log("sub admins:", res.data);
			if (res.data.status == "success") { // if success only

				dispatch({
					type: _getSubAdmins,
					payload: {
						result: res.data,
					}
				});
			}
		})
		.catch((error) => {
			// console.log(error);
			dispatch({
				type: _getSubAdmins,
				payload: 'error'
			});
			// .catch((error) => {
				const errorMessage = error.response ? error.response.data.message : error.message;
				const statusCode = error.response ? error.response.status : 500;
				relogin(errorMessage, statusCode);
			// });
		});
}
/**
 * add the sub admin
 */
export const addNewSubAdmin = (employeeId, callback) => async (dispatch) => {

	var bodyFormData = new FormData();

	bodyFormData.set('employee_id', employeeId);
	bodyFormData.set('access_token', accessToken());
	// bodyFormData.set('can_add', '0');
	// bodyFormData.set('can_edit', '0');
	// bodyFormData.set('can_delete', '0');
	bodyFormData.set('year', getYear());
	const token = generateToken();
	axios.post(getBaseUrl() + 'api/admin/subadmin/add', bodyFormData, {
		headers: {
			'Content-Type': 'application/json',
			'authorizedToken': token,
			'appidadmin':localStorage.getItem('app_id_session') // Add your custom token here
		}
	})
		.then((res) => {
			console.log("sub admins:", res.data);
			if (res.data.status == "success") { // if success only

				// dispatch({
				// 	type: _getSubAdmins,
				// 	payload: {
				// 		result : res.data,
				// 	}
				// });
				if (typeof callback === 'function')
					callback(res.data);
			}
			else {

				if (typeof callback === 'function')
					callback(res.data);
			}
		})
		.catch((error) => {
			if (typeof callback === 'function')
				callback(0);
			// console.log(error);
			// dispatch({
			// 	type: _getSubAdmins,
			// 	payload: 'error'
			// });
			// .catch((error) => {
				const errorMessage = error.response ? error.response.data.message : error.message;
				const statusCode = error.response ? error.response.status : 500;
				relogin(errorMessage, statusCode);
			// });
		});
}
/**
 * add the sub admin
 */
export const removeSubAdmin = (subadminId, callback) => async (dispatch) => {

	const token = generateToken();
	axios.delete(getBaseUrl() + 'api/admin/subadmin/remove/' + subadminId + '?access_token=' + accessToken() + '&year=' + getYear(), {
		headers: {
			'Content-Type': 'application/json',
			'authorizedToken': token,
			'appidadmin':localStorage.getItem('app_id_session') // Add your custom token here
		}
	})
		.then((res) => {
			console.log("sub admins:", res.data);
			if (res.data.status == "success") { // if success only

				// dispatch({
				// 	type: _getSubAdmins,
				// 	payload: {
				// 		result : res.data,
				// 	}
				// });
				if (typeof callback === 'function')
					callback(res.data);
			}
			else {

				if (typeof callback === 'function')
					callback(res.data);
			}
		})
		.catch((error) => {
			if (typeof callback === 'function')
				callback(0);
			// .catch((error) => {
				const errorMessage = error.response ? error.response.data.message : error.message;
				const statusCode = error.response ? error.response.status : 500;
				relogin(errorMessage, statusCode);
			// });
			// console.log(error);
			// dispatch({
			// 	type: _getSubAdmins,
			// 	payload: 'error'
			// });
		});
}
/**
 * update the sub admin 
 */
export const updateSubAdmin = (subadminId, col, val, callback) => async (dispatch) => {

	var bodyFormData = new FormData();

	bodyFormData.set('admin_id', subadminId);
	bodyFormData.set('col', col);
	bodyFormData.set('val', val);
	bodyFormData.set('access_token', accessToken());
	bodyFormData.set('year', getYear());
	const token = generateToken();
	axios.post(getBaseUrl() + 'api/admin/subadmin/update', bodyFormData, {
		headers: {
			'Content-Type': 'application/json',
			'authorizedToken': token,
			'appidadmin':localStorage.getItem('app_id_session') // Add your custom token here
		}
	}).then((res) => {
		console.log("Update Role Access then Block", res)
			console.log("sub admins:", res.data);
			if (res.data.status == "success") { // if success only

				// dispatch({
				// 	type: _getSubAdmins,
				// 	payload: {
				// 		result : res.data,
				// 	}
				// });
				if (typeof callback === 'function')
					callback(res.data);
			}
			else {

				if (typeof callback === 'function')
					callback(res.data);
			}
		})
		.catch((error) => {
			const errorMessage = error.response ? error.response.data.message : error.message;
			const statusCode = error.response ? error.response.status : 500;
			relogin(errorMessage, statusCode);
			if (typeof callback === 'function')
				callback({ statusCode, errorMessage });
		});
}
/**
 * get the sub admin data
 */
export const fetchAdminData = (employeeId, callback) => async () => {
	const token = generateToken();
	axios.get(getBaseUrl() + 'api/admin/subadmin/fetch?employee_id=' + employeeId + '&access_token=' + accessToken() + '&year=' + getYear(), {
		headers: {
			'Content-Type': 'application/json',
			'authorizedToken': token,
			'appidadmin':localStorage.getItem('app_id_session') // Add your custom token here
		}
	})
		.then((res) => {
			console.log("sub admins:", res.data);
			if (res.data.status == "success") { // if success only

				if (typeof callback === 'function')
					callback(res.data);
			}
			else {

				if (typeof callback === 'function')
					callback(res.data);
			}
		})
		.catch((error) => {
			if (typeof callback === 'function')
				callback(0);
			// .catch((error) => {
				const errorMessage = error.response ? error.response.data.message : error.message;
				const statusCode = error.response ? error.response.status : 500;
				relogin(errorMessage, statusCode);
			// });

		});
}
/**
 * for the resting the value of veriable
 */
export const restObjVal = () => async (dispatch) => {
	localStorage.removeItem('saveAnswers');
	//alert('hi');
	// dispatch({
	// 	type: _restValue,
	// 	payload: {
	// 		data : {},
	// 		random : Math.random()
	// 	}
	// })
}
/**
 * validate the employeeid
 * 
 * @param employeeId
 */
export const validateEmpId = (employeeId) => async (dispatch) => {
	const token = generateToken();
	axios.get(getBaseUrl() + 'api/admin/validate/employeeid?employee_id=' + employeeId + '&access_token=' + accessToken() + '&year=' + getYear(), {
		headers: {
			'Content-Type': 'application/json',
			'authorizedToken': token,
			'appidadmin':localStorage.getItem('app_id_session') // Add your custom token here
		}
	})
		.then((res) => {
			console.log("sub admins:", res.data);
			if (res.data.status == "success") { // if success only

				dispatch({
					type: _validateEmailId,
					payload: {
						result: res.data,
					}
				});
			}
		})
		.catch((error) => {
			// console.log(error);
			dispatch({
				type: _validateEmailId,
				payload: 'error'
			});
			// .catch((error) => {
				const errorMessage = error.response ? error.response.data.message : error.message;
				const statusCode = error.response ? error.response.status : 500;
				relogin(errorMessage, statusCode);
			// });
		});
}
/**
 * validate the employee email
 * 
 * @param selectedEmail
 * @param callback
*/
export const validateEmpEmail = (selectedEmail, callback) => async (dispatch) => {
	const token = generateToken();
	axios.get(getBaseUrl() + 'api/admin/validate/employee/email?email=' + selectedEmail + '&access_token=' + accessToken() + '&year=' + getYear(), {
		headers: {
			'Content-Type': 'application/json',
			'authorizedToken': token,
			'appidadmin':localStorage.getItem('app_id_session') // Add your custom token here
		}
	})
		.then((res) => {
			console.log("sub admins:", res.data);
			if (res.data.status == "success") { // if success only

				if (typeof callback === 'function')
					callback(res.data);
			}
			else {

				if (typeof callback === 'function')
					callback(res.data);
			}
		})
		.catch((error) => {
			if (typeof callback === 'function')
				callback(0);
			// .catch((error) => {
				const errorMessage = error.response ? error.response.data.message : error.message;
				const statusCode = error.response ? error.response.status : 500;
				relogin(errorMessage, statusCode);
			// });

		});
}

/**
 * validate the employee email
 * 
 * @param addData
 * @param callback
*/
export const addEmp = (addData, callback) => async (dispatch) => {
	const token = generateToken();
	axios.post(getBaseUrl() + 'api/admin/employee/add?access_token=' + accessToken() + '&year=' + getYear(), addData, {
		headers: {
			'Content-Type': 'application/json',
			'authorizedToken': token,
			'appidadmin':localStorage.getItem('app_id_session') // Add your custom token here
		}
	})
		.then((res) => {
			console.log("sub admins:", res.data);
			if (res.data.status == "success") { // if success only

				if (typeof callback === 'function')
					callback(res.data);
			}
			else {

				if (typeof callback === 'function')
					callback(res.data);
			}
		})
		.catch((error) => {
			// .catch((error) => {
				const errorMessage = error.response ? error.response.data.message : error.message;
				const statusCode = error.response ? error.response.status : 500;
				relogin(errorMessage, statusCode);
			// });
			if (typeof callback === 'function')
				callback(0);

		});
}

export const fetchCompleteStats = (filterData) => async (dispatch) => {

	// const token = generateToken();
	// 'authorizedToken': token // Add your custom token here

	if (filterData == '') {
		const token = generateToken();
		axios.get(getBaseUrl() + 'api/admin/assessment/statistics/fetch?access_token=' + accessToken() + '&year=' + getYear(),{
			headers: {
				'Content-Type': 'application/json',
				'authorizedToken': token,
			'appidadmin':localStorage.getItem('app_id_session') // Add your custom token here
			}
		})
			.then((res) => {
				dispatch({
					type: _getAllAssessStats,
					payload: {
						data: res.data.result,
						random: Math.random()
					}
				});
			})
			.catch((error) => {
				dispatch({
					type: _getAllAssessStats,
					payload: 'error'
				});
				// .catch((error) => {
					const errorMessage = error.response ? error.response.data.message : error.message;
					const statusCode = error.response ? error.response.status : 500;
					relogin(errorMessage, statusCode);
				// });
			})
	} else {
		const token = generateToken();
		axios.get(getBaseUrl() + 'api/admin/assessment/statistics/fetch?access_token=' + accessToken() + '&branch_id=' + filterData.BRANCH + '&dept=' + encodeURIComponent(filterData.DEPT) + '&division=' + encodeURIComponent(filterData.DIVISION) + '&section=' + encodeURIComponent(filterData.SECTION) + '&payclass=' + filterData.PC + '&year=' + getYear(),{
			headers: {
				'Content-Type': 'application/json',
				'authorizedToken': token,
			'appidadmin':localStorage.getItem('app_id_session') // Add your custom token here
			}
		})
			.then((res) => {
				dispatch({
					type: _getAllAssessStats,
					payload: {
						data: res.data.result,
						random: Math.random()
					}
				});
			})
			.catch((error) => {
				// .catch((error) => {
					const errorMessage = error.response ? error.response.data.message : error.message;
					const statusCode = error.response ? error.response.status : 500;
					relogin(errorMessage, statusCode);
				// });
				dispatch({
					type: _getAllAssessStats,
					payload: 'error'
				});
			})
	}
}

 
export const fetchCompleteReportsData = (params) => async (dispatch) => {
	// const token = generateToken();
	// 'authorizedToken': token // Add your custom token here
	const token = generateToken();
	axios.get(getBaseUrl() + 'api/admin/assessment/statistics/all?' + params,{
		headers: {
			'Content-Type': 'application/json',
			'authorizedToken': token,
			'appidadmin':localStorage.getItem('app_id_session') // Add your custom token here
		}
	})
		.then((res) => {
			dispatch({
				type: _getAllEmpReportsData,
				payload: {
					data: res.data.result,
					random: Math.random()
				}
			});
			
		})
		.catch((error) => {
			dispatch({
				type: _getAllEmpReportsData,
				payload: 'error'
			});
			// .catch((error) => {
				const errorMessage = error.response ? error.response.data.message : error.message;
				const statusCode = error.response ? error.response.status : 500;
				relogin(errorMessage, statusCode);
			// });
		})

}


export const fethcAdminAssessmentHistory = (filterData) => async (dispatch) => {

	// const token = generateToken();
	// 'authorizedToken': token // Add your custom token here
	const token = generateToken();
	axios.get(getBaseUrl() + 'api/admin/assessment/history/fetch?access_token=' + accessToken() + '&employee_id=' + filterData.employee_id + '&branch_id=' + filterData.branch_id + '&page=' + filterData.page + '&year=' + getYear() + '&dept_id=' + filterData.dept_id + '&div_id=' + filterData.div_id + '&group_id=' + filterData.group_id + '&section_id=' + filterData.section_id + '&completed=' + filterData.completed,{
		headers: {
			'Content-Type': 'application/json',
			'authorizedToken': token,
			'appidadmin':localStorage.getItem('app_id_session') // Add your custom token here
		}
	})
		.then((res) => {
			console.log(res);
			dispatch({
				type: _getAssessmentsHistory,
				payload: {
					data: res.data,
					random: Math.random()
				}
			});
		})
		.catch((error) => {
			// .catch((error) => {
				const errorMessage = error.response ? error.response.data.message : error.message;
				const statusCode = error.response ? error.response.status : 500;
				relogin(errorMessage, statusCode);
			// });
			dispatch({
				type: _getAssessmentsHistory,
				payload: 'error'
			});
		})
}
// header not 
export const fethcAdminAssessmentHistoryFilter = (filterData, callback) => () => {

	const token = generateToken();
	axios.get(getBaseUrl() + 'api/admin/assessment/history/fetch?access_token=' + accessToken() + '&employee_id=' + filterData.employee_id + '&branch_id=' + filterData.branch_id + '&page=' + filterData.page + '&year=' + getYear() + '&dept_id=' + filterData.dept_id + '&div_id=' + filterData.div_id + '&group_id=' + filterData.group_id + '&section_id=' + filterData.section_id + '&completed=' + filterData.completed,{
		headers: {
			'Content-Type': 'application/json',
			'authorizedToken': token,
			'appidadmin':localStorage.getItem('app_id_session') // Add your custom token here
		}
	})
		.then((res) => {
			console.log(res);
			callback(res.data)
			// dispatch({
			// 	type: _getAssessmentsHistory,
			// 	payload: {
			// 		data: res.data.result,
			// 		random: Math.random()
			// 	}
			// });
		})
		.catch((error) => {
			// .catch((error) => {
				const errorMessage = error.response ? error.response.data.message : error.message;
				const statusCode = error.response ? error.response.status : 500;
				relogin(errorMessage, statusCode);
			// });
			callback(0)
		})
}
// header not 
export const fetchAdminAuditLogs = (pagination_no, empId = "", year) => async (dispatch) => {
	if (empId == "") {
		// const token = generateToken();
		// 'authorizedToken': token // Add your custom token here
		const token = generateToken();
		axios.get(getBaseUrl() + 'api/admin/logs/data?access_token=' + accessToken() + '&page=' + pagination_no + '&year=' + year,{
			headers: {
				'Content-Type': 'application/json',
				'authorizedToken': token,
			'appidadmin':localStorage.getItem('app_id_session') // Add your custom token here
			}
		})
			.then((res) => {
				console.log(res);
				dispatch({
					type: _getAllAdminAuditLogs,
					payload: {
						data: res.data.result,
						random: Math.random()
					}
				});
			})
			.catch((error) => {
				// .catch((error) => {
					const errorMessage = error.response ? error.response.data.message : error.message;
					const statusCode = error.response ? error.response.status : 500;
					relogin(errorMessage, statusCode);
				// });
				dispatch({
					type: _getAllAdminAuditLogs,
					payload: 'error'
				});
			})
	}
	else {
		const token = generateToken();
		axios.get(getBaseUrl() + 'api/admin/logs/data?access_token=' + accessToken() + '&page=' + pagination_no + '&year=' + getYear() + '&employee_id=' + empId,{
			headers: {
				'Content-Type': 'application/json',
				'authorizedToken': token,
			'appidadmin':localStorage.getItem('app_id_session') // Add your custom token here
			}
		})
			.then((res) => {
				console.log(res);
				dispatch({
					type: _getAllAdminAuditLogs,
					payload: {
						data: res.data.result,
						random: Math.random()
					}
				});
			})
			.catch((error) => {
				// .catch((error) => {
					const errorMessage = error.response ? error.response.data.message : error.message;
					const statusCode = error.response ? error.response.status : 500;
					relogin(errorMessage, statusCode);
				// });
				dispatch({
					type: _getAllAdminAuditLogs,
					payload: 'error'
				});
			})
	}
}

export const showScoreCardLoader = () => async (dispatch) => {
	dispatch({
		type: _showScoreCardLoader,
		payload: {
			data: true,
			random: Math.random()
		}

	})
}


//*************Statistics Actions***********************

export const getTotalPeopleAssesst = (branchId, year) => async (dispatch) => {
	const token = generateToken();
	axios.post(getBaseUrl() + 'api/branches/statistics?branch_id=' + branchId + '&year=' + year + '&access_token=' + accessToken(), {
		headers: {
			'Content-Type': 'application/json',
			'authorizedToken': token,
			'appidadmin':localStorage.getItem('app_id_session') // Add your custom token here
		}
	})
		.then((res) => {
			console.log(res.data.result);
			if (res.data.status == "success") { // if success only
				dispatch({
					type: _totalPeopleAssesst,
					payload: {
						result: res.data.result,
					}
				});
			}
		})
		.catch((error) => {
			debugger;
			// console.log(error);
			// .catch((error) => {
				const errorMessage = error.response ? error.response.data.message : error.message;
				const statusCode = error.response ? error.response.status : 500;
				relogin(errorMessage, statusCode);
			// });
			dispatch({
				type: _totalPeopleAssesst,
				payload: 'error'
			});
		});
}

export const getMainCompetencyList = (branchId, year) => async (dispatch) => {
	const token = generateToken();
	axios.get(getBaseUrl() + 'api/branches/main/competencies?branch_id=' + branchId + '&year=' + year + '&access_token=' + accessToken(), {
		headers: {
			'Content-Type': 'application/json',
			'authorizedToken': token,
			'appidadmin':localStorage.getItem('app_id_session') // Add your custom token here
		}
	})
		.then((res) => {
			console.log(res.data.result);
			if (res.data.status == "success") { // if success only
				dispatch({
					type: _fetchMainCompetencies,
					payload: {
						result: res.data.result,
						mainComptPerc: res.data.mainComptPerc,
						everyMainComptPerc: res.data.everyMainComptPerc
					}
				});
			}
		})
		.catch((error) => {
			debugger;
			// .catch((error) => {
				const errorMessage = error.response ? error.response.data.message : error.message;
				const statusCode = error.response ? error.response.status : 500;
				relogin(errorMessage, statusCode);
			// });
			// console.log(error);
			dispatch({
				type: _fetchMainCompetencies,
				payload: 'error'
			});
		});
}
// headers available 
export const getSubCompetencyRecord = (mainCompId, branchId, year) => async (dispatch) => {
	const token = generateToken();
	axios.post(getBaseUrl() + 'api/branches/sub/competencies/statistics?branch_id=' + branchId + '&year=' + year + '&access_token=' + accessToken() + '&main_comptid=' + mainCompId, {
		headers: {
			'Content-Type': 'application/json',
			'authorizedToken': token,
			'appidadmin':localStorage.getItem('app_id_session') // Add your custom token here
		}
	})
		.then((res) => {
			console.log(res.data.result);
			if (res.data.status == "success") { // if success only
				dispatch({
					type: _getSubCompetency,
					payload: {
						result: res.data.result,
					}
				});
			}
		})
		.catch((error) => {
			// .catch((error) => {
				const errorMessage = error.response ? error.response.data.message : error.message;
				const statusCode = error.response ? error.response.status : 500;
				relogin(errorMessage, statusCode);
			// });
			debugger;
			console.log(error);
			dispatch({
				type: _getSubCompetency,
				payload: 'error'
			});
		});
}
export const getAllCompetencyAvgRecord = (branchId, year) => async (dispatch) => {
	const token = generateToken();
	axios.get(getBaseUrl() + 'api/branches/all/competencies/avg?branch_id=' + branchId + '&year=' + year + '&access_token=' + accessToken(), {
		headers: {
			'Content-Type': 'application/json',
			'authorizedToken': token,
			'appidadmin':localStorage.getItem('app_id_session') // Add your custom token here
		}
	})
		.then((res) => {
			console.log(res.data.result);
			if (res.data.status == "success") { // if success only
				dispatch({
					type: _allCompetencyAvg,
					payload: {
						result: res.data.result,
					}
				});
			}
		})
		.catch((error) => {
			// .catch((error) => {
				const errorMessage = error.response ? error.response.data.message : error.message;
				const statusCode = error.response ? error.response.status : 500;
				relogin(errorMessage, statusCode);
			// });
			debugger;
			console.log(error);
			dispatch({
				type: _allCompetencyAvg,
				payload: 'error'
			});
		});
}
export const getAllDivisions = (branchId, year) => async (dispatch) => {
	const token = generateToken();
	axios.get(getBaseUrl() + 'api/column/data?branch_id=' + branchId + '&year=' + year + '&access_token=' + accessToken() + '&is_filter=1', {
		headers: {
			'Content-Type': 'application/json',
			'authorizedToken': token,
			'appidadmin':localStorage.getItem('app_id_session') // Add your custom token here
		}
	})
		.then((res) => {
			console.log(res.data.result);
			if (res.data.status == "success") { // if success only
				debugger;
				dispatch({
					type: _fetchDivisions,
					payload: res.data.result,
				});
			}
		})
		.catch((error) => {
			// .catch((error) => {
				const errorMessage = error.response ? error.response.data.message : error.message;
				const statusCode = error.response ? error.response.status : 500;
				relogin(errorMessage, statusCode);
			// });
			debugger;
			console.log(error);
			dispatch({
				type: _fetchDivisions,
				payload: 'error'
			});
		});
}
export const competencyByDivisionRecord = (branchId, year, division) => async (dispatch) => {
	const token = generateToken();
	axios.get(getBaseUrl() + 'api/branches/all/competencies/division/avg?branch_id=' + branchId + '&year=' + year + '&access_token=' + accessToken() + '&division=' + division, {
		headers: {
			'Content-Type': 'application/json',
			'authorizedToken': token,
			'appidadmin':localStorage.getItem('app_id_session') // Add your custom token here
		}
	})
		.then((res) => {
			debugger;
			console.log(res.data.result);
			if (res.data.status == "success") { // if success only
				dispatch({
					type: _competencyByDivision,
					payload: res.data.result,
				});
			}
		})
		.catch((error) => {
			// .catch((error) => {
				const errorMessage = error.response ? error.response.data.message : error.message;
				const statusCode = error.response ? error.response.status : 500;
				relogin(errorMessage, statusCode);
			// });
			debugger;
			console.log(error);
			dispatch({
				type: _competencyByDivision,
				payload: 'error'
			});
		});
}
export const competencyBySectionRecord = (branchId, year, section) => async (dispatch) => {

	const token = generateToken();
	axios.get(getBaseUrl() + 'api/branches/all/competencies/section/avg?branch_id=' + branchId + '&year=' + year + '&access_token=' + accessToken() + '&section=' + section, {
		headers: {
			'Content-Type': 'application/json',
			'authorizedToken': token,
			'appidadmin':localStorage.getItem('app_id_session') // Add your custom token here
		}
	})
		.then((res) => {
			debugger;
			console.log(res.data.result);
			if (res.data.status == "success") { // if success only
				dispatch({
					type: _competencyBySecions,
					payload: res.data.result,
				});
			}
		})
		.catch((error) => {
			const errorMessage = error.response ? error.response.data.message : error.message;
			const statusCode = error.response ? error.response.status : 500;
			relogin(errorMessage, statusCode);
			debugger;
			console.log(error);
			dispatch({
				type: _competencyBySecions,
				payload: 'error'
			});
		});
}
export const branchAvgDMERecord = (branchId, year) => async (dispatch) => {
	debugger;
	const token = generateToken();
	axios.get(getBaseUrl() + 'api/branches/all/competencies/dme/avg?branch_id=' + branchId + '&year=' + year + '&access_token=' + accessToken() + '&action_type=' + 0, {
		headers: {
			'Content-Type': 'application/json',
			'authorizedToken': token,
			'appidadmin':localStorage.getItem('app_id_session')
		}
	})
		.then((res) => {
			debugger;
			console.log(res.data.result);
			if (res.data.status == "success") { // if success only
				dispatch({
					type: _branchAvgDme,
					payload: res.data.result,
				});
			}
		})
		.catch((error) => {
			const errorMessage = error.response ? error.response.data.message : error.message;
			const statusCode = error.response ? error.response.status : 500;
			relogin(errorMessage, statusCode);
			debugger;
			console.log(error);
			dispatch({
				type: _branchAvgDme,
				payload: 'error'
			});
		});
}
export const divisionDMERecord = (branchId, year, division) => async (dispatch) => {

	const token = generateToken();
	axios.get(getBaseUrl() + 'api/branches/all/competencies/dme/avg?branch_id=' + branchId + '&year=' + year + '&access_token=' + accessToken() + '&action_type=' + 2 + '&division=' + division, {
		headers: {
			'Content-Type': 'application/json',
			'authorizedToken': token,
			'appidadmin':localStorage.getItem('app_id_session') // Add your custom token here
		}
	})
		.then((res) => {
			debugger;
			console.log(res.data.result);
			if (res.data.status == "success") { // if success only
				dispatch({
					type: _dmeByDivision,
					payload: res.data.result,
				});
			}
		})
		.catch((error) => {
			const errorMessage = error.response ? error.response.data.message : error.message;
			const statusCode = error.response ? error.response.status : 500;
			relogin(errorMessage, statusCode);
			debugger;
			console.log(error);
			dispatch({
				type: _dmeByDivision,
				payload: 'error'
			});
		});
}

export const sectionDMERecord = (branchId, year, section) => async (dispatch) => {

	const token = generateToken();
	axios.get(getBaseUrl() + 'api/branches/all/competencies/dme/avg?branch_id=' + branchId + '&year=' + year + '&access_token=' + accessToken() + '&action_type=' + 1 + '&section=' + section, {
		headers: {
			'Content-Type': 'application/json',
			'authorizedToken': token,
			'appidadmin':localStorage.getItem('app_id_session') // Add your custom token here
		}
	})
		.then((res) => {
			debugger;
			console.log(res.data.result);
			if (res.data.status == "success") { // if success only
				dispatch({
					type: _dmeBySections,
					payload: res.data.result,
				});
			}
		})
		.catch((error) => {
			const errorMessage = error.response ? error.response.data.message : error.message;
			const statusCode = error.response ? error.response.status : 500;
			relogin(errorMessage, statusCode);
			debugger;
			console.log(error);
			dispatch({
				type: _dmeBySections,
				payload: 'error'
			});
		});
}
export const categorisedDMERecord = (branchId, year) => async (dispatch) => {
	const token = generateToken();
	axios.get(getBaseUrl() + 'api/branches/all/competencies/dme/avg?branch_id=' + branchId + '&year=' + year + '&access_token=' + accessToken() + '&action_type=' + 3, {
		headers: {
			'Content-Type': 'application/json',
			'authorizedToken': token,
			'appidadmin':localStorage.getItem('app_id_session') // Add your custom token here
		}
	})
		.then((res) => {
			debugger;
			console.log(res.data.result);
			if (res.data.status == "success") { // if success only
				dispatch({
					type: _dmeCategorised,
					payload: res.data.result,
				});
			}
		})
		.catch((error) => {
			const errorMessage = error.response ? error.response.data.message : error.message;
			const statusCode = error.response ? error.response.status : 500;
			relogin(errorMessage, statusCode);
			debugger;
			console.log(error);
			dispatch({
				type: _dmeCategorised,
				payload: 'error'
			});
		});
}

export const candidateDistributionRecord = (branchId, year) => async (dispatch) => {
	const token = generateToken();
	axios.get(getBaseUrl() + 'api/branches/all/competencies/dsp/avg?branch_id=' + branchId + '&year=' + year + '&access_token=' + accessToken(), {
		headers: {
			'Content-Type': 'application/json',
			'authorizedToken': token,
			'appidadmin':localStorage.getItem('app_id_session') // Add your custom token here
		}
	})
		.then((res) => {
			debugger;
			console.log(res.data.result);
			if (res.data.status == "success") { // if success only
				dispatch({
					type: _candidateDistribution,
					payload: res.data.result,
				});
			}
		})
		.catch((error) => {
			const errorMessage = error.response ? error.response.data.message : error.message;
			const statusCode = error.response ? error.response.status : 500;
			relogin(errorMessage, statusCode);
			debugger;
			console.log(error);
			dispatch({
				type: _candidateDistribution,
				payload: 'error'
			});
		});
}

export const triggerYearChange = (year) => async (dispatch) => {

	dispatch({
		type: _competencyYear,
		payload: year
	});

};


// Comparision Actions
export const getTotalPeopleAssesstCompare = (branchId, year) => async (dispatch) => {
	const token = generateToken();
	axios.get(getBaseUrl() + 'api/branches/statistics/ba?branch_id=' + branchId + '&years=' + year + '&access_token=' + accessToken(), {
		headers: {
			'Content-Type': 'application/json',
			'authorizedToken': token,
			'appidadmin':localStorage.getItem('app_id_session') // Add your custom token here
		}
	})
		.then((res) => {
			console.log(res.data.result);
			if (res.data.status == "success") { // if success only
				dispatch({
					type: _totalPeopleAssesstCompare,
					payload: {
						result: res.data.result,
					}
				});
			}
		})
		.catch((error) => {
			const errorMessage = error.response ? error.response.data.message : error.message;
			const statusCode = error.response ? error.response.status : 500;
			relogin(errorMessage, statusCode);
			debugger;
			// console.log(error);
			dispatch({
				type: _totalPeopleAssesstCompare,
				payload: 'error'
			});
		});
}
export const getCompetencyAvgCompare = (branchId, year) => async (dispatch) => {
	const token = generateToken();
	axios.get(getBaseUrl() + 'api/branches/main/competencies/ba?branch_id=' + branchId + '&years=' + year + '&access_token=' + accessToken(), {
		headers: {
			'Content-Type': 'application/json',
			'authorizedToken': token,
			'appidadmin':localStorage.getItem('app_id_session') // Add your custom token here
		}
	})
		.then((res) => {
			console.log(res.data.result);
			if (res.data.status == "success") { // if success only
				dispatch({
					type: _competencyAvgCompare,
					payload: {
						result: res.data,
					}
				});
			}
		})
		.catch((error) => {
			const errorMessage = error.response ? error.response.data.message : error.message;
			const statusCode = error.response ? error.response.status : 500;
			relogin(errorMessage, statusCode);
			debugger;
			// console.log(error);
			dispatch({
				type: _competencyAvgCompare,
				payload: 'error'
			});
		});
}

export const compareCompetencyByDivisionRecord = (branchId, year, division) => async (dispatch) => {
	const token = generateToken();
	axios.get(getBaseUrl() + 'api/branches/all/competencies/division/avg/ba?branch_id=' + branchId + '&years=' + year + '&access_token=' + accessToken() + '&division=' + division, {
		headers: {
			'Content-Type': 'application/json',
			'authorizedToken': token,
			'appidadmin':localStorage.getItem('app_id_session') // Add your custom token here
		}
	})
		.then((res) => {
			debugger;
			console.log(res.data.result);
			if (res.data.status == "success") { // if success only
				dispatch({
					type: _competencyByDivisionCompare,
					payload: res.data.result,
				});
			}
		})
		.catch((error) => {
			const errorMessage = error.response ? error.response.data.message : error.message;
			const statusCode = error.response ? error.response.status : 500;
			relogin(errorMessage, statusCode);
			debugger;
			console.log(error);
			dispatch({
				type: _competencyByDivisionCompare,
				payload: 'error'
			});
		});
}

export const compareCompetencyBySectionRecord = (branchId, year, section) => async (dispatch) => {
	const token = generateToken();
	axios.get(getBaseUrl() + 'api/branches/all/competencies/section/avg/ba?branch_id=' + branchId + '&years=' + year + '&access_token=' + accessToken() + '&section=' + section, {
		headers: {
			'Content-Type': 'application/json',
			'authorizedToken': token,
			'appidadmin':localStorage.getItem('app_id_session') // Add your custom token here
		}
	})
		.then((res) => {
			debugger;
			console.log(res.data.result);
			if (res.data.status == "success") { // if success only
				dispatch({
					type: _competencyBySecionsCompare,
					payload: res.data.result,
				});
			}
		})
		.catch((error) => {
			const errorMessage = error.response ? error.response.data.message : error.message;
			const statusCode = error.response ? error.response.status : 500;
			relogin(errorMessage, statusCode);
			debugger;
			console.log(error);
			dispatch({
				type: _competencyBySecionsCompare,
				payload: 'error'
			});
		});
}

export const compareBranchAvgDMERecord = (branchId, year) => async (dispatch) => {
	const token = generateToken();
	axios.get(getBaseUrl() + 'api/branches/all/competencies/dme/avg/ba?branch_id=' + branchId + '&years=' + year + '&access_token=' + accessToken() + '&action_type=' + 0, {
		headers: {
			'Content-Type': 'application/json',
			'authorizedToken': token,
			'appidadmin':localStorage.getItem('app_id_session')
		}
	})
		.then((res) => {
			debugger;
			console.log(res.data.result);
			if (res.data.status == "success") { // if success only
				dispatch({
					type: _branchAvgDmeCompare,
					payload: res.data.result,
				});
			}
		})
		.catch((error) => {
			const errorMessage = error.response ? error.response.data.message : error.message;
			const statusCode = error.response ? error.response.status : 500;
			relogin(errorMessage, statusCode);
			debugger;
			console.log(error);
			dispatch({
				type: _branchAvgDmeCompare,
				payload: 'error'
			});
		});
}

export const compareDivisionDMERecord = (branchId, year, division) => async (dispatch) => {
	const token = generateToken();
	axios.get(getBaseUrl() + 'api/branches/all/competencies/dme/avg/ba?branch_id=' + branchId + '&years=' + year + '&access_token=' + accessToken() + '&action_type=' + 2 + '&division=' + division, {
		headers: {
			'Content-Type': 'application/json',
			'authorizedToken': token,
			'appidadmin':localStorage.getItem('app_id_session')
		}
	})
		.then((res) => {
			debugger;
			console.log(res.data.result);
			if (res.data.status == "success") { // if success only
				dispatch({
					type: _compareDmeByDivision,
					payload: res.data.result,
				});
			}
		})
		.catch((error) => {
			const errorMessage = error.response ? error.response.data.message : error.message;
			const statusCode = error.response ? error.response.status : 500;
			relogin(errorMessage, statusCode);
			debugger;
			console.log(error);
			dispatch({
				type: _compareDmeByDivision,
				payload: 'error'
			});
		});
}

export const compareSectionDMERecord = (branchId, year, section) => async (dispatch) => {
	const token = generateToken();
	axios.get(getBaseUrl() + 'api/branches/all/competencies/dme/avg/ba?branch_id=' + branchId + '&years=' + year + '&access_token=' + accessToken() + '&action_type=' + 1 + '&section=' + section, {
		headers: {
			'Content-Type': 'application/json',
			'authorizedToken': token,
			'appidadmin':localStorage.getItem('app_id_session')
		}
	})
		.then((res) => {
			debugger;
			console.log(res.data.result);
			if (res.data.status == "success") { // if success only
				dispatch({
					type: _compareDmeBySections,
					payload: res.data.result,
				});
			}
		})
		.catch((error) => {
			const errorMessage = error.response ? error.response.data.message : error.message;
			const statusCode = error.response ? error.response.status : 500;
			relogin(errorMessage, statusCode);
			debugger;
			console.log(error);
			dispatch({
				type: _compareDmeBySections,
				payload: 'error'
			});
		});
}

export const compareCategorisedDMERecord = (branchId, year) => async (dispatch) => {
	const token = generateToken();
	axios.get(getBaseUrl() + 'api/branches/all/competencies/dme/avg/ba?branch_id=' + branchId + '&years=' + year + '&access_token=' + accessToken() + '&action_type=' + 3, {
		headers: {
			'Content-Type': 'application/json',
			'authorizedToken': token,
			'appidadmin':localStorage.getItem('app_id_session')
		}
	})
		.then((res) => {
			debugger;
			console.log(res.data.result);
			if (res.data.status == "success") { // if success only
				dispatch({
					type: _compareDmeCategorised,
					payload: res.data.result,
				});
			}
		})
		.catch((error) => {
			const errorMessage = error.response ? error.response.data.message : error.message;
			const statusCode = error.response ? error.response.status : 500;
			relogin(errorMessage, statusCode);
			debugger;
			console.log(error);
			dispatch({
				type: _compareDmeCategorised,
				payload: 'error'
			});
		});
}


// Comparision Actions
export const getTotalPeopleAssesstAllBranches = (year) => async (dispatch) => {
	const token = generateToken();
	axios.get(getBaseUrl() + 'api/pagcor/pds/count?year=' + year + '&access_token=' + accessToken(), {
		headers: {
			'Content-Type': 'application/json',
			'authorizedToken': token,
			'appidadmin':localStorage.getItem('app_id_session')
		}
	})
		.then((res) => {
			console.log(res.data.result);
			if (res.data.status == "success") { // if success only
				dispatch({
					type: _totalPeopleAssesstAllBranches,
					payload: {
						result: res.data.result,
					}
				});
			}
		})
		.catch((error) => {
			const errorMessage = error.response ? error.response.data.message : error.message;
			const statusCode = error.response ? error.response.status : 500;
			relogin(errorMessage, statusCode);
			debugger;
			dispatch({
				type: _totalPeopleAssesstAllBranches,
				payload: 'error'
			});
		});
}

export const getMainCompetencyListAllBranches = (year) => async (dispatch) => {
	const token = generateToken();
	axios.get(getBaseUrl() + 'api/pagcor/competencies/avg?year=' + year + '&access_token=' + accessToken(), {
		headers: {
			'Content-Type': 'application/json',
			'authorizedToken': token,
			'appidadmin':localStorage.getItem('app_id_session')
		}
	})
		.then((res) => {
			console.log(res.data.result);
			if (res.data.status == "success") { // if success only
				dispatch({
					type: _fetchMainCompetenciesAllBranches,
					payload: {
						result: res.data.result,
						mainComptPerc: res.data.mainComptPerc,
						everyMainComptPerc: res.data.everyMainComptPerc
					}
				});
			}
		})
		.catch((error) => {
			const errorMessage = error.response ? error.response.data.message : error.message;
			const statusCode = error.response ? error.response.status : 500;
			relogin(errorMessage, statusCode);
			debugger;
			// console.log(error);
			dispatch({
				type: _fetchMainCompetenciesAllBranches,
				payload: 'error'
			});
		});
}

export const getSubCompetencyRecordAllBranches = (mainCompId, year) => async (dispatch) => {
	const token = generateToken();
	axios.get(getBaseUrl() + 'api/pagcor/competency/avg?main_comptid=' + mainCompId + '&year=' + year + '&access_token=' + accessToken(), {
		headers: {
			'Content-Type': 'application/json',
			'authorizedToken': token,
			'appidadmin':localStorage.getItem('app_id_session')
		}
	})
		.then((res) => {
			console.log(res.data.result);
			if (res.data.status == "success") { // if success only
				dispatch({
					type: _getSubCompetencyAllBranches,
					payload: {
						result: res.data.result,
					}
				});
			}
		})
		.catch((error) => {
			const errorMessage = error.response ? error.response.data.message : error.message;
			const statusCode = error.response ? error.response.status : 500;
			relogin(errorMessage, statusCode);
			debugger;
			console.log(error);
			dispatch({
				type: _getSubCompetency,
				payload: 'error'
			});
		});
}

export const candidateDistributionRecordAllBranches = (year) => async (dispatch) => {
	const token = generateToken();
	axios.get(getBaseUrl() + 'api/pagcor/competencies/dsp/avg?year=' + year + '&access_token=' + accessToken(), {
		headers: {
			'Content-Type': 'application/json',
			'authorizedToken': token,
			'appidadmin':localStorage.getItem('app_id_session')
		}
	})
		.then((res) => {
			debugger;
			console.log(res.data.result);
			if (res.data.status == "success") { // if success only
				dispatch({
					type: _candidateDistributionAllBranches,
					payload: res.data.result,
				});
			}
		})
		.catch((error) => {
			const errorMessage = error.response ? error.response.data.message : error.message;
			const statusCode = error.response ? error.response.status : 500;
			relogin(errorMessage, statusCode);
			debugger;
			console.log(error);
			dispatch({
				type: _candidateDistributionAllBranches,
				payload: 'error'
			});
		});
}

export const categorisedDMERecordAllBranches = (year) => async (dispatch) => {
	const token = generateToken();
	axios.get(getBaseUrl() + 'api/pagcor/competencies/dme/avg?year=' + year + '&access_token=' + accessToken() + '&action_type=' + 3, {
		headers: {
			'Content-Type': 'application/json',
			'authorizedToken': token,
			'appidadmin':localStorage.getItem('app_id_session')
		}
	})
		.then((res) => {
			debugger;
			console.log(res.data.result);
			if (res.data.status == "success") { // if success only
				dispatch({
					type: _dmeCategorisedAllBranches,
					payload: res.data.result,
				});
			}
		})
		.catch((error) => {
			const errorMessage = error.response ? error.response.data.message : error.message;
			const statusCode = error.response ? error.response.status : 500;
			relogin(errorMessage, statusCode);
			debugger;
			console.log(error);
			dispatch({
				type: _dmeCategorisedAllBranches,
				payload: 'error'
			});
		});
}


export const getTotalPeopleAssesstCompareAllBranches = (year) => async (dispatch) => {
	const token = generateToken();
	axios.get(getBaseUrl() + 'api/pagcor/pds/count/ba?years=' + year + '&access_token=' + accessToken(), {
		headers: {
			'Content-Type': 'application/json',
			'authorizedToken': token,
			'appidadmin':localStorage.getItem('app_id_session')
		}
	})
		.then((res) => {
			console.log(res.data.result);
			if (res.data.status == "success") { // if success only
				dispatch({
					type: _totalPeopleAssesstCompareAllBranches,
					payload: {
						result: res.data.result,
					}
				});
			}
		})
		.catch((error) => {
			const errorMessage = error.response ? error.response.data.message : error.message;
			const statusCode = error.response ? error.response.status : 500;
			relogin(errorMessage, statusCode);
			debugger;
			// console.log(error);
			dispatch({
				type: _totalPeopleAssesstCompareAllBranches,
				payload: 'error'
			});
		});
}

export const getCompetencyAvgCompareAllBranch = (year) => async (dispatch) => {
	const token = generateToken();
	axios.get(getBaseUrl() + 'api/pagcor/competencies/avg/ba?years=' + year + '&access_token=' + accessToken(), {
		headers: {
			'Content-Type': 'application/json',
			'authorizedToken': token,
			'appidadmin':localStorage.getItem('app_id_session')
		}
	})
		.then((res) => {
			console.log(res.data.result);
			if (res.data.status == "success") { // if success only
				dispatch({
					type: _competencyAvgCompareAllBranches,
					payload: {
						result: res.data,
					}
				});
			}
		})
		.catch((error) => {
			const errorMessage = error.response ? error.response.data.message : error.message;
			const statusCode = error.response ? error.response.status : 500;
			relogin(errorMessage, statusCode);
			debugger;
			// console.log(error);
			dispatch({
				type: _competencyAvgCompareAllBranches,
				payload: 'error'
			});
		});
}

export const compareCompetencyByDivisionRecordAllBranches = (year, division) => async (dispatch) => {
	const token = generateToken();
	axios.get(getBaseUrl() + 'api/pagcor/competencies/division/avg/ba?years=' + year + '&access_token=' + accessToken() + '&division=' + division, {
		headers: {
			'Content-Type': 'application/json',
			'authorizedToken': token,
			'appidadmin':localStorage.getItem('app_id_session')
		}
	})
		.then((res) => {
			debugger;
			console.log(res.data.result);
			if (res.data.status == "success") { // if success only
				dispatch({
					type: _competencyByDivisionCompareAllBranches,
					payload: res.data.result,
				});
			}
		})
		.catch((error) => {
			const errorMessage = error.response ? error.response.data.message : error.message;
			const statusCode = error.response ? error.response.status : 500;
			relogin(errorMessage, statusCode);
			debugger;
			console.log(error);
			dispatch({
				type: _competencyByDivisionCompare,
				payload: 'error'
			});
		});
}

export const compareCompetencyBySectionRecordAllBranches = (year, section) => async (dispatch) => {
	const token = generateToken();
	axios.get(getBaseUrl() + 'api/pagcor/competencies/section/avg/ba?years=' + year + '&access_token=' + accessToken() + '&section=' + section, {
		headers: {
			'Content-Type': 'application/json',
			'authorizedToken': token,
			'appidadmin':localStorage.getItem('app_id_session')
		}
	})
		.then((res) => {
			debugger;
			console.log(res.data.result);
			if (res.data.status == "success") { // if success only
				dispatch({
					type: _competencyBySecionsCompareAllBranches,
					payload: res.data.result,
				});
			}
		})
		.catch((error) => {
			const errorMessage = error.response ? error.response.data.message : error.message;
			const statusCode = error.response ? error.response.status : 500;
			relogin(errorMessage, statusCode);
			debugger;
			console.log(error);
			dispatch({
				type: _competencyBySecionsCompare,
				payload: 'error'
			});
		});
}

export const compareBranchAvgDMERecordAllBranches = (year) => async (dispatch) => {
	const token = generateToken();
	axios.get(getBaseUrl() + 'api/pagcor/competencies/dme/avg/ba?years=' + year + '&access_token=' + accessToken() + '&action_type=' + 0, {
		headers: {
			'Content-Type': 'application/json',
			'authorizedToken': token,
			'appidadmin':localStorage.getItem('app_id_session')
		}
	})
		.then((res) => {
			debugger;
			console.log(res.data.result);
			if (res.data.status == "success") { // if success only
				dispatch({
					type: _branchAvgDmeCompareAllBranches,
					payload: res.data.result,
				});
			}
		})
		.catch((error) => {
			const errorMessage = error.response ? error.response.data.message : error.message;
			const statusCode = error.response ? error.response.status : 500;
			relogin(errorMessage, statusCode);
			debugger;
			console.log(error);
			dispatch({
				type: _branchAvgDmeCompareAllBranches,
				payload: 'error'
			});
		});
}

export const compareDivisionDMERecordAllBranches = (year, division) => async (dispatch) => {
	const token = generateToken();
	axios.get(getBaseUrl() + 'api/pagcor/competencies/dme/avg/ba?years=' + year + '&access_token=' + accessToken() + '&action_type=' + 2 + '&division=' + division, {
		headers: {
			'Content-Type': 'application/json',
			'authorizedToken': token,
			'appidadmin':localStorage.getItem('app_id_session')
		}
	})
		.then((res) => {
			debugger;
			console.log(res.data.result);
			if (res.data.status == "success") { // if success only
				dispatch({
					type: _compareDmeByDivisionAllBranches,
					payload: res.data.result,
				});
			}
		})
		.catch((error) => {
			const errorMessage = error.response ? error.response.data.message : error.message;
			const statusCode = error.response ? error.response.status : 500;
			relogin(errorMessage, statusCode);
			debugger;
			console.log(error);
			dispatch({
				type: _compareDmeByDivisionAllBranches,
				payload: 'error'
			});
		});
}

export const compareSectionDMERecordAllBranches = (year, section) => async (dispatch) => {
	const token = generateToken();
	axios.get(getBaseUrl() + 'api/pagcor/competencies/dme/avg/ba?years=' + year + '&access_token=' + accessToken() + '&action_type=' + 1 + '&section=' + section, {
		headers: {
			'Content-Type': 'application/json',
			'authorizedToken': token,
			'appidadmin':localStorage.getItem('app_id_session')
		}
	})
		.then((res) => {
			debugger;
			console.log(res.data.result);
			if (res.data.status == "success") { // if success only
				dispatch({
					type: _compareDmeBySectionsAllBranches,
					payload: res.data.result,
				});
			}
		})
		.catch((error) => {
			const errorMessage = error.response ? error.response.data.message : error.message;
			const statusCode = error.response ? error.response.status : 500;
			relogin(errorMessage, statusCode);
			debugger;
			console.log(error);
			dispatch({
				type: _compareDmeBySectionsAllBranches,
				payload: 'error'
			});
		});
}


export const compareCategorisedDMERecordAllBranches = (year) => async (dispatch) => {
	const token = generateToken();
	axios.get(getBaseUrl() + 'api/pagcor/competencies/dme/avg/ba?years=' + year + '&access_token=' + accessToken() + '&action_type=' + 3, {
		headers: {
			'Content-Type': 'application/json',
			'authorizedToken': token,
			'appidadmin':localStorage.getItem('app_id_session')
		}
	})
		.then((res) => {
			debugger;
			console.log(res.data.result);
			if (res.data.status == "success") { // if success only
				dispatch({
					type: _compareDmeCategorisedAllBranches,
					payload: res.data.result,
				});
			}
		})
		.catch((error) => {
			const errorMessage = error.response ? error.response.data.message : error.message;
			const statusCode = error.response ? error.response.status : 500;
			relogin(errorMessage, statusCode);
			debugger;
			console.log(error);
			dispatch({
				type: _compareDmeCategorisedAllBranches,
				payload: 'error'
			});
		});
}
/**
 * get the additional data for profile of employee
 */
export const getAdditionalData = () => async (dispatch) => {
	// var bodyFormData = new FormData();
	// bodyFormData.set('acess_token', accessToken());
	// bodyFormData.set('year', getYear());
	const token = generateToken();
	axios.post(getBaseUrl() + 'api/admin/employee/additional/data?acess_token=' + accessToken() + '&year=' + getYear(),{}, {
		headers: {
			'Content-Type': 'application/json',
			'authorizedToken': token,
			'appidadmin':localStorage.getItem('app_id_session')
		}
	})
		.then((res) => {
			console.log(res);
			if (res.data.status == 'success' && res.data.result) {
				dispatch({
					type: _fetchAdditionalData,
					payload: {
						data: res.data.result,
						random: Math.random()
					}

				})
			}
		})
		.catch((error) => {
			const errorMessage = error.response ? error.response.data.message : error.message;
			const statusCode = error.response ? error.response.status : 500;
			relogin(errorMessage, statusCode);
			console.log(error);
		});
}
/**
 * get the main groups data
 */
export const getMainGroupsData = (employeeId) => (dispatch) => {
	// var bodyFormData = new FormData();
	// bodyFormData.set('acess_token', accessToken());
	// bodyFormData.set('year', getYear());
	// bodyFormData.set('emp_id', employeeId);
	const token = generateToken();
	axios.get(getBaseUrl() + 'api/admin/groups?acess_token=' + accessToken() + '&year=' + getYear() + '&emp_id=' + employeeId, {
		headers: {
			'Content-Type': 'multipart/form-data',
			'authorizedToken': token,
			'appidadmin':localStorage.getItem('app_id_session')
		}
	})
		.then((res) => {
			console.log(res);
			if (res.data.status == 'success' && res.data.result) {
				dispatch({
					type: _fetchMainGroupsData,
					payload: {
						data: res.data.result,
						random: Math.random()
					}

				})
			}
		})
		.catch((error) => {
			const errorMessage = error.response ? error.response.data.message : error.message;
			const statusCode = error.response ? error.response.status : 500;
			relogin(errorMessage, statusCode);
			console.log(error);
		});
}
/**
 * get the main groups data
 */
export const fetchSubGroupsOrLocationsOrSOG = (mainGroupId, callback) => (dispatch) => {
	// var bodyFormData = new FormData();
	// bodyFormData.set('acess_token', accessToken());
	// bodyFormData.set('year', getYear());
	// bodyFormData.set('main_groupid', mainGroupId);
	const token = generateToken();
	axios.get(getBaseUrl() + 'api/admin/group/related/data?acess_token=' + accessToken() + '&year=' + getYear() + '&main_groupid=' + mainGroupId, {
		headers: {
			'Content-Type': 'application/json',
			'authorizedToken': token,
			'appidadmin':localStorage.getItem('app_id_session')
		}
	})
		.then((res) => {
			console.log(res);
			if (res.data.status == 'success' && res.data.result) {
				if (typeof callback === 'function')
					callback(res.data)
			}
			else {
				if (typeof callback === 'function')
					callback(0)
			}
		})
		.catch((error) => {
			const errorMessage = error.response ? error.response.data.message : error.message;
			const statusCode = error.response ? error.response.status : 500;
			relogin(errorMessage, statusCode);
			console.log(error);
		});
}
/**
 * get the departments
 */
export const fetchSubGroupsDepartments = (subGroupId, callback) => (dispatch) => {
	// var bodyFormData = new FormData();
	// bodyFormData.set('acess_token', accessToken());
	// bodyFormData.set('year', getYear());
	// bodyFormData.set('main_groupid', mainGroupId);
	const token = generateToken();
	axios.get(getBaseUrl() + 'api/admin/subgroup/departements/fetch?acess_token=' + accessToken() + '&year=' + getYear() + '&sub_groupid=' + subGroupId, {
		headers: {
			'Content-Type': 'application/json',
			'authorizedToken': token,
			'appidadmin':localStorage.getItem('app_id_session')
		}
	})
		.then((res) => {
			console.log(res);
			if (res.data.status == 'success' && res.data.result) {
				if (typeof callback === 'function')
					callback(res.data)
			}
			else {
				if (typeof callback === 'function')
					callback(0)
			}
		})
		.catch((error) => {
			const errorMessage = error.response ? error.response.data.message : error.message;
			const statusCode = error.response ? error.response.status : 500;
			relogin(errorMessage, statusCode);
			console.log(error);
		});
}
/**
 * get the branches
 */
export const fetchLocationBranches = (locationId, callback) => (dispatch) => {
	// var bodyFormData = new FormData();
	// bodyFormData.set('acess_token', accessToken());
	// bodyFormData.set('year', getYear());
	// bodyFormData.set('main_groupid', mainGroupId);
	const token = generateToken();
	axios.get(getBaseUrl() + 'api/admin/location/branches/fetch?acess_token=' + accessToken() + '&year=' + getYear() + '&location_id=' + locationId, {
		headers: {
			'Content-Type': 'application/json',
			'authorizedToken': token,
			'appidadmin':localStorage.getItem('app_id_session')
		}
	})
		.then((res) => {
			console.log(res);
			if (res.data.status == 'success' && res.data.result) {
				if (typeof callback === 'function')
					callback(res.data)
			}
			else {
				if (typeof callback === 'function')
					callback(0)
			}
		})
		.catch((error) => {
			const errorMessage = error.response ? error.response.data.message : error.message;
			const statusCode = error.response ? error.response.status : 500;
			relogin(errorMessage, statusCode);
			console.log(error);
		});
}
/**
 * get the satellite
 */
export const fetchBrancheSatellite = (branchId, callback) => (dispatch) => {
	// var bodyFormData = new FormData();
	// bodyFormData.set('acess_token', accessToken());
	// bodyFormData.set('year', getYear());
	// bodyFormData.set('main_groupid', mainGroupId);
	const token = generateToken();
	axios.get(getBaseUrl() + 'api/admin/branch/satellite/fetch?acess_token=' + accessToken() + '&year=' + getYear() + '&branch_id=' + branchId, {
		headers: {
			'Content-Type': 'application/json',
			'authorizedToken': token,
			'appidadmin':localStorage.getItem('app_id_session')
		}
	})
		.then((res) => {
			console.log(res);
			if (res.data.status == 'success' && res.data.result) {
				if (typeof callback === 'function')
					callback(res.data)
			}
			else {
				if (typeof callback === 'function')
					callback(0)
			}
		})
		.catch((error) => {
			const errorMessage = error.response ? error.response.data.message : error.message;
			const statusCode = error.response ? error.response.status : 500;
			relogin(errorMessage, statusCode);
			console.log(error);
		});
}
/**
 * get the satellite
 */
export const updateGroupData = (params, callback) => (dispatch) => {
	params["acess_token"] = accessToken();
	// params["emp_id"] = "10-0000";
	// var bodyFormData = new FormData();
	// bodyFormData.set('acess_token', accessToken());
	// bodyFormData.set('year', year);
	// bodyFormData.set('main_group', mainGroup);
	// bodyFormData.set('sub_group', subGroup);
	// bodyFormData.set('department', department);
	// bodyFormData.set('emp_id', empId);
	// bodyFormData.set('div_id', divId);
	// bodyFormData.set('section_id', sectionId);
	// bodyFormData.set('desig_id', desigId);
	// {
	// 	acess_token: accessToken(), year: year, main_group: mainGroup,
	// 	sub_group: subGroup, department: department, emp_id: empId,
	// 	location: location, branch: branch, satellite: satellite
	// }
	//axios.post(getBaseUrl() + 'api/admin/groups/update/data/fd', params)
	const token = generateToken();
	axios.post(getBaseUrl() + 'api/admin/update/user/data', params, {
		headers: {
			'Content-Type': 'application/json',
			'authorizedToken': token,
			'appidadmin':localStorage.getItem('app_id_session')
		}
	})
		.then((res) => {
			console.log(res);
			if (res.data.status == 'success' && res.data.result) {
				if (typeof callback === 'function')
					callback(res.data)
			}
			else {
				if (typeof callback === 'function')
					callback(0)
			}
		})
		.catch((error) => {
			const errorMessage = error.response ? error.response.data.message : error.message;
			const statusCode = error.response ? error.response.status : 500;
			relogin(errorMessage, statusCode);
			console.log(error);
		});
}
/**
 * get year wise groups data
 */
export const fetchYearWiseGroupsData = (empId, years, callback) => (dispatch) => {
	const token = generateToken();
	axios.get(getBaseUrl() + 'api/admin/yearwise/groups/data?acess_token=' + accessToken() + '&years=' + JSON.stringify(years) + '&employee_id=' + empId, {
		headers: {
			'Content-Type': 'application/json',
			'authorizedToken': token,
			'appidadmin':localStorage.getItem('app_id_session')
		}
	})
		.then((res) => {
			if (res.data.status == 'success' && res.data.result) {
				if (typeof callback === 'function')
					callback(res.data)
			}
			else {
				if (typeof callback === 'function')
					callback(0)
			}
		})
		.catch((error) => {
			const errorMessage = error.response ? error.response.data.message : error.message;
			const statusCode = error.response ? error.response.status : 500;
			relogin(errorMessage, statusCode);
			console.log(error);
		});
}
/**
 * add logout time of employee
 */
export const addEmployeeLogoutTime = (employeeId, callback) => (dispatch) => {
	var bodyFormData = new FormData();
	bodyFormData.set('acess_token', accessToken());
	bodyFormData.set('year', getYear());
	bodyFormData.set('employee_id', employeeId);
	const token = generateToken();
	axios.post(getBaseUrl() + 'api/add/employee/logout/time', bodyFormData, {
		headers: {
			'Content-Type': 'multipart/form-data',
			'authorizedToken': token,
			'appidadmin':localStorage.getItem('app_id_session')
		}
	}).then((res) => {
		console.log(res.data);
		callback();
	}).catch((err) => {
		const errorMessage = err.response ? err.response.data.message : err.message;
		const statusCode = err.response ? err.response.status : 500;
		relogin(errorMessage, statusCode);
		console.log(err)
	})

}
/**
 * add login time of employee
 */
export const addEmployeeLoginTime = (employeeId) => (dispatch) => {
	var bodyFormData = new FormData();
	bodyFormData.set('acess_token', accessToken());
	bodyFormData.set('year', getYear());
	bodyFormData.set('employee_id', employeeId);
	const token = generateToken();
	axios.post(getBaseUrl() + 'api/add/employee/login/time', bodyFormData, {
		headers: {
			'Content-Type': 'multipart/form-data',
			'authorizedToken': token,
			'appidadmin':localStorage.getItem('app_id_session')
		}
	}).then((res) => {
		console.log(res.data);
	}).catch((err) => {
		const errorMessage = err.response ? err.response.data.message : err.message;
		const statusCode = err.response ? err.response.status : 500;
		relogin(errorMessage, statusCode);
		console.log(err)
	})

}
/**
 * add the user taken assessment time
 */
export const addEmployeeAssessmentTakenTime = (employeeId, assessId) => (dispatch) => {
	var bodyFormData = new FormData();
	bodyFormData.set('acess_token', accessToken());
	bodyFormData.set('year', getYear());
	bodyFormData.set('employee_id', employeeId);
	bodyFormData.set('assess_id', assessId);
	const token = generateToken();
	axios.post(getBaseUrl() + 'api/add/employee/assess/time', bodyFormData, {
		headers: {
			'Content-Type': 'multipart/form-data',
			'authorizedToken': token,
			'appidadmin':localStorage.getItem('app_id_session')
		}
	}).then((res) => {
		console.log(res.data);
	}).catch((err) => {
		const errorMessage = err.response ? err.response.data.message : err.message;
		const statusCode = err.response ? err.response.status : 500;
		relogin(errorMessage, statusCode);
		console.log(err)
	})
}
/**
 * fetch employee activity logs
 */
export const fetchEmployeeActivityLogs = (page, year) => (dispatch) => {
	const token = generateToken();
	axios.get(getBaseUrl() + 'api/employee/logs/fetch?page=' + page + '&year=' + year + '&acess_token=' + accessToken(), {
		headers: {
			'Content-Type': 'multipart/form-data',
			'authorizedToken': token,
			'appidadmin':localStorage.getItem('app_id_session')
		}
	}).then((res) => {

		if (res.data.status == 'success' && res.data.result) {
			dispatch({
				type: _fetchEmployeeActivityLogs,
				payload: {
					data: res.data.result,
					random: Math.random()
				}

			})
		}
	}).catch((err) => {
		const errorMessage = err.response ? err.response.data.message : err.message;
		const statusCode = err.response ? err.response.status : 500;
		relogin(errorMessage, statusCode);
		console.log(err)
	})
}
/**
 * hide search loader 
 */
export const showHideLoader = (status) => (dispatch) => {
	dispatch({
		type: _showHideLoader,
		payload: {
			data: status,
			random: Math.random()
		}
	});
}
/**
 * search the employee logs
 */
export const seachEmployeeLogs = (employeeId, page, logType) => (dispatch) => {
	const token = generateToken();
	axios.get(getBaseUrl() + 'api/search/employee/logs?page=' + page + '&year=' + getYear() + '&acess_token=' + accessToken() + '&employee_id=' + employeeId + '&log_type=' + logType, {
		headers: {
			'Content-Type': 'multipart/form-data',
			'authorizedToken': token,
			'appidadmin':localStorage.getItem('app_id_session')
		}
	}).then((res) => {

		if (res.data.status == 'success' && res.data.result) {
			dispatch({
				type: _searchEmployeeLogs,
				payload: {
					data: res.data.result,
					random: Math.random()
				}

			})
		}
	}).catch((err) => {
		const errorMessage = err.response ? err.response.data.message : err.message;
		const statusCode = err.response ? err.response.status : 500;
		relogin(errorMessage, statusCode);
		console.log(err)
	})
}
/**
 * reset the employee if in search engine
 */
export const resetEmployeeId = () => (dispatch) => {
	dispatch({
		type: _resetEmpId,
		payload: {
			data: "",
			random: Math.random()
		}
	})
}
/**
 * get the hierarchy of divisions
 */
export const getAssesstDivHierarchy = (deptId, subGroupId, callback) => () => {
	//deptId = 4;
	const token = generateToken();
	axios.get(getBaseUrl() + 'api/assess/divs/hierarchy?year=' + getYear() + '&acess_token=' + accessToken() + '&dept_id=' + deptId + '&sub_groupid=' + subGroupId, {
		headers: {
			'Content-Type': 'multipart/form-data',
			'authorizedToken': token,
			'appidadmin':localStorage.getItem('app_id_session')
		}
	}).then((res) => {
		console.log("This is response:", res)
		if (res.data.status == "success")
			callback(res.data)
		else
			callback(0)
	}).catch((err) => {
		const errorMessage = err.response ? err.response.data.message : err.message;
		const statusCode = err.response ? err.response.status : 500;
		relogin(errorMessage, statusCode);
		console.log(err)
	})
}
/**
 * get the hierarchy of department
 */
export const getAssesstDeptsHierarchy = (subGroupId, callback) => () => {
	//deptId = 4;
	const token = generateToken();
	axios.get(getBaseUrl() + 'api/assess/departments/hierarchy?year=' + getYear() + '&acess_token=' + accessToken() + '&sub_groupid=' + subGroupId, {
		headers: {
			'Content-Type': 'multipart/form-data',
			'authorizedToken': token,
			'appidadmin':localStorage.getItem('app_id_session')
		}
	}).then((res) => {
		console.log("This is response:", res)
		if (res.data.status == "success")
			callback(res.data)
		else
			callback(0)
	}).catch((err) => {
		const errorMessage = err.response ? err.response.data.message : err.message;
		const statusCode = err.response ? err.response.status : 500;
		relogin(errorMessage, statusCode);
		console.log(err)
	})
}
/**
 * get the hierarchy of sections
 */
export const getAssesstSectionHierarchy = (subGroupId, deptId, divId, callback) => () => {
	const token = generateToken();
	axios.get(getBaseUrl() + 'api/assess/sections/hierarchy?year=' + getYear() + '&acess_token=' + accessToken() + '&sub_groupid=' + subGroupId + '&dept_id=' + deptId + '&division_id=' + divId, {
		headers: {
			'Content-Type': 'multipart/form-data',
			'authorizedToken': token,
			'appidadmin':localStorage.getItem('app_id_session')
		}
	}).then((res) => {
		console.log("This is response:", res)
		if (res.data.status == "success")
			callback(res.data)
		else
			callback(0)
	}).catch((err) => {
		const errorMessage = err.response ? err.response.data.message : err.message;
		const statusCode = err.response ? err.response.status : 500;
		relogin(errorMessage, statusCode);
		console.log(err)
	})
}
/**
 * get the hierarchy of payclass
 */
export const getAssesstPCHierarchy = (subGroupId, deptId, divisionId, sectionId, callback) => () => {
	const token = generateToken();
	axios.get(getBaseUrl() + 'api/assess/payclass/hierarchy?year=' + getYear() + '&acess_token=' + accessToken() + '&sub_groupid=' + subGroupId + '&dept_id=' + deptId + "&division_id=" + divisionId + "&section_id=" + sectionId, {
		headers: {
			'Content-Type': 'multipart/form-data',
			'authorizedToken': token,
			'appidadmin':localStorage.getItem('app_id_session')
		}
	}).then((res) => {
		console.log("This is response:", res)
		if (res.data.status == "success")
			callback(res.data)
		else
			callback(0)
	}).catch((err) => {
		const errorMessage = err.response ? err.response.data.message : err.message;
		const statusCode = err.response ? err.response.status : 500;
		relogin(errorMessage, statusCode);
		console.log(err)
	})
}
/**
 * get the hierarchy of designation
 */
export const getAssesstDesignationHierarchy = (subGroupId, deptId, divisionId, sectionId, pcId, callback) => () => {
	const token = generateToken();
	axios.get(getBaseUrl() + 'api/assess/designation/hierarchy?year=' + getYear() + '&acess_token=' + accessToken() + '&sub_groupid=' + subGroupId + '&dept_id=' + deptId + '&division_id=' + divisionId + '&section_id=' + sectionId + '&pc_id=' + pcId, {
		headers: {
			'Content-Type': 'multipart/form-data',
			'authorizedToken': token,
			'appidadmin':localStorage.getItem('app_id_session')
		}
	}).then((res) => {
		console.log("This is response:", res)
		if (res.data.status == "success")
			callback(res.data)
		else
			callback(0)
	}).catch((err) => {
		const errorMessage = err.response ? err.response.data.message : err.message;
		const statusCode = err.response ? err.response.status : 500;
		relogin(errorMessage, statusCode);
		console.log(err)
	})
}
/**
 * add the new designation 
 */
export const addNewDesignation = (mainGroupId, subGroupId, deptId = 0, pcId, divisionId, sectionId, desigName, locationId = 0, branchId = 0, callback) => () => {

	var bodyFormData = new FormData();
	bodyFormData.set('acess_token', accessToken());
	bodyFormData.set('year', getYear());
	bodyFormData.set('maingroup_id', mainGroupId);
	bodyFormData.set('subgroup_id', subGroupId);
	bodyFormData.set('dept_id', deptId);
	bodyFormData.set('divsion_id', divisionId);
	bodyFormData.set('section_id', sectionId);
	bodyFormData.set('desig_name', desigName);
	bodyFormData.set('pc_id', pcId);
	bodyFormData.set('location_id', locationId);
	bodyFormData.set('branch_id', branchId);
	const token = generateToken();
	axios.post(getBaseUrl() + 'api/admin/designation/add', bodyFormData, {
		headers: {
			'Content-Type': 'multipart/form-data',
			'authorizedToken': token,
			'appidadmin':localStorage.getItem('app_id_session')
		}
	}).then((res) => {
		console.log(res.data);
		callback(res.data)
	}).catch((err) => {
		const errorMessage = err.response ? err.response.data.message : err.message;
		const statusCode = err.response ? err.response.status : 500;
		relogin(errorMessage, statusCode);
		console.log(err)
	})
}
/**
 * fetch the designation rpl 
 */
export const getAssessRpl = (subGroupId, deptId, divisionId, sectionId, pcId, desigId, callback) => () => {
	const token = generateToken();
	axios.get(getBaseUrl() + 'api/assess/designation/rpl?year=' + getYear() + '&acess_token=' + accessToken() + '&sub_groupid=' + subGroupId + '&dept_id=' + deptId + '&division_id=' + divisionId + '&section_id=' + sectionId + '&pc_id=' + pcId + '&desig_id=' + desigId, {
		headers: {
			'Content-Type': 'multipart/form-data',
			'authorizedToken': token,
			'appidadmin':localStorage.getItem('app_id_session')
		}
	}).then((res) => {
		console.log("This is response:", res)
		if (res.data.status == "success")
			callback(res.data)
		else
			callback(0)
	}).catch((err) => {
		const errorMessage = err.response ? err.response.data.message : err.message;
		const statusCode = err.response ? err.response.status : 500;
		relogin(errorMessage, statusCode);
		console.log(err)
	})
}
/**
 * get location wise divisions
 * @param {*} locationId 
 * @param {*} callback 
 * @returns 
 */
export const getLocationDivsHierarchy = (locationId, callback) => () => {
	const token = generateToken();
	axios.get(getBaseUrl() + 'api/assess/location/divs/hierarchy?year=' + getYear() + '&acess_token=' + accessToken() + '&location_id=' + locationId, {
		headers: {
			'Content-Type': 'multipart/form-data',
			'authorizedToken': token,
			'appidadmin':localStorage.getItem('app_id_session')
		}
	}).then((res) => {
		console.log("This is response:", res)
		if (res.data.status == "success")
			callback(res.data)
		else
			callback(0)
	}).catch((err) => {
		const errorMessage = err.response ? err.response.data.message : err.message;
		const statusCode = err.response ? err.response.status : 500;
		relogin(errorMessage, statusCode);
		console.log(err)
	})

}
/**
 * get location wise section 
 * @param {*} locationId 
 * @param {*} divisionId 
 * @param {*} callback 
 * @returns 
 */
export const getLocationSectionHierarchy = (locationId, divisionId, callback) => () => {
	const token = generateToken();
	axios.get(getBaseUrl() + 'api/assess/location/sections/hierarchy?year=' + getYear() + '&acess_token=' + accessToken() + '&location_id=' + locationId + '&division_id=' + divisionId, {
		headers: {
			'Content-Type': 'multipart/form-data',
			'authorizedToken': token,
			'appidadmin':localStorage.getItem('app_id_session')
		}
	}).then((res) => {
		console.log("This is response:", res)
		if (res.data.status == "success")
			callback(res.data)
		else
			callback(0)
	}).catch((err) => {
		const errorMessage = err.response ? err.response.data.message : err.message;
		const statusCode = err.response ? err.response.status : 500;
		relogin(errorMessage, statusCode);
		console.log(err)
	})
}
/**
 * get location wise pc
 * @param {*} locationId 
 * @param {*} divisionId 
 * @param {*} sectionId 
 * @param {*} callback 
 * @returns 
 */
export const getLocationPCHierarchy = (locationId, divisionId, sectionId, callback) => () => {
	const token = generateToken();
	axios.get(getBaseUrl() + 'api/assess/location/pc/hierarchy?year=' + getYear() + '&acess_token=' + accessToken() + '&location_id=' + locationId + '&division_id=' + divisionId + "&section_id=" + sectionId, {
		headers: {
			'Content-Type': 'multipart/form-data',
			'authorizedToken': token,
			'appidadmin':localStorage.getItem('app_id_session')
		}
	}).then((res) => {
		console.log("This is response:", res)
		if (res.data.status == "success")
			callback(res.data)
		else
			callback(0)
	}).catch((err) => {
		const errorMessage = err.response ? err.response.data.message : err.message;
		const statusCode = err.response ? err.response.status : 500;
		relogin(errorMessage, statusCode);
		console.log(err)
	})
}
/**
 * get the location designation 
 * @param {*} locationId 
 * @param {*} divisionId 
 * @param {*} sectionId 
 * @param {*} pcId 
 * @param {*} callback 
 * @returns 
 */
export const getLocationDesigHierarchy = (locationId, divisionId, sectionId, pcId, callback) => () => {
	const token = generateToken();
	axios.get(getBaseUrl() + 'api/assess/location/desig/hierarchy?year=' + getYear() + '&acess_token=' + accessToken() + '&location_id=' + locationId + '&division_id=' + divisionId + "&section_id=" + sectionId + "&pc_id=" + pcId, {
		headers: {
			'Content-Type': 'multipart/form-data',
			'authorizedToken': token,
			'appidadmin':localStorage.getItem('app_id_session')
		}
	}).then((res) => {
		console.log("This is response:", res)
		if (res.data.status == "success")
			callback(res.data)
		else
			callback(0)
	}).catch((err) => {
		const errorMessage = err.response ? err.response.data.message : err.message;
		const statusCode = err.response ? err.response.status : 500;
		relogin(errorMessage, statusCode);
		console.log(err)
	})
}
/**
 * get the location rpl
 * @param {*} locationId 
 * @param {*} divisionId 
 * @param {*} sectionId 
 * @param {*} pcId 
 * @param {*} desigId 
 * @param {*} callback 
 * @returns 
 */
export const getAssessLocationRpl = (locationId, divisionId, sectionId, pcId, desigId, branchId, callback) => () => {
	const token = generateToken();
	axios.get(getBaseUrl() + 'api/assess/location/desig/rpl/hierarchy?year=' + getYear() + '&acess_token=' + accessToken() + '&location_id=' + locationId + '&division_id=' + divisionId + "&section_id=" + sectionId + "&pc_id=" + pcId + "&desig_id=" + desigId + "&branch_id=" + branchId, {
		headers: {
			'Content-Type': 'multipart/form-data',
			'authorizedToken': token,
			'appidadmin':localStorage.getItem('app_id_session')
		}
	}).then((res) => {
		console.log("This is response:", res)
		if (res.data.status == "success")
			callback(res.data)
		else
			callback(0)
	}).catch((err) => {
		const errorMessage = err.response ? err.response.data.message : err.message;
		const statusCode = err.response ? err.response.status : 500;
		relogin(errorMessage, statusCode);
		console.log(err)
	})
}
/**
 * get the location/branch division
 * @param {*} locationId 
 * @param {*} branchId 
 * @returns 
 */
export const getAssessBranchDivsHierarchy = (locationId, branchId, callback) => () => {
	const token = generateToken();
	axios.get(getBaseUrl() + 'api/assess/location/branch/divs/hierarchy?year=' + getYear() + '&acess_token=' + accessToken() + '&location_id=' + locationId + "&branch_id=" + branchId, {
		headers: {
			'Content-Type': 'multipart/form-data',
			'authorizedToken': token,
			'appidadmin':localStorage.getItem('app_id_session')
		}
	}).then((res) => {
		console.log("This is response:", res)
		if (res.data.status == "success")
			callback(res.data)
		else
			callback(0)
	}).catch((err) => {
		const errorMessage = err.response ? err.response.data.message : err.message;
		const statusCode = err.response ? err.response.status : 500;
		relogin(errorMessage, statusCode);
		console.log(err)
	})
}
/**
 * get the location / branch / division / section
 * @param {*} locationId 
 * @param {*} branchId 
 * @param {*} divisionId 
 * @param {*} callback 
 * @returns 
 */
export const getAssessBranchSectionHierarchy = (locationId, branchId, divisionId, callback) => () => {
	const token = generateToken();
	axios.get(getBaseUrl() + 'api/assess/location/branch/section/hierarchy?year=' + getYear() + '&acess_token=' + accessToken() + '&location_id=' + locationId + "&branch_id=" + branchId + "&division_id=" + divisionId, {
		headers: {
			'Content-Type': 'multipart/form-data',
			'authorizedToken': token,
			'appidadmin':localStorage.getItem('app_id_session')
		}
	}).then((res) => {
		console.log("This is response:", res)
		if (res.data.status == "success")
			callback(res.data)
		else
			callback(0)
	}).catch((err) => {
		const errorMessage = err.response ? err.response.data.message : err.message;
		const statusCode = err.response ? err.response.status : 500;
		relogin(errorMessage, statusCode);
		console.log(err)
	})
}
/**
 * get the location / branch / division / section / Job Grade
 * @param {*} locationId 
 * @param {*} branchId 
 * @param {*} divisionId 
 * @param {*} sectionId 
 * @param {*} callback 
 * @returns 
 */
export const getAssessBranchPCHierarchy = (locationId, branchId, divisionId, sectionId, callback) => () => {
	const token = generateToken();
	axios.get(getBaseUrl() + 'api/assess/location/branch/pc/hierarchy?year=' + getYear() + '&acess_token=' + accessToken() + '&location_id=' + locationId + "&branch_id=" + branchId + "&division_id=" + divisionId + "&section_id=" + sectionId, {
		headers: {
			'Content-Type': 'multipart/form-data',
			'authorizedToken': token,
			'appidadmin':localStorage.getItem('app_id_session')
		}
	}).then((res) => {
		console.log("This is response:", res)
		if (res.data.status == "success")
			callback(res.data)
		else
			callback(0)
	}).catch((err) => {
		const errorMessage = err.response ? err.response.data.message : err.message;
		const statusCode = err.response ? err.response.status : 500;
		relogin(errorMessage, statusCode);
		console.log(err)
	})
}
/**
 * get location / branch / division / section / Job Grade / designation
 * @param {*} locationId 
 * @param {*} branchId 
 * @param {*} divisionId 
 * @param {*} sectionId 
 * @param {*} pcId 
 * @param {*} callback 
 * @returns 
 */
export const getAssessBranchDesignationHierarchy = (locationId, branchId, divisionId, sectionId, pcId, callback) => () => {
	const token = generateToken();
	axios.get(getBaseUrl() + 'api/assess/location/branch/desig/hierarchy?year=' + getYear() + '&acess_token=' + accessToken() + '&location_id=' + locationId + "&branch_id=" + branchId + "&division_id=" + divisionId + "&section_id=" + sectionId + "&pc_id=" + pcId, {
		headers: {
			'Content-Type': 'multipart/form-data',
			'authorizedToken': token,
			'appidadmin':localStorage.getItem('app_id_session')
		}
	}).then((res) => {
		console.log("This is response:", res)
		if (res.data.status == "success")
			callback(res.data)
		else
			callback(0)
	}).catch((err) => {
		const errorMessage = err.response ? err.response.data.message : err.message;
		const statusCode = err.response ? err.response.status : 500;
		relogin(errorMessage, statusCode);
		console.log(err)
	})
}
/**
 * assing the sub group wise assessment
 * @param {*} mainGroupId 
 * @param {*} subGroupId 
 * @param {*} departmentId 
 * @param {*} divisionId 
 * @param {*} sectionId 
 * @param {*} pcId 
 * @param {*} desigId 
 * @returns 
 */
export const assignSubGroupAssessment = (mainGroupId, subGroupId,
	departmentId, divisionId, sectionId, pcId, desigId,
	assessmentData, callback) => () => {

		var bodyFormData = new FormData();

		bodyFormData.set('acess_token', accessToken());
		bodyFormData.set('year', getYear());
		bodyFormData.set('main_groupid', mainGroupId);
		bodyFormData.set('sub_groupid', subGroupId);
		bodyFormData.set('department_id', departmentId);
		bodyFormData.set('division_id', divisionId);
		bodyFormData.set('section_id', sectionId);
		bodyFormData.set('pc_id', pcId);
		bodyFormData.set('desig_id', desigId);
		bodyFormData.set('is_subgroup', 1);
		bodyFormData.set('assessment_data', JSON.stringify(assessmentData));
		const token = generateToken();
		axios.post(getBaseUrl() + 'api/admin/assign/assessments', bodyFormData, {
			headers: {
				'Content-Type': 'multipart/form-data',
				'authorizedToken': token,
			'appidadmin':localStorage.getItem('app_id_session')
			}
		}).then((res) => {
			console.log(res.data);
			callback(res.data)
		}).catch((err) => {
			const errorMessage = err.response ? err.response.data.message : err.message;
		const statusCode = err.response ? err.response.status : 500;
			relogin(errorMessage, statusCode);
			console.log(err)
		})
	}
/**
 * store assessment against location when branch is 0
 * @param {*} mainGroupId 
 * @param {*} locationId 
 * @param {*} divisionId 
 * @param {*} sectionId 
 * @param {*} pcId 
 * @param {*} desigId 
 * @param {*} assessmentData 
 * @param {*} callback 
 * @returns 
 */
export const assignLocationAssessment = (mainGroupId, locationId,
	divisionId, sectionId, pcId, desigId,
	assessmentData, isBranch, branchId, callback) => () => {

		var bodyFormData = new FormData();

		bodyFormData.set('acess_token', accessToken());
		bodyFormData.set('year', getYear());
		bodyFormData.set('main_groupid', mainGroupId);
		bodyFormData.set('location_id', locationId);
		bodyFormData.set('division_id', divisionId);
		bodyFormData.set('section_id', sectionId);
		bodyFormData.set('pc_id', pcId);
		bodyFormData.set('desig_id', desigId);
		bodyFormData.set('is_branch', isBranch);
		bodyFormData.set('branch_id', branchId);
		bodyFormData.set('assessment_data', JSON.stringify(assessmentData));
		const token = generateToken();
		axios.post(getBaseUrl() + 'api/admin/assign/assessments', bodyFormData, {
			headers: {
				'Content-Type': 'multipart/form-data',
				'authorizedToken': token,
			'appidadmin':localStorage.getItem('app_id_session')
			}
		}).then((res) => {
			console.log(res.data);
			callback(res.data)
		}).catch((err) => {
			const errorMessage = err.response ? err.response.data.message : err.message;
			const statusCode = err.response ? err.response.status : 500;
			relogin(errorMessage, statusCode);
			console.log(err)
		})
	}
/**
 * search assigned assessment with given parameters
 * @param {*} groupId 
 * @param {*} subGroupId 
 * @param {*} deptId 
 * @param {*} locationId 
 * @param {*} divisionId 
 * @param {*} sectionId 
 * @param {*} pcId 
 * @param {*} desigId 
 * @param {*} branchId 
 * @param {*} callback 
 * @returns 
 */
export const searchAssignedAssessment = (groupId, subGroupId,
	deptId, locationId, divisionId, sectionId, pcId, desigId, branchId, callback) => () => {
		const token = generateToken();
		axios.get(getBaseUrl() + 'api/admin/search/assessments?year=' + getYear() + '&acess_token=' + accessToken() + '&group_id=' + groupId + "&sub_groupid=" + subGroupId + "&dept_id=" + deptId + "&location_id=" + locationId + "&division_id=" + divisionId + '&section_id=' + sectionId + '&pc_id=' + pcId + '&desig_id=' + desigId + '&branch_id=' + branchId, {
			headers: {
				'Content-Type': 'multipart/form-data',
				'authorizedToken': token,
			'appidadmin':localStorage.getItem('app_id_session')
			}
		}).then((res) => {
			console.log("This is response:", res)
			if (res.data.status == "success")
				callback(res.data)
			else
				callback(0)
		}).catch((err) => {
			const errorMessage = err.response ? err.response.data.message : err.message;
			const statusCode = err.response ? err.response.status : 500;
			relogin(errorMessage, statusCode);
			console.log(err)
		})
	}
/**
 * edit assigned assessment with given parameters
 * @param {*} groupId 
 * @param {*} subGroupId 
 * @param {*} deptId 
 * @param {*} locationId 
 * @param {*} divisionId 
 * @param {*} sectionId 
 * @param {*} pcId 
 * @param {*} desigId 
 * @param {*} branchId 
 * @param {*} callback 
 * @returns 
 */
export const editAssignedAssessment = (groupId, subGroupId,
	deptId, locationId, divisionId, sectionId, pcId, desigId, branchId, callback) => () => {
		const token = generateToken();
		axios.get(getBaseUrl() + 'api/admin/edit/assessments?year=' + getYear() + '&acess_token=' + accessToken() + '&group_id=' + groupId + "&sub_groupid=" + subGroupId + "&dept_id=" + deptId + "&location_id=" + locationId + "&division_id=" + divisionId + '&section_id=' + sectionId + '&pc_id=' + pcId + '&desig_id=' + desigId + '&branch_id=' + branchId, {
			headers: {
				'Content-Type': 'multipart/form-data',
				'authorizedToken': token,
			'appidadmin':localStorage.getItem('app_id_session')
			}
		}).then((res) => {
			console.log("This is response:", res)
			if (res.data.status == "success")
				callback(res.data)
			else
				callback(0)
		}).catch((err) => {
			const errorMessage = err.response ? err.response.data.message : err.message;
		const statusCode = err.response ? err.response.status : 500;
			relogin(errorMessage, statusCode);
			console.log(err)
		})

	}
/**
 * update assessment data
 * @param {*} groupId 
 * @param {*} subGroupId 
 * @param {*} deptId 
 * @param {*} locationId 
 * @param {*} divisionId 
 * @param {*} sectionId 
 * @param {*} pcId 
 * @param {*} desigId 
 * @param {*} branchId 
 * @param {*} assessData 
 * @param {*} rplData 
 * @param {*} callback 
 * @returns 
 */
export const updateAssignedAssessment = (groupId, subGroupId,
	deptId, locationId, divisionId, sectionId, pcId, desigId, branchId, rplData, rclData, callback) => () => {
		var formData = new FormData();
		formData.set('year', getYear());
		formData.set('acess_token', accessToken());
		formData.set('group_id', groupId);
		formData.set('sub_groupid', subGroupId);
		formData.set('dept_id', deptId);
		formData.set('location_id', locationId);
		formData.set('division_id', divisionId);
		formData.set('section_id', sectionId);
		formData.set('pc_id', pcId);
		formData.set('desig_id', desigId);
		formData.set('branch_id', branchId);
		// formData.set('assess_data',JSON.stringify(assessData));
		formData.set('rpl_data', JSON.stringify(rplData));
		formData.set('rcl_data', JSON.stringify(rclData));
		const token = generateToken();
		axios.post(getBaseUrl() + 'api/admin/update/assessments', formData, {
			headers: {
				'Content-Type': 'multipart/form-data',
				'authorizedToken': token,
			'appidadmin':localStorage.getItem('app_id_session')
			}
		}).then((res) => {
			console.log("This is response:", res)
			if (res.data.status == "success")
				callback(res.data)
			else
				callback(0)
		}).catch((err) => {
			const errorMessage = err.response ? err.response.data.message : err.message;
			const statusCode = err.response ? err.response.status : 500;
			relogin(errorMessage, statusCode);
			console.log(err)
		})

	}

/**
 * get the group/departs
 * @param {*} locationId 
 * @param {*} branchId 
 * @returns 
 */
export const getAssessGroupDeparts = (groupId, callback) => () => {
	//alert("in ajax func")
	const token = generateToken();
	axios.get(getBaseUrl() + 'api/fetch/group/departs?year=' + getYear() + '&acess_token=' + accessToken() + '&groupid=' + groupId, {
		headers: {
			'Content-Type': 'multipart/form-data',
			'authorizedToken': token,
			'appidadmin':localStorage.getItem('app_id_session')
		}
	}).then((res) => {
		console.log("This is response:", res)
		if (res.data.status == "success")
			callback(res.data)
		else
			callback(0)
	}).catch((err) => {
		const errorMessage = err.response ? err.response.data.message : err.message;
		const statusCode = err.response ? err.response.status : 500;
		relogin(errorMessage, statusCode);
		console.log(err)
	})
}

/**
 * get the group/divisions
 * @param {*} locationId 
 * @param {*} branchId 
 * @returns 
 */
export const getAssessGroupDivisions = (groupId, callback) => () => {
	//alert("in ajax func")
	const token = generateToken();
	axios.get(getBaseUrl() + 'api/fetch/group/divisions?year=' + getYear() + '&acess_token=' + accessToken() + '&groupid=' + groupId, {
		headers: {
			'Content-Type': 'multipart/form-data',
			'authorizedToken': token,
			'appidadmin':localStorage.getItem('app_id_session')
		}
	}).then((res) => {
		console.log("This is response:", res)
		if (res.data.status == "success")
			callback(res.data)
		else
			callback(0)
	}).catch((err) => {
		const errorMessage = err.response ? err.response.data.message : err.message;
		const statusCode = err.response ? err.response.status : 500;
		relogin(errorMessage, statusCode);
		console.log(err)
	})
}

/**
 * get the sog/group/divisions
 * @param {*} locationId 
 * @param {*} branchId 
 * @returns 
 */
export const getAssessSOGGroupDivisions = (groupId, callback) => () => {
	//alert("in ajax func")
	const token = generateToken();
	axios.get(getBaseUrl() + 'api/fetch/sog/group/divisions?year=' + getYear() + '&acess_token=' + accessToken() + '&groupid=' + groupId, {
		headers: {
			'Content-Type': 'multipart/form-data',
			'authorizedToken': token,
			'appidadmin':localStorage.getItem('app_id_session')
		}
	}).then((res) => {
		console.log("This is response:", res)
		if (res.data.status == "success")
			callback(res.data)
		else
			callback(0)
	}).catch((err) => {
		const errorMessage = err.response ? err.response.data.message : err.message;
		const statusCode = err.response ? err.response.status : 500;
		relogin(errorMessage, statusCode);
		console.log(err)
	})
}
/**
 * get the hierarchy of divisions
 */
export const getAssesstDiv = (deptId, callback) => () => {
	//deptId = 4;
	const token = generateToken();
	axios.get(getBaseUrl() + 'api/assess/divs?year=' + getYear() + '&acess_token=' + accessToken() + '&dept_id=' + deptId, {
		headers: {
			'Content-Type': 'multipart/form-data',
			'authorizedToken': token,
			'appidadmin':localStorage.getItem('app_id_session')
		}
	}).then((res) => {
		console.log("This is response:", res)
		if (res.data.status == "success")
			callback(res.data)
		else
			callback(0)
	}).catch((err) => {
		const errorMessage = err.response ? err.response.data.message : err.message;
		const statusCode = err.response ? err.response.status : 500;
		relogin(errorMessage, statusCode);
		console.log(err)
	})
}
/**
 * get the hierarchy of sections
 */
export const getAssesstDivSesction = (deptId, divId, callback) => () => {
	//deptId = 4;
	const token = generateToken();
	axios.get(getBaseUrl() + 'api/assess/sections?year=' + getYear() + '&acess_token=' + accessToken() + '&dept_id=' + deptId + '&division_id=' + divId, {
		headers: {
			'Content-Type': 'multipart/form-data',
			'authorizedToken': token,
			'appidadmin':localStorage.getItem('app_id_session')
		}
	}).then((res) => {
		console.log("This is response:", res)
		if (res.data.status == "success")
			callback(res.data)
		else
			callback(0)
	}).catch((err) => {
		const errorMessage = err.response ? err.response.data.message : err.message;
		const statusCode = err.response ? err.response.status : 500;
		relogin(errorMessage, statusCode);
		console.log(err)
	})
}
/**
 * get the cf of sections
 */
export const getAssesstCFDivSesction = (divId, callback) => () => {
	//deptId = 4;
	const token = generateToken();
	axios.get(getBaseUrl() + 'api/assess/cf/sections?year=' + getYear() + '&acess_token=' + accessToken() + '&division_id=' + divId, {
		headers: {
			'Content-Type': 'multipart/form-data',
			'authorizedToken': token,
			'appidadmin':localStorage.getItem('app_id_session')
		}
	}).then((res) => {
		console.log("This is response:", res)
		if (res.data.status == "success")
			callback(res.data)
		else
			callback(0)
	}).catch((err) => {
		const errorMessage = err.response ? err.response.data.message : err.message;
		const statusCode = err.response ? err.response.status : 500;
		relogin(errorMessage, statusCode);
		console.log(err)
	})
}
/**
 * get the cf of sections
 */
export const getAssesstSOGDivSesction = (divId, callback) => () => {
	//deptId = 4;
	const token = generateToken();
	axios.get(getBaseUrl() + 'api/assess/sog/sections?year=' + getYear() + '&acess_token=' + accessToken() + '&division_id=' + divId, {
		headers: {
			'Content-Type': 'multipart/form-data',
			'authorizedToken': token,
			'appidadmin':localStorage.getItem('app_id_session')
		}
	}).then((res) => {
		console.log("This is response:", res)
		if (res.data.status == "success")
			callback(res.data)
		else
			callback(0)
	}).catch((err) => {
		const errorMessage = err.response ? err.response.data.message : err.message;
		const statusCode = err.response ? err.response.status : 500;
		relogin(errorMessage, statusCode);
		console.log(err)
	})
}
/**
 *fetch the assessment statistics
 * 
 * @param filter
 */
export const fetchAssessStatistics = (filter, callback) => () => {
	const queryString = new URLSearchParams(filter).toString();
	const token = generateToken();
	axios.get(getBaseUrl() + 'api/assess/statistics?year=' + getYear() + '&acess_token=' + accessToken() + "&" + queryString, {
		headers: {
			'Content-Type': 'multipart/form-data',
			'authorizedToken': token,
			'appidadmin':localStorage.getItem('app_id_session')
		}
	}).then((res) => {
		console.log("This is response:", res)
		if (res.data.status == "success")
			callback(res.data)
		else
			callback(0)
	}).catch((err) => {
		const errorMessage = err.response ? err.response.data.message : err.message;
		const statusCode = err.response ? err.response.status : 500;
		relogin(errorMessage, statusCode);
		console.log(err)
	})
}

/**
 * fetch the emplotee data
 * 
 * @param filter
 */
export const fetchEmployeeData = (filter, callback) => () => {
	let paramStr = new URLSearchParams(filter).toString();
	const token = generateToken();
	axios.get(getBaseUrl() + 'api/employees/data?year=' + getYear() + '&acess_token=' + accessToken() + "&" + paramStr, {
		headers: {
			'Content-Type': 'multipart/form-data',
			'authorizedToken': token,
			'appidadmin':localStorage.getItem('app_id_session')
		}
	}).then((res) => {
		console.log("This is response:", res)
		if (res.data.status == "success")
			callback(res.data)
		else
			callback(0)
	}).catch((err) => {
		const errorMessage = err.response ? err.response.data.message : err.message;
		const statusCode = err.response ? err.response.status : 500;
		relogin(errorMessage, statusCode);
		console.log(err)
	})
}
/**
 * fetch the pagcor charts data
 * 
 * 
 * @param year
 */
export const fetchChartsData = (groupId = 1, callback) => {
	const token = generateToken();
	axios.get(getBaseUrl() + 'api/population/data?year=' + getYear() + '&acess_token=' + accessToken() + '&group_id=' + groupId, {
		headers: {
			'Content-Type': 'multipart/form-data',
			'authorizedToken': token,
			'appidadmin':localStorage.getItem('app_id_session')
		}
	}).then((res) => {
		// console.log("This is response:", res)
		callback(res.data)
	}).catch((err) => {
		const errorMessage = err.response ? err.response.data.message : err.message;
		const statusCode = err.response ? err.response.status : 500;
		relogin(errorMessage, statusCode);
		console.log(err)
	})
}
/**
 * fetch the departments data
 * 
 * 
 */
export const fetchDepartments = (callback) => {
	const token = generateToken();
	axios.get(getBaseUrl() + 'api/departments?year=' + getYear() + '&acess_token=' + accessToken(), {
		headers: {
			'Content-Type': 'multipart/form-data',
			'authorizedToken': token,
			'appidadmin':localStorage.getItem('app_id_session')
		}
	}).then((res) => {
		// console.log("This is response:", res)
		callback(res.data)
	}).catch((err) => {
		const errorMessage = err.response ? err.response.data.message : err.message;
		const statusCode = err.response ? err.response.status : 500;
		relogin(errorMessage, statusCode);
		console.log(err)
	})
}
/**
 * fetch the departments chart data
 * 
 * 
 */
export const fetchDepartmentsData = (groupId, deptId, callback) => {
	const token = generateToken();
	axios.get(getBaseUrl() + 'api/population/data?year=' + getYear() + '&acess_token=' + accessToken() + '&dept_id=' + deptId + '&group_id=' + groupId, {
		headers: {
			'Content-Type': 'multipart/form-data',
			'authorizedToken': token,
			'appidadmin':localStorage.getItem('app_id_session')
		}
	}).then((res) => {
		// console.log("This is response:", res)
		callback(res.data)
	}).catch((err) => {
		const errorMessage = err.response ? err.response.data.message : err.message;
		const statusCode = err.response ? err.response.status : 500;
		relogin(errorMessage, statusCode);
		console.log(err)
	})
}
/**
 * fetch the division chart data
 * 
 * 
 */
export const fetchDivisionChartData = (groupId, deptId, divId, callback) => {
	const token = generateToken();
	axios.get(getBaseUrl() + 'api/population/data?year=' + getYear() + '&acess_token=' + accessToken() + '&dept_id=' + deptId + '&group_id=' + groupId + '&div_id=' + divId, {
		headers: {
			'Content-Type': 'multipart/form-data',
			'authorizedToken': token,
			'appidadmin':localStorage.getItem('app_id_session')
		}
	}).then((res) => {
		// console.log("This is response:", res)
		callback(res.data)
	}).catch((err) => {
		const errorMessage = err.response ? err.response.data.message : err.message;
		const statusCode = err.response ? err.response.status : 500;
		relogin(errorMessage, statusCode);
		console.log(err)
	})
}
/**
 * fetch the division chart data
 * 
 * 
 */
export const fetchsectionChartData = (groupId, deptId, divId, sectionId, callback) => {
	const token = generateToken();
	axios.get(getBaseUrl() + 'api/population/data?year=' + getYear() + '&acess_token=' + accessToken() + '&dept_id=' + deptId + '&group_id=' + groupId + '&div_id=' + divId + '&section_id=' + sectionId, {
		headers: {
			'Content-Type': 'multipart/form-data',
			'authorizedToken': token,
			'appidadmin':localStorage.getItem('app_id_session')
		}
	}).then((res) => {
		// console.log("This is response:", res)
		callback(res.data)
	}).catch((err) => {
		const errorMessage = err.response ? err.response.data.message : err.message;
		const statusCode = err.response ? err.response.status : 500;
		relogin(errorMessage, statusCode);
		console.log(err)
	})
}
/**
 * fetch the department/divisions
 * 
 * 
 */
export const fetchDepartmentDivisionData = (groupId, deptId, callback) => {
	const token = generateToken();
	axios.get(getBaseUrl() + 'api/departments/divisions?year=' + getYear() + '&acess_token=' + accessToken() + '&dept_id=' + deptId + '&group_id=' + groupId, {
		headers: {
			'Content-Type': 'multipart/form-data',
			'authorizedToken': token,
			'appidadmin':localStorage.getItem('app_id_session')
		}
	}).then((res) => {
		// console.log("This is response:", res)
		callback(res.data)
	}).catch((err) => {
		const errorMessage = err.response ? err.response.data.message : err.message;
		const statusCode = err.response ? err.response.status : 500;
		relogin(errorMessage, statusCode);
		console.log(err)
	})
}

/**
 * fetch the department/divisions
 * 
 * 
 */
export const fetchDepartmentSectionData = (groupId, deptId, divId, callback) => {
	const token = generateToken();
	axios.get(getBaseUrl() + 'api/division/sections?year=' + getYear() + '&acess_token=' + accessToken() + '&dept_id=' + deptId + '&group_id=' + groupId + '&div_id=' + divId, {
		headers: {
			'Content-Type': 'multipart/form-data',
			'authorizedToken': token,
			'appidadmin':localStorage.getItem('app_id_session')
		}
	}).then((res) => {
		// console.log("This is response:", res)
		callback(res.data)
	}).catch((err) => {
		const errorMessage = err.response ? err.response.data.message : err.message;
		const statusCode = err.response ? err.response.status : 500;
		relogin(errorMessage, statusCode);
		console.log(err)
	})
}

/**
 * fetch the location dropdown
 * 
 * 
 */
export const fetchLocationBranchesDD = (callback) => {
	const token = generateToken();
	axios.get(getBaseUrl() + 'api/locations/branches/dd?year=' + getYear() + '&acess_token=' + accessToken(), {
		headers: {
			'Content-Type': 'multipart/form-data',
			'authorizedToken': token,
			'appidadmin':localStorage.getItem('app_id_session')
		}
	}).then((res) => {
		// console.log("This is response:", res)
		callback(res.data)
	}).catch((err) => {
		const errorMessage = err.response ? err.response.data.message : err.message;
		const statusCode = err.response ? err.response.status : 500;
		relogin(errorMessage, statusCode);
		console.log(err)
	})
}
/**
 * fetch the branch chart data
 * 
 * 
 */
export const fetchBranchChartData = (groupId, branchId, callback) => {
	const token = generateToken();
	axios.get(getBaseUrl() + 'api/population/data?year=' + getYear() + '&acess_token=' + accessToken() + '&branch_id=' + branchId + '&group_id=' + groupId, {
		headers: {
			'Content-Type': 'multipart/form-data',
			'authorizedToken': token,
			'appidadmin':localStorage.getItem('app_id_session')
		}
	}).then((res) => {
		// console.log("This is response:", res)
		callback(res.data)
	}).catch((err) => {
		const errorMessage = err.response ? err.response.data.message : err.message;
		const statusCode = err.response ? err.response.status : 500;
		relogin(errorMessage, statusCode);
		console.log(err)
	})
}
/**
 * fetch the branch chart data
 * 
 * 
 */
export const fetchBranchDivChartData = (groupId, branchId, divId, callback) => {
	const token = generateToken();
	axios.get(getBaseUrl() + 'api/population/data?year=' + getYear() + '&acess_token=' + accessToken() + '&branch_id=' + branchId + '&group_id=' + groupId + '&div_id=' + divId, {
		headers: {
			'Content-Type': 'multipart/form-data',
			'authorizedToken': token,
			'appidadmin':localStorage.getItem('app_id_session')
		}
	}).then((res) => {
		// console.log("This is response:", res)
		callback(res.data)
	}).catch((err) => {
		const errorMessage = err.response ? err.response.data.message : err.message;
		const statusCode = err.response ? err.response.status : 500;
		relogin(errorMessage, statusCode);
		console.log(err)
	})
}
/**
 * fetch the branch chart data
 * 
 * 
 */
export const fetchBranchDivSectionChartData = (groupId, branchId, divId, sectionId, callback) => {
	const token = generateToken();
	axios.get(getBaseUrl() + 'api/population/data?year=' + getYear() + '&acess_token=' + accessToken() + '&branch_id=' + branchId + '&group_id=' + groupId + '&div_id=' + divId + '&section_id=' + sectionId, {
		headers: {
			'Content-Type': 'multipart/form-data',
			'authorizedToken': token,
			'appidadmin':localStorage.getItem('app_id_session')
		}
	}).then((res) => {
		// console.log("This is response:", res)
		callback(res.data)
	}).catch((err) => {
		const errorMessage = err.response ? err.response.data.message : err.message;
		const statusCode = err.response ? err.response.status : 500;
		relogin(errorMessage, statusCode);
		console.log(err)
	})
}
/**
 * fetch the location chart data
 * 
 * 
 */
export const fetchLocationChartData = (groupId, locationId, callback) => {
	const token = generateToken();
	axios.get(getBaseUrl() + 'api/population/data?year=' + getYear() + '&acess_token=' + accessToken() + '&location_id=' + locationId + '&group_id=' + groupId, {
		headers: {
			'Content-Type': 'multipart/form-data',
			'authorizedToken': token,
			'appidadmin':localStorage.getItem('app_id_session')
		}
	}).then((res) => {
		// console.log("This is response:", res)
		callback(res.data)
	}).catch((err) => {
		const errorMessage = err.response ? err.response.data.message : err.message;
		const statusCode = err.response ? err.response.status : 500;
		relogin(errorMessage, statusCode);
		console.log(err)
	})
}
/**
 * fetch the location div chart data
 * 
 * 
 */
export const fetchLocationDivChartData = (groupId, locationId, divId, callback) => {
	const token = generateToken();
	axios.get(getBaseUrl() + 'api/population/data?year=' + getYear() + '&acess_token=' + accessToken() + '&location_id=' + locationId + '&group_id=' + groupId + '&div_id=' + divId, {
		headers: {
			'Content-Type': 'multipart/form-data',
			'authorizedToken': token,
			'appidadmin':localStorage.getItem('app_id_session')
		}
	}).then((res) => {
		// console.log("This is response:", res)
		callback(res.data)
	}).catch((err) => {
		const errorMessage = err.response ? err.response.data.message : err.message;
		const statusCode = err.response ? err.response.status : 500;
		relogin(errorMessage, statusCode);
		console.log(err)
	})
}

/**
 * fetch the location sections chart data
 * 
 * 
 */
export const fetchLocationDivSectionsChartData = (groupId, locationId, divId, sectionId, callback) => {
	const token = generateToken();
	axios.get(getBaseUrl() + 'api/population/data?year=' + getYear() + '&acess_token=' + accessToken() + '&location_id=' + locationId + '&group_id=' + groupId + '&div_id=' + divId + '&section_id=' + sectionId, {
		headers: {
			'Content-Type': 'multipart/form-data',
			'authorizedToken': token,
			'appidadmin':localStorage.getItem('app_id_session')
		}
	}).then((res) => {
		// console.log("This is response:", res)
		callback(res.data)
	}).catch((err) => {
		const errorMessage = err.response ? err.response.data.message : err.message;
		const statusCode = err.response ? err.response.status : 500;

		relogin(errorMessage, statusCode);
		console.log(err)
	})
}

export const updateProfileYear = (year) => async (dispatch) => {
	dispatch({
		type: _setPrfileYear,
		payload: year
	});
}