import React, { Component } from "react";
import { Redirect } from "react-router-dom";
// import Header from '../../Header';
// import Footer from "./../Footer";

import {
  fetchAdminData, getMainGroupsData, fetchSubGroupsOrLocationsOrSOG,
  getAssesstDeptsHierarchy, getAssesstDivHierarchy, getAssesstSectionHierarchy,
  getAssesstPCHierarchy, getLocationDivsHierarchy, getLocationSectionHierarchy,
  getLocationPCHierarchy, fetchLocationBranches, getAssessBranchDivsHierarchy,
  getAssessBranchSectionHierarchy, getAssessBranchPCHierarchy, fetchEmployeeData,
  adminFetchEmployeeInformation
} from '../../../../../src/actions/webRedActions';

// import { getEmployeeProfile } from '../../../../actions/utility';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// import userData from './../userData';

import {
  Button,
  Container,
  Row,
  Col,
  Media,
  Form,
  FormGroup,
  Label,
  Input,
  Spinner
} from "reactstrap";

// import readXlsxFile from 'read-excel-file';
import EmployeeProfile from "./EmployeeProfile";
import EmployeeSearchForm from "./EmployeeSearchForm";
import AdminHeader from './../AdminHeader';
import SideMenu from './../sidMenu/SideMenu';
import { getAdminUserData, getYear, updateYear } from "../../../../../src/actions/utility";
// import { BrowserRouter as Router, Route, Link } from "react-router-dom";
// import { ProSidebar, SidebarHeader, SidebarFooter, SidebarContent, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faBars,faHome,faUsers } from '@fortawesome/free-solid-svg-icons';
import 'react-pro-sidebar/dist/css/styles.css';
import './../../../../App.css';
import UpdateYear from "../../../updateyear";
class EmployeeManagment extends Component {

  constructor(props) {
    super(props);
    this.state = {
      empProfileData: '',
      empSupervisorData: '',
      empReportingData: '',
      assessmentMangement: '',
      mainGroupsDataArr: [],
      subGroups: [],
      locations: [],
      departsData: [],
      locationBranches: [],
      divisionData: [],
      sectionData: [],
      pcData: [],
      divisionLoading: false,
      sectionLoading: false,
      locationLoading: false,
      branchLoading: false,
      departmentLoading: false,
      loadingSubGroups: false,
      departmentLoading: false,
      applyFilterLoader: false,
      page: 1,
      employeeData: [],
      isLoadingData: false,
      groupId: "",
      subGroupId: "",
      locationId: "",
      branchId: "",
      divisionId: "",
      sectionId: "",
      deptId: "",
      pcId: "",
      loadMoreEnabled: false,
      page: 1,
      activeRowKey: null,
      showSpinner : false,
      selYear : "",
    };
  
  }
  componentWillMount() {
    // updateYear("");
    this.props.getMainGroupsData()
    try {
      var adminData = getAdminUserData();

      if (adminData.is_superadmin == 0) {

        let employeeId = adminData.admin.employee_id
        //checking for updated data on server
        this.props.fetchAdminData(employeeId, function (adminResData) {

          // console.log('here is the length:',Object.keys(adminResData).length);

          if (adminResData && typeof adminResData === 'object' && adminResData.status == 'success' && Object.keys(adminResData).length) {

            localStorage.setItem('userAdminData', JSON.stringify(adminResData.result));

            // if(adminData.admin.can_add != adminResData.result.admin.can_add) {
            //   this.forceUpdate();
            // }
            // if(adminData.admin.can_edit != adminResData.result.admin.can_edit) {
            //   this.forceUpdate();
            // }
            // if(adminData.admin.can_delete != adminResData.result.admin.can_delete) {
            //   this.forceUpdate();
            // }
          }
        });
      }
    }
    catch (err) {
      console.log('error', err);
    }
    // console.log('adminData:',adminData);
  }


  setProfileYear = (year) => {
    // alert("Hi")
    this.setState({selYear : year})
  }
  componentWillReceiveProps(nextProps, thisProps) {

    if (this.props.fetchEmpDataSuccessAdmin != nextProps.fetchEmpDataSuccessAdmin) {
      // console.log(nextProps.fetchEmpDataSuccessAdmin);
      if (nextProps.fetchEmpDataSuccessAdmin.result) {
        if (nextProps.fetchEmpDataSuccessAdmin.result.profile) {
          if (nextProps.fetchEmpDataSuccessAdmin.result.profile !== null) {
            this.setState({
              empProfileData: nextProps.fetchEmpDataSuccessAdmin.result.profile,
              showSpinner:false
            }, () => {
              console.log(this.state.empProfileData)
              this.scrollToBottom();
            })
          } else {
            this.setState({
              empProfileData: ''
            })
          }
        }

        if (nextProps.fetchEmpDataSuccessAdmin.result.supervision && nextProps.fetchEmpDataSuccessAdmin.result.supervision.length) {
          this.setState({
            empSupervisorData: nextProps.fetchEmpDataSuccessAdmin.result.supervision
          })
        } else {
          this.setState({
            empSupervisorData: ''
          })
        }

        if (nextProps.fetchEmpDataSuccessAdmin.result.sub_ords && nextProps.fetchEmpDataSuccessAdmin.result.sub_ords.length) {
          this.setState({
            empReportingData: nextProps.fetchEmpDataSuccessAdmin.result.sub_ords
          })
        } else {
          this.setState({
            empReportingData: ''
          })
        }

      }
    }

    if (this.props.fetchedAllAssessmentsForAdmin != nextProps.fetchedAllAssessmentsForAdmin) {
      if (nextProps.fetchedAllAssessmentsForAdmin.result) {
        this.setState({
          assessmentMangement: nextProps.fetchedAllAssessmentsForAdmin.result
        }, () => {
          console.log(this.state.assessmentMangement)
        })
      }
    }
    if (this.props.mainGroupsData != nextProps.mainGroupsData) {
      this.setState({ mainGroupsDataArr: nextProps.mainGroupsData.data }, () => {
        console.log('main groups data fetch and set now in state:', this.state.mainGroupsDataArr);
      })
    }

  }
  subGroups = (groupId) => {
    // alert("Hi");
    this.setState({
      loadingSubGroups: true, divisionData: [],
      groupId: groupId, deptDivs: [],
      subGroups: [], locations: [], sogs: [],
      loacations: [], divisionData: [], sectionData: [],
      pcData: [], locations: [], locationBranches: [],
      departsData: [], pcData: [], divisionData: [], sectionData: [],
      subGroupId: '', locationId: "", branchId: "", divisionId: "", sectionId: "",
      deptId: "", pcId: "",employeeData:[],empProfileData:"",activeRowKey:null
    })
    let ths = this;
    this.props.fetchSubGroupsOrLocationsOrSOG(groupId, function (res) {
      console.log("this is the sever res:", res)
      if (res != 0) {
        ths.setState({ subGroups: res.result, loadingSubGroups: false })
      }
    })
  }
  sog = (groupId) => {
    // alert("Hi");
    this.setState({
      loadingDepart: true, divisionData: [], groupId: groupId, deptDivs: [],
      subGroups: [], locations: [], sogs: [], pcData: [], locations: [], locationBranches: [],
      subGroupId: '', locationId: "", branchId: "", divisionId: "", sectionId: "",
      deptId: "", pcId: "",employeeData:[],empProfileData:"",activeRowKey:null
    })
    let ths = this;
    this.props.fetchSubGroupsOrLocationsOrSOG(groupId, function (res) {
      console.log("this is the sever res:", res)
      if (res != 0) {
        ths.setState({ sogs: res.result, loadingDepart: false })
      }
    })
  }
  locations = (groupId) => {
    // alert("Hi");
    this.setState({
      loadingSubGroups: true, divisionData: [], groupId: groupId, deptDivs: [],
      sogs: [], subGroups: [], locations: [], sogs: [],
      loacations: [], divisionData: [], sectionData: [], departsData: [],
      pcData: [], locationBranches: [],
      subGroupId: '', locationId: "", branchId: "", divisionId: "", sectionId: "",
      deptId: "", pcId: "",employeeData:[],empProfileData:"",activeRowKey:null
    })
    let ths = this;
    this.props.fetchSubGroupsOrLocationsOrSOG(groupId, function (res) {
      console.log("this is the sever res:", res)
      if (res != 0) {
        ths.setState({ locations: res.result, loadingSubGroups: false })
      }
    })
  }
  groupDeparts = (subGroupId) => {
    // alert("Hi");
    this.setState({
      departmentLoading: true, divisionData: [], subGroupId: subGroupId, deptDivs: [],
      locations: [], sogs: [], pcData: [], divisionData: [], sectionData: [],
      locationId: "", branchId: "", divisionId: "", sectionId: "",
      deptId: "", pcId: "",employeeData:[],empProfileData:"",activeRowKey:null
    })
    let ths = this;
    this.props.getAssesstDeptsHierarchy(subGroupId, function (res) {
      console.log("this is the sever res:", res)
      if (res != 0) {
        ths.setState({ departsData: res.result, departmentLoading: false })
      }
    })
  }
  getDivisions = (deptId) => {
    this.setState({
      divisionLoading: true, divisionData: [], pcData: [], sectionData: [], deptId: deptId,
      locationId: "", branchId: "", divisionId: "", sectionId: "", pcId: "",employeeData:[],empProfileData:""
      ,activeRowKey:null
    })
    let ths = this;
    this.props.getAssesstDivHierarchy(deptId, this.state.subGroupId, function (res) {
      console.log("this is the sever res for divisions:", res.result)
      if (res != 0) {
        ths.setState({ divisionData: res.result, divisionLoading: false })
      }
    })
  }
  getSections = (divId) => {
    this.setState({
      sectionLoading: true, divisionId: divId, pcData: [],
      locationId: "", branchId: "", sectionId: "", pcId: "",employeeData:[],empProfileData:"",activeRowKey:null
    })
    const { subGroupId, deptId } = this.state
    let ths = this;
    this.props.getAssesstSectionHierarchy(subGroupId, deptId, divId, function (res) {
      console.log("this is the sever res for divisions:", res.result)
      if (res != 0) {
        ths.setState({ sectionData: res.result, sectionLoading: false })
      }
    })
  }
  getPC = (sectionId) => {
    this.setState({ pcLoading: true, sectionId: sectionId, locationId: "", branchId: "", pcId: "",employeeData:[],empProfileData:""
    ,activeRowKey:null
  })
    const { subGroupId, deptId, divisionId } = this.state
    let ths = this;
    this.props.getAssesstPCHierarchy(subGroupId, deptId, divisionId, sectionId, function (res) {
      console.log("this is the sever res for divisions:", res.result)
      if (res != 0) {
        ths.setState({ pcData: res.result, pcLoading: false })
      }
    })
  }
  getlocationDivs = (locationId) => {
    this.setState({
      locationLoading: true, locationId: locationId, divisionData: [], pcData: [], sectionData: [],
      subGroupId: "", branchId: "", divisionId: "", sectionId: "", pcId: "", deptId: "",employeeData:[],empProfileData:""
      ,activeRowKey:null
    })
    let ths = this;
    this.props.getLocationDivsHierarchy(locationId, (res) => {
      console.log("this is the sever res for divisions:", res.result)
      if (res != 0) {
        ths.setState({ divisionData: res.result, locationLoading: false })
      }
    })
  }
  getlocationSection = (divisionId) => {
    this.setState({
      sectionLoading: true, divisionId: divisionId, sectionData: [], pcData: [],
      subGroupId: "", branchId: "", sectionId: "", pcId: "", deptId: "",employeeData:[],
      empProfileData:"",activeRowKey:null
    })
    const { locationId } = this.state;
    let ths = this;
    this.props.getLocationSectionHierarchy(locationId, divisionId, (res) => {
      console.log("this is the sever res for divisions:", res.result)
      if (res != 0) {
        ths.setState({ sectionLoading: false, sectionData: res.result })
      }
    })
  }
  getLocationPC = (sectionId) => {

    this.setState({
      pcLoading: true, sectionId: sectionId, pcData: [],
      subGroupId: "", branchId: "", pcId: "", deptId: "",employeeData:[],empProfileData:""
      ,activeRowKey:null
    })
    const { locationId, divisionId } = this.state;
    let ths = this;
    this.props.getLocationPCHierarchy(locationId, divisionId, sectionId, (res) => {
      console.log("this is the sever res for divisions:", res.result)
      if (res != 0) {
        ths.setState({ pcData: res.result, pcLoading: false })
      }
    })
  }
  getBranches = (locId) => {
    this.setState({
      locationLoading: true, locationId: locId, locationBranches: [],
      subGroupId: "", branchId: "", divisionId: "", sectionId: "", pcId: "", deptId: ""
      ,activeRowKey:null,
      employeeData:[],empProfileData:""
    })
    const { locationId, divisionId } = this.state;
    let ths = this;
    this.props.fetchLocationBranches(locId, (res) => {
      console.log("this is the sever res for divisions:", res.result)
      if (res != 0) {
        ths.setState({ locationLoading: false, locationBranches: res.result })
      }
    })

  }
  getBranchDiv = (branchId) => {
    this.setState({
      divisionLoading: true, branchId: branchId, divisionData: [], pcData: [], sectionData: [],
      subGroupId: "", divisionId: "", sectionId: "", pcId: "", deptId: "",
      employeeData:[],empProfileData:"",activeRowKey:null
    })
    const { locationId } = this.state
    //getAssessBranchDivsHierarchy
    let ths = this;
    this.props.getAssessBranchDivsHierarchy(locationId, branchId, (res) => {
      console.log("this is the sever res for divisions:", res.result)
      if (res != 0) {
        ths.setState({ divisionLoading: false, divisionData: res.result })
      }
    })
  }
  getlocationBranchSection = (divisionId) => {
    this.setState({
      sectionLoading: true, divisionId: divisionId, sectionData: [], pcData: [],
      subGroupId: "", sectionId: "", pcId: "", deptId: "",employeeData:[],empProfileData:""
      ,activeRowKey:null
    })
    const { locationId, branchId } = this.state;
    let ths = this;
    this.props.getAssessBranchSectionHierarchy(locationId, branchId, divisionId, (res) => {
      console.log("this is the sever res for divisions:", res.result)
      if (res != 0) {
        ths.setState({ sectionLoading: false, sectionData: res.result })
      }
    })
  }
  getLocationBranchPC = (sectionId) => {

    this.setState({
      pcLoading: true, sectionId: sectionId, pcData: [],
      subGroupId: "", deptId: "", pcId: "",employeeData:[],empProfileData:"",activeRowKey:null
    })
    const { locationId, divisionId, branchId } = this.state;
    let ths = this;
    this.props.getAssessBranchPCHierarchy(locationId, branchId, divisionId, sectionId, (res) => {
      console.log("this is the sever res for divisions:", res.result)
      if (res != 0) {
        ths.setState({ pcData: res.result, pcLoading: false })
      }
    })
  }

  handleRowClick = (key, employeeId) => {
    this.setState({showSpinner:true})
    // Toggle active state on row click
    this.setState(prevState => ({
      activeRowKey: prevState.activeRowKey === key ? null : key
    }));

    // Call your function here
    console.log("employee id: " + employeeId);
    this.props.adminFetchEmployeeInformation(employeeId);
  }
  scrollToBottom = () => {
    // Get the target element
    var targetElement = document.querySelector('.ProfileComponentEmployee');

    // Scroll to the target element
    if (targetElement) {
      // Get the position of the target element relative to the viewport
      var elementPosition = targetElement.getBoundingClientRect().top;

      // Scroll the window to the position of the target element
      window.scrollTo({
        top: window.scrollY + elementPosition,
        behavior: 'smooth' // Optional: smooth scrolling behavior
      });
    }
  };
  render() {

    const { empProfileData, empSupervisorData,
      empReportingData, assessmentMangement, mainGroupsDataArr,
      subGroups,
      locations,
      departsData,
      locationBranches,
      divisionData,
      sectionData,
      pcData,
      pcLoading,
      sectionLoading,
      divisionLoading,
      locationLoading,
      loadingSubGroups,
      departmentLoading,
      employeeData,
      isLoadingData,
      activeRowKey,
      showSpinner
    } = this.state;

    return (
      <div>
        {/*Side menu*/}
        <SideMenu />

        <AdminHeader title={'Employee Management'} style={{ marginLeft: 80 }} />
        <Container className="AssessmentStatistics">
          <Row>
            <Col md={{ size: 4 }}>
              <FormGroup>
                <Label for="BRANCH">Main Group</Label>:
                <Input type="select" name="main_group" id="main_group" onChange={(event) => {
                  console.log("sel val:", event.target.value)
                  if (event.target.value == 1) {
                    this.subGroups(event.target.value);
                  }
                  else if (event.target.value == 2 || event.target.value == 3) {
                    this.locations(event.target.value);
                  }
                }}>
                  <option value="0">Select Main Group</option>
                  {
                    mainGroupsDataArr.length > 0 ?
                      mainGroupsDataArr.map((group, index) =>
                        <option value={group.group_id}>{group.group_name}</option>
                      )
                      : null
                  }
                </Input>
                  {/* <UpdateYear/> */}
              </FormGroup>
              {
                loadingSubGroups == true ?
                  <Spinner color="primary" style={{
                    width: '1.10rem',
                    height: '1.10rem',
                    marginTop: '-40px',
                    float: 'right'
                  }} />
                  : null
              }
            </Col>
            <Button style={{ position: 'absolute', top: '110px', left: '90%' }} color={"warning"} size="md" onClick={() => {
              let filter = "";
              this.setState({ isLoadingData: true })
              if (this.state.groupId == 1) {
                filter = {
                  group_id: this.state.groupId,
                  dept_id: this.state.deptId,
                  div_id: this.state.divisionId,
                  section_id: this.state.sectionId,
                  page: this.state.page,
                  sub_groupid: this.state.subGroupId,
                  pc_id: this.state.pcId
                }
                console.log("This is the filter data:", filter)
                this.props.fetchEmployeeData(filter, (data) => {
                  this.setState({ applyFilterLoader: false, isLoadingData: false })
                  console.log("This is the server data:", data.result)
                  //statisticsData
                  if (data != 0) {
                    this.setState({ employeeData: data.result })
                  }
                })

              }
              if (this.state.groupId == 2 || this.state.groupId == 3) {
                filter = {
                  group_id: this.state.groupId,
                  div_id: this.state.divisionId,
                  section_id: this.state.sectionId,
                  pc_id: this.state.pcId,
                  location_id: this.state.locationId,
                  branch_id: this.state.branchId,
                  page: this.state.page,
                }
                console.log("This is the filter data:", filter)
                this.props.fetchEmployeeData(filter, (data) => {
                  console.log("This is the server data:", data.result)
                  this.setState({ applyFilterLoader: false, isLoadingData: false })
                  //statisticsData
                  if (data != 0) {
                    this.setState({ employeeData: data.result })
                  }
                })

              }
            }}>
              {'Apply filter'}
              {
                this.state.applyFilterLoader ? <Spinner style={{ margin: '4px 0px 4px 10px' }} color="light" size="sm" /> : null
              }
            </Button>
            {
              subGroups.length > 0 ?
                <Col md={{ size: 4 }}>
                  <>
                    {
                      subGroups.length > 0 ?
                        <FormGroup>
                          <Label for="BRANCH">Sub Groups</Label>:
                          <Input type="select" name="main_group" id="main_group" onChange={(e) => {
                            this.groupDeparts(e.target.value)
                          }}>
                            <option value="0">Select sub group</option>
                            {
                              subGroups.length > 0 ?
                                subGroups.map((sg, index) =>
                                  <option value={sg.sub_groupid}>{sg.sub_groupname}</option>
                                )
                                : null
                            }
                          </Input>
                        </FormGroup>
                        :
                        null
                    }
                  </>

                  {
                    departmentLoading == true ?
                      <Spinner color="primary" style={{
                        width: '1.10rem',
                        height: '1.10rem',
                        marginTop: '-40px',
                        float: 'right'
                      }} />
                      : null
                  }
                </Col>
                : null
            }
            {
              locations.length > 0 ?
                <Col md={{ size: 4 }}>
                  <>
                    {
                      locations.length > 0 ?
                        <FormGroup>
                          <Label for="BRANCH">Locations</Label>:
                          <Input type="select" name="main_group" id="main_group" onChange={(e) => {
                            if (this.state.groupId == 2)
                              this.getlocationDivs(e.target.value)
                            if (this.state.groupId == 3)
                              this.getBranches(e.target.value)
                          }}>
                            <option value="0">Select locations</option>
                            {
                              locations.length > 0 ?
                                locations.map((location, index) =>
                                  <option value={location.location_id}>{location.location_name}</option>
                                )
                                : null
                            }
                          </Input>
                        </FormGroup>
                        :
                        null
                    }
                  </>

                  {
                    locationLoading == true ?
                      <Spinner color="primary" style={{
                        width: '1.10rem',
                        height: '1.10rem',
                        marginTop: '-40px',
                        float: 'right'
                      }} />
                      : null
                  }
                </Col>
                : null
            }
            {
              departsData.length > 0 ?
                <Col md={{ size: 4 }}>
                  <FormGroup>
                    <Label for="BRANCH">Departments</Label>:
                    <Input type="select" name="main_group" id="main_group" onChange={(e) => {
                      // alert(e.target.value)
                      this.getDivisions(e.target.value)
                    }}>
                      <option value="0">Select department</option>
                      {
                        departsData.length > 0 ?
                          departsData.map((department, index) =>
                            <option value={department.dept_id}>{department.dept_suffix}</option>
                          )
                          : null
                      }
                    </Input>
                  </FormGroup>
                  {
                    divisionLoading == true ?
                      <Spinner color="primary" style={{
                        width: '1.10rem',
                        height: '1.10rem',
                        marginTop: '-40px',
                        float: 'right'
                      }} />
                      : null
                  }
                </Col>

                : null
            }
            {
              locationBranches.length > 0 ?
                <Col md={{ size: 4 }}>
                  <FormGroup>
                    <Label for="BRANCH">Branches</Label>:
                    <Input type="select" name="main_group" id="main_group" onChange={(e) => {
                      // alert(e.target.value)
                      this.getBranchDiv(e.target.value)
                    }}>
                      <option value="0">Select branch</option>
                      {
                        locationBranches.length > 0 ?
                          locationBranches.map((branch, index) =>
                            <option value={branch.branch_id}>{branch.branch_name}</option>
                          )
                          : null
                      }
                    </Input>
                  </FormGroup>
                  {
                    divisionLoading == true ?
                      <Spinner color="primary" style={{
                        width: '1.10rem',
                        height: '1.10rem',
                        marginTop: '-40px',
                        float: 'right'
                      }} />
                      : null
                  }
                </Col>

                : null
            }
            {
              divisionData.length > 0 ?
                <Col md={{ size: 4 }}>
                  <FormGroup>
                    <Label for="BRANCH">Divisions</Label>:
                    <Input type="select" name="main_group" id="main_group" onChange={(e) => {
                      // alert(e.target.value)
                      if (this.state.groupId == 1)
                        this.getSections(e.target.value)
                      else if (this.state.groupId == 2)
                        this.getlocationSection(e.target.value)
                      else if (this.state.groupId == 3)
                        this.getlocationBranchSection(e.target.value)
                    }}>
                      <option value="0">Select Division</option>
                      {
                        divisionData.length > 0 ?
                          divisionData.map((div, index) =>
                            <option value={div.division_id}>{div.division_name}</option>
                          )
                          : null
                      }
                    </Input>
                  </FormGroup>
                  {
                    sectionLoading == true ?
                      <Spinner color="primary" style={{
                        width: '1.10rem',
                        height: '1.10rem',
                        marginTop: '-40px',
                        float: 'right'
                      }} />
                      : null
                  }
                </Col>

                : null
            }

            {
              sectionData.length > 0 ?
                <Col md={{ size: 4 }}>
                  <FormGroup>
                    <Label for="BRANCH">Sections</Label>:
                    <Input type="select" name="main_group" id="main_group" onChange={(e) => {
                      // alert(e.target.value)
                      // if (this.state.groupId == 1)
                      //   this.getPC(e.target.value)
                      // else if (this.state.groupId == 2)
                      //   this.getLocationPC(e.target.value)
                      // else if (this.state.groupId == 3)
                      //   this.getLocationBranchPC(e.target.value)

                      this.setState({ sectionId: e.target.value })
                    }}>
                      <option value="0">Select Section</option>
                      {
                        sectionData.length > 0 ?
                          sectionData.map((section, index) =>
                            <option value={section.section_id}>{section.section_name}</option>
                          )
                          : null
                      }
                    </Input>
                  </FormGroup>
                  {
                    pcLoading == true ?
                      <Spinner color="primary" style={{
                        width: '1.10rem',
                        height: '1.10rem',
                        marginTop: '-40px',
                        float: 'right'
                      }} />
                      : null
                  }
                </Col>

                : null
            }
            {
              pcData.length > 0 ?
                <Col md={{ size: 4 }}>
                  <FormGroup>
                    <Label for="BRANCH">Job Grade</Label>:
                    <Input type="select" name="main_group" id="main_group" onChange={(e) => {
                      // alert(e.target.value)
                      // this.getPC(e.target.value)
                      this.setState({ pcId: e.target.value })
                    }}>
                      <option value="0">Select Job Grade</option>
                      {
                        pcData.length > 0 ?
                          pcData.map((pc, index) =>
                            <option value={pc.pc_id}>{pc.pc_name}</option>
                          )
                          : null
                      }
                    </Input>
                  </FormGroup>
                </Col>

                : null
            }



          </Row>

          <EmployeeSearchForm selYear={this.state.selYear}/>

          <Row className="bgColorApplied" style={{ paddingTop: '4vh', textTransform: 'uppercase', fontWeight: 'bold' }}>
            <Col md={{ size: 2 }}>
              Employee ID
            </Col>
            <Col md={{ size: 3 }}>
              Fullname
            </Col>
            <Col md={{ size: 3 }}>
              Division
            </Col>
            <Col md={{ size: 2 }}>
              SECTION
            </Col>
          </Row>
          {
            employeeData && employeeData.length ?
              employeeData.map((emp, key) =>
                <Row
                  key={key}
                  className={activeRowKey === key ? "bgColorApplied active" : "bgColorApplied"}
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    this.handleRowClick(key, emp.employee_id)
                  }}
                >
                  {activeRowKey === key && showSpinner == true && <Spinner size='sm'/>}
                  <Col md={{ size: 2 }}>
                    {emp.employee_id}
                  </Col>
                  <Col md={{ size: 3 }}>
                    {emp.FIRST_NAME + " " + emp.LAST_NAME}
                  </Col>
                  <Col md={{ size: 3 }}>
                    {emp.division_name}
                  </Col>
                  <Col md={{ size: 2 }}>
                    {emp.section_name}
                  </Col>
                </Row>


              )

              :
              isLoadingData == true ?
                <Spinner style={{ margin: '20vh 0' }} size="md" color="dark" />
                : null

          }

          {
            employeeData.length > 0 ?
              <>
                <Row style={{ marginBottom: 100 }}>
                  <Col>
                    <Button style={{ textAlign: 'left', marginTop: 20, marginRight: 10 }} size="sm" color="secondary"
                      disabled={this.state.page == 1 || this.state.loadMoreEnabled ? true : false}
                      onClick={(e) => {
                        this.setState({
                          page: this.state.page - 1,
                          loadMoreEnabled: false,
                          isLoadingData: true,
                          employeeData: []
                        }, () => {
                          let filter = "";
                          if (this.state.groupId == 1) {
                            filter = {
                              group_id: this.state.groupId,
                              dept_id: this.state.deptId,
                              div_id: this.state.divisionId,
                              section_id: this.state.sectionId,
                              page: this.state.page,
                              sub_groupid: this.state.subGroupId,
                              pc_id: this.state.pcId
                            }
                            console.log("This is the filter data:", filter)
                            this.props.fetchEmployeeData(filter, (data) => {
                              this.setState({ applyFilterLoader: false, isLoadingData: false })
                              console.log("This is the server data:", data.result)
                              //statisticsData
                              if (data != 0) {
                                this.setState({ employeeData: data.result })
                              }
                            })

                          }
                          if (this.state.groupId == 2 || this.state.groupId == 3) {
                            filter = {
                              group_id: this.state.groupId,
                              div_id: this.state.divisionId,
                              section_id: this.state.sectionId,
                              pc_id: this.state.pcId,
                              location_id: this.state.locationId,
                              branch_id: this.state.branchId,
                              page: this.state.page,
                            }
                            console.log("This is the filter data:", filter)
                            this.props.fetchEmployeeData(filter, (data) => {
                              console.log("This is the server data:", data.result)
                              this.setState({ applyFilterLoader: false, isLoadingData: false })
                              //statisticsData
                              if (data != 0) {
                                this.setState({ employeeData: data.result })
                              }
                            })

                          }
                        })
                      }}>
                      {
                        this.state.loadMoreEnabled ?
                          <Spinner size="sm" color="light" style={{ marginRight: '5px' }} />
                          : null
                      }
                      Previous
                    </Button>
                    <Button style={{ textAlign: 'left', marginTop: 20 }} size="sm" color="secondary"
                      disabled={this.state.loadMoreEnabled}
                      onClick={(e) => {
                        this.setState({
                          page: this.state.page + 1,
                          loadMoreEnabled: false,
                          isLoadingData: true,
                          employeeData: []
                        }, () => {
                          let filter = "";
                          if (this.state.groupId == 1) {
                            filter = {
                              group_id: this.state.groupId,
                              dept_id: this.state.deptId,
                              div_id: this.state.divisionId,
                              section_id: this.state.sectionId,
                              page: this.state.page,
                              sub_groupid: this.state.subGroupId,
                              pc_id: this.state.pcId
                            }
                            console.log("This is the filter data:", filter)
                            this.props.fetchEmployeeData(filter, (data) => {
                              this.setState({ applyFilterLoader: false, isLoadingData: false })
                              console.log("This is the server data:", data.result)
                              //statisticsData
                              if (data != 0) {
                                this.setState({ employeeData: data.result })
                              }
                            })

                          }
                          if (this.state.groupId == 2 || this.state.groupId == 3) {
                            filter = {
                              group_id: this.state.groupId,
                              div_id: this.state.divisionId,
                              section_id: this.state.sectionId,
                              pc_id: this.state.pcId,
                              location_id: this.state.locationId,
                              branch_id: this.state.branchId,
                              page: this.state.page,
                            }
                            console.log("This is the filter data:", filter)
                            this.props.fetchEmployeeData(filter, (data) => {
                              console.log("This is the server data:", data.result)
                              this.setState({ applyFilterLoader: false, isLoadingData: false })
                              //statisticsData
                              if (data != 0) {
                                this.setState({ employeeData: data.result })
                              }
                            })

                          }
                        })
                      }}
                    >
                      {
                        this.state.loadMoreEnabled ?
                          <Spinner size="sm" color="light" style={{ marginRight: '5px' }} />
                          : null
                      }
                      Next</Button>
                  </Col>
                </Row>

              </>
              : null

          }
          {/* <Footer /> */}
          {
            empProfileData && empProfileData != '' ?
              <EmployeeProfile userData={empProfileData} userSupervisorData={empSupervisorData} userReportingData={empReportingData} assessmentMangement={assessmentMangement} setProfileYear={this.setProfileYear}/>
              : null
          }

        </Container>
      </div>


    );
  }
}

EmployeeManagment.propTypes = {
  fetchAdminData: PropTypes.func.isRequired,
  getMainGroupsData: PropTypes.func.isRequired,
  fetchSubGroupsOrLocationsOrSOG: PropTypes.func.isRequired,
  getAssesstDeptsHierarchy: PropTypes.func.isRequired,
  getAssesstDivHierarchy: PropTypes.func.isRequired,
  getAssesstSectionHierarchy: PropTypes.func.isRequired,
  getAssesstPCHierarchy: PropTypes.func.isRequired,
  getLocationDivsHierarchy: PropTypes.func.isRequired,
  getLocationSectionHierarchy: PropTypes.func.isRequired,
  getLocationPCHierarchy: PropTypes.func.isRequired,
  fetchLocationBranches: PropTypes.func.isRequired,
  getAssessBranchDivsHierarchy: PropTypes.func.isRequired,
  getAssessBranchSectionHierarchy: PropTypes.func.isRequired,
  getAssessBranchPCHierarchy: PropTypes.func.isRequired,
  fetchEmployeeData: PropTypes.func.isRequired,
  adminFetchEmployeeInformation: PropTypes.func.isRequired,
}

const mapStatesToProps = state => ({
  fetchEmpDataSuccessAdmin: state.webRed.fetchEmpDataSuccessAdmin,
  fetchedAllAssessmentsForAdmin: state.webRed.fetchedAllAssessmentsForAdmin,
  mainGroupsData: state.webRed.mainGroupsData,

})

export default connect(mapStatesToProps, {
  fetchAdminData,
  getMainGroupsData, fetchSubGroupsOrLocationsOrSOG, getAssesstDeptsHierarchy,
  getAssesstDivHierarchy, getAssesstSectionHierarchy, getAssesstPCHierarchy,
  getLocationDivsHierarchy, getLocationSectionHierarchy, getLocationPCHierarchy,
  fetchLocationBranches, getAssessBranchDivsHierarchy, getAssessBranchSectionHierarchy,
  getAssessBranchPCHierarchy, fetchEmployeeData, adminFetchEmployeeInformation
})(EmployeeManagment);