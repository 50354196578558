const branchData2 = {
    name : "CEBU",
    thumbnail : "https://upload.wikimedia.org/wikipedia/en/c/c2/Ph_seal_cebucity.png",
    totalCandidates : 1232,
    totalDivisions : 6,
    totalSections : 22,
    mainAverage : 89,
    mainCompetencies : [{
        name : "CORE",
        totalAverage : 90.2,
        subCompetencies : [{
            name : "Customer Focus",
            average : 92,
            d_total : 90,
            m_total : 246,
            e_total : 58
        },{
            name : "Working with Others",
            average : 93,
            d_total : 76,
            m_total : 157,
            e_total : 160
        },{
            name : "Coping with Pressure and Setbacks",
            average : 82,
            d_total : 146,
            m_total : 147,
            e_total : 92
        },{
            name : "Communicating Effectively",
            average : 93,
            d_total : 73,
            m_total : 219,
            e_total : 101
        },{
            name : "Drive for Excellence",
            average : 91,
            d_total : 92,
            m_total : 203,
            e_total : 98
        }]
    },{
        name : "ORGANIZATIONAL",
        totalAverage : 89.4,
        subCompetencies : [{
            name : "Planning and Organizing",
            average : 92,
            d_total : 71,
            m_total : 184,
            e_total : 49
        },{
            name : "Monitoring and Evaluating",
            average : 91,
            d_total : 58,
            m_total : 120,
            e_total : 49
        },{
            name : "Managing Information",
            average : 93,
            d_total : 63,
            m_total : 144,
            e_total : 109
        },{
            name : "Demonstrating Computer Literacy",
            average : 79,
            d_total : 125,
            m_total : 104,
            e_total : 25
        },{
            name : "Adapting to and Leading Change",
            average : 92,
            d_total : 86,
            m_total : 205,
            e_total : 93
        }]
    },{
        name : "LEADERSHIP",
        totalAverage : 91.6,
        subCompetencies : [{
            name : "Partnering and Networking",
            average : 94,
            d_total : 18,
            m_total : 44,
            e_total : 50
        },{
            name : "Making Decisions and Implementing Actions",
            average : 94,
            d_total : 16,
            m_total : 34,
            e_total : 55
        },{
            name : "Institutionalizing Continuous Improvement",
            average : 94,
            d_total : 17,
            m_total : 31,
            e_total : 58
        },{
            name : "Displaying Business Acumen",
            average : 94,
            d_total : 37,
            m_total : 102,
            e_total : 49
        },{
            name : "Motivating and Developing Others",
            average : 82,
            d_total : 47,
            m_total : 34,
            e_total : 32
        }]
    },{
        name : "TECHNICAL",
        totalAverage : 94.9,
        subCompetencies : [{
            name : "T1 Accounting",
            average : 79,
            d_total : 1,
            m_total : 1,
            e_total : 1
          },{
            name : "T3 Benefits Programs Management",
            average : 100,
            d_total : 1,
            m_total : 1,
            e_total : 1
          },{
            name : "T4 Bingo Operations Management",
            average : 100,
            d_total : 1,
            m_total : 1,
            e_total : 1
          },{
            name : "T5 Budget Planning and Monitoring",
            average : 89,
            d_total : 1,
            m_total : 1,
            e_total : 1
          },{
            name : "T6 Business Development and Research",
            average : 100,
            d_total : 1,
            m_total : 1,
            e_total : 1
          },{
            name : "T7 Casino Operations Table Game Management",
            average : 96,
            d_total : 1,
            m_total : 1,
            e_total : 1
          },{
            name : "T8 Clerical Assistance Skills",
            average : 90,
            d_total : 1,
            m_total : 1,
            e_total : 1
          },{
            name : "T12 Data Collection and Analysis",
            average : 90,
            d_total : 1,
            m_total : 1,
            e_total : 1
          },{
            name : "T14 Engineering Expertise",
            average : 97,
            d_total : 1,
            m_total : 1,
            e_total : 1
          },{
            name : "T15 Entertainment Operations Management",
            average : 100,
            d_total : 1,
            m_total : 1,
            e_total : 1
          },{
            name : "T17 External / Internal Security Operations Management",
            average : 98,
            d_total : 1,
            m_total : 1,
            e_total : 1
          },{
            name : "T18 Facility, Property and/or Equipment Maintenance",
            average : 100,
            d_total : 1,
            m_total : 1,
            e_total : 1
          },{
            name : "T19 Financial Analysis / Reporting and Cash Management",
            average : 83,
            d_total : 1,
            m_total : 1,
            e_total : 1
          },{
            name : "T20 Health Services Delivery and Management",
            average : 100,
            d_total : 1,
            m_total : 1,
            e_total : 1
          },{
            name : "T21 Help Desk",
            average : 100,
            d_total : 1,
            m_total : 1,
            e_total : 1
          },{
            name : "T24 Inventory Management",
            average : 99,
            d_total : 1,
            m_total : 1,
            e_total : 1
          },{
            name : "T25 Investigation Management",
            average : 100,
            d_total : 1,
            m_total : 1,
            e_total : 1
          },{
            name : "T27 Market Analysis and Planning",
            average : 79,
            d_total : 1,
            m_total : 1,
            e_total : 1
          },{
            name : "T28 Occupational Health Safety",
            average : 100,
            d_total : 1,
            m_total : 1,
            e_total : 1
          },{
            name : "T32 Procurement Management",
            average : 100,
            d_total : 1,
            m_total : 1,
            e_total : 1
          },{
            name : "T33 Project Management",
            average : 98,
            d_total : 1,
            m_total : 1,
            e_total : 1
          },{
            name : "T34 Property / Asset Usage and Management",
            average : 100,
            d_total : 1,
            m_total : 1,
            e_total : 1
          },{
            name : "T36 Records Management",
            average : 94,
            d_total : 1,
            m_total : 1,
            e_total : 1
          },{
            name : "T40 Slot Machine Operations Management",
            average : 88,
            d_total : 1,
            m_total : 1,
            e_total : 1
          },{
            name : "T42 Supplier Vendor Management",
            average : 100,
            d_total : 1,
            m_total : 1,
            e_total : 1
          },{
            name : "T43 Surveillance Operations Management",
            average : 100,
            d_total : 1,
            m_total : 1,
            e_total : 1
          },{
            name : "T46 Technical Writing",
            average : 97,
            d_total : 1,
            m_total : 1,
            e_total : 1
          },{
            name : "T50 Transport Operations and Management",
            average : 98,
            d_total : 1,
            m_total : 1,
            e_total : 1
          },{
            name : "T51 Treasury Management",
            average : 92,
            d_total : 1,
            m_total : 1,
            e_total : 1
          },{
            name : "T54 Warehouse Operations",
            average : 75,
            d_total : 1,
            m_total : 1,
            e_total : 1
          },{
            name : "T55 Workforce Planning",
            average : 100,
            d_total : 1,
            m_total : 1,
            e_total : 1
          }]
    }],
    divisions : [{
        name : "ADMINISTRATIVE DIVISION",
        count : 92,
        sections : [{
            name : "ACCOUNTING SECTION",
            count : 6,
            designations : [{
                name : "ASST. ACCOUNTING OFFICER",
                count : 1
            },{
                name : "ACCOUNTING ASSISTANT",
                count : 1
            },{
                name : "SR. ACCOUNTING OFFICER",
                count : 1
            },{
                name : "ACCOUNTING OFFICER II",
                count : 1
            },{
                name : "ACCOUNTING OFFICER I",
                count : 2
            }]
        },{
            name : "FINANCE SECTION",
            count : 2,
            designations : [{
                name : "FINANCE ASSISTANT",
                count : 1
            },{
                name : "FINANCE OFFICER II",
                count : 1
            }]
        },{
            name : "PROVIDENT FUND SECTION",
            count : 2,
            designations : [{
                name : "PROVIDENT FUND ASSISTANT",
                count : 1
            },{
                name : "PROVIDENT FUND OFFICER I",
                count : 1
            }]
        },{
            name : "HEALTH SERVICES SECTION",
            count : 4,
            designations : [{
                name : "SENIOR NURSE",
                count : 1
            },{
                name : "NURSE",
                count : 3
            }]
        },{
            name : "HUMAN RESOURCE SECTION",
            count : 6,
            designations : [{
                name : "ASST. HUMAN RESOURCE OFFICER",
                count : 1
            },{
                name : "HUMAN RESOURCE ASSISTANT",
                count : 1
            },{
                name : "FILING CLERK / MESSENGER II",
                count : 1
            },{
                name : "HUMAN RESOURCE OFFICER II",
                count : 1
            },{
                name : "FILING CLERK / MESSENGER I",
                count : 2
            }]
        },{
            name : "GENERAL ADMINISTRATIVE SECTION",
            count : 5,
            designations : [{
                name : "ADMINISTRATIVE ASSISTANT",
                count : 2
            },{
                name : "COMMS. EQUIPMENT STAFF II",
                count : 1
            },{
                name : "TELEPHONE OPERATOR",
                count : 2
            }]
        },{
            name : "FACILITIES MANAGEMENT SECTION",
            count : 24,
            designations : [{
                name : "MAINTENANCE TECHNICIAN II",
                count : 11
            },{
                name : "FABRICATOR II",
                count : 4
            },{
                name : "MAINTENANCE TECH. I",
                count : 1
            },{
                name : "SANITATION AIDE",
                count : 1
            },{
                name : "UTILITYMAN II",
                count : 1
            },{
                name : "CLERICAL AIDE II",
                count : 1
            },{
                name : "SR. FACILITIES MGMT. ASSISTANT",
                count : 2
            },{
                name : "FACILITIES MGMT. TECH'L. ASST.",
                count : 2
            },{
                name : "SR. FACILITIES MGMT. OFFICER",
                count : 1
            }]
        },{
            name : "BINGO SECTION",
            count : 2,
            designations : [{
                name : "BINGO STAFF II",
                count : 1
            },{
                name : "BINGO OFFICER I",
                count : 1
            }]
        },{
            name : "ENTERTAINMENT SECTION",
            count : 1,
            designations : [{
                name : "SR. TECHNICAL ASSISTANT",
                count : 1
            }]
        },{
            name : "CUSTOMER RELATIONS SECTION",
            count : 14,
            designations : [{
                name : "DRIVER I",
                count : 3
            },{
                name : "DRIVER II",
                count : 4
            },{
                name : "SR. CUSTOMER RELATIONS OFFICER",
                count : 1
            },{
                name : "CUSTOMER RELATIONS ASSISTANT",
                count : 5
            },{
                name : "DRIVER / MECHANIC",
                count : 1
            }]
        },{
            name : "MARKETING SECTION",
            count : 10,
            designations : [{
                name : "ASST. MARKETING OFFICER",
                count : 2
            },{
                name : "MARKETING ASSISTANT",
                count : 2
            },{
                name : "MARKETING STAFF II",
                count : 5
            },{
                name : "SENIOR MARKETING OFFICER",
                count : 1
            }]
        },{
            name : "INFORMATION TECHNOLOGY SECTION",
            count : 6,
            designations : [{
                name : "ASSISTANT IT OFFICER",
                count : 1
            },{
                name : "IT ASSISTANT",
                count : 5
            }]
        },{
            name : "PROCUREMENT SECTION",
            count : 3,
            designations : [{
                name : "ASSISTANT PROCUREMENT OFFICER",
                count : 2
            },{
                name : "PROCUREMENT STAFF II",
                count : 1
            }]
        },{
            name : "LOGISTICS MANAGEMENT SECTION",
            count : 4,
            designations : [{
                name : "LOGISTICS ASSISTANT",
                count : 2
            },{
                name : "LOGISTICS MANAGEMENT OFCR. II",
                count : 1
            },{
                name : "STOCKMAN II",
                count : 1
            }]
        }]
    },{
        name : "TREASURY DIVISION",
        count : 47,
        sections : [{
            name : "TREASURY SECTION",
            count : 46,
            designations : [{
                name : "TREASURY STAFF II",
                count : 1
            },{
                name : "TREASURY STAFF I",
                count : 2
            },{
                name : "TREASURY OFFICER II",
                count : 5
            },{
                name : "TELLER II",
                count : 18
            },{
                name : "TREASURY ASSISTANT",
                count : 7
            },{
                name : "TREASURY OFFICER I",
                count : 9
            },{
                name : "TELLER I",
                count : 4
            }]
        }]
    },{
        name : "SECURITY DIVISION",
        count : 72,
        sections : [{
            name : "INTERNAL SECURITY SECTION",
            count : 62,
            designations : [{
                name : "ASST. SECURITY OFFICER",
                count : 9
            },{
                name : "SECURITY STAFF II",
                count : 36
            },{
                name : "SECURITY STAFF I",
                count : 12
            },{
                name : "INTERNAL SECURITY INVESTIGATOR",
                count : 3
            },{
                name : "SECURITY OFFICER I",
                count : 2
            }]
        },{
            name : "EXTERNAL SECURITY SECTION",
            count : 10,
            designations : [{
                name : "SECURITY GUARD II",
                count : 4
            },{
                name : "LADY GUARD II",
                count : 3
            },{
                name : "EXTERNAL SECURITY SUPERVISOR",
                count : 3
            }]
        }]
    },{
        name : "SURVEILLANCE DIVISION",
        count : 25,
        sections : [{
            name : "SURVEILLANCE SECTION",
            count : 26,
            designations : [{
                name : "SR. SURVEILLANCE OFFICER",
                count : 1
            },{
                name : "CCTV OPERATOR I",
                count : 2
            },{
                name : "CCTV OPERATOR II",
                count : 1
            },{
                name : "CCTV OPERATOR III",
                count : 14
            },{
                name : "CCTV TECHNICIAN",
                count : 3
            },{
                name : "SURVEILLANCE OFFICER I",
                count : 5
            }]
        }]
    },{
        name : "GAMING DIVISION",
        count : 109,
        sections : [{
            name : "GAMING AREA SECTION",
            count : 82,
            designations : [{
                name : "DEALER I",
                count : 3
            },{
                name : "DEALER II",
                count : 7
            },{
                name : "PIT MANAGER",
                count : 7
            },{
                name : "PIT OFFICER II",
                count : 18
            },{
                name : "DEALER IV",
                count : 29
            }]
        },{
            name : "GAMING SUPPORT SECTION",
            count : 26,
            designations : [{
                name : "GAMING STAFF II",
                count : 4
            },{
                name : "GAMING STAFF I",
                count : 11
            },{
                name : "ASST. GAMING OFFICER",
                count : 7
            },{
                name : "GAMING OFFICER I",
                count : 1
            },{
                name : "GAMING ASSISTANT",
                count : 3
            }]
        }]
    },{
        name : "SLOT MACHINE DIVISION",
        count : 45,
        sections : [{
            name : "SLOT MACHINE FLOOR OPNS. GROUP",
            count : 28,
            designations : [{
                name : "SLOT MACHINE STAFF II",
                count : 1
            },{
                name : "SLOT SERVICE ASSISTANT I",
                count : 2
            },{
                name : "SLOT OPERATIONS OFFICER II",
                count : 1
            },{
                name : "SLOT SERVICE ASSISTANT II",
                count : 11
            },{
                name : "SLOT SERVICE ASSISTANT IV",
                count : 1
            },{
                name : "SLOT OPERATIONS OFFICER I",
                count : 12
            }]
        },{
            name : "SLOT MACHINE MAINTENANCE GROUP",
            count : 12,
            designations : [{
                name : "ASST. SLOT TECHNICAL OFFICER",
                count : 1
            },{
                name : "SLOT TECHNICAL OFFICER II",
                count : 1
            },{
                name : "SLOT TECHNICAL OFFICER I",
                count : 2
            },{
                name : "SLOT TECHNICAL ASSISTANT",
                count : 8
            }]
        }]
    }]
}

export default branchData2;