import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import Header from './../Header';
// import Footer from "./../Footer";

import { uploadDataBranch,addEmployeeAssessmentTakenTime } from './../../../actions/webRedActions';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import './../../../public/scss/Assessments.scss';

// import dataDummy from './../dataDummy';
import{ getYear,getEmployeeProfile } from './../../../actions/utility';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus, faLeaf } from '@fortawesome/free-solid-svg-icons';

import {
    Button,
    Container,
    Row,
    Col,
    Collapse,
    Card,
    CardBody
  } from "reactstrap";

import readXlsxFile from 'read-excel-file';

// import { BrowserRouter as Router, Route, Link } from "react-router-dom";

class AssessmentList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            collapseState : [true, true, true, true]
        };
    }

    componentDidMount() {
        
    }

    componentWillReceiveProps(nextProps, thisProps) {
        
    }

  render() {
    
    const { competencies, subComptStatus, type, subOrdinateIdentity,competenciesRpl } = this.props;
    const { collapseState } = this.state;
    var empData = getEmployeeProfile();
    console.log(competencies,":competencies");

    return (
      <div className="AssessmentList" style={{padding: '0 0 5vh 0', background: '#f6fbff'}}>
          {/* <div
      className="d-flex justify-content-center align-items-center text-center mt-5"
      style={{ width: '100vw' }}
    >
      <div className="p-4 bg-white rounded shadow-lg">
        <h2 className="font-weight-bold">Important Notice:</h2>
        <p>
        The system will undergo maintenance from <strong> 06:00 PM to 07:00 PM, September 26, 2024</strong>. Please ensure that your assessment is completed before this time, as you will be automatically logged out.
        </p>
      </div>
    </div> */}
        <Container style={{textAlign: 'left'}}>
            {
                competencies && competencies.length > 0 && competencies.map((comp, key) => 
                    
                    comp.subCompetencies && comp.subCompetencies.length ?

                        <Row className="mainList" key={key} style={{paddingBottom: '1vh'}}>
                            <Col md={{size: 10, offset: 1}}>
                                <Row className="heading">
                                    <Col><h4>{comp.name} COMPETENCIES</h4></Col>
                                    <Col style={{textAlign: 'right'}}>
                                        <Button style={{textAlign: 'left'}} size="sm" color="primary" outline
                                            onClick={(e) => {
                                                var tempCol = collapseState;
                                                tempCol[key] = !tempCol[key];
                                                this.setState({
                                                    collapseState : tempCol
                                                })
                                            }}
                                        >
                                            <FontAwesomeIcon className="expandIcon" icon={!collapseState[key] ? faPlus : faMinus} size="1x"/>
                                            {
                                                !collapseState[key] ? 'Expand' : 'Close'
                                            }
                                        </Button>
                                    </Col>
                                </Row>
                                
                                <Collapse className="subList" isOpen={collapseState[key]}>
                                    {
                                        getYear() == '2020' ? 
                                        comp.subCompetencies && comp.subCompetencies.length > 0 && comp.subCompetencies.map((subComp, key2) => {
                                            var isCompleted = false;

                                            var status = subComptStatus[subComp.sub_comptkeyword];
                                            if(status && status != null && status != undefined) {
                                                var status_key = Object.keys(status);
                                                // console.log(status_key);
                                                var incValue = status[status_key[0]];
                                                var supValue = status[status_key[1]];
                                            }

                                            if(type == "sup") { // if assessment list is for supervisor
                                                if(supValue && supValue != undefined && supValue != 'null' && supValue != 0 && supValue != '0'){
                                                    isCompleted = true;
                                                }
                                            } else {
                                                if(incValue && incValue != undefined && incValue != 'null' && incValue != 0 && incValue != '0'){
                                                    isCompleted = true;
                                                }
                                            }
                                            
                                            return (

                                                <Row key={key2}>
                                                    <Col md={{size: '8'}}>
                                                        <p>
                                                            {subComp.sub_comptname}
                                                        </p>
                                                    </Col>
                                                    <Col md={{size: '4'}} style={{textAlign: 'right'}}>
                                                        <Link
                                                            to={ subComp.assess_form_id ? "assessment/"+subComp.assess_form_id : "#"}
                                                            onClick={(e) => {
                                                                this.props.addEmployeeAssessmentTakenTime(empData.ID_NUMBER,subComp.sub_comptid)
                                                                localStorage.setItem('sub_comptkeyword', subComp.sub_comptkeyword);
                                                                localStorage.setItem('isSelfRating', type == "sup" ? 0 : 1);
                                                                localStorage.setItem('subOrdinateIdentity', type == "sup" ? JSON.stringify(subOrdinateIdentity) : '');
                                                            }}
                                                        >
                                                            <Button size="sm" color={isCompleted ? "secondary" : "primary"}
                                                            outline
                                                            disabled={isCompleted}
                                                            >{
                                                                isCompleted ? 'Completed' : 'Assess'
                                                            }</Button>
                                                        </Link>
                                                    </Col>
                                                </Row>
                                            )}
                                        )
                                    :comp.subCompetencies && comp.subCompetencies.length > 0 && comp.subCompetencies.map((subComp, key2) => {
                                        var isCompleted = false;
                                        var hasData = true;
                                        try {
                                            var keyWord = subComp.sub_comptkeyword.toLowerCase();
                                            var status = subComptStatus[keyWord];
                                            console.log(keyWord,"**",status)
                                            
                                            isCompleted = status[keyWord+'_score'] > 0  || status[keyWord+'_gap'] !=0 ? true : false;
                                            var rpl = competenciesRpl[keyWord];
                                        }
                                        catch(err) {
                                            console.log("error:",err)
                                            // var keyWord = subComp.sub_comptkeyword;
                                            // var status = subComptStatus[keyWord];
                                            // console.log(keyWord,"**",status)
                                            
                                            // isCompleted = status[keyWord+'_score'] > 0 || status[keyWord+'_gap'] !=0 ? true : false;
                                            // var rpl = competenciesRpl[keyWord];
                                            hasData = false;
                                        }
                                        // console.log("this is the status of compt:",isCompleted);
                                        console.log("hasData:",hasData);
                                            if (hasData == true) {
                                            
                                                return (

                                                    <Row key={key2}>
                                                        <Col md={{size: '8'}}>
                                                            <p>
                                                                {subComp.sub_comptname}
                                                            </p>
                                                        </Col>
                                                        <Col md={{size: '4'}} style={{textAlign: 'right'}}>
                                                            <Link
                                                                to={ subComp.assess_form_id ? "assessment/"+subComp.assess_form_id : "#"}
                                                                onClick={(e) => {
                                                                    this.props.addEmployeeAssessmentTakenTime(empData.ID_NUMBER,subComp.sub_comptid)
                                                                    localStorage.setItem('sub_comptkeyword', keyWord);
                                                                    localStorage.setItem('sub_comptrpl', rpl);
                                                                    //localStorage.setItem('isSelfRating', type == "sup" ? 0 : 1);
                                                                    //localStorage.setItem('subOrdinateIdentity', type == "sup" ? JSON.stringify(subOrdinateIdentity) : '');
                                                                }}
                                                            >
                                                                <Button size="sm" color={isCompleted ? "secondary" : "primary"}
                                                                outline
                                                                disabled={isCompleted}
                                                                >{
                                                                    isCompleted ? 'Completed' : 'Assess'
                                                                }</Button>
                                                            </Link>
                                                        </Col>
                                                    </Row>
                                                )
                                            }
                                            else {
                                                return (
                                                    <Row key={key2}>
                                                        <Col md={{size: '8'}}>
                                                            <label>
                                                                {"No assessment found."}
                                                            </label>
                                                        </Col>
                                                    </Row>
                                                )
                                            }
                                                
                                        })
                                    }
                                </Collapse>
                            </Col>
                        </Row>
                    : null
                    // <Row className="mainList" key={key} style={{paddingBottom: '1vh'}}>
                    //     <Col md={{size: 10, offset: 1}}>
                    //         <Row className="heading">
                    //             <Col><h4>{comp.name} COMPETENCY</h4></Col>
                    //             <Col style={{textAlign: 'right'}}>
                    //                 <Button style={{textAlign: 'left'}} size="sm" color="primary" outline
                    //                     onClick={(e) => {
                    //                         var tempCol = collapseState;
                    //                         tempCol[key] = !tempCol[key];
                    //                         this.setState({
                    //                             collapseState : tempCol
                    //                         })
                    //                     }}
                    //                 >
                    //                     <FontAwesomeIcon className="expandIcon" icon={!collapseState[key] ? faPlus : faMinus} size="1x"/>
                    //                     {
                    //                         !collapseState[key] ? 'Expand' : 'Close'
                    //                     }
                    //                 </Button>
                    //             </Col>
                    //         </Row>
                    //     </Col>
                    // </Row>
                )
            }

        </Container>

      </div>
    );
  }
}

AssessmentList.propTypes = {
    // uploadDataBranch: PropTypes.func.isRequired
}

const mapStatesToProps = state => ({
    // triggerNextPinSuccess : state.webRed.triggerNextPinSuccess,
    // triggeredLoginAction : state.webRed.triggeredLoginAction
})

export default connect( mapStatesToProps, { addEmployeeAssessmentTakenTime })( AssessmentList );