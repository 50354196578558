import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import classnames from 'classnames';
// import Header from './../Header';
// import Footer from "./../Footer";

import { updateProfileData, assignNewAdmin,
    assignNewSubordinate, adminFetchEmployeeInformation, 
    removeSubordinate,validateEmpEmail,addEmp,
    getAllAssessmentsForAdmin,getAdditionalData,
    getMainGroupsData,fetchSubGroupsOrLocationsOrSOG,
    fetchSubGroupsDepartments,fetchLocationBranches,
    fetchBrancheSatellite
} from './../../../../actions/webRedActions';

import { autoFocusNextField, getBaseUrl,validateEmail,accessToken } from './../../../../actions/utility';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle, faCheck } from '@fortawesome/free-solid-svg-icons';

import axios from 'axios';
import './../../../../public/scss/ProfileComponentEmployee.scss';
// import userData from './../userData';
import $ from 'jquery';

import AssessmentManagement from './AssessmentManagement';

import {
    Button,
    Container,
    Row,
    Col,
    Table,
    Form,
    FormGroup,
    Label,
    Input,
    NavLink,
    Nav,
    NavItem,
    TabPane,
    TabContent,
    Alert,
    Spinner
  } from "reactstrap";

import readXlsxFile from 'read-excel-file';
import { getAdminUserData,getYear } from "../../../../actions/utility";
import userData from "../../userData";
import ScoreCard from "../../assessment/ScoreCard";
import ScoreCardsList from "../../assessment/ScoreCardsList";

// import { BrowserRouter as Router, Route, Link } from "react-router-dom";

class AddEmployeeProfile extends Component {

    constructor(props) {
        super(props);
        this.state = {
            activeTab : 'p',
            userDataMain : this.props.userData,
            userData : {
                FIRST_NAME : '',
                MI : '',
                LAST_NAME : '',
                ID_NUMBER : '',
                email : '',
                DESIGNATION : '',
                branch_id : '',
                // DEPT : '',
                // DIVISION : '',
                // SECTION : '',
                // PC : '',
                main_groupid:'',
                sub_groupid:'',
                location_id:'',
                satellite_id:'',
                dept_id:''
            },
            userSupervisorData : {},
            userReportingData : {},
            assessmentMangement : {},
            highlights : {},

            designationList : [],
            divisionList    : [],
            sectionList     : [],
            branchList      : [],
            deptList        : [],
            pcList          : [],
            
            employeeId : ["", "", "-", "", "", "", ""],
            employeeIdString : "",
            employeeIdError : "",
            isLoading: false,

            
            employeeIdSub : ["", "", "-", "", "", "", ""],
            employeeIdStringSub : "",
            employeeIdErrorSub : "",
            isLoadingSub: false,
            isAddComplete:false,
            isAddBtnLoading : false,
            mainGroupsDataArr : [],
            subGroupDataArr : [],
            departmentsArr : [],
            locationsArr : [],
            branchesArr : [],
            satelliteArr : [],
            mainGroupsDataLoading:true,
            mainGroup:'',
            subGroup:'',
            department:'',
            year : '',
            location : '',
            branch : '',
            satellite : '',
            ddLoader2:false,
            ddLoader3:false,
            groupRelateDataType:''
            
        };
    }

    toggleTab = (tab) => {
        if(this.state.activeTab !== tab) {
            this.setState({
                activeTab : tab
            })
        }
    }

    selectUpdate = (col, value) => {

        const {userData, highlights} = this.state;
        this.props.updateProfileData(userData.ID_NUMBER, col, value); //calls action for updating profile data

        this.setState({
            highlights : {
                NAME : col == 'NAME' ? false : highlights.NAME,
                FIRST_NAME : col == 'FIRST_NAME' ? false : highlights.FIRST_NAME,
                MI : col == 'MI' ? false : highlights.MI,
                LAST_NAME : col == 'LAST_NAME' ? false : highlights.LAST_NAME,
                ID_NUMBER : col == 'ID_NUMBER' ? false : highlights.ID_NUMBER,
                email : col == 'email' ? false : highlights.email,
                DESIGNATION : col == 'DESIGNATION' ? false : highlights.DESIGNATION,
                BRANCH : col == 'BRANCH' ? false : highlights.BRANCH,
                DEPT : col == 'DEPT' ? false : highlights.DEPT,
                DIVISION : col == 'DIVISION' ? false : highlights.DIVISION,
                SECTION : col == 'SECTION' ? false : highlights.SECTION,
                PC : col == 'JG' ? false : highlights.PC
            }
        })

    }

    updateValue = (e) => {
        
        var name = e.target.name;
        console.log("This is the target:",e.target.value);

        if($('#'+name).siblings('.invalid-feedback').hasClass('d-block')) {
            $('#'+name).siblings('.invalid-feedback').removeClass('d-block')
        }

        if(name == 'email' && !validateEmail(e.target.value)) {
            $('#'+name).siblings('.invalid-feedback').text('Invalid email ').addClass('d-block')
        }
        var value = e.target.value;
        
        const {userData} = this.state;

        userData[name] = value
        this.setState({
            userData
        },()=>{
            console.log('After set USERData:',this.state.userData);
        })
    }

    componentDidMount() {
        
        this.setState({
            userSupervisorData :{},
            userReportingData:{}
        });

        autoFocusNextField("boxFieldForm"); // auto focus utility method called
        this.props.getMainGroupsData('',(res)=>{
            console.log('This is the main group data:',res);
        });//get all main groups data
        this.fetchAllDistinctValues();

    }

    fetchAllDistinctValues = () => {
        console.log('in method fetchAllDistinctValues:',getYear());
        if(getYear() > '2020') {
            this.props.getAdditionalData();
        }
        else {
            //fetch all distinct values
            this.getAllDistinctData('DESIGNATION');
            this.getAllDistinctData('BRANCH');
            this.getAllDistinctData('DEPT');
            this.getAllDistinctData('DIVISION');
            this.getAllDistinctData('SECTION');
            this.getAllDistinctData('JG');
        }
    }

    componentWillReceiveProps(nextProps, thisProps) {
        console.log("User Data:",nextProps.additionalData);

        if(this.props.userData != nextProps.userData) {
            this.setState({
                userDataMain : nextProps.userData,
                userData : nextProps.userData,
                highlights : {}
            }, () => {
                
                this.fetchAllDistinctValues();
                
            })
        }

        if(this.props.userSupervisorData  != nextProps.fetchEmpDataSuccessAdmin) {
            
            

            if(typeof nextProps.fetchEmpDataSuccessAdmin.data ==='object' && typeof nextProps.fetchEmpDataSuccessAdmin.data.result !=='undefined') {
                this.setState({
                    userSupervisorData : nextProps.fetchEmpDataSuccessAdmin.data.result.supervision
                })
            }
            if(typeof nextProps.fetchEmpDataSuccessAdmin.result !=='undefined') {
                this.setState({
                    userSupervisorData : nextProps.fetchEmpDataSuccessAdmin.result.supervision
                })
            }
        }

        if(this.props.userReportingData != nextProps.fetchEmpDataSuccessAdmin) {
            if(typeof nextProps.fetchEmpDataSuccessAdmin.data ==='object' && typeof nextProps.fetchEmpDataSuccessAdmin.data.result !=='undefined') {
                this.setState({
                    userReportingData : nextProps.fetchEmpDataSuccessAdmin.data.result.sub_ords
                })
            }
            if(typeof nextProps.fetchEmpDataSuccessAdmin.result !=='undefined') {
                this.setState({
                    userReportingData : nextProps.fetchEmpDataSuccessAdmin.result.sub_ords
                })
            }
        }

        if(this.props.assessmentMangement != nextProps.assessmentMangement) {
            this.setState({
                assessmentMangement : nextProps.assessmentMangement
            })
        }
        if(this.props.fetchedAllAssessmentsForAdmin != nextProps.fetchedAllAssessmentsForAdmin) {
            if(nextProps.fetchedAllAssessmentsForAdmin.result) {
              this.setState({
                assessmentMangement : nextProps.fetchedAllAssessmentsForAdmin.result,
                isAddComplete:true,
                isAddBtnLoading:false,
                userReportingData: [],
                userSupervisorData:[]
              }, () => {
                console.log(this.state.assessmentMangement)
              })
            }
        }
        if(this.props.additionalData != nextProps.additionalData) {
            this.setState({
                designationList : nextProps.additionalData.data.designation,
                divisionList    : nextProps.additionalData.data.divisions,
                sectionList     : nextProps.additionalData.data.sections,
                branchList      : nextProps.additionalData.data.branches,
                deptList        : nextProps.additionalData.data.departments,
                pcList          : nextProps.additionalData.data.payClass
            })
        }

        // when the supervisor is successfully assigned
        if(this.props.assignedSupDataSuccessAdmin != nextProps.assignedSupDataSuccessAdmin) {
            
            console.log('this is the my response:',nextProps.assignedSupDataSuccessAdmin);
            if(typeof nextProps.assignedSupDataSuccessAdmin.result.status !=='undefined' && nextProps.assignedSupDataSuccessAdmin.result.status == 'error') {
                this.setState({
                    employeeId : ["", "", "-", "", "", "", ""],
                    employeeIdString : "",
                    employeeIdError : "",
                    isLoading: false
                }, () => {
                    alert(nextProps.assignedSupDataSuccessAdmin.result.message);
                })
            }
            else {
                this.setState({
                    employeeId : ["", "", "-", "", "", "", ""],
                    employeeIdString : "",
                    employeeIdError : "",
                    isLoading: false
                }, () => {
                    this.props.adminFetchEmployeeInformation(this.state.userData.ID_NUMBER);
                })
            }
        }

        // when the subordinate is successfully assigned
        if(this.props.assignedSubordinateDataSuccessAdmin != nextProps.assignedSubordinateDataSuccessAdmin) {
            
            console.log('this is the my response:',nextProps.assignedSubordinateDataSuccessAdmin.result);
            if(typeof nextProps.assignedSubordinateDataSuccessAdmin.result.status !== 'undefined' && nextProps.assignedSubordinateDataSuccessAdmin.result.status == 'error') {
                this.setState({
                    employeeIdSub : ["", "", "-", "", "", "", ""],
                    employeeIdStringSub : "",
                    employeeIdErrorSub : "",
                    isLoadingSub: false
                }, () => {
                    alert(nextProps.assignedSubordinateDataSuccessAdmin.result.message);
                })
            }
            else {
                this.setState({
                    employeeIdSub : ["", "", "-", "", "", "", ""],
                    employeeIdStringSub : "",
                    employeeIdErrorSub : "",
                    isLoadingSub: false
                }, () => {
                    this.props.adminFetchEmployeeInformation(this.state.userData.ID_NUMBER);
                })
            }
        }

        // when the subordinate is successfully removed
        if(this.props.removedSubordinateDataSuccessAdmin != nextProps.removedSubordinateDataSuccessAdmin) {
            this.setState({
                employeeIdSub : ["", "", "-", "", "", "", ""],
                employeeIdStringSub : "",
                employeeIdErrorSub : "",
                isLoadingSub: false
            }, () => {
                this.props.adminFetchEmployeeInformation(this.state.userData.ID_NUMBER);
            })
        }
        //main group data
        if(this.props.mainGroupsData != nextProps.mainGroupsData) {
            this.setState({mainGroupsDataArr : nextProps.mainGroupsData.data,mainGroupsDataLoading:false},()=>{
                console.log('set state works here');
            })
        }
        

    }
    
    fetchSubGroupDepartments = (event, index) => {
        event.preventDefault();
        const { name, value } = event.target;
        if(value !="select") {
            this.updateValue(event);
            console.log(name,':name:',value)
            let that = this;
            this.setState({ddLoader2:true,subGroup:value});
            this.props.fetchSubGroupsDepartments(value,(data)=>{
                if(data !=0) 
                    that.setState({departmentsArr:data.result,ddLoader2:false})
                else
                    alert('Data not found.');
            })
        }
    }

    fetchRelatedData = (event, index) => {
        
        event.preventDefault();
        
        const {userData} = this.state;

        const { name, value } = event.target;
        if(value !="select") {
            this.updateValue(event);
            userData[name] = value;
            this.setState({mainGroupsDataLoading : true, mainGroup : value});
            let that = this;
            this.props.fetchSubGroupsOrLocationsOrSOG(value,(data)=>{
                console.log('i found the data:',data);
                if(data !=0) {
                    if(data.type == 'sub_groups')
                        that.setState({groupRelateDataType:data.type,mainGroupsDataLoading : false,subGroupDataArr : data.result,relatedDataType : data.type,branchesArr:[],locationsArr:[],satelliteArr:[]});
                    else
                        that.setState({groupRelateDataType:data.type,mainGroupsDataLoading : false,locationsArr : data.result,relatedDataType : data.type,departmentsArr:[]});
                }
                else
                    alert('Data not found.');
            });
        }

        console.log(name,':change event data:',value)
        
    }
    fetchSections = (event,index) => {
        const { name, value } = event.target;
        
        if(value !='select') {
            this.updateValue(event);
            const {userData} = this.state;
            userData[name] = value;
            this.setState({department : value});
            console.log('fetch section:',value)
        }
    }
    fetchLocationBranches = (event, index) => {
        const { name, value } = event.target;
        
        if(value !='select') {
            this.updateValue(event);
            const {userData} = this.state;
            userData[name] = value;
            this.setState({ddLoader3:true,location:value})
            let that = this;
            console.log(name,':name:',value)
            this.props.fetchLocationBranches(value,(data) => {
                console.log('here is the branches data:',data);
                if(data !=0)
                    that.setState({ddLoader3:false,branchesArr:data.result,satelliteArr:[],showUpdateBtn1 : data.result.length > 0 ? false : true},()=>{
                        console.log('this is btton:',that.state.showUpdateBtn1)
                    })
                else
                    alert('Data not found.');

            }) 
        }
    }
    fetchBrachSatellite = (event, index) => {
        const { name, value } = event.target;
        if(value !='select') {
            this.updateValue(event);
            const {userData} = this.state;
            userData[name] = value;
            this.setState({ddLoader4:true,branch:value});
            let that = this;
            this.props.fetchBrancheSatellite(value,(data)=>{
                if(data !=0)
                    that.setState({ddLoader4:false,satelliteArr:data.result.length > 0 ? data.result : [], 
                        showUpdateBtn : data.result.length > 0 ? false : true})
                else
                    alert('Data not found.');

            });
            console.log('here is the value:',value);
        }
    }
    satelliteChange = (event,index) => {
        const { name, value } = event.target;
        if(value !='select') {
            this.updateValue(event);
            const {userData} = this.state;
            userData[name] = value;
            this.setState({satellite:value});
        }
    }
    handleChange = (event, index) => {
        event.preventDefault();
        const { name, value } = event.target;

        var employeeIdTemp = this.state.employeeId;
        employeeIdTemp[index] = value;

        this.setState({
            [name] : employeeIdTemp
        }, () => {
            // console.log(this.state.employeeId);

            var emp_id = this.convertEmpIdToString(this.state.employeeId);
            this.setState({
                employeeIdString : emp_id
            }, () => {
                // it means all inputs are done here we need to call action to verify
                
                if(emp_id.length == 7) {
                }
                // console.log(this.state.employeeIdString)
            })

        })

        if(this.state.employeeIdError != "") {
            this.setState({
                employeeIdError : ""
            })
        }
    }

    // handleSubmit = (event) => {
    //     event.preventDefault();
        
    // }

    convertEmpIdToString = (empId) => {
        var stringEmpId = "";
        empId.map(val => {
            if(val && val != "" && val != undefined){
                stringEmpId += val;
            }
        })

        return stringEmpId;
    }


    // change handle for subordinates only
    handleChangeSub = (event, index) => {
        event.preventDefault();
        const { name, value } = event.target;

        var employeeIdTemp = this.state.employeeIdSub;
        employeeIdTemp[index] = value;

        this.setState({
            [name] : employeeIdTemp
        }, () => {
            // console.log(this.state.employeeId);

            var emp_id = this.convertEmpIdToStringSub(this.state.employeeIdSub);
            this.setState({
                employeeIdStringSub : emp_id
            }, () => {
                // it means all inputs are done here we need to call action to verify
                
                if(emp_id.length == 7) {
                }
                // console.log(this.state.employeeIdString)
            })

        })

        if(this.state.employeeIdErrorSub != "") {
            this.setState({
                employeeIdErrorSub : ""
            })
        }
    }

    convertEmpIdToStringSub = (empId) => {
        var stringEmpId = "";
        empId.map(val => {
            if(val && val != "" && val != undefined){
                stringEmpId += val;
            }
        })

        return stringEmpId;
    }

    
    // fetch all distinct values for any column
    getAllDistinctData = (col) => {

        var bodyFormData = new FormData();
        bodyFormData.set('col', col);
        bodyFormData.set('acess_token', accessToken());
        bodyFormData.set('year', getYear());

        axios.post( getBaseUrl() + 'api/admin/employee/column/data', bodyFormData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
        .then((res) => {
            console.log(res);
            if(res.data.result) {

                if(col == 'DESIGNATION') {
                    this.setState({
                        designationList : res.data.result
                    }, () => {
                        console.log(this.state.designationList)
                    })
                }
                else if(col == 'DIVISION') {
                    this.setState({
                        divisionList : res.data.result
                    }, () => {
                        console.log(this.state.divisionList)
                    })
                }
                else if(col == 'SECTION') {
                    this.setState({
                        sectionList : res.data.result
                    }, () => {
                        console.log(this.state.sectionList)
                    })
                }
                else if(col == 'BRANCH') {
                    this.setState({
                        branchList : res.data.result
                    }, () => {
                        console.log(this.state.branchList)
                    })
                }
                else if(col == 'DEPT') {
                    this.setState({
                        deptList : res.data.result
                    }, () => {
                        console.log(this.state.deptList)
                    })
                }
                else if(col == 'JG') {
                    this.setState({
                        pcList : res.data.result
                    }, () => {
                        console.log(this.state.pcList)
                    })
                }
                

            }
        })
        .catch((error) => {
            console.log(error);
        });
    }

  render() {
    
    const adminData = getAdminUserData(); // user which is logged in usually ad admin user here.
    // const { userData } = this.props; // empData to review and update the details is required by admin.
    const { userData,
    activeTab, 
    userSupervisorData, 
    userReportingData, 
    employeeId, 
    employeeIdError, 
    employeeIdString, 
    isLoading, 
    employeeIdSub, 
    employeeIdErrorSub, 
    employeeIdStringSub, 
    isLoadingSub,
    isAddBtnLoading,
    isAddComplete,
    mainGroupsDataArr
} = this.state;
    
    console.log('adminData:',adminData)
    
    console.log('userData:',userData)

    var handleEditSubOrds =  adminData.admin.can_editsubords == 0 ? 'disabled' : '';

    var handleRPL =  adminData.admin.can_editrpl == 0 ? 'disabled' : '';

    var handleEditSup =  adminData.admin.can_editsup == 0 ? 'disabled' : '';
    
    // console.log('handleEditEmp:',adminData);

    return (
      <div className="ProfileComponentEmployee" >
       

        <div>

            <Container fluid>

                <Nav tabs>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: activeTab === 'p' })}
                            onClick={() => { this.toggleTab('p'); }} // profile
                        >
                            {"Add employee"}
                        </NavLink>
                    </NavItem>
                    
                    <NavItem>
                        <NavLink
                            disabled={this.state.isAddComplete == false ? true : false}
                            className={classnames({ active: activeTab === 'am' })}
                            onClick={() => { this.toggleTab('am'); }} // assessment management
                        >
                            Assessment Management
                        </NavLink>
                    </NavItem>
                   
                </Nav>
                <TabContent activeTab={activeTab}>
                    <TabPane tabId="p">

                        <Container>
                            <Form>
                                <Row style={{padding: '6vh 3vw', textAlign: 'left'}}>

                                    {/* <Col md={{size : 12}} style={{paddingBottom: '30px'}}>
                                        <Alert color="primary" className="alertMessage">
                                            Please review the profile of {userData.NAME} and click on the respective field to update information. Click Update &nbsp;<FontAwesomeIcon icon={faCheck} size="1x" />&nbsp;&nbsp; to save changes for each field.
                                        </Alert>
                                    </Col> */}

                                    <Col md={{size: 4}}>
                                        <FormGroup >
                                            
                                            <Label for="FIRST_NAME">First name</Label>
                                            <Input  type="text" name="FIRST_NAME" id="FIRST_NAME" placeholder="Enter employee first name" value={userData.FIRST_NAME} onChange={(e) => this.updateValue(e)} />
                                            <div className="invalid-feedback">
                                                Please provide a valid first name.
                                            </div>
                                        </FormGroup>
                                    </Col>
                                    <Col md={{size: 4}}>
                                        <FormGroup >
                                            <Label for="MI">MI</Label>
                                            <Input  type="text" name="MI" id="MI" placeholder="Enter employee mi" value={userData.MI}  onChange={(e) => this.updateValue(e)}/>
                                            <div className="invalid-feedback">
                                                Please provide a valid mi.
                                            </div>
                                        </FormGroup>
                                    </Col>
                                    <Col md={{size: 4}}>
                                        <FormGroup>
                                            <Label for="LAST_NAME">Last name</Label>
                                            <Input type="text" name="LAST_NAME" id="LAST_NAME" placeholder="Enter employee last name" value={userData.LAST_NAME}  onChange={(e) => this.updateValue(e)}/>
                                            <div className="invalid-feedback">
                                                Please provide a valid last name.
                                            </div>
                                        </FormGroup>
                                    </Col>
                                    <Col md={{size: 12}}>
                                        <FormGroup>
                                            <Label for="ID_NUMBER">Employee ID</Label>
                                            <Input type="text" name="ID_NUMBER" id="ID_NUMBER" placeholder="Enter employee id" value={typeof this.props.validEmpId !=='undefined' ? this.props.validEmpId : ''} disabled />
                                        </FormGroup>
                                    </Col>
                                    
                                    <Col md={{size: 12}}>
                                        <FormGroup>
                                            <Label for="email">Email id</Label>
                                            <Input type="text" name="email" id="email" placeholder="Enter email id" value={userData.email} onChange={(e) => this.updateValue(e)} />
                                            <div className="invalid-feedback">
                                                Please provide a valid email.
                                            </div>
                                        </FormGroup>
                                    </Col>                        
                                    <Col md={{size: 12}}>
                                        <FormGroup>
                                            <Label for="Main Groups">Main Groups</Label>
                                            
                                            <Input  type="select" name="main_groupid" id="main_groupid" onChange={(e) => this.fetchRelatedData(e)}>
                                                <option value="select" key={0}>Select</option>
                                                    {

                                                        mainGroupsDataArr.length > 0 && mainGroupsDataArr.map((data, key) => 
                                                        
                                                            <option
                                                                key={key}
                                                                value={data.group_id}
                                                                
                                                            >
                                                                {data.group_name}
                                                            </option>
                                                        )

                                                    }
                                            </Input>
                                            <div className="invalid-feedback">
                                                Please provide a valid first main group.
                                            </div>
                                            {
                                                this.state.mainGroupsDataLoading == true ? 
                                                    <div style={{float:'right', marginTop:'-70px'}}><Spinner color="primary"  size='small' /></div>
                                                :null
                                            }
                                        </FormGroup>
                                    </Col>
                                    {
                                        this.state.subGroupDataArr.length > 0 && this.state.relatedDataType == "sub_groups" ?
                                        <Col md={{size: 12}}>
                                            <FormGroup>
                                                <Label for="SubGroups">Sub Groups</Label>
                                                
                                                <Input  type="select" name="sub_groupid" id="sub_groupid" onChange={(e) => this.fetchSubGroupDepartments(e)}>
                                                    <option value="select" key={0}>Select</option>
                                                    {
                                                        this.state.subGroupDataArr.map((data, key) => 
                                                            <option
                                                                key={key}
                                                                value={data.sub_groupid}
                                                            >
                                                            {data.sub_groupname}
                                                            </option>
                                                        )

                                                    }
                                                </Input>
                                                <div className="invalid-feedback">
                                                    Please provide a valid sub group.
                                                </div>
                                                {
                                                    this.state.ddLoader2 == true ? 
                                                        <div style={{float:'right', marginTop:'-70px'}}><Spinner color="primary"  size='small' /></div>
                                                    :null
                                                }
                                            </FormGroup>
                                        </Col>
                                        :null
                                    }
                                    {
                                        this.state.departmentsArr.length > 0  ?
                                        <Col md={{size: 12}}>
                                            <FormGroup>
                                                <Label for="SubGroups">Departments</Label>
                                                
                                                <Input type="select" name="dept_id" id="dept_id" onChange={(e) => this.fetchSections(e)}>
                                                    <option value="select" key={0}>Select</option>
                                                    {
                                                        this.state.departmentsArr.map((data, key) => 
                                                            <option
                                                                key={key}
                                                                value={data.dept_id}
                                                            >
                                                            {data.dept_name}
                                                            </option>
                                                        )

                                                    }
                                                </Input>
                                                <div className="invalid-feedback">
                                                    Please provide a valid departments.
                                                </div>
                                            </FormGroup>
                                            
                                        </Col>
                                        :null
                                    }
                                    {
                                        this.state.locationsArr.length > 0 && this.state.relatedDataType == "locations" ?     
                                        <Col md={{size: 12}}>
                                            <FormGroup>
                                                <Label for="BRANCH">Locations</Label>
                                                {/* <Input type="text" name="BRANCH" id="BRANCH" placeholder="" value={userData.BRANCH} onChange={(e) => this.updateValue(e)} />  */}
                                                    <Input  type="select" name="location_id" id="location_id" onChange={(e) => this.fetchLocationBranches(e)}>
                                                        <option value="select" key={0}>Select</option>
                                                        {

                                                            this.state.locationsArr.map((data, key) => 
                                                                <option
                                                                    key={key}
                                                                    value={data.location_id}
                                                                
                                                                >
                                                                    {data.location_name}
                                                                </option>
                                                            )

                                                        }
                                                    </Input>
                                                    <div className="invalid-feedback">
                                                        Please provide a valid locations.
                                                    </div>
                                                    {
                                                    this.state.ddLoader3 == true ? 
                                                        <div style={{float:'right', marginTop:'-70px'}}><Spinner color="primary"  size='small' /></div>
                                                    :null
                                                }
                                            </FormGroup>
                                            
                                        </Col>
                                        :null
                                    }
                                    {
                                        this.state.branchesArr.length > 0 ?     
                                            <Col md={{size: 12}}>
                                                <FormGroup>
                                                    <Label for="DEPT">Branches</Label>
                                                    {/*<Input type="text" name="DEPT" id="DEPT" placeholder="" value={userData.DEPT} onChange={(e) => this.updateValue(e)} /> */}
                                                    
                                                        <Input  type="select" name="branch_id" id="branch_id" onChange={(e) => this.fetchBrachSatellite(e)}>
                                                            <option value="select" key={0}>Select</option>
                                                            {

                                                                this.state.branchesArr.map((data, key) => 
                                                                    <option
                                                                        key={key}
                                                                        value={data.branch_id}
                                                                    >
                                                                        {data.branch_name}
                                                                    </option>
                                                                )

                                                            }
                                                        </Input>
                                                        <div className="invalid-feedback">
                                                            Please provide a valid branch.
                                                        </div>
                                                        {
                                                            this.state.ddLoader4 == true ? 
                                                                <div style={{float:'right', marginTop:'-70px'}}><Spinner color="primary"  size='small' /></div>
                                                            :null
                                                        }
                                                        
                                                </FormGroup>
                                                
                                            </Col>
                                            :null
                                    }
                                    {
                                        this.state.satelliteArr.length > 0 ?     
                                            <Col md={{size: 12}}>
                                                <FormGroup>
                                                    <Label for="satellite">Satellite</Label>
                                                    {/*<Input type="text" name="DEPT" id="DEPT" placeholder="" value={userData.DEPT} onChange={(e) => this.updateValue(e)} /> */}
                                                    
                                                        <Input  type="select" name="satellite_id" id="satellite_id" onChange={(e) => this.satelliteChange(e)}>
                                                            <option value="select" key={0}>Select</option>
                                                            {

                                                                this.state.satelliteArr.map((data, key) => 
                                                                    <option
                                                                        key={key}
                                                                        value={data.satellite_id}
                                                                    >
                                                                        {data.satellite_name}
                                                                    </option>
                                                                )

                                                            }
                                                        </Input>
                                                        <div className="invalid-feedback">
                                                            Please provide a valid satellite name.
                                                        </div>
                                                </FormGroup>
                                                
                                            </Col>
                                            :null
                                        }
                                    <Col md={{size: 12}}>
                                        <FormGroup>
                                            
                                            <Label for="DESIGNATION">Designation</Label>
                                            {/* <Input type="text" name="DESIGNATION" id="DESIGNATION" placeholder="" value={userData.DESIGNATION} onChange={(e) => this.updateValue(e)} /> */}

                                            { getYear() > '2020' ? 
                                            <Input type="select" name="DESIGNATION" id="DESIGNATION" onChange={(e) => this.updateValue(e)}>
                                                <option value="select" key={0} selected={userData.DESIGNATION =="" ? "selected" : ''} >Select</option>
                                                {
                                                    this.state.designationList.map((data, key) => 
                                                        userData.DESIGNATION == ""? 
                                                        <option
                                                            key={key}
                                                            value={data.desig_id}
                                                            
                                                        >
                                                            
                                                            {data.desig_name}
                                                        </option>
                                                        :
                                                        <option
                                                            key={key}
                                                            value={data.desig_id}
                                                            selected={data.desig_name == userData.DESIGNATION}
                                                            
                                                        >
                                                            
                                                            {data.desig_name}
                                                        </option>
                                                    )

                                                }
                                            </Input> :
                                            <Input type="select" name="DESIGNATION" id="DESIGNATION" onChange={(e) => this.updateValue(e)}>
                                                <option val="select" key={0} selected={userData.DESIGNATION =="" ? "selected" : ''} >Select</option>
                                                {
                                                    this.state.designationList.map((data, key) => 
                                                        userData.DESIGNATION == ""? 
                                                        <option
                                                            key={key}
                                                            val={data.DESIGNATION}
                                                            
                                                        >
                                                            
                                                            {data.DESIGNATION}
                                                        </option>
                                                        :
                                                        <option
                                                            key={key}
                                                            val={data.DESIGNATION}
                                                            selected={data.DESIGNATION == userData.DESIGNATION}
                                                            
                                                        >
                                                            
                                                            {data.DESIGNATION}
                                                        </option>
                                                    )

                                                }
                                            </Input>
                                            }
                                            <div className="invalid-feedback">
                                                Please provide a valid designation.
                                            </div>
                                        </FormGroup>
                                    </Col>
                                    {/*
                                    <Col md={{size: 12}}>
                                        <FormGroup>
                                            
                                            <Label for="BRANCH">Branch</Label>
                                            {/* <Input type="text" name="BRANCH" id="BRANCH" placeholder="" value={userData.BRANCH} onChange={(e) => this.updateValue(e)} /> */}
                                            {/*
                                                getYear() > '2020' ? 
                                                <Input  type="select" name="BRANCH" id="BRANCH" onChange={(e) => this.updateValue(e)}>
                                                <option value="select" key={0} selected={userData.BRANCH =="" ? "selected" : ''} >Select</option>
                                                    {

                                                        this.state.branchList.map((data, key) => 
                                                            userData.BRANCH !="" ?
                                                            <option
                                                                key={key}
                                                                value={data.branch_id}
                                                                selected={data.branch_name == userData.BRANCH}
                                                            >
                                                                {data.branch_name}
                                                            </option>
                                                            :
                                                            <option
                                                                key={key}
                                                                value={data.branch_id}
                                                                
                                                            >
                                                                {data.branch_name}
                                                            </option>
                                                        )

                                                    }
                                                </Input>
                                                :
                                            
                                                <Input  type="select" name="BRANCH" id="BRANCH" onChange={(e) => this.updateValue(e)}>
                                                <option val="select" key={0} selected={userData.BRANCH =="" ? "selected" : ''} >Select</option>
                                                    {

                                                        this.state.branchList.map((data, key) => 
                                                            userData.BRANCH !="" ?
                                                            <option
                                                                key={key}
                                                                val={data.BRANCH}
                                                                selected={data.BRANCH == userData.BRANCH}
                                                            >
                                                                {data.BRANCH}
                                                            </option>
                                                            :
                                                            <option
                                                                key={key}
                                                                val={data.BRANCH}
                                                                
                                                            >
                                                                {data.BRANCH}
                                                            </option>
                                                        )

                                                    }
                                                </Input>
                                            }
                                            <div className="invalid-feedback">
                                                Please provide a valid branch.
                                            </div>
                                        </FormGroup>
                                        </Col>*/}
                                    {/*
                                    <Col md={{size: 12}}>
                                        <FormGroup>
                                            
                                            <Label for="DEPT">Department</Label>
                                            {/* <Input type="text" name="DEPT" id="DEPT" placeholder="" value={userData.DEPT} onChange={(e) => this.updateValue(e)} /> */}

                                            {/*
                                                getYear() > '2020' ? 
                                                <Input  type="select" name="DEPT" id="DEPT" onChange={(e) => this.updateValue(e)}>
                                                <option value="select" key={0} selected={userData.DEPT =="" ? "selected" : ''} >Select</option>
                                                    {

                                                        this.state.deptList.map((data, key) => 
                                                        userData.DEPT !="" ?
                                                            <option
                                                                key={key}
                                                                value={data.dept_id}
                                                                selected={data.dept_name == userData.DEPT}
                                                            >
                                                                {data.dept_name}
                                                            </option>
                                                            :
                                                            <option
                                                                key={key}
                                                                value={data.dept_id}
                                                                
                                                            >
                                                                {data.dept_name}
                                                            </option>
                                                        )

                                                    }
                                                </Input>
                                                :
                                                <Input  type="select" name="DEPT" id="DEPT" onChange={(e) => this.updateValue(e)}>
                                                <option val="select" key={0} selected={userData.DEPT =="" ? "selected" : ''} >Select</option>
                                                    {

                                                        this.state.deptList.map((data, key) => 
                                                        userData.DEPT !="" ?
                                                            <option
                                                                key={key}
                                                                val={data.DEPT}
                                                                selected={data.DEPT == userData.DEPT}
                                                            >
                                                                {data.DEPT}
                                                            </option>
                                                            :
                                                            <option
                                                                key={key}
                                                                val={data.DEPT}
                                                                
                                                            >
                                                                {data.DEPT}
                                                            </option>
                                                        )

                                                    }
                                                </Input>
                                            }
                                            <div className="invalid-feedback">
                                                Please provide a valid department.
                                            </div>
                                        </FormGroup>
                                        </Col>*/}
                                    {/*
                                    <Col md={{size: 12}}>
                                        <FormGroup>
                                            
                                            <Label for="DIVISION">Division</Label>
                                            {/* <Input type="text" name="DIVISION" id="DIVISION" placeholder="" value={userData.DIVISION} onChange={(e) => this.updateValue(e)} /> */}
                                            {/*
                                                getYear() > '2020' ? 
                                                <Input  type="select" name="DIVISION" id="DIVISION" onChange={(e) => this.updateValue(e)}>
                                                <option value="select" key={0} selected={userData.DIVISION =="" ? "selected" : ''} >Select</option>
                                                    {

                                                        this.state.divisionList.map((data, key) => 
                                                            userData.DIVISION !="" ? 
                                                            <option
                                                                key={key}
                                                                value={data.division_id}
                                                                selected={data.division_name == userData.DIVISION}
                                                            >
                                                                {data.division_name}
                                                            </option>
                                                            :
                                                            <option
                                                                key={key}
                                                                value={data.division_id}
                                                                
                                                            >
                                                                {data.division_name}
                                                            </option>
                                                        )

                                                    }
                                                </Input>
                                            :
                                                <Input  type="select" name="DIVISION" id="DIVISION" onChange={(e) => this.updateValue(e)}>
                                                <option val="select" key={0} selected={userData.DIVISION =="" ? "selected" : ''} >Select</option>
                                                    {

                                                        this.state.divisionList.map((data, key) => 
                                                            userData.DIVISION !="" ? 
                                                            <option
                                                                key={key}
                                                                val={data.DIVISION}
                                                                selected={data.DIVISION == userData.DIVISION}
                                                            >
                                                                {data.DIVISION}
                                                            </option>
                                                            :
                                                            <option
                                                                key={key}
                                                                val={data.DIVISION}
                                                                
                                                            >
                                                                {data.DIVISION}
                                                            </option>
                                                        )

                                                    }
                                                </Input>
                                            }
                                            <div className="invalid-feedback">
                                                Please provide a valid division.
                                            </div>
                                        </FormGroup>
                                        </Col>
                                    
                                    <Col md={{size: 12}}>
                                        <FormGroup>
                                            
                                            <Label for="SECTION">Section</Label>
                                            {/* <Input type="text" name="SECTION" id="SECTION" placeholder="" value={userData.SECTION} onChange={(e) => this.updateValue(e)} /> */}
                                            {/*
                                                getYear() > '2020' ?
                                                <Input type="select" name="SECTION" id="SECTION" onChange={(e) => this.updateValue(e)}>
                                                <option val="select" key={0} selected={userData.SECTION =="" ? "selected" : ''} >Select</option>
                                                    {

                                                        this.state.sectionList.map((data, key) => 
                                                            userData.SECTION !="" ? 
                                                            <option
                                                                key={key}
                                                                value={data.section_id}
                                                                selected={data.section_name == userData.SECTION}
                                                            >
                                                                {data.section_name}
                                                            </option>
                                                            :
                                                            <option
                                                                key={key}
                                                                value={data.section_id}
                                                                
                                                            >
                                                                {data.section_name}
                                                            </option>
                                                        )

                                                    }
                                                </Input>
                                                :
                                                <Input type="select" name="SECTION" id="SECTION" onChange={(e) => this.updateValue(e)}>
                                                <option val="select" key={0} selected={userData.SECTION =="" ? "selected" : ''} >Select</option>
                                                    {

                                                        this.state.sectionList.map((data, key) => 
                                                            userData.SECTION !="" ? 
                                                            <option
                                                                key={key}
                                                                val={data.SECTION}
                                                                selected={data.SECTION == userData.SECTION}
                                                            >
                                                                {data.SECTION}
                                                            </option>
                                                            :
                                                            <option
                                                                key={key}
                                                                val={data.SECTION}
                                                                
                                                            >
                                                                {data.SECTION}
                                                            </option>
                                                        )

                                                    }
                                                </Input>
                                            }
                                            <div className="invalid-feedback">
                                                Please provide a valid section.
                                            </div>
                                        </FormGroup>
                                    </Col>
                                    
                                    <Col md={{size: 12}}>
                                        <FormGroup>
                                            
                                            <Label for="PC">Job Grade</Label>
                                            {/* <Input type="text" name="PC" id="PC" placeholder="" value={userData.PC} onChange={(e) => this.updateValue(e)} /> */}
                                            {/*
                                                getYear() > '2020' ? 
                                                <Input  type="select" name="PC" id="PC" onChange={(e) => this.updateValue(e)}>
                                                <option value="select" key={0} selected={userData.PC =="" ? "selected" : ''} >Select</option>
                                                    {

                                                        this.state.pcList.map((data, key) => 
                                                            userData.PC !="" ? 
                                                            <option
                                                                key={key}
                                                                value={data.pc_id}
                                                                selected={data.pc_name == userData.PC}
                                                                
                                                            >
                                                                {data.pc_name}
                                                            </option>
                                                            :
                                                            <option
                                                                key={key}
                                                                value={data.pc_id}
                                                                
                                                            >
                                                                {data.pc_name}
                                                            </option>
                                                        )

                                                    }
                                                </Input>
                                                :
                                                <Input  type="select" name="PC" id="PC" onChange={(e) => this.updateValue(e)}>
                                                <option val="select" key={0} selected={userData.PC =="" ? "selected" : ''} >Select</option>
                                                    {

                                                        this.state.pcList.map((data, key) => 
                                                            userData.PC !="" ? 
                                                            <option
                                                                key={key}
                                                                val={data.PC}
                                                                selected={data.PC == userData.PC}
                                                                
                                                            >
                                                                {data.PC}
                                                            </option>
                                                            :
                                                            <option
                                                                key={key}
                                                                val={data.PC}
                                                                
                                                            >
                                                                {data.PC}
                                                            </option>
                                                        )

                                                    }
                                                </Input>
                                            }
                                            <div className="invalid-feedback">
                                                Please provide a valid Job Grade.
                                            </div>
                                        </FormGroup>
                                        </Col>*/}
                                    
                                    <Col md={{size: 12}} >
                                        <FormGroup>
                                            <Label for="section">Supervising <b>{userReportingData.length} {userReportingData.length == 1 ? 'Employee' : 'Employees'}</b></Label>
                                            <Table style={{marginTop:5}}>
                                            
                                                <tbody>
                                                    {
                                                    userReportingData && userReportingData.length && userReportingData.map((subOrdinate, key) =>
                                                        <tr key={key}>
                                                            <td >{subOrdinate.ID_NUMBER}</td>
                                                            <td>{subOrdinate.NAME}</td>
                                                            <td>{subOrdinate.SECTION}</td>
                                                            <td>{subOrdinate.DIVISION}</td>
                                                            { handleEditSubOrds=='' ? 
                                                            <td style={{color:'red', fontSize: '12px', cursor: 'pointer'}}
                                                                onClick={(e) => this.props.removeSubordinate(adminData.admin.admin_id, subOrdinate.supervisor_id,subOrdinate.ID_NUMBER)}    
                                                            >
                                                                <FontAwesomeIcon icon={faTimesCircle} size="1x" style={{marginRight: '10px'}} /> Remove
                                                            </td>
                                                            :null
                                                            }
                                                        </tr>
                                                    )
                                                    }

                                                    <tr>
                                                        <td colSpan="2" style={{textAlign: 'right'}}>
                                                            Add new subordinate: 
                                                        </td>
                                                        <td colSpan="2">
                                                            <FormGroup className="boxFieldForm" style={{marginBottom: 0}}>
                        
                                                                <Input type="text" bsSize="lg" maxLength="1" name="employeeIdSub" id="employeeIdSub1"
                                                                    value={employeeIdSub[0]}
                                                                    onChange={evt => this.handleChangeSub(evt, 0)} />

                                                                <Input type="text" bsSize="lg" maxLength="1" name="employeeIdSub" id="employeeIdSub2" 
                                                                    value={employeeIdSub[1]}
                                                                    onChange={evt => this.handleChangeSub(evt, 1)} />

                                                                <Input style={{
                                                                    background: '#fef67f',
                                                                    border: 'none',
                                                                }} type="text" bsSize="lg" value="-" maxLength="1" name="dash" id="dash" disabled />

                                                                <Input type="text" bsSize="lg" maxLength="1" name="employeeIdSub" id="employeeIdSub3"
                                                                    value={employeeIdSub[3]}
                                                                    onChange={evt => this.handleChangeSub(evt, 3)} />

                                                                <Input type="text" bsSize="lg" maxLength="1" name="employeeIdSub" id="employeeIdSub4"
                                                                    value={employeeIdSub[4]}
                                                                    onChange={evt => this.handleChangeSub(evt, 4)} />

                                                                <Input type="text" bsSize="lg" maxLength="1" name="employeeIdSub" id="employeeIdSub5"
                                                                    value={employeeIdSub[5]}
                                                                    onChange={evt => this.handleChangeSub(evt, 5)} />

                                                                <Input type="text" bsSize="lg" maxLength="1" name="employeeIdSub" id="employeeIdSub6"
                                                                    value={employeeIdSub[6]}
                                                                    onChange={evt => this.handleChangeSub(evt, 6)} />

                                                            </FormGroup>
                                                        </td>
                                                        <td style={{textAlign: 'left'}}>
                                                            {
                                                            employeeIdStringSub.length == 7 && isAddComplete == true?
                                                                
                                                                <Button disabled={handleEditSubOrds !=''  ? true : isLoadingSub} style={{margin: '0 0 0 0'}} color={"warning"} size="md"
                                                                    onClick={(e) => {
                                                                        this.setState({
                                                                            isLoadingSub : true
                                                                        }, () => {

                                                                            if( employeeIdStringSub !=userData.ID_NUMBER && employeeIdStringSub != adminData.admin.employee_id) {
                                                                                this.props.assignNewSubordinate(adminData.admin.admin_id, userData.ID_NUMBER, employeeIdStringSub)
                                                                                return false;
                                                                            }

                                                                            if(employeeIdStringSub != adminData.admin.employee_id && employeeIdStringSub == userData.ID_NUMBER ) {
                                                                                alert('You have entered the same Employee ID as the profile. Please add a valid Employee ID to add sub-ordinate for '+userData.NAME)
                                                                                this.setState({
                                                                                    isLoadingSub : false
                                                                                })
                                                                                return false;
                                                                            }
                                                                            
                                                                            if(employeeIdStringSub == adminData.admin.employee_id && employeeIdStringSub != userData.ID_NUMBER) {
                                                                                alert('You have entered your Employee ID. Please add a valid Employee ID to add sub-ordinate.')
                                                                                this.setState({
                                                                                    isLoadingSub : false
                                                                                })
                                                                                return false;
                                                                            }
                                                                                

                                                                        })
                                                                    }}
                                                                    
                                                                >
                                                                    Add
                                                                    {
                                                                        isLoadingSub ? <Spinner style={{margin: '4px 0px 4px 10px'}} color="light" size="sm" /> : null
                                                                    }
                                                                </Button>
                                                            : null
                                                            }
                                                        </td>
                                                    </tr>

                                                </tbody>
                                            </Table>
                                        </FormGroup>
                                    </Col>
                                    <Col md={{size: 12}}>
                                        <FormGroup>
                                            <Label for="section">Reporting to <b>{userSupervisorData.length} Supervisor</b></Label>
                                            <Table style={{marginTop:5}} >  
                                                <tbody>
                                                    {
                                                    userSupervisorData && userSupervisorData.length ?
                                                        userSupervisorData.map((supervisor, key) =>
                                                            <tr key={key}>
                                                                <td>{supervisor.ID_NUMBER}</td>
                                                                <td>{supervisor.NAME}</td>
                                                                <td>{supervisor.SECTION}</td>
                                                                <td>{supervisor.DIVISION}</td>
                                                                {handleEditSup =='' ? 
                                                                <td style={{color:'green', fontSize: '12px', cursor: 'pointer'}} onClick={(e) => this.setState({
                                                                    userSupervisorData : []
                                                                })}>
                                                                    <FontAwesomeIcon icon={faTimesCircle} size="1x" style={{marginRight: '10px'}} /> Change
                                                                </td>
                                                                :null}
                                                            </tr>
                                                        )
                                                    : null
                                                    }
                                                    
                                                    {
                                                    !userSupervisorData.length ?
                                                        <tr>
                                                            <td colSpan="2">
                                                                Employee id of new supervisor: 
                                                            </td>
                                                            <td>
                                                                <FormGroup className="boxFieldForm" style={{marginBottom: 0}}>
                            
                                                                    <Input type="text" bsSize="lg" maxLength="1" name="employeeId" id="employeeId1"
                                                                        value={employeeId[0]}
                                                                        onChange={evt => this.handleChange(evt, 0)} />

                                                                    <Input type="text" bsSize="lg" maxLength="1" name="employeeId" id="employeeId2" 
                                                                        value={employeeId[1]}
                                                                        onChange={evt => this.handleChange(evt, 1)} />

                                                                    <Input style={{
                                                                        background: '#fef67f',
                                                                        border: 'none',
                                                                    }} type="text" bsSize="lg" value="-" maxLength="1" name="dash" id="dash" disabled />

                                                                    <Input type="text" bsSize="lg" maxLength="1" name="employeeId" id="employeeId3"
                                                                        value={employeeId[3]}
                                                                        onChange={evt => this.handleChange(evt, 3)} />

                                                                    <Input type="text" bsSize="lg" maxLength="1" name="employeeId" id="employeeId4"
                                                                        value={employeeId[4]}
                                                                        onChange={evt => this.handleChange(evt, 4)} />

                                                                    <Input type="text" bsSize="lg" maxLength="1" name="employeeId" id="employeeId5"
                                                                        value={employeeId[5]}
                                                                        onChange={evt => this.handleChange(evt, 5)} />

                                                                    <Input type="text" bsSize="lg" maxLength="1" name="employeeId" id="employeeId6"
                                                                        value={employeeId[6]}
                                                                        onChange={evt => this.handleChange(evt, 6)} />

                                                                </FormGroup>
                                                            </td>
                                                            <td>
                                                                {
                                                                employeeIdString.length == 7 && isAddComplete == true ?
                                                                    
                                                                    <Button disabled={handleEditSup !=''  ? true : isLoading} style={{margin: '0 0 0 0'}} color={"warning"} size="md"
                                                                        onClick={(e) => {
                                                                            this.setState({
                                                                                isLoading : true
                                                                            }, () => {

                                                                                if(employeeIdString != userData.ID_NUMBER && employeeIdString != adminData.admin.employee_id)  {
                                                                                    this.props.assignNewAdmin(adminData.admin.admin_id, employeeIdString, userData.ID_NUMBER)
                                                                                }

                                                                                if(employeeIdString != adminData.admin.employee_id && employeeIdString == userData.ID_NUMBER) {
                                                                                    alert('You have entered the same Employee ID as the profile. Please add a valid Employee ID to add supervisor for '+userData.NAME);
                                                                                    this.setState({
                                                                                        isLoading : false
                                                                                    })
                                                                                    return false;
                                                                                }

                                                                                if(employeeIdString == adminData.admin.employee_id && employeeIdString != userData.ID_NUMBER) {
                                                                                    alert('You have entered your Employee ID. Please add a valid Employee ID to add supervisor.');
                                                                                    this.setState({
                                                                                        isLoading : false
                                                                                    })
                                                                                    return false;
                                                                                }
                                                                            })
                                                                        }}
                                                                       
                                                                    >
                                                                        Add
                                                                        {
                                                                            isLoading ? <Spinner style={{margin: '4px 0px 4px 10px'}} color="light" size="sm" /> : null
                                                                        }
                                                                    </Button>
                                                                : null
                                                                }
                                                            </td>
                                                        </tr>
                                                        : null
                                                    }
                                                </tbody>
                                            </Table>
                                        </FormGroup>
                                    </Col>
                                    <Col className="text-center">
                                        <Button className="btn btn-lg" color="warning" onClick={()=>{
                                            
                                            
                                            if(isAddComplete == false) {
                                                
                                                const {userData,groupRelateDataType} = this.state;
                                                console.log(this.props.validEmpId,':userData:',userData);
                                                userData['ID_NUMBER'] = this.props.validEmpId;
                                                let objKeys = Object.keys(userData);
                                                let flag = 1;
                                                objKeys.map((eachKey)=> {
                                                    console.log("each_key:",userData[eachKey])
                                                    if(userData[eachKey] == '') {
                                                        console.log('Here is the data:',$('#'+eachKey).siblings());
                                                        $('div.row').scrollTop()
                                                        $('#'+eachKey).siblings('.invalid-feedback').addClass('d-block');
                                                        
                                                    }
                                                    else
                                                        flag++;
                                                    
                                                   
                                                    let objlength; 
                                                    if(groupRelateDataType == "sub_groups") {
                                                        objlength = objKeys.length - 2;
                                                        userData['location_id'] = 0;
                                                        userData['satellite_id'] = 0;
                                                        //userData['branch_id'] = 0;

                                                    }
                                                    else {
                                                        userData['sub_groupid'] = 0;
                                                        userData['dept_id'] = 0;
                                                        objlength = objKeys.length - 2
                                                    }

                                                    console.log(objlength,'****',flag); 
                                                    if(objlength == flag) {
                                                        
                                                        if(userData.email  !="") {
                                                            
                                                            this.setState({
                                                                isAddBtnLoading:true
                                                            })

                                                            let that = this;
                                                            
                                                            this.props.validateEmpEmail(userData.email,function(res){
                                                                
                                                                console.log('this is the request:',res);
                                                                
                                                                if(typeof res === 'object' && (res.status == 'success' && res.result == 1)) {
                                                                    
                                                                    that.setState({
                                                                        isAddBtnLoading:false
                                                                    })
                                                                    
                                                                    $('#email').siblings('.invalid-feedback').text('Email is already in use').addClass('d-block');
                                                                }
                                                                else {
                                                                    userData['ID_NUMBER'] = that.props.validEmpId
                                                                    
                                                                    userData['NAME'] = userData.FIRST_NAME+' '+userData.LAST_NAME;

                                                                    that.props.addEmp(userData,function(res) {
                                                                        console.log('add employee res:',res);
                                                                        if(typeof res ==='object' && res.status == 'success') {
                                                                            that.props.getAllAssessmentsForAdmin(userData['ID_NUMBER']);
                                                                        } else {
                                                                            that.setState({
                                                                                isAddBtnLoading:false
                                                                            })
                                                                        }
                                                                        
                                                                    });
                                                                    
                                                                }
                                                            });
                                                        }  
                                                    }
                                                    
                                                    
                                                })
                                            }
                                            else
                                                window.location.reload();
                                                
                                            console.log("here is the userData:",userData);
                                        }}>
                                            {
                                            isAddComplete == false ? 
                                            'Add Employee':'Done'
                                            }

                                            {
                                            isAddBtnLoading ? <Spinner style={{margin: '4px 0px 4px 10px'}} color="light" size="sm" /> : null
                                            }
                                         </Button>
                                    </Col>
                                    
                                </Row>
                            </Form>
                        </Container>

                    </TabPane>
                    
                    <TabPane tabId="am">

                        <Row style={{margin: 0}}>
                            <AssessmentManagement userFetchedProfile={userData} assessmentMangement={this.state.assessmentMangement} handleEdit={handleRPL}/>
                        </Row>

                    </TabPane>
                    
                </TabContent>

            </Container>

        </div>
        
        {/* <Footer /> */}
      </div>
    );
  }
}

AddEmployeeProfile.propTypes = {
    updateProfileData: PropTypes.func.isRequired,
    assignNewAdmin : PropTypes.func.isRequired,
    assignNewSubordinate : PropTypes.func.isRequired,
    adminFetchEmployeeInformation : PropTypes.func.isRequired,
    removeSubordinate : PropTypes.func.isRequired,
    validateEmpEmail : PropTypes.func.isRequired,
    addEmp : PropTypes.func.isRequired,
    getAllAssessmentsForAdmin : PropTypes.func.isRequired,
    getAdditionalData : PropTypes.func.isRequired,
    getMainGroupsData : PropTypes.func.isRequired,
    fetchSubGroupsOrLocationsOrSOG : PropTypes.func.isRequired,
    fetchSubGroupsDepartments : PropTypes.func.isRequired,
    fetchLocationBranches : PropTypes.func.isRequired,
    fetchBrancheSatellite : PropTypes.func.isRequired
    
}

const mapStatesToProps = state => ({
    assignedSupDataSuccessAdmin : state.webRed.assignedSupDataSuccessAdmin,
    assignedSubordinateDataSuccessAdmin : state.webRed.assignedSubordinateDataSuccessAdmin,
    removedSubordinateDataSuccessAdmin : state.webRed.removedSubordinateDataSuccessAdmin,
    fetchedAllAssessmentsForAdmin : state.webRed.fetchedAllAssessmentsForAdmin,
    fetchEmpDataSuccessAdmin : state.webRed.fetchEmpDataSuccessAdmin,
    additionalData : state.webRed.additionalData,
    mainGroupsData : state.webRed.mainGroupsData
})

export default connect( mapStatesToProps, { updateProfileData, assignNewAdmin, assignNewSubordinate,
    adminFetchEmployeeInformation, removeSubordinate, validateEmpEmail,
    addEmp,getAllAssessmentsForAdmin, getAdditionalData,
    getMainGroupsData,fetchSubGroupsOrLocationsOrSOG,
    fetchSubGroupsDepartments,fetchLocationBranches,
    fetchBrancheSatellite
    })( AddEmployeeProfile );