import React, { Component,useState } from 'react';
import { render } from 'react-dom';
import MultiPage from "./anwers/reports/MultiPage";
import MultiPageV2 from './anwers/reports/MultiPageV2';
import PrintButton from "./anwers/reports/PrintButton";
import {
  Button,
  Container,
  Row,
  Col,
  Collapse,
  Card,
  Spinner,
  CardBody
} from "reactstrap";
//import './anwers/reports/print.min.css';
const Report = ({reportData,userName,selYear}) => {
  const date = new Date();  // 2009-11-10
  const month = date.toLocaleString('default', { month: 'long' });
  const today = date.getDate();
  const year = date.getFullYear();
  var hours = date.getHours();

  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? '0'+minutes : minutes;
  var strTime = hours + ':' + minutes + ' ' + ampm;
  //console.log("Faheem here:",userName)
  return(
    <div>
      
      {/* <div className="white mt5 tc f3">Multi Page Mode</div> */}
      <PrintButton id={"multiPage"} label={"Download Report"} />
      {/* <Button>Download Report</Button>
      <span style={{marginRight:10}}></span>
      <Button>Print Report</Button>
      <span style={{marginBottom:20}}></span> */}
      {/* <div id="myMm" style="height: 1mm;"></div> */}
      {
        selYear == "2020" ? 
        <MultiPage id={"multiPage"} data={reportData} userName={userName} month={month} date={today} year={year} time={strTime}/>
        :
        <MultiPageV2 id={"multiPage"} data={reportData} userName={userName} month={month} date={today} year={year} time={strTime}/>
      }
    </div>
  )
};

export default Report;