const archetypesData = [
    {
        name: "Perceptive",
        desc: "Displays intuitive/ sensitive judgment to identify crucial factors and understand their possible effects on organization",
        total: 3
    },{
        "name": "Coordinator",
        desc: "Establishes teamwork and understanding, relays information and/or form alliances with concerned parties for collaboration",
        total: 7
    },{
        "name": "Responsible",
        desc: "Ably performs all the basic/necessary tasks/functions related to one's field (devoted)  ",
        total: 45
    },{
        "name": "Facilitator",
        desc: "Helps subordinates/ team members to identify best course of action/ oversees the work of others and directs them to perform their jobs successfully",
        total: 18
    },{
        "name": "Proactive",
        desc: "Takes initiative/ charge of the situation (creating or controlling a situation rather than just responding to it) ",
        total: 3
    },{
        "name": "Appraiser",
        desc: "(Evaluator) Carries out assessment of objects/employees' performance/ status of project, etc and reports the findings/feedback regarding validity/ usefulness, etc  ",
        total: 30
    },{
        "name": "Analyst",
        desc: "Gathers all relevant data and conducts in-depth assessment of all aspects and elements to yield significant finding from the given data.",
        total: 10
    },{
        "name": "Adaptive",
        desc: "Possesses tendency and flexibility to adapt to different situations.",
        total: 2
    },{
        "name": "Motivator",
        desc: "Encourages others and promote their interest and enthusiasm to display required attitude for the job",
        total: 2
    },{
        "name": "Diplomat",
        desc: "Handles situations tactfully to make it a win-win situation and to bring out synergies. ",
        total: 2
    },{
        "name": "Diligent",
        desc: "Demonstrates consistent effort to accomplish set goals (works with perseverance) ",
        total: 2
    },{
        "name": "Composed",
        desc: "Have one’s emotions and expressions under control/ in check (Restrained)",
        total: 1
    },{
        "name": "Aspirant",
        desc: "Aspires/ seeks/ desires advancement/ achievement through proper conduct of responsibilities.",
        total: 10
    },{
        "name": "Professional",
        desc: "Ably performs complex tasks and handle matters related to one's field (competent), also behaves and interacts on formal lines (ensuring objective and fair judgment)",
        total: 36
    },{
        "name": "Assertive",
        desc: "Confident and clear with opinion so people take notice",
        total: 2
    },{
        "name": "Technologist",
        desc: "Practices expertise in the use of technology  ",
        total: 5
    },{
        "name": "Influencer",
        desc: "Effects or changes the way other people behave, takes charge, directs the team, as well as influences decisions",
        total: 20
    },{
        "name": "Organized",
        desc: "Performs tasks in a structured/ systematic manner",
        total: 2
    },{
        "name": "Strategist",
        desc: "Skilled in planning actions and policies, using tact and reasoning to tackle the identified redundancies or for enhancements",
        total: 40
    },{
        "name": "Mediator",
        desc: "Helps people resolve conflict and reach agreement",
        total: 1
    },{
        "name": "Receptive",
        desc: "Considers/ seeks/ accepts suggestions/ ideas from others, able or quick to receive knowledge and ideas ",
        total: 2
    },{
        "name": "Optimizer",
        desc: "Seeks continuous improvement, attempts to make given resources/ procedures as effective as possible ",
        total: 33
    },{
        "name": "Auditor",
        desc: "Performs official inspection of organization’s accounts",
        total: 1
    },{
        "name": "Cognizant",
        desc: "Have scope of knowledge and awareness related to ones's field",
        total: 2
    },{
        "name": "Pragmatic",
        desc: "Deals with things sensibly and realistically in a way that is based on practical rather than theoretical considerations.",
        total: 1
    },{
        "name": "Acest",
        desc: "Dazzlingly skilled in one's field.",
        total: 1
    },{
        "name": "Mechanical",
        desc: "Demonstartes ability to perform mechanical tasks",
        total: 1
    }
]

export default archetypesData;