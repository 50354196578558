import React from 'react';
import './App.css';
import { HashRouter as Router, Route, Switch } from "react-router-dom";
import store from "./store";
import { Provider } from "react-redux";

//importing component for website
// import ImportSheetComponent from "./components/website/ImportSheetComponent";
// import ImportSheetEmailsComponent from "./components/website/ImportSheetEmailsComponent";
import ImportDeptSheetComponent from "./components/website/ImportDeptSheetComponent";
import ImportEmpSheetComponent from "./components/website/ImportEmpSheetComponent";

import LoginComponent from "./components/website/login/LoginComponent";
import ProfileComponent from "./components/website/profile/ProfileComponent";
import MyAssessmentsComponent from "./components/website/assessment/MyAssessmentsComponent";
import SubordinateAssessmentsComponent from "./components/website/assessment/SubordinateAssessmentsComponent";
import FormComponent from "./components/website/assessment/FormComponent";

import PagcorStatsComponent from "./components/stats/talentmap/PagcorStatsComponent";
import PagcorStatsComponentWithArchetypesTemp from "./components/stats/talentmap/PagcorStatsComponentWithArchetypesTemp";

import EmployeeManagment from './components/website/admin/employeeComponent/EmployeeManagment';
import AddEmployee from './components/website/admin/employeeComponent/AddEmployee';

import SubAdmins from './components/website/admin/subAdmins/SubAdmins';
import EditSubAdmins from './components/website/admin/subAdmins/EditSubAdmins';

import ScoreCard from './components/website/assessment/ScoreCard';

import AssessmentStatistics from './components/website/admin/employeeComponent/AssessmentStatistics';
import AssessmentStatisticsV2 from './components/website/admin/employeeComponent/AssessmentStatisticsV2';
import EmpReports from './components/website/admin/employeeComponent/EmpReports';
import AdminAuditLogs from './components/website/admin/employeeComponent/AdminAuditLogs';
import AdminManageAssessment from'./components/website/admin/employeeComponent/AdminManageAssessment';
import AdminSearchAssessment from'./components/website/admin/employeeComponent/AdminSearchAssessment';
import AdminEditAssessment from'./components/website/admin/employeeComponent/AdminEditAssessment';

import AssessmentHistory from './components/website/admin/employeeComponent/AssessmentHistory';
import StatsCompareComponent from './components/stats/talentmap/StatsCompareComponent';
import AllBranchesStats from './components/stats/talentmap/AllBranchesStats';
import AllBranchesStatsCompare from './components/stats/talentmap/AllBranchesStatsCompare';
import ChartComponent from './components/website/admin/employeeComponent/Charts';
import AssessmentTest from './components/website/admin/employeeComponent/AssessmentTest';


function App() {
  return (
    <Provider store={store}>
      <Router>
        <div className="App">
          <Switch>
            <Route exact path="/" component={LoginComponent} />
            <Route path="/login" component={LoginComponent} />
            <Route path="/profile" component={ProfileComponent} />
            <Route path="/assess-myself" component={MyAssessmentsComponent} />
            <Route path="/assess-subordinate" component={SubordinateAssessmentsComponent} />
            <Route path="/scorecard" component={ScoreCard} />
            
            {/* This component is used for rendering the forms for subcompetencies */}
            <Route path="/assessment/:formId" component={FormComponent} />

            <Route path="/pagocr-charts" component={ChartComponent} />
            {/* <Route path="/importSheetDataEmail" component={ImportSheetEmailsComponent} /> */}
            <Route path="/ImportDeptSheetData" component={ImportDeptSheetComponent} />
            <Route path="/ImportEmpSheetData" component={ImportEmpSheetComponent} />
            
            {/* Route is specifically for pagCor dashboard */}
            <Route path="/pagcor/talentmap" component={PagcorStatsComponent} />
            <Route path="/pagcor/comparision" component={StatsCompareComponent} />
            <Route path="/pagcor/AllBranchesStats" component={AllBranchesStats} />
            <Route path="/pagcor/AllBranchesStatsCompare" component={AllBranchesStatsCompare} />
            <Route path="/branch-with-archetypes/:branchId" component={PagcorStatsComponentWithArchetypesTemp} />

            {/* this component is used for admin  */}
            <Route path="/admin" component={EmployeeManagment} />
            <Route path="/assessment" component={AssessmentTest} />
             
             {/* this component is used for admin  */}
             <Route path="/employee/add" component={AddEmployee} />

            {/* this component is used for sub admin  */}
            <Route path="/sub-admin" component={SubAdmins} />
             {/* this component is used for edit sub admin  */}
             <Route path="/edit-sub-admin/:employeeId/:adminId" component={EditSubAdmins} />

             {/* this component is used for statistics  */}
             {/* <Route path="/statistics" component={AssessmentStatistics} /> */}
             <Route path="/statistics" component={AssessmentStatisticsV2} />
             
             {/* this component is used for em-reports  */}
             <Route path="/em-reports" component={EmpReports} />

             {/* this component is used for statistics  */}
             <Route path="/assessment-history" component={AssessmentHistory} />

             {/* this component is used for audit logs  */}
             <Route path="/audit-logs" component={AdminAuditLogs} />

             {/* this component is used for manage assessment  */}
             <Route path="/manage-assessment" component={AdminManageAssessment} />

              {/* this component is used for search assigned assessment  */}
              <Route path="/search-assessment" component={AdminSearchAssessment} />
              
              {/*this component is used for edit assigned assessment*/}
              <Route path="/edit-assessment" component={AdminEditAssessment} />
              

          </Switch>
        </div>
      </Router>
    </Provider>
  );
}

export default App;