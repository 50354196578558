import React, { Component } from "react";
import { Redirect } from "react-router-dom";
// import Header from '../../Header';
// import Footer from "./../Footer";

import {
    fethcAdminAssessmentHistory, getMainGroupsData,
    getAssessGroupDeparts, getAssessGroupDivisions,
    getAssessSOGGroupDivisions, getAssesstDiv,
    getAssesstDivSesction, fethcAdminAssessmentHistoryFilter,
    getAssesstCFDivSesction, getAssesstSOGDivSesction
} from '../../../../../src/actions/webRedActions';

// import { getEmployeeProfile } from '../../../../actions/utility';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import axios from 'axios';
import { getBaseUrl, getYear, accessToken, generateToken } from './../../../../actions/utility';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css


// import userData from './../userData';

import {
    Button,
    Container,
    Row,
    Col,
    Media,
    Form,
    FormGroup,
    Label,
    Input,
    Collapse,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Spinner,
    Progress
} from "reactstrap";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle, faCheckCircle } from '@fortawesome/free-solid-svg-icons';

// import readXlsxFile from 'read-excel-file';
import EmployeeProfile from "./EmployeeProfile";
import EmployeeSearchForm from "./EmployeeSearchForm";
import AddEmployeeProfile from "./AddEmployeeProfile";
import AdminHeader from './../AdminHeader';
import SideMenu from './../sidMenu/SideMenu';
import { getAdminUserData } from "../../../../../src/actions/utility";
// import { BrowserRouter as Router, Route, Link } from "react-router-dom";
// import { ProSidebar, SidebarHeader, SidebarFooter, SidebarContent, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faBars,faHome,faUsers } from '@fortawesome/free-solid-svg-icons';
import 'react-pro-sidebar/dist/css/styles.css';
import { isArray } from "jquery";
import UpdateYear from "../../../updateyear";

class AssessmentHistory extends Component {

    constructor(props) {
        super(props);
        this.state = {
            adminAssessmentHistory: '',
            historyEmployees: [],
            branchList: [],
            branch_id: '',
            branch_name: '',
            isShowingComplete: true,
            // employee_id : '',

            employeeId: ["", "", "-", "", "", "", ""],
            employeeIdString: "",
            employeeIdError: "",
            isLoading: false,
            enableEmpIdSearch: false,
            pageNo: 1,
            divLoading: false,
            loadMoreEnabled: false,
            isLoadingHistory: false,
            isSingleRecord: false,
            totallPages: 0,
            mainGroupsDataArr: [],
            loadingSubGroups: false,
            loadingDepart: [],
            departsData: [],
            branchesData: [],
            divisionData: [],
            deptDivs: [],
            deptDivLoader: false,
            groupId: "",
            branchId: "",
            deptId: "",
            divisionId: "",
            sectionId: "",
            sectionLoader: false,
            sectionData: [],
            applyFilterLoader: false,
            selComplete: 1,
            completePerc:0,
            completeRec:0,
            inCompleteRec:0,
            inCompletePerc:0

        };
    }

    componentWillMount() {
        this.props.getMainGroupsData("");
        this.fetchHistoryData();

    }
    groupDeparts = (groupId) => {
        // alert("Hi");
        this.setState({ loadingDepart: true, divisionData: [], branchesData:[], groupId: groupId, deptDivs: [] })
        let ths = this;
        this.props.getAssessGroupDeparts(groupId, function (res) {
            console.log("this is the sever res:", res)
            if (res != 0) {
                ths.setState({ departsData: res.result, loadingDepart: false })
            }
        })
    }
    getDivisions = (groupId, value) => {
        this.setState({ divLoading: true, departsData: [],branchId: value, deptDivs: [],divisionData:[], groupId: groupId,deptId:"",divisionId:"",sectionId:"",sectionData:"" })
        let ths = this;
        this.props.getAssessGroupDivisions(groupId, function (res) {
            console.log("this is the sever res:", res)
            if (res != 0) {
                ths.setState({ divisionData: res.result, divLoading: false })
            }
        })
    }
    getSOGDivisions = (groupId) => {
        this.setState({ loadingDepart: true, branchId:'', departsData: [], branchesData:[], groupId: groupId,deptId:"",sectionId:"",divisionId:"",sectionData:[],deptDivs:[],divisionData:[] })
        let ths = this;
        this.props.getAssessSOGGroupDivisions(groupId, function (res) {
            console.log("this is the sever res:", res)
            if (res != 0) {
                ths.setState({ divisionData: res.result, loadingDepart: false })
            }
        })
    }
    fetchDeptDivisions = (deptId) => {
        this.setState({ deptDivLoader: true, deptDivs: [], deptId: deptId,sectionId:"",divisionId:"" ,sectionData: [],deptDivs:[]})
        let ths = this;
        this.props.getAssesstDiv(deptId, function (res) {
            console.log("this is the sever res:", res)
            if (res != 0) {
                ths.setState({ deptDivs: res.result, deptDivLoader: false })
            }
        })
    }
    fetchDeptDivisionSection = (divId) => {
        this.setState({ sectionLoader: true, divisionId: divId,sectionId:'',sectionData:[] })
        let ths = this;
        this.props.getAssesstDivSesction(this.state.deptId, divId, function (res) {
            console.log("this is the sever res:", res)
            if (res != 0) {
                ths.setState({ sectionData: res.result, sectionLoader: false })
            }
        })
    }
    fetchCFDivisionSection = (divId) => {
        this.setState({ sectionLoader: true, divisionId: divId,sectionId: "",sectionData:[] })
        let ths = this;
        this.props.getAssesstCFDivSesction(divId, function (res) {
            console.log("this is the sever res:", res)
            if (res != 0) {
                ths.setState({ sectionData: res.result, sectionLoader: false })
            }
        })
    }
    fetchSOGDivisionSection = (divId) => {
        this.setState({ sectionLoader: true, divisionId: divId,sectionId:"",sectionData:[] })
        let ths = this;
        this.props.getAssesstSOGDivSesction(divId, function (res) {
            console.log("this is the sever res:", res)
            if (res != 0) {
                ths.setState({ sectionData: res.result, sectionLoader: false })
            }
        })
    }

    handleChange = (event, index) => {
        event.preventDefault();
        const { name, value } = event.target;

        var employeeIdTemp = this.state.employeeId;
        employeeIdTemp[index] = value;

        this.setState({
            [name]: employeeIdTemp
        }, () => {
            // console.log(this.state.employeeId);

            var emp_id = this.convertEmpIdToString(this.state.employeeId);
            this.setState({
                employeeIdString: emp_id
            }, () => {
                // it means all inputs are done here we need to call action to verify

                if (emp_id.length == 7) {
                }
                // console.log(this.state.employeeIdString)
            })

        })

        if (this.state.employeeIdError != "") {
            this.setState({
                employeeIdError: ""
            })
        }
    }

    convertEmpIdToString = (empId) => {
        var stringEmpId = "";
        empId.map(val => {
            if (val && val != "" && val != undefined) {
                stringEmpId += val;
            }
        })

        return stringEmpId;
    }

    getAllBranchesData = (groupId) => {
        this.setState({ loadingDepart: true, departsData: [], branchesData:[], deptDivs: [], branchId: "", divisionData:[], groupId: groupId,deptId:"",divisionId:"",sectionId:"",sectionData:"" })
        const token = generateToken();
        axios.get(getBaseUrl() + 'api/branches/get?access_token=' + accessToken() + '&year=' + getYear() + '&groupid=' + groupId, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'authorizedToken': token,
                'appidadmin':localStorage.getItem('app_id_session') // Add your custom token here
            }
        })
            .then((res) => {

                console.log('hhhhhhhhhhh:',res);
                if (res.data.result) {
                    this.setState({
                        branchList: res.data.result, branchesData: res.data.result, loadingDepart: false
                    }, () => {
                        console.log(this.state.branchList)
                        this.setState({
                            branch_id: "",
                            branch_name: ""
                        }, () => {
                            //this.fetchHistoryData();
                        })
                    })
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    fetchHistoryData = () => {

        this.setState({
            historyEmployees: [],
            isLoadingHistory: true
        }, () => {

            // get all history data
            var filterData = {
                branch_id: this.state.branchId,
                employee_id: this.state.employeeIdString,
                page: this.state.pageNo,
                completed: this.state.selComplete,
                group_id: this.state.groupId,
                dept_id: this.state.deptId,
                div_id: this.state.divisionId,
                section_id: this.state.sectionId
            }
            this.props.fethcAdminAssessmentHistory(filterData);

        })

    }


    componentWillReceiveProps(nextProps, thisProps) {
        if (this.props.getAssessmentsHistorySuccess !== nextProps.getAssessmentsHistorySuccess) {
            console.log("In main if", nextProps.getAssessmentsHistorySuccess);

            const { adminAssessmentHistory, isSingleRecord } = this.state;

            try {
                var history = nextProps.getAssessmentsHistorySuccess.data.result;
                this.state.completePerc = nextProps.getAssessmentsHistorySuccess.data.percentageComplete
                this.state.inCompletePerc = nextProps.getAssessmentsHistorySuccess.data.percentageIncomplete
                this.state.completeRec = nextProps.getAssessmentsHistorySuccess.data.completeRecord
                this.state.inCompleteRec = nextProps.getAssessmentsHistorySuccess.data.incompleteRecord

                

                console.log("this is the history data:",nextProps.getAssessmentsHistorySuccess.data);
                this.state.totallPages = history.noOfRecords;
                console.log("history data:", history)
                if (typeof history === 'object' && Object.keys(history).length > 0) {
                    console.log('in when data: ', Object.keys(history));
                    if (isSingleRecord == false) {
                        this.setState({
                            adminAssessmentHistory: history,
                            historyEmployees: Object.keys(history).map((emp, key) => {
                                return ({
                                    allCompt: history[emp].allCompt,
                                    complete: history[emp].complete,
                                    inComplete: history[emp].inComplete,
                                    empProfile: history[emp].empProfile
                                })
                            }),
                            isLoading: false,
                            isLoadingHistory: false
                        }, () => {
                            console.log(this.state.historyEmployees)
                        })
                    }
                    else {
                        var singleEmpData = [];
                        singleEmpData.push(history);
                        this.setState({
                            adminAssessmentHistory: history,
                            historyEmployees: singleEmpData,
                            isLoading: false,
                            isLoadingHistory: false,
                            isSingleRecord: false
                        }, () => {
                            console.log(history, "Faheem in single:", this.state.historyEmployees)
                        })
                    }
                }
                else {
                    console.log('in else when there is no data found');
                    this.setState({
                        isLoadingHistory: false
                    })
                }
            } catch (e) {
                this.setState({
                    isLoading: false,
                    isLoadingHistory: false
                })
                confirmAlert({
                    title: 'Error',
                    message: "There's some error in fetching the assessment history.",
                    closeOnEscape: false,
                    closeOnClickOutside: false,
                    buttons: [
                        {
                            label: 'OK',
                            onClick: () => { }
                        }
                    ]
                })
            }

        }
        if (this.props.mainGroupsData != nextProps.mainGroupsData) {
            this.setState({ mainGroupsDataArr: nextProps.mainGroupsData.data }, () => {
                console.log('set state works here', this.state.mainGroupsDataArr);
            })
        }
    }

    updateValue = (e) => {
        this.setState({
            branch_id: e.target.selectedIndex == 0 ? '' : this.state.branchList[e.target.selectedIndex - 1].branch_id,
            branch_name: e.target.selectedIndex == 0 ? '' : this.state.branchList[e.target.selectedIndex - 1].branch_name,
            pageNo: 1,
        }, () => {
            console.log(this.state.branch_id, this.state.branch_name);
            this.fetchHistoryData();
        })
    }

    render() {

        const { loadMoreEnabled, pageNo,
            adminAssessmentHistory, historyEmployees, branchList,
            branch_name, isShowingComplete, employeeId, employeeIdError,
            employeeIdString, enableEmpIdSearch, isLoading,
            isLoadingHistory, mainGroupsDataArr, loadingSubGroups,
            departsData, loadingDepart, divisionData, deptDivLoader,
            sectionLoader, sectionData, isSingleRecord,branchesData,divLoading,
            deptDivs,branchId
        } = this.state;
console.log('branchId:',branchId);
        return (
            <div>
                {/*Side menu*/}
                <SideMenu />

                <AdminHeader title={"Assessment History"} style={{ marginLeft: 80 }} />

                <Container className="AssessmentHistory" style={{ padding: '5vh 0' }}>

                    <Row className="EmployeeSearchForm">
                  
                        <Col md={{ size: 4 }}>
                            <FormGroup>
                                <Label for="BRANCH">Main Group</Label>:
                                <Input type="select" name="main_group" id="main_group" onChange={(event) => {
                                    console.log("sel val:", event.target.value)
                                    if (event.target.value == 1) {
                                        this.groupDeparts(event.target.value);
                                    }
                                    else if (event.target.value == 2) {
                                        this.getAllBranchesData(event.target.value);
                                    }
                                    else if (event.target.value == 3) {
                                        // this.getSOGDivisions(event.target.value);
                                        this.getAllBranchesData(event.target.value);
                                    }
                                }}>
                                    <option value="0">Select Main Group</option>
                                    {
                                        mainGroupsDataArr.length > 0 ?
                                            mainGroupsDataArr.map((group, index) =>
                                                <option value={group.group_id}>{group.group_name}</option>
                                            )
                                            : null
                                    }
                                </Input>
                            </FormGroup>
                            {
                                loadingDepart == true ?
                                    <Spinner color="primary" style={{
                                        width: '1.10rem',
                                        height: '1.10rem',
                                        marginTop: '-40px',
                                        float: 'right'
                                    }} />
                                    : null
                            }
                        </Col>
                        {
                            departsData.length>0?
                            <Col md={{ size: 4 }}>
                                    <FormGroup>
                                            <Label for="BRANCH">Departments</Label>:
                                            <Input type="select" name="main_group" id="main_group" onChange={(e) => {
                                                // alert(e.target.value)
                                                this.fetchDeptDivisions(e.target.value)
                                            }}>
                                                <option value="0">Select department</option>
                                                {
                                                    departsData.length > 0 ?
                                                        departsData.map((department, index) =>
                                                            <option value={department.dept_id}>{department.dept_suffix}</option>
                                                        )
                                                        : null
                                                }
                                            </Input>
                                </FormGroup>
                                 {
                                    deptDivLoader == true ?
                                        <Spinner color="primary" style={{
                                            width: '1.10rem',
                                            height: '1.10rem',
                                            marginTop: '-40px',
                                            float: 'right'
                                        }} />
                                        : null
                                }
                            </Col>
                                
                            :null
                        }
                                {
                                    branchesData.length > 0 ?
                                    <Col md={{ size: 4 }}>
                                        <FormGroup>
                                            <Label for="BRANCH">Branches</Label>:
                                            <Input type="select" name="main_group" id="main_group" onChange={(e) => {
                                                //  console.log("sel3333 val:", e.target.value,groupId)
                                                    this.getDivisions(this.state.groupId,e.target.value)
                                            }}>
                                                <option value="0">Select Branch</option>
                                                {
                                                    branchesData.length > 0 ?
                                                    branchesData.map((branches, index) =>
                                                            <option value={branches.branch_id} key={index}>{branches.branch_name}</option>
                                                        )
                                                        : null
                                                }
                                            </Input>
                                           
                                        </FormGroup>
                                        {
                                divLoading == true ?
                                    <Spinner color="primary" style={{
                                        width: '1.10rem',
                                        height: '1.10rem',
                                        marginTop: '-40px',
                                        float: 'right'
                                    }} />
                                    : null
                            }
                        </Col>
                                        :null
                                }

           
                            <>
                                {
                                    divisionData.length > 0 ?
                        <Col md={{ size: 4 }}>
                                        <FormGroup>
                                            <Label for="BRANCH">Divisions</Label>:
                                            <Input type="select" name="main_group" id="main_group" onChange={(e) => {
                                                if (this.state.groupId == 2) {
                                                    this.fetchCFDivisionSection(e.target.value)
                                                }
                                                if (this.state.groupId == 3) {
                                                    this.fetchSOGDivisionSection(e.target.value)
                                                }
                                            }}>
                                                <option value="0">Select division</option>
                                                {
                                                    divisionData.length > 0 ?
                                                        divisionData.map((div, index) =>
                                                            <option value={div.division_id}>{div.division_name}</option>
                                                        )
                                                        : null
                                                }
                                            </Input>
                                           
                                        </FormGroup>
                                        {
                                            deptDivLoader == true ?
                                                <Spinner color="primary" style={{
                                                    width: '1.10rem',
                                                    height: '1.10rem',
                                                    marginTop: '-40px',
                                                    float: 'right'
                                                }} />
                                                : null
                                        }
                                    </Col>
                                        : null
                                        // <FormGroup>
                                        //     <Label for="BRANCH">Departments</Label>:
                                        //     <Input type="select" name="main_group" id="main_group" onChange={(e) => {
                                        //         // alert(e.target.value)
                                        //         this.fetchDeptDivisions(e.target.value)
                                        //     }}>
                                        //         <option value="0">Select department</option>
                                        //         {
                                        //             departsData.length > 0 ?
                                        //                 departsData.map((department, index) =>
                                        //                     <option value={department.dept_id}>{department.dept_suffix}</option>
                                        //                 )
                                        //                 : null
                                        //         }
                                        //     </Input>
                                        // </FormGroup>
                                }
                            </>

                        {
                            deptDivs.length > 0 ?

                                <Col md={{ size: 4 }}>
                                    <FormGroup>
                                        <Label for="BRANCH">Divisions</Label>:
                                        <Input type="select" name="main_group" id="main_group" onChange={(e) => {
                                            //alert(e.target.value)
                                            this.fetchDeptDivisionSection(e.target.value);
                                        }}>
                                            <option value="0">Select division</option>
                                            {
                                                deptDivs.length > 0 ?
                                                    deptDivs.map((div, index) =>
                                                        <option value={div.division_id}>{div.division_name}</option>
                                                    )
                                                    : null
                                            }
                                        </Input>
                                    </FormGroup>
                                    {
                                        sectionLoader == true ?
                                            <Spinner color="primary" style={{
                                                width: '1.10rem',
                                                height: '1.10rem',
                                                marginTop: '-40px',
                                                float: 'right'
                                            }} />
                                            : null
                                    }
                                </Col>
                                : null
                        }

                        <Col md={{ size: 4 }}>
                            <FormGroup>
                                <Label for="BRANCH">Sections</Label>:
                                <Input type="select" name="main_group" id="main_group" onChange={(e) => {
                                    this.setState({ sectionId: e.target.value })

                                }}>
                                    <option value="0">Select Section</option>
                                    {
                                        sectionData.length > 0 ?
                                            sectionData.map((section, index) =>
                                                <option value={section.section_id}>{section.section_name}</option>
                                            )
                                            : null
                                    }
                                </Input>
                            </FormGroup>
                            {
                                loadingSubGroups == true ?
                                    <Spinner color="primary" style={{
                                        width: '1.10rem',
                                        height: '1.10rem',
                                        marginTop: '-40px',
                                        float: 'right'
                                    }} />
                                    : null
                            }

                        </Col>
                        <UpdateYear/>
                        <Button style={{position:'absolute' ,top:'129px',left:'90.20%'}} color={"warning"} size="md"
                                onClick={(e) => {
                                    this.setState({ applyFilterLoader: true })
                                    if (this.state.groupId == 1) {
                                        let filter = {
                                            group_id: this.state.groupId,
                                            dept_id: this.state.deptId,
                                            div_id: this.state.divisionId,
                                            section_id: this.state.sectionId,
                                            employee_id: this.state.employeeIdString,
                                            branch_id: '',
                                            page: this.state.pageNo,
                                            completed: this.state.selComplete
                                        }
                                        console.log("This is the filter data:", filter)
                                        this.props.fethcAdminAssessmentHistoryFilter(filter, (res) => {
                                            this.setState({ applyFilterLoader: false })
                                            if (res != 0) {
                                                try {
                                                    var history = res.result;
                                                    this.state.completePerc = res.percentageComplete
                                                    this.state.inCompletePerc = res.percentageIncomplete
                                                    this.state.completeRec = res.completeRecord
                                                    this.state.inCompleteRec = res.incompleteRecord
                                                    
                                                    this.state.totallPages = history.noOfRecords;
                                                    console.log("history data:", history)
                                                    if (typeof history === 'object' && Object.keys(history).length > 0) {
                                                        console.log('in when data: ', Object.keys(history));
                                                        if (isSingleRecord == false) {
                                                            this.setState({
                                                                adminAssessmentHistory: history,
                                                                historyEmployees: Object.keys(history).map((emp, key) => {
                                                                    return ({
                                                                        allCompt: history[emp].allCompt,
                                                                        complete: history[emp].complete,
                                                                        inComplete: history[emp].inComplete,
                                                                        empProfile: history[emp].empProfile
                                                                    })
                                                                }),
                                                                isLoading: false,
                                                                isLoadingHistory: false
                                                            }, () => {
                                                                console.log(this.state.historyEmployees)
                                                            })
                                                        }
                                                        else {
                                                            var singleEmpData = [];
                                                            singleEmpData.push(history);
                                                            this.setState({
                                                                adminAssessmentHistory: history,
                                                                historyEmployees: singleEmpData,
                                                                isLoading: false,
                                                                isLoadingHistory: false,
                                                                isSingleRecord: false
                                                            }, () => {
                                                                console.log(history, "Faheem in single:", this.state.historyEmployees)
                                                            })
                                                        }
                                                    }
                                                    else {
                                                        console.log('in else when there is no data found');
                                                        this.setState({
                                                            isLoadingHistory: false
                                                        })
                                                    }
                                                } catch (e) {
                                                    this.setState({
                                                        isLoading: false,
                                                        isLoadingHistory: false
                                                    })
                                                    confirmAlert({
                                                        title: 'Error',
                                                        message: "There's some error in fetching the assessment history.",
                                                        closeOnEscape: false,
                                                        closeOnClickOutside: false,
                                                        buttons: [
                                                            {
                                                                label: 'OK',
                                                                onClick: () => { }
                                                            }
                                                        ]
                                                    })
                                                }
                                            }
                                        })
                                    }
                                    if (this.state.groupId == 2 || this.state.groupId == 3) {
                                        let filter = {
                                            group_id: this.state.groupId,
                                            dept_id: '',
                                            div_id: this.state.divisionId,
                                            section_id: this.state.sectionId,
                                            employee_id: this.state.employeeIdString,
                                            // branch_id: '',
                                            branch_id: branchId,
                                            page: this.state.pageNo,
                                            completed: this.state.selComplete
                                        }
                                        console.log("This is the filter data:", filter)
                                        this.props.fethcAdminAssessmentHistoryFilter(filter, (res) => {
                                            this.setState({ applyFilterLoader: false })
                                            if (res != 0) {
                                                try {
                                                    var history = res.result;
                                                    this.state.totallPages = history.noOfRecords;
                                                    this.state.completePerc = res.percentageComplete
                                                    this.state.inCompletePerc = res.percentageIncomplete
                                                    this.state.completeRec = res.completeRecord
                                                    this.state.inCompleteRec = res.incompleteRecord
                                                    console.log("history data:", history)
                                                    if (typeof history === 'object' && Object.keys(history).length > 0) {
                                                        console.log('in when data: ', Object.keys(history));
                                                        if (isSingleRecord == false) {
                                                            this.setState({
                                                                adminAssessmentHistory: history,
                                                                historyEmployees: Object.keys(history).map((emp, key) => {
                                                                    return ({
                                                                        allCompt: history[emp].allCompt,
                                                                        complete: history[emp].complete,
                                                                        inComplete: history[emp].inComplete,
                                                                        empProfile: history[emp].empProfile
                                                                    })
                                                                }),
                                                                isLoading: false,
                                                                isLoadingHistory: false
                                                            }, () => {
                                                                console.log(this.state.historyEmployees)
                                                            })
                                                        }
                                                        else {
                                                            var singleEmpData = [];
                                                            singleEmpData.push(history);
                                                            this.setState({
                                                                adminAssessmentHistory: history,
                                                                historyEmployees: singleEmpData,
                                                                isLoading: false,
                                                                isLoadingHistory: false,
                                                                isSingleRecord: false
                                                            }, () => {
                                                                console.log(history, "Faheem in single:", this.state.historyEmployees)
                                                            })
                                                        }
                                                    }
                                                    else {
                                                        console.log('in else when there is no data found');
                                                        this.setState({
                                                            isLoadingHistory: false
                                                        })
                                                    }
                                                } catch (e) {
                                                    this.setState({
                                                        isLoading: false,
                                                        isLoadingHistory: false
                                                    })
                                                    confirmAlert({
                                                        title: 'Error',
                                                        message: "There's some error in fetching the assessment history.",
                                                        closeOnEscape: false,
                                                        closeOnClickOutside: false,
                                                        buttons: [
                                                            {
                                                                label: 'OK',
                                                                onClick: () => { }
                                                            }
                                                        ]
                                                    })
                                                }
                                            }
                                        })
                                    }
                                }}
                                disabled={this.state.applyFilterLoader}
                            >
                                {'Apply filter'}
                                {
                                    this.state.applyFilterLoader ? <Spinner style={{ margin: '4px 0px 4px 10px' }} color="light" size="sm" /> : null
                                }
                            </Button>
                        {/* <Col md={{ size: 12, offset: 0 }}>
                            <Form>
                                <Row style={{
                                    marginBottom: '30px',
                                    padding: '1.5rem 1rem',
                                    backgroundColor: '#fef67f',
                                    border: '1px #ffd29b solid',
                                    borderRadius: '3px'
                                }}>
                                    <Col md={4} style={{ textAlign: 'right' }}>
                                        <p>Search by employee id</p>
                                    </Col>
                                    <Col md={5} style={{ textAlign: 'left' }}>
                                        <FormGroup className="boxFieldForm" style={{ marginBottom: 0 }}>

                                            <Input type="text" bsSize="lg" maxLength="1" name="employeeId" id="employeeId1"
                                                value={employeeId[0]}
                                                onChange={evt => this.handleChange(evt, 0)} />

                                            <Input type="text" bsSize="lg" maxLength="1" name="employeeId" id="employeeId2"
                                                value={employeeId[1]}
                                                onChange={evt => this.handleChange(evt, 1)} />

                                            <Input style={{
                                                background: '#fef67f',
                                                border: 'none',
                                                fontSize: '36px',
                                                height: '10px'
                                            }} type="text" bsSize="lg" value="-" maxLength="1" name="dash" id="dash" disabled />

                                            <Input type="text" bsSize="lg" maxLength="1" name="employeeId" id="employeeId3"
                                                value={employeeId[3]}
                                                onChange={evt => this.handleChange(evt, 3)} />

                                            <Input type="text" bsSize="lg" maxLength="1" name="employeeId" id="employeeId4"
                                                value={employeeId[4]}
                                                onChange={evt => this.handleChange(evt, 4)} />

                                            <Input type="text" bsSize="lg" maxLength="1" name="employeeId" id="employeeId5"
                                                value={employeeId[5]}
                                                onChange={evt => this.handleChange(evt, 5)} />

                                            <Input type="text" bsSize="lg" maxLength="1" name="employeeId" id="employeeId6"
                                                value={employeeId[6]}
                                                onChange={evt => this.handleChange(evt, 6)} />

                                        </FormGroup>
                                    </Col>
                                    <Col md={3} style={{ textAlign: 'left' }}>
                                        {
                                            employeeIdString.length == 7 ?
                                                <Button style={{ margin: '0 0 0 0' }} color={enableEmpIdSearch ? "danger" : "warning"} size="md"
                                                    onClick={(e) => {
                                                        if (enableEmpIdSearch) {
                                                            this.setState({
                                                                isLoading: true,
                                                                enableEmpIdSearch: false,
                                                                employeeId: ["", "", "-", "", "", "", ""],
                                                                employeeIdString: "",
                                                                employeeIdError: ""
                                                            }, () => {
                                                                this.fetchHistoryData();
                                                            })
                                                        } else {
                                                            this.setState({
                                                                isLoading: true,
                                                                enableEmpIdSearch: true,
                                                                isSingleRecord: true
                                                            }, () => {
                                                                this.fetchHistoryData();
                                                            })
                                                        }
                                                    }}
                                                    disabled={isLoading}
                                                >
                                                    {!enableEmpIdSearch ? 'Search' : 'Remove'}
                                                    {
                                                        isLoading ? <Spinner style={{ margin: '4px 0px 4px 10px' }} color="light" size="sm" /> : null
                                                    }
                                                </Button>
                                                : null
                                        }
                                    </Col>

                                    {
                                        employeeIdError && employeeIdError != "" ?
                                            <Col>
                                                <p style={{ color: 'red' }}>
                                                    <FontAwesomeIcon icon={faTimesCircle} size="1x" style={{ marginRight: '10px' }} />
                                                    {employeeIdError}
                                                </p>
                                            </Col>
                                            : null
                                    }
                                </Row>
                            </Form>
                        </Col> */}
                        
                    </Row>

                    <Row className="topButtons">
                        <Col>
                            <Button color="primary" block size="lg" className={isShowingComplete ? 'active' : null} onClick={() => {
                                this.setState({
                                    isShowingComplete: true,
                                    selComplete: 1
                                }, () => {
                                    this.fetchHistoryData()
                                })
                            }}> Assessment Completed <label style={{marginLeft:20,color:"green"}}>{this.state.completePerc}% ({this.state.completeRec})</label></Button>
                             
                             
                        </Col>
                        <Col>
                            <Button color="primary" block size="lg" className={!isShowingComplete ? 'active' : null} onClick={() => {
                                this.setState({
                                    isShowingComplete: false,
                                    selComplete: 0
                                }, () => {
                                    this.fetchHistoryData()
                                })
                            }}> Assessment Incompleted <label style={{marginLeft:20,color:"red"}}>{this.state.inCompletePerc}% ({this.state.inCompleteRec})</label></Button>
                        </Col>
                    </Row>

                    <Row className="bgColorApplied" style={{ paddingTop: '4vh', textTransform: 'uppercase', fontWeight: 'bold' }}>
                        <Col md={{ size: 2 }}>
                            Employee ID
                        </Col>
                        <Col md={{ size: 2 }}>
                            Fullname
                        </Col>
                        <Col md={{ size: 2 }}>
                            Group
                        </Col>
                        {
                            getYear() >= 2021 ? null
                                :
                                <Col md={{ size: 2 }}>
                                    <FormGroup>
                                        {/* <Label for="BRANCH">Branch</Label> */}
                                        <Input type="select" name="branch_id" id="branch_id" onChange={(e) => this.updateValue(e)}>
                                            <option val="">All Branches</option>
                                            {
                                                this.state.branchList && this.state.branchList.map((data, key) =>
                                                    <option
                                                        key={key}
                                                        val={data.branch_id}
                                                    // selected={branch_name == data.branch_name}
                                                    >
                                                        {data.branch_name}
                                                    </option>
                                                )
                                            }
                                        </Input>
                                    </FormGroup>
                                </Col>
                        }
                        <Col md={{ size: 2 }}>
                            Division
                        </Col>
                        <Col md={{ size: 2 }}>
                            Assessments
                        </Col>
                        <Col md={{ size: 2 }}>
                            Pecentage Completion
                        </Col>
                    </Row>

                    {
                        historyEmployees && historyEmployees.length ?
                            historyEmployees.map((emp, key) =>
                                isShowingComplete ?
                                    emp.complete == emp.allCompt && emp.allCompt > 0 ?
                                        <Row key={key} className="bgColorApplied">
                                            <Col md={{ size: 2 }}>
                                                {emp.empProfile.employee_id}
                                            </Col>
                                            <Col md={{ size: 2 }}>
                                                {emp.empProfile.name}
                                            </Col>
                                            <Col md={{ size: 2 }}>
                                                {emp.empProfile.group}
                                            </Col>
                                            {
                                                getYear() >= 2021 ? null
                                                    :
                                                    <Col md={{ size: 2 }}>
                                                        {branch_name}
                                                    </Col>
                                            }
                                            <Col md={{ size: 2 }}>
                                                {emp.empProfile.division}
                                            </Col>
                                            <Col md={{ size: 2 }}>
                                                {isShowingComplete ? (emp.complete + '/' + emp.allCompt) : (emp.inComplete + '/' + emp.allCompt)}
                                            </Col>
                                            <Col md={{ size: 2 }}>
                                                {Math.round((parseInt(emp.complete) / parseInt(emp.allCompt))*100)+"%"}
                                            </Col>
                                        </Row>
                                        : null
                                    :
                                    (emp.inComplete < emp.allCompt || emp.allCompt == emp.inComplete) && emp.inComplete > 0 ?
                                        <Row key={key} className="bgColorApplied">
                                            <Col md={{ size: 2 }}>
                                                {typeof emp.empProfile.employee_id !== "undefined" ? emp.empProfile.employee_id : emp.empProfile.ID_NUMBER}
                                            </Col>
                                            <Col md={{ size: 2 }}>
                                                {typeof emp.empProfile.name !== 'undefined' ? emp.empProfile.name : emp.empProfile.NAME}
                                            </Col>
                                            <Col md={{ size: 2 }}>
                                                {emp.empProfile.group}
                                            </Col>
                                            {
                                                getYear() >= 2021 ? null
                                                    :
                                                    <Col md={{ size: 2 }}>
                                                        {branch_name}
                                                    </Col>
                                            }
                                            <Col md={{ size: 2 }}>
                                                {typeof emp.empProfile.division !== "undefined" ? emp.empProfile.division : emp.empProfile.DIVISION}
                                            </Col>
                                            <Col md={{ size: 2 }}>
                                                {isShowingComplete ? (emp.complete + '/' + emp.allCompt) : (parseInt(emp.allCompt) - parseInt(emp.inComplete) + '/' + emp.allCompt)}
                                            </Col>
                                            <Col md={{ size: 2 }}>
                                                {Math.round((parseInt(emp.complete) / parseInt(emp.allCompt))*100)+"%"}
                                            </Col>
                                        </Row>
                                        : null

                            )

                            :
                            isLoadingHistory == true ?
                                <Spinner style={{ margin: '20vh 0' }} size="md" color="dark" />
                                : null

                    }
                    {
                        historyEmployees.length && employeeIdString == "" ?
                        <>
                            <Row>
                                <Col>
                                    <Button style={{ textAlign: 'left', marginTop: 20, marginRight: 10 }} size="sm" color="secondary"
                                        disabled={pageNo == 1 || loadMoreEnabled ? true : false}
                                        onClick={(e) => {
                                            this.setState({
                                                pageNo: pageNo - 1,
                                                loadMoreEnabled: false
                                            }, () => {
                                                this.fetchHistoryData();
                                            })
                                        }}
                                    >
                                        {
                                            loadMoreEnabled ?
                                                <Spinner size="sm" color="light" style={{ marginRight: '5px' }} />
                                                : null
                                        }
                                        Previous</Button>

                                    <Button style={{ textAlign: 'left', marginTop: 20 }} size="sm" color="secondary"
                                        disabled={loadMoreEnabled || pageNo == this.state.totallPages}
                                        onClick={(e) => {
                                            this.setState({
                                                pageNo: pageNo + 1,
                                                loadMoreEnabled: false
                                            }, () => {
                                                this.fetchHistoryData();
                                            })
                                        }}
                                    >
                                        {
                                            loadMoreEnabled ?
                                                <Spinner size="sm" color="light" style={{ marginRight: '5px' }} />
                                                : null
                                        }
                                        Next</Button><sup style={{ marginLeft: 10 }}><strong>Every page loads 20 records for better performance</strong><sup style={{ marginLeft: 5 }}><b>( page {pageNo} out of {this.state.totallPages} )</b></sup></sup>
                                </Col>
                            </Row>
                            <Row>
                            <Col>
                            <Button size="sm" color="primary" onClick={() => {
                                let filter = {
                                    group_id: this.state.groupId,
                                    dept_id: this.state.deptId,
                                    div_id: this.state.divisionId,
                                    section_id: this.state.sectionId,
                                    employee_id: this.state.employeeIdString,
                                    branch_id: branchId,
                                    page: this.state.pageNo,
                                    completed: this.state.selComplete
                                }
                                let queryString = new URLSearchParams(filter).toString();
                                const token = generateToken()
                                const tokenConvert = encodeURIComponent(token)
                                let downloadURL = getBaseUrl() +'api/admin/download/assessmentbased/pdf/report?year='+ getYear()+'&access_token='+accessToken()+'&is_excel=0'+'&'+ queryString + '&pass_token=' + tokenConvert;
                                window.open(downloadURL, "_blank");
                            }}>Download PDF</Button>
                            <Button size="sm" color="primary" onClick={() => {
                                let filter = {
                                    group_id: this.state.groupId,
                                    dept_id: this.state.deptId,
                                    div_id: this.state.divisionId,
                                    section_id: this.state.sectionId,
                                    employee_id: this.state.employeeIdString,
                                    branch_id: branchId,
                                    page: this.state.pageNo,
                                    completed: this.state.selComplete
                                }
                                let queryString = new URLSearchParams(filter).toString();
                                const token = generateToken()
                                const tokenConvert = encodeURIComponent(token)
                                let downloadURL = getBaseUrl() +'api/admin/download/assessmentbased/pdf/report?year='+ getYear()+'&access_token='+accessToken()+'&is_excel=1'+'&'+ queryString + '&pass_token=' + tokenConvert;
                                window.open(downloadURL, "_blank");
                            }}>Download Excel</Button>   
                            </Col>
                            </Row>
                            </>
                            : null
                    }
                </Container>

            </div>


        );
    }
}

AssessmentHistory.propTypes = {
    fethcAdminAssessmentHistory: PropTypes.func.isRequired,
    getMainGroupsData: PropTypes.func.isRequired,
    getAssessGroupDeparts: PropTypes.func.isRequired,
    getAssessGroupDivisions: PropTypes.func.isRequired,
    getAssessSOGGroupDivisions: PropTypes.func.isRequired,
    getAssesstDivHierarchy: PropTypes.func.isRequired,
    getAssesstDiv: PropTypes.func.isRequired,
    getAssesstDivSesction: PropTypes.func.isRequired,
    fethcAdminAssessmentHistoryFilter: PropTypes.func.isRequired,
    getAssesstCFDivSesction: PropTypes.func.isRequired
}

const mapStatesToProps = state => ({
    getAssessmentsHistorySuccess: state.webRed.getAssessmentsHistorySuccess,
    mainGroupsData: state.webRed.mainGroupsData,
})

export default connect(mapStatesToProps, {
    fethcAdminAssessmentHistory, getMainGroupsData,
    getAssessGroupDeparts, getAssessGroupDivisions, getAssessSOGGroupDivisions,
    getAssesstDiv, getAssesstDivSesction, fethcAdminAssessmentHistoryFilter,
    getAssesstCFDivSesction, getAssesstSOGDivSesction
})(AssessmentHistory);