import {
	_addedDataSuccessfull,
	_triggerNextToPin,
	_triggerLoginBtn,
	_triggerSwitchTab,
	_getAllAssessment,
	_assessmentFormDone,
	_switchBranchFromSidebar,
	_getAllBranchesList,
	_proceedToAssessments,
	_updateRequestSent,
	_fetchEmployeeDataAdmin,
	_assignedSupervisorSuccess,
	_assignedSubordinateSuccess,
	_removedSubordinateSuccess,
	_fetchedAllCompetenciesListAdmin,
	_getScoreCard,
	_getScoreCardStatus,
	_getSubAdmins,
	_restValue,
	_validateEmailId,
	_getAllAssessStats,
	_getAssessmentsHistory,
	_getAllAdminAuditLogs,
	_getAllEmpReportsData,
	_showScoreCardLoader,
	_totalPeopleAssesst,
	_fetchMainCompetencies,
	_getSubCompetency,
	_allCompetencyAvg,
	_fetchDivisions,
	_competencyByDivision,
	_competencyBySecions,
	_branchAvgDme,
	_dmeByDivision,
	_dmeBySections,
	_dmeCategorised,
	_candidateDistribution,
	_competencyYear,
	_totalPeopleAssesstCompare,
	_competencyAvgCompare,
	_competencyByDivisionCompare,
	_competencyBySecionsCompare,
	_branchAvgDmeCompare,
	_compareDmeByDivision,
	_compareDmeBySections,
	_compareDmeCategorised,
	_totalPeopleAssesstAllBranches,
	_fetchMainCompetenciesAllBranches,
	_getSubCompetencyAllBranches,
	_candidateDistributionAllBranches,
	_dmeCategorisedAllBranches,
	_totalPeopleAssesstCompareAllBranches,
	_competencyAvgCompareAllBranches,
	_competencyByDivisionCompareAllBranches,
	_competencyBySecionsCompareAllBranches,
	_branchAvgDmeCompareAllBranches,
	_compareDmeByDivisionAllBranches,
	_compareDmeBySectionsAllBranches,
	_compareDmeCategorisedAllBranches,
	_fetchAdditionalData,
	_fetchMainGroupsData,
	_fetchSubGroupsData,
	_fetchEmployeeActivityLogs,
	_showHideLoader,
	_searchEmployeeLogs,
	_resetEmpId,
	_setPrfileYear
    
} from '../actions/types';

const initialState = {

	dataBranchSuccessful: '',
	triggerNextPinSuccess : '',
	triggeredLoginAction: '',
	triggeredSwitchingTabs: '',
	fetchedAllAssessments: '',
	doneWithAssessmentForm: '',
	switchedBranch: '',
	allBranchesListData : '',
	proceedToAssessmentsTrigger : '',
	updateRequestSentTrigger : '',
	fetchEmpDataSuccessAdmin : '',
	assignedSupDataSuccessAdmin : '',
	assignedSubordinateDataSuccessAdmin : '',
	removedSubordinateDataSuccessAdmin : '',
	fetchedAllAssessmentsForAdmin: '',
	getScoreCardDataSet:'',
	getScoreCardStatusSet:'',
	subAdminsData:'',
	restObjValRes : {},
	validateEmailIDRes : '',
	getAllAssessmentStatistics : '',
	getAllAuditLogs : '',
	getAssessmentsHistorySuccess: '',
	getEmpReportsSuccess : '',
	isLoadingCardList:'',
	totalPeopleAssest: '',
	mainCompetencies: '',
	getSubCompetency: '',
	getAllCompetencyAvg: '',
	divisionsAndSections: '',
	competencyByDivision: '',
	competencyBySection: '',
	branchAvgDme: '',
	dmeByDivision: '',
	dmeBySection: '',
	dmeCategorised: '',
	candidateDistribution: '',
	competencyYear: '',
	totalPeopleAssestCompare: '',
	competencyAvgCompare: '',
	competencyByDivisionCompare: '',
	competencyBySectionCompare: '',
	branchAvgDmeCompare: '',
	dmeByDivisionCompare: '',
	dmeBySectionCompare: '',
	dmeCategorisedCompare: '',
	totalPeopleAssestAllBranches: '',
	mainCompetenciesAllBranches: '',
	candidateDistributionAllBranch: '',
	getSubCompetencyBranches: '',
	dmeCategorisedAllBranches: '',
	totalPeopleAssestCompareAllBranches: '',
	competencyAvgCompareAllBranches: '',
	competencyByDivisionCompareAllBranches: '',
	competencyBySectionCompareAllBranches: '',
	branchAvgDmeCompareAllBranch: '',
	dmeByDivisionCompareAllBranch: '',
	dmeBySectionCompareAllBranch: '',
	dmeCategorisedCompareAllBranches: '',
	additionalData:'',
	mainGroupsData:'',
	subGroupsData : '',
	employeeActivityLogsData : '',
	handleLoader : false,
	searchEmployeeLogsData : '',
	resetEmpId : '',
	selectedYear : ""

};

export default function(state = initialState, action) {
	switch (action.type) {
		
		case _addedDataSuccessfull:
			return {
				...state,
				dataBranchSuccessful: action.payload
			};
		
		case _triggerNextToPin:
			return {
				...state,
				triggerNextPinSuccess: action.payload
			};
		
		case _triggerLoginBtn:
			return {
				...state,
				triggeredLoginAction: action.payload
			};
			
		case _triggerSwitchTab:
			return {
				...state,
				triggeredSwitchingTabs: action.payload
			}
			
		case _getAllAssessment:
			return {
				...state,
				fetchedAllAssessments: action.payload
			}

		case _assessmentFormDone:
			return {
				...state,
				doneWithAssessmentForm : action.payload
			}
			
		case _switchBranchFromSidebar:
			return {
				...state,
				switchedBranch: action.payload
			};
		
		case _getAllBranchesList:
			return {
				...state,
				allBranchesListData : action.payload
			};
		
		case _proceedToAssessments:
			return {
				...state,
				proceedToAssessmentsTrigger : action.payload
			};
		
		case _updateRequestSent:
			return {
				...state,
				updateRequestSentTrigger : action.payload
			};	
		
		case _fetchEmployeeDataAdmin:
			return {
				...state,
				fetchEmpDataSuccessAdmin : action.payload
			};
		
		case _assignedSupervisorSuccess:
			return {
				...state,
				assignedSupDataSuccessAdmin : action.payload
			}
		
		case _assignedSubordinateSuccess:
			return {
				...state,
				assignedSubordinateDataSuccessAdmin : action.payload
			}

		case _removedSubordinateSuccess:
			return {
				...state,
				removedSubordinateDataSuccessAdmin : action.payload
			}

		case _fetchedAllCompetenciesListAdmin:
			return {
				...state,
				fetchedAllAssessmentsForAdmin : action.payload
			}
		case _getScoreCard:
			return {
				...state,
				getScoreCardDataSet : action.payload
			}
		case _getScoreCardStatus:
			return {
				...state,
				getScoreCardStatusSet : action.payload
			}		
		case _getSubAdmins:
			return {
				...state,
				subAdminsData: action.payload
			}
		case _restValue:
			return {
				...state,
				restObjValRes: action.payload
			}
		case _validateEmailId:
			return {
				...state,
				validateEmailIDRes: action.payload
			}	
		case _getAllAssessStats:
			return {
				...state,
				getAllAssessmentStatistics: action.payload
			}	
		case _getAllAdminAuditLogs:
			return {
				...state,
				getAllAuditLogs : action.payload
			}
		case _getAssessmentsHistory:
			return {
				...state,
				getAssessmentsHistorySuccess : action.payload
			}
		case _getAllEmpReportsData:
			return {
				...state,
				getEmpReportsSuccess : action.payload
			}
		case _showScoreCardLoader :
			return {
				...state,
				isLoadingCardList : action.payload
			}
		case _totalPeopleAssesst :
			return {
				...state,
				totalPeopleAssest : action.payload
			}
		case _fetchMainCompetencies :
			return {
				...state,
				mainCompetencies : action.payload
			}
		case _getSubCompetency :
			return {
				...state,
				getSubCompetency : action.payload
			}
		case _allCompetencyAvg :
			return {
				...state,
				getAllCompetencyAvg : action.payload
			}
		case _fetchDivisions :
			return {
				...state,
				divisionsAndSections : action.payload
			}
		case _competencyByDivision :
			return {
				...state,
				competencyByDivision : action.payload
			}
		case _competencyBySecions :
			return {
				...state,
				competencyBySection : action.payload
			}
		case _branchAvgDme :
			return {
				...state,
				branchAvgDme : action.payload
			}
		case _dmeByDivision :
			return {
				...state,
				dmeByDivision : action.payload
			}
		case _dmeBySections :
			return {
				...state,
				dmeBySection : action.payload
			}
		case _dmeCategorised :
			return {
				...state,
				dmeCategorised : action.payload
			}
		case _candidateDistribution :
			return {
				...state,
				candidateDistribution : action.payload
			}
		case _competencyYear :
			return {
				...state,
				competencyYear : action.payload
			}
		case _totalPeopleAssesstCompare :
			return {
				...state,
				totalPeopleAssestCompare : action.payload
			}
		case _competencyAvgCompare :
			return {
				...state,
				competencyAvgCompare : action.payload
			}
		case _competencyByDivisionCompare :
			return {
				...state,
				competencyByDivisionCompare : action.payload
			}
		case _competencyBySecionsCompare :
			return {
				...state,
				competencyBySectionCompare : action.payload
			}
		case _branchAvgDmeCompare :
			return {
				...state,
				branchAvgDmeCompare : action.payload
			}
		case _compareDmeByDivision :
			return {
				...state,
				dmeByDivisionCompare : action.payload
			}
		case _compareDmeBySections :
			return {
				...state,
				dmeBySectionCompare : action.payload
			}
		case _compareDmeCategorised :
			return {
				...state,
				dmeCategorisedCompare : action.payload
			}
		case _totalPeopleAssesstAllBranches :
			return {
				...state,
				totalPeopleAssestAllBranches : action.payload
			}
		case _fetchMainCompetenciesAllBranches :
			return {
				...state,
				mainCompetenciesAllBranches : action.payload
			}
		case _getSubCompetencyAllBranches :
			return {
				...state,
				getSubCompetencyBranches : action.payload
			}
		case _candidateDistributionAllBranches :
			return {
				...state,
				candidateDistributionAllBranch : action.payload
			}
		case _dmeCategorisedAllBranches :
			return {
				...state,
				dmeCategorisedAllBranches : action.payload
			}
		case _totalPeopleAssesstCompareAllBranches :
			return {
				...state,
				totalPeopleAssestCompareAllBranches : action.payload
			}
		case _competencyAvgCompareAllBranches :
			return {
				...state,
				competencyAvgCompareAllBranches : action.payload
			}
		case _competencyByDivisionCompareAllBranches :
			return {
					...state,
					competencyByDivisionCompareAllBranches : action.payload
				}
		case _competencyBySecionsCompareAllBranches :
			return {
					...state,
					competencyBySectionCompareAllBranches : action.payload
				}
		case _branchAvgDmeCompareAllBranches :
			return {
					...state,
					branchAvgDmeCompareAllBranch : action.payload
				}
		case _compareDmeByDivisionAllBranches :
			return {
					...state,
					dmeByDivisionCompareAllBranch : action.payload
				}
		case _compareDmeBySectionsAllBranches :
			return {
					...state,
					dmeBySectionCompareAllBranch : action.payload
				}
		case _compareDmeCategorisedAllBranches :
			return {
					...state,
					dmeCategorisedCompareAllBranches : action.payload
				}
		case _fetchAdditionalData:
			return {
				...state,
				additionalData : action.payload
			}
		case _fetchMainGroupsData:
			return {
				...state,
				mainGroupsData : action.payload
			}
		case _fetchSubGroupsData:
			return {
				...state,
				subGroupsData : action.payload
			}
		case _fetchEmployeeActivityLogs :
			return {
				...state,
				employeeActivityLogsData : action.payload
			}
		case _showHideLoader : 
			return {
				...state,
				handleLoader : action.payload
			}
		case _searchEmployeeLogs : 
			return {
				...state,
				searchEmployeeLogsData : action.payload
			}
		case _resetEmpId : 
			return {
				...state,
				resetEmpId : action.payload
			}
		case _setPrfileYear : 
			return {
				...state,
				selectedYear : action.payload
			}
			
		default:
			return state;
	}
}
