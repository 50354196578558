import React, { Component } from "react";
import { Link } from "react-router-dom";
// import logoHere from "./../../../public/images/logo.png";
import "./../../../public/scss/Header.scss";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';

// import userData from './userData';

// import store from './../../store';
import PropTypes from 'prop-types';
import {  } from './../../../actions/webRedActions';
import { connect } from 'react-redux';
import {getEmployeeProfile} from './../../../actions/utility';

import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Row,
  Col,
  Media,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  // NavLink
} from "reactstrap";

class AdminHeader extends Component {
  constructor(props) {
    super(props);
    const thisRoute = window.location.hash;

    this.toggle = this.toggle.bind(this);

    this.state = {
      isOpen: false,
      isUserLoggedIn : localStorage.getItem('isLoggedInPagCor') && localStorage.getItem('isLoggedInPagCor') == 'true' ? true : false,
      userInfo : getEmployeeProfile(),
      activeTab : thisRoute && thisRoute != undefined && thisRoute != null ? thisRoute.replace("#/","") : '',
      isSubAdmin:false,
      checked: false
    };
    //this.handleChange = this.handleChange.bind(this);

  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  componentWillMount () {
    let checkAdmin = window.localStorage.userIsAdmin;
    console.log(checkAdmin,"===",this.state.activeTab);
    if (checkAdmin == false) {
      window.location.href="/#/login";
      window.location.reload();
    }
  }
  // handleChange(checked) {
  //   console.log('here is the change event:',checked);
  //   this.setState({ checked });
  // }
  componentDidMount () {

    // var subAdmins = ''; 
    // try {
    //   subAdmins = JSON.parse(localStorage.userAdminData);
    //   if(subAdmins.is_superadmin == 0 && subAdmins.sub_admins == null)
    //     this.setState({isSubAdmin : true});
    // }
    // catch(err) {

    // }
    console.log(this.state.userInfo);
    
    if(!this.state.isUserLoggedIn) {
      if(!(window.location.href.indexOf('/login') > -1)) {
        window.location.href = "/#/login";
      }
    }

  }
  handleClick = () => {
    // Refresh the page
    window.location.reload();
  }
  // validateRouteForActive = (route) => {
  //   if(window.location.href.indexOf('/'+route) > -1)
  //     return true;
  //   else 
  //     return false;
  // }

  render() {
    
    const { userInfo, activeTab } = this.state;
    var style = typeof this.props.style !=='undefined' ?  this.props.style : {}
    return (
      <div className="MainHeader" style={style}>
        
        <div>
        <Navbar color="light" light expand="md">
            <NavbarBrand style={{cursor:'pointer'}} onClick={this.handleClick}>
              {this.props.title}
            </NavbarBrand>
            
            <NavbarToggler onClick={this.toggle} />
            <Collapse isOpen={this.state.isOpen} navbar>
              <Nav className="ml-auto text-left" navbar>

                {
                  !this.state.isUserLoggedIn ?
                    <NavItem>
                      <Link className="nav-link" to="/login">
                        Login
                      </Link>
                    </NavItem>
                    : null
                }

                {/* {
                  this.state.isUserLoggedIn ?
                    <NavItem className={activeTab == 'profile' ? 'isActive' : ''}>
                      <Link className="nav-link" to="/profile" onClick={(e) => this.setState({
                        activeTab : 'profile'
                      })}>
                        My profile
                      </Link>
                    </NavItem>
                    : null
                } */}

                {/* {
                  this.state.isUserLoggedIn ?
                    <NavItem className={activeTab == 'assess-myself' ? 'isActive' : ''}>
                      <Link className="nav-link" to="/assess-myself" onClick={(e) => this.setState({
                        activeTab : 'assess-myself'
                      })}>
                        Assess myself
                      </Link>
                    </NavItem>
                    : null
                } */}

                {/* {
                  this.state.isUserLoggedIn ?
                    <NavItem className={activeTab == 'assess-subordinate' ? 'isActive' : ''}>
                      <Link className="nav-link" to="/assess-subordinate" onClick={(e) => this.setState({
                        activeTab : 'assess-subordinate'
                      })}>
                        Assess subordinate
                      </Link>
                    </NavItem>
                    : null
                } */}

                {
                  this.state.isUserLoggedIn ?
                    
                    <UncontrolledDropdown nav inNavbar>
                      
                      <DropdownToggle nav>
                        <span style={{
                          color: "#034d9c",
                          fontSize: '10px',
                          fontWeight: 'bold',
                          position: 'relative',
                          left: '-10px',
                          top: '-1px',
                          background: '#fef67f',
                          padding: '3px 10px',
                          borderRadius: '20px'
                        }}>admin</span>
                        { userInfo.FIRST_NAME + " " + userInfo.MI + ". " + userInfo.LAST_NAME }
                        <FontAwesomeIcon className="userIconHeader" icon={faUser} size="1x" />
                      </DropdownToggle>
                      <DropdownMenu right>
                        <DropdownItem>
                          <Link className="nav-link" to="#"
                            onClick={(e) => {
                              // remove all the localstorage data stored for user
                              localStorage.clear();
                              // localStorage.removeItem("isLoggedInPagCor");
                              // localStorage.removeItem("pagCorUserProfile");
                              // localStorage.removeItem("userIsAdmin");
                              window.location.reload();
                            }}
                          >
                            Logout
                          </Link>
                        </DropdownItem>
                      </DropdownMenu>
                  </UncontrolledDropdown>
                  : null
                }
                
              </Nav>
            </Collapse>
          </Navbar>
        </div>
      </div>
    );
  }
}

// export default Header;

AdminHeader.propTypes = {
  // getCenturySkillsData: PropTypes.func.isRequired
}

const mapStatesToProps = state => ({
  // skillsResponse: state.webRed.skillsResponse
})

export default connect(mapStatesToProps, {  })(AdminHeader);