import React, { Component } from "react";

// import store from './../../store';
import PropTypes from 'prop-types';
// import { uploadAttachedFile } from './../../actions/formActions';
import { getUserData, replaceImagePathCkEditor } from './../../../../actions/utility';
import { connect } from 'react-redux';



// import {
//     Container,
//     Row,
//     Col,
//     ButtonGroup,
//     Button,
//     Card, CardImg, CardText, CardBody,
//     CardTitle, CardSubtitle,
//     Media,
//     Badge,
//     Spinner,
//     Input,
//     UncontrolledCollapse,
//     Modal, ModalHeader, ModalBody, ModalFooter,
//     Form, FormGroup,
//     CustomInput,
//     Label,
//     Collapse
// } from "reactstrap";
import AnswerComponent from "./AnswerComponent";

class QuestionComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            // collapseThis : false
            selectedFile : []
        };
    }

    componentDidMount() {
        
    }

    componentWillReceiveProps(nextProps, thisProps) {

    }

    // onChangeFileUpload = (e, questionid) => {
    //     // console.log(e, questionid);
        
    //     // var tempSelectedFileArray = this.state.selectedFile;
    //     // tempSelectedFileArray[questionid] = e.target.files[0];
    //     this.setState({
    //         selectedFile : e.target.files[0]
    //     }, () => {
            
    //         if(this.state.selectedFile && this.state.selectedFile != undefined) {
    //             var userData = getUserData();
    //             console.log(questionid, this.state.selectedFile, userData.id, userData.form_id);
    //             this.props.uploadAttachedFile(questionid, this.state.selectedFile, userData.id, userData.form_id); // upload the attached file with this question
    //         }
    //     })
    // }


    render() {
        const key2 = this.props.key2;
        const question = this.props.question;
        // var collapseThis = this.state.collapseThis;
        // if(question){
        //     collapseThis = !collapseThis
        // }
        
        return (
            <div className="QuestionComponent">
                {/* <Collapse isOpen={collapseThis}> */}
                    {/* <div key={key}> */}
                    <span className="index">
                        {/* {key2 + 1} */}
                    </span>
                    <div className="d-flex">
                        <div>
                    <span className="mr-2">{this.props.key2 + 1 }.</span>
                        </div>
                    <div dangerouslySetInnerHTML={{__html: replaceImagePathCkEditor(question.question)}}></div>
                    </div>
                    <AnswerComponent answerData={{type: question.typee, answers: question.answers}} />
                    {/* {
                        question.is_file == 1 ?
                            <input style={{marginTop: '10px'}} id={"input_" + question.questionid} type="file" name="file" onChange={e => this.onChangeFileUpload(e, question.questionid)}/>
                        : null
                    } */}

                    {/* </div> */}
                {/* </Collapse> */}

            </div>
        );
    }

}

QuestionComponent.propTypes = {
    // uploadAttachedFile: PropTypes.func.isRequired
}

const mapStatesToProps = state => ({

})

export default connect(mapStatesToProps, { })(QuestionComponent);