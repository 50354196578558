import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import Header from './../../Header';
// import Footer from "./../Footer";

import { getAllAssessmentsForAdmin } from './../../../../actions/webRedActions';
import { getBaseUrl,getYear } from './../../../../actions/utility';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import axios from 'axios';

import './../../../../public/scss/Assessments.scss';

// import dataDummy from './../dataDummy';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';

import {
    Button,
    Container,
    Row,
    Col,
    Collapse,
    Card,
    CardBody,
    Input,
    Label,
    Spinner
  } from "reactstrap";

import readXlsxFile from 'read-excel-file';

// import { BrowserRouter as Router, Route, Link } from "react-router-dom";

class AssessmentManagement extends Component {

    constructor(props) {
        super(props);
        this.state = {
            collapseState : [true, true, true, true],
            mainCompetencies : [],
            subCompetencies : [],
            subComptRpl : [],
            competencies : {},
            listingLoading : false
        };
    }

    componentDidMount() {
        this.setState({
            listingLoading : true
        })
    }

    componentWillReceiveProps(nextProps, thisProps) {
        if(this.props.assessmentMangement != nextProps.assessmentMangement) {
            const data = nextProps.assessmentMangement;
            console.log('assessmentMangement:',data)
            this.setState({
                mainCompetencies : data.mainCompt,
                subCompetencies : data.allSubCompt,
                subComptRpl : data.userSubComptRPL
            }, () => {

                this.setState({
                    competencies : this.state.mainCompetencies.map((comp) => {
                        return {
                        'id' : comp.competency_id,
                        'name' : comp.competency_name,
                        'subCompetencies' : this.state.subCompetencies[comp.competency_id]
                        };
                    }),
                    listingLoading : false
                }, () => {
                    console.log(this.state.competencies);
                })

            })
        }
    }

    updateProfileData = (employee_id, col, val) => {

        var bodyFormData = new FormData();
    
        bodyFormData.set('employee_id', employee_id);
        bodyFormData.set('col', col);
        bodyFormData.set('val', val);
        bodyFormData.set('year', getYear());

        axios.post( getBaseUrl() + 'api/admin/employee/profile/update', bodyFormData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
        .then((res) => {
            console.log(res);
            if (res.data.status == "success") { // if success only
                this.setState({
                    listingLoading : true
                }, () => {
                    // get all the assessments list for this employee
                    this.props.getAllAssessmentsForAdmin(employee_id);
                })
            }
        })
        .catch((error) => {
            console.log(error);
        });
    }

  render() {
    
    const { userFetchedProfile } = this.props;
    console.log(userFetchedProfile);
    const { collapseState, competencies, subComptRpl } = this.state;

    // console.log(competencies);

    return (
      <div className="AssessmentList AssessmentManagement" style={{padding: '0 0 5vh 0', background: '#f6fbff', width: '100%'}}>
          
        {
          this.state.listingLoading ?
            <Col md={{size: 12}} style={{
                textAlign: 'center',
                padding: '45vh 0',
                left: 0,
                top: 0,
                position: 'fixed',
                height: '100vh',
                width: '100vw',
                background: 'rgba(255,255,255,0.7)',
                zIndex: '999',
            }}>
              <Spinner color="primary" />
            </Col>
          : null
        }

        <Container style={{textAlign: 'left'}}>
            {
                competencies && competencies.length > 0 && competencies.map((comp, key) => 
                    
                    comp.subCompetencies && comp.subCompetencies.length ?

                        <Row className="mainList" key={key} style={{paddingBottom: '1vh'}}>
                            <Col md={{size: 10, offset: 1}}>
                                <Row className="heading">
                                    <Col><h4>{comp.name} COMPETENCY</h4></Col>
                                    <Col style={{textAlign: 'right'}}>
                                        <Button style={{textAlign: 'left'}} size="sm" color="primary" outline
                                            onClick={(e) => {
                                                var tempCol = collapseState;
                                                tempCol[key] = !tempCol[key];
                                                this.setState({
                                                    collapseState : tempCol
                                                })
                                            }}
                                        >
                                            <FontAwesomeIcon className="expandIcon" icon={!collapseState[key] ? faPlus : faMinus} size="1x"/>
                                            {
                                                !collapseState[key] ? 'Expand' : 'Close'
                                            }
                                        </Button>
                                    </Col>
                                </Row>
                                
                                <Collapse className="subList" isOpen={collapseState[key]}>
                                    {
                                        comp.subCompetencies && comp.subCompetencies.length > 0 && comp.subCompetencies.map((subComp, key2) => {
                                            var isAssigned = false;
                                            console.log('subComp:',subComp)
                                            var RPLValue = 0;
                                            if(getYear() == '2020') {
                                                var status = subComptRpl[subComp.sub_comptkeyword];
                                                if(status && status != null && status != undefined) {
                                                    var status_key = Object.keys(status);
                                                    // console.log(status_key);
                                                    RPLValue = status[status_key[0]];
                                                }
                                            }
                                            else {
                                                //var status = subComptRpl[subComp.sub_comptkeyword];
                                                console.log('subComptRpl:',subComptRpl)
                                                RPLValue = subComptRpl[subComp.sub_comptkeyword] !=null ? subComptRpl[subComp.sub_comptkeyword][subComp.sub_comptkeyword+'_rpl'] : 'null';
                                            }
                                            if(RPLValue && RPLValue != undefined && RPLValue != 'null' && RPLValue != 0 && RPLValue != '0'){
                                                isAssigned = true;
                                            }
                                                
                                            return (

                                                <Row key={key2}>
                                                    <Col md={{size: '8'}}>
                                                        <p>
                                                            {subComp.sub_comptname}
                                                        </p>
                                                    </Col>
                                                    <Col md={{size: '2'}} style={{textAlign: 'right'}}>
                                                        <Label for={subComp.sub_comptkeyword+"_rpl"} style={{fontSize: '12px', marginRight: '10px', marginBottom: 0, display: 'inline-block'}}>RPL</Label>
                                                        { getYear() == '2020' ? 
                                                        <Input disabled={typeof this.props.handleEdit === 'undefined' ? false : this.props.handleEdit} type="select" 
                                                            name={subComp.sub_comptkeyword+"_rpl"} 
                                                            id={subComp.sub_comptkeyword+"_rpl"}
                                                            style={{
                                                                display: 'inline-block',
                                                                fontSize: '12px',
                                                                width: '60px',
                                                                height: '28px',
                                                                paddingTop: 0,
                                                                paddingBottom: 0,
                                                                paddingLeft: 0
                                                            }}
                                                            onChange={(e) => {
                                                                if(isAssigned) {
                                                                    this.updateProfileData(userFetchedProfile.ID_NUMBER, subComp.sub_comptkeyword+"_RPL", e.target.value); //calls action for updating profile data RPL Column
                                                                }
                                                            }}
                                                        >
                                                            {
                                                                // !isAssigned ? 
                                                                    <option selected={!isAssigned} disabled>RPL</option>
                                                                // : null
                                                            }
                                                            <option value="1" selected={RPLValue == 1}>1</option>
                                                            <option value="2" selected={RPLValue == 2}>2</option>
                                                            <option value="3" selected={RPLValue == 3}>3</option>
                                                            <option value="4" selected={RPLValue == 4}>4</option>
                                                        </Input>
                                                        :
                                                        <Input disabled={typeof this.props.handleEdit === 'undefined' ? false : this.props.handleEdit} type="select" 
                                                            name={subComp.sub_comptkeyword+"_rpl"} 
                                                            id={subComp.sub_comptkeyword+"_rpl"}
                                                            style={{
                                                                display: 'inline-block',
                                                                fontSize: '12px',
                                                                width: '60px',
                                                                height: '28px',
                                                                paddingTop: 0,
                                                                paddingBottom: 0,
                                                                paddingLeft: 0
                                                            }}
                                                            onChange={(e) => {
                                                                if(isAssigned) {
                                                                    this.updateProfileData(userFetchedProfile.ID_NUMBER, subComp.sub_comptkeyword+"_RPL", e.target.value); //calls action for updating profile data RPL Column
                                                                }
                                                            }}
                                                        >
                                                            {
                                                                // !isAssigned ? 
                                                                    <option selected={!isAssigned} disabled>RPL</option>
                                                                // : null
                                                            }
                                                            <option value="100" selected={RPLValue == 100}>100</option>
                                                        </Input>
                                                        }
                                                    </Col>
                                                    <Col md={{size: '2'}}>
                                                        <Button disabled={typeof this.props.handleEdit === 'undefined' ? false : this.props.handleEdit}size="sm" color={isAssigned ? "danger" : "primary"}
                                                        outline
                                                        // disabled={!isAssigned}
                                                        onClick={(e) => {
                                                            if(isAssigned){ // need to unassign
                                                                
                                                                this.updateProfileData(userFetchedProfile.ID_NUMBER, subComp.sub_comptkeyword+"_RPL", "null");
                                                                
                                                                // get all the assessments list for this employee
                                                                // this.props.getAllAssessmentsForAdmin(userFetchedProfile.ID_NUMBER);

                                                            } else {
                                                                var rplSelected = document.getElementById(subComp.sub_comptkeyword+"_rpl").value;
                                                                console.log(rplSelected);
                                                                if(rplSelected && rplSelected !== "RPL") {

                                                                    
                                                                    this.updateProfileData(userFetchedProfile.ID_NUMBER, subComp.sub_comptkeyword+"_RPL", rplSelected);
                                                                    
                                                                    // get all the assessments list for this employee
                                                                    // this.props.getAllAssessmentsForAdmin(userFetchedProfile.ID_NUMBER);

                                                                } else {
                                                                    alert('RPL is required.')
                                                                }
                                                            }

                                                            // setTimeout(() => {
                                                                

                                                            // }, 500);

                                                        }}
                                                        >
                                                            {
                                                                isAssigned ? 'Unassign' : 'Assign'
                                                            }
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            )}
                                        )
                                    }
                                </Collapse>
                            </Col>
                        </Row>
                    : null
                )
            }

        </Container>

      </div>
    );
  }
}

AssessmentManagement.propTypes = {
    getAllAssessmentsForAdmin: PropTypes.func.isRequired
}

const mapStatesToProps = state => ({
    // triggerNextPinSuccess : state.webRed.triggerNextPinSuccess,
    // triggeredLoginAction : state.webRed.triggeredLoginAction
})

export default connect( mapStatesToProps, { getAllAssessmentsForAdmin })( AssessmentManagement );